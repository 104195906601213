import React, { useState } from 'react';
import './PageReportTranslate';
import { useTranslation } from 'react-i18next';
import { Menu, Box, Typography } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import { colorsFoodReport, colorsReport } from '../../_helpers/Constants';

export default function ColorInfo({ isGood }) {
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <Box>
      <HelpIcon
        color="primary"
        style={{ cursor: 'pointer' }}
        onClick={event => setAnchorEl(event.currentTarget)}
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        style={{ marginTop: 30 }}
        MenuListProps={{ style: { minWidth: 250 } }}
      >
        <div>
          <Typography
            style={{ padding: '10px', fontSize: '18px', fontWeight: '600' }}
            children={t('pageReport_reference')}
          />
          {isGood
            ? colorsFoodReport.map((item, index) => {
                return (
                  <Box
                    style={{
                      display: 'flex',
                      marginBottom: '10px',
                      alignItems: 'center',
                      paddingLeft: '10px',
                      paddingRight: '10px'
                    }}
                    key={index}
                  >
                    <div
                      style={{
                        backgroundColor: item.color,
                        width: '50px',
                        height: '30px',
                        marginRight: '10px'
                      }}
                    ></div>
                    <Typography> {item[i18n.language]} </Typography>
                  </Box>
                );
              })
            : colorsReport.map((item, index) => {
                return (
                  <Box
                    style={{
                      display: 'flex',
                      marginBottom: '10px',
                      alignItems: 'center',
                      paddingLeft: '10px',
                      paddingRight: '10px'
                    }}
                    key={index}
                  >
                    <div
                      style={{
                        backgroundColor: item.color,
                        width: '50px',
                        height: '30px',
                        marginRight: '10px'
                      }}
                    ></div>
                    <Typography> {item[i18n.language]} </Typography>
                  </Box>
                );
              })}
        </div>
      </Menu>
    </Box>
  );
}
