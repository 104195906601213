import moment from 'moment';
import React from 'react';
import FilterInputField from '../../components/TableFilter/ColumnsFilters/FilterInputField';
import FilterDatePicker from '../../components/TableFilter/ColumnsFilters/FilterDatePicker';
import ColumnSorting from '../../components/TableFilter/ColumnsFilters/ColumnSorting';
import FilterSelect from '../../components/TableFilter/ColumnsFilters/FilterSelect';
import { capitalize } from 'lodash';
import FilterTreeSelect from '../../components/TableFilter/ColumnsFilters/FilterTreeSelect/FilterTreeSelect';

export function kezekBulletinColumns({ t, lang, isAdmin }) {
  return [
    {
      Header: '№',
      accessor: 'rowNumber',
      width: 60,
      filterable: false
    },
    {
      Header: <ColumnSorting title={t('kezekBulletin_columnID')} name="id" />,
      accessor: 'id',
      width: 80,
      style: { textAlign: 'center' },
      Filter: <FilterInputField name="id" type="number" />
    },
    {
      Header: <ColumnSorting title={t('kezekBulletin_columnDate')} name="submissionDate" />,
      accessor: 'submissionDate',
      Cell: row => moment(row.original.submissionDate).format('DD.MM.YYYY HH:mm'),
      width: 120,
      sortable: false,
      style: { textAlign: 'center' },
      Filter: <FilterDatePicker name="submissionDate" lang={lang} />
    },
    {
      Header: t('kezekBulletin_columnServiceProvider'),
      accessor: 'serviceProviderName',
      sortable: false,
      Cell: ({ original }) => original[`serviceProvider${capitalize(lang)}Name`],
      Filter: <FilterInputField name="serviceProviderName" />
    },
    {
      Header: t('kezekBulletin_columnGroupName'),
      accessor: 'nameGroupName',
      sortable: false,
      Cell: ({ original }) => original[`nameGroup${capitalize(lang)}`],
      Filter: <FilterInputField name="nameGroup" />
    },
    {
      Header: <ColumnSorting title={t('kezekBulletin_columnAgeGroup')} name="ageGroup" />,
      accessor: 'ageGroup ',
      Cell: ({ original: { ageGroupName } }) => ageGroupName[`${lang}_name`],
      Filter: <FilterSelect name="ageGroup" dictCode="gu_age_group" lang={lang} />
    },
    {
      Header: <ColumnSorting title={t('kezekBulletin_columnQueuedType')} name="queuedType" />,
      accessor: 'queuedType ',
      Cell: ({ original: { queuedTypeName } }) => queuedTypeName[`${lang}_name`],
      Filter: <FilterSelect name="queuedType" dictCode="gu_queued_type" lang={lang} />
    },
    {
      Header: <ColumnSorting title={t('kezekBulletin_columnTypeBulletin')} name="typeBulletin" />,
      accessor: 'typeBulletin ',
      Cell: ({ original: { bulletinTypeName } }) => bulletinTypeName[`${lang}_name`],
      Filter: <FilterSelect name="typeBulletin" dictCode="gu_type_bulletin" lang={lang} />
    },
    {
      Header: t('kezekBulletin_columnNumberSeats'),
      accessor: 'numberSeats',
      width: 150,
      Filter: <FilterInputField name="numberSeats" type="number" />
    },
    {
      Header: t('kezekBulletin_columnFreeSeats'),
      accessor: 'freeSeats',
      width: 150,
      Filter: <FilterInputField name="freeSeats" type="number" />
    },
    {
      Header: t('kezekBulletin_columnAcademicYear'),
      accessor: 'beginAcademicYear',
      Cell: ({ original: { academicYearName } }) => academicYearName[`${lang}_name`],
      width: 150,
      Filter: <FilterSelect name="beginAcademicYear" dictCode="gu_academic_year_do" lang={lang} />
    },
    {
      Header: <ColumnSorting title={t('kezekBulletin_columnStatusWeb')} name="statusWeb" />,
      accessor: 'statusWeb ',
      Cell: ({ original: { statusWebName } }) => statusWebName[`${lang}_name`],
      Filter: <FilterSelect name="statusWeb" dictCode="gu_bulletin_status_web" lang={lang} />
    },
    {
      Header: t('kezekBulletin_columnBin'),
      accessor: 'serviceProviderBin',
      width: 150,
      style: { textAlign: 'center' },
      Filter: <FilterInputField name="serviceProviderBin" type="number" />
    },
    {
      Header: t('kezekBulletin_columnServiceProviderLocation'),
      accessor: 'serviceProviderLocation',
      Cell: ({ original: { serviceProviderLocation } }) => serviceProviderLocation[`${lang}_name`],
      Filter: (
        <FilterTreeSelect lookupId="60" name="serviceProviderLocation" lang={lang} />
      )
    },
  ].filter(item => isAdmin ? true : !['serviceProviderBin', 'serviceProviderLocation'].includes(item.accessor));
}
