const protocol = window.location.protocol;

export default {
  MON: {
    code: 'MON',
    logoUrl: '/logoMON.png',
    manualUrl: {
      ru: '/server/folder/media/manualMON_RU.pdf',
      kk: '/server/folder/media/manualMON_KK.pdf'
    },
    manualUniversityGrantUrl: {
      ru: '/server/folder/media/MANUAL_UNIVERSITY_GRANT_RU.pdf',
      kk: '/server/folder/media/MANUAL_UNIVERSITY_GRANT_KK.pdf'
    },
    email: 'support_gu@edu.kz',
    skypeUrl: 'https://join.skype.com/pkLhNPXtRdtn',
    orgName: {
      ru: 'МОН РК',
      kk: 'ҚР БҒМ'
    },
    companyName: {
      ru: 'Автоматизация Государственных услуг МОН РК',
      kk: 'ҚР БҒМ МЕМЛЕКЕТТІК ҚЫЗМЕТТЕРІН АВТОМАТТАНДЫРУ'
    },
    systemName: {
      ru: 'АВТОМАТИЗАЦИЯ ГОСУДАРСТВЕННЫХ УСЛУГ',
      kk: 'МЕМЛЕКЕТТІК ҚЫЗМЕТТЕРІН АВТОМАТТАНДЫРУ'
    },
    url: {
      base: `${protocol}//es-api.iac.kz`,
      service: `${protocol}//nobd-services.iac.kz`,
      fileStore: `${protocol}//es-filestore-api.iac.kz`
    }
  },
  Almaty: {
    code: 'Almaty',
    logoUrl: '/logoAlmaty.png',
    manualUrl: {
      kk: '/server/folder/media/manualAlmatyKK.pdf',
      ru: '/server/folder/media/manualAlmatyRU.pdf'
    },
    manualStagingDDOUrl: {
      kk: '/server/folder/media/STAGING_DDO_KK.pdf',
      ru: '/server/folder/media/STAGING_DDO_RU.pdf'
    },
    email: 'help@e-zhetysu.kz',
    supportPhones: '8 747 262 2750',
    skypeUrl: 'https://t.me/sp_zhetysu',
    orgName: {
      ru: 'Акимата Алматинской области',
      kk: 'Алматы облысының әкімдігі'
    },
    companyName: {
      ru: 'АВТОМАТИЗАЦИЯ ГОСУДАРСТВЕННЫХ УСЛУГ АКИМАТА АЛМАТИНСКОЙ ОБЛАСТИ',
      kk: 'АЛМАТЫ ОБЛЫСЫ ӘКІМДІГІНІҢ МЕМЛЕКЕТТІК ҚЫЗМЕТТЕРІН АВТОМАТТАНДЫРУ'
    },
    systemName: {
      ru: 'АВТОМАТИЗАЦИЯ ГОСУДАРСТВЕННЫХ УСЛУГ АКИМАТА АЛМАТИНСКОЙ ОБЛАСТИ',
      kk: 'АЛМАТЫ ОБЛЫСЫ ӘКІМДІГІНІҢ МЕМЛЕКЕТТІК ҚЫЗМЕТТЕРІН АВТОМАТТАНДЫРУ'
    },
    url: {
      base: `${protocol}//api.gu.almobl.kz`,
      service: `${protocol}//services.gu.almobl.kz`,
      fileStore: `${protocol}//filestore-api.gu.almobl.kz`
    }
  },
  Zhetisu: {
    code: 'Zhetisu',
    logoUrl: '/logoZhetisu.png',
    manualUrl: {
      kk: '/server/folder/media/manualAlmatyKK.pdf',
      ru: '/server/folder/media/manualAlmatyRU.pdf'
    },
    email: 'help@e-zhetysu.kz',
    supportPhones: '8 747 262 2750',
    skypeUrl: 'https://t.me/sp_zhetysu',
    orgName: {
      ru: 'Акимата области Жетісу',
      kk: 'Жетісу облысының әкімдігі'
    },
    companyName: {
      ru: 'АВТОМАТИЗАЦИЯ ГОСУДАРСТВЕННЫХ УСЛУГ АКИМАТА ЖЕТІСУСКОЙ ОБЛАСТИ',
      kk: 'ЖЕТІСУ ОБЛЫСЫ ӘКІМДІГІНІҢ МЕМЛЕКЕТТІК ҚЫЗМЕТТЕРІН АВТОМАТТАНДЫРУ'
    },
    systemName: {
      ru: 'АВТОМАТИЗАЦИЯ ГОСУДАРСТВЕННЫХ УСЛУГ АКИМАТА ЖЕТІСУСКОЙ ОБЛАСТИ',
      kk: 'ЖЕТІСУ ОБЛЫСЫ ӘКІМДІГІНІҢ МЕМЛЕКЕТТІК ҚЫЗМЕТТЕРІН АВТОМАТТАНДЫРУ'
    },
    url: {
      base: `${protocol}//es-api.e-zhetysu.kz`,
      service: `${protocol}//es-services.e-zhetysu.kz`,
      fileStore: `${protocol}//es-filestore-api.e-zhetysu.kz`
    }
  }
};
