import { addTranslation } from '../../../_helpers/Translate';

addTranslation({
  ru: {
    responsible_assign_responsible: 'Назначить ответственного',
    responsible_assign_responsible_list_empty: 'Список пуст',
    responsible_assign_responsible_Btn: 'Назначить ответственным',
    responsibleDucks_error_assign: 'Не удалось назначить ответственного',
    responsibleDucks_error_load: 'Не удалось загрузить список ответственных',
    responsibleDucks_assignSuccess: 'Ответственный успешно назначен'
  },
  kk: {
    responsible_assign_responsible: 'Жауаптыны тағайындау',
    responsible_assign_responsible_list_empty: 'Тізім бос',
    responsible_assign_responsible_Btn: 'Жауапты қылып тағайындау',
    responsibleDucks_error_assign: 'Жауапты тұлғаны тағайындау мүмкін емес',
    responsibleDucks_error_load: 'Жауаптылар тізімін жүктеу мүмкін емес',
    responsibleDucks_assignSuccess: 'Жауапты тұлға сәтті тағайындалды'
  }
});
