import moment from 'moment';
import React from 'react';
import { CheckBox } from '../../MyTaskStyles';
import { capitalize } from 'lodash';

export function AssignExecutorColumns({ t, i18n, checkedList, onClickRow, isVisible }) {
  return [
    {
      Header: '',
      accessor: 'checkItem',
      show: isVisible,
      width: 50,
      Cell: ({ original }) => (
        <div onClick={e => e.stopPropagation()} className="center">
          <CheckBox
            checked={checkedList.some(item => item.taskId === original.taskId)}
            onChange={() => onClickRow({ original })}
          />
        </div>
      )
    },
    {
      Header: '№',
      accessor: 'rowNumber',
      width: 60
    },
    {
      Header: t('assignExecutor_columnNumber'),
      accessor: 'taskId',
      Cell: ({ original }) => original[`taskName${capitalize(i18n.language)}`]
    },
    {
      Header: t('assignExecutor_columnExecutorName'),
      accessor: 'makerDisplayName'
    },
    {
      Header: t('assignExecutor_columnDueDate'),
      accessor: 'dueDate',
      Cell: ({ original }) => moment(original.dueDate).format('DD.MM.YYYY HH:mm')
    }
  ];
}
