import moment from 'moment';
import getStyle from './getStyle';

export default function handleData(t, lang, data, filter) {
  const list = [];
  const period = {
    fromDate: moment(filter.fromDate).format('DD.MM.YYYY HH:mm'),
    toDate: moment(filter.toDate).format('DD.MM.YYYY HH:mm')
  };
  const service = filter.serviceType
    ? filter.serviceType[`${lang}_name`]
    : t('monitoring_excelAllServices');

  list.push([{ v: t('monitoring_excelPeriod', { period }), s: { font: { bold: true } } }]);
  list.push([{ v: t('monitoring_excelService', { service }), s: { font: { bold: true } } }]);
  list.push([
    { v: t('monitoring_columnLocation'), s: getStyle({ isHeader: true }) },
    { v: t('monitoring_columnSUBMITTED'), s: getStyle({ isHeader: true }) },
    { v: t('monitoring_columnIN_PROGRESS'), s: getStyle({ isHeader: true }) },
    { v: t('monitoring_columnREADY_FOR_SIGN'), s: getStyle({ isHeader: true }) },
    { v: t('monitoring_columnCOMPLETED_POSITIVE'), s: getStyle({ isHeader: true }) },
    { v: t('monitoring_columnCOMPLETED_NEGATIVE'), s: getStyle({ isHeader: true }) },
    { v: t('monitoring_columnIN_PROGRESS_IN_EXTERNAL_APP'), s: getStyle({ isHeader: true }) },
    { v: t('monitoring_columnAllByRegion'), s: getStyle({ isHeader: true }) }
  ]);

  for (let item of data) {
    const isSum = item.isSum;
    list.push([
      { v: item[`${lang}_locationName`] || '', s: getStyle({ isLabel: true, isSum }) },
      { v: item['SUBMITTED'], s: getStyle({ isSum }) },
      { v: item['IN_PROGRESS'], s: getStyle({ isSum }) },
      { v: item['READY_FOR_SIGN'], s: getStyle({ isSum }) },
      { v: item['COMPLETED_POSITIVE'], s: getStyle({ isSum }) },
      { v: item['COMPLETED_NEGATIVE'], s: getStyle({ isSum }) },
      { v: item['IN_PROGRESS_IN_EXTERNAL_APP'], s: getStyle({ isSum }) },
      { v: item['allByRegion'], s: getStyle({ isSum: true }) }
    ]);
  }
  return list;
}
