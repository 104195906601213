import React from 'react';
import moment from 'moment';

const styles = {
  textAlign: 'center',
  alignSelf: 'center'
};

export function ClassListColumns({ t, lang }) {
  return [
    {
      Header: '№',
      accessor: 'id',
      filterable: false,
      width: 60,
      style: styles,
    },
    {
      Header: t('classListPage_childIin'),
      accessor: 'studentIin',
      filterable: false,
      style: styles,
    },
    {
      Header: t('classListPage_fullName'),
      accessor: 'childName',
      filterable: false,
      Cell: ({ original }) => {
        return (
          <div>
            { original?.childName } {original?.studentSurname} {original?.studentName}
          </div>
        ) 
      }},
    {
      Header: t('classListPage_birthday'),
      accessor: 'birthday',
      Cell: ({ original: { birthday } }) => birthday && moment(birthday).format("DD.MM.YYYY"),
      filterable: false,
      style: styles,
    },
    {
      Header: t('classListPage_date'),
      accessor: 'enrollmentDate',
      filterable: false,
      style: styles,
      Cell: ({ original: { enrollmentDate } }) => enrollmentDate && moment(enrollmentDate).format("DD.MM.YYYY"),
    },
    {
      Header: t('classListPage_columnLanguage'),
      accessor: 'language',
      filterable: false,
      style: styles,
      Cell: ({ original: { languageChild } }) => languageChild[`${lang}_name`],
    },
  ];
}
