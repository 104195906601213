import { addTranslation } from '../../_helpers/Translate';

addTranslation({
  ru: {
    resetPass_recovering: 'Восстановление пароля',
    resetPass_recover: 'Восстановить',
    resetPass_login: 'Логин пользователя',
    resetPass_info:
      'На почту, указанную при регистрации будет отправлено письмо с ссылкой, куда нужно будет перейти, чтобы задать новый пароль',
    resetPass_changing: 'Изменение пароля',
    resetPass_change: 'Изменить пароль',
    resetPass_newPass: 'Новый пароль',
    resetPass_repeatPass: 'Повторите пароль',

    resetPass_passNotEquals: 'Пароли не совпадают',
    resetPass_required: 'Поле обязательное для заполнения',
    resetPass_wrongPasswordLength: 'Пароль должен состоять не менее из 8 символов',
    resetPass_wrongPasswordDigits: 'Пароль должен включать цифры',
    resetPass_wrongPasswordUppercase:
      'Пароль должен включать латинские буквы верхнего регистра (A-Z)',
    resetPass_wrongPasswordLowercase:
      'Пароль должен включать латинские буквы нижнего регистра (a-z)',
    resetPass_wrongPasswordNotCyrillic: 'Пароль не должен содержать кириллицу',
    resetPass_wrongPasswordSymbols:
      'Пароль должен включать не буквенные и не цифровые символы (например, "#", "!" или "&")',

    resetPassDucks_mailResetPassFailure: 'Не удалось отправить ссылку для сброса пароля!',
    resetPassDucks_mailResetPassSuccess:
      'Письмо с ссылкой для сброса пароля успешно отправлено!',
    resetPassDucks_resetPassSuccess: 'Пароль успешно изменен!',
    resetPassDucks_resetPassFailure: 'Не удалось изменить пароль!',
    resetPassDucks_oneTimePasswordNotFound:
      'Ссылка для восстановления пароля не действительна, обратитесь в службу поддержки'
  },
  kk: {
    resetPass_recovering: 'Құпия сөзді қалпына келтіру',
    resetPass_recover: 'Қалпына келтіру',
    resetPass_login: 'Пайдаланушының логині',
    resetPass_info:
      'Сілтемесі бар хат тіркеу кезінде көрсетілген поштаға жіберіледі, онда сізге жаңа құпия сөзді орнату үшін бару қажет болады',
    resetPass_changing: 'Құпия сөзді өзгерту',
    resetPass_change: 'Құпия сөзді өзгерту',
    resetPass_newPass: 'Жаңа құпия сөз',
    resetPass_repeatPass: 'Құпия сөзді қайталау',

    resetPass_required: 'Бұл өрісті толтыру міндетті',
    resetPass_wrongPasswordLength: 'Құпия сөз 8 таңбадан кем болмауы тиіс',
    resetPass_wrongPasswordDigits: 'Құпия сөз сандарды қамтуы тиіс',
    resetPass_wrongPasswordUppercase:
      'Құпия сөз жоғарғы регистрдің латын әріптерін (A-Z) қамтуы тиіс',
    resetPass_wrongPasswordLowercase:
      'Құпия сөз төменгі регистрдің латын әріптерін (a-z) қамтуы тиіс',
    resetPass_wrongPasswordNotCyrillic: 'Құпия сөз кириллицаны қамтымауы керек',
    resetPass_wrongPasswordSymbols:
      "Құпия сөз әріптік емес және сандық емес символдарды қамтуы тиіс (мысалы, '#', '!' немесе '&')",

    resetPassDucks_mailResetPassFailure: 'Құпия сөзді қалпына келтіру сілтемесі жіберілмеді!',
    resetPassDucks_mailResetPassSuccess:
      'Құпия сөзді қалпына келтіру үшін сілтемесі бар электрондық пошта жіберілді!',
    resetPassDucks_resetPassSuccess: 'Құпия сөз сәтті өзгертілді!',
    resetPassDucks_resetPassFailure: 'Құпия сөз өзгертілмеді!',
    resetPassDucks_oneTimePasswordNotFound:
      'Құпия сөзді қалпына келтіру сілтемесі жарамсыз, қолдау қызметіне хабарласыңыз'
  }
});
