import React, { useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import i18next from 'i18next';
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs';
import { compose } from 'redux';
import withTranslation from '../../components/_hoc/withTranslation';
import { connect } from 'react-redux';
import withModal from '../../components/_hoc/withModal';
import { handbookModule, loadHandbook, clearState } from './HandbookDucks';
import { statusColor } from '../../utils/statusColors';
import { handbookColumns } from './HandbookColumns';
import StaticTable from '../../_ui/Table/StaticTable';
import HandbookViewModal from './HandbookViewModal';
import './HandbookTranslate';

function HandbookPage({
  t,
  lang,
  tableData,
  loadHandbook,
  loadingTable,
  modal,
  clearState
}) {
  const breadCrumbItems = [
    { name: i18next.t('breadcrumbs_home'), href: '/' },
    { name: i18next.t('breadcrumbs_handbooks'), href: '/handbook' }
  ];

  useEffect(() => {
    loadHandbook();
    return () => clearState();
  }, [loadHandbook, clearState]);

  return (
    <>
      <BreadCrumbs items={breadCrumbItems} />
      <Paper className="pb3 pt1 px3">
        <h3>{t('handbookPage_title')}</h3>
        <StaticTable
          columns={handbookColumns({ t, lang })}
          data={tableData}
          totalElements={tableData.length}
          loading={loadingTable}
          onClickRow={({ original }) => modal.open(original)}
          rowBgColor={row => statusColor[row.original.resolutionType]}
        />
      </Paper>
      {modal.isOpen && <HandbookViewModal modal={modal} />}
    </>
  );
}

export default compose(
  withTranslation,
  connect(
    state => ({
      loadingTable: state[handbookModule].loadingTable,
      tableData: state[handbookModule].tableData
    }),
    { loadHandbook, clearState }
  )
)(withModal(HandbookPage));
