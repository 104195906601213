import React, { useState } from 'react';
import { Tabs, Tab, Paper } from '@material-ui/core';
import { Link } from 'react-router-dom';
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs';
import paths from '../../_helpers/paths';
import MonitoringTable from './Table/MonitoringTable';
import MonitoringCharts from './Charts/MonitoringCharts';
import './MonitoringTranslate';
import DateTimePicker from '../../components/TableFilter/ColumnsFilters/FilterDateTimePicker';
import FilterSelect from '../../components/TableFilter/ColumnsFilters/FilterSelect';
import useTableFilter from '../../components/TableFilter/useTableFilter';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { loadData, monitoringModule as module } from './MonitoringDucks';
import Popper from '@material-ui/core/Popper/Popper';
import TextField from '@material-ui/core/TextField/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete';
import useDict from '../../components/_hooks/useDict';

const defaultValues = {
  fromDate: moment()
    .add(-20, 'd')
    .format('YYYY-MM-DDTHH:mm:ss'),
  toDate: moment().format('YYYY-MM-DDTHH:mm:ss')
};

export default function MonitoringPage({ location: { pathname, search } }) {
  const { t, i18n } = useTranslation();
  const [serviceType, setServiceType] = useState();
  const dispatch = useDispatch();
  const tableUrl = paths.monitoringTablePage;
  const chartUrl = paths.monitoringChartsPage;
  const loading = useSelector(state => state[module].loading);
  const data = useSelector(state => state[module].data);
  const serviceTypeDict = useDict('gu_services');
  const { filter } = useTableFilter(filter => dispatch(loadData(filter)), defaultValues);
  const breadCrumbItems = [
    { name: t('breadcrumbs_home'), href: '/' },
    { name: t('breadcrumbs_monitoring') }
  ];

  return (
    <>
      <BreadCrumbs items={breadCrumbItems} />
      <Paper>
        <Tabs value={pathname}>
          <Tab
            label={t('monitoring_tables')}
            value={tableUrl}
            component={Link}
            to={tableUrl + search}
          />
          <Tab
            label={t('monitoring_charts')}
            value={chartUrl}
            component={Link}
            to={chartUrl + search}
          />
        </Tabs>
        <div className="px3 pb3 pt1 relative">
          <div className="flex justify-between mb1">
            <div className="flex flex-wrap">
              <DateTimePicker
                name="fromDate"
                placeholder={t('monitoring_fromDate')}
                style={{ marginRight: 10, width: 230 }}
              />
              <DateTimePicker
                name="toDate"
                placeholder={t('monitoring_toDate')}
                style={{ marginRight: 10, width: 230 }}
              />
              {pathname === tableUrl && (
                <Autocomplete
                  style={{ width: 350 }}
                  placeholder={t('monitoring_columnService')}
                  fullWidth
                  value={serviceType}
                  PopperComponent={props => (
                    <Popper {...props} style={{ ...props.style, minWidth: 400 }} />
                  )}
                  onChange={(ev, option) => setServiceType(option)}
                  options={serviceTypeDict.filter(s =>
                    data.some(d => d.service_type.toLowerCase() === s.code.toLowerCase())
                  )}
                  getOptionLabel={option => option[`${i18n.language}_name`]}
                  renderInput={params => <TextField {...params} />}
                />
              )}
              {serviceType?.code === 'doc_queued_ddo' && (
                <div style={{ width: 200, marginLeft: 12 }}>
                  <FilterSelect
                    name="reportType"
                    dictCode="report_type"
                    lang={i18n.language}
                  />
                </div>
              )}
            </div>
          </div>
          {pathname === tableUrl && (
            <MonitoringTable
              data={data}
              loading={loading}
              serviceType={serviceType}
              filter={{
                ...filter,
                serviceType,
                reportType:
                  serviceType?.code === 'doc_queued_ddo' ? filter.reportType : ''
              }}
            />
          )}
          {pathname === chartUrl && <MonitoringCharts data={data} />}
        </div>
      </Paper>
    </>
  );
}
