import styled from 'styled-components';
import { withStyles, Checkbox as JustCheckbox, Tooltip as MuiTooltip } from '@material-ui/core';

export const Wrapper = styled.div`
  min-width: 1800px;
  
  .ReactTable .rt-table .rt-td {
    padding: 7px 5px;
  }
`;

export const CheckBox = withStyles(() => ({
  root: {
    padding: 0,
    margin: 0
  }
}))(JustCheckbox);

export const Tooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.warning.light,
    color: 'black'
  }
}))(MuiTooltip);
