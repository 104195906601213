import styled from 'styled-components';
import { withStyles } from '@material-ui/core';
import JustCheckbox from '../../_ui/Checkbox/Checkbox';
import StaticTablePagination from '../../_ui/Table/StaticTablePaginated';

export const Table = styled(StaticTablePagination)`
  .MuiFormControlLabel-root {
    margin: 0 !important;
  }

  .rt-td:nth-child(2n) {
    ${props => props.columns[1].show && 'padding: 0 !important'}
  }

  .rt-thead.-header {
    top: -15px !important;
  }

  .rt-thead.-filters {
    top: 25px !important;
  }
`;

export const CheckBox = withStyles(() => ({
  root: {
    padding: 0,
    margin: 0
  }
}))(JustCheckbox);

export const Wrapper = styled.div`
  .ReactTable {
    border-top: 1px solid rgba(170,170,170,0.08); !important;
    border-right: none;
    border-bottom: none;
    border-left: none;
    border-radius: 0 !important;
  }
`;
