import styled from 'styled-components';

export const ErrorMessage = styled.div`
  position: absolute;
  top: calc(50% - 100px);
  left: calc(50% - 325px);
  width: 650px;
  font-size: 19px;
  box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
  border-radius: 0.28571429rem;
  padding: 30px;
  color: #cd0930;
  background: #fff5f7;
  word-wrap: break-word;
  white-space: pre-line;

  a {
    color: #cd0930;
    text-decoration: underline;
  }
`;
