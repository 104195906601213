import React from 'react';
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs';
import { Tabs, Tab, Paper, Box, Typography } from '@material-ui/core';
import './ContingentTranslate';
import { useTranslation } from 'react-i18next';
import paths from './../../_helpers/paths';
import { Link } from 'react-router-dom';
import ContingentCandidate from './Candidate/ContingentCandidate';
import ContingentEnrolled from './Enrolled/ContingentEnrolled';
import ContingentExited from './Exited/ContingentExited';
import ExportIcon from '@material-ui/icons/SaveAlt';
import Button from '../../_ui/Button/Button';
import AddIcon from '@material-ui/icons/Add';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { exportApplicant, exportEnrolled, exportExpelled, contingentModule } from './ContingentDucks'
import { Color } from '../../components/ThemeProvider/ThemeStyle';

export default function ContingentPage({ location: { pathname } }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loadingExport = useSelector(state => state[contingentModule].loadingExport);

  const candidateUrl = paths.contingentCandidatePage;
  const enrolledUrl = paths.contingentEnrolledPage;
  const exitedUrl = paths.contingentExitedPage;

  const breadCrumbItems = [
    { name: t('breadcrumbs_home'), href: '/' },
    { name: t('breadcrumbs_contingen'), href: paths.contingentCandidatePage }
  ];
  
  const exportFile = () => {
    if (pathname === candidateUrl) {
      dispatch(exportApplicant())
    } else if (pathname === enrolledUrl) {
      dispatch(exportEnrolled())
    } else if (pathname === exitedUrl) {
      dispatch(exportExpelled())
    }
  }

  const totalElements = useSelector(state => state[contingentModule].tableData.totalElements);

  return (
    <>
      <BreadCrumbs items={breadCrumbItems} />
      <Paper 
        style={{ position: 'relative' }}
      >
        <Box className='flex justify-between'>
          <Box className={Color.isDark ? 'flex contingent_dark' : 'flex contingent'} style={{zIndex: 9}}>
            <Tabs 
              value={pathname} 
              indicatorColor={Color.isDark ? '#252525' : '#fff'}
            >
              <Tab
                label={t('contingentPage_candidate')}
                value={candidateUrl}
                component={Link}
                to={candidateUrl}
                style={{
                  fontWeight: '700',
                  border: '1px solid #e1e1e1',
                  borderBottom: 'none',
                  borderLeft: 'none',
                  backgroundColor: '#e1e1e185'
                }}
              />
              <Tab
                label={t('contingentPage_enrolled')}
                value={enrolledUrl}
                component={Link}
                to={enrolledUrl}
                style={{
                  fontWeight: '700',
                  border: '1px solid #e1e1e1',
                  borderBottom: 'none',
                  backgroundColor: '#e1e1e185'
                }}
              />
              <Tab
                label={t('contingentPage_exited')}
                value={exitedUrl}
                component={Link}
                to={exitedUrl}
                style={{
                  fontWeight: '700',
                  backgroundColor: '#e1e1e185',
                  border: '1px solid #e1e1e1',
                  borderBottom: 'none',
                }}
              />
            </Tabs>
            <Box style={{marginTop: '3px', marginLeft: '10px', display: 'flex', alignItems: 'center'}}>
              <Button
                text={t('journalPage_export_btn')}
                icon={<ExportIcon fontSize="small" className="mr1" />}
                onClick={exportFile}
                loading={loadingExport}
              />
              <Typography style={{ marginLeft: '10px'}}>
                {t('colleaguesPassport_numberEmployees')} {' '} {totalElements}
              </Typography>
            </Box>
          </Box>
          {
            pathname === enrolledUrl &&
            <Box className='mr3' style={{marginTop: '5px'}}>
              <Button
                icon={<AddIcon fontSize="small" className="mr1" />}
                text={t('kezekBulletinPage_add')}
                component={NavLink}
                to={paths.contingentItemAddPage}
              />
            </Box>
          }
        </Box>
        
        <div className="px3 pb3 pt1 relative" style={{ borderTop: '1px solid #e1e1e1', marginTop: '-1px', }}>
          {pathname === candidateUrl && <ContingentCandidate />}
          {pathname === enrolledUrl && <ContingentEnrolled />}
          {pathname === exitedUrl && <ContingentExited />}
        </div>
      </Paper>
    </>
  );
}
