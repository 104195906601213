import React from 'react';
import { Card, Link } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import config from '../../_helpers/config';

const ManualCard = ({onChangeSelectedInstruction, t}) => {
  return KEYS_OF_FILES.map(keyOfFile => config[keyOfFile] && (
    <Card
      variant="outlined"
      className="my1 p2 pointer flex items-center fw-6"
      key={keyOfFile}
      data-keyOfInstruction={keyOfFile}
      onClick={onChangeSelectedInstruction}
    >
      <DescriptionIcon color="action" className="mr2" />
      <Link color="textPrimary">{t(`${keyOfFile.substring(0, keyOfFile.length - 3)}`)}</Link>
    </Card>
  ))
}

const KEYS_OF_FILES = [
  "manualUrl",
  "manualUniversityGrantUrl",
  "manualStagingDDOUrl"
];

export default ManualCard