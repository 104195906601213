import { addTranslation } from '../../_helpers/Translate';

addTranslation({
  ru: {
    kezekDirectionTable_title: 'Список направлении',
    kezekDirectionTable_columnID: 'Номер',
    kezekDirectionTable_childIIN: 'ИИН ребенка',
    kezekDirectionTable_childName: 'ФИО ребенка',
    kezekDirectionTable_columnBin: 'БИН',
    kezekDirectionTable_bulletin: 'Номер бюллетени',
    kezekDirectionTable_columnDate: 'Дата, время',
    kezekDirectionTable_time24Direction: 'Срок резервирования',
    kezekDirectionTable_directionDate: 'Срок направления',
    kezekDirectionTable_columnServiceProviderLocation: 'Населенный пункт',
    kezekDirectionTable_columnQueuedType: 'Тип очереди',
    kezekDirectionTable_status: 'Статус',
    kezekDirectionTable_columnAgeGroup: 'Возрастная группа',

    kezekDirectionTableDucks_errorLoadTable: 'Не удалось загрузить бюллетени',
    kezekDirectionTableDucks_errorChangeFilter: 'Не удалось поменять фильтр',
  },
  kk: {
    kezekDirectionTable_title: 'Жолдамалар тізімі',
    kezekDirectionTable_columnID: 'Нөмірі',
    kezekDirectionTable_childIIN: 'Баланың ЖСН',
    kezekDirectionTable_childName: 'Баланың аты-жөні',
    kezekDirectionTable_bulletin: 'Бюллетеннің нөмірі',
    kezekDirectionTable_columnBin: 'БСН',
    kezekDirectionTable_columnDate: 'Күні, уақыты',
    kezekDirectionTable_time24Direction: 'Резервтеліну уақыты',
    kezekDirectionTable_directionDate: 'Жолдаманың мерзімі',
    kezekDirectionTable_columnServiceProviderLocation: 'Аймақ',
    kezekDirectionTable_columnQueuedType: 'Кезек түрі',
    kezekDirectionTable_status: 'Статус',
    kezekDirectionTable_columnAgeGroup: 'Жас тобы',

    kezekDirectionTableDucks_errorLoadTable: 'Бюллетеньдерді жүктеу мүмкін емес',
    kezekDirectionTableDucks_errorChangeFilter: 'Сүзгіні өзгерту мүмкін болмады',
  }
});
