import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ButtonGroup } from '@material-ui/core';
import _ from 'lodash';

import { login, loginModule, loginWithECP } from './LoginDucks';
import Form from '../../_ui/Form/Form';
import Input from '../../_ui/Input/Input';
import Button from '../../_ui/Button/Button';
import { LightTooltip, Wrapper } from './LoginStyle';
import { SignInSchema } from './LoginValidation';
import config from '../../_helpers/config';
import paths from '../../_helpers/paths';
import PasswordField from '../../components/PasswordField/PasswordField';
import './LoginTranslate';

export default function LoginPage() {
  const { t, i18n } = useTranslation();
  const loadingLogin = useSelector(state => state[loginModule].loadingLogin);
  const loadingECP = useSelector(state => state[loginModule].loadingECP);
  const dispatch = useDispatch();

  return (
    <Wrapper>
      <div className="login-header" />
      <div className="login-container">
        <div className="color-secondary mb2 pre-line">{t('loginPage_disclaimer')}</div>
        <div className="flex" style={{ flex: 1 }}>
          <div className="left-block">
            <img alt="" src={config.logoUrl} width={150} />
            <div className="system-name">{config.systemName[i18n.language]}</div>
          </div>
          <div className="right-block">
            <div className="mt3">
              <div className="title">{t('loginPage_auth')}</div>
              <Form
                initialValues={{ username: '', password: '' }}
                onSubmit={data => dispatch(login(data))}
                validate={SignInSchema}
                render={(_, { values, handleChange }) => (
                  <>
                    <Input name="username" placeholder={t('loginPage_username')} />
                    <PasswordField
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      placeholder={t('loginPage_password')}
                    />
                    <ButtonGroup
                      className="mt2"
                      fullWidth
                      color="primary"
                      variant="contained"
                    >
                      <Button
                        fullWidth
                        type="submit"
                        text={t('loginPage_signIn')}
                        loading={loadingLogin}
                      />
                      {config.code === 'MON' && (
                        <Button
                          fullWidth
                          text={t('loginPage_signInWithSignature')}
                          onClick={e => {
                            e.stopPropagation();
                            dispatch(loginWithECP());
                          }}
                          loading={loadingECP}
                        />
                      )}
                    </ButtonGroup>
                  </>
                )}
              />
              <div className="help-text mt2">
                {config.code === 'MON' && (
                  <Link to="/resetpass" children={t('loginDucks_forgotPass')} />
                )}
                <Link to={paths.contactSupportPage} children={t('loginDucks_help')} />
                <Link to={paths.faqPage} children={t('loginDucks_faq')} />
                {config.code === 'MON' && (
                  <LightTooltip
                    children={<Link to="#">{t('loginDucks_securityReqs')}</Link>}
                    title={
                      <>
                        <h3>{t('loginDucks_securityReqs_title')}</h3>
                        <ul className="fs-14">
                          {_.range(1, 6).map(num => (
                            <li key={num} className="mb1">
                              {t(`loginDucks_securityReqs_text_${num}`)}
                            </li>
                          ))}
                        </ul>
                      </>
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
