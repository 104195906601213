import React from 'react';
import Modal from '../../_ui/Modal/Modal';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import Button from '../../_ui/Button/Button';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { archive } from './JournalDucks';

function ArchiveModal({ data, close, reloadPage }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return (
    <Modal title={t('journalDucks_archiveModalTitle')} open onClose={close}>
      <DialogContent>{t('journalDucks_archiveModalText', data)}</DialogContent>
      <DialogActions>
        <Button
          text={t('journalPage_toArchive')}
          onClick={() => dispatch(archive(data, reloadPage, close))}
        />
      </DialogActions>
    </Modal>
  );
}

export default ArchiveModal;
