import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import CreateIcon from '@material-ui/icons/Create';
import SaveIcon from '@material-ui/icons/Save';
import Button from '../../_ui/Button/Button';
import withTranslation from '../../components/_hoc/withTranslation';
import { serviceProviderModule } from './ServiceProviderDucks';
import { ButtonsWrapper } from './ServiceProviderStyle';
import { PAGE_MODE } from '../../_helpers/Constants';
import { useTranslation } from 'react-i18next';

const SaveButton = ({ loadingSave, pageMode, onClick }) => {
  const { t } = useTranslation();
  return (
    <ButtonsWrapper>
      {pageMode === PAGE_MODE.EDIT ? (
        <Button
          type="button"
          size="small"
          loading={loadingSave}
          icon={<SaveIcon fontSize="small" className="mr1" />}
          text={t('serviceProvider_saveForm')}
          onClick={onClick}
        />
      ) : (
        <Button
          type="button"
          size="small"
          loading={loadingSave}
          icon={<CreateIcon fontSize="small" className="mr1" />}
          text={t('serviceProvider_createForm')}
          onClick={onClick}
        />
      )}
    </ButtonsWrapper>
  );
};

export default compose(
  withTranslation,
  connect(state => ({
    loadingSave: state[serviceProviderModule].loadingSave
  }))
)(SaveButton);
