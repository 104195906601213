import React from 'react';
import { TASK_TYPE } from '../../../_helpers/Constants';
import AssignExecutor from './AssignExecutor/AssignExecutor';
import QuestionnaireTask from './QuestionnaireTask/QuestionnaireTask';
import BreadCrumbs from '../../../components/BreadCrumbs/BreadCrumbs';
import Paper from '@material-ui/core/Paper';
import { useTranslation } from 'react-i18next';
import SignTask from './SignTask/SignTask';
import SelectUser from './SelectUser/SelectUser';

const TaskPage = ({ match, location }) => {
  const { t } = useTranslation();
  const taskId = match.params.id;
  const taskType = new URLSearchParams(location.search).get('taskType');

  const breadCrumbItems = [
    { name: t('breadcrumbs_home'), href: '/' },
    { name: t('breadcrumbs_myTask'), href: '/myTask?filter=%7B"pageNumber"%3A1%2C"pageSize"%3A20%2C"taskStatus"%3A"IN_PROGRESS"%7D' },
    { name: t('breadcrumbs_task') }
  ];

  return (
    <>
      <>
        <BreadCrumbs items={breadCrumbItems} />
        <Paper className="pb3 pt1 px3">
          {taskType === TASK_TYPE.ASSIGN_FUTURE_TASKS && (
            <AssignExecutor taskIds={[taskId]} />
          )}
          {taskType === TASK_TYPE.QUESTIONNAIRE_TASK && (
            <QuestionnaireTask taskId={taskId} />
          )}
          {taskType === TASK_TYPE.FULL_BO_QUESTIONNAIRE_TASK && (
            <QuestionnaireTask taskId={taskId} />
          )}
          {taskType === TASK_TYPE.SIGN_REQUEST_FORM && (
            <SignTask taskId={taskId} />
          )}
          {taskType === TASK_TYPE.SIGN_BUSINESS_OBJECT && (
            <SignTask taskId={taskId} />
          )}
          {taskType === TASK_TYPE.SELECT_USER && (
            <SelectUser taskIds={[taskId]} />
          )}
        </Paper>
      </>
    </>
  );
};

export default TaskPage;
