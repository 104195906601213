import React from 'react';
import Button from '@material-ui/core/Button';

export function columnsTab1({ lang, columnTranslate }) {
  const cTranslate = code =>
    columnTranslate[code] ? columnTranslate[code][`${lang}_name`] : code;
  return [
    {
      Header: '№',
      accessor: 'rowNumber',
      maxWidth: 60,
      filterable: false
    },
    {
      Header: cTranslate('external_app_name'),
      accessor: `external_app_name`
    },
    {
      Header: cTranslate('waiting'),
      accessor: 'waiting'
    },
    {
      Header: cTranslate('success'),
      accessor: 'success'
    },
    {
      Header: cTranslate('failure'),
      accessor: 'failure'
    },
    {
      Header: cTranslate('failure_end_try'),
      accessor: 'failure_end_try'
    }
  ];
}

export function columnsTab2({ t, lang, columnTranslate, cancelQueue }) {
  const cTranslate = code =>
    columnTranslate[code] ? columnTranslate[code][`${lang}_name`] : code;
  return [
    {
      Header: '№',
      accessor: 'rowNumber',
      maxWidth: 60,
      filterable: false
    },
    {
      Header: cTranslate('id'),
      accessor: `id`
    },
    {
      Header: cTranslate('request_form_id'),
      accessor: 'request_form_id'
    },
    {
      Header: cTranslate('external_app_name'),
      accessor: 'external_app_name'
    },
    {
      Header: cTranslate('external_request_id'),
      accessor: 'external_request_id'
    },
    {
      Header: cTranslate('attempts_count'),
      accessor: 'attempts_count'
    },
    {
      Header: cTranslate('error_message'),
      accessor: 'error_message'
    },
    {
      Header: cTranslate('last_attempt_date'),
      accessor: 'last_attempt_date'
    },
    {
      Header: cTranslate('queue_name'),
      accessor: 'queue_name'
    },
    {
      Header: cTranslate('process_status'),
      accessor: 'process_status'
    },
    {
      Header: t('queuePage_actions'),
      accessor: 'id',
      Cell: ({ original }) => (
        <div className="center" style={{ margin: '-5px 0' }}>
          <Button
            color="secondary"
            size="small"
            onClick={() => cancelQueue(original.id)}
            children={t('queuePage_cancelQueue')}
          />
        </div>
      )
    }
  ];
}
