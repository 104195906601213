import React from 'react';
import Modal from '../../../../_ui/Modal/Modal';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import Button from '../../../../_ui/Button/Button';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import SaveIcon from '@material-ui/icons/Save';
import {WrapperForm} from '../../ColleaguesStyle'

export default function ModalAdd(data) {
  const { t, i18n } = useTranslation();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const save = (info) => {
    console.log('info', info)
    console.log('errors', errors)
  }


  return (
    <Modal
      title={t('colleagues_modalEmployeesTitle') }
      open
      onClose={data.close}
    >
      <DialogContent>
        <div className="flex justify-start pb2">
          <form onSubmit={handleSubmit(save)} style={{width: '100%'}}>
            <WrapperForm>
              <Typography children={t('colleagues_iin')}/>
              <Box>
                <TextField
                  multiline
                  size="medium"
                  fullWidth
                  error={!!errors.iinEmployee}
                  {...register('iinEmployee', {
                    required: t('colleagues_modalRequired')
                  })}
                />
                {errors?.iinEmployee && errors?.iinEmployee.type === "required" && <FormHelperText error>{t('colleagues_modalRequired')}</FormHelperText>}
              </Box>
            </WrapperForm> 
            <WrapperForm>
              <Typography children={t('colleagues_fullName')} />
              <Box>
                <TextField
                  multiline
                  size="medium"
                  fullWidth
                  error={!!errors.fullName}
                  {...register('fullName', {
                    required: t('colleagues_modalRequired')
                  })}
                />
                {errors?.fullName && errors?.fullName.type === "required" && <FormHelperText error>{t('colleagues_modalRequired')}</FormHelperText>}
              </Box>
            </WrapperForm> 
            <WrapperForm>
              <Typography children={t('colleagues_dateBirth')}/>
              <Box>
                <TextField
                  multiline
                  size="medium"
                  fullWidth
                  error={!!errors.dateBirth}
                  {...register('dateBirth', {
                    required: t('colleagues_modalRequired')
                  })}
                />
                {errors?.dateBirth && errors?.dateBirth.type === "required" && <FormHelperText error>{t('colleagues_modalRequired')}</FormHelperText>}
              </Box>
            </WrapperForm>
            <WrapperForm>
              <Typography children={t('colleagues_employeePosition')}/>
              <Box>
                <TextField
                  multiline
                  size="medium"
                  fullWidth
                  error={!!errors.iinEmployee}
                  {...register('employeePosition', {
                    required: t('colleagues_modalRequired')
                  })}
                />
                {errors?.employeePosition && errors?.employeePosition.type === "required" && <FormHelperText error>{t('colleagues_modalRequired')}</FormHelperText>}
              </Box>
            </WrapperForm>
            <WrapperForm>
              <Typography children={t('colleagues_employeePhone')}/>
              <Box>
                <TextField
                  multiline
                  size="medium"
                  fullWidth
                  error={!!errors.iinEmployee}
                  {...register('employeePhone', {
                    required: t('colleagues_modalRequired')
                  })}
                />
                {errors?.employeePhone && errors?.employeePhone.type === "required" && <FormHelperText error>{t('colleagues_modalRequired')}</FormHelperText>}
              </Box>
            </WrapperForm>
            <WrapperForm>
              <Typography children={t('colleagues_employeeEmail')}/>
              <Box>
                <TextField
                  multiline
                  size="medium"
                  fullWidth
                  error={!!errors.employeeEmail}
                  {...register('employeeEmail', {
                    required: t('colleagues_modalRequired')
                  })}
                />
                {errors?.employeeEmail && errors?.employeeEmail.type === "required" && <FormHelperText error>{t('colleagues_modalRequired')}</FormHelperText>}
              </Box>
            </WrapperForm>
            <WrapperForm>
              <Typography children={t('colleagues_startDate')}/>
              <Box>
                <TextField
                  multiline
                  size="medium"
                  fullWidth
                  error={!!errors.startDate}
                  {...register('startDate', {
                    required: t('colleagues_modalRequired')
                  })}
                />
                {errors?.startDate && errors?.startDate.type === "required" && <FormHelperText error>{t('colleagues_modalRequired')}</FormHelperText>}
              </Box>
            </WrapperForm> 

            <Box className='flex justify-end mt2'>
              <Button
                color="primary"
                icon={<SaveIcon fontSize="small" className="mr1" />}
                text={t('userModal_save')}
                type='submit'
              />
            </Box>

          </form>
        </div>
      </DialogContent>
    </Modal>
  );
}
