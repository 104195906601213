import { addTranslation } from '../../_helpers/Translate';

addTranslation({
  ru: {
    handbookPage_title: 'Справочники',
    handbookPage_save: 'Сохранить',
    handbookPage_data_title: 'Данные справочника',
    handbookPage_columnDescription: 'Описание',
    handbookPage_columnCode: 'Код',
    handbookPage_columnLastUpdated: 'Последнее обновление',
    handbookPage_columnRuName: 'Наименование на русском языке',
    handbookPage_columnKkName: 'Наименование на казахском  языке',
    handbookPage_columnVersion: 'Версия'
  },
  kk: {
    handbookPage_title: 'Анықтамалар',
    handbookPage_save: 'Сақтау',
    handbookPage_data_title: 'Анықтаманың деректері',
    handbookPage_columnDescription: 'Сипаттамасы',
    handbookPage_columnCode: 'Код',
    handbookPage_columnLastUpdated: 'Соңғы жаңартылым',
    handbookPage_columnRuName: 'Орыс тіліндегі атауы',
    handbookPage_columnKkName: 'Қазақ тіліндегі атауы',
    handbookPage_columnVersion: 'Версиясы'
  }
});
