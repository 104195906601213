import React, { useState } from 'react';
import { Menu, MenuItem, Typography, Switch, Divider } from '@material-ui/core';
import { connect } from 'react-redux';
import { logout } from '../../pages/LoginPage/LoginDucks';
import ExitToApp from '@material-ui/icons/ExitToApp';
import Person from '@material-ui/icons/Person';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import ProfileModal from '../Modals/ProfileModal/ProfileModal';
import ChangePassModal from '../Modals/ChangePassModal/ChangePassModal';
import useModal from '../_hooks/useModal';

const style = {
  marginRight: 10,
  marginBottom: 3,
  color: '#666666',
  fontSize: '1.2rem'
};

function HeaderMenu({ anchorEl, setAnchorEl, t, logout }) {
  const [profileOpened, setProfileOpened] = useState(false);
  const changePassModal = useModal('changePass')

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        style={{ marginTop: 50 }}
        MenuListProps={{ style: { minWidth: 180 } }}
      >
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            setProfileOpened(true);
          }}
        >
          <Person style={style}/>
          <Typography variant="inherit">{t('header_profile')}</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            changePassModal.open(true);
          }}
        >
          <VpnKeyIcon style={style}/>
          <Typography variant="inherit">{t('header_changePass')}</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            logout();
          }}
        >
          <ExitToApp style={style}/>
          <Typography variant="inherit">{t('header_logout')}</Typography>
        </MenuItem>
        <Divider/>
        <div className="px2 pt1">
          <Typography variant="inherit" style={{ marginRight: 10 }}>
            {t('header_darkMode')}
          </Typography>
          <Switch
            size="small"
            checked={localStorage.getItem('themeType') === 'dark'}
            onChange={event => {
              localStorage.setItem('themeType', event.target.checked ? 'dark' : 'light');
              window.location.reload();
            }}
            name="checkedA"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
        </div>
      </Menu>
      {profileOpened && <ProfileModal onClose={() => setProfileOpened(false)}/>}
      {changePassModal.isOpen && <ChangePassModal onClose={changePassModal.close}/>}
    </>
  );
}

export default connect(
  null,
  { logout }
)(HeaderMenu);
