import { createReducer } from 'redux-starter-kit';
import { handleError } from '../../../utils/handleError';
import i18next from 'i18next';
import { ResponsibleApi } from '../../../_helpers/service';

/**
 * Constants
 */

export const responsibleModule = 'responsible';
const LOADING_RESPONSIBLE = `${responsibleModule}/LOADING_TABLE`;
const RESPONSIBLE_LIST = `${responsibleModule}/RESPONSIBLE_LIST`;
const CLEAR = `${responsibleModule}/CLEAR`;

/**
 * Reducer
 */

const initialState = {
  loadingResponsible: false,
  responsibleList: []
};

export default createReducer(initialState, {
  [LOADING_RESPONSIBLE]: (state, action) => {
    state.loadingResponsible = action.payload;
  },
  [RESPONSIBLE_LIST]: (state, action) => {
    state.responsibleList = action.payload;
  },
  [CLEAR]: () => initialState
});

/**
 * Actions
 */

export const clearState = () => ({ type: CLEAR });

export const loadResponsible = serviceProviderId => async dispatch => {
  try {
    dispatch({ type: LOADING_RESPONSIBLE, payload: true });
    let { data } = await ResponsibleApi.loadResponsible(serviceProviderId);
    if (data.status === 'SUCCESS') {
      dispatch({
        type: RESPONSIBLE_LIST,
        payload: data.result
      });
    }
  } catch (error) {
    handleError(error, i18next.t('responsibleDucks_error_load'));
  } finally {
    dispatch({ type: LOADING_RESPONSIBLE, payload: false });
  }
};
