import React, { useEffect, useState } from 'react';
import StaticTablePagination from '../../../_ui/Table/StaticTablePaginated';
import { useTranslation } from 'react-i18next';
import { EnrolledColumns } from './EnrolledColumns';
import { useDispatch, useSelector } from 'react-redux';
import { loadData, contingentModule, clearState } from '../ContingentDucks';
import useTableFilter from '../../../components/TableFilter/useTableFilter';
import {ROLES_ADMIN} from '../../../_helpers/Constants'
import { loginModule } from '../../LoginPage/LoginDucks';
import FilterInputField from '../../../components/TableFilter/ColumnsFilters/FilterInputField';

export default function ContingentEnrolled() { 
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const data = useSelector(state => state[contingentModule].tableData);
  const loadingTable = useSelector(state => state[contingentModule].loadingTable);
  const user = useSelector(state => state[loginModule].user);
  const [isAdmin, setIsAdmin] = useState(false);
  const { nextPage } = useTableFilter(filter =>
    dispatch(loadData({filter, type: 'ENROLLED'}))
  );  
  useEffect(() => {
    dispatch(clearState());
  }, [dispatch]);

  useEffect(() => {
    user.roles.forEach((item) => {
      if (item === ROLES_ADMIN.ES_ADMINS || item === ROLES_ADMIN.ES_GODS) {
        setIsAdmin(true)
      }
    })
  }, [user.roles])

  return (
    <div className='mt1'>
      <StaticTablePagination
        sortable={false}
        loading={loadingTable}
        columns={
          !isAdmin ?
          [
            ...EnrolledColumns({
              t,
              lang: i18n.language,
            }),
          ] :
          [
            ...EnrolledColumns({
              t,
              lang: i18n.language,
            }),
            {
              Header: t('contingentPage_columnBinOrg'),
              accessor: 'bin',
              Filter: <FilterInputField name="bin" type="number" />
            },
          ]
        }
        totalElements={data.totalElements}
        data={data.content}
        urlOnClick={row => `/contingent/item/${row.original.id}`}
        onClickLoadMore={() => nextPage()}
      />
    </div>
  );
}
