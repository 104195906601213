import { createReducer } from 'redux-starter-kit';
import { handleError } from '../../../utils/handleError';
import { BusinessProcessApi } from '../../../_helpers/service';
import i18next from 'i18next';
import { cloneObject } from '../../../passport/Utils/cloneObject';
import moment from 'moment';
import { PROCESS_STATUS, TASK_STATUS } from '../../../_helpers/Constants';

/**
 * Constants
 */

export const bpModule = 'businessProcess';
const LOADING = `${bpModule}/LOADING`;
const SET_DATA = `${bpModule}/SET_DATA`;
const ADD_PROCESS = `${bpModule}/ADD_PROCESS`;
const STOP_PROCESS = `${bpModule}/STOP_PROCESS`;
const CLEAR_STATE = `${bpModule}/CLEAR_STATE`;
const SET_ERROR = `${bpModule}/SET_ERROR`;

/**
 * Reducer
 */

const initialState = {
  loading: true,
  data: null,
  error: null
};

export default createReducer(initialState, {
  [SET_DATA]: (state, action) => {
    state.data = action.payload;
  },
  [ADD_PROCESS]: (state, action) => {
    if (state.data) {
      for (const wf of state.data) {
        if (action.payload.processDefinitionId === wf.processId) {
          wf.instances.unshift(action.payload);
        }
      }
    }
  },
  [STOP_PROCESS]: (state, action) => {
    if (state.data) {
      for (const wf of state.data) {
        if (action.payload.processDefinitionId === wf.processId) {
          for (let instance of wf.instances) {
            if (instance.processInstanceId === action.payload.processInstanceId) {
              instance.status = PROCESS_STATUS.FORCE_COMPLETED;
              instance.endTime = moment().format('DD.MM.YYYY HH:mm');
              if (instance.tasks) {
                for (const task of instance.tasks) {
                  task.status = TASK_STATUS.INTERRUPTED;
                  task.endTime = moment().format('DD.MM.YYYY HH:mm');
                }
              }
            }
          }
        }
      }
    }
  },
  [SET_ERROR]: (state, action) => {
    state.error = action.payload;
  },
  [LOADING]: (state, action) => {
    state.loading = action.payload;
  },
  [CLEAR_STATE]: () => initialState
});

/**
 * Actions
 */

export const loadData = (boData, boMeta) => async dispatch => {
  try {
    dispatch({ type: LOADING, payload: true });
    const { data } = await BusinessProcessApi.loadData(boData.id);
    const cc = cloneObject(boMeta.customConfigs) || [];
    const wd = cc.find(item => item['@type'] === 'WorkflowMetadataConfig');
    if (wd && wd.workflowDefinitions) {
      const workflow = wd.workflowDefinitions.map(item => {
        item.instances = data.result.filter(
          i => i.processDefinitionId === item.processId
        );
        return item;
      });
      workflow.forEach(w => {
        w.instances.forEach(i => {
          if (i.startTime) i.startTime = moment(i.startTime).format('DD.MM.YYYY HH:mm');
          if (i.endTime) i.endTime = moment(i.endTime).format('DD.MM.YYYY HH:mm');
          if (i.tasks) {
            for (const task of i.tasks) {
              if (task.startTime) task.startTime = moment(task.startTime).format('DD.MM.YYYY HH:mm');
              if (task.endTime) task.endTime = moment(task.endTime).format('DD.MM.YYYY HH:mm');
            }
          }
        });
      });
      dispatch({ type: SET_DATA, payload: workflow });
    }
  } catch (error) {
    let msg = handleError(error, i18next.t('businessProcess_errorLoadData'));
    dispatch({ type: SET_ERROR, payload: msg });
  } finally {
    dispatch({ type: LOADING, payload: false });
  }
};

export const startProcess = (id, boId, close) => async dispatch => {
  try {
    dispatch({ type: LOADING, payload: true });
    const response = await BusinessProcessApi.startProcess(id, boId);
    const instance = response.data.result;
    if (instance.startTime)
      instance.startTime = moment(instance.startTime).format('DD.MM.YYYY HH:mm');
    if (instance.endTime)
      instance.endTime = moment(instance.endTime).format('DD.MM.YYYY HH:mm');
    if (instance.tasks) {
      for (const task of instance.tasks) {
        if (task.startTime) task.startTime = moment(task.startTime).format('DD.MM.YYYY HH:mm');
        if (task.endTime) task.endTime = moment(task.endTime).format('DD.MM.YYYY HH:mm');
      }
    }
    dispatch({ type: ADD_PROCESS, payload: instance });
    if (close) close();
  } catch (error) {
    handleError(error, i18next.t('businessProcess_errorStartProcess'));
  } finally {
    dispatch({ type: LOADING, payload: false });
  }
};

export const stopProcess = (instance, close) => async dispatch => {
  try {
    dispatch({ type: LOADING, payload: true });
    await BusinessProcessApi.stopProcess(instance.processInstanceId);
    dispatch({ type: STOP_PROCESS, payload: instance });
    if (close) close();
  } catch (error) {
    handleError(error, i18next.t('businessProcess_errorStopProcess'));
  } finally {
    dispatch({ type: LOADING, payload: false });
  }
};

export const stopTask = (id, boMeta, boData, close) => async dispatch => {
  try {
    dispatch({ type: LOADING, payload: true });
  } catch (error) {
    handleError(error, i18next.t('businessProcess_errorStopTask'));
  } finally {
    dispatch({ type: LOADING, payload: false });
  }
};

export const clearState = () => ({ type: CLEAR_STATE });
