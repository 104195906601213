import React from 'react';
import ReactDOM from 'react-dom';
import App from './_app/App';
import * as serviceWorker from './serviceWorker';
import './_helpers/CheckingNumbersTabs';
import 'react-table/react-table.css';
import 'react-notifications-component/dist/scss/notification.scss';
import 'animate.css/animate.min.css';
import './_css/style.scss';
import './_css/basscss.min.css';

ReactDOM.render(<App />, document.getElementById('root'));
serviceWorker.unregister();
console.info(
  '%c%s',
  'color: #007D77; background-color: black;',
  `
                                                                                           
                                                                                           
      █████╗   █████╗   █████╗         ██╗  ██╗  █████╗  ██╗     ██╗  ██╗  █████╗  ██╗     
     ██╔══██╗ ██╔══██╗ ██╔══██╗        ██║  ██║ ██╔══██╗ ██║     ██║  ██║ ██╔══██╗ ██║     
     ██║  ╚═╝ ██║  ██║ ██║  ╚═╝        ██║  ██║ ██║  ╚═╝ ██║     ██║  ██║ ██║  ╚═╝ ██║     
     ██║      ██║  ██║ ╚█████╗   ████╗ ██║  ██║ ╚█████╗  ██║     ██║  ██║ ██║      ██║     
     ██║ ███╗ ██║  ██║  ╚═══██╗  ╚═══╝ ██║  ██║  ╚═══██╗ ██║     ██║  ██║ ██║ ███╗ ██║     
     ██║ ╚██║ ██║  ██║ ██╗  ██║        ██║  ██║ ██╗  ██║ ██║     ██║  ██║ ██║ ╚██║ ██║     
     ╚█████╔╝ ╚█████╔╝ ╚█████╔╝        ╚█████╔╝ ╚█████╔╝ ██████╗ ╚█████╔╝ ╚█████╔╝ ██║     
      ╚════╝   ╚════╝   ╚════╝          ╚════╝   ╚════╝  ╚═════╝  ╚════╝   ╚════╝  ╚═╝     
                                                                                           
     А В Т О М А Т И З А Ц И Я         Г О С У Д А Р С Т В Е Н Н Ы Х         У С Л У Г 
     
     test deploy    
                                                                                           
`
);
