import { createReducer } from 'redux-starter-kit';
import { handleError } from '../../utils/handleError';
import { TaskApi } from '../../_helpers/service';
import i18next from 'i18next';
import Dict from '../../utils/Dict';
import { history } from '../../_helpers/history';
import paths from '../../_helpers/paths';
import Notice from '../../utils/Notice';
import { TASK_TYPE } from '../../_helpers/Constants';

/**
 * Constants
 */

export const myTaskModule = 'myTask';
const LOADING_TABLE = `${myTaskModule}/LOADING_TABLE`;
const TABLE_DATA = `${myTaskModule}/TABLE_DATA`;
const CLEAR_STATE = `${myTaskModule}/CLEAR_STATE`;

/**
 * Reducer
 */

const initialState = {
  loadingTable: false,
  tableData: {
    content: [],
    totalElements: 0
  }
};

export default createReducer(initialState, {
  [LOADING_TABLE]: (state, action) => {
    state.loadingTable = action.payload;
  },
  [TABLE_DATA]: (state, action) => {
    state.tableData = action.payload;
  },
  [CLEAR_STATE]: () => initialState
});

/**
 * Actions
 */

export const clearState = () => ({ type: CLEAR_STATE });

export const loadTableData = filter => async dispatch => {
  try {
    dispatch({ type: LOADING_TABLE, payload: true });
    const filters = JSON.stringify({
      ...filter,
      showUnassigned: true,
      businessObjectType: 'request_form'
    });
    let { data } = await TaskApi.loadTasks(filters);
    if (data.status === 'SUCCESS') {
      const dictServices = await Dict.itemsObject('gu_services');
      for (const row of data.result.result.content) {
        row.serviceTypeName = dictServices[row.serviceType] || {};
      }
      dispatch({ type: TABLE_DATA, payload: data.result.result, filter });
    } else {
      handleError(data, i18next.t('myTaskPage_errorLoadTable'));
    }
  } catch (error) {
    handleError(error, i18next.t('myTaskPage_errorLoadTable'));
  } finally {
    dispatch({ type: LOADING_TABLE, payload: false });
  }
};

export async function forwardToNextTask(processInstanceId, user, params, showNotice) {
  try {
    const response = await TaskApi.loadTaskByProcessId(processInstanceId);
    const newTask = response.data?.result?.content[0];
    console.log('newTasknewTask', newTask, user)
    if (newTask.metadataKey === 'request_form_doc_queued_ddo') {
      if (newTask.assignee) {
        if (params.selectedUsername && user.username !== params.selectedUsername) {
          Notice.success(i18next.t('myTaskPage_selectedUsername', newTask));
          return;
        } else if (newTask.assignee.userId === user.username) {
          history.push(
            `${paths.taskPage.replace(':id', newTask.id)}?taskType=${newTask.type}`
          );
        } else if (params.isSign) {
          Notice.success(i18next.t('signTask_successRejectExec', newTask));
        }
      } else if (newTask.type === TASK_TYPE.SIGN_BUSINESS_OBJECT) {
        if (newTask?.configuration?.candidateGroups.some(i => user.roles.includes(i))) {
          history.push(
            `${paths.taskPage.replace(':id', newTask.id)}?taskType=${newTask.type}`
          );
        } else {
          Notice.success(i18next.t('myTaskPage_toSign', newTask));
          return;
        }
      }
    }
    showNotice();
  } catch (ignore) {}
}
