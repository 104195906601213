import { createReducer } from 'redux-starter-kit';
import { HandbookApi } from '../../_helpers/service';
import { handleError } from '../../utils/handleError';
import i18next from 'i18next';

/**
 * Constants
 */

export const handbookModule = 'handbookModule';
const LOADING_TABLE = `${handbookModule}/LOADING_TABLE`;
const TABLE_DATA = `${handbookModule}/TABLE_DATA`;
const CLEAR_STATE = `${handbookModule}/CLEAR_STATE`;

/**
 * Reducer
 */

const initialState = {
  loadingTable: false,
  tableData: []
};

export default createReducer(initialState, {
  [LOADING_TABLE]: (state, action) => {
    state.loadingTable = action.payload;
  },
  [TABLE_DATA]: (state, action) => {
    state.tableData = action.payload;
  },
  [CLEAR_STATE]: () => initialState
});

/**
 * Actions
 */

export const clearState = () => ({ type: CLEAR_STATE });

export const loadHandbook = () => async dispatch => {
  try {
    dispatch({ type: LOADING_TABLE, payload: true });
    let { data } = await HandbookApi.loadHandbook();
    const handbook = [];
    for (let item of data.result) {
      item.code.indexOf('gu_') !== -1 ? handbook.unshift(item) : handbook.push(item);
    }
    dispatch({ type: TABLE_DATA, payload: handbook });
  } catch (error) {
    handleError(error, i18next.t('monitoringDucks_errorLoadTable'));
  } finally {
    dispatch({ type: LOADING_TABLE, payload: false });
  }
};
