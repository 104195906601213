import React, {useState} from 'react';
import Modal from '../../_ui/Modal/Modal';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import Button from '../../_ui/Button/Button';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DoneIcon from '@material-ui/icons/Done';
import { Color } from '../ThemeProvider/ThemeStyle';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import MuiSelect from '@material-ui/core/Select';
import moment from 'moment';

export default function ModalReportCard(modalData) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [causeCode, setCauseCode] = useState(modalData.data?.absenceReason);
  const [isErrorCause, setIsErrorCause] = useState(false);
  
  const save = () => {
    if (causeCode) {
      dispatch(modalData.saveCause({ 
        causeCode, 
        userID: modalData.data?.userID, 
        logDate: modalData.data.logDate, 
        close: modalData.close 
      }))
    } else {
      setIsErrorCause(true)
    }
  }

  return (
    <Modal
      title={modalData.data.isVisited ? t('pageReport_arrivalDate') : t('pageReport_reportModalTitle')}
      open
      onClose={modalData.close}
    >
      <DialogContent>
        {
          modalData.data.isVisited === false &&
          <>
            <Box className='flex items-center'>
              <Typography children={t('pageReport_reportCause')} style={{width: '50%'}} />
              <Box 
                style={{
                  width: '100%',
                  maxWidth: '100%',
                }}
              >
                <FormControl fullWidth>
                  <MuiSelect value={causeCode} error={isErrorCause}>
                    {
                      modalData.cause.map((item) => {
                        return <MenuItem 
                          value={item.code} 
                          onClick={() => {
                            setCauseCode(item.code)
                            setIsErrorCause(false)
                          }} 
                          key={item.code}
                        >
                          {item[`${i18n.language}_name`]}
                        </MenuItem>
                      })
                    }
                  </MuiSelect>
                  {isErrorCause && <FormHelperText error>{t('pageReport_requiredField')}</FormHelperText>}   
                </FormControl>
              </Box>
            </Box>
            <Box className="flex justify-end pt2">
              <Button
                color="primary"
                style={{ backgroundColor: Color.bgColorSuccess }}
                loading={false}
                icon={<DoneIcon fontSize="small" className="mr1" />}
                text={ t('pageReport_saveBtn') }
                type='submit'
                onClick={() => save()}
              />
            </Box>
          </>
        }
        {
          modalData.data.isVisited === true &&
            <Box className='flex items-center mb2'>
              <Typography children={t('pageReport_scanTime')} style={{width: '50%'}} />
              <Typography children={moment(modalData.data.visitingTime).format('YYYY-MM-DD HH:mm:ss')} style={{width: '50%'}} />
            </Box>
        }
      </DialogContent>
    </Modal>
  );
}
