import { addTranslation } from '../../_helpers/Translate';

addTranslation({
  ru: {
    administrationPage_title: 'Управление пользователями',
    administrationPage_createUser: 'Создать пользователя',
    administrationPage_import: 'Импорт',
    administrationPage_columnID: 'ID',
    administrationPage_columnIin: 'ИИН',
    administrationPage_columnBin: 'БИН организации',
    administrationPage_columnLastName: 'Фамилия',
    administrationPage_columnFirstName: 'Имя',
    administrationPage_columnMiddleName: 'Отчество',
    administrationPage_columnUsername: 'Логин',
    administrationPage_columnEmail: 'Email',
    administrationPage_columnRole: 'Роль',
    administrationPage_columnOrganization: 'Организация (подразделение)',
    administrationPage_columnPosition: 'Должность',
    administrationPage_columnPhoneNumber: 'Номер телефона',
    administrationPage_columnCreatedDate: 'Дата создания',
    administrationPage_columnUpdatedDate: 'Дата изменения',
    administrationPage_columnStatus: 'Активация/Деактивация',
    administrationPage_columnAction: 'Действие',
    administrationPage_columnDelete: 'Удалить пользователя',
    administrationPage_deleteUser: 'Удалить пользователя',
    administrationPage_delete: 'Удалить',
    administrationPage_cancel: 'Отмена',
    administrationPage_choose: 'Выбрать',
    administrationPage_upload: 'Загрузить файл',
    administrationPage_confirmation: 'Вы уверены что хотите удалить пользователя',
    administrationPage_heir_choose_sp: 'Выберите услугодателя',
    administrationDucks_errorLoadTable: 'Не удалось загрузить пользователей',
    administrationDucks_errorCreateUser: 'Не удалось создать пользователя',
    administrationDucks_userCreated: 'Пользователь успешно создан!',
    administrationDucks_errorChangeUser: 'Не удалось обновить данные пользователя!',
    administrationDucks_userChanged: 'Изменения успешно сохранены!',
    administrationDucks_errorActivateUser:
      'Не удалось активировать/деактивировать пользователя!',
    administrationDucks_errorDeleteUser: 'Не удалось удалить пользователя!',
    administrationDucks_errorImport: 'Не удалось выполнить импорт!',
    administrationDucks_imported: 'Импорт выполнен успешно!',
    administrationDucks_notImported: 'Не удалось сделать импорт по следующим пользователям',
    administrationDucks_errorChangePassword: 'Не удалось сменить пароль, введенный вами текущий пароль неверный',
  },
  kk: {
    administrationPage_title: 'Пайдаланушыларды басқару',
    administrationPage_createUser: 'Пайдаланушыны құру',
    administrationPage_import: 'Импорт',
    administrationPage_columnID: 'ID',
    administrationPage_columnIin: 'ЖСН',
    administrationPage_columnBin: 'Ұйымның БСН',
    administrationPage_columnLastName: 'Тегі',
    administrationPage_columnFirstName: 'Аты',
    administrationPage_columnMiddleName: 'Әкесінің аты',
    administrationPage_columnUsername: 'Логин',
    administrationPage_columnEmail: 'Email',
    administrationPage_columnRole: 'Рөлі',
    administrationPage_columnOrganization: 'Ұйым(бөлімше)',
    administrationPage_columnPosition: 'Лауазымы',
    administrationPage_columnPhoneNumber: 'Телефон нөмірі',
    administrationPage_columnCreatedDate: 'Құрылған күні',
    administrationPage_columnUpdatedDate: 'Өзгерту күні',
    administrationPage_columnStatus: 'Белсендіру/Белсендірмеу',
    administrationPage_columnAction: 'Әрекет',
    administrationPage_columnDelete: 'Пайдаланушыны жою',
    administrationPage_deleteUser: 'Пайдаланушыны жою',
    administrationPage_delete: 'Жою',
    administrationPage_cancel: 'Болдырмау',
    administrationPage_choose: 'Выбрать',
    administrationPage_upload: 'Загрузить файл',
    administrationPage_confirmation: 'Пайдаланушыны жоюға сенімдісіз бе?',
    administrationPage_heir_choose_sp: 'Қызмет көрсетушіні таңдаңыз',
    administrationDucks_errorLoadTable: 'Пайдаланушылар жүктеу мүмкін емес',
    administrationDucks_errorCreateUser: 'Пайдаланушыны құру мүмкін емес!',
    administrationDucks_userCreated: 'Пайдаланушы сәтті құрылды!',
    administrationDucks_errorChangeUser: 'Өзгерістерді сақтау мүмкін емес!',
    administrationDucks_userChanged: 'Өзгерістер сәтті сақталды!',
    administrationDucks_errorActivateUser:
      'Пайдаланушыны белсендіру/белсендірмеу мүмкін емес!',
    administrationDucks_errorDeleteUser: 'Пайдаланушыны жою мүмкін емес!',
    administrationDucks_errorImport: 'Не удалось выполнить импорт!',
    administrationDucks_imported: 'Импорт выполнен успешно!',
    administrationDucks_notImported: 'Не удалось сделать импорт по следующим пользователям',
    administrationDucks_errorChangePassword: 'Құпия сөзді өзгерту мүмкін болмады, сіз енгізген құпия сөз дұрыс емес',

  }
});
