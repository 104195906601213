import { addTranslation } from '../../_helpers/Translate';

addTranslation({
  ru: {
    kezekDirection_number: 'Номер направления',
    kezekDirection_submissionDate: 'Дата направления',
    kezekDirection_time24Direction: 'Срок резервирования',
    kezekDirection_bulletin: 'Номер и дата бюллетени',
    kezekDirection_requestForm: 'Номер и дата заявки',
    kezekDirection_directionDate: 'Срок направления',
    kezekDirection_providerId: 'Номер услугодателя',
    kezekDirection_BIN: 'БИН услугодателя',
    kezekDirection_serviceProviderName: 'Наименование организации',
    kezekDirection_providerLocation: 'Населенный пункт',
    kezekDirection_columnStatusWeb: 'Статус направления',
    kezekDirection_columnStatusDWeb: 'Статус направлений на Портале',
    kezekDirection_errorLoad: 'Не удалось загрузить данные направления',

    kezekDirection_revoke: 'Отозвать направление',
    kezekDirection_createRevokeTitle: 'Отзыв направления',
    kezekDirection_createRevokeText: 'При отзыве, данное направление будет аннулировано и ребенок будет восстановлен в очередь',
    kezekDirection_kinderGarden: 'Заявление',
    kezekDirection_kinderGardenView: 'Посмотреть заявку',
    kezekDirection_reasonDeletion: 'Причина отзыва свободных мест',
    kezekDirection_reasonDeletionTextRu: 'Причина на русском',
    kezekDirection_reasonDeletionTextKk: 'Причина на казахском',
    kezekDirection_requiredField: 'Обязательное для заполнения',

    kezekDirection_revokeSuccess: 'Направление успешно отозвано',
    kezekDirection_revokeError: 'Ошибка при отзыве направления',
    kezekDirection_approveSuccess: 'Прибытие по направлению успешно подтверждена ',
    kezekDirection_approveError: 'Ошибка при подтверждении о прибытии',
    kezekDirection_approveTitle: 'Подтверждение о прибытии',
    kezekDirection_approveQuestion: 'Подтверждена льгота ВО/ПО ?',
    kezekDirection_approveText: 'При нажатии кнопки "Нет", ребенок будет восстановлен в очередь без льгот',
    kezekDirection_approveYes: 'Подтверждаю',
    kezekDirection_approveYesPrev: 'Да, подтверждено',
    kezekDirection_approveNo: 'Нет',
    kezekDirection_close: 'Закрыть',
  },
  kk: {
    kezekDirection_number: 'Жолдама нөмірі',
    kezekDirection_submissionDate: 'Жолдама күні',
    kezekDirection_time24Direction: 'Брондау мерзімі',
    kezekDirection_bulletin: 'Бюллетен нөмірі мен күні',
    kezekDirection_requestForm: 'Өтініш нөмірі мен күні',
    kezekDirection_directionDate: 'Жолдама мерзімі',
    kezekDirection_providerId: 'Қызмет көрсетушінің нөмірі',
    kezekDirection_BIN: 'Қызмет көрсетушінің БСН',
    kezekDirection_serviceProviderName: 'Ұйымның атауы',
    kezekDirection_providerLocation: 'Елді мекен',
    kezekDirection_columnStatusWeb: 'Жолдама мәртебесі',
    kezekDirection_columnStatusDWeb: 'Порталдағы Жолдама мәртебесі',
    kezekDirection_errorLoad: 'Жолдама деректерін жүктеу мүмкін болмады',

    kezekDirection_revoke: 'Жолдаманы кері Қайтару',
    kezekDirection_createRevokeTitle: 'Жолдаманы кері Қайтару',
    kezekDirection_createRevokeText: 'Кері қайтарып алу кезінде  жолдаманың күші жойылады және бала кезекке қайтарылады',
    kezekDirection_kinderGarden: 'Мәлімдеме',
    kezekDirection_kinderGardenView: 'Өтінішті көру',
    kezekDirection_reasonDeletion: 'Орындарды жою себебі',
    kezekDirection_reasonDeletionTextRu: 'Себебі орыс тілінде',
    kezekDirection_reasonDeletionTextKk: 'Себебі қазақ тілінде',
    kezekDirection_requiredField: 'Міндетті өріс',

    kezekDirection_revokeSuccess: 'Жолдама сәтті кері Қайтарылды',
    kezekDirection_revokeError: 'Жолдаманы кері Қайтару сәтсіз аяқталды',
    kezekDirection_approveSuccess: 'Жолдама бойынша келуді растау сәтті аяқталды',
    kezekDirection_approveError: 'Келуді растау сәтсіз аяқталды',
    kezekDirection_approveTitle: 'Келуді растау',
    kezekDirection_approveQuestion: 'Кезектен тыс/бірінші кезекті орын алу құқығы бойынша жеңілдігі расталды ма?',
    kezekDirection_approveText: '«Жоқ» түймесін бассаңыз, бала жеңілдіксіз кезекке қайтадан қайтарылады',
    kezekDirection_approveYes: 'Иә, расталды',
    kezekDirection_approveNo: 'Жоқ',
    kezekDirection_close: 'Жабу',
  }
});
