import React from 'react';
import Modal from '../../../_ui/Modal/Modal';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import Button from '../../../_ui/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DoneIcon from '@material-ui/icons/Done';
import { Color } from '../../../components/ThemeProvider/ThemeStyle';
import {
  getFormValues,
} from '../../../passport/Passport';
import Typography from '@material-ui/core/Typography';
import { useHistory } from "react-router-dom";
import { groupPassportModule, saveGroup } from '../GroupPassportDucks';

export default function ModalConfirmation(data) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { loadingSave } = useSelector(
    state => state[groupPassportModule]
  );
  return (
    <Modal
      title={t('userModal_save') }
      open
      onClose={data.close}
    >
      <DialogContent>
        {
          data?.groupId &&
          <Typography>
            {t('groupPage_changeAgeGroupInfo')}
          </Typography>
        }
        <div className="flex justify-end pb2">
          <Button
            color="primary"
            style={{ backgroundColor: Color.bgColorSuccess }}
            icon={<DoneIcon fontSize="small" className="mr1" />}
            text={ t('groupPage_confirm') }
            disabled={loadingSave}
            onClick={() => {
              getFormValues().then(values => {
                if (values) {
                 dispatch(saveGroup(values, data.groupId, history, data.valueEducators));
                }
              });
            }}
          />
        </div>
      </DialogContent>
    </Modal>
  );
}
