import moment from 'moment';
import React from 'react';
import FilterInputField from '../../components/TableFilter/ColumnsFilters/FilterInputField';
import FilterDatePicker from '../../components/TableFilter/ColumnsFilters/FilterDatePicker';
import ColumnSorting from '../../components/TableFilter/ColumnsFilters/ColumnSorting';
import FilterSelect from '../../components/TableFilter/ColumnsFilters/FilterSelect';
import FilterTreeSelect from '../../components/TableFilter/ColumnsFilters/FilterTreeSelect/FilterTreeSelect';

export function kezekDirectionTableColumns({ t, lang }) {
  return [
    {
      Header: '№',
      accessor: 'rowNumber',
      width: 60,
      filterable: false
    },
    {
      Header: <ColumnSorting title={t('kezekDirectionTable_columnID')} name="id" />,
      accessor: 'id',
      width: 80,
      style: { textAlign: 'center' },
      Filter: <FilterInputField name="id" type="number" />
    },
    {
      Header: (
        <ColumnSorting
          title={t('kezekDirectionTable_columnDate')}
          name="submissionDate"
        />
      ),
      accessor: 'submissionDate',
      Cell: row =>
        row.original.submissionDate
          ? moment(row.original.submissionDate).format('DD.MM.YYYY HH:mm')
          : '',
      width: 120,
      sortable: false,
      style: { textAlign: 'center' },
      Filter: <FilterDatePicker name="submissionDate" lang={lang} />
    },
    {
      Header: t('kezekDirectionTable_childIIN'),
      accessor: 'childIin',
      style: { textAlign: 'center' },
      Filter: <FilterInputField name="childIin" type="number" />
    },
    {
      Header: t('kezekDirectionTable_childName'),
      accessor: 'childName',
      style: { textAlign: 'center' },
      Filter: <FilterInputField name="childFullName" />
    },
    {
      Header: (
        <ColumnSorting title={t('kezekDirectionTable_columnAgeGroup')} name="ageGroup" />
      ),
      accessor: 'ageGroup ',
      Cell: ({ original: { ageGroupName } }) => ageGroupName[`${lang}_name`],
      Filter: <FilterSelect name="ageGroup" dictCode="gu_age_group" lang={lang} />
    },
    {
      Header: (
        <ColumnSorting
          title={t('kezekDirectionTable_columnQueuedType')}
          name="queuedType"
        />
      ),
      accessor: 'queuedType ',
      Cell: ({ original: { queuedTypeName } }) => queuedTypeName[`${lang}_name`],
      Filter: <FilterSelect name="queuedType" dictCode="gu_queued_type" lang={lang} />
    },
    {
      Header: (
        <ColumnSorting
          title={t('kezekDirectionTable_time24Direction')}
          name="time24Direction"
        />
      ),
      accessor: 'time24Direction',
      Cell: row =>
        row.original.time24Direction
          ? moment(row.original.time24Direction).format('DD.MM.YYYY HH:mm')
          : '',
      width: 120,
      sortable: false,
      style: { textAlign: 'center' },
      Filter: <FilterDatePicker name="time24Direction" lang={lang} />
    },
    {
      Header: (
        <ColumnSorting
          title={t('kezekDirectionTable_directionDate')}
          name="directionDate"
        />
      ),
      accessor: 'directionDate',
      Cell: row =>
        row.original.directionDate
          ? moment(row.original.directionDate).format('DD.MM.YYYY HH:mm')
          : '',
      width: 120,
      sortable: false,
      style: { textAlign: 'center' },
      Filter: <FilterDatePicker name="directionDate" lang={lang} />
    },
    {
      Header: (
        <ColumnSorting title={t('kezekDirectionTable_status')} name="statusDirection" />
      ),
      accessor: 'statusDirection ',
      Cell: ({ original: { statusDirectionName } }) =>
        statusDirectionName[`${lang}_name`],
      Filter: (
        <FilterSelect name="statusDirection" dictCode="gu_status_direction" lang={lang} />
      )
    },
    {
      Header: t('kezekDirectionTable_bulletin'),
      accessor: 'bulletinSubmissionDate',
      Cell: ({ original: { bulletinSubmissionDate, bulletinId } }) =>
        `${bulletinId} от ${
          bulletinSubmissionDate
            ? moment(bulletinSubmissionDate).format('DD.MM.YYYY HH:mm')
            : ''
        }`,
      style: { textAlign: 'center' },
      Filter: <FilterInputField name="bulletinId" type="number" />
    },
    {
      Header: t('kezekDirectionTable_columnServiceProviderLocation'),
      accessor: 'serviceProviderLocation',
      Cell: ({ original: { serviceProviderLocation } }) =>
        serviceProviderLocation[`${lang}_name`],
      Filter: (
        <FilterTreeSelect lookupId="60" name="serviceProviderLocation" lang={lang} />
      )
    },
    {
      Header: t('kezekDirectionTable_columnBin'),
      accessor: 'serviceProviderBin',
      style: { textAlign: 'center' },
      Filter: <FilterInputField name="serviceProviderBin" type="number" />
    },
  ];
}
