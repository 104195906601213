import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography } from '@material-ui/core';
import {  useSelector } from 'react-redux';
import { compose } from 'redux';
import withTranslation from '../_hoc/withTranslation';
import { loginModule } from '../../pages/LoginPage/LoginDucks';
import Button from '@material-ui/core/Button';
import { history } from '../../_helpers/history';
import paths from '../../_helpers/paths';
import AccountCircle from '@material-ui/icons/AccountCircle';
import HeaderMenu from './HeaderMenu';
import { CompanyName, useStyles } from './HeaderStyle';
import { Link } from 'react-router-dom';
import i18next from 'i18next';
import config from '../../_helpers/config';
import GlobalMessage from '../GlobalMessage';
import './HeaderTranslate';
import { StyledButton } from './SideBar/SideBarStyle';
import MenuIcon from '@material-ui/icons/Menu';

function Header({ t, lang, showSideBar, setShowSideBar }) {
  const user = useSelector(state => state[loginModule].user);
  const [anchorEl, setAnchorEl] = useState(null);
  const { langBtnStyle,organizationName } = useStyles();
  useEffect(() => {
    let lsLang = localStorage.getItem('lang');
    if (lsLang && lsLang !== lang) {
      i18next.changeLanguage(lsLang);
    } else {
      localStorage.setItem('lang', lang);
    }
  }, [lang]);

  return (
    <>
      <AppBar position="fixed" elevation={1} color="default" style={{ zIndex: 1300 }}>
        <Toolbar style={{ padding: 0 }}>
          <StyledButton
            color={showSideBar ? 'primary' : 'default'}
            variant={showSideBar ? 'contained' : undefined}
            onClick={() => setShowSideBar(!showSideBar)}
            children={<MenuIcon />}
          />
          <CompanyName>
            <Link to={paths.homePage} style={{ color: 'inherit' }}>
              <Typography
                style={{ textTransform: 'uppercase', fontWeight: 600, marginTop: 2 }}
                children={config.companyName[lang]}
              />
             {user && <Typography
                title={user.organization[`${i18next.language}_name`]}
                className={organizationName}
                children={user.organization[`${i18next.language}_name`]}
              />}
            </Link>
          </CompanyName>

          {user ? (
            <Button
              color="inherit"
              style={{ height: 64 }}
              onClick={event => setAnchorEl(event.currentTarget)}
              size="large"
            >
              <Typography color="inherit">
                {user.firstName} {user.lastName}
              </Typography>
              <AccountCircle color="action" style={{ marginLeft: 15 }} />
            </Button>
          ) : (
            <Button
              style={{ height: 64 }}
              onClick={() => history.push(paths.loginPage)}
              children={t('header_login')}
            />
          )}
          <Button
            className={langBtnStyle}
            color={lang === 'kk' ? 'primary' : 'default'}
            variant={lang === 'kk' ? 'contained' : undefined}
            style={
              lang === 'kk' ? undefined : { backgroundColor: 'rgba(125,125,125,0.25)' }
            }
            onClick={() => {
              i18next.changeLanguage('kk');
              localStorage.setItem('lang', 'kk');
            }}
            children="ҚАЗ"
          />
          <Button
            className={langBtnStyle}
            color={lang === 'ru' ? 'primary' : 'default'}
            variant={lang === 'ru' ? 'contained' : undefined}
            style={
              lang === 'ru' ? undefined : { backgroundColor: 'rgba(125,125,125,0.25)' }
            }
            onClick={() => {
              i18next.changeLanguage('ru');
              localStorage.setItem('lang', 'ru');
            }}
            children="РУС"
          />
          <HeaderMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} t={t} />
        </Toolbar>
      </AppBar>
      <GlobalMessage />
    </>
  );
}

export default compose(withTranslation)(Header);
