import { addTranslation } from '../../_helpers/Translate';

addTranslation({
  ru: {
    myTaskPage_title: 'Мои задачи',
    myTaskPage_handle_btn: 'Обработать',
    myTaskPage_columnTaskNumber: 'Номер задачи',
    myTaskPage_columnName: 'Наименование',
    myTaskPage_columnStatementId: 'Номер заявки',
    myTaskPage_columnStatementType: 'Тип заявки',
    myTaskPage_columnStatus: 'Статус',
    myTaskPage_columnStartTime: 'Начала выполнения',
    myTaskPage_columnEndTime: 'Время окончания',
    myTaskPage_filterTaskNumber: 'Номер заявки',
    myTaskPage_filterTaskStatus: 'Статус',
    myTaskPage_errorLoadTable: 'Не удалось загрузить список задач',
    myTaskPage_selectedUsername: 'Заявка № {{businessObjectId}} направлена ответственному исполнителю',
    myTaskPage_toSign: 'Заявка № {{businessObjectId}} направлена на подпись руководителю',

    myTaskPage_columnStatementNumber: 'Номер заявки',
    myTaskPage_columnIin: 'ИИН заявителя',
    myTaskPage_columnBin: 'БИН услугодателя',
    myTaskPage_columnFullName: 'ФИО заявителя',
    myTaskPage_columnDate: 'Дата заявки',
    myTaskPage_columnOnlyMyTask: 'Только назначенные на меня',
    myTaskPage_columnDateFrom: 'от',
    myTaskPage_columnDateTo: 'до',
    myTaskPage_columnExternalId: 'Номер ПЭП ',
    myTaskPage_columnResponsible: 'Ответственный ',
    myTaskPage_appoint_responsible: 'Назначить ответственного',
    myTaskPage_requiredFields: 'Вам необходимо заполнить все обязательные поля',
  },
  kk: {
    myTaskPage_title: 'Менің міндеттерім',
    myTaskPage_handle_btn: 'Өңдеу',
    myTaskPage_columnTaskNumber: 'Тапсырманың нөмірі',
    myTaskPage_columnName: 'Атауы',
    myTaskPage_columnStatementId: 'Өтінім нөмірі',
    myTaskPage_columnStatementType: 'Өтінім түрі',
    myTaskPage_columnStatus: 'Мәртебесі',
    myTaskPage_columnStartTime: 'Орындаудың басталуы',
    myTaskPage_columnEndTime: 'Аяқталу уақыты',
    myTaskPage_filterTaskNumber: 'Өтінім нөмірі',
    myTaskPage_filterTaskStatus: 'Статус',
    myTaskPage_errorLoadTable: 'Тапсырмалар тізімін жүктеу мүмкін емес',
    myTaskPage_selectedUsername: '№ {{businessObjectId}} өтініш жауапты тұлғаға жіберілді',
    myTaskPage_toSign: '№ {{businessObjectId}} өтініш басшыға қол қоюға жіберілді',

    myTaskPage_columnStatementNumber: 'Өтінім нөмірі',
    myTaskPage_columnIin: 'ЖСН',
    myTaskPage_columnBin: 'БСН',
    myTaskPage_columnFullName: 'ТӘА',
    myTaskPage_columnDate: 'Күні, уақыты',
    myTaskPage_columnOnlyMyTask: 'Тек маған тағайындалғандар',
    myTaskPage_columnDateFrom: 'күннен бастап',
    myTaskPage_columnDateTo: 'күнге дейін',
    myTaskPage_columnExternalId: 'ЭҮП нөмірі',
    myTaskPage_columnResponsible: 'Жауапты',
    myTaskPage_appoint_responsible: 'Жауаптыны тағайындау',
    myTaskPage_requiredFields: 'Сізге барлық қажетті жолдарды толтыру қажет',
  }
});
