import React from 'react';
import FilterInputField from '../../components/TableFilter/ColumnsFilters/FilterInputField';
import ColumnSorting from '../../components/TableFilter/ColumnsFilters/ColumnSorting';
import FilterSelect from '../../components/TableFilter/ColumnsFilters/FilterSelect';
import FilterTreeSelect from '../../components/TableFilter/ColumnsFilters/FilterTreeSelect/FilterTreeSelect';
import moment from 'moment';
import FilterDatePicker from '../../components/TableFilter/ColumnsFilters/FilterDatePicker';

export function kezekArchiveColumns({ t, lang, isAdmin }) {
  return [
    {
      Header: '№',
      accessor: 'rowNumber',
      width: 60,
      filterable: false
    },
    {
      Header: <ColumnSorting title={t('kezekArchive_columnID')} name="requestFormId" />,
      accessor: 'requestFormId',
      width: 80,
      style: { textAlign: 'center' },
      Filter: <FilterInputField name="requestFormId" type="number" />
    },
    {
      Header: t('kezekArchive_childIIN'),
      accessor: 'childIin',
      style: { textAlign: 'center' },
      Filter: <FilterInputField name="childIin" type="number" />
    },
    {
      Header: t('kezekArchive_childName'),
      accessor: 'childName',
      style: { textAlign: 'center' },
      Filter: <FilterInputField name="childFullName" />
    },
    {
      Header: t('kezekArchive_requesterIIN'),
      accessor: 'requesterIin',
      style: { textAlign: 'center' },
      Filter: <FilterInputField name="requesterIin" type="number" />
    },
    {
      Header: t('kezekArchive_requesterName'),
      accessor: 'requesterName',
      style: { textAlign: 'center' },
      Filter: <FilterInputField name="requesterFullName" />
    },
    {
      Header: t('kezekArchive_startArchived'),
      accessor: 'startArchived',
      Cell: row =>
        row.original.startArchived
          ? moment(row.original.startArchived).format('DD.MM.YYYY')
          : '',
      sortable: false,
      style: { textAlign: 'center' },
      Filter: <FilterDatePicker name="startArchived" lang={lang} />
    },
    {
      Header: t('kezekArchive_appStatus'),
      accessor: 'appStatus ',
      Cell: ({ original: { appStatusName } }) => appStatusName[`${lang}_name`],
      Filter: (
        <FilterSelect
          name="appStatus"
          dictCode="gu_app_status"
          lang={lang}
          dictFilter={item =>
            ['CAME_PO', 'LEFT_QUEUED', 'MORE_6_YEARS', 'REFUSAL_QUEUED'].includes(
              item.code
            )
          }
        />
      )
    },
    {
      Header: <ColumnSorting title={t('kezekArchive_queuedType')} name="queuedType" />,
      accessor: 'queuedType ',
      Cell: ({ original: { queuedTypeName } }) => queuedTypeName[`${lang}_name`],
      Filter: <FilterSelect name="queuedType" dictCode="gu_queued_type" lang={lang} />
    },
    {
      Header: (
        <ColumnSorting title={t('kezekArchive_priorityQueued')} name="priorityQueued" />
      ),
      accessor: 'priorityQueued ',
      Cell: ({ original: { priorityQueuedName } }) => priorityQueuedName[`${lang}_name`],
      Filter: (
        <FilterSelect name="priorityQueued" dictCode="gu_priority_queued" lang={lang} />
      )
    },
    {
      Header: t('kezekArchive_serviceProviderBin'),
      accessor: 'serviceProviderBin',
      style: { textAlign: 'center' },
      show: isAdmin,
      Filter: <FilterInputField name="serviceProviderBin" type="number" />
    },
    {
      Header: t('kezekArchive_legalLocality'),
      accessor: 'legalLocality',
      show: isAdmin,
      Cell: ({ original: { legalLocality } }) => legalLocality[`${lang}_name`],
      Filter: <FilterTreeSelect lookupId="60" name="locationCode" lang={lang} />
    }
  ];
}
