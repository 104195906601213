import { createReducer } from 'redux-starter-kit';
import { handleError } from '../../utils/handleError';
import Dict, { item } from '../../utils/Dict';
import i18next from 'i18next';
import { ContingentApi } from '../../_helpers/service';
import Notice from '../../utils/Notice';
import {ROLES} from '../../_helpers/Constants'
import moment from 'moment';

/**
 * Constants
 */

export const contingentModule = 'contingent';
const LOADING_TABLE = `${contingentModule}/LOADING_TABLE`;
const TABLE_DATA = `${contingentModule}/TABLE_DATA`;
const LOADING_EXPORT_DATA = `${contingentModule}/LOADING_EXPORT_DATA`;
const CLEAR_STATE = `${contingentModule}/CLEAR_STATE`;
const SET_REPORT = `${contingentModule}/SET_REPORT`;
const SET_CAUSE = `${contingentModule}/SET_CAUSE`;
const SET_GROUP_REPORT = `${contingentModule}/SET_GROUP_REPORT`;
const LOADING_SAVE = `${contingentModule}/LOADING_SAVE`;
const SET_ORGANIZATIONSE = `${contingentModule}/SET_ORGANIZATIONSE`;

/**
 * Reducer
 */

const initialState = {
  loadingTable: false,
  loadingExport: false,
  loadingSave: false,
  tableData: {
    content: [],
    totalElements: 0
  },
  report: {
    content: [],
    totalElements: 0
  },
  cause: [],
  groupReport: [],
  organizations: []
};

export default createReducer(initialState, {
  [LOADING_TABLE]: (state, action) => {
    state.loadingTable = action.payload;
  },
  [TABLE_DATA]: (state, action) => {
    const filter = action.filter || {};
    if (filter.pageNumber && filter.pageNumber !== 1) {
      state.tableData.content = [...state.tableData.content, ...action.payload.content];
    } else {
      state.tableData = action.payload;
    }
  },
  [LOADING_EXPORT_DATA]: (state, action) => {
    state.loadingExport = action.payload;
  },
  [SET_REPORT]: (state, action) => {
    const filter = action.filter || {};
    if (filter.pageNumber && filter.pageNumber !== 1) {
      state.report.content = [...state.report.content, ...action.payload.content];
      state.report.totalElements = action.payload.totalElements;
    } else {
      state.report = action.payload;
    }
  },
  [SET_CAUSE]: (state, action) => {
    state.cause = action.payload;
  },
  [SET_GROUP_REPORT]: (state, action) => {
    state.groupReport = action.payload.map((item) => {return {...item, code: item.id, kk_name: item.nameGroup, ru_name: item.nameGroup}});
  },
  [LOADING_SAVE]: (state, action) => {
    state.loadingSave = action.payload;
  },
  [SET_ORGANIZATIONSE]: (state, action) => {
    state.organizations = action.payload.map((item) => {return {...item, code: item.id}});
  },
  [CLEAR_STATE]: () => initialState
});

/**
 * Actions
 */

export const clearState = () => ({ type: CLEAR_STATE });

export const loadData = ({filter, type}) => async dispatch => {
  try {
    dispatch({ type: LOADING_TABLE, payload: true });
    filter['contingentStatus'] = type 
    const {data} = await ContingentApi.loadData(JSON.stringify(filter));
    if (data.status === 'SUCCESS') {
      const dictAgeGroup = await Dict.itemsObject('gu_age_group');
      const dictQueuedType = await Dict.itemsObject('gu_queued_type');
      const dictDirectionStatus = await Dict.itemsObject('gu_status_direction');
      const dictAcademicYear = await Dict.itemsObject('gu_academic_year_do');
      const dictlanguage = await Dict.itemsObject('gu_language');
      const dictPrivilege = await Dict.itemsObject('gu_privilege');
      const dictKato = await Dict.itemsObject('60');

      for (let row of data.result.content) {
        row.ageGroupName = dictAgeGroup[row.ageGroup] || {};
        row.queuedTypeName = dictQueuedType[row.queuedType] || {};
        row.directionStatus = dictDirectionStatus[row.statusDirection] || {};
        row.academicYearName = dictAcademicYear[row.beginAcademicYear] || {};
        row.languageChild = dictlanguage[row.language] || {};
        row.privilege = dictPrivilege[row.privilegeType] || {};
        row.location = dictKato[row.legalLocality] || {};
      }
    }
    dispatch({ type: TABLE_DATA, payload: data.result, filter });
  } catch (error) {
    handleError(error, i18next.t('contingentPage_candidateErrorLoadTable'));
  } finally {
    dispatch({ type: LOADING_TABLE, payload: false });
  }
};

export const exportApplicant = () => async dispatch => {
  dispatch({ type: LOADING_EXPORT_DATA, payload: true });
  try {
    const response = await ContingentApi.exportApplicant({
      lang: i18next.language
    });
    download(response.data, 'Претенденты.xlsx');
  } catch (error) {
    handleError(error, i18next.t('contingentPage_candidateErrorLoadTable'));
  } finally {
    dispatch({ type: LOADING_EXPORT_DATA, payload: false });
  }
};

export const exportEnrolled = () => async dispatch => {
  dispatch({ type: LOADING_EXPORT_DATA, payload: true });
  try {
    const response = await ContingentApi.exportEnrolled({
      lang: i18next.language
    });
    download(response.data, 'Зачисленные.xlsx');
  } catch (error) {
    handleError(error, i18next.t('contingentPage_enrolledErrorLoadTable'));
  } finally {
    dispatch({ type: LOADING_EXPORT_DATA, payload: false });
  }
};

export const exportExpelled = () => async dispatch => {
  dispatch({ type: LOADING_EXPORT_DATA, payload: true });
  try {
    const response = await ContingentApi.exportExpelled({
      lang: i18next.language
    });
    download(response.data, 'Выбывшие.xlsx');
  } catch (error) {
    handleError(error, i18next.t('contingentPage_exitedErrorLoadTable'));
  } finally {
    dispatch({ type: LOADING_EXPORT_DATA, payload: false });
  }
};


export const loadDateReport = ({filter}) => async (dispatch, getState) => {
  try {
    dispatch({ type: LOADING_TABLE, payload: true });
    const userProfile = { ...getState().login.user };
    if (userProfile) {
      if (userProfile.roles.includes(ROLES.ES_REGION_EMPLOYEES) ||
          userProfile.roles.includes(ROLES.ES_REGION_MIO_EMPLOYEES) ||
          userProfile.roles.includes(ROLES.ES_GODS)) {
        delete userProfile?.organization_id;
        if (!filter.serviceProviderLocation) {
          delete filter['serviceProviderId'];
          delete filter['groupId'];
        } else if (!filter['serviceProviderId']) {
          delete filter['groupId']
        }
      } else {
        filter['serviceProviderId'] = userProfile?.organization_id;
      }
    }
    if (filter?.serviceProviderId) {
      dispatch(allGroups(filter?.serviceProviderId))
    }
    
    if (filter.serviceProviderLocation || filter.serviceProviderId) {
      let locationOrganizations = await ContingentApi.getOrganizations(filter.serviceProviderLocation);
      dispatch({ type: SET_ORGANIZATIONSE, payload: locationOrganizations.data.result })
      const {data} = await ContingentApi.loadDataReport(JSON.stringify(filter)); 
      if (data.status === 'SUCCESS') {
        let reasonChildren = await Dict.itemsObject('absence_reason_children');
        let reasonList = []
        for (let variable in reasonChildren) {
          reasonList.push(reasonChildren[variable])
        }
        dispatch({ type: SET_CAUSE, payload: reasonList })
        dispatch({ type: SET_REPORT, payload: data.result, filter })
      }
    } else {
      dispatch({ 
        type: SET_REPORT, 
        payload: {
        content: [],
        totalElements: 0}, 
        filter
      })
    }
  } catch (error) {
    handleError(error, i18next.t('contingentPage_reportErrorLoadTable'));
  } finally {
    dispatch({ type: LOADING_TABLE, payload: false });
  }
};

export const saveCause = ({ causeCode, userID, logDate, close }) => async (dispatch, getState) => {
  try {
    dispatch({ type: LOADING_SAVE, payload: true });
    const state = getState()[contingentModule];
    const stateCopy = JSON.parse(JSON.stringify(state.report));
    const {data} = await ContingentApi.saveItemReport({ id: userID, date: logDate, absenceReason: causeCode });
    if (data) {
      stateCopy.content.forEach((item) => {
        if (item.visitsInfo) {
          item.visitsInfo.forEach((visitInfo, index) => {
            if (item.childId === userID && moment(visitInfo.logDate).format('DD') ===  moment(data.result.logDate).format('DD')) {
              item.visitsInfo[index] = { ...data.result, logDate: moment(data.result.logDate).format('YYYY-MM-DD') }
              if (!visitInfo.hasOwnProperty('absenceReason')) {
                item.unvisitedDaysWithReason = item.unvisitedDaysWithReason + 1;
                item.unvisitedDaysWithoutReason = item.unvisitedDaysWithoutReason - 1;
              }
            }
          })
        }
      })
      dispatch({ type: SET_REPORT, payload: stateCopy })
      Notice.success(i18next.t('colleagues_causeAddSuccess'));
      close()
    }
  } catch (error) {
    handleError(error, i18next.t('colleagues_causeAddError'));
  } finally {
    dispatch({ type: LOADING_SAVE, payload: false });
  }
}

export const allGroups = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING_SAVE, payload: true });
    if (id) {
      const groupsDate = await ContingentApi.allGroups(id);
      if (groupsDate.data.status === 'SUCCESS') {
        dispatch({ type: SET_GROUP_REPORT, payload: groupsDate.data.result })
      }
    }
  } catch (error) {
    handleError(error, i18next.t('contingentPage_reportErrorLoadTable'));
  } finally {
    dispatch({ type: LOADING_SAVE, payload: false });
  }
};

export const exportData = ({
  maxDate,
  filter,
  currentOrg
}) => async dispatch => {
  try {
    dispatch({ type: LOADING_EXPORT_DATA, payload: true });
    delete filter.pageNumber;
    delete filter.pageSize;
    if (!filter?.serviceProviderId) {
      if (currentOrg) {
        filter['serviceProviderId'] = currentOrg;
      } else {
        delete filter['serviceProviderId'];
      }
    }
    const response = await ContingentApi.exportDataContingent(JSON.stringify(filter));
    download(response.data, `${i18next.t('contingentPage_reportTitle')}.xlsx`);
  } catch (error) {
    handleError(error, i18next.t('contingentPage_reportExportError'));
  } finally {
    dispatch({ type: LOADING_EXPORT_DATA, payload: false });
  }
};

const download = (data, fileName) => {
  let element = document.createElement('a');
  document.body.appendChild(element);
  element.style.display = 'none';
  let blob = new Blob([data]);
  let url = window.URL.createObjectURL(blob);
  element.href = url;
  element.download = fileName;
  element.click();
  window.URL.revokeObjectURL(url);
};
