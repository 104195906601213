import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import { Redirect } from 'react-router-dom';

import { Wrapper } from './ChangePasswordStyles';
import Row from '../../_ui/Row';
import Button from '../../_ui/Button/Button';
import { ChangePassSchema } from '../../components/Modals/ChangePassModal/ChangePassValidation';
import { administrationModule, changePassword } from '../AdministrationPage/AdministrationDucks';
import Form from '../../_ui/Form/Form';
import { loginModule } from '../LoginPage/LoginDucks';
import paths from '../../_helpers/paths';
import PasswordField from '../../components/PasswordField/PasswordField';
import './ChangePasswordTranslate';

function ChangePassword() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loading = useSelector(state => state[administrationModule].changingPassword);
  const user = useSelector(state => state[loginModule].user);
  if (!user?.needChangePassword) {
    return <Redirect to={paths.homePage}/>;
  }

  return (
    <Wrapper>
      <Paper elevation={3}>
        <div className="color-secondary mb2">
          {t('changePassword_title')}
        </div>
        <Form
          initialValues={{
            currentPassword: '',
            newPassword: '',
            confirmationPassword: ''
          }}
          onSubmit={values => dispatch(changePassword(values))}
          validate={ChangePassSchema}
          render={(_, {values, handleChange}) => (
            <>
              <Row label={t('userModal_currentPassword')}>
                <PasswordField
                  name="currentPassword"
                  placeholder={t('userModal_currentPassword')}
                  value={values.currentPassword}
                  onChange={handleChange}
                />
              </Row>
              <Row label={t('userModal_newPassword')}>
                <PasswordField
                  name="newPassword"
                  placeholder={t('userModal_newPassword')}
                  value={values.newPassword}
                  onChange={handleChange}
                />
              </Row>
              <Row label={t('userModal_confirmationPassword')}>
                <PasswordField
                  name="confirmationPassword"
                  placeholder={t('userModal_confirmationPassword')}
                  value={values.confirmationPassword}
                  onChange={handleChange}
                />
              </Row>
              <div className="mt2"/>
              <Button
                type="submit"
                text={t('userModal_changePassword')}
                loading={loading}
                disabled={loading}
              />
            </>
          )}
        />
      </Paper>
    </Wrapper>
  );
}

export default ChangePassword;
