import React from 'react';
import { useTranslation } from 'react-i18next';
import { DialogContent, DialogActions } from '@material-ui/core';

import Modal from '../../_ui/Modal/Modal';
import Button from '../../_ui/Button/Button';

function getFullName(user) {
  return `${user.lastName || ''} ${user.firstName || ''} ${user.middleName || ''}`;
}

const UserDeleteModal = ({ open, onClose, onDelete, data }) => {
  const { t } = useTranslation();
  if (!data) return null;

  return (
    <Modal open={open} onClose={onClose} title={t('administrationPage_deleteUser')}>
      <DialogContent>
        {t('administrationPage_confirmation')} <b>"{getFullName(data.info)}"</b>?
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          text={t('administrationPage_cancel')}
          color="default"
        />
        <Button
          onClick={() => {
            onDelete(data.info, data.index);
            onClose();
          }}
          text={t('administrationPage_delete')}
          color="secondary"
        />
      </DialogActions>
    </Modal>
  );
};

export default UserDeleteModal;