import { createReducer } from 'redux-starter-kit';
import { handleError } from '../../utils/handleError';
import { BulletinApi, ServiceProvidersApi } from '../../_helpers/service';
import Notice from '../../utils/Notice';
import i18next from 'i18next';
import Dict from '../../utils/Dict';
import { NCALayerSign } from '../../components/NCALayer/NCALayerSign';
import { history } from '../../_helpers/history';
import paths from '../../_helpers/paths';
import lodash from 'lodash'
import { loginModule } from '../LoginPage/LoginDucks';

/**
 * Constants
 */

export const kezekBulletinModule = 'kezekBulletin';
const LOADING = `${kezekBulletinModule}/LOADING`;
const LOADING_SAVE = `${kezekBulletinModule}/LOADING_SAVE`;
const LOADING_REFUSE = `${kezekBulletinModule}/LOADING_REFUSE`;
const LOADING_APPROVE = `${kezekBulletinModule}/LOADING_APPROVE`;
const LOADING_DELETE = `${kezekBulletinModule}/LOADING_DELETE`;
const LOADING_CREATE_REVOKE = `${kezekBulletinModule}/LOADING_CREATE_REVOKE`;

const SET_DATA = `${kezekBulletinModule}/SET_DATA`;
const SET_INFO = `${kezekBulletinModule}/SET_INFO`;
const CLEAR_STATE = `${kezekBulletinModule}/CLEAR_STATE`;
const SET_ERROR = `${kezekBulletinModule}/SET_ERROR`;

/**
 * Reducer
 */

const initialState = {
  loading: false,
  loadingSave: false,
  loadingRefuse: false,
  loadingDelete: false,
  loadingRevoke: false,
  loadingApprove: false,
  info: {},
  data: {},
  meta: {},
  error: null
};

export default createReducer(initialState, {
  [SET_DATA]: (state, { payload }) => {
    if (payload.data) state.data = payload.data;
    if (payload.meta) state.meta = payload.meta;
  },
  [SET_INFO]: (state, action) => {
    state.info = action.payload;
  },
  [SET_ERROR]: (state, action) => {
    state.error = action.payload;
  },
  [LOADING]: (state, action) => {
    state.loading = action.payload;
  },
  [LOADING_SAVE]: (state, action) => {
    state.loadingSave = action.payload;
  },
  [LOADING_REFUSE]: (state, action) => {
    state.loadingRefuse = action.payload;
  },
  [LOADING_DELETE]: (state, action) => {
    state.loadingDelete = action.payload;
  },
  [LOADING_CREATE_REVOKE]: (state, action) => {
    state.loadingRevoke = action.payload;
  },
  [LOADING_APPROVE]: (state, action) => {
    state.loadingApprove = action.payload;
  },
  [CLEAR_STATE]: () => initialState
});

/**
 * Actions
 */

export const loadData = id => async dispatch => {
  try {
    dispatch({ type: LOADING, payload: true });
    let data = { metadataKey: 'form_bulletin_do' };
    if (id) {
      let response = await BulletinApi.getBulletin(id);
      data = response.data;
    }
    let { data: meta } = await BulletinApi.loadMeta(data.metadataKey);
    dispatch(setData({ meta, data }));
  } catch (error) {
    let msg = handleError(error, i18next.t('kezekBulletinPage_errorLoad'));
    dispatch({ type: SET_ERROR, payload: msg });
  } finally {
    dispatch({ type: LOADING, payload: false });
  }
};

export const saveFrom = (info, values, organizationId, setEditMode) => async dispatch => {
  try {
    dispatch({ type: LOADING_SAVE, payload: true });
    if (values[info.metadataKey]) {
      let response
      if (info.id) {
        response = await BulletinApi.save({
          id: info.id,
          serviceProviderId: info.serviceProviderId,
          bulletinChanges: values[info.metadataKey]
        });
      } else {
        const dataValues = {
          serviceProviderId: organizationId,
          bulletinChanges: values[info.metadataKey]
        };
        const {data:sign} = await BulletinApi.getSignString(dataValues)
        dataValues.signature = await NCALayerSign(sign.result.signature);
        if (dataValues.signature) {
          response = await BulletinApi.create(dataValues);
        }
      }
      if (response) {
        if (response.data.results.length === 0) {
          dispatch(setData({ data: response.data.entity }));
          !info.id && history.push(paths.kezekBulletin.replace(':id?', response.data.entity.id));
          Notice.success(i18next.t('kezekBulletinPage_saveFormSuccess'));
          setEditMode(false);
        } else {
          handleError(response, i18next.t('kezekBulletinPage_errorSaveForm'));
        }
      }
    }
  } catch (error) {
    handleError(error, i18next.t('kezekBulletinPage_errorSaveForm'));
  } finally {
    dispatch({ type: LOADING_SAVE, payload: false });
  }
};

export const deleteBulletin = (id, closeModal) => async dispatch => {
  try {
    dispatch({ type: LOADING_DELETE, payload: true });
    const { data } = await BulletinApi.delete(id);
    dispatch(setData({ data: data.result }));
    Notice.success(i18next.t('kezekBulletinPage_deleteSuccess'));
    closeModal();
  } catch (error) {
    handleError(error, i18next.t('kezekBulletinPage_deleteError'));
  } finally {
    dispatch({ type: LOADING_DELETE, payload: false });
  }
};

export const revokeBulletin = (id, values, closeModal) => async (dispatch) => {
  try {
    dispatch({ type: LOADING_CREATE_REVOKE, payload: true });
    const response = await BulletinApi.createRevokeSignString({id, ...values});
    const signature = await NCALayerSign(response.data.result.signature)
    if (signature) {
      const { data } = await BulletinApi.createRevoke(id, {id, signature, ...values});
      dispatch(setData({ data: data.result }));
      Notice.success(i18next.t('kezekBulletinPage_createRevokeSuccess'));
      closeModal();
    }
  } catch (error) {
    handleError(error, i18next.t('kezekBulletinPage_createRevokeError'));
  } finally {
    dispatch({ type: LOADING_CREATE_REVOKE, payload: false });
  }
};

export const approveBulletin = (id, closeModal) => async dispatch => {
  try {
    dispatch({ type: LOADING_APPROVE, payload: true });
    const response = await BulletinApi.approveSignString({id});
    const signature = await NCALayerSign(response.data.result.signature)
    if (signature) {
      const { data } = await BulletinApi.approveRevoke(id, {id, signature });
      dispatch(setData({ data: data.result }));
      Notice.success(i18next.t('kezekBulletinPage_approveRevokeSuccess'));
      closeModal();
    }
  } catch (error) {
    handleError(error, i18next.t('kezekBulletinPage_approveRevokeError'));
  } finally {
    dispatch({ type: LOADING_APPROVE, payload: false });
  }
};

export const refuseBulletin = (id, values, closeModal) => async dispatch => {
  try {
    dispatch({ type: LOADING_REFUSE, payload: true });
    const response = await BulletinApi.refuseSignString(id, {id, ...values});
    const signature = await NCALayerSign(response.data.result.signature)
    if (signature) {
      const { data } = await BulletinApi.refuseRevoke(id, {id, signature, ...values});
      dispatch(setData({ data: data.result }));
      Notice.success(i18next.t('kezekBulletinPage_refuseRevokeSuccess'));
      closeModal();
    }
  } catch (error) {
    handleError(error, i18next.t('kezekBulletinPage_refuseRevokeError'));
  } finally {
    dispatch({ type: LOADING_REFUSE, payload: false });
  }
};

const setData = ({ meta, data }) => async (dispatch, getState) => {
  let serviceProvider = null
  const organization_id = getState()[loginModule].user.organization_id;
  if (organization_id) {
    serviceProvider = await ServiceProvidersApi.load(organization_id);
  }
  const dictStatusSuo = await Dict.items('gu_bulletin_status_suo');
  data.providerLocation = await Dict.item('60', data.serviceProviderLocation);
  data.statusWebName = await Dict.item('gu_bulletin_status_web', data.statusWeb);
  data.approvalStatus = lodash.get(data, 'questionnaire.questionCodeToAnswers.approval_status.values[0].value', null);

  if (data.approvalStatus !== null) {
    data.approvalStatusName = await Dict.item('gu_approval_status', data.approvalStatus?.toLowerCase());
  } else {
    data.approvalStatusName = {}
  }

  data.suoStatus = dictStatusSuo.map(item => {
    let suoO = (data.placesStatusSuoInfo || []).find(sItem => sItem.name === item.code) || {}
    return {...item, count: suoO.count || 0 }
  });

  data.availableSeats = ((data.placesStatusSuoInfo || []).find(item => item.name.toUpperCase() === 'ACCESSIBLE') || {}).count || 0;

  const info = {
    id: data.id,
    statusWeb: data.statusWeb,
    serviceProviderBin: data.serviceProviderBin,
    placesStatusSuoInfo: data.placesStatusSuoInfo,
    submissionDate: data.submissionDate,
    age_group: lodash.get(serviceProvider?.data, 'questionnaire.questionCodeToAnswers.age_group.values[0].value', null)
  };

  dispatch({ type: SET_INFO, payload: info });
  dispatch({ type: SET_DATA, payload: { meta, data } });
  dispatch({ type: LOADING, payload: false });
};

export const clearState = () => ({ type: CLEAR_STATE });
