import React from 'react';
import Modal from '../../../_ui/Modal/Modal';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '../../../_ui/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { startProcess, bpModule } from './BusinessProcessDucks';
import { Color } from '../../../components/ThemeProvider/ThemeStyle';
import { useTranslation } from 'react-i18next';

export default function StartModal({ close, data: id, boId }) {
  const { t } = useTranslation();
  const loading = useSelector(state => state[bpModule].loading);
  const dispatch = useDispatch();
  return (
    <Modal
      open
      title={t('businessProcess_modalStartTitle')}
      onClose={close}
      maxWidth="sm"
    >
      <DialogContent
        dividers
        children={t('businessProcess_modalStartQuestion', { id })}
      />
      <DialogActions>
        <Button
          type="submit"
          style={{ marginRight: 10, backgroundColor: Color.bgColorSuccess }}
          text={t('businessProcess_modalStartRun')}
          onClick={() => dispatch(startProcess(id, boId, close))}
          loading={loading}
        />
      </DialogActions>
    </Modal>
  );
}
