import React from 'react';
import Modal from '../../../_ui/Modal/Modal';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '../../../_ui/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { stopProcess, bpModule } from './BusinessProcessDucks';
import { Color } from '../../../components/ThemeProvider/ThemeStyle';
import { useTranslation } from 'react-i18next';

export default function StopProcessModal({ close, data: instance }) {
  const { t } = useTranslation();
  const loading = useSelector(state => state[bpModule].loading);
  const dispatch = useDispatch();
  return (
    <Modal open title={t('businessProcess_modalStopProcTitle')} onClose={close} maxWidth="sm">
      <DialogContent dividers children={t('businessProcess_modalStopProcQuestion')} />
      <DialogActions>
        <Button
          type="submit"
          style={{ marginRight: 10, backgroundColor: Color.bgColorSuccess }}
          text={t('businessProcess_modalStopProcRun')}
          onClick={() => dispatch(stopProcess(instance, close))}
          loading={loading}
        />
      </DialogActions>
    </Modal>
  );
};
