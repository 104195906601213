import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  PassportProvider,
  PassportExpander,
  PassportNavigation,
  PassportForm,
  getFormValues,
} from '../../passport/Passport';
import { Color } from '../../components/ThemeProvider/ThemeStyle';
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary';
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs';
import { contingentPassportModule, loadData, clearState, getMeta, getGroupsChild, savePassport } from './ContingentPassportDucks';
import { Wrapper, ButtonsWrapper } from './ContingentPassportStyle';
import Row from '../../passport/Components/Row';
import { useTranslation } from 'react-i18next';
import PageLoading from '../../components/PageLoader/PageLoading';
import useSimpleModal from '../../components/_hooks/useSimpleModal';
import './ContingentPassportTranslate';
import i18next from 'i18next';
import { useParams } from 'react-router';
import ContingentInfo from './ContingentInfo'
import paths from './../../_helpers/paths';
import DoneIcon from '@material-ui/icons/Done';
import Button from '../../_ui/Button/Button';
import ModalApprove from './modal/ModalApprove';
import CancelIcon from '@material-ui/icons/Cancel';
import { DIRECTION_STATUS, STATUS_DIRECTION, CONTINGENT_STATUS } from '../../_helpers/Constants';
import ModalRevoke from './modal/ModalRevoke'
import ModalRetirement from './modal/ModalRetirement';
import ModalEnroll from './modal/ModalEnroll';
import MenuItem from '@material-ui/core/MenuItem';
import MuiSelect from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import {extraFunctionality} from './components/extraFunctionality.jsx'
import SaveIcon from '@material-ui/icons/Save';
import ModalAdd from './modal/ModalAdd'
import { changeFormValue } from '../../passport/Passport';
import { Box } from '@material-ui/core';

export default function ContingentPassportPage() {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const approveModal = useSimpleModal();
  const revokeModal = useSimpleModal();
  const retirementModal = useSimpleModal();
  const enrollModal = useSimpleModal();
  const addModal = useSimpleModal();

  const [groupId, setGroupId] = useState('');
  const dispatch = useDispatch();
  const [isError, setIsError] = useState(false);
  const { loading, info, data, meta, loadingApprove, loadingRevoke, groups, loadingSave } = useSelector(
    state => state[contingentPassportModule]
  );
  const breadCrumbItems = [
    { name: i18next.t('breadcrumbs_home'), href: '/' },
    { name: i18next.t('breadcrumbs_contingen'), href: paths.contingentCandidatePage },
    { name: i18next.t('breadcrumbs_contingentPassport') }
  ];

  useEffect(() => {
    if (id && Number(id)) {
      dispatch(loadData(id));
      dispatch(getGroupsChild(id));
    } else {
      dispatch(getMeta());
    }
    return () => dispatch(clearState());
  }, []);

  useEffect(() => {
    if (id && Number(id)) {
      dispatch(loadData(id));
    }
  }, [data?.contingentStatus, data?.statusDirection])

  useEffect(() => {
    if (data) {
      setGroupId(data?.childGroupId)
      if (data.statusDirection === STATUS_DIRECTION.ARRIVED_TO_PO) {
      /*  changeFormValue('form_contingent_do_child.begin_academic_year', '');
        changeFormValue('form_contingent_do_child.year_queue', ''); */
        changeFormValue('form_contingent_do_child.type_bulletin', '');
        changeFormValue('form_contingent_do_child.name_group_kk', '');
        changeFormValue('form_contingent_do_child.name_group_ru', '');
        changeFormValue('form_contingent_do_child.street', '');
        changeFormValue('form_contingent_do_child.house', '');
      }
    }
  }, [data])

  function extraF(code, value) {
    return extraFunctionality({
      code,
      value,
      id,
      data
    });
  }

  const onSave = (values) => {
    dispatch(
      savePassport({element : {
        id: data.id,
        childGroupId: groupId,
        isChangingGroup: true,
        contingentChanges: values['form_contingent_do_child']
      }
      })
    )
  }

  const onEnroll = () => {
    getFormValues().then(values => {
      if (values) {
        if (!groupId) {
          setIsError(true)
        } else {
          enrollModal.open(data.id)
        }
      }
    })
  }

  const onAddContingent = () => {
    getFormValues().then(values => {
      if (values) {
        if (!groupId) {
          setIsError(true)
        } else {
          addModal.open({groupId})
        }
      }
    })
  }

  return (
    <PageLoading loading={loading}>
      <BreadCrumbs items={breadCrumbItems} />
      <Wrapper className="px3 pb3">
        <ErrorBoundary>
          <PassportProvider
            withoutScroll={false}
            stickyTop={Color.headerHeight}
            info={info}
            questionRender={extraF}
            data={JSON.stringify(data) === '{}' ? {metadataKey: "form_contingent_do_child"} : data}
            meta={meta}
            config={{
              documentsProps: {
                mimeType: 'image/jpeg,image/png,application/pdf',
                maxSize: 10000000,
                Foto: {
                  mimeType: 'image/jpeg,image/png',
                  maxSize: 10000000,
                }
              },
            }}
          >
            <PassportExpander />
            <div className='flex'>
              <div className="left-info">
                {
                  JSON.stringify(data) !== '{}' &&
                  <ContingentInfo info={info} t={t} lang={i18n.language}/>
                }
                <PassportNavigation />
              </div>
              <div className="ml2 flex-auto">
                <PassportForm />
                {
                  !(
                    (data.statusDirection === DIRECTION_STATUS.RESERVED ||
                    data.statusDirection === DIRECTION_STATUS.RECEIVED ||
                    data.statusDirection === DIRECTION_STATUS.PROLONGED ||
                    data.contingentStatus === CONTINGENT_STATUS.EXPELLED) 
                  ) &&
                  <div className='mt2'>
                    <Row label={t('contingentPassport_groupName')}>
                      <FormControl fullWidth>
                        <MuiSelect
                          value={groupId}
                          error={isError}
                        >
                          {
                            groups.map((item) => {
                              return <MenuItem value={item.id} onClick={() => setGroupId(item.id)} key={item.id}>{item.nameGroup}</MenuItem>
                            })
                          
                          }
                          {
                            groups.length === 0 &&
                            <MenuItem>{t('contingentPassport_noGroup')}</MenuItem>
                          }
                        </MuiSelect>
                        {
                          isError &&
                          <FormHelperText error>{t('contingentPassport_requiredField')}</FormHelperText>
                        }
                      </FormControl>
                    </Row>
                  </div>
                }
              </div>
            </div>
            
          </PassportProvider>
          
            <ButtonsWrapper>
              {
                (
                  data.statusDirection === DIRECTION_STATUS.RESERVED ||
                  data.statusDirection === DIRECTION_STATUS.RECEIVED ||
                  data.statusDirection === DIRECTION_STATUS.PROLONGED ||
                  data.statusDirection === STATUS_DIRECTION.ARRIVED_TO_PO
                ) 
                && (
                  <Box className="mr1">
                    <Button
                      icon={<CancelIcon fontSize="small" />}
                      loading={loadingRevoke}
                      style={{ marginLeft: 10 }}
                      disabled={loading || loadingRevoke}
                      size="small"
                      color="secondary"
                      text={t('contingentPassport_revoke')}  //Отозвать направление
                      onClick={revokeModal.open}
                    />
                  </Box>
                )
              }
              {
                (
                  data.statusDirection === DIRECTION_STATUS.RESERVED ||
                  data.statusDirection === DIRECTION_STATUS.RECEIVED ||
                  data.statusDirection === DIRECTION_STATUS.PROLONGED 
                ) && 
                (
                  <Box className="mr1">
                    <Button
                      icon={<DoneIcon fontSize="small" />}
                      loading={loadingApprove}
                      style={{ marginLeft: 10, backgroundColor: Color.bgColorSuccess }}
                      disabled={loading || loadingApprove}
                      size="small"
                      color="primary"
                      text={t('contingentPassport_approve')}
                      onClick={() => approveModal.open(data.directionId)}
                    />
                  </Box>
                )
              }
              {
                data.statusDirection === STATUS_DIRECTION.ARRIVED_TO_PO &&
                  <Box className="mr1">
                    <Button
                      icon={<DoneIcon fontSize="small" />}
                      loading={loadingApprove}
                      style={{ marginLeft: 10, backgroundColor: Color.bgColorSuccess }}
                      disabled={loading || loadingApprove}
                      size="small"
                      color="primary"
                      text={t('contingentPassport_enroll')} //Зачислить
                      onClick={onEnroll}
                    />
                  </Box>  
              }
              {
                data.contingentStatus === CONTINGENT_STATUS.ENROLLED && (
                  <>
                    <Box className="mr1">
                      <Button
                        icon={<SaveIcon fontSize="small" />}
                        loading={loadingSave}
                        style={{ marginLeft: 10 }}
                        disabled={loading || loadingSave}
                        size="small"
                        color="primary"
                        text={t('contingentPassport_save')}
                        onClick={() => {
                          getFormValues().then(values => {
                            if (values) {
                              onSave(values)
                            }
                          })
                        }
                      }
                      />
                    </Box>
                    <Box className="mr1">
                      <Button
                        size="small"
                        color="secondary"
                        text={t('contingentPassport_retirement')}
                        onClick={() => {
                          getFormValues().then(values => {
                            if (values) {
                              retirementModal.open({
                                id: data.id,
                                directionId: data.directionId
                              })
                            }
                          });
                        }}
                      />
                    </Box>
                  </>
                )
              }
              {
                id === 'add' && (
                  <Button
                    loading={loadingSave}
                    style={{ backgroundColor: Color.bgColorSuccess }}
                    disabled={loading || loadingSave}
                    size="small"
                    text={t('contingentPassport_enroll')}
                    onClick={onAddContingent}
                  />
                )
              }
            </ButtonsWrapper>

          {approveModal.isOpen && <ModalApprove directionId={data.directionId} close={approveModal.close} contingentId={id} />}
          {revokeModal.isOpen && <ModalRevoke directionId={data.directionId} close={revokeModal.close} contingentId={id} />}
          {retirementModal.isOpen && <ModalRetirement {...retirementModal} />}
          {enrollModal.isOpen && <ModalEnroll id={data.id} groupId={groupId} close={enrollModal.close} setIsError={setIsError} directionId={data.directionId}  />}
          {addModal.isOpen && <ModalAdd {...addModal} />}

        </ErrorBoundary>
      </Wrapper>
    </PageLoading>
  );
}
