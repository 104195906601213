import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import {
  PassportProvider,
  PassportExpander,
  PassportForm
} from '../../passport/Passport';
import { getFormValues } from '../../passport/Passport';
import { Color } from '../../components/ThemeProvider/ThemeStyle';
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary';
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs';
import SaveButton from './SaveButton';
import {
  serviceProviderModule,
  loadData,
  clearState,
  onCreate,
  onSave
} from './ServiceProviderDucks';
import { Wrapper, ErrorMessage } from './ServiceProviderStyle';
import TextField from '@material-ui/core/TextField';
import Row from '../../passport/Components/Row';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import usePageMode from '../../components/_hooks/usePageMode';
import PageLoading from '../../components/PageLoader/PageLoading';
import { PAGE_MODE } from '../../_helpers/Constants';
import useSimpleModal from '../../components/_hooks/useSimpleModal';
import ParentOrganizationModal from './ParentOrganizationModal';
import { IconButton, InputAdornment } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import './ServiceProviderTranslate';
import useHasAccess from '../../components/useHasAccess';
import permissions from '../../_helpers/permissions';

export default function ServiceProviderPage({ match }) {
  const modal = useSimpleModal();
  const { register, handleSubmit } = useForm();
  const pageMode = usePageMode(match);
  const viewMode = !useHasAccess(permissions.serviceProviderPage);
  const { t, i18n } = useTranslation();
  const [parentOrg, setParentOrg] = useState({});

  const dispatch = useDispatch();
  const { loading, info, error, data, metadata } = useSelector(
    state => state[serviceProviderModule]
  );

  const breadCrumbItems = [
    { name: t('breadcrumbs_home'), href: '/' },
    { name: t('breadcrumbs_serviceProviders'), href: '/serviceProviders' },
    { name: info[`${i18n.language}_name`] }
  ];

  const onSubmit = staticValues => {
    let values = { ...staticValues, metadataKey: info.metadataKey };
    if (parentOrg.id) values.parentServiceProviderId = parentOrg.id;

    if (pageMode === PAGE_MODE.EDIT) {
      getFormValues().then(formValues => {
        if (formValues) {
          dispatch(onSave({ ...values, id: match.params.id }, formValues));
        }
      });
    }

    if (pageMode === PAGE_MODE.CREATE) {
      getFormValues().then(formValues => {
        if (formValues) {
          dispatch(onCreate(values, formValues));
        }
      });
    }
  };

  useEffect(() => {
    dispatch(loadData(match.params.id));
    return () => dispatch(clearState());
  }, [dispatch, match.params.id]);

  useEffect(() => {
    setParentOrg(info.parentOrg);
  }, [info.parentOrg]);

  if (error) {
    return (
      <>
        <BreadCrumbs items={breadCrumbItems} />
        <Paper className="p3">
          <ErrorMessage>
            <code>{error}</code>
          </ErrorMessage>
        </Paper>
      </>
    );
  }

  return (
    <PageLoading loading={loading}>
      <BreadCrumbs items={breadCrumbItems} />
      <Wrapper className="p3">
        <ErrorBoundary>
          <div className="title">{info[`${i18n.language}_name`]}</div>
          <Divider />
          <div style={{ transform: 'translateY(30px)' }}>
            <span className="bold">{t('serviceProvider_id')}</span>: {info.id}
          </div>
          <PassportProvider
            info={info}
            withoutScroll={false}
            stickyTop={Color.headerHeight}
            meta={metadata}
            data={{ metadataKey: metadata.id, ...data }}
            config={{
              documentsProps: {
                mimeType: 'image/jpeg,image/png',
                maxSize: 10000000,
              },
            }}
          >
            <PassportExpander />
            <PassportForm
              disabled={viewMode}
              info={info}
              betweenRender={(_, nextQuestionCode) =>
                nextQuestionCode === 'service_provider.ru_name' && (
                  <>
                    <Row label={t('serviceProvider_bin')}>
                      <TextField
                        disabled={viewMode}
                        placeholder={viewMode ? '' : t('serviceProvider_bin')}
                        defaultValue={info.bin}
                        {...register('bin')}
                      />
                    </Row>
                    <Row label={t('serviceProvider_iin')}>
                      <TextField
                        disabled={viewMode}
                        placeholder={viewMode ? '' : t('serviceProvider_iin')}
                        defaultValue={info.iin}
                        {...register('iin')}
                      />
                    </Row>
                    <Row label={t('serviceProvider_nedbId')}>
                      <TextField
                        disabled={viewMode}
                        placeholder={viewMode ? '' : t('serviceProvider_nedbId')}
                        defaultValue={info.nedbId}
                        {...register('nedbId')}
                      />
                    </Row>
                    <Row label={t('serviceProvider_parent')}>
                      <TextField
                        value={parentOrg[`${i18n.language}_name`] || ''}
                        onClick={() => modal.open(true)}
                        disabled={viewMode}
                        placeholder={viewMode ? '' : t('serviceProvider_parent')}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                disabled={viewMode}
                                className="show-on-hover"
                                onClick={e => {
                                  e.stopPropagation();
                                  setParentOrg({});
                                }}
                                children={<CloseIcon fontSize="small" />}
                              />
                            </InputAdornment>
                          )
                        }}
                      />
                    </Row>
                  </>
                )
              }
            />
            {modal.isOpen && (
              <ParentOrganizationModal
                parentOrg={parentOrg}
                modal={modal}
                onSelect={row => {
                  setParentOrg(row);
                  modal.close();
                }}
              />
            )}
            {[PAGE_MODE.EDIT, PAGE_MODE.CREATE].includes(pageMode) && (
              <SaveButton onClick={handleSubmit(onSubmit)} pageMode={pageMode} />
            )}
          </PassportProvider>
        </ErrorBoundary>
      </Wrapper>
    </PageLoading>
  );
}
