import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { queueModule, loadDataByName, cancelQueue } from './QueueDucks';
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs';
import { Paper } from '@material-ui/core';
import DateTimePicker from '../../components/TableFilter/ColumnsFilters/FilterDateTimePicker';
import Select from '../../components/TableFilter/ColumnsFilters/FilterSelect';
import './QueueTranslate';
import useTableFilter from '../../components/TableFilter/useTableFilter';
import { queueByNameValues, statusNames } from './QueueConstans';
import { columnsTab2 } from './QueueColumns';
import useDict from '../../components/_hooks/useDict';
import StaticTablePagination from '../../_ui/Table/StaticTablePaginated';

function QueueByName() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const loading = useSelector(state => state[queueModule].loading);
  const tableData = useSelector(state => state[queueModule].dataByName);
  const columnTranslate = useDict('gu_message_queue', true);
  const breadCrumbItems = [
    { name: t('breadcrumbs_home'), href: '/' },
    { name: t('breadcrumbs_monitoringQueue'), href: '/queue/tab1' },
    { name: t('breadcrumbs_queue') }
  ];

  const { nextPage, reloadPage } = useTableFilter(filter => {
    dispatch(loadDataByName(filter));
  }, queueByNameValues);

  return (
    <>
      <BreadCrumbs items={breadCrumbItems} />
      <Paper>
        <div className="p3 relative">
          <div className="flex mb1">
            <Select
              name="status"
              options={statusNames}
              allowClear={false}
              fullWidth={false}
              placeholder={t('queuePage_name')}
              style={{ marginRight: 10, width: 230 }}
            />
            <DateTimePicker
              allowClear={false}
              name="datetime_begin"
              placeholder={t('queuePage_fromDate')}
              style={{ marginRight: 10, width: 230 }}
            />
            <DateTimePicker
              allowClear={false}
              name="datetime_end"
              placeholder={t('queuePage_toDate')}
              style={{ marginRight: 10, width: 230 }}
            />
          </div>
          <StaticTablePagination
            sortable={false}
            filterable={false}
            loading={loading}
            columns={columnsTab2({
              t,
              lang: i18n.language,
              columnTranslate,
              cancelQueue: id => dispatch(cancelQueue(id, reloadPage))
            })}
            data={tableData.content}
            totalElements={tableData.totalElements}
            onClickLoadMore={() => nextPage()}
          />
        </div>
      </Paper>
    </>
  );
}

export default QueueByName;
