import { addTranslation } from '../../_helpers/Translate';

addTranslation({
  ru: {
    pageReport_fullName: 'ФИО',
    pageReport_reference: 'Справка',
    pageReport_visitedDays: 'Пришел',
    pageReport_withoutReason: 'Причина',
    pageReport_withReason: 'Без причины',
    pageReport_missedDays: 'Пропущенные дни',
    pageReport_total: 'ВСЕГО',
    pageReport_arrivalDate: 'Дата прихода',
    pageReport_scanTime: 'Время сканирования QR кода:',
    pageReport_reportModalTitle: 'Основание отсутствия',
    pageReport_reportCause: 'Причина',
    pageReport_requiredField: 'Обязательное для заполнения',
    pageReport_saveBtn: 'Сохранить',
  },
  kk: {
    pageReport_fullName: 'ТАӘ',
    pageReport_reference: 'Анықтама',
    pageReport_visitedDays: 'Келген күн',
    pageReport_withoutReason: 'Себепті',
    pageReport_withReason: 'Себепсіз',
    pageReport_missedDays: 'Қалған күн',
    pageReport_total: 'БАРЛЫҒЫ',
    pageReport_arrivalDate: 'Келу күні',
    pageReport_scanTime: 'QR кодын сканерлеу уақыты:',
    pageReport_reportModalTitle: 'Болмау негізі',
    pageReport_reportCause: 'Себебі',
    pageReport_requiredField: 'Толтыру үшін міндетті',
    pageReport_saveBtn: 'Сақтау',
  }
});
