import React, {useEffect} from 'react';
import {ExitedColumns} from './ExitedColumns'
import { useTranslation } from 'react-i18next';
import StaticTablePagination from '../../../_ui/Table/StaticTablePaginated';
import { useDispatch, useSelector } from 'react-redux';
import { loadData, contingentModule, clearState } from '../ContingentDucks';
import useTableFilter from '../../../components/TableFilter/useTableFilter';

export default function ContingentExited() { 
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const data = useSelector(state => state[contingentModule].tableData);
  const loadingTable = useSelector(state => state[contingentModule].loadingTable);

  const { nextPage } = useTableFilter(filter =>
    dispatch(loadData({filter, type: 'EXPELLED'}))
  ); 
  useEffect(() => {
    dispatch(clearState());
  }, [dispatch]);

  return (
    <div className='mt1'>
      <StaticTablePagination
        sortable={false}
        loading={loadingTable}
        columns={ExitedColumns({
          t,
          lang: i18n.language,
        })}
        data={data.content}
        totalElements={data.totalElements}
        urlOnClick={row => `/contingent/item/${row.original.id}`}
        onClickLoadMore={() => nextPage()}
      />
    </div>
  );
}
