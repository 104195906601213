import { addTranslation } from '../../_helpers/Translate';

addTranslation({
  ru: {
    tokenRefresher_expiredAt: 'Ваш сеанс истекает через:',
    tokenRefresher_second: ' сек',
    tokenRefresher_stay: 'Оставаться в системе',
    tokenRefresher_title: 'Завершение сеанса'
  },
  kk: {
    tokenRefresher_expiredAt: 'соң сіздің сеансыңыз аяқталады:',
    tokenRefresher_second: ' сек',
    tokenRefresher_stay: 'Жүйеде қалу',
    tokenRefresher_title: 'Сеансты аяқтау'
  }
});
