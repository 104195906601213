import { createReducer } from 'redux-starter-kit';
import { handleError } from '../../utils/handleError';
import i18next from 'i18next';
import { ContingentCommonApi } from '../../_helpers/service';
import Dict from '../../utils/Dict';
import Notice from '../../utils/Notice';
import { NCALayerSign } from '../../components/NCALayer/NCALayerSign';
import {ROLES} from '../../_helpers/Constants'

/**
 * Constants
 */

export const contingentSectionModule = 'contingentSection';
const LOADING = `${contingentSectionModule}/LOADING`;
const LOADING_TABLE = `${contingentSectionModule}/LOADING_TABLE`;
const CLEAR_STATE = `${contingentSectionModule}/CLEAR_STATE`;
const SET_META = `${contingentSectionModule}/SET_META`;
const SET_GROUP = `${contingentSectionModule}/SET_GROUP`;
const SET_RETIRED = `${contingentSectionModule}/SET_RETIRED`;
const SET_STUDENT = `${contingentSectionModule}/SET_STUDENT`;
const SET_DATA = `${contingentSectionModule}/SET_DATA`;
const LOADING_SAVE = `${contingentSectionModule}/LOADING_SAVE`;
const LOADING_DIMISSED = `${contingentSectionModule}/LOADING_DIMISSED`;
const SET_REPORT = `${contingentSectionModule}/SET_REPORT`;
const SET_CAUSE = `${contingentSectionModule}/SET_CAUSE`;
const SET_ORGANIZATIONSE = `${contingentSectionModule}/SET_ORGANIZATIONSE`;
const LOADING_EXPORT_DATA = `${contingentSectionModule}/LOADING_EXPORT_DATA`;

/**
 * Reducer
 */

const initialState = {
  loading: false,
  loadingTable: false,
  loadingSave: false,
  loadingDismissed: false,
  loadingExport: false,
  student: {
    content: [],
    totalElements: 0,
  },
  retired: {
    content: [],
    totalElements: 0,
  },
  meta: {},
  data: {},
  report: {
    content: [],
    totalElements: 0
  },
  cause: [],
  organizations: [],
};

export default createReducer(initialState, {
  [SET_META]: (state, action) => {
    state.meta = action.payload;
  },
  [SET_GROUP]: (state, action) => {
    state.group = action.payload;
  },
  [SET_STUDENT]: (state, action) => {
    const filter = action.filter || {};
    if (filter.pageNumber && filter.pageNumber !== 1) {
      state.student.content = [...state.student.content, ...action.payload.content];
    } else {
      state.student = action.payload;
    }
  },
  [SET_RETIRED]: (state, action) => {
    const filter = action.filter || {};
    if (filter.pageNumber && filter.pageNumber !== 1) {
      state.retired.content = [...state.retired.content, ...action.payload.content];
    } else {
      state.retired = action.payload;
    }
  },
  [SET_DATA]: (state, action) => {
    state.data = action.payload;
  },
  [LOADING]: (state, action) => {
    state.loading = action.payload;
  },
  [LOADING_TABLE]: (state, action) => {
    state.loadingTable = action.payload;
  },
  [LOADING_SAVE]: (state, action) => {
    state.loadingSave = action.payload;
  },
  [LOADING_DIMISSED]: (state, action) => {
    state.loadingDismissed = action.payload;
  },
  [SET_REPORT]: (state, action) => {
    const filter = action.filter || {};
    if (filter.pageNumber && filter.pageNumber !== 1) {
      state.report.content = [...state.report.content, ...action.payload.content];
    } else {
      state.report = action.payload;
    }
  },
  [SET_CAUSE]: (state, action) => {
    state.cause = action.payload;
  },
  [SET_ORGANIZATIONSE]: (state, action) => {
    state.organizations = action.payload.map((item) => { return {...item, code: item.id } });
  },
  [LOADING_EXPORT_DATA]: (state, action) => {
    state.loadingExport = action.payload;
  },
  [CLEAR_STATE]: () => initialState
});

/**
 * Actions
 */

export const clearState = () => ({ type: CLEAR_STATE });

export const loadStudents = ({filter}) => async dispatch => {
  try {
    dispatch({ type: LOADING_TABLE, payload: true });
    filter['contingentStatus'] = 'VISITS_SECTION'
    const { data } = await ContingentCommonApi.loadChildSection(JSON.stringify(filter))
    if (data.status === 'SUCCESS') {
      const dictSectionType = await Dict.itemsObject('gu_section_type');
      for (let row of data.result.content) {
        row.sectionTypeName = dictSectionType[row.sectionName] || {};
      }
    }
    dispatch({ type: SET_STUDENT, payload: data.result, filter });
  } catch (error) {
    handleError(error, i18next.t('contingentSection_pupilsErrorLoadTable'));
  } finally {
    dispatch({ type: LOADING_TABLE, payload: false });
  }
};

export const getMeta = () => async dispatch => {
  try {
    dispatch({ type: LOADING, payload: true });
    let data = {
      metadataKey: 'form_contingent_section_visitor'
    }
    const { data: meta } = await ContingentCommonApi.loadMeta(data.metadataKey);
    dispatch({ type: SET_META, payload: meta });
  } catch (error) {
    handleError(error, i18next.t('contingentSection_errorLoadMeta'));
  } finally {
    dispatch({ type: LOADING, payload: false });    
  }
}

export const setContingent = ({values, close, studentIIN, history, id }) => async dispatch => {
  try {
    dispatch({ type: LOADING_SAVE, payload: true });
    values['contingentChanges'] = [...values['form_contingent_section_visitor']];
    delete values['form_contingent_section_visitor'];
    if (id) {
      await ContingentCommonApi.changeContingent({
        id: id,
        iin: studentIIN,
        contingentType: 'SECTION_VISITOR',
        ...values
      });
      
    } else {
      await ContingentCommonApi.setContingent({
        iin: studentIIN,
        contingentType: 'SECTION_VISITOR',
        ...values
      });
    }
    history.push('/contingent/student')
    Notice.success(i18next.t('contingentSection_addSuccess'));
    close();
  } catch (error) {
    handleError(error, i18next.t('contingentSection_addError'));
  } finally {
    dispatch({ type: LOADING_SAVE, payload: false });
  }
};

export const loadData = id => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    if (id) {
      const { data } = await ContingentCommonApi.getContingentItem(id);
      //const { data: meta }  = await ContingentCommonApi.loadMeta(data.metadataKey);
      //dispatch({ type: SET_META, payload: meta });
      dispatch({ type: SET_DATA, payload: data });
    }
  } catch (error) {
    handleError(error, i18next.t('contingentPassport_errorLoad'));
  } finally {
    dispatch({ type: LOADING, payload: false });
  }
};

export const disposalSection= ({id, history, close, values, studentIIN, info}) => async (dispatch) => {
  try {
    dispatch({ type: LOADING_DIMISSED, payload: true });
    values['contingentChanges'] = [...values['form_contingent_section_visitor']];
    delete values['form_contingent_section_visitor']
    const response = await ContingentCommonApi.getSignString({ iin: studentIIN, contingentType: "SECTION_VISITOR", ...values });
    const signature = await NCALayerSign(response.data.result.signature);
    if (signature) {
      let data = await ContingentCommonApi.disposalSection({
        id: id,
        signature: signature,
        dateDropout: info.dateDropout, 
        dropoutReason: info.dropoutReason
      })
      if (data.status === 200) {
        close();
        history.push('/contingent/student')          
        Notice.success(i18next.t('contingentSection_addSuccess'));
      }
    }
  } catch (error) {
    handleError(error, i18next.t('contingentSection_retirementError'));
  } finally {
    dispatch({ type: LOADING_DIMISSED, payload: false });
  }
};

export const loadRetired = ({filter, type}) => async dispatch => {
  try {
    dispatch({ type: LOADING_TABLE, payload: true });
    filter['contingentStatus'] = 'LEFT_SECTION'
    const { data } = await ContingentCommonApi.loadChildSection(JSON.stringify(filter))
    if (data.status === 'SUCCESS') {
      const dictSectionType = await Dict.itemsObject('gu_section_type');
      for (let row of data.result.content) {
        row.sectionTypeName = dictSectionType[row.sectionName] || {};
      }
    }
    dispatch({ type: SET_RETIRED, payload: data.result, filter });
  } catch (error) {
    handleError(error, i18next.t('contingentSection_exitedErrorLoadTable'));
  } finally {
    dispatch({ type: LOADING_TABLE, payload: false });
  }
};

export const loadDateReport = ({filter, minDate, maxDate}) => async (dispatch, getState) => {
  try {
    dispatch({ type: LOADING_TABLE, payload: true });
    const userProfile = getState().login.user;
    filter['dateMin'] = `${minDate}T00:00:00`;
    filter['dateMax'] = `${maxDate}T00:00:00`;
    console.log('filter', filter)
    if (userProfile) {
      if (!(userProfile.roles.includes(ROLES.ES_REGION_EMPLOYEES) ||
          userProfile.roles.includes(ROLES.ES_REGION_MIO_EMPLOYEES) ||
          userProfile.roles.includes(ROLES.ES_GODS))) {
        filter['serviceProviderId'] = userProfile?.organization_id;
      }
    } 
    if (filter.serviceProviderLocation) {
      let locationOrganizations = await ContingentCommonApi.getSectionsOrganizations(filter.serviceProviderLocation);
      dispatch({ type: SET_ORGANIZATIONSE, payload: locationOrganizations.data.result })
    }
    if (filter.serviceProviderLocation || filter.serviceProviderId) {
      const { data } = await ContingentCommonApi.loadSectionReport(JSON.stringify(filter));
      if (data.status === 'SUCCESS') {
        let reasonChildren = await Dict.itemsObject('absence_reason_children');
        let reasonList = []
        for (let variable in reasonChildren) {
          reasonList.push(reasonChildren[variable])
        }
        dispatch({ type: SET_CAUSE, payload: reasonList })
        dispatch({ type: SET_REPORT, payload: data.result, filter })
      }
    } else {
      dispatch({ 
        type: SET_REPORT, 
        payload: {
          content: [],
          totalElements: 0 
        }, 
        filter
      })
    }
  } catch (error) {
    handleError(error, i18next.t('contingentSection_reportErrorLoadTable'));
  } finally {
    dispatch({ type: LOADING_TABLE, payload: false });
  }
};

export const saveCause = ({ causeCode, id, close }) => async (dispatch, getState) => {
  try {
    dispatch({ type: LOADING_SAVE, payload: true });
    const state = getState()[contingentSectionModule];
    const stateCopy = JSON.parse(JSON.stringify(state.report));
    const {data} = await ContingentCommonApi.saveItemReport({ id: id, absenceReason: causeCode });
    if (data) {
      stateCopy.content.forEach((item) => {
        if (item.visitsInfo){
          item.visitsInfo.forEach((visitInfo, index) => {
            if (visitInfo.id === id) {
              item.visitsInfo[index] = {...data.result}
              if (!visitInfo.hasOwnProperty('absenceReason')) {
                item.unvisitedDaysWithReason = item.unvisitedDaysWithReason + 1;
                item.unvisitedDaysWithoutReason = item.unvisitedDaysWithoutReason - 1;
              }
            }
          })
        }
      })
      dispatch({ type: SET_REPORT, payload: stateCopy })
      Notice.success(i18next.t('contingentSection_causeAddSuccess'));
      close()
    }
  } catch (error) {
    handleError(error, i18next.t('contingentSection_causeAddError'));
  } finally {
    dispatch({ type: LOADING_SAVE, payload: false });
  }
}

export const exportData = ({
  minDate,
  maxDate,
  filter,
  currentOrg
}) => async dispatch => {
  try {
    dispatch({ type: LOADING_EXPORT_DATA, payload: true });
    delete filter.pageNumber;
    delete filter.pageSize;
    filter['dateMin'] = `${minDate}T00:00:00`;
    filter['dateMax'] = `${maxDate}T00:00:00`;
    if (!filter?.serviceProviderId) {
      if (currentOrg) {
        filter['serviceProviderId'] = currentOrg;
      } else {
        delete filter['serviceProviderId'];
      }
    }
    const response = await ContingentCommonApi.exportDataSection(JSON.stringify(filter));
    download(response.data, `${i18next.t('contingentSection_reportTitle')}.xlsx`);
  } catch (error) {
    handleError(error, i18next.t('contingentSection_reportExportError'));
  } finally {
    dispatch({ type: LOADING_EXPORT_DATA, payload: false });
  }
};

const download = (data, fileName) => {
  let element = document.createElement('a');
  document.body.appendChild(element);
  element.style.display = 'none';
  let blob = new Blob([data]);
  let url = window.URL.createObjectURL(blob);
  element.href = url;
  element.download = fileName;
  element.click();
  window.URL.revokeObjectURL(url);
};
