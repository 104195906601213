import { addTranslation } from '../../_helpers/Translate';

addTranslation({
  ru: {
    kezekBulletinPage_title: 'Бюллетень',
    kezekBulletinPage_number: 'Номер бюллетеня',
    kezekBulletinPage_submissionDate: 'Дата создания',
    kezekBulletinPage_serviceProviderId: 'Номер ДО',
    kezekBulletinPage_BIN: 'БИН',
    kezekBulletinPage_serviceProviderName: 'Наименование организации',
    kezekBulletinPage_providerLocation: 'Населенный пункт',
    kezekBulletinPage_columnStatusWeb: 'Статус',
    kezekBulletinPage_saveForm: 'Сохранить',
    kezekBulletinPage_edit: 'Редактировать',
    kezekBulletinPage_countBulletin: 'Количество мест в разрезе статусов',
    kezekBulletinPage_add: 'Добавить',
    kezekBulletinPage_approveRevoke: 'Подтверидить отзыв мест',
    kezekBulletinPage_delete: 'Удалить',
    kezekBulletinPage_refuse: 'Отменить отзыв',
    kezekBulletinPage_revoke: 'Отозвать',
    kezekBulletinPage_approveTitle: 'Подтверждение отзыва',
    kezekBulletinPage_approveSign: 'Подтвердить и подписать',
    kezekBulletinPage_approveText: 'Вы собираетесь подтвердить отзыв мест?',
    kezekBulletinPage_deleteTitle: 'Удаление бюллетени',
    kezekBulletinPage_deleteText: 'Вы собираетесь удалить бюллетень с номером "{{id}}" ?',
    kezekBulletinPage_createRevokeTitle: 'Отзыв мест',
    kezekBulletinPage_requiredField: 'Обязательное для заполнения',
    kezekBulletinPage_deletedPlaces: 'Количество удаляемых мест',
    kezekBulletinPage_reasonDeletion: 'Причина отзыва свободных мест',
    kezekBulletinPage_reasonDeletionTextRu: 'Причина на русском',
    kezekBulletinPage_reasonDeletionTextKk: 'Причина на казахском',
    kezekBulletinPage_approvalStatus: 'Статус соглазования отзыва',
    kezekBulletinPage_errorDeletedPlaces: 'Указано больше чем доступных мест',

    kezekBulletinPage_errorLoad: 'Не удалось загрузить данные бюллетени',
    kezekBulletinPage_errorSaveForm: 'Не удалось сохранить данные',
    kezekBulletinPage_saveFormSuccess: 'Данные успешно сохранены',
    kezekBulletinPage_deleteSuccess: 'Бюллетень успешно удален',
    kezekBulletinPage_deleteError: 'Ошибка при удалении бюллетени',
    kezekBulletinPage_createRevokeSuccess: 'Отзыв мест успешно отправлен',
    kezekBulletinPage_createRevokeError: 'Ошибка при отзыве мест',
    kezekBulletinPage_refuseRevokeSuccess: 'Отзыв мест успешно отменен',
    kezekBulletinPage_refuseRevokeError: 'Ошибка при отмене отзыва мест',
    kezekBulletinPage_approveRevokeSuccess: 'Отзыв мест успешно подтвержден',
    kezekBulletinPage_approveRevokeError: 'Ошибка при подтверждении отзыва мест',
  },
  kk: {
    kezekBulletinPage_title: 'Бюллетень',
    kezekBulletinPage_number: 'Бюллетень номірі',
    kezekBulletinPage_submissionDate: 'Құрылған күні',
    kezekBulletinPage_serviceProviderId: 'МДҰ нөмірі ',
    kezekBulletinPage_BIN: 'БСН',
    kezekBulletinPage_serviceProviderName: 'Ұйымның атауы',
    kezekBulletinPage_providerLocation: 'Елді мекен',
    kezekBulletinPage_columnStatusWeb: 'Мәртебесі',
    kezekBulletinPage_saveForm: 'Сақтау',
    kezekBulletinPage_edit: 'Түзету',
    kezekBulletinPage_countBulletin: 'Мәртебелер бөлінісі бойынша орындар саны',
    kezekBulletinPage_add: 'Қосу',
    kezekBulletinPage_approveRevoke: 'Растау',
    kezekBulletinPage_delete: 'Жою',
    kezekBulletinPage_revoke: 'Қайтарып алу',
    kezekBulletinPage_approveTitle: "Орындарды қайтарып алуды растау",
    kezekBulletinPage_approveSign: 'Бекіту және қол қою',
    kezekBulletinPage_approveText: 'Орындарды қайтарып алуды мақұлдайсыз ба?',
    kezekBulletinPage_refuse: 'Қайтарып алудан бас тарту',
    kezekBulletinPage_deleteTitle: 'Бюллетеньді жою',
    kezekBulletinPage_deleteText: 'Сіз бюллетень нөмірі "{{id}}" жойғалы жатырсыз ба?',
    kezekBulletinPage_createRevokeTitle: "Қайтарылған орындар",
    kezekBulletinPage_requiredField: 'Міндетті өріс',
    kezekBulletinPage_deletedPlaces: 'Жойылатын орындар саны',
    kezekBulletinPage_reasonDeletion: 'Орындарды жою себебі',
    kezekBulletinPage_reasonDeletionTextRu: 'Себеп, орысша',
    kezekBulletinPage_reasonDeletionTextKk: 'Себеп, қазақша',
    kezekBulletinPage_approvalStatus: 'Орынды қайтарып алу мәртебесі',
    kezekBulletinPage_errorDeletedPlaces: 'Тізімдегі қолжетімді орындардан көп',

    kezekBulletinPage_errorLoad: 'Бюллетени деректерін жүктеу мүмкін болмады',
    kezekBulletinPage_errorSaveForm: 'Деректерді сақтау сәтсіз аяқталды',
    kezekBulletinPage_saveFormSuccess: 'Деректер сәтті сақталды',
    kezekBulletinPage_deleteSuccess: 'Бюллетень сәтті жойылды',
    kezekBulletinPage_createRevokeSuccess: 'Орындарды қайтарып алу сәтті жіберілді',
    kezekBulletinPage_createRevokeError: "Орындарды қайтарып алу жою сәтсіз аяқталды",
    kezekBulletinPage_deleteError: 'Бюллетеньді жою сәтсіз аяқталды',

    kezekBulletinPage_refuseRevokeSuccess: "Орындардан сәтті бас тартылды",
    kezekBulletinPage_refuseRevokeError: 'Орындарды қайтарып алудан бас тарту қатесі',
    kezekBulletinPage_approveRevokeSuccess: "Орын қайтарып алу мақұлданды",
    kezekBulletinPage_approveRevokeError: "Қайтарылған орындарды растау қатесі",
  }
});
