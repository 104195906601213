import React from 'react';
import StaticTable from '../../../_ui/Table/StaticTable';
import { columns } from './MonitoringColumns';
import { useTranslation } from 'react-i18next';
import ExcelFile from './ExportFile/ExportFile';

export default function MonitoringTable({ loading, data, serviceType, filter }) {
  const { t, i18n } = useTranslation();
  const tableData = handleTableData(
    JSON.parse(JSON.stringify(data)),
    serviceType,
    t,
    i18n.language
  );

  return (
    <div>
      <ExcelFile data={tableData} filter={filter} />
      <StaticTable
        loading={loading}
        columns={columns({ t, lang: i18n.language })}
        rowBgColor={row => (row.original.isSum ? 'rgba(125,125,125,0.3)' : undefined)}
        data={tableData}
        pageSize={tableData.length || 5}
      />
    </div>
  );
}

function handleTableData(data, serviceType, t, lang) {
  let result = data.map(row => {
    row['READY_FOR_SIGN'] =
      row['READY_FOR_SIGN_POSITIVE'] + row['READY_FOR_SIGN_NEGATIVE'];
    return row;
  });
  const columns = [
    'SUBMITTED',
    'IN_PROGRESS',
    'READY_FOR_SIGN',
    'COMPLETED_POSITIVE',
    'COMPLETED_NEGATIVE',
    'IN_PROGRESS_IN_EXTERNAL_APP'
  ];

  if (serviceType) {
    result = data.filter(item => item['service_type'].toLowerCase() === serviceType.code.toLowerCase());
  } else {
    // суммируем значения по регионам
    const sumByLocation = {};
    for (const row of data) {
      if (sumByLocation.hasOwnProperty(row.location)) {
        for (const column of columns) {
          sumByLocation[row.location][column] += row[column];
        }
      } else {
        sumByLocation[row.location] = row;
      }
    }
    result = Object.values(sumByLocation);
  }
  if (result.length === 0) return result;

  const columnSum = {
    [`${lang}_locationName`]: t('monitoring_columnAllByStatus'),
    allByRegion: 0,
    isSum: true
  };

  for (const column of columns) {
    columnSum[column] = 0;
  }

  result = result.map(row => {
    let allByRegion = 0;
    for (const column of columns) {
      allByRegion += row[column];
      columnSum[column] += row[column];
    }
    row.allByRegion = allByRegion;
    columnSum.allByRegion += allByRegion;
    return row;
  });
  result.push(columnSum);
  return result;
}
