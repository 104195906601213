import React, { useMemo, useEffect, useState } from 'react';
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs';
import Paper from '@material-ui/core/Paper';
import { useTranslation } from 'react-i18next';
import './ClassListTranslate';
import paths from '../../_helpers/paths'
import CreateIcon from '@material-ui/icons/Create';
import Button from '../../_ui/Button/Button';
import {ClassListColumns} from './ClassListColumns'
import StaticTablePagination from '../../_ui/Table/StaticTablePaginated';
import useSimpleModal from '../../components/_hooks/useSimpleModal';
import Modal from '../../_ui/Modal/Modal';
import { DialogContent } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { loadData, getClassChild, addChild, classListModule } from './ClassListDucks';
import useTableFilter from '../../components/TableFilter/useTableFilter';
import MenuItem from '@material-ui/core/MenuItem';
import MuiSelect from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { useForm } from 'react-hook-form';
import FormHelperText from '@material-ui/core/FormHelperText';
import SaveIcon from '@material-ui/icons/Save';

function ClassListPage() {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const modal = useSimpleModal();
  const { id } = useParams();
  const [currentChild, setCurrentChild] = useState('')
  const data = useSelector(state => state[classListModule].data);
  const loading = useSelector(state => state[classListModule].loading);
  const child = useSelector(state => state[classListModule].child);
  const freeKids = useSelector(state => state[classListModule].freeKids);
  const { register, handleSubmit, formState: { errors } } = useForm();
  const breadCrumbItems = useMemo(
    () => [
      {
        name: t('breadcrumbs_home'),
        href: paths.homePage
      },
      {
        name: t('classPage_title'),
        href: paths.classPage
      },
      {
        name: t('classItemPage_data'),
        href: paths.classItemPage
      }
    ],
    [t]
  );
  useEffect(() => {
    dispatch(loadData({id}));
  }, [id])
  
  const { filter, nextPage } = useTableFilter(filter =>
    dispatch(getClassChild({filter, id: id}))
  ); 

  const save = () => {
    dispatch(addChild({groupId:id, childId: currentChild, filter, id}));
    modal.close();
    setCurrentChild('')
  }

  return (
    <>
      <BreadCrumbs items={breadCrumbItems} />
        <Paper className="pb3 pt3 px3">
            <div className='flex justify-end'>
              <Button
                type="button"
                disabled={false}
                icon={<CreateIcon fontSize="small" className="mr1" />}
                text={t('classListPage_edit')}
                component={NavLink}
                to={`/class/add/${data.id}`}
              />
              {/*<Button*/}
              {/*  // icon={<AddIcon fontSize="small" className="mr1" />}*/}
              {/*  size="small"*/}
              {/*  text={t('classListPage_add')}*/}
              {/*  onClick={() => modal.open({})}*/}
              {/*/>*/}
            </div>
            <div className="pt2">
              <StaticTablePagination
                columns={ClassListColumns({ t, lang: i18n.language })}
                sortable={false}
                data={child.content}
                urlOnClick={row => `/contingent/school/${row.original.id}`}
                onClickLoadMore={() => nextPage()}
                totalElements={child.totalElements}
                loading={loading}
              />
            </div>
        {modal.isOpen && (
          <Modal
            open
            maxWidth="xs"
            onClose={() => {
              modal.close()
              setCurrentChild('')
            }}
            title={t('classListPage_modalAddChild')}
            disableEnforceFocus
          >
            <DialogContent>
              <form onSubmit={handleSubmit(save)}>
                <FormControl fullWidth>
                  <InputLabel margin="dense">{t('classListPage_modalSelectChild')}</InputLabel>
                  <MuiSelect
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label={t('classListPage_modalSelectChild')}
                    value={currentChild}
                    error={errors?.child && errors?.child.type === "required"}
                    {...register('child', { required: true })}
                  >
                    {
                      freeKids.map((item, index) => {
                        return <MenuItem value={item.id} onClick={() => setCurrentChild(item.id)} key={index}> {item.childName} {item.childSurname} </MenuItem>
                      })
                    }
                  </MuiSelect>
                </FormControl>
                {errors?.child && errors?.child.type === "required" && <FormHelperText error>{t('classListPage_requiredField')}</FormHelperText>}
                <div className='flex justify-end mt2'>
                  <Button
                    icon={<SaveIcon fontSize="small" className="mr1" />}
                    size="small"
                    text={t('userModal_save')}
                    type='submit'
                  />
                </div>
              </form>              
            </DialogContent>
          </Modal>
        )}
      </Paper>
    </>
  );
}

export default ClassListPage;