import { addTranslation } from '../../_helpers/Translate';

addTranslation({
  ru: {
    groupListPage_edit: 'Редактировать данные группы',
    groupListPage_nameGroup: 'Наименование группы:',
    groupListPage_numberGroup: 'Номер группы:',
    groupListPage_beginAcademicYear: 'Учебный год:',
    groupListPage_ageGroup: 'Возрастная группа',
    groupListPage_language: 'Язык обучения:',
    groupListPage_stateOrder: 'Госзаказ:',
    groupListPage_organizationNumber: 'Номер организации:',
    groupListPage_bin: 'БИН организации:',
    groupListPage_legalLocality: 'Населенный пункт:',
    groupListPage_add: 'Добавить',
    groupListPage_applicationNumberEnrollment: 'Номер заявления по зачислению',
    groupListPage_enrollmentDate: 'Дата зачисления',
    groupListPage_childIin: 'ИИН ребенка',
    groupListPage_fullName: 'ФИО ребенка',
    groupListPage_queuedType: 'Тип очереди',
    groupListPage_privilegeType: 'Вид льготы',
    groupListPage_orderNum: 'Номер приказа',
    groupListPage_orderDate: 'Дата приказа',
    groupListPage_columnLanguage: 'Язык обучения',
    groupListPage_columnAction: 'Действие',
    groupListPage_columnDelete: 'Удалить',
    groupListPage_modalAddChild: 'Добавить ребенок',
    groupListPage_modalSelectChild: 'Выберите ребенок',
    groupListPage_yes: 'Да',
    groupListPage_no: 'Нет',
    groupListPage_requiredField: 'Обязательное для заполнения',
    groupListPage_errorLoadTable: 'Не удалось загрузить группy',
    groupListPage_errorLoadChild: 'Не удалось загрузить список детей',
    groupListPage_errorSaveForm: 'Не удалось сохранить данные',
    groupListPage_saveFormSuccess: 'Данные успешно сохранены',
    groupListPage_deleteSuccess: 'Ребенок успешно удален',
    groupListPage_deleteError: 'Ошибка при удалении ребенок',
    groupListPage_wantDelete: 'Вы действительно хотите удалить?',
    groupListPage_errorRemove: 'Не удалось удалить элемент',
    groupListPage_deleteSuccessSave: 'Элемент успешно удаленно',
  },
  kk: {
    groupListPage_edit: 'Топ деректерін өзгерту',
    groupListPage_nameGroup: 'Топ аты',
    groupListPage_numberGroup: 'Топ нөмірі:',
    groupListPage_beginAcademicYear: 'Оқу жылы',
    groupListPage_ageGroup: 'Жас тобы',
    groupListPage_language: 'Оқыту тілі',
    groupListPage_stateOrder: 'Мемлекеттік тапсырыс',
    groupListPage_organizationNumber: 'Ұйым нөмірі:',
    groupListPage_bin: 'Ұйымның БСН',
    groupListPage_legalLocality: 'Аймақ',
    groupListPage_add: 'Қосу',
    groupListPage_applicationNumberEnrollment: 'Тіркелу үшін өтініш нөмірі',
    groupListPage_enrollmentDate: 'Тіркелу күні',
    groupListPage_childIin: 'Баланың ЖСН',
    groupListPage_fullName: 'Баланың аты-жөні',
    groupListPage_queuedType: 'Кезек түрі',
    groupListPage_privilegeType: 'Жеңілдік түрі',
    groupListPage_orderNum: 'Бұйрық нөмірі',
    groupListPage_orderDate: 'Бұйрық күні',
    groupListPage_columnAction: 'Әрекет',
    groupListPage_columnDelete: 'Өшіру',
    groupListPage_modalAddChild: 'Баланы қосу',
    groupListPage_modalSelectChild: 'Баланы таңдаңыз',
    groupListPage_yes: 'Иә',
    groupListPage_no: 'Жоқ',
    groupListPage_requiredField: 'Толтыру үшін міндетті',
    groupListPage_errorLoadTable: 'Топты жүктеу мүмкін болмады',
    groupListPage_errorLoadChild: 'Балалар тізімін жүктеу мүмкін болмады',
    groupListPage_errorSaveForm: 'Деректерді сақтау мүмкін болмады',
    groupListPage_saveFormSuccess: 'Деректер сәтті сақталды',
    groupListPage_deleteSuccess: 'Элемент сәтті жойылды',
    groupListPage_deleteError: 'Баланы жою кезіндегі қате',
    groupListPage_wantDelete: 'Жойғыңыз келетініне сенімдісіз бе?',
    groupListPage_errorRemove: 'Элементті жою мүмкін болмады',
    groupListPage_deleteSuccessSave: 'Элемент сәтті жойылды',
  }
});
