import React from 'react';
import { useTranslation } from 'react-i18next';
import './GroupsTableTranslate';
import Button from '../../_ui/Button/Button';
import useSimpleModal from '../../components/_hooks/useSimpleModal';
import Modal from '../../_ui/Modal/Modal';
import { DialogContent } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { IconButton, Tooltip } from '@material-ui/core';
import { Color } from '../../components/ThemeProvider/ThemeStyle';

function RemoveBtn({original, onDelete}) {
  const { t } = useTranslation();
  const modal = useSimpleModal();
  return (
    <>
      <Tooltip placement="top" title={t('groupsTablePage_tableActionDelete')}>
        <IconButton
          color="secondary"
          size="small"
          children={<DeleteIcon fontSize="small"/>}
          onClick={(e) => {
            e.preventDefault()
            modal.open({})
          }}
        />
      </Tooltip>
        {modal.isOpen && (
          <Modal
            open
            maxWidth="xs"
            onClose={() => {
              modal.close()
            }}
            title={t('groupListPage_wantDelete')}
            disableEnforceFocus
          >
            <DialogContent>
              <div className='flex justify-end'>
                <Button
                  style={{ marginLeft: 10, backgroundColor: Color.bgColorSuccess }}
                  size="small"
                  color="primary"
                  text={t('groupListPage_no')}
                  onClick={() => modal.close()}
                />
                <Button
                  style={{ marginLeft: 10 }}
                  size="small"
                  color="secondary"
                  text={t('groupListPage_yes')}
                  onClick={() => {
                    onDelete(original.id);
                    modal.close();
                  }}
                />
              </div>
            </DialogContent>
          </Modal>
        )}
    </>
  );
}

export default RemoveBtn;