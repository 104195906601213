import styled from 'styled-components';
import { withStyles, Checkbox as JustCheckbox } from '@material-ui/core';
import { Color } from '../../components/ThemeProvider/ThemeStyle';
import CircularProgress from '@material-ui/core/CircularProgress';

export const ButtonsWrapper = styled.div`
  position: sticky;
  bottom: 0;
  background-color: ${Color.bgColorPaper};
  padding: 5px 0;
  text-align: right;
  border-top: 1px solid ${Color.border};
  margin-top: 30px;
  z-index: 1;
  box-shadow: 0 -6px 6px -6px rgba(0, 0, 0, 0.16);
  > button {
    margin: 3px;
  }
`;

export const ErrorLoadingFile = styled.div`
  color: ${Color.secondary};
  padding: 30px;
  white-space: pre-line;
  background-color: rgba(0, 0, 0, 0.05);
`;

export const Loading = withStyles(() => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%'
  }
}))(CircularProgress);

export const CheckBox = withStyles(() => ({
  root: {
    padding: 0,
    margin: '-5px 0'
  }
}))(JustCheckbox);
