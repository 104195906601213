import i18next from 'i18next';
import { useEffect } from 'react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import useTableFilter from '../../components/TableFilter/useTableFilter';
import { statusColor } from '../../utils/statusColors';
import { kezekStopListModule, loadData, clearState } from './KezekStopListDucks';
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs';
import StaticTablePagination from '../../_ui/Table/StaticTablePaginated';
import { kezekStopListColumns } from './KezekStopListColumns';
import './KezekStopListTranslate';
import { useTranslation } from 'react-i18next';
import paths from '../../_helpers/paths';

export default function KezekStopListPage() {
  const breadCrumbItems = [
    { name: i18next.t('breadcrumbs_home'), href: '/' },
    { name: i18next.t('breadcrumbs_kezekStopList') }
  ];
  const { t, i18n } = useTranslation();
  const loading = useSelector(state => state[kezekStopListModule].loadingTable);
  const data = useSelector(state => state[kezekStopListModule].tableData);
  const dispatch = useDispatch();
  const { nextPage } = useTableFilter(filter => dispatch(loadData(filter)));

  useEffect(() => {
    dispatch(clearState());
  }, [dispatch]);

  return (
    <>
      <BreadCrumbs items={breadCrumbItems} />
      <Paper className="pb3 pt1 px3">
        <h3>{t('kezekStopList_title')}</h3>
        <StaticTablePagination
          columns={kezekStopListColumns({ t, lang: i18n.language })}
          data={data.content}
          sortable={false}
          urlOnClick={row =>
            row.original.requestFormId
              ? paths.statementViewPage.replace(':id', row.original.requestFormId)
              : null
          }
          totalElements={data.totalElements}
          loading={loading}
          onClickLoadMore={() => nextPage()}
          rowBgColor={row => statusColor[row.original.resolutionType]}
        />
      </Paper>
    </>
  );
}
