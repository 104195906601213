import { addTranslation } from '../../_helpers/Translate';

addTranslation({
  ru: {
    queuePage_tab1: 'Список очередей',
    queuePage_tab2: 'Поиск по номеру ПЭП',
    queuePage_errorLoadTable: 'Не удалось загрузить данные',
    queuePage_errorCancelQueue: 'Не удалось сбросить очередь',
    queuePage_requestId: 'Номер ПЭП',
    queuePage_search: 'Поиск',
    queuePage_name: 'Наименование',
    queuePage_fromDate: 'дата с',
    queuePage_toDate: 'дата по',
    queuePage_actions: 'Действие',
    queuePage_cancelQueue: 'Сброс очереди',
  },
  kk: {}
});
