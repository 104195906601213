import React, { useEffect, useState } from 'react';
import { Wrapper } from './KezekBulletinStyle';
import KezekBulletinInfo from './KezekBulletinInfo';
import { useSelector, useDispatch } from 'react-redux';
import {
  kezekBulletinModule,
  loadData,
  clearState,
  saveFrom
} from './KezekBulletinDucks';
import PageLoader from '../../components/PageLoader/PageLoader';
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary';
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs';
import { ErrorMessage } from './KezekBulletinStyle';
import Paper from '@material-ui/core/Paper';
import {
  getFormValues,
  PassportForm,
  PassportNavigation,
  PassportProvider
} from '../../passport/Passport';
import { PassportExpander } from '../../passport/Passport';
import './KezekBulletinTranslate';
import { Color } from '../../components/ThemeProvider/ThemeStyle';
import { useTranslation } from 'react-i18next';
import Button from '../../_ui/Button/Button';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CancelIcon from '@material-ui/icons/Cancel';
import ApproveIcon from '@material-ui/icons/Done';
import { ButtonsWrapper } from '../KezekPassportPage/KezekPassportStyle';
import useUser from '../LoginPage/useUser';
import paths from '../../_helpers/paths';
import useSimpleModal from '../../components/_hooks/useSimpleModal';
import ModalDelete from './modals/ModalDelete';
import ModalCreateRevoke from './modals/ModalCreateRevoke';
import { BULLETIN_STATUS } from '../../_helpers/Constants';
import ModalApproveRevoke from './modals/ModalApproveRevoke';
import ModalRefusedRevoke from './modals/ModalRefusedRevoke';
import Access from '../../components/Access';
import permissions from '../../_helpers/permissions';
import Divider from '@material-ui/core/Divider';
import useHasAccess from '../../components/useHasAccess';

export default function KezekBulletinPage({ match }) {
  const loading = useSelector(state => state[kezekBulletinModule].loading);
  const loadingSave = useSelector(state => state[kezekBulletinModule].loadingSave);
  const loadingRefuse = useSelector(state => state[kezekBulletinModule].loadingRefuse);
  const loadingRevoke = useSelector(state => state[kezekBulletinModule].loadingRevoke);
  const loadingDelete = useSelector(state => state[kezekBulletinModule].loadingDelete);
  const loadingApprove = useSelector(state => state[kezekBulletinModule].loadingApprove);

  const { t, i18n } = useTranslation();
  const id = match.params.id;
  const [editMode, setEditMode] = useState(!id);
  const dispatch = useDispatch();
  const error = useSelector(state => state[kezekBulletinModule].error);
  const info = useSelector(state => state[kezekBulletinModule].info);
  const data = useSelector(state => state[kezekBulletinModule].data);
  const meta = useSelector(state => state[kezekBulletinModule].meta);
  const readOnly =
    data.statusWeb !== BULLETIN_STATUS.EXPECT_PUBLICATION && id !== undefined;
  const user = useUser();
  const deleteModal = useSimpleModal();
  const approveModal = useSimpleModal();
  const createRevokeModal = useSimpleModal();
  const refusedModal = useSimpleModal();
  const isMio = useHasAccess(permissions.MIO_EMPLOYEE)

  const breadCrumbItems = [
    { name: t('breadcrumbs_home'), href: '/' },
    isMio
      ? { name: t('breadcrumbs_kezekRequest'), href: paths.kezekRequest }
      : { name: t('breadcrumbs_kezekBulletin'), href: paths.kezekBulletinTable },
    { name: t('kezekBulletinPage_title') }
  ];

  useEffect(() => {
    dispatch(loadData(id));
    return () => dispatch(clearState());
  }, [dispatch, id]);

  if (loading) return <PageLoader />;
  if (error) {
    return (
      <>
        <BreadCrumbs items={breadCrumbItems} />
        <Paper className="p3">
          <ErrorMessage>
            <code>{error}</code>
          </ErrorMessage>
        </Paper>
      </>
    );
  }

  return (
    <>
      <BreadCrumbs items={breadCrumbItems} />
      <Wrapper className="px3 pb3 pt1">
        <ErrorBoundary>
          <PassportProvider
            withoutScroll={false}
            stickyTop={Color.headerHeight}
            info={info}
            data={data}
            meta={meta}
            config={{
              documentsProps: {
                mimeType: 'image/jpeg,image/png,application/pdf',
                maxSize: 10000000,
              },
            }}
          >
            <PassportExpander />
            <div className="flex">
              {id !== undefined && (
                <div className="left-info">
                  <KezekBulletinInfo />
                  <div className="card p2 mb2">
                    <div className="mb1 bold">{t('kezekBulletinPage_countBulletin')}</div>
                    <Divider />
                    <div className="mt1">
                      {data.suoStatus &&
                        data.suoStatus
                          .filter(i => i.code !== 'REMOVED')
                          .map(item => (
                            <div className="flex justify-between">
                              <div>{item[`${i18n.language}_name`]}</div>
                              <div>{item.count}</div>
                            </div>
                          ))}
                    </div>
                  </div>
                  <PassportNavigation />
                </div>
              )}
              <div className="ml2 flex-auto">
                <PassportForm loading={loading} disabled={readOnly || !editMode} />
              </div>
            </div>
          </PassportProvider>
          <ButtonsWrapper>
            {!readOnly && !editMode && (
              <Button
                icon={<EditIcon fontSize="small" className="mr1" />}
                disabled={loading}
                size="small"
                text={t('kezekBulletinPage_edit')}
                onClick={() => setEditMode(true)}
              />
            )}
            {!readOnly && editMode && (
              <Button
                icon={<SaveIcon fontSize="small" className="mr1" />}
                loading={loadingSave}
                disabled={loading}
                size="small"
                text={t(id ? 'kezekBulletinPage_saveForm' : 'kezekBulletinPage_add')}
                onClick={() => {
                  getFormValues().then(values => {
                    if (values)
                      dispatch(saveFrom(data, values, user.organization_id, setEditMode));
                  });
                }}
              />
            )}

            {/* УДАЛЕНИЕ */}
            {!readOnly && id && (
              <Access ps={permissions.DDO_EMPLOYEE}>
                <Button
                  icon={<DeleteIcon fontSize="small" className="mr1" />}
                  loading={loadingDelete}
                  style={{ marginLeft: 10 }}
                  disabled={loading || loadingDelete}
                  size="small"
                  color="secondary"
                  text={t('kezekBulletinPage_delete')}
                  onClick={() => deleteModal.open(id)}
                />
              </Access>
            )}

            {/* ПОДВЕРЖДЕНИЕ ОТЗЫВА */}
            {readOnly &&
              data.statusWeb === BULLETIN_STATUS.AVAILABLE_ALL &&
              data.approvalStatus === 'check' && (
                <Access ps={permissions.MIO_EMPLOYEE}>
                  <Button
                    icon={<ApproveIcon fontSize="small" className="mr1" />}
                    loading={loadingApprove}
                    style={{ marginLeft: 10, backgroundColor: Color.bgColorSuccess }}
                    disabled={loading || loadingApprove}
                    size="small"
                    color="primary"
                    text={t('kezekBulletinPage_approveRevoke')}
                    onClick={() => approveModal.open(id)}
                  />
                </Access>
              )}

            {/* ОТМЕНА ОТЗЫВА */}
            {readOnly &&
              data.statusWeb === BULLETIN_STATUS.AVAILABLE_ALL &&
              data.approvalStatus === 'check' && (
                <Access ps={permissions.MIO_EMPLOYEE}>
                  <Button
                    icon={<CancelIcon fontSize="small" className="mr1" />}
                    loading={loadingRefuse}
                    style={{ marginLeft: 10 }}
                    disabled={loading || loadingRefuse}
                    size="small"
                    color="secondary"
                    text={t('kezekBulletinPage_refuse')}
                    onClick={() => refusedModal.open(id)}
                  />
                </Access>
              )}

            {/* ОТЗЫВ МЕСТ */}
            {readOnly &&
              data.statusWeb === BULLETIN_STATUS.AVAILABLE_ALL &&
              data.approvalStatus !== 'check' &&
              data.availableSeats > 0 && (
                <Access ps={permissions.DDO_EMPLOYEE}>
                  <Button
                    icon={<CancelIcon fontSize="small" className="mr1" />}
                    loading={loadingRevoke}
                    style={{ marginLeft: 10 }}
                    disabled={loading || loadingRevoke}
                    size="small"
                    color="secondary"
                    text={t('kezekBulletinPage_revoke')}
                    onClick={() => createRevokeModal.open(id)}
                  />
                </Access>
              )}
          </ButtonsWrapper>

          {approveModal.isOpen && <ModalApproveRevoke {...approveModal} />}
          {deleteModal.isOpen && <ModalDelete {...deleteModal} />}
          {createRevokeModal.isOpen && <ModalCreateRevoke {...createRevokeModal} />}
          {refusedModal.isOpen && <ModalRefusedRevoke {...refusedModal} />}
        </ErrorBoundary>
      </Wrapper>
    </>
  );
}
