import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DialogActions, DialogContent } from '@material-ui/core';
import XLSX from 'xlsx';

import Button from '../../_ui/Button/Button';
import Modal from '../../_ui/Modal/Modal';
import { ImportContainer } from './AdministrationStyles';
import { administrationModule, importUsers } from './AdministrationDucks';

function ImportModal({ open, onClose, loading, importUsers }) {
  const [file, setFile] = useState({});
  const { t } = useTranslation();

  const onSubmit = () => {
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    const formData = new FormData();
    formData.append('file', file);

    return reader.onload = (e) => {
      try {
        const bstr = e.target.result;
        const wb = XLSX.read(bstr, { type: 'binary', bookVBA: true, sheetRows: 2 });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws)[0];
        const columns = Object.keys(data).join(',');
        importUsers(formData, columns);
      } catch (e) {
        importUsers(formData, null);
      }
    };
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={t('administrationPage_upload')}
    >
      <DialogContent>
        <ImportContainer>
          <div id="file-name">{file.name}</div>
          <input
            id="contained-button-file"
            type="file"
            className="display-none"
            required
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            onChange={({ target }) => {
              if (target.files[0]) {
                setFile(target.files[0]);
              }
            }}
          />
          <label htmlFor="contained-button-file">
            <Button
              variant="contained"
              text={t('administrationPage_choose')}
              component="span"
            />
          </label>
        </ImportContainer>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          text={t('administrationPage_cancel')}
          color="default"
        />
        <Button
          form="import-form"
          text={t('administrationPage_import')}
          loading={loading}
          disabled={!file.name}
          onClick={onSubmit}
        />
      </DialogActions>
    </Modal>
  );
}

export default connect(
  state => ({
    loading: state[administrationModule].loadingImport
  }),
  {
    importUsers
  }
)(ImportModal);