import moment from 'moment';
import React from 'react';
import FilterInputField from '../../components/TableFilter/ColumnsFilters/FilterInputField';
import FilterDatePicker from '../../components/TableFilter/ColumnsFilters/FilterDatePicker';
import ColumnSorting from '../../components/TableFilter/ColumnsFilters/ColumnSorting';
import FilterTreeSelect from '../../components/TableFilter/ColumnsFilters/FilterTreeSelect/FilterTreeSelect';

export function kezekStopListColumns({ t, lang }) {
  return [
    {
      Header: '№',
      accessor: 'rowNumber',
      width: 60,
      filterable: false
    },
    {
      Header: <ColumnSorting title={t('kezekStopList_columnID')} name="id" />,
      accessor: 'requestFormId',
      width: 80,
      style: { textAlign: 'center' },
      Filter: <FilterInputField name="requestFormId" type="number" />
    },
    {
      Header: t('kezekStopList_childIIN'),
      accessor: 'childIin',
      style: { textAlign: 'center' },
      Filter: <FilterInputField name="childIin" type="number" />
    },
    {
      Header: t('kezekStopList_childName'),
      accessor: 'childName',
      style: { textAlign: 'center' },
      Filter: <FilterInputField name="childFullName" />
    },
    {
      Header: t('kezekStopList_requesterIIN'),
      accessor: 'requesterIin',
      style: { textAlign: 'center' },
      Filter: <FilterInputField name="requesterIin" type="number" />
    },
    {
      Header: t('kezekStopList_requesterName'),
      accessor: 'requesterName',
      style: { textAlign: 'center' },
      Filter: <FilterInputField name="requesterFullName" />
    },
    {
      Header: (
        <ColumnSorting title={t('kezekStopList_stopListDate')} name="startStopList" />
      ),
      accessor: 'startStopList',
      Cell: row => row.original.startStopList ? moment(row.original.startStopList).format('DD.MM.YYYY') : '',
      sortable: false,
      style: { textAlign: 'center' },
      Filter: <FilterDatePicker name="startStopList" lang={lang} />
    },
    {
      Header: (
        <ColumnSorting title={t('kezekStopList_deadlineStopList')} name="deadlineStopList" />
      ),
      accessor: 'deadlineStopList',
      Cell: row => row.original.deadlineStopList ? moment(row.original.deadlineStopList).format('DD.MM.YYYY') : '',
      sortable: false,
      style: { textAlign: 'center' },
      Filter: <FilterDatePicker name="deadlineStopList" lang={lang} />
    },
    {
      Header: t('kezekStopList_serviceProviderBin'),
      accessor: 'serviceProviderBin',
      style: { textAlign: 'center' },
      Filter: <FilterInputField name="serviceProviderBin" type="number" />
    },
    {
      Header: t('kezekStopList_legalLocality'),
      accessor: 'legalLocality',
      Cell: ({ original: { legalLocality } }) =>
        legalLocality[`${lang}_name`],
      Filter: (
        <FilterTreeSelect lookupId="60" name="locationCode" lang={lang} />
      )
    },
  ];
}
