import React from "react";
import Dialog from '@material-ui/core/Dialog';
import config from '../../_helpers/config';
import { Color } from '../../components/ThemeProvider/ThemeStyle';

const ManualModal = ({
  onClose,
  classes,
  selectedInstructionKey,
  t,
  language
}) => selectedInstructionKey ? (
  <Dialog
    maxWidth="lg"
    open={true}
    onClose={onClose}
    classes={classes}
  >
    <div className="p2 fs-16 fw-6">{t(`${selectedInstructionKey.substring(0, selectedInstructionKey.length - 3)}`)}</div>
    <object
      style={{ height: '100vh', width: "100%"}}
      data={config[selectedInstructionKey][language]}
    >
      <div className="bold center p3" style={{ color: Color.secondary }}>
        {t('manual_canNotViewFile')}
      </div>
    </object>
  </Dialog>
) : null

export default ManualModal;