import React from 'react';
import FilterInputField from '../../components/TableFilter/ColumnsFilters/FilterInputField';
import FilterSelect from '../../components/TableFilter/ColumnsFilters/FilterSelect';
import RemoveBtn from './RemoveBtn';

const styles = {
  textAlign: 'center',
  alignSelf: 'center'
};

export function ClassColumns({ t, lang, onDelete }) {
  return [
    {
      Header: '№',
      accessor: 'rowNumber',
      width: 60,
      filterable: false
    },
    {
      Header: t('classPage_number'),
      accessor: 'id',
      Filter: <FilterInputField name="id" type="number" />
    },
    {
      Header: t('classPage_class'),
      accessor: 'classNumber',
      Cell: ({ original: { distClassNumberType } }) => distClassNumberType[`${lang}_name`],
      Filter: <FilterSelect name="classNumber" dictCode="gu_class_type" lang={lang} />
    },
    {
      Header: t('classPage_liter'),
      accessor: 'letter',
      Cell: ({ original: { dictLiter } }) => dictLiter[`${lang}_name`],
      Filter: <FilterSelect name="letter" dictCode="39051" lang={lang} />
    },
    {
      Header: t('classPage_language'),
      accessor: 'language',
      Cell: ({ original: { languageChild } }) => languageChild[`${lang}_name`],
      Filter: <FilterSelect name="language" dictCode="gu_language" lang={lang} />
    },
    {
      Header: t('classPage_eduForm'),
      accessor: 'eduForm',
      Cell: ({ original: { distEduForm } }) => distEduForm[`${lang}_name`],
      Filter: <FilterSelect name="eduForm" dictCode="46" lang={lang} />
    },
    {
      Header: t('classPage_educators'),
      accessor: 'stateOrder',
      filterable: false,
      Cell: ({ original: {staff}}) => {
        return (
          <div>{staff.map((item) => {
            if (item.staffStatus === 'WORKING') {
              return <p style={{marginTop: 0, marginBottom: 0}}>{item.staffSurname} {item.staffName}</p>
            }
          })}</div>
        )
      }
    },
    {
      Header: t('classPage_action'),
      style: styles,
      filterable: false,
      width: 120,
      Cell: ({ original }) => (
        <div 
          style={{ margin: '-5px 0' }} 
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <RemoveBtn original={original} onDelete={onDelete}/>
        </div>
      )
    }
  ];
}
