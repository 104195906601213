import React, { useEffect, useMemo } from 'react';
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs';
import Paper from '@material-ui/core/Paper';
import { useTranslation } from 'react-i18next';
import './ClassTranslate';
import paths from '../../_helpers/paths'
import Button from '../../_ui/Button/Button';
import AddIcon from '@material-ui/icons/Add';
import { NavLink } from 'react-router-dom';
import StaticTablePagination from '../../_ui/Table/StaticTablePaginated';
import {ClassColumns} from './ClassColumns'
import { useDispatch, useSelector } from 'react-redux';
import { loadData, clearState, removeClass, classPageModule } from './ClassDucks';
import useTableFilter from '../../components/TableFilter/useTableFilter';
import { useHistory } from 'react-router-dom';
import { Color } from '../../components/ThemeProvider/ThemeStyle';
import { useTheme } from '@material-ui/core';

function ClassPage() {
  const dispatch = useDispatch();
  const { palette } = useTheme();

  const { t, i18n } = useTranslation();
  const { replace } = useHistory();

  const breadCrumbItems = useMemo(
    () => [
      {
        name: t('breadcrumbs_home'),
        href: paths.homePage
      },
      {
        name: t('classPage_title'),
        href: paths.classPage
      }
    ],
    [t]
  );
  const data = useSelector(state => state[classPageModule].data);
  const loading = useSelector(state => state[classPageModule].loading);
  const { nextPage } = useTableFilter(filter =>
    dispatch(loadData({filter}))
  );  
  useEffect(() => {
    dispatch(clearState());
  }, [dispatch]);

  const onDelete = (id) => {
    dispatch(removeClass({id}))
  }

  return (
    <>
      <BreadCrumbs items={breadCrumbItems} />
      <Paper className="pb3 pt3 px3">
        <div className='flex justify-end'>
          <Button
            icon={<AddIcon fontSize="small" className="mr1" />}
            size="small"
            text={t('classPage_add')}
            component={NavLink}
            to={'/class/add'}
          />
        </div>
        <div className="pt2">
          <StaticTablePagination
            columns={ClassColumns({ t, lang: i18n.language, onDelete, replace })}
            sortable={false}
            loading={loading}
            data={data.content}
            onClickLoadMore={() => nextPage()}
            urlOnClick={row => `/class/${row.original.id}`}
            totalElements={data.totalElements}
            rowBgColor={({row}) => {
              if (row._original.staff.length === 0) {
                return Color.isDark ? `${palette.error.dark}70` : `${palette.error.light}90`
              }
            }}
          />
        </div>
        
      </Paper>
    </>
  );
}

export default ClassPage;
