import React from 'react';
import { useTranslation } from 'react-i18next';
import DialogContent from '@material-ui/core/DialogContent';
import Modal from '../../_ui/Modal/Modal';
import { TableWrapper } from './StatementStyle';
import ServiceProvidersTable from '../ServiceProvidersPage/ServiceProvidersTable';
import { useDispatch } from 'react-redux';
import { changeServiceProvider } from './StatementDucks';

function ServiceProvidersModal({ modal }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedId = modal.data.id && parseInt(modal.data.serviceProviderId);
  const onClickRow = ({ original: { id } }) => {
    if (id !== selectedId) {
      dispatch(changeServiceProvider(modal.data.id, id));
      modal.close();
    }
  };

  return (
    <>
      <Modal
        open
        maxWidth="lg"
        title={t('statement_choose_sp')}
        onClose={modal.close}
        scroll="paper"
      >
        <DialogContent dividers>
          <TableWrapper>
            <ServiceProvidersTable
              index={2}
              rowBgColor={({ original: { id } }) =>
                id === selectedId ? 'rgba(125,125,125,0.2)' : undefined
              }
              onClickRow={onClickRow}
            />
          </TableWrapper>
        </DialogContent>
      </Modal>
    </>
  );
}

export default ServiceProvidersModal;
