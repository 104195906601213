import React from 'react';
import RequestBtnChild from './requestBtnChild/RequestBtnChild';
import SearchGroup from './searchGroup/SearchGroup'
import ResetAgeGroup from './resetAgeGroup/resetAgeGroup'
import { STATUS_DIRECTION } from '../../../_helpers/Constants';
import ResetStreetHouse from './resetStreetHouse/resetStreetHouse';

export function extraFunctionality({ code, value, id, data }) {
  if (id === 'add' && code === 'form_contingent_do_child.child_iin') {
      return <RequestBtnChild code={code} value={value} />
  }
  if (
    code === 'form_contingent_do_child.queued_type' || 
    code === 'form_contingent_do_child.age_group' ||
    code === 'form_contingent_do_child.correction_type'
  ) {
    return <SearchGroup value={value}/>
  } else if (data.statusDirection === STATUS_DIRECTION.ARRIVED_TO_PO && code === 'form_contingent_do_child.begin_academic_year') {
    return <ResetAgeGroup />
  } else if ( data.statusDirection === STATUS_DIRECTION.ARRIVED_TO_PO &&
    ( 
      code === 'form_contingent_do_child.street' ||
      code === 'form_contingent_do_child.house'
    )
  ) {
    return <ResetStreetHouse />
  } 
}
