import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { TASK_STATUS_NAMES } from '../../../_helpers/Constants';
import { capitalize, Paper } from '@material-ui/core';

const Item = ({ label, value, isDate }) => {
  if (value) {
    return (
      <div>
        <span className="bold">{label} : </span>
        <span>{isDate ? moment(value).format('DD.MM.YYYY HH:mm') : value}</span>
      </div>
    );
  }
  return null;
};

function TaskInfo({
  id,
  businessObjectId,
  serviceTypeName = {},
  status,
  startTime,
  endTime,
  data,
  multiTaskData,
  shortInfo
}) {
  const { t, i18n } = useTranslation();
  const signInfo = (data && data.signatureInfo) || {};
  const stName = serviceTypeName[`${i18n.language}_name`];
  const statusName = status && TASK_STATUS_NAMES[status][`${i18n.language}_name`];

  if (!businessObjectId) {
    return null;
  }

  if (multiTaskData) {
    return (
      <Paper className="mb2 p2" variant="outlined">
        <Item
          label={t('taskInfo_statementId')}
          value={multiTaskData
            .filter(item => item.businessObjectId)
            .map(item => item.businessObjectId)
            .join(', ')}
        />
        <Item
          label={t('taskInfo_pepNumber')}
          value={multiTaskData
            .filter(item => item.externalRequestId)
            .map(item => item.externalRequestId)
            .join(', ')}
        />
      </Paper>
    );
  }

  return (
    <Paper variant="outlined" className="mb2 p2">
      <Item label={t('taskInfo_statementId')} value={businessObjectId} />
      <Item label={t('taskInfo_requestDate')} value={shortInfo.submissionDate} isDate />
      <Item label={t('taskInfo_requesterIin')} value={shortInfo.requesterIin} />
      <Item label={t('taskInfo_pepNumber')} value={shortInfo.externalRequestId} />
      <Item
        label={t('taskInfo_serviceProviderNumber')}
        value={shortInfo.serviceProviderId}
      />
      <Item
        label={t('taskInfo_serviceProviderBin')}
        value={shortInfo.serviceProviderBin}
      />
      <Item
        label={t('taskInfo_serviceProviderIin')}
        value={shortInfo.serviceProviderIin}
      />
      <Item
        label={t('taskInfo_serviceProvider')}
        value={shortInfo[`serviceProvider${capitalize(i18n.language)}Name`]}
      />

      <Item label={t('taskInfo_number')} value={id} />
      <Item label={t('taskInfo_status')} value={statusName} />
      <Item label={t('taskInfo_startTime')} value={startTime} isDate />
      <Item label={t('taskInfo_endTime')} value={endTime} isDate />
      <Item label={t('taskInfo_statementType')} value={stName} />
      <Item label={t('taskInfo_signerName')} value={signInfo.signerName} />
      <Item label={t('taskInfo_signerDate')} value={signInfo.signedDate} isDate />
      <Item
        label={t('taskInfo_declineSignatureReason')}
        value={shortInfo.declineSignatureReason}
      />
    </Paper>
  );
}

export default TaskInfo;
