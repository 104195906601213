import { addTranslation } from '../../_helpers/Translate';

addTranslation({
  ru: {
    contingentSchool_title: 'Контингента школьники',
    contingentSchool_pupilsTitle: 'Школьники',
    contingentSchool_retiredTitle: 'Выбывшие',
    contingentSchool_id: 'ID',
    contingentSchool_photoSchool: 'Фото школьника',
    contingentSchool_iinSchool: 'ИИН школьника',
    contingentSchool_fullName: 'ФИО школьника',
    contingentSchool_dateBirth: 'Дата рождения школьника',
    contingentSchool_class: 'Класс школьника',
    contingentSchool_litera: 'Литера',
    contingentSchool_training: 'Смена обучения',
    contingentSchool_dateEnrollment: 'Дата зачисления школьника',
    contingentSchool_dateDeparture: 'Дата выбытия школьника',
    contingentSchool_disposal: 'Выбытие',
    contingentSchool_dropped: 'Выбыл',
    contingentSchool_pupilsErrorLoadTable: 'Не удалось загрузить Школьники',
    contingentSchool_exitedErrorLoadTable: 'Не удалось загрузить Выбывшие',
    contingentSchool_addBtn: 'Добавить',
    contingentSchool_studentConfirmation: 'Подтверждение школьника',
    contingentSchool_disposalConfirmation: 'Подтверждение выбытие',
    contingentSchool_addError: 'Ошибка при добавлении',
    contingentSchool_addSuccess: 'Успешно подтверждена',
    contingentSchool_errorLoadMeta: 'Не удалось загрузить мета данных',
    contingentSchool_yes: 'Подтверждаю',
    contingentSchool_changeBtn: 'Изменить',
    contingentSchool_saveSuccess: 'Успешно сохранено',
    contingentSchool_dropoutDate: 'Дата выбытия',
    contingentSchool_dropoutReason: 'Причина выбытия',
    contingentSchool_retirementError: 'Ошибка при выбытие',
    contingentSchool_reportTitle: 'Табель учета посещаемости школьника',
    contingentSchool_tableFor: 'табель для',
    contingentSchool_reportModalTitle: 'Основание отсутствия',
    contingentSchool_reportCause: 'Причина',
    contingentSchool_date: 'Дата',
    contingentSchool_startDate: 'Дата начала',
    contingentSchool_completionDate: 'Дата завершения',
    contingentSchool_saveBtn: 'Cохранить',
    contingentSchool_reference: 'Справка',
    contingentSchool_export_btn: 'Экспортировать',
    contingentSchool_export_select_date: 'Выберите период для экспорта',
    contingentSchool_export_start_date: 'Дата от',
    contingentSchool_export_end_date: 'Дата до',
    contingentSchool_reportExportError: 'Не удалось экспортировать табель',
    contingentSchool_reportErrorLoadTable: 'Не удалось загрузить табель',
    contingentSchool_causeAddSuccess: 'Успешно сохранено',
    contingentSchool_causeAddError: 'Ошибка при сохранение',
    contingentSchool_classAddError: 'Ошибка при получении классов',
    contingentSchool_noClass: 'Нет классов',
    contingentSchool_requiredField: 'Обязательное для заполнения',
    contingentSchool_classSelect: 'Класс',
    contingentSchool_reportFoodTitle: 'Табель учета питание ребенка',
    contingentSchool_tableFoodFor: 'табель для',
    contingentSchool_isShowDataTable:
      'Для отображения данных необходимо заполнить все параметры',
    contingentSchool_colFullName: 'ФИО',
    contingentSchool_colEat: 'Выдано',
    contingentSchool_freeFoodOne: 'Бесплатное ',
    contingentSchool_freeFoodTwo: 'питание',
    contingentSchool_beneficiary: 'Льготник',
    contingentSchool_refusalFreeFoodOne: 'Отказ от',
    contingentSchool_refusalFreeFoodTwo: 'бесплатного питания',
    contingentSchool_total: 'ВСЕГО',
    contingentSchool_foodYes: 'Да',
    contingentSchool_foodNo: 'Нет'
  },
  kk: {
    contingentSchool_title: 'Оқушылар контингенті',
    contingentSchool_pupilsTitle: 'Оқушы',
    contingentSchool_retiredTitle: 'Шығып кеткендер',
    contingentSchool_id: 'ID',
    contingentSchool_photoSchool: 'Оқушы фотосы',
    contingentSchool_iinSchool: 'Оқушы ЖСН-і',
    contingentSchool_fullName: 'Оқушы аты-жөні',
    contingentSchool_dateBirth: 'Оқушы туған күні',
    contingentSchool_class: 'Оқушы сыныбы',
    contingentSchool_litera: 'Литера',
    contingentSchool_training: 'Ауысым',
    contingentSchool_dateEnrollment: 'Қабылдау күні',
    contingentSchool_dateDeparture: 'Шығып кету күні',
    contingentSchool_disposal: 'Шығып кету',
    contingentSchool_dropped: 'Шығып кетті',
    contingentSchool_pupilsErrorLoadTable:
      'Оқушылар туралы мәліметтерді жүктеу сәтсіз аяқталды',
    contingentSchool_exitedErrorLoadTable:
      'Шығып кеткендер туралы мәліметтерді жүктеу сәтсіз аяқталды',
    contingentSchool_addBtn: 'Қосу',
    contingentSchool_addError: 'Қосу кезінде қате',
    contingentSchool_addSuccess: 'Сәтті расталды',
    contingentSchool_errorLoadMeta: 'Мета деректер жүктелмеді',
    contingentSchool_studentConfirmation: 'Оқушыны растау',
    contingentSchool_yes: 'Растаймын',
    contingentSchool_changeBtn: 'Өзгерту',
    contingentSchool_saveSuccess: 'Сәтті сақталды',
    contingentSchool_disposalConfirmation: 'Шығуды растау',
    contingentSchool_dropoutDate: 'Шығу күні',
    contingentSchool_dropoutReason: 'Шығу себебі',
    contingentSchool_retirementError: 'Шығуды растау кезіндегі қате',
    contingentSchool_reportTitle: 'Оқушының сабаққа қатысуын есепке алу табелі',
    contingentSchool_tableFor: 'кесте',
    contingentSchool_reportModalTitle: 'Болмау негізі',
    contingentSchool_reportCause: 'Себебі',
    contingentSchool_date: 'Күні',
    contingentSchool_startDate: 'Басталу күні',
    contingentSchool_completionDate: 'Аяқталу күні',
    contingentSchool_saveBtn: 'Сақтау',
    contingentSchool_reference: 'Анықтама',
    contingentSchool_export_btn: 'Экспорттау',
    contingentSchool_export_select_date: 'Экспорттауға арналған кезеңді таңдаңыз',
    contingentSchool_export_start_date: 'Кезеңнің басы',
    contingentSchool_export_end_date: 'Кезеңнің соңы',
    contingentSchool_reportExportError: 'Табельді экспорттау мүмкін болмады',
    contingentSchool_reportErrorLoadTable: 'Табельді жүктеу мүмкін болмады',
    contingentSchool_causeAddSuccess: 'Cәтті сақталды',
    contingentSchool_causeAddError: 'Сақтау кезінде қате',
    contingentSchool_classAddError: 'Сыныптарды алу кезіндегі қате',
    contingentSchool_noClass: 'Сыныптар жоқ',
    contingentSchool_requiredField: 'Міндетті өріс',
    contingentSchool_classSelect: 'Сынып',
    contingentSchool_reportFoodTitle: 'Баланың тамақтану табелі',
    contingentSchool_tableFoodFor: 'Табелі',
    contingentSchool_isShowDataTable:
      'Деректерді көрсету үшін барлық параметрлерді толтыру қажет',
    contingentSchool_colFullName: 'Аты-жөні',
    contingentSchool_colEat: 'Берілді',
    contingentSchool_freeFoodOne: 'Тегін ',
    contingentSchool_freeFoodTwo: 'тамақтану',
    contingentSchool_beneficiary: 'Жеілдік берілді',
    contingentSchool_refusalFreeFoodOne: 'Тегін тамақтандырудан',
    contingentSchool_refusalFreeFoodTwo: 'бас тартты',
    contingentSchool_total: 'Барлығы',
    contingentSchool_foodYes: 'Иә',
    contingentSchool_foodNo: 'Жоқ'
  }
});
