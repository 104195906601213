import { addTranslation } from '../../_helpers/Translate';

addTranslation({
  ru: {
    classItemPage_data: "Данные о классе",
    classItemPage_save: 'Сохранить',
    classItemPage_saveFormSuccess: 'Данные успешно сохранены',
    classItemPage_errorSaveForm: 'Не удалось сохранить данные',
    classItemPage_errorSaveClassDuplicate: 'Такой класс уже существует',
    classItemPage_errorLoadMeta: 'Не удалось загрузить мета данных',
    classItemPage_errorLoadItem: 'Не удалось загрузить класс',
    classItemPage_errorLoadEducators: 'Не удалось загрузить воспитателей',
    classItemPage_educators: 'Преподаватель',
    classItemPage_noEducators: 'Нет воспитателя',
    classItemPage_chooseCaregiver: 'Выберите воспитателя',
  },
  kk: {
    classItemPage_data: "Сынып туралы мәліметтер",
    classItemPage_save: 'Сақтау',
    classItemPage_saveFormSuccess: 'Деректер сәтті сақталды',
    classItemPage_errorSaveForm: 'Деректерді сақтау мүмкін болмады',
    classItemPage_errorSaveClassDuplicate: 'Мұндай класс бұрыннан бар',
    classItemPage_errorLoadMeta: 'Мета деректер жүктелмеді',
    classItemPage_errorLoadItem: 'Сынып жүктеу мүмкін болмады',
    classItemPage_errorLoadEducators: 'Мұғалімдер жүктеу мүмкін болмады',
    classItemPage_educators: 'Оқытушы',
    classItemPage_noEducators: 'Оқытушы жоқ',
    classItemPage_chooseCaregiver: 'Оқытушы таңдаңыз',
  }
});