import React from 'react';
import { connect } from 'react-redux';
import { DialogActions, DialogContent } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import { useTranslation } from 'react-i18next';

import Row from '../../../_ui/Row';
import Button from '../../../_ui/Button/Button';
import Modal from '../../../_ui/Modal/Modal';
import { ChangePassSchema } from './ChangePassValidation';
import { administrationModule, changePassword } from '../../../pages/AdministrationPage/AdministrationDucks';
import PasswordField from '../../PasswordField/PasswordField';
import Form from '../../../_ui/Form/Form';

function ChangePassModal({ loading, onClose, changePassword }) {
  const { t } = useTranslation();

  return (
    <Modal
      open
      title={<b>{t('userModal_changePassword')}</b>}
      onClose={onClose}
      scroll="body"
    >
      <Form
        initialValues={{
          currentPassword: '',
          newPassword: '',
          confirmationPassword: ''
        }}
        onSubmit={values => changePassword(values, onClose)}
        validate={ChangePassSchema}
        render={(_, { values, handleChange }) => (
          <>
            <DialogContent>
              <Row label={t('userModal_currentPassword')}>
                <PasswordField
                  value={values.currentPassword}
                  onChange={handleChange}
                  name="currentPassword"
                  placeholder={t('userModal_currentPassword')}
                />
              </Row>
              <Row label={t('userModal_newPassword')}>
                <PasswordField
                  value={values.newPassword}
                  onChange={handleChange}
                  name="newPassword"
                  placeholder={t('userModal_newPassword')}
                />
              </Row>
              <Row label={t('userModal_confirmationPassword')}>
                <PasswordField
                  value={values.confirmationPassword}
                  onChange={handleChange}
                  name="confirmationPassword"
                  placeholder={t('userModal_confirmationPassword')}
                />
              </Row>
            </DialogContent>
            <DialogActions>
              <Button
                icon={<SaveIcon fontSize="small" className="mr1"/>}
                type="submit"
                text={t('userModal_changePassword')}
                loading={loading}
                disabled={loading}
              />
              <Button
                icon={<CancelIcon fontSize="small" className="mr1" color="action"/>}
                onClick={onClose }
                text={t('userModal_cancel')}
                type="button"
                color="default"
              />
            </DialogActions>
          </>
        )}
      />
    </Modal>
  )
}

export default connect(
  state => ({
    loading: state[administrationModule].changingPassword,
  }),
  { changePassword }
)(ChangePassModal);
