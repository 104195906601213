import { createReducer } from 'redux-starter-kit';
import { handleError } from '../../utils/handleError';
import Notice from '../../utils/Notice';
import { ServiceProvidersApi } from '../../_helpers/service';
import i18next from 'i18next';
import Dict from '../../utils/Dict';

export const serviceProvidersModule = 'serviceProviders';
const LOADING = `${serviceProvidersModule}/LOADING`;
const TABLE_DATA = `${serviceProvidersModule}/TABLE_DATA`;
const SET_CHILD = `${serviceProvidersModule}/SET_CHILD_SERVICE_PROVIDER`;
const LOADING_CHILD = `${serviceProvidersModule}/LOADING_CHILD`;
const SET_HEIR_CLOSE = `${serviceProvidersModule}/SET_HEIR_CLOSE`;
const HEIR_TABLE_DATA = `${serviceProvidersModule}/HEIR_TABLE_DATA`;
const SET_HIRE_CHILD = `${serviceProvidersModule}/SET_HIRE_CHILD`;
const LOADING_HEIR_TABLE = `${serviceProvidersModule}/LOADING_HEIR_TABLE`;
const CLEAR_STATE = `${serviceProvidersModule}/CLEAR_STATE`;

/**
 * Reducer
 */

const initialState = {
  loading: false,
  tableData: {
    content: [],
    totalElements: 0
  },
  heirTableData: {
    content: [],
    totalElements: 0
  },
  loadingHeirTable: false,
  loadingChild: null
};

export default createReducer(initialState, {
  [LOADING]: (state, action) => {
    state.loading = action.payload;
  },
  [TABLE_DATA]: (state, action) => {
    const filter = action.filter || {};
    if (filter.pageNumber && filter.pageNumber !== 1) {
      state.tableData.content = [...state.tableData.content, ...action.payload.content];
    } else {
      state.tableData = action.payload;
    }
  },
  [SET_CHILD]: (state, action) => {
    state.tableData.content = action.payload;
  },
  [SET_HEIR_CLOSE]: (state, action) => {
    state.tableData.content = action.payload;
  },
  [HEIR_TABLE_DATA]: (state, action) => {
    const filter = action.filter || {};
    if (filter.pageNumber && filter.pageNumber !== 1) {
      state.heirTableData.content = [
        ...state.heirTableData.content,
        ...action.payload.content
      ];
    } else {
      state.heirTableData = action.payload;
    }
  },
  [SET_HIRE_CHILD]: (state, action) => {
    state.heirTableData.content = action.payload;
  },
  [LOADING_HEIR_TABLE]: (state, action) => {
    state.loadingHeirTable = action.payload;
  },
  [LOADING_CHILD]: (state, action) => {
    state.loadingChild = action.payload;
  },
  [CLEAR_STATE]: () => initialState
});

/**
 * Actions
 */

export const loadTableData = filter => async dispatch => {
  try {
    dispatch({ type: LOADING, payload: true });
    const filterStr = JSON.stringify({ ...filter, rootsOnly: true });
    let { data } = await ServiceProvidersApi.loadTableData(filterStr);
    if (data.status === 'SUCCESS') {
      const serviceProviderType = await Dict.itemsObject('gu_serviceProvider_type');
      const locations = await Dict.itemsObject('60');

      for (let row of data.result.content) {
        row.serviceProviderTypeName = '';
        row.locationName = '';
        if (row['location']) {
          const locationItem = locations[row.location] || {};
          row.locationName = locationItem[`${i18next.language}_name`];
        }
        if (row['serviceProviderType']) {
          const spItem = serviceProviderType[row['serviceProviderType']] || {};
          row.serviceProviderTypeName = spItem[`${i18next.language}_name`];
        }
      }

      dispatch({ type: TABLE_DATA, payload: data.result, filter });
    } else {
      handleError(data, i18next.t('serviceProviders_errorLoadTable'));
    }
  } catch (error) {
    handleError(error, i18next.t('serviceProviders_errorLoadTable'));
  } finally {
    dispatch({ type: LOADING, payload: false });
  }
};

export const loadChild = parentId => async (dispatch, getState) => {
  try {
    const setSubProviders = (tableData, data) => {
      let result = [];
      for (let item of tableData) {
        if (item.id === parentId) {
          result.push({ ...item, subServiceProviders: data.result.content });
        } else {
          if (item.subServiceProviders) {
            const subServiceProviders = setSubProviders(item.subServiceProviders, data);
            let newItem = { ...item, subServiceProviders: subServiceProviders };
            result.push(newItem);
          } else {
            result.push(item);
          }
        }
      }
      return result;
    };

    dispatch({ type: LOADING_CHILD, payload: parentId });
    const { tableData } = getState()[serviceProvidersModule];
    let { data } = await ServiceProvidersApi.loadTableData(
      JSON.stringify({ parentServiceProviderId: parentId })
    );
    if (data.status === 'SUCCESS') {
      let newData = setSubProviders(tableData.content, data);
      dispatch({
        type: SET_CHILD,
        payload: newData
      });
    } else {
      handleError(data.result.content, i18next.t('serviceProviders_errorLoadTable'));
    }
  } catch (error) {
    handleError(error, i18next.t('serviceProviders_errorLoadTable'));
  } finally {
    dispatch({ type: LOADING_CHILD, payload: null });
  }
};

export const loadHeirChild = parentId => async (dispatch, getState) => {
  try {
    const setSubProviders = (tableData, data) => {
      let result = [];
      for (let item of tableData) {
        if (item.id === parentId) {
          result.push({ ...item, subServiceProviders: data.result.content });
        } else {
          if (item.subServiceProviders) {
            const subServiceProviders = setSubProviders(item.subServiceProviders, data);
            let newItem = { ...item, subServiceProviders: subServiceProviders };
            result.push(newItem);
          } else {
            result.push(item);
          }
        }
      }
      return result;
    };

    dispatch({ type: LOADING_CHILD, payload: parentId });
    const { heirTableData } = getState()[serviceProvidersModule];
    let { data } = await ServiceProvidersApi.loadTableData(
      JSON.stringify({ parentServiceProviderId: parentId })
    );
    if (data.status === 'SUCCESS') {
      let newData = setSubProviders(heirTableData.content, data);
      dispatch({
        type: SET_HIRE_CHILD,
        payload: newData
      });
    } else {
      handleError(data, i18next.t('serviceProviders_errorLoadTable'));
    }
  } catch (error) {
    handleError(error, i18next.t('serviceProviders_errorLoadTable'));
  } finally {
    dispatch({ type: LOADING_CHILD, payload: null });
  }
};

export const loadHeirTableData = filter => async dispatch => {
  try {
    dispatch({ type: LOADING_HEIR_TABLE, payload: true });
    let { data } = await ServiceProvidersApi.loadTableData(JSON.stringify(filter));
    if (data.status === 'SUCCESS') {
      dispatch({ type: HEIR_TABLE_DATA, payload: data.result, filter });
    } else {
      handleError(data, i18next.t('serviceProviders_errorLoadTable'));
    }
  } catch (error) {
    handleError(error, i18next.t('serviceProviders_errorLoadTable'));
  } finally {
    dispatch({ type: LOADING_HEIR_TABLE, payload: false });
  }
};

export const reopenHeir = (id, modalClose) => async (dispatch, getState) => {
  try {
    dispatch({ type: LOADING_HEIR_TABLE, payload: true });
    const { tableData } = getState()[serviceProvidersModule];
    let { data } = await ServiceProvidersApi.reopenHeir(id, {});
    if (data.status === 'SUCCESS') {
      Notice.success(i18next.t('serviceProviders_heirReopenedSuccess'));
      const newDate = tableData.content.map(item =>
        item.id === data.result.id ? data.result : item
      );
      dispatch({ type: SET_HEIR_CLOSE, payload: newDate });
      modalClose();
    } else {
      handleError(data, i18next.t('serviceProviders_heirReopenedError'));
    }
  } catch (error) {
    handleError(error, i18next.t('serviceProviders_heirReopenedError'));
  } finally {
    dispatch({ type: LOADING_HEIR_TABLE, payload: false });
  }
};

export const closeHeir = (id, parentId, delegateServiceProviderId, modalClose) => async (
  dispatch,
  getState
) => {
  try {
    const setSubProviders = (tableData, data) => {
      let result = [];
      for (let item of tableData) {
        if (item.id === parentId) {
          let count =
            item.subServiceProvidersCount !== 0 ? item.subServiceProvidersCount - 1 : 0;
          result.push({ ...item, subServiceProvidersCount: count });
        } else if (item.id === id) {
          item.parentServiceProviderId
            ? item.parentServiceProviderId !== parentId &&
              result.push({ ...item, ...data.result })
            : result.push({ ...item, ...data.result });
        } else {
          if (item.subServiceProviders) {
            const subServiceProviders = setSubProviders(item.subServiceProviders, data);
            let newItem = { ...item, subServiceProviders: subServiceProviders };
            result.push(newItem);
          } else {
            result.push(item);
          }
        }
      }
      return result;
    };

    const { tableData } = getState()[serviceProvidersModule];
    dispatch({ type: LOADING_HEIR_TABLE, payload: true });
    let { data } = await ServiceProvidersApi.closeHeir(id, {
      delegateServiceProviderId
    });
    if (data.status === 'SUCCESS') {
      let newData = setSubProviders(tableData.content, data);
      Notice.success(i18next.t('serviceProviders_heirCloseSuccess'));
      dispatch({ type: SET_HEIR_CLOSE, payload: newData });
      modalClose();
    } else {
      handleError(data, i18next.t('serviceProviders_heirCloseError'));
    }
  } catch (error) {
    handleError(error, i18next.t('serviceProviders_heirCloseError'));
  } finally {
    dispatch({ type: LOADING_HEIR_TABLE, payload: false });
  }
};

export const clearState = () => ({ type: CLEAR_STATE });
