import { createReducer } from 'redux-starter-kit';
import { handleError } from '../../utils/handleError';
import { GroupApi } from '../../_helpers/service';
import Notice from '../../utils/Notice';
import i18next from 'i18next';
import Dict from '../../utils/Dict';


/**
 * Constants
 */

export const groupsTableModule = 'groupsTable';
const LOADING = `${groupsTableModule}/LOADING`;
const SET_DATA = `${groupsTableModule}/SET_DATA`;
const CLEAR_STATE = `${groupsTableModule}/CLEAR_STATE`;

/**
 * Reducer
 */

const initialState = {
  loading: false,
  loadingSave: false,
  data: {
    content: [],
    totalElements: 0

  },
};

export default createReducer(initialState, {
  [SET_DATA]: (state, action) => {
    const filter = action.filter || {};
    if (filter.pageNumber && filter.pageNumber !== 1) {
      state.data.content = [...state.data.content, ...action.payload.content];
    } else {
      state.data = action.payload;
    }
  },
  [LOADING]: (state, action) => {
    state.loading = action.payload;
  },
  [CLEAR_STATE]: () => initialState
});

/**
 * Actions
 */

export const loadData = ({filter}) => async dispatch => {
  try {
    dispatch({ type: LOADING, payload: true });
    let hasKey = Reflect.has(filter, 'stateOrder');
    if (hasKey) {
      if (filter['stateOrder'] === 'true') {
        filter['stateOrder'] = true;
      } else {
        filter['stateOrder'] = false;
      }
    }
    let {data} = await GroupApi.getGroups(JSON.stringify(filter));
    if (data.status === 'SUCCESS') {
      const dictAgeGroup = await Dict.itemsObject('gu_age_group');
      const dictQueuedType = await Dict.itemsObject('gu_queued_type');
      const dictDirectionStatus = await Dict.itemsObject('gu_status_direction');
      const dictAcademicYear = await Dict.itemsObject('gu_academic_year_do');
      const dictlanguage = await Dict.itemsObject('gu_language');
      const dictPrivilege = await Dict.itemsObject('gu_privilege');  
      for (let row of data.result.content) {
        row.ageGroupName = dictAgeGroup[row.ageGroup] || {};
        row.queuedTypeName = dictQueuedType[row.queuedType] || {};
        row.directionStatus = dictDirectionStatus[row.statusDirection] || {};
        row.academicYearName = dictAcademicYear[row.beginAcademicYear] || {};
        row.languageChild = dictlanguage[row.language] || {};
        row.privilege = dictPrivilege[row.privilegeType] || {};
      }
    }
    dispatch({ type: SET_DATA, payload: data.result, filter });
  } catch (error) {
    handleError(error, i18next.t('groupsTablePage_errorLoadTable'));
  } finally {
    dispatch({ type: LOADING, payload: false });
  }
};

export const clearState = () => ({ type: CLEAR_STATE });

export const removeGroup = ({groupId}) => async (dispatch, getState) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const state = getState()[groupsTableModule].data;
    const stateCopy = JSON.parse(JSON.stringify(state.content));
    const {data} = await GroupApi.removeGroup({groupId});
    dispatch({ 
      type: SET_DATA, 
      payload: { 
        content: stateCopy.filter((item) => {
          return item.id !== data.result.id
        }), 
        totalElements: state.totalElements - 1  
      } 
    })
    Notice.success(i18next.t('groupsTablePage_deleteSuccess'));
  } catch (error) {
    if (error.response.data.errors[0].code === 'error.group_deletion_with_children') {
      Notice.error(i18next.t('groupsTablePage_errorRemoveInfo'))
    } else {
      handleError(error, i18next.t('groupsTablePage_errorRemove'));
    }
  } finally {
    dispatch({ type: LOADING, payload: false });    
  }
} 
