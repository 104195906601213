import React from 'react';
import moment from 'moment';
import FilterInputField from '../../components/TableFilter/ColumnsFilters/FilterInputField';
import { Color } from '../../components/ThemeProvider/ThemeStyle';

const styles = {
  textAlign: 'center',
  alignSelf: 'center'
};

export const ContingentSchoolRpfColumns = ({ t, days }) => {
  let newDays = [];
  days &&
    days.forEach(item => {
      newDays.push({
        Header: item,
        accessor: String(`${item}`),
        Cell: ({ original }) => {
          let currentOrder = null;
          let text = null;
          if (original?.ordersInfo) {
            original.ordersInfo.forEach(orderInfo => {
              if (moment(orderInfo.orderDate).format('DD') === item) {
                currentOrder = orderInfo;
              }
            });
          }
          return (
            <>
              {currentOrder?.orderStatus === 'DECLINED' && (
                <div
                  style={{
                    backgroundColor:
                      (currentOrder.absenceReason && Color.grey) || Color.grey,
                    textAlign: 'center',
                    transform: 'scale(2)',
                    marginTop: 1,
                    display: 'list-item',
                    cursor: 'pointer'
                  }}
                >
                  <span
                    style={{ transform: 'scale(0.45)', display: 'block', color: '#000' }}
                  >
                    {text}
                  </span>
                </div>
              )}
              {currentOrder?.orderStatus === 'ACCEPTED' && (
                <div
                  style={{
                    backgroundColor:
                      (currentOrder.absenceReason && Color.injury) || Color.secondary,
                    textAlign: 'center',
                    transform: 'scale(2)',
                    marginTop: 1,
                    display: 'list-item',
                    cursor: 'pointer'
                  }}
                >
                  <span
                    style={{ transform: 'scale(0.45)', display: 'block', color: '#000' }}
                  >
                    {text}
                  </span>
                </div>
              )}
              {currentOrder?.orderStatus === 'COMPLETED' && (
                <div
                  style={{
                    backgroundColor: Color.bgColorSuccess,
                    textAlign: 'center',
                    transform: 'scale(2)',
                    marginTop: 1,
                    display: 'list-item',
                    cursor: 'pointer'
                  }}
                >
                  <span style={{ transform: 'scale(0.55)', display: 'block' }}> + </span>
                </div>
              )}
            </>
          );
        },
        Filter: <span></span>
      });
    });
  let daysTable = [
    {
      Header: '№',
      accessor: 'rowNumber',
      maxWidth: 100,
      width: 100,
      filterable: false,
      Cell: (row) => row.original.iin && row.index + 1,
    },
    {
      Header: 'ID',
      accessor: 'id',
      Filter: <FilterInputField name="id" type="number" />,
      maxWidth: 150,
      width: 150,
      Cell: ({ original }) => {
        return <div>{original.id && original.id}</div>;
      }
    },
    {
      Header: t('contingentSchool_colFullName'),
      accessor: 'studentFullname',
      Filter: <FilterInputField name="studentFullname" />,
      Cell: ({ original }) => {
        return (
          <div style={{ fontWeight: original.totalFinish ? 600 : null }}>
            {original.studentSurname} {original.studentName} {original?.studentMiddlename}
          </div>
        );
      },
      maxWidth: 470,
      width: 470
    },
    ...newDays,
    {
      Header: t('contingentSchool_colEat'),
      accessor: 'totalAte',
      Filter: <span></span>,
      maxWidth: 200,
      width: 200,
      style: styles
    },
    {
      Header: (
        <div>
          <div>{t('contingentSchool_freeFoodOne')}</div>
          <div>{t('contingentSchool_freeFoodTwo')}</div>
        </div>
      ),
      accessor: 'isFreeMeal',
      Filter: <span></span>,
      maxWidth: 250,
      width: 450,
      style: styles,
      Cell: ({ original }) => {
        return (
          <div>
            {original.isFreeMealAAll !== undefined ? (
              <>{original.isFreeMealAAll}</>
            ) : (
              <>
                {original.isFreeMeal
                  ? t('contingentSchool_foodYes')
                  : t('contingentSchool_foodNo')}
              </>
            )}
          </div>
        );
      }
    },
    {
      Header: t('contingentSchool_beneficiary'),
      accessor: 'isBeneficiary',
      Filter: <span></span>,
      width: 350,
      style: styles,
      Cell: ({ original }) => {
        return (
          <div>
            {original.isBeneficiaryAll !== undefined ? (
              <>{original.isBeneficiaryAll}</>
            ) : (
              <>
                {original.isBeneficiary
                  ? t('contingentSchool_foodYes')
                  : t('contingentSchool_foodNo')}
              </>
            )}
          </div>
        );
      }
    },
    {
      Header: (
        <div>
          <div>{t('contingentSchool_refusalFreeFoodOne')}</div>
          <div>{t('contingentSchool_refusalFreeFoodTwo')}</div>
        </div>
      ),
      accessor: 'isDeclined',
      Filter: <span></span>,
      width: 525,
      style: styles,
      Cell: ({ original }) => {
        return (
          <div>
            {original.isDeclinedAll !== undefined ? (
              <>{original.isDeclinedAll}</>
            ) : (
              <>
                {original.isDeclined
                  ? t('contingentSchool_foodYes')
                  : t('contingentSchool_foodNo')}
              </>
            )}
          </div>
        );
      }
    }
  ];
  return daysTable;
};
