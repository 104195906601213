import { createReducer } from 'redux-starter-kit';
import { handleError } from '../../utils/handleError';
import { JournalApi, ResponsibleApi } from '../../_helpers/service';
import Dict from '../../utils/Dict';
import i18next from 'i18next';
import Notice from '../../utils/Notice';
import { responsibleModule } from './Responsible/ResponsibleDucks';
import { P_STATUS, RAG_STATUS } from '../../_helpers/Constants';
import moment from 'moment';

/**
 * Constants
 */

export const journalModule = 'journal';
const LOADING_TABLE = `${journalModule}/LOADING_TABLE`;
const LOADING_ARCHIVE = `${journalModule}/LOADING_ARCHIVE`;
const LOADING_EXPORT_DATA = `${journalModule}/LOADING_EXPORT_DATA`;
const TABLE_DATA = `${journalModule}/TABLE_DATA`;
const CLEAR_STATE = `${journalModule}/CLEAR_STATE`;

/**
 * Reducer
 */

const initialState = {
  loadingTable: false,
  loadingArchive: false,
  loadingExport: false,
  tableData: {
    content: [],
    totalElements: 0
  }
};

export default createReducer(initialState, {
  [LOADING_TABLE]: (state, action) => {
    state.loadingTable = action.payload;
  },
  [LOADING_ARCHIVE]: (state, action) => {
    state.loadingArchive = action.payload;
  },
  [LOADING_EXPORT_DATA]: (state, action) => {
    state.loadingExport = action.payload;
  },
  [TABLE_DATA]: (state, action) => {
    const filter = action.filter || {};
    if (filter.pageNumber && filter.pageNumber !== 1) {
      state.tableData.content = [...state.tableData.content, ...action.payload.content];
    } else {
      state.tableData = action.payload;
    }
  },
  [CLEAR_STATE]: () => initialState
});

/**
 * Actions
 */

export const clearState = () => ({ type: CLEAR_STATE });

export const loadJournal = filter => async dispatch => {
  try {
    const setResponsibleName = async (data, responsibles) => {
      if (responsibles.length > 0) {
        let { data: users } = await ResponsibleApi.loadUsers(
          JSON.stringify(responsibles)
        );
        for (let item of data.result.content) {
          if (!item.assigneeName) {
            item.assigneeName = '';
            for (let user of users.result) {
              item.assignee === user.username &&
              (item.assigneeName = `${user.firstName}  ${user.lastName}  ${user.middleName}`);
            }
          }
        }
      }
    };

    dispatch({ type: LOADING_TABLE, payload: true });
    let { data } = await JournalApi.loadJournal(JSON.stringify(filter));
    if (data.status === 'SUCCESS') {
      const dictServices = await Dict.itemsObject('gu_services');
      const dictStatuses = await Dict.itemsObject('gu_status_res');
      const dictResolution = await Dict.itemsObject('gu_status_pr');
      const dictAdditional = await Dict.itemsObject('gu_additional_status');
      const dictKato = await Dict.itemsObject('60');

      let responsibles = [];
      for (let row of data.result.content) {
        row.serviceTypeName = dictServices[row.serviceType] || {};
        row.statusName = dictStatuses[row.processingStatus] || {};
        row.resolutionTypeName = dictResolution[row.resolutionType] || {};
        row.additionalStatusName = dictAdditional[row.additionalStatus] || {};
        row.location = dictKato[row.serviceProviderLocation] || {};
        row.assignee &&
          !responsibles.includes(row.assignee) &&
          responsibles.push(row.assignee);

        // Рассчитываем срок просрочки
        if (row.ragStatusValidUntil) {
          const ragDateMoment = moment(row.ragStatusValidUntil);
          row.ragDate = ragDateMoment.format('DD.MM.YYYY HH:mm');

          if (row.ragStatus === RAG_STATUS.RED) {
            const { COMPLETED, FAILED } = P_STATUS;
            if (
              [COMPLETED, FAILED].includes(row.processingStatus) &&
              row.resolutionDate
            ) {
              const resolutionDateMoment = moment(row.resolutionDate);
              const duration = moment.duration(resolutionDateMoment.diff(ragDateMoment));
              const day = duration.days();
              const hours = duration.hours();
              const minutes = duration.minutes();
              row.delayPeriod = { day, hours, minutes };
            } else {
              const duration = moment.duration(moment().diff(ragDateMoment));
              const day = duration.days();
              const hours = duration.hours();
              const minutes = duration.minutes();
              row.delayPeriod = { day, hours, minutes };
            }
          }
        }
      }
      await setResponsibleName(data, responsibles);
      dispatch({ type: TABLE_DATA, payload: data.result, filter });
    } else {
      handleError(data, i18next.t('journalDucks_errorLoadTable'));
    }
  } catch (error) {
    handleError(error, i18next.t('journalDucks_errorLoadTable'));
  } finally {
    dispatch({ type: LOADING_TABLE, payload: false });
  }
};

export const assignResponsible = (params, modalOnClose) => async (dispatch, getState) => {
  try {
    dispatch({ type: LOADING_TABLE, payload: true });
    const { tableData } = getState()[journalModule];
    const { responsibleList } = getState()[responsibleModule];
    let { data } = await JournalApi.assign(params);
    if (data.status === 'SUCCESS') {
      Notice.success(i18next.t('responsibleDucks_assignSuccess'));
      dispatch({
        type: TABLE_DATA,
        payload: {
          ...tableData,
          content: tableData.content.map(({ ...contentItem }) => {
            for (let item of data.result) {
              if (contentItem.id === item.id) {
                for (let responsible of responsibleList) {
                  if (item.assignee === responsible.username) {
                    contentItem.assignee = item.assignee;
                    contentItem.assigneeName = responsible.fullName;
                  }
                }
              }
            }
            return contentItem;
          })
        }
      });
      modalOnClose && modalOnClose();
    }
  } catch (error) {
    handleError(error, i18next.t('responsibleDucks_error_assign'));
  } finally {
    dispatch({ type: LOADING_TABLE, payload: false });
  }
};

export const archive = (data, reloadPage, closeModal) => async dispatch => {
  dispatch({ type: LOADING_ARCHIVE, payload: true });
  try {
    const response = await JournalApi.archive(data);
    if (response.data.status === 'SUCCESS') {
      reloadPage();
      closeModal();
    } else {
      handleError(response, i18next.t('journalDucks_archiveError'));
    }
  } catch (error) {
    handleError(error, i18next.t('journalDucks_archiveError'));
  } finally {
    dispatch({ type: LOADING_ARCHIVE, payload: false });
  }
};

export const exportData = (params, modalOnClose) => async dispatch => {
  dispatch({ type: LOADING_EXPORT_DATA, payload: true });
  try {
    const { serviceType, pageNumber, pageSize, ...rest } = params;
    const filter = new URLSearchParams(rest).toString();
    const response = await JournalApi.exportData(serviceType, filter);
    download(response.data, 'Журнал.xlsx');
    modalOnClose && modalOnClose();
  } catch (error) {
    handleError(error, i18next.t('journalDucks_exportError'));
  } finally {
    dispatch({ type: LOADING_EXPORT_DATA, payload: false });
  }
};

const download = (data, fileName) => {
  let element = document.createElement('a');
  document.body.appendChild(element);
  element.style.display = 'none';
  let blob = new Blob([data]);
  let url = window.URL.createObjectURL(blob);
  element.href = url;
  element.download = fileName;
  element.click();
  window.URL.revokeObjectURL(url);
};
