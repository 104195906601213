import styled from 'styled-components';
import { Tooltip, withStyles } from '@material-ui/core';
import { Color } from '../../components/ThemeProvider/ThemeStyle';

export const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    maxWidth: '400px'
  },
}))(Tooltip);

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .login-header {
    background-color: ${Color.primary};
    opacity: ${Color.isDark ? 0.3 : 1};
    height: 35vh;
  }

  .login-container {
    display: flex;
    flex-direction: column;
    background-color: ${Color.bgColorPaper};
    width: 800px;
    height: 470px;
    overflow: hidden;
    position: absolute;
    box-shadow: 0 17px 50px 0 rgba(0, 0, 0, 0.19), 0 12px 15px 0 rgba(0, 0, 0, 0.24);
    border-radius: ${Color.borderRadius};
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    padding: 30px;
    transform: translateY(-30px);

    .left-block {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      border-right: 1px solid ${Color.border};
      text-align: right;
      padding-right: 30px;
      font-size: 17px;

      img {
        margin-bottom: 10px;
      }

      .system-name {
        white-space: pre-line;
        max-width: 300px;
      }
    }

    .right-block {
      padding-left: 30px;
      flex: 1;
      display: flex;
      align-items: baseline;
      & > div {
        width: 100%;
      }
    }
  }

  .title {
    font-size: 17px;
    font-weight: 300;
    margin-bottom: 15px;
    color: ${Color.textPrimary};
  }

  .notice {
    font-size: 12px;
    margin-bottom: 20px;
    color: ${Color.secondary};
  }

  .help-text {

    color: ${Color.primary};
    > a {
      font-size: 15px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      :hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
    svg {
      margin-left: 5px;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    z-index: 999;
    -webkit-box-shadow: 0 0 0 1000px transparent inset;
    -webkit-text-fill-color: ${Color.textPrimary};
    transition: background-color 5000s ease-in-out 0s;
    -webkit-transition-delay: 9999999s;
    border-radius: 4px;
    padding: 0 !important;
    margin: 8px 14px;
  }
`;
