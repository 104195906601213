import i18next from 'i18next';
import { useEffect } from 'react';
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import useTableFilter from '../../components/TableFilter/useTableFilter';
import { statusColor } from '../../utils/statusColors';
import withTranslation from '../../components/_hoc/withTranslation';
import { kezekContingentModule, loadArchive, clearState } from './KezekContingentDucks';
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs';
import StaticTablePagination from '../../_ui/Table/StaticTablePaginated';
import { kezekContingentColumns } from './KezekContingentColumns';
import './KezekContingentTranslate';

function KezekContingentPage({ t, lang, loadingTable, tableData, loadArchive, clearState }) {
  const breadCrumbItems = [
    { name: i18next.t('breadcrumbs_home'), href: '/' },
    { name: i18next.t('breadcrumbs_kezekContingent') }
  ];

  const { nextPage } = useTableFilter(loadArchive);
  useEffect(() => clearState, [clearState]);

  return (
    <>
      <BreadCrumbs items={breadCrumbItems} />
      <Paper className="pb3 pt1 px3">
        <div className="flex justify-between items-center">
          <h3>{t('archivePage_title')}</h3>
        </div>
        <StaticTablePagination
          columns={kezekContingentColumns({ t, lang })}
          data={tableData.content}
          sortable={false}
          urlOnClick={row => `/statementView/${row.original.id}`}
          totalElements={tableData.totalElements}
          loading={loadingTable}
          onClickLoadMore={() => nextPage()}
          rowBgColor={row => statusColor[row.original.resolutionType]}
        />
      </Paper>
    </>
  );
}

export default compose(
  withTranslation,
  connect(
    state => ({
      loadingTable: state[kezekContingentModule].loadingTable,
      tableData: state[kezekContingentModule].tableData,
      tableFilter: state[kezekContingentModule].tableFilter
    }),
    { loadArchive, clearState }
  )
)(KezekContingentPage);
