import { addTranslation } from '../../_helpers/Translate';


addTranslation({
  ru: {
    instruction_title: 'Видео инструкция пользователя',
    instruction_services: 'Видео инструкции по государственным услугам',
  },
  kk: {
    instruction_title: 'Пайдаланушының бейне нұсқаулықтары',
    instruction_services: 'Мемлекеттік қызметтердің бейне нұсқаулықтары',
  }
});
