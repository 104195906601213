import Notice from './Notice';
import i18next from 'i18next';
import { addTranslation } from '../_helpers/Translate';

addTranslation({
  ru: {
    handleError_networkError: 'Проблема в настройках сети. \nВам необходимо обратиться к администратору Вашей организаций.\n' +
      'Также рекомендуем:\n' +
      '- полностью очистить кэш в браузере и авторизоваться заново;\n' +
      '- авторизоваться с другой сети;\n' +
      '- авторизоваться с другого браузера;\n' +
      '- авторизоваться с другого компьютера'
  },
  kk: {
    handleError_networkError: 'Мәселе желі параметрлерінде. \nСіз өзіңіздің ұйымыңыздың әкімшісіне жүгінуіңіз керек.\n' +
      'Сондай-ақ келесіні ұсынамыз:\n' +
      '- браузердегі кэшті толығымен тазалаңыз және қайта кіріңіз;\n' +
      '- басқа желіден кіру;\n' +
      '- басқа браузерден кіру;\n' +
      '- басқа компьютерден кіру'
  }
});

export function handleError(error, message = '', errorInMessage = true, type = 'error') {
  let errorMsg = error.message;
  if (error && error.request) {
    errorMsg = `
      STATUS: ${error.request.status},
      STATUS_TEXT: ${error.request.statusText}
      METHOD: ${error.config.method},
      URL: ${error.config.url}
      ${
      error.config.data && !error.config.url.includes('/login')
        ? `REQUEST_BODY:  ${error.config.data} \n`
        : ''
    }
    `;
    if (error.data) {
      if (error.data.results instanceof Array) {
        errorMsg += `RESPONSE_BODY: ${JSON.stringify(
          error.data.results.filter(item => item.status !== 'OK')
        )}`;
      } else {
        errorMsg += `RESPONSE_BODY: ${JSON.stringify(error.data)}`;
      }
    }
  }

  if (error && error.response) {
    errorMsg = `
      STATUS: ${error.response.status}, 
      STATUS_TEXT: ${error.response.statusText}
      METHOD: ${error.response.config.method}, 
      URL: ${error.response.config.url}
      ${
      error.response.config.data && !error.response.config.url.includes('/login')
        ? `REQUEST_BODY:  ${error.response.config.data} \n`
        : ''
    } 
    `;
    if (error.response.data) {
      errorMsg += `RESPONSE_BODY: ${JSON.stringify(error.response.data)}`;
    } else {
      errorMsg += `RESPONSE_BODY: ${JSON.stringify(error.response)}`;
    }
  }

  if (error.message === 'Network Error') {
    message += '\n\n';
    message +=  i18next.t('handleError_networkError')
    message += '\n';
  }

  console.error(message, errorMsg);

  if (message) {
    message += '\n';
    Notice[type](`${message}${errorInMessage ? errorMsg : ''}`);
  }
  return `${message}${errorMsg}`;
}
