import React, { useCallback, useEffect, useState } from 'react';
import { Select, FormControl, IconButton, MenuItem, TextField } from '@material-ui/core';
import useDict from '../../_hooks/useDict';
import { compose } from 'redux';
import withTranslation from '../../_hoc/withTranslation';
import useColumnFilter from '../useColumnFilter';
import CloseIcon from '@material-ui/icons/Close';
import { Autocomplete } from '@material-ui/lab';

function FilterAutocomplete({
  name,
  t,
  tReady,
  lang,
  dictCode,
  dictFilter,
  options,
  queryIndex = '',
  fullWidth = true,
  allowClear = true,
  placeholder,
  ...rest
}) {
  const { value, setFilter } = useColumnFilter(name, queryIndex);
  let dict = useDict(dictCode);
  if (dictFilter && typeof dictFilter === 'function') {
    dict = dict.filter(dictFilter);
  }
  const option = (options || dict).find(item => item.code === value) || {};
  const clearFiled = value &&
    allowClear && {
      IconComponent: () => (
        <IconButton
          onClick={() => setFilter(null)}
          className="show-on-hover"
          size="small"
          style={{ marginRight: 5 }}
          children={<CloseIcon fontSize="small" />}
        />
      )
    };

  return (
    <FormControl fullWidth={fullWidth} margin="none" className="left-align">
      <Autocomplete
        value={option}
        className='mr1'
        options={options || dict}
        onChange={(event, newValue) => {
          setFilter(newValue?.code)
        }}
        {...clearFiled}
        {...rest}
        noOptionsText={t('colleagues_organizationsNotFound')}
        getOptionSelected={(option, value) => {
          return option.code === value.code}
        }
        getOptionLabel={(option) => option[`${lang}_name`] || ""}
        renderInput={(params) => 
          <TextField 
            {...params}
            placeholder={placeholder}
          />
        }
        style={{ minWidth: 250 }}
      />      
    </FormControl>
  );
}

export default compose(withTranslation)(FilterAutocomplete);
