import React from 'react';
import { useSelector } from 'react-redux';
import { kezekDirectionModule } from './KezekDirectionDucks';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import capitalize from '@material-ui/core/utils/capitalize';

const getInfo = (data, t, lang) => {
  return [
    { label: t('kezekDirection_number'), value: data.id },
    {
      label: t('kezekDirection_submissionDate'),
      value: data.submissionDate
        ? moment(data.submissionDate).format('DD.MM.YYYY HH:mm')
        : ''
    },
    {
      label: t('kezekDirection_columnStatusWeb'),
      value: data.statusDirectionName[`${lang}_name`]
    },
    {
      label: t('kezekDirection_columnStatusDWeb'),
      value: data.statusDirectionWebName[`${lang}_name`]
    },
    {
      label: t('kezekDirection_time24Direction'),
      value: data.time24Direction
        ? moment(data.time24Direction).format('DD.MM.YYYY HH:mm')
        : ''
    },
    {
      label: t('kezekDirection_directionDate'),
      value: data.directionDate
        ? moment(data.directionDate).format('DD.MM.YYYY HH:mm')
        : ''
    },
    { label: t('kezekDirection_providerId'), value: data.serviceProviderId },
    { label: t('kezekDirection_BIN'), value: data.serviceProviderBin },
    {
      label: t('kezekDirection_serviceProviderName'),
      value: data[`serviceProvider${capitalize(lang)}Name`]
    },
    {
      label: t('kezekDirection_providerLocation'),
      value: data.providerLocation[`${lang}_name`]
    },
    { label: t('kezekDirection_bulletin'), value: data.bulletinSubmissionDate
        ? `${data.bulletinId} от ${moment(data.bulletinSubmissionDate).format('DD.MM.YYYY')}`
        : '' },
    { label: t('kezekDirection_requestForm'), value: data.requestFormDate
        ? `${data.requestFormId} от ${moment(data.requestFormDate).format('DD.MM.YYYY')}`
        : '' },
  ].filter(item => item.value);
};

export default function KezekDirectionInfo() {
  const data = useSelector(state => state[kezekDirectionModule].data);
  const { t, i18n } = useTranslation();

  return (
    <div className="mb2 card p2">
      {getInfo(data, t, i18n.language).map((info, index) => (
        <div className="pd1" key={index}>
          <span className="bold textSecondary mr1">{info.label}:</span>
          <span>{info.value}</span>
        </div>
      ))}
    </div>
  );
}
