import React from 'react';
import FilterInputField from '../../components/TableFilter/ColumnsFilters/FilterInputField';
import FilterSelect from '../../components/TableFilter/ColumnsFilters/FilterSelect';
import RemoveBtn from './RemoveBtn';
import CreateIcon from '@material-ui/icons/Create';
import { IconButton, Tooltip } from '@material-ui/core';

const styles = {
  textAlign: 'center',
  alignSelf: 'center'
};

export function GroupsTableColumns({ t, lang, onDelete, replace }) {
  return [
    {
      Header: '№',
      accessor: 'rowNumber',
      width: 60,
      filterable: false
    },
    {
      Header: t('groupsTablePage_numberGroup'),
      accessor: 'id',
      Filter: <FilterInputField name="id" type="number" />
    },
    {
      Header: t('groupsTablePage_nameGroup'),
      accessor: 'nameGroup',
      Filter: <FilterInputField name="nameGroup" type="text" />
    },
    {
      Header: t('groupsTablePage_beginAcademicYear'),
      accessor: 'beginAcademicYear',
      Cell: ({ original: { academicYearName } }) => academicYearName[`${lang}_name`],
      Filter: <FilterSelect name="beginAcademicYear" dictCode="gu_academic_year_do" lang={lang} />

    },
    {
      Header: t('groupsTablePage_ageGroup'),
      accessor: 'ageGroup',
      Cell: ({ original: { ageGroupName } }) => ageGroupName[`${lang}_name`],
      Filter: <FilterSelect name="ageGroup" dictCode="gu_age_group" lang={lang} />

    },
    {
      Header: t('groupsTablePage_language'),
      accessor: 'language',
      Cell: ({ original: { languageChild } }) => languageChild[`${lang}_name`],
      Filter: <FilterSelect name="language" dictCode="gu_language" lang={lang} />

    },
    {
      Header: t('groupsTablePage_stateOrder'),
      accessor: 'stateOrder',
      Cell: ({ original: { stateOrder } }) => (stateOrder === undefined || stateOrder === null) ? '' : stateOrder ? t('groupsTablePage_stateOrderYes') : t('groupsTablePage_stateOrderNo'),
    //  Filter: <FilterInputField name="stateOrder" type="text" />
      Filter: (
        <FilterSelect
          name="stateOrder"
          lang={lang}
          options={[
            {kk_name: t('groupsTablePage_stateOrderYes'), code: 'true', ru_name: t('groupsTablePage_stateOrderYes')}, 
            {kk_name: t('groupsTablePage_stateOrderNo'), code: 'false', ru_name: t('groupsTablePage_stateOrderNo')}
          ].map(item => ({
            ...item,
          }))}
        />
      )
    },
    {
      Header: t('groupsTablePage_educators'),
      accessor: 'stateOrder',
      filterable: false,
      Cell: ({ original: {staff}}) => {
        return (
          <div>{staff.map((item) => {
            if (item.staffStatus === 'WORKING') {
              return <p style={{marginTop: 0, marginBottom: 0}}>{item.staffSurname} {item.staffName}</p>
            }
          })}</div>
        )
      }
    },
    {
      Header: t('groupsTablePage_action'),
      style: styles,
      filterable: false,
      width: 120,
      Cell: ({ original, index }) => (
        <div 
          style={{ margin: '-5px 0' }} 
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <RemoveBtn original={original} onDelete={onDelete}/>
        </div>
      )
    }
  ];
}
