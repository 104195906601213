import React from 'react';
import FilterTreeSelect from '../../../components/TableFilter/ColumnsFilters/FilterTreeSelect/FilterTreeSelect';
import FilterDatePicker from '../../../components/TableFilter/ColumnsFilters/FilterDatePicker';
import FilterSelect from '../../../components/TableFilter/ColumnsFilters/FilterSelect';
import FilterInputField from '../../../components/TableFilter/ColumnsFilters/FilterInputField';
import { STATUS_DIRECTION_COLOR } from '../../../_helpers/Constants';
import moment from 'moment';

export const CandidateColumns = ({ t, lang, statusDirection, palette }) => {
  return [
    {
      Header: '№',
      accessor: 'rowNumber',
      maxWidth: 50,
      filterable: false
    },
    {
      Header: t('contingentPage_columnDirectionNumber'),
      accessor: 'directionId',
      width: 150,
      Filter: <FilterInputField name="directionId" type="number" />
    },
    {
      Header: t('contingentPage_columnChild_iin'),
      accessor: 'childIin',
      Filter: <FilterInputField name="iin" type="number" />
    },
    {
      Header: t('contingentPage_columnFullName'),
      accessor: 'childFullName',
      Cell: ({ original}) => {
        return (
          <div>
            {original.childSurname} {original.childName} {original.childMiddlename}
          </div>
        )
      },
      Filter: <FilterInputField name="childFullName" />
    },
    {
      Header: t('contingentPage_columnAgeGroup'),
      accessor: 'ageGroup',
      Cell: ({ original: { ageGroupName } }) => ageGroupName[`${lang}_name`],
      Filter: <FilterSelect name="ageGroup" dictCode="gu_age_group" lang={lang} />
    },
    {
      Header: t('contingentPage_columnQueuedType'),
      accessor: 'queuedType',
      Cell: ({ original: { queuedTypeName } }) => queuedTypeName[`${lang}_name`],
      Filter: <FilterSelect name="queuedType" dictCode="gu_queued_type" lang={lang} />
    },
    {
      Header: t('contingentPage_columnDirectionDate'),
      accessor: 'directionDate',
      Cell: row => moment(row.original.directionDate).format('DD.MM.YYYY'),
      Filter: <FilterDatePicker name="directionDate" lang={lang} />
    },
    {
      Header: t('contingentPage_columnStatusDirection'),
      accessor: 'statusDirection',
      Cell: ({ original: { directionStatus } }) => directionStatus[`${lang}_name`],
      Filter: (
        <FilterSelect
          name="statusDirection"
          lang={lang}
          options={statusDirection.map(item => ({
            ...item,
            backgroundColor: STATUS_DIRECTION_COLOR.getColor(item.code, palette)
          }))}
        />
      )
    },
    {
      Header: t('contingentPage_columnDateNumberBulletin'),
      accessor: 'submissionDate',
      Cell: ({ original}) => {
        return (
          <div>
            {original.bulletinId} {t('contingentPage_columnOf')} {moment(original.bulletinSubmissionDate).format('DD.MM.YYYY')}
          </div>
        )
      },
      width: 250,
      Filter: <div style={{display: 'flex', gap: '3px'}}>
        <div style={{ width: '100px' }}>
          <FilterInputField name="bulletinId" type="number" />
        </div>
      <FilterDatePicker name="bulletinSubmissionDate" lang={lang} />
      </div> 
    },
    {
      Header: t('contingentPage_columnBin'),
      accessor: 'bin',
      Filter: <FilterInputField name="bin" type="number" />
    },
    {
      Header: t('contingentPage_columnLegalLocality'),
      accessor: 'legal_locality',
      Cell: ({ original: { location } }) => location[`${lang}_name`],
      Filter: (
        <FilterTreeSelect
          lookupId="60"
          name="locationCode"
          lang={lang}
          filter={item => item.ARM_gu === 'true'}
        />
      )
    },
  ];
};
