import React from 'react';
import FilterInputField from '../../../components/TableFilter/ColumnsFilters/FilterInputField';
import FilterSelect from '../../../components/TableFilter/ColumnsFilters/FilterSelect';
import moment from 'moment';

export const DepartColumns = ({ t, lang }) => {
  return [
    {
      Header: '№',
      accessor: 'rowNumber',
      maxWidth: 50,
      filterable: false
    },
    {
      Header: 'ID',
      accessor: 'id',
      maxWidth: 60,
      Filter: <FilterInputField name="id" type="number" />
    },
    {
      Header: t('contingentSection_iinSchool'),
      accessor: 'visitorIin',
      Filter: <FilterInputField name="visitorIin" type="number" />
    },
    {
      Header: t('contingentSection_fullName'),
      accessor: 'fullName',
       Cell: ({ original}) => {
        return (
          <div>
            {original?.visitorSurname} {original?.visitorName} {original?.visitorMiddlename}
          </div>
        )
      },
      Filter: <FilterInputField name="visitorFullName" />
    },
    {
      Header: t('contingentSection_dateBirth'),
      accessor: 'visitorBirthday',
      Cell: row => moment(row.original.visitorBirthday).format('DD.MM.YYYY'),
    },
    {
      Header: t('contingentSection_phone'),
      accessor: 'visitorTelephoneNumber',
      Filter: <FilterInputField name="visitorTelephoneNumber" type="number" />
    },
    {
      Header: t('contingentSection_name'),
      accessor: 'sectionName',
      Cell: ({ original: { sectionTypeName } }) => sectionTypeName[`${lang}_name`],
      Filter: <FilterSelect name="sectionName" dictCode="gu_section_type" lang={lang} />
    },
    {
      Header: t('contingentSection_dateEnrollment'),
      accessor: 'startDate',
      Cell: ({ original: { enrollmentDate } }) => moment(enrollmentDate).format('DD.MM.YYYY'),
    },
    {
      Header: t('contingentSection_dropoutDate'),
      accessor: 'dropoutDate',
      Cell: row => moment(row.original.dropoutDate).format('DD.MM.YYYY'),
      Filter: false,
    },
    {
      Header: t('contingentSection_dropoutReason'),
      accessor: 'dropoutReason',
      Filter: false,
    },
  ];
};
