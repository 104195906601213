import i18next from 'i18next';
import { useEffect } from 'react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import useTableFilter from '../../components/TableFilter/useTableFilter';
import { statusColor } from '../../utils/statusColors';
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs';
import StaticTablePagination from '../../_ui/Table/StaticTablePaginated';
import { kezekArchiveColumns } from './KezekArchiveColumns';
import './KezekArchiveTranslate';
import { useTranslation } from 'react-i18next';
import { kezekArchiveModule, loadData, clearState } from './KezekArchiveDucks';
import paths from '../../_helpers/paths';
import useHasAccess from '../../components/useHasAccess';
import permissions from '../../_helpers/permissions';

export default function KezekArchivePage() {
  const breadCrumbItems = [
    { name: i18next.t('breadcrumbs_home'), href: '/' },
    { name: i18next.t('breadcrumbs_kezekArchiv') }
  ];
  const { t, i18n } = useTranslation();
  const loading = useSelector(state => state[kezekArchiveModule].loadingTable);
  const data = useSelector(state => state[kezekArchiveModule].tableData);
  const dispatch = useDispatch();
  const { nextPage } = useTableFilter(filter => dispatch(loadData(filter)));
  const isAdmin = useHasAccess([permissions.admin]);

  useEffect(() => {
    dispatch(clearState());
  }, [dispatch]);

  return (
    <>
      <BreadCrumbs items={breadCrumbItems} />
      <Paper className="pb3 pt1 px3">
        <h3>{t('kezekArchive_title')}</h3>
        <StaticTablePagination
          columns={kezekArchiveColumns({ t, lang: i18n.language, isAdmin })}
          data={data.content}
          sortable={false}
          urlOnClick={row =>
            row.original.requestFormId
              ? paths.statementViewPage.replace(':id', row.original.requestFormId)
              : null
          }
          totalElements={data.totalElements}
          loading={loading}
          onClickLoadMore={() => nextPage()}
          rowBgColor={row => statusColor[row.original.resolutionType]}
        />
      </Paper>
    </>
  );
}
