import i18next from 'i18next';
import React, {useEffect, useState} from 'react';
import Paper from '@material-ui/core/Paper';
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs';
import './ContingentTranslate';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { loadDateReport, contingentModule, saveCause, exportData } from './ContingentDucks'
import StaticTablePagination from '../../_ui/Table/StaticTablePaginated';
import { ReportColumns } from '../../components/PageReport/ReportColumns'
import useTableFilter from '../../components/TableFilter/useTableFilter';
import ModalReportCard from '../../components/PageReport/ModalReportCard'
import useSimpleModal from '../../components/_hooks/useSimpleModal';
import ColorInfo from '../../components/PageReport/ColorInfo'
import paths from '../../_helpers/paths';
import useUser from '../LoginPage/useUser';
import {ROLES} from '../../_helpers/Constants'
import FilterTreeSelect from '../../components/TableFilter/ColumnsFilters/FilterTreeSelect/FilterTreeSelect';
import FormHelperText from '@material-ui/core/FormHelperText';
import { monthCurrent, createTableCell, getLastDayOfMonth } from '../../components/PageReport/reportUtils'
import Button from '../../_ui/Button/Button';
import SaveIcon from '@material-ui/icons/Save';
import Autocomplete from '../../components/TableFilter/ColumnsFilters/FilterAutocomplete';
import FilterSelect from '../../components/TableFilter/ColumnsFilters/FilterSelect';
import FilterDatePickerMonth from '../../components/TableFilter/ColumnsFilters/FilterDatePickerMonth';
import moment from 'moment';

export default function ContingentDDOReport() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const user = useUser();
  const [days, setDays] = useState([]);
  const reportCardModal = useSimpleModal();
  const [isEdit, setIsEdit] = useState(true);
  const [counterVisitedDays, setCounterVisitedDays] = useState(0);
  const [counterDaysWithoutReason, setCounterDaysWithoutReason] = useState(0);
  const [counterDaysWithReason, setCounterDaysWithReason] = useState(0);
  const [copyFilter, setCopyFilter] = useState();
  const [currentOrg, setCurrentOrg] = useState(user?.organization_id);

  const breadCrumbItems = [
    { name: i18next.t('breadcrumbs_home'), href: '/' },
    { name: i18next.t('breadcrumbs_contingen'), href: paths.contingentCandidatePage },
    { name: i18next.t('breadcrumbs_report')}
  ];
  useEffect(() => {
    if (user) {
      user.roles.forEach((item) => {
        if (item === ROLES.ES_REGION_MIO_EMPLOYEES || item === ROLES.ES_REGION_EMPLOYEES || item === ROLES.ES_GODS) {
          setIsEdit(false);
          setCurrentOrg(false)
        }
      })
    }
  }, [user])
  const { report, loadingTable, groupReport, organizations, cause, loadingExport } = useSelector(
    state => state[contingentModule]
  );

  const getFullYear = new Date().getFullYear();
  const [currentMonth, setCurrentMonth] = useState(monthCurrent(new Date().getMonth() + 1));

  const { nextPage } = useTableFilter(filter => {
    let activeMonth = moment(filter.dateMin).format('MM');
    const arrDays = createTableCell(getLastDayOfMonth(getFullYear, activeMonth));
    if (arrDays.length) {
      setDays(arrDays)
    }
    setCurrentMonth(activeMonth);
    filter['dateMax'] = `${getFullYear}-${activeMonth}-${getLastDayOfMonth(getFullYear, activeMonth)}T00:00:00`
    setCopyFilter({...filter})
    dispatch(loadDateReport({filter}));
  }, {
    pageNumber: 1,
    pageSize: 20,
    dateMin: `${getFullYear}-${currentMonth}-01T00:00:00`
  });

  const onExportHandle = () => {
    dispatch(exportData({filter: copyFilter, currentOrg}))
  };
  
  useEffect(() => {
    let visitedDays = 0;
    let withoutReason = 0;
    let withReason = 0;
    report.content.forEach((item) => {
      setCounterVisitedDays(visitedDays += item.visitedDays)
      setCounterDaysWithoutReason(withoutReason += item.unvisitedDaysWithoutReason)
      setCounterDaysWithReason(withReason += item.unvisitedDaysWithReason)
    })
  }, [report])

  return (
    <>
      <BreadCrumbs items={breadCrumbItems} />
      <Paper className="pb3 pt1 px3" style={{ minWidth: 1800 }}>
        <div className="flex justify-between items-center">
          <h3>{t('contingentPage_reportTitle')}</h3>
        </div>
        <div className="flex justify-between items-center mb2">
          <Box className="flex justify-between items-center">
            <Box className="flex justify-between items-center mr2">
              <Typography style={{fontSize: '12px', width: '100px'}}>{t('contingentPage_tableFor')}</Typography>
              <Box style={{width: '250px'}}>
                <FilterDatePickerMonth
                  name="dateMin"
                  lang={i18n.language}
                  currentMonth={currentMonth}
                />
              </Box>
            </Box>
            
            {
              !isEdit && !currentOrg &&
              <>
                <Box className="flex justify-between items-center mr2">
                  <Typography style={{fontSize: '12px', width: '200px'}}>{t('colleagues_location')}</Typography>
                  <FilterTreeSelect
                    lookupId="60"
                    name="serviceProviderLocation"
                    lang={i18n.language}
                    filter={item => item.ARM_gu === 'true'}
                  />
                </Box>
                <Box className="flex justify-between items-center mr2">
                  <Typography style={{fontSize: '12px', width: '140px'}}>{t('colleagues_organizations')}</Typography>
                  <Autocomplete
                    name="serviceProviderId"
                    options={organizations}
                  />
                </Box>
              </>
            }
            {
              (currentOrg || (!isEdit && copyFilter.serviceProviderId)) &&
              <FilterSelect
                name="groupId"
                options={groupReport}
                fullWidth={false}
                style={{ marginRight: 10, width: 150 }}
                placeholder={t('contingentPage_group')}
              />
            }
          </Box>
          <Box className="flex justify-between items-center">
            <Button
              onClick={onExportHandle}
              icon={<SaveIcon fontSize="small" className="mr1" />}
              text={t('colleagues_export_btn')}
              loading={loadingExport}
            />
            <Box className='mr2'></Box>
            <ColorInfo />
          </Box>
        </div>
        {  
          (!currentOrg && (!isEdit && !copyFilter.serviceProviderLocation)) &&
          <FormHelperText error style={{marginBottom: '10px', fontSize: '14px'}}>{t('contingentPage_isShowDataTable')}</FormHelperText>
        }
        <Box className='reportСard'>
          <StaticTablePagination
            sortable={false}
            loading={loadingTable}
            columns={ReportColumns({
              t,
              lang: i18n.language,
              days,
              open: reportCardModal.open,
              cause
            })}
            data={report.content.length ? [...report.content, {
              name: t('pageReport_total'),
              visitedDays: counterVisitedDays,
              totalFinish: true,
              unvisitedDaysWithoutReason: counterDaysWithoutReason,
              unvisitedDaysWithReason: counterDaysWithReason,
            }] : []}
            totalElements={report.totalElements ? report.totalElements + 1 : report.totalElements}
            onClickLoadMore={() => nextPage()}
          />
        </Box>
        {reportCardModal.isOpen && <ModalReportCard {...reportCardModal} close={reportCardModal.close} saveCause={saveCause} cause={cause} />}
      </Paper>
    </>
  );
}
