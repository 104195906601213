import React, { useEffect, useState } from 'react';
import '../TaskTranslate';
import { useDispatch, useSelector } from 'react-redux';
import ExecutorsModal from './ExecutorsModal';
import Button from '../../../../_ui/Button/Button';
import ExecutorIcon from '@material-ui/icons/HowToReg';
import { AssignExecutorColumns } from './AssignExecutorColumns';
import useSimpleModal from '../../../../components/_hooks/useSimpleModal';
import { useTranslation } from 'react-i18next';
import { TASK_STATUS } from '../../../../_helpers/Constants';
import SaveIcon from '@material-ui/icons/Save';
import CompleteIcon from '@material-ui/icons/DoneAll';
import { Color } from '../../../../components/ThemeProvider/ThemeStyle';
import { capitalize } from 'lodash';
import Table from '../../../../_ui/Table/StaticTablePaginated';
import { ButtonsWrapper } from '../../MyTaskStyles';
import { assignExecutorModule as module } from './AssignExecutorDucks';
import { clearState, completeAssign, loadData, saveAssign } from './AssignExecutorDucks';
import TaskInfo from '../TaskInfo';

function AssignExecutor({ taskIds }) {
  const { t, i18n } = useTranslation();
  const modal = useSimpleModal();
  const dispatch = useDispatch();
  const loading = useSelector(state => state[module].loading);
  const loadingSave = useSelector(state => state[module].loadingSave);
  const loadingApprove = useSelector(state => state[module].loadingApprove);
  const task = useSelector(state => state[module].info);
  const shortInfo = useSelector(state => state[module].shortInfo);

  const taskId = taskIds[0];
  const [checkedList, setCheckedList] = useState([]);
  const isVisible = task.status === TASK_STATUS.IN_PROGRESS;
  const configuration = task.configuration || {};

  useEffect(() => {
    dispatch(loadData(taskId));
    return () => dispatch(clearState());
  }, [taskId, dispatch]);

  const onClickRow = ({ original }) => {
    const checked = !checkedList.some(item => item.taskId === original.taskId);
    if (checked) {
      setCheckedList([...checkedList, original]);
    } else {
      setCheckedList(checkedList.filter(item => item.taskId !== original.taskId));
    }
  };

  return (
    <>
      <div>
        <h3>{configuration[`name${capitalize(i18n.language)}`]}</h3>
        <TaskInfo {...task} shortInfo={shortInfo} />
        {isVisible && (
          <div className="right-align mb2">
            <Button
              icon={<ExecutorIcon style={{ marginRight: 5 }} />}
              disabled={checkedList.length === 0}
              text={t('assignExecutor_button')}
              onClick={e => {
                e.stopPropagation();
                modal.open(checkedList);
              }}
            />
          </div>
        )}
      </div>
      <Table
        columns={AssignExecutorColumns({
          t,
          i18n,
          checkedList,
          onClickRow,
          isVisible
        })}
        rowBgColor={row =>
          checkedList.some(item => item.taskId === row.original.taskId)
            ? 'rgba(125,125,125,0.2)'
            : undefined
        }
        onClickRow={isVisible ? onClickRow : undefined}
        data={task.data ? task.data.makers : []}
        loading={loading}
        sortable={false}
        filterable={false}
      />
      {isVisible && (
        <ButtonsWrapper>
          <Button
            loading={loadingSave}
            size="small"
            icon={<SaveIcon fontSize="small" className="mr1" />}
            text={t('taskPage_onSave_Btn')}
            onClick={() => dispatch(saveAssign(taskIds))}
          />
          <Button
            loading={loadingApprove}
            style={{ backgroundColor: Color.bgColorSuccess }}
            icon={<CompleteIcon style={{ marginRight: 5 }} />}
            text={t('taskPage_onApprove_Btn')}
            size="small"
            onClick={() => dispatch(completeAssign(taskIds))}
          />
        </ButtonsWrapper>
      )}
      {modal.isOpen && <ExecutorsModal {...modal} taskId={taskId} />}
    </>
  );
}

export default AssignExecutor;
