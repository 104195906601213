import { createReducer } from 'redux-starter-kit';

/**
 * Constants
 */

export const badGatewayModule = 'badGateway';
const SET_ERROR = `${badGatewayModule}/SET_ERROR`;
const CLEAR_STATE = `${badGatewayModule}/CLEAR_STATE`;
/**
 * Reducer
 */

const initialState = {
  error: false
};

export default createReducer(initialState, {
  [SET_ERROR]: (state, action) => {
    state.error = action.payload;
  },
  [CLEAR_STATE]: () => initialState
});

/**
 * Actions
 */

export const setBadGateway = status => async dispatch => {
  dispatch({
    type: SET_ERROR,
    payload: status
  });
};
