import React, { useEffect, useState } from 'react';
import { clearState, loadData, submit, save, selectUserModule } from './SelectUserDucks';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';
import TaskInfo from '../TaskInfo';
import { TASK_STATUS } from '../../../../_helpers/Constants';
import Button from '../../../../_ui/Button/Button';
import SaveIcon from '@material-ui/icons/Save';
import { Color } from '../../../../components/ThemeProvider/ThemeStyle';
import CompleteIcon from '@material-ui/icons/DoneAll';
import { ButtonsWrapper } from '../../MyTaskStyles';
import Select from '../../../../_ui/Select/Select';
import useUser from '../../../LoginPage/useUser';

function SelectUser({ taskIds, taskData }) {
  const { t, i18n } = useTranslation();
  const [selectedUser, setSelectedUser] = useState('');
  const dispatch = useDispatch();
  const loadingAction = useSelector(state => state[selectUserModule].loadingAction);
  const users = useSelector(state => state[selectUserModule].users);
  const data = useSelector(state => state[selectUserModule].data);
  const shortInfo = useSelector(state => state[selectUserModule].shortInfo);
  const { configuration, status } = data;
  const isDisabled = status !== TASK_STATUS.IN_PROGRESS;
  const taskId = taskIds[0];
  const user = useUser();

  useEffect(() => {
    dispatch(loadData(taskId));
    return () => dispatch(clearState());
  }, [taskId, dispatch]);

  useEffect(() => {
    if (data.data) {
      setSelectedUser(data.data.selectedUsername);
    }
  }, [data]);

  return (
    <div>
      <div>
        <h3>{configuration[`name${capitalize(i18n.language)}`]}</h3>
        <TaskInfo
          {...data}
          multiTaskData={taskData && taskData.length > 1 && taskData}
          shortInfo={shortInfo}
        />
      </div>
      <div className="fs-16 mb1">{t('taskSelectUser_selectUser')}</div>
      <Select
        disabled={isDisabled}
        withoutForm
        placeholder={t('taskSelectUser_selectUser')}
        value={selectedUser}
        options={users}
        onChange={e => setSelectedUser(e.target.value)}
      />
      <ButtonsWrapper>
        {!isDisabled &&
          configuration.actions.map(action => {
            switch (action.action) {
              case 'save':
                return (
                  <Button
                    icon={<SaveIcon fontSize="small" className="mr1" />}
                    size="small"
                    key={action.action}
                    text={action.renderer[`caption${capitalize(i18n.language)}`]}
                    loading={loadingAction === action.id}
                    disabled={!selectedUser || loadingAction}
                    onClick={() => dispatch(save(taskIds, selectedUser, action.id))}
                  />
                );
              case 'submit':
                return (
                  <Button
                    loading={loadingAction === action.id}
                    key={action.action}
                    size="small"
                    style={{ backgroundColor: Color.bgColorSuccess }}
                    disabled={!selectedUser || loadingAction}
                    icon={<CompleteIcon style={{ marginRight: 5 }} />}
                    text={action.renderer[`caption${capitalize(i18n.language)}`]}
                    onClick={() =>
                      dispatch(submit(taskIds, selectedUser, action.id, user))
                    }
                  />
                );
              default:
                return null;
            }
          })}
      </ButtonsWrapper>
    </div>
  );
}

export default SelectUser;
