import { createReducer } from 'redux-starter-kit';
import i18next from 'i18next';
import { handleError } from '../../../../utils/handleError';
import { TaskApi } from '../../../../_helpers/service';
import Notice from '../../../../utils/Notice';
import Dict from '../../../../utils/Dict';
import lodash from 'lodash';
import _ from 'lodash';
import { handleTaskError } from '../handleTaskError';
import { forwardToNextTask } from '../../MyTaskDucks';

export const questionnaireTaskModule = 'questionnaireTask';
const LOADING = `${questionnaireTaskModule}/LOADING`;
const SET_INFO = `${questionnaireTaskModule}/SET_INFO`;
const SHORT_INFO = `${questionnaireTaskModule}/SHORT_INFO`;
const LOADING_ACTION = `${questionnaireTaskModule}/LOADING_ACTION`;
const CLEAR_STATE = `${questionnaireTaskModule}/CLEAR_STATE`;

/**
 * Reducer
 */

const initialState = {
  loading: false,
  loadingAction: null,
  shortInfo: {},
  info: {
    configuration: {},
    data: {}
  }
};

export default createReducer(initialState, {
  [SET_INFO]: (state, action) => {
    state.info = action.payload;
  },
  [SHORT_INFO]: (state, action) => {
    state.shortInfo = action.payload;
  },
  [LOADING]: (state, action) => {
    state.loading = action.payload;
  },
  [LOADING_ACTION]: (state, action) => {
    state.loadingAction = action.payload;
  },
  [CLEAR_STATE]: () => initialState
});

/**
 * Actions
 */

export const loadData = id => async dispatch => {
  dispatch({ type: LOADING, payload: true });
  try {
    let { data } = await TaskApi.loadTask(id);
    try {
      if (data.result.businessObjectId) {
        let res = await TaskApi.shortInfo(data.result.businessObjectId);
        dispatch({ type: SHORT_INFO, payload: res.data.result });
      }
    } catch (e) {}
    dispatch(setInfo(data));
  } catch (error) {
    const errorCode = _.get(error, 'response.data.status');
    if (errorCode === 'ACCESS_RIGHTS_NOT_ENOUGH') {
      handleError(error, i18next.t('taskDucks_errorLoad403'));
    } else {
      handleError(error, i18next.t('taskDucks_errorLoadTask'));
    }
  } finally {
    dispatch({ type: LOADING, payload: false });
  }
};

export const complete = (changes, actionId, user) => async (dispatch, getState) => {
  try {
    dispatch({ type: LOADING_ACTION, payload: actionId });
    const task = getState()[questionnaireTaskModule].info;
    let { data } = await TaskApi.complete(task.id, actionId, { ...task.data, changes });
    if (data.status === 'SUCCESS') {
      dispatch(setInfo(data));
      await forwardToNextTask(data.result.processInstanceId, user, {}, () => {
        Notice.success(i18next.t('taskDucks_successOnApprove'));
      });
    }
  } catch (error) {
    handleTaskError(error, i18next.t('taskDucks_errorOnApprove'));
  } finally {
    dispatch({ type: LOADING_ACTION, payload: null });
  }
};

export const save = (changes, actionId) => async (dispatch, getState) => {
  dispatch({ type: LOADING_ACTION, payload: actionId });
  try {
    const task = getState()[questionnaireTaskModule].info;
    let { data } = await TaskApi.save(task.id, actionId, { ...task.data, changes });
    if (data.status === 'SUCCESS') {
      dispatch(setInfo(data));
      Notice.success(i18next.t('taskDucks_successOnSave'));
    } else {
      const error = { message: JSON.stringify(data) };
      handleError(error, i18next.t('taskDucks_errorOnSave'));
    }
  } catch (error) {
    handleTaskError(error, i18next.t('taskDucks_errorOnSave'));
  } finally {
    dispatch({ type: LOADING_ACTION, payload: null });
  }
};

const setInfo = data => async dispatch => {
  try {
    const dictServices = await Dict.itemsObject('gu_services');
    const key = data.result.metadataKey.replace('REQUEST_FORM_', '');
    const tbo = lodash.get(data, 'result.data.taskBusinessObject', {});
    const staticFields = {};

    for (const key of Object.keys(tbo)) {
      if (typeof tbo[key] !== 'object') {
        staticFields[key] = tbo[key];
      }
    }

    data.result.serviceTypeName = dictServices[key] || {};
    if (!data.result.data) data.result.data = { '@type': 'QuestionnaireTaskData' };
    dispatch({ type: SET_INFO, payload: { ...data.result, staticFields } });
  } catch (error) {
    handleError(error, i18next.t('taskDucks_errorOnSave'));
  }
};

export const clearState = () => ({ type: CLEAR_STATE });
