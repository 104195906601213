import { createReducer } from 'redux-starter-kit';
import { handleError } from '../../utils/handleError';
import { GroupApi } from '../../_helpers/service';
import Notice from '../../utils/Notice';
import i18next from 'i18next';
import paths from './../../_helpers/paths';
/**
 * Constants
 */

export const groupPassportModule = 'group';
const LOADING = `${groupPassportModule}/LOADING`;
const LOADING_SAVE = `${groupPassportModule}/LOADING_SAVE`;

const SET_DATA = `${groupPassportModule}/SET_DATA`;
const CLEAR_STATE = `${groupPassportModule}/CLEAR_STATE`;
const SET_META = `${groupPassportModule}/SET_META`;
const SER_EDUCATORS = `${groupPassportModule}/SER_EDUCATORS`;

/**
 * Reducer
 */

const initialState = {
  loading: false,
  loadingSave: false,
  data: {},
  meta: {},
  group: {},
  educators: []
};

export default createReducer(initialState, {
  [SET_DATA]: (state, action) => {
    state.group = action.payload;
  },
  [LOADING]: (state, action) => {
    state.loading = action.payload;
  },
  [LOADING_SAVE]: (state, action) => {
    state.loadingSave = action.payload;
  },
  [SET_META]: (state, action) => {
    state.meta = action.payload;
  },
  [SER_EDUCATORS]: (state, action) => {
    state.educators = action.payload;
  }, 
  [CLEAR_STATE]: () => initialState
});

/**
 * Actions
 */

export const saveGroup = (values, id, history, valueEducators) => async dispatch => {
  try {
    let staffLists = valueEducators.map((item) => item.id)
    dispatch({ type: LOADING_SAVE, payload: true });
    if (id) {
      await GroupApi.updateGroup({
        id: id,
        staffIds: staffLists,
        studyGroupChanges: values['form_study_group_do']
      });
    } else {
      await GroupApi.saveGroup({
        staffIds: staffLists,
        studyGroupType:"DO",
        studyGroupChanges: values['form_study_group_do']
      });
    }
    history.replace(paths.groupsTabelPage)
    Notice.success(i18next.t('groupPage_saveFormSuccess'));
  } catch (error) {
    handleError(error, i18next.t('groupPage_errorSaveForm'));
  } finally {
    dispatch({ type: LOADING_SAVE, payload: false });
  }
};

export const getMeta = () => async dispatch => {
  try {
    dispatch({ type: LOADING, payload: true });
    let data = {
      metadataKey: 'form_study_group_do'
    }
    const { data: meta } = await GroupApi.loadMeta(data.metadataKey);
    dispatch({ type: SET_META, payload: meta });
  } catch (error) {
    handleError(error, i18next.t('groupPage_errorLoadMeta'));
  } finally {
    dispatch({ type: LOADING, payload: false });
  }
}

export const getGroup = ({id}) => async dispatch => {
  try {
    dispatch({ type: LOADING, payload: true });
    const { data } = await GroupApi.getItemGroup({id});
    dispatch({ type: SET_DATA, payload: data })
  } catch (error) {
    handleError(error, i18next.t('groupPage_errorLoadGroup'));
  } finally {
    dispatch({ type: LOADING, payload: false });
  }
}

export const getEducators = () => async dispatch => {
  try {
    dispatch({ type: LOADING, payload: true });
    const {data} = await GroupApi.getEducators();
    dispatch({ type: SER_EDUCATORS, payload: data.result })
  } catch (error) {
    handleError(error, i18next.t('groupPage_errorLoadEducators'));
  } finally {
    dispatch({ type: LOADING, payload: false });
  }
} 

export const clearState = () => ({ type: CLEAR_STATE });
