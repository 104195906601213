import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  PassportProvider,
  PassportExpander,
  PassportForm,
  PassportNavigation,
  getFormValues,
} from '../../passport/Passport';
import { Color } from '../../components/ThemeProvider/ThemeStyle';
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary';
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs';
import {
  clearState,
  getMeta,
  loadData,
  getClasses,
  contingentSchoolModule
} from './ContingentSchoolDucks';
import { Wrapper, ButtonsWrapper } from './ContingentSchoolStyle';
import { useTranslation } from 'react-i18next';
import PageLoading from '../../components/PageLoader/PageLoading';
import './ContingentSchoolTranslate';
import i18next from 'i18next';
import { useParams } from 'react-router';
import paths from './../../_helpers/paths';
import Button from '../../_ui/Button/Button';
import SaveIcon from '@material-ui/icons/Save';
import ModalAdd from './modal/ModalAdd'
import useSimpleModal from '../../components/_hooks/useSimpleModal';
import ModalDisposal from './modal/ModalDisposal'
import { CONTINGENT_SCHOOL_STATUS } from '../../_helpers/Constants';
import ContingentSchoolInfo from './ContingentSchoolInfo'
import Row from '../../passport/Components/Row';
import FormControl from '@material-ui/core/FormControl';
import MuiSelect from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import useDict from '../../components/_hooks/useDict';

export default function ContingentSchoolPassport() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [studentIIN, setStudentIIN] = useState()
  const addModal = useSimpleModal();
  const disposalModal = useSimpleModal();
  const [classSelect, setClass] = useState('');
  const [isError, setIsError] = useState(false);
  const classNumber = useDict('gu_class_type')
  const classLetter = useDict('39051')
  const { id } = useParams();

  const { loading, data, meta, loadingSave, loadingDismissed, classes } = useSelector(
    state => state[contingentSchoolModule]
  );

  const breadCrumbItems = [
    { name: i18next.t('breadcrumbs_home'), href: '/' },
    { name: i18next.t('breadcrumbs_contingentSchool'), href: paths.contingentPupilsPage },
    { name: i18next.t('breadcrumbs_contingentPassport') }
  ];
 
  useEffect(() => {
    dispatch(getMeta());
    dispatch(getClasses())
    if (id) {
      dispatch(loadData(id));
    } 
   return () => dispatch(clearState());
  }, [dispatch, id]);

  const onAddSchool = () => {
    getFormValues().then(values => {
      if (values) {
        if (!classSelect){
          setIsError(true)
        }else {
          addModal.open(true)
        }
      }
    })
  }
  
  function extraF(code, value) {
    if (code === 'form_contingent_school_student.student_iin') {
      setStudentIIN(value)
    }
  }

  useEffect(() => {
    if (data) {
      setClass(data?.childGroupId)
    }
  }, [data])

  return (
    <PageLoading loading={loading}>
      <BreadCrumbs items={breadCrumbItems} />
      <Wrapper className="px3 pb3">
        <ErrorBoundary>
          <PassportProvider
            withoutScroll={false}
            stickyTop={Color.headerHeight}
            data={JSON.stringify(data) === '{}' ? { metadataKey: 'form_contingent_school_student' } : data}
            meta={meta}
            questionRender={extraF}
            config={{
              documentsProps: {
                mimeType: 'image/jpeg,image/png,application/pdf',
                maxSize: 10000000
              }
            }}
          >
            <PassportExpander />
            <div className="flex">
              {
                JSON.stringify(data) !== '{}' &&
                <div className="left-info">
                  <ContingentSchoolInfo data={data} t={t} />
                  <PassportNavigation />
                </div>
              }
              <div className="ml2 flex-auto">
                <PassportForm loading={loading} disabled={false} />
                <div className="mt2">
                  <Row label={t('contingentSchool_classSelect')}>
                    <FormControl fullWidth>
                      <MuiSelect
                        value={classSelect}
                        error={isError}
                      >
                        {
                          classes.map((item) => {
                            const findClass = classNumber.find(el => el.code === item.classNumber)
                            const findCLetter = classLetter.find(el => el.code === item.letter)
                            let name = ""
                            if (findClass && findCLetter){
                              name = `${findClass[`${i18n.language}_name`]} ${findCLetter[`${i18n.language}_name`]}`

                            }
                            return <MenuItem value={item.id} onClick={() => setClass(item.id)}
                                             key={item.id}>{name}</MenuItem>;
                          })

                        }
                        {
                          classes.length === 0 &&
                          <MenuItem>{t('contingentSchool_noClass')}</MenuItem>
                        }
                      </MuiSelect>
                      {
                        isError &&
                        <FormHelperText error>{t('contingentSchool_requiredField')}</FormHelperText>
                      }
                    </FormControl>
                  </Row>
                </div>
              </div>
            </div>

          </PassportProvider>

          <ButtonsWrapper>
            {
              data.contingentStatus === CONTINGENT_SCHOOL_STATUS.STUDIES_SCHOOL &&
              <Button
                loading={loading || loadingDismissed}
                style={{ marginRight: 10 }}
                disabled={loading || loadingDismissed}
                size="small"
                color="secondary"
                text={t('contingentSchool_disposal')}
                onClick={() => disposalModal.open(true)}
              />
            }
            {
              data.contingentStatus !== CONTINGENT_SCHOOL_STATUS.LEFT_SCHOOL &&
              <Button
                icon={<SaveIcon fontSize="small" style={{ marginRight: 10 }} />}
                loading={loading || loadingSave}
                style={{ backgroundColor: Color.bgColorSuccess }}
                disabled={loading || loadingSave}
                size="small"
                color="primary"
                text={id ? t('contingentSchool_changeBtn') : t('contingentSchool_addBtn')}
                onClick={onAddSchool}
              />
            }
          </ButtonsWrapper>
          {addModal.isOpen && <ModalAdd {...addModal} studentIIN = {studentIIN} close={addModal.close} classSelect={classSelect} id={id} />}
          {disposalModal.isOpen && <ModalDisposal {...disposalModal} close={disposalModal.close} id={id} studentIIN = {studentIIN} />}

        </ErrorBoundary>
      </Wrapper>
    </PageLoading>
  );
}
