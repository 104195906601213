import { createReducer } from 'redux-starter-kit';
import _ from 'lodash';

import { handleError } from '../../utils/handleError';
import { ResetPassApi } from '../../_helpers/service';
import Notice from '../../utils/Notice';
import paths from '../../_helpers/paths';
import { history } from '../../_helpers/history';
import i18next from 'i18next';
import { handleStatusError } from '../LoginPage/LoginDucks';

export const resetPassModule = 'resetPass';

const IS_MAILING = `${resetPassModule}/IS_MAILING`;
const IS_CHANGING = `${resetPassModule}/IS_CHANGING`;

const initialState = {
  isMailing: false,
  isChanging: false
};

export default createReducer(initialState, {
  [IS_MAILING]: (state, action) => {
    state.isMailing = action.payload;
  },
  [IS_CHANGING]: (state, action) => {
    state.isChanging = action.payload;
  }
});

export const mailResetPass = username => async dispatch => {
  dispatch({ type: IS_MAILING, payload: true });

  try {
    const response = await ResetPassApi.resetPassMail(username);
    if (response.data.status === 'SUCCESS') {
      Notice.success(i18next.t('resetPassDucks_mailResetPassSuccess'));
    } else {
      handleError(response, i18next.t('resetPassDucks_mailResetPassFailure'));
    }
  } catch (e) {
    handleError(e, i18next.t('resetPassDucks_mailResetPassFailure'));
  } finally {
    dispatch({ type: IS_MAILING, payload: false });
  }
};

export const resetPass = data => async dispatch => {
  dispatch({ type: IS_CHANGING, payload: true });

  try {
    const response = await ResetPassApi.resetPass(data);
    if (response.data.status === 'SUCCESS') {
      history.push(paths.loginPage);
      Notice.success(i18next.t('resetPassDucks_resetPassSuccess'));
    } else {
      handleError(response, i18next.t('resetPassDucks_resetPassFailure'));
    }
  } catch (error) {
    const errorCode = _.get(error, 'response.data.errors[0].errorCode');
    if (errorCode === 'entitlement_user_by_one_time_password_not_found') {
      Notice.error(i18next.t('resetPassDucks_oneTimePasswordNotFound'));
    } else {
      handleStatusError(error, i18next.t('resetPassDucks_resetPassFailure'));
    }
  } finally {
    dispatch({ type: IS_CHANGING, payload: false });
  }
};
