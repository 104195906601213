import React from 'react';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import CreateIcon from '@material-ui/icons/Create';
import Button from '../../_ui/Button/Button';
import { history } from '../../_helpers/history';
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs';
import HeirReopenedModal from './Heir/HeirReopenedModal';
import HeirCloseModal from './Heir/HeirCloseModal';
import useSimpleModal from '../../components/_hooks/useSimpleModal';
import { useTranslation } from 'react-i18next';
import { serviceProvidersModule } from './ServiceProvidersDucks';
import ServiceProvidersTable from './ServiceProvidersTable';
import './ServiceProvidersTranslate';
import useHasAccess from '../../components/useHasAccess';
import permissions from '../../_helpers/permissions';

const ServiceProvidersPage = ({ loading }) => {
  const { t } = useTranslation();
  const modal = useSimpleModal();
  const canEdit = useHasAccess(permissions.serviceProviderPage);
  const breadCrumbItems = [
    { name: t('breadcrumbs_home'), href: '/' },
    { name: t('breadcrumbs_serviceProviders'), href: '/serviceProviders' }
  ];

  return (
    <>
      <BreadCrumbs items={breadCrumbItems} />
      <Paper className="pb3 pt1 px3">
        <div className="flex justify-between items-center">
          <h3>{t('serviceProviders_title')}</h3>
          {canEdit && (
            <Button
              type="button"
              disabled={loading}
              icon={<CreateIcon fontSize="small" className="mr1" />}
              text={t('serviceProviders_create')}
              onClick={() => history.push('/serviceProviders/create')}
            />
          )}
        </div>
        <ServiceProvidersTable
          config={{ modal }}
          onClickRow={row =>
            history.push(
              `/serviceProviders/${canEdit ? 'edit' : 'view'}/${row.original.id}`
            )
          }
        />
        {modal.isOpen && modal.data.closed && <HeirReopenedModal modal={modal} />}
        {modal.isOpen && !modal.data.closed && <HeirCloseModal modal={modal} />}
      </Paper>
    </>
  );
};

export default connect(state => ({
  loading: state[serviceProvidersModule].loading
}))(ServiceProvidersPage);
