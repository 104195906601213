import { addTranslation } from '../../_helpers/Translate';

addTranslation({
  ru: {
    kezekPassport_title: 'Реестр услугодателей',
    kezekPassport_errorLoad: 'Не удалось загрузить данные услугодателя',
    kezekPassport_createForm: 'Создать',
    kezekPassport_saveForm: 'Сохранить',
    kezekPassport_saveFormSuccess: 'Данные успешно сохранены',
    kezekPassport_errorSaveForm: 'Не удалось сохранить данные',
    kezekPassport_id: 'Номер услугодателя',
    kezekPassport_iin: 'ИИН',
    kezekPassport_bin: 'БИН',
    kezekPassport_nedbId: 'ID НОБД',
    kezekPassport_parent: 'Родительская организация',
    kezekPassport_parentChoose: 'Выбрать',
    kezekPassport_invalidPassport:
      'Уважаемый пользователь! Чтобы продолжить работу, необходимо заполнить обязательные поля! После заполнения полей, нужно нажать на кнопку на "Сохранить"'
  },
  kk: {
    kezekPassport_title: 'Қызмет көрсетушілердің тізілімі',
    kezekPassport_errorLoad: 'Қызмет көрсетушінің деректерін жүктеу мүмкін емес',
    kezekPassport_createForm: 'Создать',
    kezekPassport_saveForm: 'Сақтау',
    kezekPassport_saveFormSuccess: 'Деректер сәтті сақталды',
    kezekPassport_errorSaveForm: 'Деректерді сақтау сәтсіз аяқталды',
    kezekPassport_id: 'Қызметті көрсетушінің нөмірі',
    kezekPassport_iin: 'ЖСН',
    kezekPassport_bin: 'БСН',
    kezekPassport_nedbId: 'ID НОБД',
    kezekPassport_parent: 'Бас ұйым',
    kezekPassport_parentChoose: 'Таңдау',
    kezekPassport_invalidPassport:
      'Құрметті пайдаланушы! Жұмысты жалғастыру үшін міндетті өрістерді толтыру қажет! Өрістерді толтырғаннан кейін "Сақтау" батырмасын басу керек.'
  }
});
