import { createReducer } from 'redux-starter-kit';
import { ServiceProvidersApi } from '../../_helpers/service';
import { handleError } from '../../utils/handleError';
import Notice from '../../utils/Notice';
import i18next from 'i18next';

export const kezekPassportModule = 'kezekPassport';
const LOADING = `${kezekPassportModule}/LOADING`;
const SET_INFO = `${kezekPassportModule}/SET_INFO`;
const ERROR = `${kezekPassportModule}/ERROR`;
const LOADING_SAVE = `${kezekPassportModule}/LOADING_SAVE`;
const SET_VALID_PASSPORT = `${kezekPassportModule}/SET_VALID_PASSPORT`;
const CLEAR_STATE = `${kezekPassportModule}/CLEAR_STATE`;
const HIDED_QUESTIONS = [
  'affiliation',
  'service_provider_type',
  'type_code',
  'sub_types',
  'Start_date',
  'Closing_date',
  'supervisor_position',
  'supervisor_fio',
  'Name_gu',
  'Name_gu_1',
  'en_name'
];

/**
 * Reducer
 */

const initialState = {
  loading: false,
  info: {},
  data: {},
  metadata: {},
  error: null,
  loadingSave: false,
  invalidPassport: false
};

export default createReducer(initialState, {
  [LOADING]: (state, action) => {
    state.loading = action.payload;
  },
  [SET_INFO]: (state, action) => {
    const { info, metadata, data } = action.payload;
    if (info) state.info = info;
    if (metadata) state.metadata = metadata;
    if (data) state.data = data;
  },
  [ERROR]: (state, action) => {
    state.error = action.payload;
  },
  [LOADING_SAVE]: (state, action) => {
    state.loadingSave = action.payload;
  },
  [SET_VALID_PASSPORT]: (state, action) => {
    state.invalidPassport = action.payload;
  },
  [CLEAR_STATE]: (state) => {
    return  {...initialState,invalidPassport:state.invalidPassport }
  }
});

/**
 * Actions
 */

export const loadData = id => async dispatch => {
  dispatch({ type: LOADING, payload: true });

  try {
    console.log('test')
    let { data: metadata } = await ServiceProvidersApi.loadMetaData();
    hideQuestions(metadata);
    console.log('test 2')
    if (id) {
      let { data } = await ServiceProvidersApi.load(id);

      let info = {
        id: data.id,
        bin: data.bin,
        iin: data.iin,
        nedbId: data.nedbId,
        ru_name: metadata.ru_name,
        kk_name: metadata.kk_name,
        en_name: metadata.en_name,
        metadataKey: metadata.id,
        parentOrg: {
          id: data.parentServiceProviderId,
          kk_name: data.kk_parentServiceProviderName,
          ru_name: data.ru_parentServiceProviderName
        }
      };

      dispatch({ type: SET_INFO, payload: { info, metadata, data } });
    } else {
      let info = {
        id: '',
        bin: '',
        iin: '',
        nedbId: '',
        ru_name: metadata.ru_name,
        kk_name: metadata.kk_name,
        en_name: metadata.en_name,
        metadataKey: metadata.id,
        parentOrg: {}
      };
      dispatch({ type: SET_INFO, payload: { info, metadata, data: {} } });
    }
  } catch (error) {
    handleError(error, i18next.t('serviceProvider_errorLoad'));
    dispatch({ type: ERROR, payload: JSON.stringify(error, null, 4) });
  } finally {
    dispatch({ type: LOADING, payload: false });
  }
};

export const onSave = (info, values) => async dispatch => {
  try {
    dispatch({ type: LOADING_SAVE, payload: true });

    let response = await ServiceProvidersApi.updateServiceProvider({
      id: info.id,
      bin: info.bin,
      iin: info.iin,
      nedbId: info.nedbId,
      parentServiceProviderId: info.parentServiceProviderId,
      serviceProviderChanges: (values[info.metadataKey] || []).filter(
        item => !HIDED_QUESTIONS.includes(item.questionCode)
      )
    });

    if (response.data.results.length === 0) {
      dispatch({ type: SET_INFO, payload: { data: response.data.entity } });
      dispatch(setValidPassport(false))
      Notice.success(i18next.t('serviceProvider_saveFormSuccess'));
    } else {
      handleError(response.data, i18next.t('serviceProvider_errorSaveForm'), false);
    }
  } catch (error) {
    handleError(error, i18next.t('serviceProvider_errorSaveForm'));
  } finally {
    dispatch({ type: LOADING_SAVE, payload: false });
  }
};

export const setValidPassport = (valid) => async dispatch => {
  dispatch({ type: SET_VALID_PASSPORT, payload: valid });
}

export const clearState = () => ({ type: CLEAR_STATE });

function hideQuestions(meta) {
  const group = meta.group.subGroups.find(item => item.id === 'SERVICE_PROVIDER');
  group.kk_name = 'Мектепке дейінгі ұйым';
  group.ru_name = 'Дошкольная организация';
  group.sections[0].questionCodes = [
    'ru_name',
    'kk_name',
    'legal_locality',
    'legal_address',
    'legal_address_kz'
  ];

  meta.questions.ru_name.readOnlyRule = 'true';
  meta.questions.kk_name.readOnlyRule = 'true';
  meta.questions.legal_locality.readOnlyRule = 'true';
  meta.questions.legal_address.readOnlyRule = 'true';
  meta.questions.legal_address_kz.readOnlyRule = 'true';

  HIDED_QUESTIONS.forEach(questionCode => {
    if (meta.questions[questionCode]) {
      meta.questions[questionCode].keepIfHidden = true;
    }
  });
}
