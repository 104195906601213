import { createReducer } from 'redux-starter-kit';
import { handleError } from '../../utils/handleError';
import { StatementApi } from '../../_helpers/service';
import Notice from '../../utils/Notice';
import i18next from 'i18next';
import Dict from '../../utils/Dict';
import { NCALayerSign } from '../../components/NCALayer/NCALayerSign';
import lodash from 'lodash';
import { buttonRules } from './ButtonRules';
import moment from 'moment';

/**
 * Constants
 */

export const statementModule = 'statement';
const LOADING = `${statementModule}/LOADING`;
const LOADING_INFO = `${statementModule}/LOADING_INFO`;
const LOADING_ACCEPT = `${statementModule}/LOADING_ACCEPT`;
const LOADING_SAVE = `${statementModule}/LOADING_SAVE`;
const LOADING_REJECT = `${statementModule}/LOADING_REJECT`;
const LOADING_COMMIT_POSITIVE = `${statementModule}/LOADING_COMMIT_POSITIVE`;
const LOADING_COMMIT_NEGATIVE = `${statementModule}/LOADING_COMMIT_NEGATIVE`;
const LOADING_COMMIT_DECLINE = `${statementModule}/LOADING_COMMIT_DECLINE`;
const LOADING_COMMIT_COMPLETE = `${statementModule}/LOADING_COMMIT_SIGN`;
const LOADING_INTERMEDIATE = `${statementModule}/LOADING_INTERMEDIATE`;

const SET_INFO = `${statementModule}/SET_INFO`;
const SET_DATA = `${statementModule}/SET_DATA`;
const CLEAR_STATE = `${statementModule}/CLEAR_STATE`;
const SET_ERROR = `${statementModule}/SET_ERROR`;

/**
 * Reducer
 */

const initialState = {
  loading: false,
  loadingInfo: false,
  loadingAccept: false,
  loadingSave: false,
  loadingReject: false,
  loadingPositive: false,
  loadingNegative: false,
  loadingDecline: false,
  loadingComplete: false,
  loadingIntermediate: false,
  requestForm: {},
  requestFormMeta: {},
  requester: {},
  requesterMeta: {},
  info: {
    buttonRules: {},
    requestForm: {},
    requester: {}
  },
  error: null
};

export default createReducer(initialState, {
  [SET_DATA]: (state, { payload }) => {
    if (payload.requestForm) state.requestForm = payload.requestForm;
    if (payload.requestFormMeta) state.requestFormMeta = payload.requestFormMeta;
    if (payload.requester) state.requester = payload.requester;
    if (payload.requesterMeta) state.requesterMeta = payload.requesterMeta;
  },
  [SET_INFO]: (state, action) => {
    state.info = action.payload;
  },
  [SET_ERROR]: (state, action) => {
    state.error = action.payload;
  },
  [LOADING]: (state, action) => {
    state.loading = action.payload;
  },
  [LOADING_INFO]: (state, action) => {
    state.loadingInfo = action.payload;
  },
  [LOADING_ACCEPT]: (state, action) => {
    state.loadingAccept = action.payload;
  },
  [LOADING_SAVE]: (state, action) => {
    state.loadingSave = action.payload;
  },
  [LOADING_REJECT]: (state, action) => {
    state.loadingReject = action.payload;
  },
  [LOADING_COMMIT_POSITIVE]: (state, action) => {
    state.loadingPositive = action.payload;
  },
  [LOADING_COMMIT_NEGATIVE]: (state, action) => {
    state.loadingNegative = action.payload;
  },
  [LOADING_COMMIT_DECLINE]: (state, action) => {
    state.loadingDecline = action.payload;
  },
  [LOADING_COMMIT_COMPLETE]: (state, action) => {
    state.loadingComplete = action.payload;
  },
  [LOADING_INTERMEDIATE]: (state, action) => {
    state.loadingIntermediate = action.payload;
  },
  [CLEAR_STATE]: () => initialState
});

/**
 * Actions
 */

export const loadStatement = id => async dispatch => {
  try {
    dispatch({ type: LOADING, payload: true });
    let { data: requestForm } = await StatementApi.loadRequestForm(id);
    let { data: requestFormMeta } = await StatementApi.loadMeta(requestForm.metadataKey);
    let { data: requester } = await StatementApi.requester(requestForm.requesterId);
    let { data: requesterMeta } = await StatementApi.loadMeta(requester.metadataKey);
    dispatch(setData({ requestForm, requestFormMeta, requester, requesterMeta }));
  } catch (error) {
    let msg = handleError(error, i18next.t('statement_errorLoad'));
    dispatch({ type: SET_ERROR, payload: msg });
  } finally {
    dispatch({ type: LOADING, payload: false });
  }
};

export const saveStatement = (info, values) => async dispatch => {
  try {
    dispatch({ type: LOADING_SAVE, payload: true });
    let responseData = {};
    if (values[info.requestForm.metadataKey]) {
      let response = await StatementApi.saveForm({
        id: info.requestForm.id,
        serviceType: info.serviceType,
        requestFormChanges: values[info.requestForm.metadataKey]
      });

      if (response.data.results.length > 0) {
        handleError(response, i18next.t('statement_errorSaveForm'));
        return;
      } else {
        responseData.requestForm = response.data.entity;
      }
    }

    if (values[info.requester.metadataKey]) {
      let response = await StatementApi.saveRequesterForm({
        id: info.requester.id,
        iin: info.requester.iin,
        requesterChanges: values[info.requester.metadataKey]
      });
      if (response.data.results.length > 0) {
        handleError(response, i18next.t('statement_errorSaveForm'));
        return;
      } else {
        responseData.requester = response.data.entity;
      }
    }
    dispatch(setData(responseData));
    Notice.success(i18next.t('statement_saveFormSuccess'));
  } catch (error) {
    handleError(error, i18next.t('statement_errorSaveForm'));
  } finally {
    dispatch({ type: LOADING_SAVE, payload: false });
  }
};

export const accept = (id, closeModal) => async dispatch => {
  try {
    dispatch({ type: LOADING_ACCEPT, payload: true });
    const { data } = await StatementApi.accept(id);
    dispatch(setData({ requestForm: data.result }));
    Notice.success(i18next.t('statement_acceptSuccess'));
    closeModal();
  } catch (error) {
    handleError(error, i18next.t('statement_acceptError'));
  } finally {
    dispatch({ type: LOADING_ACCEPT, payload: false });
  }
};

export const reject = (id, values, closeModal) => async dispatch => {
  try {
    dispatch({ type: LOADING_REJECT, payload: true });
    const { data } = await StatementApi.reject(id, values);
    dispatch(setData({ requestForm: data.result }));
    Notice.success(i18next.t('statement_rejectSuccess'));
    closeModal();
  } catch (error) {
    handleError(error, i18next.t('statement_rejectError'));
  } finally {
    dispatch({ type: LOADING_REJECT, payload: false });
  }
};

export const commitPositive = (id, closeModal) => async dispatch => {
  try {
    dispatch({ type: LOADING_COMMIT_POSITIVE, payload: true });
    const { data } = await StatementApi.commitPositive(id);
    if (data.status === 'SUCCESS') {
      dispatch(setData({ requestForm: data.result }));
      Notice.success(i18next.t('statement_positiveSuccess'));
      closeModal();
    }
  } catch (error) {
    handleError(error, i18next.t('statement_positiveError'));
  } finally {
    dispatch({ type: LOADING_COMMIT_POSITIVE, payload: false });
  }
};

export const commitNegative = (id, values, closeModal) => async dispatch => {
  try {
    dispatch({ type: LOADING_COMMIT_NEGATIVE, payload: true });
    const { data } = await StatementApi.commitNegative(id, values);
    if (data.status === 'SUCCESS') {
      dispatch(setData({ requestForm: data.result }));
      Notice.success(i18next.t('statement_negativeSuccess'));
      closeModal();
    }
  } catch (error) {
    handleError(error, i18next.t('statement_negativeError'));
  } finally {
    dispatch({ type: LOADING_COMMIT_NEGATIVE, payload: false });
  }
};

export const decline = (id, values, closeModal) => async dispatch => {
  try {
    dispatch({ type: LOADING_COMMIT_DECLINE, payload: true });
    const { data } = await StatementApi.decline(id, values);
    dispatch(setData({ requestForm: data.result }));
    Notice.success(i18next.t('statement_declineSuccess'));
    closeModal();
  } catch (error) {
    handleError(error, i18next.t('statement_declineError'));
  } finally {
    dispatch({ type: LOADING_COMMIT_DECLINE, payload: false });
  }
};

export const complete = id => async dispatch => {
  try {
    dispatch({ type: LOADING_COMMIT_COMPLETE, payload: true });
    const { data: sign } = await StatementApi.sign(id);
    const signedXml = await NCALayerSign(sign.result.signature);
    if (signedXml) {
      const { data } = await StatementApi.complete(id, {
        signature: signedXml
      });
      if (data.status === 'SUCCESS') {
        dispatch(setData({ requestForm: data.result }));
        Notice.success(i18next.t('statement_completeSuccess'));
      }
    }
  } catch (error) {
    const errorStatus = lodash.get(error, 'response.data.status');
    if (errorStatus === 'SIGNATURE_VERIFICATION_FAILED') {
      Notice.error(i18next.t('statement_completeErrorSign'))
    } else {
      handleError(error, i18next.t('statement_completeError'));
    }
  } finally {
    dispatch({ type: LOADING_COMMIT_COMPLETE, payload: false });
  }
};

export const commitIntermediate = (id, closeModal) => async dispatch => {
  try {
    dispatch({ type: LOADING_INTERMEDIATE, payload: true });
    const { data: sign } = await StatementApi.sign(id);
    const signedXml = await NCALayerSign(sign.result.signature);
    if (signedXml) {
      const response = await StatementApi.commitIntermediate(id, {
        signature: signedXml
      });
      const data = response.data;
      if (data.status === 'SUCCESS') {
        dispatch(setData({ requestForm: data.result }));
        Notice.success(i18next.t('statement_intermediateSuccess'));
        closeModal();
      } else {
        handleError({ response }, i18next.t('statement_intermediateError'));
      }
    }
  } catch (error) {
    handleError(error, i18next.t('statement_intermediateError'));
  } finally {
    dispatch({ type: LOADING_INTERMEDIATE, payload: false });
  }
};

const setData = params => dispatch => {
  dispatch({ type: SET_DATA, payload: params });
  if (params.requestForm) {
    dispatch(setInfo(params.requestForm, params.requester, params.requestFormMeta));
  }
};

const setInfo = (requestForm, requester, names) => async (dispatch, getState) => {
  dispatch({ type: LOADING_INFO, payload: true });

  let info = {
    ...getState()[statementModule].info,
    showPositive: true,
    showNegative: true
  };

  if (requestForm) {
    const dictResolution = await Dict.item('gu_status_pr', requestForm.resolutionType);
    const dictStatus = await Dict.item('gu_status_res', requestForm.processingStatus);
    const providerId = requestForm.serviceProviderId;
    const serviceProvider = {};
    if (
      (!info.requestForm.serviceProvider && providerId) ||
      info.requestForm.serviceProvider.id !== providerId
    ) {
      const provider = await StatementApi.loadProvider(providerId);
      const path = 'data.questionnaire.questionCodeToAnswers';
      const providerData = lodash.get(provider, path, {});
      serviceProvider.id = provider.data.id;
      serviceProvider.bin = provider.data.bin;
      serviceProvider.ru_name = lodash.get(providerData, 'ru_name.values[0].value');
      serviceProvider.kk_name = lodash.get(providerData, 'kk_name.values[0].value');
    }

    const negativeResolution = {};
    if (requestForm.resolutionType === 'NEGATIVE') {
      negativeResolution.reasonName = await Dict.item(
        'gu_refuse',
        requestForm.negativeResolutionReason
      );
      negativeResolution.reasonTextKk = requestForm.negativeResolutionReasonTextKk;
      negativeResolution.reasonTextRu = requestForm.negativeResolutionReasonTextRu;
      negativeResolution.missingDocumentsListKk =
        requestForm.negativeResolutionMissingDocumentsListKk;
      negativeResolution.missingDocumentsListRu =
        requestForm.negativeResolutionMissingDocumentsListRu;
    }

    info.requestForm = {
      id: requestForm.id,
      serviceProviderId: providerId,
      externalRequestId: requestForm.externalRequestId,
      metadataKey: requestForm.metadataKey,
      submissionDate: requestForm.submissionDate,
      resolutionDate: requestForm.resolutionDate,
      serviceType: requestForm.serviceType,
      resolutionType: requestForm.resolutionType,
      resolutionTypeName: dictResolution,
      processingStatus: requestForm.processingStatus,
      ragStatus: requestForm.ragStatus,
      ragStatusValidUntil: requestForm.ragStatusValidUntil
        ? moment(requestForm.ragStatusValidUntil).format('DD.MM.YYYY HH:mm')
        : '',
      processingStatusName: dictStatus,
      serviceProvider,
      negativeResolution
    };
  }

  info.buttonRules = await buttonRules(requestForm);

  if (requester) {
    info.requester = {
      id: requester.id,
      metadataKey: requester.metadataKey,
      iin: requester.iin
    };
  }
  if (names) {
    info.ru_name = names.ru_name;
    info.kk_name = names.kk_name;
  }
  dispatch({ type: SET_INFO, payload: info });
  dispatch({ type: LOADING_INFO, payload: false });
};

export const changeServiceProvider = (id, providerId) => async dispatch => {
  try {
    dispatch({ type: LOADING_INFO, payload: true });
    const { data } = await StatementApi.changeServiceProvider(id, providerId);
    if (data.status === 'SUCCESS') {
      dispatch(setData({ requestForm: data.result }));
      Notice.success(i18next.t('statement_successChangeProvider'));
    }
  } catch (error) {
    handleError(error, i18next.t('statement_errorChangeProvider'));
  } finally {
    dispatch({ type: LOADING_INFO, payload: false });
  }
};

export const clearState = () => ({ type: CLEAR_STATE });
