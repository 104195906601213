import { addTranslation } from '../../_helpers/Translate';


addTranslation({
  ru: {
    faq_title: 'Часто задаваемые вопросы'
  },
  kk: {
    faq_title: 'Жиі қойылатын сұрақтар'
  }
})
