import i18next from 'i18next';
import React, {useEffect, useState} from 'react';
import Paper from '@material-ui/core/Paper';
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs';
import './ContingentSectionTranslate';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import MuiSelect from '@material-ui/core/Select';
import { useDispatch, useSelector } from 'react-redux';
import { loadDateReport, contingentSectionModule, saveCause, exportData } from './ContingentSectionDucks'
import StaticTablePagination from '../../_ui/Table/StaticTablePaginated';
import { ReportColumns } from '../../components/PageReport/ReportColumns'
import useTableFilter from '../../components/TableFilter/useTableFilter';
import ModalReportCard from '../../components/PageReport/ModalReportCard'
import useSimpleModal from '../../components/_hooks/useSimpleModal';
import { MenuItem } from '@material-ui/core';
import ColorInfo from '../../components/PageReport/ColorInfo'
import paths from '../../_helpers/paths';
import { months, ROLES } from '../../_helpers/Constants'
import FilterTreeSelect from '../../components/TableFilter/ColumnsFilters/FilterTreeSelect/FilterTreeSelect';
import useUser from '../LoginPage/useUser';
import { monthCurrent, createTableCell, getLastDayOfMonth } from '../../components/PageReport/reportUtils'
import Button from '../../_ui/Button/Button';
import SaveIcon from '@material-ui/icons/Save';
import Autocomplete from '../../components/TableFilter/ColumnsFilters/FilterAutocomplete';
import FormHelperText from '@material-ui/core/FormHelperText';

export default function ContingentSectionReport() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const user = useUser();
  const [days, setDays] = useState([]);
  const reportCardModal = useSimpleModal();
  const [currentOrg, setCurrentOrg] = useState(user?.organization_id)
  const [isEdit, setIsEdit] = useState(true);
  const [counterVisitedDays, setCounterVisitedDays] = useState(0)
  const [counterDaysWithoutReason, setCounterDaysWithoutReason] = useState(0)
  const [counterDaysWithReason, setCounterDaysWithReason] = useState(0)
  const [copyFilter, setCopyFilter] = useState({});

  useEffect(() => {
    if (user) {
      user.roles.forEach((item) => {
        if (item === ROLES.ES_REGION_MIO_EMPLOYEES || item === ROLES.ES_REGION_EMPLOYEES || item === ROLES.ES_GODS) {
          setIsEdit(false);
          setCurrentOrg(false)
        }
      })
    }
  }, [user])

  const { nextPage } = useTableFilter(filter => {
    setCopyFilter({...filter});
    dispatch(loadDateReport({filter, minDate, maxDate}));
  });
  
  const breadCrumbItems = [
    { name: i18next.t('breadcrumbs_home'), href: '/' },
    { name: i18next.t('breadcrumbs_contingentSection'), href: paths.contingentStudentPage },
    { name: i18next.t('breadcrumbs_report')}
  ];

  const { report, loadingTable, organizations, cause, loadingExport } = useSelector(
    state => state[contingentSectionModule]
  );

  const getFullYear = new Date().getFullYear();
  const getMonth = new Date().getMonth();
  const [currentMonth, setCurrentMonth] = useState(monthCurrent(getMonth+1));
  const [currentYear, setCurrentYear] = useState(getFullYear);
  const lastDay = getLastDayOfMonth(currentYear, currentMonth);
  let minDate = `${currentYear}-${currentMonth}-01`
  let maxDate = `${currentYear}-${currentMonth}-${lastDay}`

  useEffect(() => {
    const arrDays = createTableCell(lastDay);
    if (arrDays.length) {
      setDays(arrDays)
    }
    if (copyFilter) {
      dispatch(loadDateReport({filter: copyFilter, minDate, maxDate}))
    } 
  }, [currentMonth, currentYear, lastDay])

  const onExportHandle = () => {
    dispatch(exportData({minDate, maxDate, currentOrg, filter: copyFilter}))
  };

  useEffect(() => {
    let visitedDays = 0;
    let withoutReason = 0;
    let withReason = 0;
    report.content.forEach((item) => {
      setCounterVisitedDays(visitedDays += item.visitedDays)
      setCounterDaysWithoutReason(withoutReason += item.unvisitedDaysWithoutReason)
      setCounterDaysWithReason(withReason += item.unvisitedDaysWithReason)
    })
  }, [report])

  return (
    <>
      <BreadCrumbs items={breadCrumbItems} />
      <Paper className="pb3 pt1 px3" style={{ minWidth: 1800 }}>
        <div className="flex justify-between items-center">
          <h3>{t('contingentSection_reportTitle')}</h3>
        </div>
        <div className="flex justify-between items-center mb2">
          <Box className="flex justify-between items-center">
            <Box className="flex justify-between items-center mr2">
              <Typography style={{fontSize: '12px', width: '200px'}}>{t('contingentSection_tableFor')}:</Typography>
              <FormControl fullWidth>
                <MuiSelect
                  value={currentMonth}
                  className='mr1'
                >
                  {
                    months.map((item) => {
                      return <MenuItem value={item.code} key={item.code} onClick={() => setCurrentMonth(item.code)}>{item[`${i18n.language}`]}</MenuItem>
                    })
                  }
                </MuiSelect>      
              </FormControl>
              <FormControl fullWidth>
                <MuiSelect
                  value={currentYear}
                >
                  <MenuItem value={'2023'} onClick={() => setCurrentYear('2023')}>2023</MenuItem>
                  <MenuItem value={'2024'} onClick={() => setCurrentYear('2024')}>2024</MenuItem>
                </MuiSelect>      
              </FormControl>
            </Box>
            {
              !isEdit &&
              <>
                <Box className="flex justify-between items-center mr2">
                  <Typography style={{fontSize: '12px', width: '200px'}}>{t('colleagues_location')}:</Typography>
                  <FilterTreeSelect
                    lookupId="60"
                    name="serviceProviderLocation"
                    lang={i18n.language}
                    filter={item => item.ARM_gu === 'true'}
                  />
                </Box>
                <Box 
                  className="flex justify-between items-center mr2"
                  style={{
                    width: '400px', 
                    maxWidth: '400px'
                  }}
                >
                  <Typography style={{fontSize: '12px', width: '150px'}}>{t('colleagues_organizations')}</Typography>
                  <Autocomplete
                    name="serviceProviderId"
                    options={organizations}
                  />
                </Box>          
              </>
            } 
          </Box>
          <Box className="flex justify-between items-center">
            <Button
              onClick={onExportHandle}
              icon={<SaveIcon fontSize="small" className="mr1" />}
              text={t('colleagues_export_btn')}
              loading={loadingExport}
            />
            <Box className='mr2'></Box>
            <ColorInfo />
          </Box>
        </div>
        {  
          (!currentOrg && (!isEdit && !copyFilter.serviceProviderLocation)) &&
          <FormHelperText error style={{marginBottom: '10px', fontSize: '14px'}}>{t('contingentPage_isShowDataTable')}</FormHelperText>
        }
        <Box className='reportСard'>
          <StaticTablePagination
            sortable={false}
            loading={loadingTable}
            columns={ReportColumns({
              t,
              lang: i18n.language,
              days,
              open: reportCardModal.open,
              cause
            })}
            data={report.content.length ? [...report.content, {
              name: t('pageReport_total'),
              visitedDays: counterVisitedDays,
              totalFinish: true,
              unvisitedDaysWithoutReason: counterDaysWithoutReason,
              unvisitedDaysWithReason: counterDaysWithReason,
            }] : []}
            totalElements={report.totalElements ? report.totalElements + 1 : report.totalElements}
            onClickLoadMore={() => nextPage()}
          />
        </Box>
        {reportCardModal.isOpen && <ModalReportCard {...reportCardModal} close={reportCardModal.close} saveCause={saveCause} cause={cause} />}
      </Paper>
    </>
  );
}
