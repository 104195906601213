import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Button from '../../../../_ui/Button/Button';
import { changeFormValue } from '../../../../passport/Passport';
import { ContingentApi } from '../../../../_helpers/service';
import Notice from '../../../../utils/Notice';
import PassportForm from '../../../../passport/FormData/PassportForm';
import moment from 'moment';

const requestGBDFL = async ({ setLoading, code, value, t }) => {
  try {
    setLoading(true);
    const metadataKey = code.split('.')[0];
    let queueType = PassportForm.values[`${metadataKey}.queued_type`];
    const data = await ContingentApi.requestChildInfo({iin: value, queueType: queueType});
    const info = data.data.result;
    if (data.status === 200) {      
      switch (code) {
        case 'form_contingent_do_child.child_iin':
          changeFormValue(`${metadataKey}.child_surname`, info.childSurname);
          changeFormValue(`${metadataKey}.child_birthday`, moment(info.childBirthday).format('YYYY-MM-DDTHH:mm:ss'));
          changeFormValue(`${metadataKey}.child_name`, info.childName);
          changeFormValue(`${metadataKey}.child_gender`, info.childGender);
          changeFormValue(`${metadataKey}.privilege_type`, info.privilegeType);
          changeFormValue(`${metadataKey}.priority_queued`, info.priorityQueued);
          changeFormValue(`${metadataKey}.user_category`, info.userCategory);
          changeFormValue(`${metadataKey}.user_surname`, info.userSurname);
          changeFormValue(`${metadataKey}.user_name`, info.userName);
          changeFormValue(`${metadataKey}.user_middlename`, info.userMiddlename);
          changeFormValue(`${metadataKey}.user_birthday`, moment(info.userBirthday).format('YYYY-MM-DDTHH:mm:ss'));
          changeFormValue(`${metadataKey}.user_telephone_number`, info.userTelephoneNumber);
          changeFormValue(`${metadataKey}.user_email`, info.userEmail);
          changeFormValue(`${metadataKey}.area`, info.area);
          changeFormValue(`${metadataKey}.region`, info.region);
          changeFormValue(`${metadataKey}.street`, info.street);
          changeFormValue(`${metadataKey}.house`, info.house);
          break
        default:
          break;
      }
    }
  } catch (error) {
    if (error?.response?.data?.errors[0]?.code === 'error.creating_already_queued_contingent') {
      Notice.error(t('contingentPassport_errorCreatingQueuedContingent'));
    } else if(error?.response?.data?.errors[0]?.code === 'error.creating_already_applicant_contingent') {
      Notice.error(t('contingentPassport_errorCreatingApplicantContingent'));
    } else {
      Notice.error(t('contingentPassport_iinNotFound'));
    }
  } finally {
    setLoading(false);
  }
};

function RequestBtnChild({ code, value }) {
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    return (
      <div
      style={{
        minWidth: '150px',
        width: '150px',
        fontSize: '11px',
        height: '36px',
        margin: '2px 0 2px 5px', 
      }}>
        <Button
          color="secondary"
          loading={loading}
          text={t('contingentPassport_dataRequest')}
          disabled={value.length !== 12}
          onClick={() => requestGBDFL({code, value, t, lang: i18n.language, dispatch, setLoading})}
        />
      </div>
    );
  }
  
  export default RequestBtnChild;
