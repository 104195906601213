import React from 'react';
import { useSelector } from 'react-redux';
import { kezekBulletinModule } from './KezekBulletinDucks';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import capitalize from '@material-ui/core/utils/capitalize';

const getInfo = (data, t, lang) => {
  return [
    { label: t('kezekBulletinPage_number'), value: data.id },
    {
      label: t('kezekBulletinPage_submissionDate'),
      value: data.submissionDate
        ? moment(data.submissionDate).format('DD.MM.YYYY HH:mm')
        : ''
    },
    { label: t('kezekBulletinPage_serviceProviderId'), value: data.serviceProviderId },
    { label: t('kezekBulletinPage_BIN'), value: data.serviceProviderBin },
    {
      label: t('kezekBulletinPage_serviceProviderName'),
      value: data[`serviceProvider${capitalize(lang)}Name`]
    },
    {
      label: t('kezekBulletinPage_providerLocation'),
      value: data.providerLocation[`${lang}_name`]
    },
    {
      label: t('kezekBulletinPage_columnStatusWeb'),
      value: data.statusWebName[`${lang}_name`]
    },
    {
      label: t('kezekBulletinPage_approvalStatus'),
      value: data.approvalStatusName[`${lang}_name`]
    }
  ].filter(item => item.value);
};

export default function KezekBulletinInfo() {
  const data = useSelector(state => state[kezekBulletinModule].data);
  const { t, i18n } = useTranslation();

  return (
    <div className="mb2 card p2">
      {getInfo(data, t, i18n.language).map((info, index) => (
        <div className="pd1" key={index}>
          <span className="bold textSecondary mr1">{info.label}:</span>
          <span>{info.value}</span>
        </div>
      ))}
    </div>
  );
}
