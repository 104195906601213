import React from 'react';
import FilterDatePicker from '../../../components/TableFilter/ColumnsFilters/FilterDatePicker';
import FilterSelect from '../../../components/TableFilter/ColumnsFilters/FilterSelect';
import FilterInputField from '../../../components/TableFilter/ColumnsFilters/FilterInputField';
import moment from 'moment';

export const DismissedColumns = ({ t, lang, staffType }) => {
  return [
    {
      Header: '№',
      accessor: 'rowNumber',
      maxWidth: 50,
      filterable: false
    },
    {
      Header: 'ID',
      accessor: 'id',
      maxWidth: 60,
      Filter: <FilterInputField name="id" type="number" />
    },
    {
      Header: t('colleagues_iin'),
      accessor: 'staffIin',
      Filter: <FilterInputField name="iin" type="number" />
    },
    {
      Header: t('colleagues_fullName'),
      accessor: 'fullName',
       Cell: ({ original}) => {
        return (
          <div>
            {original.staffSurname} {original.staffName} {original.staffMiddlename}
          </div>
        )
      },
      Filter: <FilterInputField name="fullName" />
    },
    {
      Header: t('colleagues_dateBirth'),
      accessor: 'staffBirthday',
      Cell: row => moment(row.original.staffBirthday).format('DD.MM.YYYY'),
    },
    {
      Header: t('colleagues_employeePosition'),
      accessor: 'staffTypeName',
      Cell: ({ original: { staffTypeName } }) => staffTypeName[`${lang}_name`],
      Filter: <FilterSelect name="staffType" dictCode={staffType} lang={lang} />
    },
    {
      Header: t('colleagues_employeePhone'),
      accessor: 'staffTelephoneNumber',
      Filter: <FilterInputField name="staffTelephoneNumber" type="number" />
    },
    {
      Header: t('colleagues_employeeEmail'),
      accessor: 'staffEmail',
      Filter: <FilterInputField name="staffEmail" />
    },
    {
      Header: t('colleagues_startDate'),
      accessor: 'startDate',
      Cell: ({ original: { startDate } }) => moment(startDate).format('DD.MM.YYYY'),
      Filter: <FilterDatePicker name="startDate" lang={lang} />,

    },
    {
      Header: t('colleagues_endDate'),
      accessor: 'dismissalDate',
      Cell: ({ original: { dismissalDate } }) => dismissalDate && moment(dismissalDate).format('DD.MM.YYYY'),
      Filter: <FilterDatePicker name="dismissalDate" lang={lang} />,
    }
  ];
};
