import styled from 'styled-components';
import { Color } from '../../components/ThemeProvider/ThemeStyle';

export const Div = styled.div`
  .ReactTable {
    border: 1px solid rgba(125, 125, 125, 0.4);
    border-radius: ${Color.borderRadius};
    .rt-table {
      border-radius: ${Color.borderRadius};
      overflow: inherit;
      .rt-td {
        padding: 7px 5px;
      }
    }
    .rt-thead {
      .rt-th,
      .rt-td {
        border-right: 1px solid rgba(170, 170, 170, 0.08);
      }
    }
    .-loading {
      background: rgba(125, 125, 125, 0.2);
    }
    .-loading.-active > div {
      transform: none;
    }

    .rt-expandable {
      padding: 0 !important;
    }
  }

  .rt-table div {
    min-width: auto !important;
  }

  .rt-thead.-header {
    height: 40px;
    position: sticky;
    display: flex;
    top: 65px;
    z-index: 110;
    background-color: ${Color.bgColorPaper};
    box-shadow: 0 1px 1px 0 ${Color.border}, 0 0 0 1px ${Color.border} !important;
    border-top-left-radius: ${Color.borderRadius};
    border-top-right-radius: ${Color.borderRadius};
    .rt-th {
      align-items: center;
    }
  }
  .rt-thead.-filters {
    position: sticky;
    top: 40px;
    z-index: 101;
    background-color: ${Color.bgColorPaper};
    box-shadow: none;
    border-bottom: solid 1px rgba(170, 170, 170, 0.15) !important;

    input,
    select {
      background-color: transparent !important;
    }

    .rt-th {
      overflow: inherit !important;
      border-right: 1px solid rgba(170, 170, 170, 0.08) !important;
      :focus {
        outline: none !important;
      }
    }
  }

  .rt-thead .rt-th {
    align-items: baseline;
    display: flex;
    justify-content: center;
    :focus {
      outline: none !important;
    }
  }

  .ReactTable {
    z-index: 1;
    border-top: none;
  }

  .ReactTable .rt-tbody {
    .rt-td {
      border-right: 1px solid rgba(170, 170, 170, 0.08);
    }
    .rt-tr-group {
      border-bottom: solid 1px rgba(170, 170, 170, 0.15);
    }

    .rt-tr {
      align-items: center;
    }
  }

  .ReactTable .rt-thead .rt-th.-sort-asc,
  .ReactTable .rt-thead .rt-td.-sort-asc {
    box-shadow: none;
  }

  .ReactTable .rt-thead .rt-th.-sort-desc,
  .ReactTable .rt-thead .rt-td.-sort-desc {
    box-shadow: none;
  }

  .rt-resizable-header-content {
    width: 100% !important;
  }

  .ReactTable .rt-thead.-filters input,
  .ReactTable .rt-thead.-filters select {
    border: none !important;
  }

  .rt-sort {
    display: inline-block;
    margin-left: 5px;
    margin-top: -20px;
    transform: translateY(5px);
    .up,
    .down {
      opacity: 0.3;
    }
  }

  .-sort-asc .rt-sort .up {
    opacity: 0.8;
  }

  .-sort-desc .rt-sort .down {
    opacity: 0.8;
  }
`;

export const LoadingBox = styled.div`
  z-index: 100 !important;
`;
