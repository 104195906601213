import { useEffect } from 'react';
import { changeFormValue } from '../../../../passport/Passport';

function ResetStreetHouse() {
    useEffect(() => {
        changeFormValue('form_contingent_do_child.street', '');
        changeFormValue('form_contingent_do_child.house', '')
    }, [])
    
    return null;
}
  
export default ResetStreetHouse;
