import React from 'react';
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs';
import { Tabs, Tab, Paper, Box, Typography } from '@material-ui/core';
import './ContingentSectionTranslate';
import { useTranslation } from 'react-i18next';
import paths from '../../_helpers/paths';
import { Link } from 'react-router-dom';
import ExportIcon from '@material-ui/icons/SaveAlt';
import Button from '../../_ui/Button/Button';
import AddIcon from '@material-ui/icons/Add';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Color } from '../../components/ThemeProvider/ThemeStyle';
import DepartPage from './Depart/DepartPage'
import StudentsPage from './Students/StudentsPage'
import {contingentSectionModule} from './ContingentSectionDucks'

export default function ContingentSectionPage({ location: { pathname } }) {
  const { t } = useTranslation();

  const breadCrumbItems = [
    { name: t('breadcrumbs_home'), href: '/' },
    { name: t('breadcrumbs_contingentSection'), href: paths.contingentStudentPage }
  ];

  const studentsdUrl = paths.contingentStudentPage;
  const departUrl = paths.contingentDepartPage;
  const studentTotal = useSelector(state => state[contingentSectionModule].student.totalElements);
  const retiredTotal = useSelector(state => state[contingentSectionModule].retired.totalElements);

  return (
    <>
      <BreadCrumbs items={breadCrumbItems} />
      <Paper 
        style={{ position: 'relative' }}
      >
        <Box className='flex justify-between'>
          <Box className={Color.isDark ? 'flex contingent_dark' : 'flex contingent'} style={{zIndex: 9}}>
            <Tabs 
              value={pathname} 
              indicatorColor={Color.isDark ? '#252525' : '#fff'}
            >
              <Tab
                label={t('contingentSection_studentsTitle')}
                value={studentsdUrl}
                component={Link}
                to={studentsdUrl}
                style={{
                  fontWeight: '700',
                  border: '1px solid #e1e1e1',
                  borderBottom: 'none',
                  borderLeft: 'none',
                  backgroundColor: '#e1e1e185'
                }}
              />
              <Tab
                label={t('contingentSection_departTitle')}
                value={departUrl}
                component={Link}
                to={departUrl}
                style={{
                  fontWeight: '700',
                  border: '1px solid #e1e1e1',
                  borderBottom: 'none',
                  backgroundColor: '#e1e1e185'
                }}
              />
            </Tabs>
            <Box style={{marginTop: '5px', marginLeft: '10px', display: 'flex', alignItems: 'center'}}>
              <Button
                text={t('journalPage_export_btn')}
                icon={<ExportIcon fontSize="small" className="mr1" />}
                onClick={() => {}}
              />
              <Typography style={{ marginLeft: '10px'}}>
                {t('colleaguesPassport_numberEmployees')} {' '} {pathname === studentsdUrl ? studentTotal : retiredTotal}
              </Typography>
            </Box>
          </Box>
          {
            pathname === studentsdUrl &&
            <Box className='mr3' style={{marginTop: '5px'}}>
              <Button
                icon={<AddIcon fontSize="small" className="mr1" />}
                size="small"
                text={t('contingentSection_addBtn')}
                component={NavLink}
               to={'/contingent/section'}
              />
            </Box>
          }
        </Box>
        <div className="px3 pb3 pt1 relative" style={{ borderTop: '1px solid #e1e1e1', marginTop: '-1px', }}>
          {pathname === studentsdUrl && <StudentsPage /> }
          {pathname === departUrl && <DepartPage />}
        </div>

      </Paper>
    </>
  );
}
