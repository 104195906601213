import moment from 'moment';
import React from 'react';
import FilterInputField from '../../components/TableFilter/ColumnsFilters/FilterInputField';
import FilterDatePicker from '../../components/TableFilter/ColumnsFilters/FilterDatePicker';
import ColumnSorting from '../../components/TableFilter/ColumnsFilters/ColumnSorting';
import FilterSelect from '../../components/TableFilter/ColumnsFilters/FilterSelect';
import { capitalize } from 'lodash';

export function kezekContingentColumns({ t, lang }) {
  return [
    {
      Header: '№',
      accessor: 'rowNumber',
      width: 60,
      filterable: false
    },
    {
      Header: <ColumnSorting title={t('archivePage_columnStatementNumber')} name="id" />,
      accessor: 'id',
      style: { textAlign: 'center' },
      maxWidth: 120,
      Filter: <FilterInputField name="id" type="number" />
    },
    {
      Header: <ColumnSorting title={t('archivePage_columnType')} name="serviceType" />,
      accessor: 'serviceType ',
      Cell: ({ original: { serviceTypeName } }) => serviceTypeName[`${lang}_name`],
      Filter: <FilterSelect name="serviceType" dictCode="gu_services" lang={lang} />
    },
    {
      Header: t('archivePage_columnIin'),
      accessor: 'requesterIin',
      style: { textAlign: 'center' },
      maxWidth: 170,
      Filter: <FilterInputField name="requesterIinBin" type="number" />
    },
    {
      Header: (
        <ColumnSorting title={t('archivePage_columnFullName')} name="requesterName" />
      ),
      accessor: 'requesterName',
      Filter: <FilterInputField name="requesterName" type="text" />
    },
    {
      Header: <ColumnSorting title={t('archivePage_columnDate')} name="submissionDate" />,
      accessor: 'submissionDate',
      Cell: row => moment(row.original.submissionDate).format('DD.MM.YYYY HH:mm'),
      sortable: false,
      style: { textAlign: 'center' },
      maxWidth: 180,
      Filter: <FilterDatePicker name="submissionDate" lang={lang} />
    },
    {
      Header: (
        <ColumnSorting title={t('archivePage_columnStatus')} name="processingStatus" />
      ),
      accessor: 'processingStatus',
      Cell: ({ original: { statusName } }) => statusName[`${lang}_name`],
      sortable: false,
      maxWidth: 200,
      Filter: (
        <FilterSelect name="processingStatus" dictCode="gu_status_res" lang={lang} />
      )
    },
    {
      Header: (
        <ColumnSorting title={t('archivePage_columnResolution')} name="resolutionType" />
      ),
      accessor: 'resolutionTypeName',
      Cell: ({ original: { resolutionTypeName } }) => resolutionTypeName[`${lang}_name`],
      sortable: false,
      maxWidth: 200,
      Filter: <FilterSelect name="resolutionType" dictCode="gu_status_pr" lang={lang} />
    },
    {
      Header: t('archivePage_columnResponsible'),
      accessor: 'assigneeName',
      filterable: false
    },
    {
      Header: t('archivePage_columnExternalId'),
      accessor: 'externalRequestId',
      sortable: false,
      Filter: <FilterInputField name="externalRequestId" type="number"  />
    },
    {
      Header: t('archivePage_columnServiceProvider'),
      accessor: 'serviceProviderName',
      filterable: false,
      sortable: false,
      Cell: ({ original }) => original[`serviceProvider${capitalize(lang)}Name`]
    }
  ];
}
