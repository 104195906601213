import React from 'react';
import FilterInputField from '../../components/TableFilter/ColumnsFilters/FilterInputField';
import ColumnSorting from '../../components/TableFilter/ColumnsFilters/ColumnSorting';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CancelIcon from '@material-ui/icons/Cancel';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { capitalize } from 'lodash';
import { Color } from '../../components/ThemeProvider/ThemeStyle';
import { CheckBox } from './ServiceProvidersStyles';
import DiscloseIcon from '@material-ui/icons/ArrowRight';
import CloseIcon from '@material-ui/icons/ArrowDropDown';
import CircularProgress from '@material-ui/core/CircularProgress';
import FilterSelect from '../../components/TableFilter/ColumnsFilters/FilterSelect';
import FilterTreeSelect from '../../components/TableFilter/ColumnsFilters/FilterTreeSelect/FilterTreeSelect';

const Expander = ({ loadingChild, original, isExpanded, index, editExpanded }) => {
  if (original.subServiceProvidersCount > 0) {
    return loadingChild === original.id ? (
      <CircularProgress size={22} />
    ) : (
      <IconButton
        size="small"
        children={isExpanded ? <CloseIcon /> : <DiscloseIcon />}
        onClick={e => {
          e.stopPropagation();
          editExpanded(isExpanded, index);
        }}
      />
    );
  }
  return null;
};

export const serviceProvidersColumns = (
  t,
  lang,
  config = {},
  editExpanded,
  loadingChild,
  level = 1
) => {
  return [
    {
      Header: '',
      width: 150,
      expander: true,
      Expander: eProps => (
        <div className="flex items-center" style={{ paddingLeft: level * 10 }}>
          <div style={{ width: 32, height: 30 }}>
            <Expander
              {...eProps}
              editExpanded={editExpanded}
              loadingChild={loadingChild}
            />
          </div>
          {!!config.isModal && (
            <CheckBox
              withoutForm
              checked={eProps.original.id === config.selectedId}
              onClick={e => {
                e.stopPropagation();
                config.setSelected({ original: eProps.original });
              }}
            />
          )}
        </div>
      )
    },
    {
      Header: <ColumnSorting title={t('serviceProviders_columnId')} name="id" />,
      accessor: 'id',
      resize: false,
      maxWidth: 250,
      Filter: <FilterInputField name="id" type="number" />
      // filterable: false
    },
    {
      Header: <ColumnSorting title={t('serviceProviders_columnBin')} name="bin" />,
      accessor: 'bin',
      maxWidth: 250,
      Filter: <FilterInputField name="bin" type="text" />
    },
    {
      Header: (
        <ColumnSorting title={t('serviceProviders_columnName')} name={`${lang}Name`} />
      ),
      Cell: ({ original }) => original[`${lang}_name`],
      Filter: <FilterInputField name={`name${capitalize(lang)}`} type="text" />
    },
    {
      Header: <ColumnSorting title={t('serviceProviders_columnIin')} name="iin" />,
      accessor: 'iin',
      maxWidth: 250,
      Filter: <FilterInputField name="iin" type="number" />
    },
    {
      Header: (
        <ColumnSorting
          title={t('serviceProviders_columnTypeName')}
          name="serviceProviderType"
        />
      ),
      accessor: 'serviceProviderTypeName',
      maxWidth: 250,
      Filter: (
        <FilterSelect
          name="serviceProviderType"
          dictCode="gu_serviceProvider_type"
          lang={lang}
        />
      )
    },
    {
      Header: (
        <ColumnSorting title={t('serviceProviders_columnLocation')} name="location" />
      ),
      accessor: 'locationName',
      maxWidth: 250,
      Filter: <FilterTreeSelect lookupId="60" name="location" lang={lang} />
    },
    {
      Header: t('serviceProviders_columnAction'),
      accessor: 'action',
      show: !config.isModal && config.canEdit,
      maxWidth: 200,
      Cell: ({ original }) => (
        <div className="center" style={{ margin: '-5px 0' }}>
          {original.closed ? (
            <Tooltip placement="top" title={t('serviceProviders_reopened')}>
              <IconButton
                style={{ color: Color.bgColorSuccess }}
                size="small"
                children={<LockOpenIcon fontSize="small" />}
                onClick={e => {
                  e.stopPropagation();
                  config.modal.open({ ...original, closed: original.closed });
                }}
              />
            </Tooltip>
          ) : (
            <Tooltip placement="top" title={t('serviceProviders_close')}>
              <IconButton
                color="secondary"
                size="small"
                children={<CancelIcon fontSize="small" />}
                onClick={e => {
                  e.stopPropagation();
                  config.modal.open({ ...original, closed: original.closed });
                }}
              />
            </Tooltip>
          )}
        </div>
      ),
      filterable: false,
      sortable: false
    }
  ];
};
