import React from 'react';
import Paper from '@material-ui/core/Paper';
import './ResetPasswordTranslate';

import { Wrapper } from './ResetPasswordStyles';
import ResetPass from './ResetPass/ResetPass';
import MailLink from './MailLink/MailLink';

function ResetPassword({ match }) {
  const { id } = match.params;
  return (
    <Wrapper>
      <Paper elevation={3}>
        {id ? (
          <ResetPass id={id}/>
        ) : (
          <MailLink/>
        )}
      </Paper>
    </Wrapper>
  );
}

export default ResetPassword;
