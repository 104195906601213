import wormhole from 'wormhole.js';
(function() {
  try {
    let hole = wormhole();

    let socket = wormhole.WS ? new wormhole.WS('ws://echo.websocket.org') : null;
    if (socket) {
      socket.onopen = socket.onerror = socket.onclose = function(evt) {};
    }
    if (socket) {
      socket.onmaster = onmaster;
      socket.onmessage = function(evt) {
        let data = JSON.parse(evt.data);
        if (data.type === 'choose') {
          onchoose(data.num);
        }
      };

      hole.on('peers', function(peers) {
        if (Array.isArray(peers)) {
          if (peers.length > 5) {
            window.localStorage.setItem('tabClose', 'true');
          } else {
            window.localStorage.setItem('tabClose', 'false');
          }
        }
      });
    } else {
      hole.on('master', onmaster);
      hole.on('choose', onchoose);
      hole.on('peers', function(peers) {
        if (Array.isArray(peers)) {
          if (peers.length >= 5) {
            window.localStorage.setItem('tabClose', 'true');
          } else {
            window.localStorage.setItem('tabClose', 'false');
          }
        }
      });
    }

    function onmaster() {}
    function onchoose(num) {}
  } catch (e) {
    console.log(e);
  }
})();
