import React from 'react';
import Modal from '../../../_ui/Modal/Modal';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import Button from '../../../_ui/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { approveBulletin, kezekBulletinModule } from '../KezekBulletinDucks';
import DoneIcon from '@material-ui/icons/Done';
import { Color } from '../../../components/ThemeProvider/ThemeStyle';

export default function ModalApproveRevoke({ data: id, close }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loading = useSelector(state => state[kezekBulletinModule].loadingApprove);
  return (
    <Modal title={t('kezekBulletinPage_approveTitle')} open onClose={close}>
      <DialogContent>{t('kezekBulletinPage_approveText')}</DialogContent>
      <DialogActions>
        <Button
          color="primary"
          style={{ backgroundColor: Color.bgColorSuccess }}
          loading={loading}
          icon={<DoneIcon fontSize="small" className="mr1" />}
          text={t('kezekBulletinPage_approveSign')}
          onClick={() => dispatch(approveBulletin(id, close))}
        />
      </DialogActions>
    </Modal>
  );
}
