import React, { useState } from 'react';
import Modal from '../../_ui/Modal/Modal';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '../../_ui/Button/Button';
import DoneIcon from '@material-ui/icons/Done';
import ServiceProvidersTable from '../ServiceProvidersPage/ServiceProvidersTable';
import { useTranslation } from 'react-i18next';
import { TableWrapper } from './KezekPassportStyle';

export default function ParentOrganizationModal({ modal, parentOrg, onSelect }) {
  const { t } = useTranslation();
  const [selected, setSelected] = useState(parentOrg);
  const onClickRow = ({ original }) => {
    setSelected(selected.id === original.id ? parentOrg : original);
  };
  const config = { isModal: true, selectedId: selected.id, setSelected: onClickRow };


  return (
    <Modal
      open
      title={t('serviceProvider_parent')}
      onClose={modal.close}
      maxWidth="lg"
      scroll="paper"
    >
      <DialogContent dividers>
        <TableWrapper>
          <ServiceProvidersTable
            config={config}
            rowBgColor={row =>
              row.original.id === selected.id ? 'rgba(125,125,125,0.2)' : undefined
            }
            onClickRow={onClickRow}
          />
        </TableWrapper>
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          type="button"
          icon={<DoneIcon fontSize="small" className="mr1" />}
          text={t('serviceProvider_parentChoose')}
          onClick={() => onSelect(selected)}
        />
      </DialogActions>
    </Modal>
  );
}
