import { createReducer } from 'redux-starter-kit';
import i18next from 'i18next';
import { TaskApi } from '../../../../_helpers/service';
import Notice from '../../../../utils/Notice';
import { findIndex, some } from 'lodash';
import Dict from '../../../../utils/Dict';
import { handleTaskError } from '../handleTaskError';

export const assignExecutorModule = 'taskAssignExecutor';
const LOADING = `${assignExecutorModule}/LOADING`;
const SET_INFO = `${assignExecutorModule}/SET_INFO`;
const TASK_IDS = `${assignExecutorModule}/TASK_IDS`;
const LOADING_APPROVE = `${assignExecutorModule}/LOADING_APPROVE`;
const LOADING_SAVE = `${assignExecutorModule}/LOADING_SAVE`;
const CLEAR_STATE = `${assignExecutorModule}/CLEAR_STATE`;
const EXECUTORS = `${assignExecutorModule}/EXECUTORS`;
const EXECUTOR_ASSIGN = `${assignExecutorModule}/EXECUTOR_ASSIGN`;
const LOADING_EXECUTORS = `${assignExecutorModule}/LOADING_EXECUTORS`;
const CLEAR_EXECUTORS = `${assignExecutorModule}/CLEAR_EXECUTORS`;
const SHORT_INFO = `${assignExecutorModule}/SHORT_INFO`;

/**
 * Reducer
 */

const initialState = {
  loading: false,
  info: {
    configuration: {},
    data: { makers: [] }
  },
  shortInfo: {},
  taskIds: [],
  executors: [],
  loadingExecutors: false,
  loadingApprove: false,
  loadingSave: false
};

export default createReducer(initialState, {
  [SET_INFO]: (state, action) => {
    state.info = action.payload;
  },
  [TASK_IDS]: (state, action) => {
    state.taskIds = action.payload;
  },
  [SHORT_INFO]: (state, action) => {
    state.shortInfo = action.payload;
  },
  [EXECUTOR_ASSIGN]: (state, action) => {
    state.info.data.makers = state.info.data.makers.map(item =>
      some(action.payload, ['taskId', item['taskId']])
        ? action.payload[findIndex(action.payload, ['taskId', item['taskId']])]
        : item
    );
  },
  [EXECUTORS]: (state, action) => {
    state.executors = action.payload;
  },
  [LOADING]: (state, action) => {
    state.loading = action.payload;
  },
  [LOADING_EXECUTORS]: (state, action) => {
    state.loadingExecutors = action.payload;
  },
  [LOADING_SAVE]: (state, action) => {
    state.loadingSave = action.payload;
  },
  [LOADING_APPROVE]: (state, action) => {
    state.loadingApprove = action.payload;
  },
  [CLEAR_EXECUTORS]: state => {
    state.executors = [];
  },
  [CLEAR_STATE]: () => initialState
});

/**
 * Actions
 */

export const loadData = id => async dispatch => {
  dispatch({ type: LOADING, payload: true });
  try {
    let { data } = await TaskApi.loadTask(id);
    const dictServices = await Dict.itemsObject('gu_services');
    const key = data.result.metadataKey.replace('REQUEST_FORM_','')
    data.result.serviceTypeName = dictServices[key] || {};
    try {
      if (data.result.businessObjectId) {
        let res = await TaskApi.shortInfo(data.result.businessObjectId);
        dispatch({ type: SHORT_INFO, payload: res.data.result });
      }
    } catch (e) {}
    dispatch({ type: SET_INFO, payload: data.result });
  } catch (error) {
    handleTaskError(error, i18next.t('taskDucks_errorLoadTask'))
  } finally {
    dispatch({ type: LOADING, payload: false });
  }
};

export const completeAssign = taskIds => async (dispatch, getState) => {
  dispatch({ type: LOADING_APPROVE, payload: true });
  try {
    const task = getState()[assignExecutorModule].info;
    let { data } = await TaskApi.completeMore(JSON.stringify(taskIds), task.data);
    if (data.status === 'SUCCESS') {
      const result = Object.values(data.result);
      dispatch({ type: SET_INFO, payload: result[0] });
      Notice.success(i18next.t('taskDucks_successOnApprove'));
    }
  } catch (error) {
    handleTaskError(error, i18next.t('taskDucks_errorOnApprove'));
  } finally {
    dispatch({ type: LOADING_APPROVE, payload: false });
  }
};

export const saveAssign = taskIds => async (dispatch, getState) => {
  dispatch({ type: LOADING_SAVE, payload: true });
  try {
    const task = getState()[assignExecutorModule].info;
    let { data } = await TaskApi.saveMore(JSON.stringify(taskIds), task.data);
    if (data.status === 'SUCCESS') {
      const result = Object.values(data.result);
      dispatch({ type: SET_INFO, payload: result[0] });
      Notice.success(i18next.t('taskDucks_successOnSave'));
    }
  } catch (error) {
    handleTaskError(error, i18next.t('taskDucks_errorOnSave'));
  } finally {
    dispatch({ type: LOADING_SAVE, payload: false });
  }
};

export const loadExecutors = id => async dispatch => {
  dispatch({ type: LOADING_EXECUTORS, payload: true });
  try {
    let { data } = await TaskApi.assignees(id);
    dispatch({ type: EXECUTORS, payload: data.result });
  } catch (error) {
    handleTaskError(error, i18next.t('taskDucks_errorLoadExecutors'));
  } finally {
    dispatch({ type: LOADING_EXECUTORS, payload: false });
  }
};

export const executorAssign = makers => ({ type: EXECUTOR_ASSIGN, payload: makers });
export const clearState = () => ({ type: CLEAR_STATE });
export const clearExecutors = () => ({ type: CLEAR_EXECUTORS });
