import React, { useEffect } from 'react';
import { Wrapper } from './KezekDirectionStyle';
import KezekDirectionInfo from './KezekDirectionInfo';
import { useSelector, useDispatch } from 'react-redux';
import { kezekDirectionModule, loadData, clearState } from './KezekDirectionDucks';
import PageLoader from '../../components/PageLoader/PageLoader';
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary';
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs';
import { ErrorMessage } from './KezekDirectionStyle';
import Paper from '@material-ui/core/Paper';
import {
  PassportForm,
  PassportNavigation,
  PassportProvider
} from '../../passport/Passport';
import { PassportExpander } from '../../passport/Passport';
import './KezekDirectionTranslate';
import { Color } from '../../components/ThemeProvider/ThemeStyle';
import { useTranslation } from 'react-i18next';
import Button from '../../_ui/Button/Button';
import CancelIcon from '@material-ui/icons/Cancel';
import DoneIcon from '@material-ui/icons/Done';
import { ButtonsWrapper } from '../KezekPassportPage/KezekPassportStyle';
import paths from '../../_helpers/paths';
import useSimpleModal from '../../components/_hooks/useSimpleModal';
import ModalRevoke from './ModalRevoke';
import ModalApprove from './ModalApprove';
import { DIRECTION_STATUS } from '../../_helpers/Constants';
import { NavLink } from 'react-router-dom';

export default function KezekDirectionPage({ match }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const error = useSelector(state => state[kezekDirectionModule].error);
  const loading = useSelector(state => state[kezekDirectionModule].loading);
  const loadingApprove = useSelector(state => state[kezekDirectionModule].loadingApprove);
  const loadingRevoke = useSelector(state => state[kezekDirectionModule].loadingRevoke);
  const info = useSelector(state => state[kezekDirectionModule].info);
  const id = match.params.id;
  const revokeModal = useSimpleModal();
  const approveModal = useSimpleModal();

  // passport data
  const data = useSelector(state => state[kezekDirectionModule].data);
  const meta = useSelector(state => state[kezekDirectionModule].meta);
  const kgData = useSelector(state => state[kezekDirectionModule].kgData);
  const kgMeta = useSelector(state => state[kezekDirectionModule].kgMeta);
  const bData = useSelector(state => state[kezekDirectionModule].bData);
  const bMeta = useSelector(state => state[kezekDirectionModule].bMeta);

  const breadCrumbItems = [
    { name: t('breadcrumbs_home'), href: '/' },
    { name: t('breadcrumbs_kezekDirectionList'), href: paths.kezekDirectionTable },
    { name: t('breadcrumbs_kezekDirection') }
  ];

  useEffect(() => {
    dispatch(loadData(id));
    return () => dispatch(clearState());
  }, [dispatch, id]);

  if (loading) return <PageLoader />;
  if (error) {
    return (
      <>
        <BreadCrumbs items={breadCrumbItems} />
        <Paper className="p3">
          <ErrorMessage>
            <code>{error}</code>
          </ErrorMessage>
        </Paper>
      </>
    );
  }

  return (
    <>
      <BreadCrumbs items={breadCrumbItems} />
      <Wrapper className="px3 pb3 pt1">
        <ErrorBoundary>
          <PassportProvider
            withoutScroll={false}
            stickyTop={Color.headerHeight}
            info={info}
            data={[kgData, bData, data]}
            meta={[kgMeta, bMeta, meta]}
            config={{
              documentsProps: {
                mimeType: 'image/jpeg,image/png,application/pdf',
                maxSize: 10000000,
              },
            }}
          >
            <div className="flex justify-end py1">
              <PassportExpander />
              <Button
                size="small"
                color="primary"
                text={t('kezekDirection_kinderGardenView')}
                component={NavLink}
                to={paths.statementViewPage.replace(':id', kgData.requestFormId)}
              />
            </div>
            <div className="flex">
              {id !== undefined && (
                <div className="left-info">
                  <KezekDirectionInfo />
                  <PassportNavigation />
                </div>
              )}
              <div className="ml2 flex-auto">
                <PassportForm loading={loading} disabled={true} />
              </div>
            </div>
          </PassportProvider>
          {/*<ButtonsWrapper>
            {(data.statusDirection === DIRECTION_STATUS.PROLONGED ||
              data.statusDirection === DIRECTION_STATUS.RECEIVED) && (
              <Button
                icon={<CancelIcon fontSize="small" className="mr1" />}
                loading={loadingRevoke}
                style={{ marginLeft: 10 }}
                disabled={loading || loadingRevoke}
                size="small"
                color="secondary"
                text={t('kezekDirection_revoke')}
                onClick={() => revokeModal.open(id)}
              />
            )}
            {(data.statusDirection === DIRECTION_STATUS.PROLONGED ||
              data.statusDirection === DIRECTION_STATUS.RECEIVED ||
              data.statusDirection === DIRECTION_STATUS.RESERVED) && (
              <Button
                icon={<DoneIcon fontSize="small" className="mr1" />}
                loading={loadingApprove}
                style={{ marginLeft: 10, backgroundColor: Color.bgColorSuccess }}
                disabled={loading || loadingApprove}
                size="small"
                color="primary"
                text={t('kezekDirection_approveTitle')}
                onClick={() => approveModal.open(id)}
              />
              )}
          </ButtonsWrapper>*/}
          {revokeModal.isOpen && <ModalRevoke {...revokeModal} />}
          {approveModal.isOpen && <ModalApprove {...approveModal} />}
        </ErrorBoundary>
      </Wrapper>
    </>
  );
}
