import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import withTranslation from '../../components/_hoc/withTranslation';
import ReactTableWrapper from './ReactTableWrapper';

const StaticTablePagination = props => {
  const { t } = props;

  // pageSize
  let size = 5;
  if (props.data.length > 0) {
    size = props.data.length;
  }

  return (
    <div>
      <ReactTableWrapper {...props} pageSize={size} />
      {!props.hideBtn && props.onClickLoadMore && props.totalElements > props.data.length && (
        <Button
          fullWidth
          size="small"
          variant="outlined"
          loading={props.loadingBtn}
          onClick={props.onClickLoadMore}
        >
          {t('staticTable_load')}
        </Button>
      )}
    </div>
  );
};

StaticTablePagination.propTypes = {
  hideBtn: PropTypes.bool,
  filterable: PropTypes.bool,
  sortable: PropTypes.bool,
  loading: PropTypes.bool,
  data: PropTypes.array.isRequired,
  totalElements: PropTypes.number,
  columns: PropTypes.array.isRequired,
  onClickRow: PropTypes.func,
  onClickLoadMore: PropTypes.func,
  withoutHeader: PropTypes.bool,
  markText: PropTypes.string
};

StaticTablePagination.defaultProps = {
  hideBtn: false,
  filterable: true,
  loading: false,
  withoutHeader: false,
  totalElements: 0
};

export default withTranslation(StaticTablePagination);
