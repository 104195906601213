export const handbookItemOptionColumns = ({ t }) => {
  return [
    {
      Header: '№',
      accessor: 'rowNumber',
      width: 60,
      filterable: false
    },
    {
      Header: t('handbookPage_columnCode'),
      accessor: 'code',
      width: 200
    },
    {
      Header: t('handbookPage_columnKkName'),
      accessor: 'kk_name'
    },
    {
      Header: t('handbookPage_columnRuName'),
      accessor: 'ru_name'
    },
    {
      Header: t('handbookPage_columnVersion'),
      accessor: 'version',
      width: 100
    }
  ];
};
