import React from 'react';
import Modal from '../../../_ui/Modal/Modal';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Input from '../../../_ui/Input/Input';
import withTranslation from '../../../components/_hoc/withTranslation';
import Form from '../../../_ui/Form/Form';
import Row from '../../../_ui/Row';
import * as Yup from 'yup';
import Button from '../../../_ui/Button/Button';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { decline, statementModule } from '../StatementDucks';
import i18next from 'i18next';

const ValidationSchema = Yup.object().shape({
  declineSignatureReason: Yup.string().required(() =>
    i18next.t('statement_declineRequired')
  )
});

function DeclineModal({ onClose, id, decline, t, loading }) {
  return (
    <Modal open title={t('statement_declineTitle')} onClose={onClose} maxWidth="sm">
      <Form onSubmit={values => decline(id, values, onClose)} validate={ValidationSchema}>
        <DialogContent dividers>
          <Row label={t('statement_declineComment')} className="flex items-start">
            <Input
              name="declineSignatureReason"
              placeholder={t('statement_declineComment')}
              multiline
            />
          </Row>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            color="secondary"
            text={t('statement_declineSendAction')}
            loading={loading}
          />
        </DialogActions>
      </Form>
    </Modal>
  );
}

export default compose(
  withTranslation,
  connect(
    state => ({
      loading: state[statementModule].loadingDecline
    }),
    { decline }
  )
)(DeclineModal);
