import { statusColor } from '../../../utils/statusColors';
import { statusColumns } from '../statusColumns';
import Chart from 'chart.js';

export function renderVerticalCharts(t, lang, data, service) {
  const config = {
    type: 'bar',
    data: {
      labels: Object.keys(statusColumns).map(status => t(`monitoring_column${status}`)),
      datasets: [
        {
          backgroundColor: [
            '#8ab7c3',
            '#768ac3',
            statusColor.HARD_POSITIVE,
            statusColor.HARD_NEGATIVE,
            '#aad7ad',
            '#daa9a5',
            'rgba(0,0,0,0)'
          ],
          data: data.reduce(
            (result = [0, 0, 0, 0, 0, 0, 0], item) => {
              if (service) {
                if (item.service.code === service) {
                  Object.keys(statusColumns).forEach((status, index) => {
                    if (typeof item[status] === 'number') {
                      result[index] += item[status];
                    }
                  });
                }
              } else {
                Object.keys(statusColumns).forEach((status, index) => {
                  if (typeof item[status] === 'number') {
                    result[index] += item[status];
                  }
                });
              }
              return result;
            },
            [0, 0, 0, 0, 0, 0, 0]
          )
        }
      ]
    },
    options: {
      legend: { display: false },
      responsive: false,
      title: { display: false },
      scales: {
        yAxes: [{ ticks: { beginAtZero: true } }]
      }
    }
  };

  let elem = document.createElement('canvas');
  let Container = document.getElementById('vertical');
  elem.style.width = '100%';
  elem.style.height = '600px';
  Container.innerHTML = '';
  Container.appendChild(elem);
  new Chart(elem.getContext('2d'), config);
}
