import React from 'react';
import moment from 'moment';
import { TASK_STATUS, TASK_STATUS_NAMES } from '../../_helpers/Constants';
import { capitalize } from 'lodash';
import { CheckBox } from './MyTaskStyles';
import FilterInputField from '../../components/TableFilter/ColumnsFilters/FilterInputField';
import FilterSelect from '../../components/TableFilter/ColumnsFilters/FilterSelect';

export function myTaskColumns({
  t,
  lang,
  checkedList,
  serviceType,
  setCheckedList,
  content
}) {
  const handleChange = original => {
    checkedList.includes(original.id)
      ? setCheckedList(checkedList.filter(id => id !== original.id))
      : setCheckedList([...checkedList, original.id]);
  };

  const isDisabled = item => {
    if (
      item.configuration['enableBulkProcessing'] &&
      item.status === TASK_STATUS.IN_PROGRESS
    ) {
      if (checkedList[0]) {
        const row = content.find(({ id }) => id === checkedList[0]);
        if (row) {
          return !(
            row['processDefinitionId'] === item['processDefinitionId'] &&
            row['taskDefinitionKey'] === item['taskDefinitionKey'] &&
            row['serviceProviderBin'] === item['serviceProviderBin']
          );
        }
      }
      return false;
    } else {
      return true;
    }
  };

  return [
    {
      Header: '',
      accessor: 'checkItem',
      filterable: false,
      width: 50,
      Cell: ({ original }) => (
        <div
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
            if (!isDisabled(original)) {
              handleChange(original);
            }
          }}
          className="center"
        >
          <CheckBox
            disabled={isDisabled(original)}
            checked={checkedList.includes(original.id)}
            onChange={() => handleChange(original)}
          />
        </div>
      )
    },
    {
      Header: t('myTaskPage_columnStatementId'),
      accessor: 'businessObjectId',
      Filter: <FilterInputField name="businessObjectId" type="number" />
    },
    {
      Header: t('myTaskPage_columnStatementType'),
      accessor: 'serviceType ',
      Cell: ({ original: { serviceTypeName } }) => serviceTypeName[`${lang}_name`],
      Filter: <FilterSelect name="serviceType" options={serviceType} lang={lang} />
    },
    {
      Header: t('myTaskPage_columnDate'),
      accessor: 'submissionDate',
      Cell: row =>
        row.original.submissionDate
          ? moment(row.original.submissionDate).format('DD.MM.YYYY / HH:mm')
          : '',
      sortable: false,
      filterable: false,
      style: { textAlign: 'center' },
      maxWidth: 180
    },
    {
      Header: t('myTaskPage_columnIin'),
      accessor: 'requesterIin',
      style: { textAlign: 'center' },
      maxWidth: 170,
      Filter: <FilterInputField name="requesterIinBin" type="number" />
    },
    {
      Header: t('myTaskPage_columnFullName'),
      accessor: 'requesterName',
      Filter: <FilterInputField name="requesterName" type="text" />
    },
    {
      Header: t('myTaskPage_columnBin'),
      accessor: 'serviceProviderBin',
      style: { textAlign: 'center' },
      maxWidth: 170,
      Filter: <FilterInputField name="serviceProviderBin" type="number" />
    },
    {
      Header: t('myTaskPage_columnExternalId'),
      accessor: 'externalRequestId',
      Filter: <FilterInputField name="externalRequestId" type="number" />
    },
    {
      Header: t('myTaskPage_columnResponsible'),
      accessor: 'requestFormAssigneeName',
      Filter: <FilterInputField name="requestFormAssigneeName" type="text" />
    },
    {
      Header: t('myTaskPage_columnTaskNumber'),
      filterable: false,
      accessor: 'id'
    },
    {
      Header: t('myTaskPage_columnName'),
      accessor: 'name',
      filterable: false,
      Cell: ({ original }) => original.configuration[`name${capitalize(lang)}`]
    },
    {
      Header: t('myTaskPage_columnStatus'),
      accessor: 'status',
      Cell: ({ original }) => TASK_STATUS_NAMES[original.status][`${lang}_name`],
      Filter: (
        <FilterSelect
          name="taskStatus"
          options={Object.values(TASK_STATUS_NAMES).filter(item => !item.disableOption)}
        />
      )
    },
    {
      Header: t('myTaskPage_columnStartTime'),
      filterable: false,
      accessor: 'startTime',
      Cell: ({ original }) => moment(original.startTime).format('DD.MM.YYYY HH:mm')
    },
    {
      Header: t('myTaskPage_columnEndTime'),
      filterable: false,
      accessor: 'endTime',
      Cell: ({ original }) =>
        original.endTime && moment(original.endTime).format('DD.MM.YYYY HH:mm')
    }
  ];
}
