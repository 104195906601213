import { addTranslation } from '../../../_helpers/Translate';

addTranslation({
  ru: {
    businessProcess_title: 'Процессы',
    businessProcess_startProcess: 'Запустить новый процесс',
    businessProcess_endProcess: 'Завершить процесс',
    businessProcess_endTask: 'Завершить задачу',
    businessProcess_taskIN_PROGRESS: 'На исполнении',
    businessProcess_taskCOMPLETED: 'Завершено',
    businessProcess_taskINTERRUPTED: 'Прервана',
    businessProcess_processIN_PROGRESS: 'Запущено',
    businessProcess_processCOMPLETED: 'Завершено',
    businessProcess_processFORCE_COMPLETED: 'Остановлено вручную',
    businessProcess_modalStartTitle: 'Запуск нового процесса',
    businessProcess_modalStartQuestion: 'Запустить новый процесс ?',
    businessProcess_modalStartRun: 'Запустить',
    businessProcess_modalStopProcTitle: 'Завершение процесса',
    businessProcess_modalStopProcQuestion: 'Вы хотите завершить процесс ?',
    businessProcess_modalStopProcRun: 'Завершить',
    businessProcess_modalStopTaskTitle: 'Завершение задачи',
    businessProcess_modalStopTaskQuestion: 'Вы хотите завершить задачу ?',
    businessProcess_modalStopTaskRun: 'Завершить',
    businessProcess_errorLoadData: 'Не удалось загрузить бизнес процесс',
    businessProcess_errorStartProcess: 'Не удалось запустить новый процесс',
    businessProcess_errorStopProcess: 'Не удалось завершить процесс',
    businessProcess_errorStopTask: 'Не удалось завершить задачу',
  },
  kk: {
    businessProcess_title: 'Процестер',
    businessProcess_startProcess: 'Жаңа процесті бастау',
    businessProcess_endProcess: 'Аяқтау процесі',
    businessProcess_endTask: 'Соңындағы тапсырма',
    businessProcess_taskIN_PROGRESS: 'Орындалуда',
    businessProcess_taskCOMPLETED: 'Аяқталды',
    businessProcess_taskINTERRUPTED: 'Тоқтатылды',
    businessProcess_processIN_PROGRESS: 'Басталды',
    businessProcess_processCOMPLETED: 'Аяқталды',
    businessProcess_processFORCE_COMPLETED: 'Қолмен тоқтатылды',
    businessProcess_modalStartTitle: 'Жаңа процесті бастау',
    businessProcess_modalStartQuestion: 'Жаңа процесті бастаңыз ба?',
    businessProcess_modalStartRun: 'Бастау',
    businessProcess_modalStopProcTitle: 'Аяқтау процесі',
    businessProcess_modalStopProcQuestion: 'Процесті аяқтағыңыз келе ме?',
    businessProcess_modalStopProcRun: 'Соңы',
    businessProcess_modalStopTaskTitle: 'Соңындағы тапсырма',
    businessProcess_modalStopTaskQuestion: 'Тапсырманы аяқтағыңыз келе ме?',
    businessProcess_modalStopTaskRun: 'Тоқтату',
    businessProcess_errorLoadData: 'Іскери процесс жүктелмеді',
    businessProcess_errorStartProcess: 'Жаңа процесті бастау мүмкін болмады',
    businessProcess_errorStopProcess: 'Процесс аяқталмады',
    businessProcess_errorStopTask: 'Тапсырма орындалмады',
  }
});
