import { useEffect, useState } from 'react';
import React from 'react';
import useColumnFilter from '../useColumnFilter';
import { Switch } from '@material-ui/core';

export default function FilterYesNoField({
  name,
  type,
  queryIndex = '',
  placeholder,
  startAdornment,
  ...rest
}) {
  const filter = useColumnFilter(name, queryIndex);
  const [value, setValue] = useState(false);

  useEffect(() => {
    setValue(filter.value || false);
    // eslint-disable-next-line
  }, []);

  const handleChange = (event, value) => {
    if (value) {
      filter.setFilter(value);
      setValue(value);
    } else {
      filter.setFilter(null);
      setValue(value);
    }
  };

  return (
    <div style={{ width: '100%' }}>
      <Switch
        checked={value}
        onChange={handleChange}
        color="primary"
        {...rest}
      />
    </div>
  );
}
