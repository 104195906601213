import styled from 'styled-components';
import { Color } from '../../components/ThemeProvider/ThemeStyle';
import Paper from '@material-ui/core/Paper';

export const WrapperForm = styled.div`
  display: grid;
  grid-template-columns: 0.7fr 1fr;
  margin-bottom: 10px;
`;

export const ButtonsWrapper = styled.div`
  position: sticky;
  bottom: 0;
  background-color: ${Color.bgColorPaper};
  padding: 10px 0;
  text-align: right;
  border-top: 1px solid ${Color.border};
  margin-top: 30px;
  z-index: 1;
  box-shadow: 0 -6px 6px -6px rgba(0, 0, 0, 0.16);
  display: flex;
  justify-content: flex-end;
`;

export const Wrapper = styled(Paper)`
  min-width: 1000px;
  .title {
    font-weight: 600;
    font-size: 18px;
    padding-bottom: 5px;
  }
  .left-info {
    width: 350px;
  }
`;

export const PhotoWrapper = styled.div`
  width: 220px;
  height: 240px;
  border-radius: 6px !important;
  border: 1px solid #cecfd2;
  position: relative;
    p {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin: 0;
      padding: 0;
    }
`