import { addTranslation } from '../../_helpers/Translate';

addTranslation({
  ru: {
    manual_title: 'Руководство пользователя',
    manual_canNotViewFile: 'Не удалось открыть файл для просмотра',
    manual: "Инструкция пользователя для оказания государственной услуги",
    manualUniversityGrant: "Присуждение образовательных грантов, а также оказание социальной поддержки обучающимся в организациях высшего образования",
    manualStagingDDO: "Инструкция для сотрудников дошкольных организаций по оказанию гос. услуг"
  },
  kk: {
    manual_title: 'Пайдаланушы нұсқаулығы',
    manual_canNotViewFile: 'Көру үшін файлды ашу мүмкін болмады',
    manual: "Мемлекеттік қызметін көрсету үшін пайдаланушы нұсқаулығы",
    manualUniversityGrant: "Білім беру гранттарын тағайындау, сондай-ақ жоғары білім беру ұйымдарында білім алушыларға әлеуметтік қолдау көрсету",
    manualStagingDDO: "Мемлекеттік қызмет көрсету бойынша мектепке дейінгі ұйымдардың қызметкерлеріне арналған нұсқаулық"
  }
});
