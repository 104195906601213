import { addTranslation } from '../../_helpers/Translate';

addTranslation({
  ru: {
    kezekRequest_title: 'Запросы на отзыв места',
    kezekRequest_add: 'Добавить',
    kezekRequest_columnID: 'Номер',
    kezekRequest_columnBin: 'БИН',
    kezekRequest_columnDate: 'Дата запроса',
    kezekRequest_columnServiceProvider: 'Наименование ДО',
    kezekRequest_columnServiceProviderLocation: 'Населенный пункт',
    kezekRequest_columnQueuedType: 'Тип очереди',
    kezekRequest_columnTypeBulletin: 'Вид бюллетени',
    // kezekRequest_columnStatusWeb: 'Статус бюллетени',
    kezekRequest_columnGroupName: 'Группа',
    kezekRequest_columnAgeGroup: 'Возрастная группа',
    kezekRequest_columnNumberSeats: 'Общее количество мест',
    kezekRequest_columnDeletedPlaces: 'Количество удаляемых мест',
    kezekRequest_columnAcademicYear: 'Учебный год',
    kezekRequest_columnReasonDeletion: 'Причина отзыва',
    kezekRequest_columnApprovalStatus: 'Статус',

    kezekRequestDucks_errorLoadTable: 'Не удалось загрузить бюллетени',
    kezekRequestDucks_errorChangeFilter: 'Не удалось поменять фильтр',
  },
  kk: {
    kezekRequest_title: 'Орындарды қайтарып алуға сұраныс',
    kezekRequest_add: 'Қосу',
    kezekRequest_columnID: 'Нөмірі',
    kezekRequest_columnBin: 'БСН',
    kezekRequest_columnDate: 'Сұраныс уақыты',
    kezekRequest_columnServiceProvider: 'МДҰ атауы',
    kezekRequest_columnServiceProviderLocation: 'Аймақ',
    kezekRequest_columnQueuedType: 'Кезек түрі',
    kezekRequest_columnTypeBulletin: 'Бюллетень түрі',
    // kezekRequest_columnStatusWeb: 'Бюллетень күйі',
    kezekRequest_columnGroupName: 'Тобы',
    kezekRequest_columnAgeGroup: 'Жас тобы',
    kezekRequest_columnNumberSeats: 'Жалпы орындар саны',
    kezekRequest_columnAcademicYear: 'Оқу жылы',
    kezekRequest_columnReasonDeletion: 'Кері қайтару себебі',
    kezekRequest_columnApprovalStatus: 'Статус',
    kezekRequest_columnDeletedPlaces: 'Жойылатын орындар саны',

    kezekRequestDucks_errorLoadTable: 'Бюллетеньдерді жүктеу мүмкін емес',
    kezekRequestDucks_errorChangeFilter: 'Сүзгіні өзгерту мүмкін болмады',
  }
});
