import React from 'react';
import { createReducer } from 'redux-starter-kit';
import _ from 'lodash';
import { LoginApi, ServiceProvidersApi } from '../../_helpers/service';
import { history } from '../../_helpers/history';
import routerProps from '../../_helpers/routerProps';
import paths from '../../_helpers/paths';
import { handleError } from '../../utils/handleError';
import i18next from 'i18next';
import Notice from '../../utils/Notice';
import { NCALayerSign } from '../../components/NCALayer/NCALayerSign';
import PERMISSIONS from '../../_helpers/permissions';
import permissionsMap from '../../_helpers/permissionsMap';
import { ROLES } from '../../_helpers/Constants';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { setValidPassport } from '../KezekPassportPage/KezekPassportDucks';
import { jwtDecode } from 'jwt-decode';

/**
 * Constants
 */

export const loginModule = 'login';
const USER = `${loginModule}/USER`;
const LOGOUT = `${loginModule}/LOGOUT`;
const LOADING = `${loginModule}/LOADING`;
const LOADING_LOGIN = `${loginModule}/LOADING_LOGIN`;
const LOADING_ECP = `${loginModule}/LOADING_ECP`;
const NEED_CHANGE_PASS = `${loginModule}/NEED_CHANGE_PASS`;
/**
 * Reducer
 */

const initialState = {
  loadingLogin: false,
  loading: true,
  loadingECP: false,
  user: null,
  permissions: []
};

export default createReducer(initialState, {
  [USER]: (state, action) => {
    state.user = action.user;
    state.permissions = action.permissions;
  },
  [LOADING]: (state, action) => {
    state.loading = action.loading;
  },
  [LOADING_LOGIN]: (state, action) => {
    state.loadingLogin = action.loadingLogin;
  },
  [LOADING_ECP]: (state, action) => {
    state.loadingECP = action.payload;
  },
  [LOGOUT]: state => {
    state.user = null;
  },
  [NEED_CHANGE_PASS]: (state, action) => {
    state.user.needChangePassword = action.payload;
  }
});

/**
 * Actions
 */

export const login = params => async dispatch => {
  try {
    dispatch({ type: LOADING_LOGIN, loadingLogin: true });
    let { data } = await LoginApi.login(params);
    const decode = jwtDecode(data.accessToken);
    if (decode.exp) {
      localStorage.setItem('accessToken', data.accessToken);
      localStorage.setItem('refreshToken', data.refreshToken);
      localStorage.setItem('expiredAt', decode.exp * 1000 - 10000);
      dispatch(setUser(data.user, true));
    }
    /*if (localStorage.getItem('withoutAuthSignature') === 'true') {
      localStorage.setItem('accessToken', data.accessToken);
      localStorage.setItem('refreshToken', data.refreshToken);
      localStorage.setItem('expiredAt', Date.now() + 600000);
      dispatch(setUser(data.user, true));
    } else {
      dispatch(loginWithECP());
    }*/
  } catch (error) {
    handleStatusError(error, i18next.t('loginDucks_authError'));
  } finally {
    dispatch({ type: LOADING_LOGIN, loadingLogin: false });
  }
};

export const loginWithECP = () => async dispatch => {
  try {
    dispatch({ type: LOADING_ECP, payload: true });
    let { data } = await LoginApi.getStringToSign();
    const signedXml = await NCALayerSign(data.signatureAuthToken, true);
    if (signedXml) {
      const { data: token } = await LoginApi.loginWithSign({ signature: signedXml });

      const decode = jwtDecode(data.accessToken);
      if (decode.exp) {
        localStorage.setItem('accessToken', token.accessToken);
        localStorage.setItem('refreshToken', token.refreshToken);
        localStorage.setItem('expiredAt', decode.exp * 1000 - 10000);
        dispatch(setUser(token.user, true));
        history.push(window.addressBarUrl || paths.homePage);
      }
    }
  } catch (error) {
    handleStatusError(error, i18next.t('loginDucks_authError'));
  } finally {
    dispatch({ type: LOADING_ECP, payload: false });
  }
};

export const checkLogin = () => async dispatch => {
  try {
    dispatch({ type: LOADING, loading: true });
    const { data } = await LoginApi.getUser();
    dispatch(setUser(data.user));
  } catch (error) {
    let location = window.location;
    for (const route of Object.values(routerProps)) {
      if (route.path === location.pathname && !route.withoutAuth) {
        window.addressBarUrl = `${location.pathname}${location.search}`;
        history.push(paths.loginPage);
      }
    }
    dispatch({ type: LOADING, loading: false });
  }
};

export const setUser = (user, isLoginPage) => async dispatch => {
  let organization = {};
  let permissions = [];
  let profileData = {};
  let orgType = null;
  let response = null;
  let data = null;

  // collect roles
  const groupNames = user.groups.map(g => g.name);
  const roles = groupNames.filter(n => Object.values(ROLES).includes(n));

  // collect profileData
  if (user.profileData) {
    user.profileData.forEach(item => {
      if (item.name.toUpperCase() === 'ES_SERVICE_PROVIDER_ID') {
        profileData.organization_id = item.value;
      }
      if (item.name.toUpperCase() === 'POSITION_RU') {
        profileData.position_ru = item.value;
      }
      if (item.name.toUpperCase() === 'POSITION_KK') {
        profileData.position_kk = item.value;
      }
      if (item.name.toUpperCase() === 'PHONE_NUMBER') {
        profileData.phone_number = item.value;
      }
      if (item.name.toUpperCase() === 'ES_REGION_CODE') {
        profileData.region_code = item.value;
      }
    });
  }

  if (!user.services_list) {
    user.services_list = [];
  }
  
  if (profileData.organization_id) {
    response = await ServiceProvidersApi.load(profileData.organization_id);
    data = response.data['questionnaire']['questionCodeToAnswers'];
    orgType = _.get(data, 'type_code.values[0].value', null);
  }

  // collect permissions
  for (const group of user.groups || []) {
    for (const permission of group.items || []) {
      if (permission.appName === 'ES_APP') {
        permissionsMap(permission.name, permissions, orgType);
        permissions.push(permission.name);
      }
    }
  }

  // collect organization
  if (profileData.organization_id) {
    organization.bin = _.get(response, 'data.bin', '');
    organization.kk_name = _.get(data, 'kk_name.values[0].value', '');
    organization.ru_name = _.get(data, 'ru_name.values[0].value', '');
    organization.services = _.get(data, 'Name_gu.values[0].value', null);
    organization.orgType = _.get(data, 'type_code.values[0].value', null);

    const providerType = _.get(data, 'service_provider_type.values[0].value', null);
    const typeCode = _.get(data, 'type_code.values[0].value', null);
    const subTypes = _.get(data, 'sub_types.values[0].value', []);
    const formOwnership = _.get(data, 'form_ownership.values[0].value', null);
    const nameGu = _.get(data, 'Name_gu.values[0].value', null);

    if (providerType === '0' && typeCode === '13' && nameGu.includes('doc_queued_ddo')) {
      if (roles.includes(ROLES.ES_REGION_MIO_EMPLOYEES)) {
        permissions.push(PERMISSIONS.MIO_EMPLOYEE);
      }
    } else if (
      providerType === '1' &&
      typeCode === '08' &&
      subTypes.some(item => ['08.1', '08.2', '08.5'].includes(item))
    ) {
      permissions.push(PERMISSIONS.DDO_EMPLOYEE);
    } else if (providerType === '1' && typeCode === '01') {
      permissions.push(PERMISSIONS.DDO_EMPLOYEE);
    }
    // if (typeCode === '01') {
    //   Notice.info(i18next.t('loginDucks_notAbleReceive'));
    // }
    dispatch(checkValidPassportKezek(typeCode, subTypes, formOwnership));
  }

  try {
    const res = await LoginApi.getUserProfile(user.username);
    const profile = res?.data?.result ?? {};
    const dateToString = date => (date ? moment(date).format('L - LT') : '');
    user.creationDt = dateToString(profile.creationDt);
    user.lastLoginDt = dateToString(profile.lastLoginDt);
    user.lastPasswordChange = dateToString(profile.lastPasswordChange);
    user.lastFailedAttemp = dateToString(profile.lastFailedAttemp);
  } catch (error) {
    console.error(error);
  }

  // проверка срока смены пароля
  if (
    isLoginPage &&
    user.enablePasswordPolicy &&
    user.numberOfDaysBeforePasswordExpires < 10
  ) {
    const xDay = user.numberOfDaysBeforePasswordExpires;
    if (xDay <= 0) {
      Notice.error(
        <div>
          {i18next.t('loginDucks_passwordPolicy0Day')}
          {/*<br />*/}
          {/*<Link to="?changePass=true">{window.location.href}</Link>*/}
        </div>
      );
    } else {
      Notice.warning(
        <div>
          {i18next.t('loginDucks_passwordPolicy', { xDay })}
          <br />
          <Link to="?changePass=true">{window.location.href}</Link>
        </div>
      );
    }
  }
  // просто выводим уведомление
  if (isLoginPage) {
    Notice.warning(i18next.t('loginPage_disclaimer'));
  }

  dispatch({
    type: USER,
    user: { ...user, ...profileData, roles, organization },
    permissions
  });
  dispatch({ type: LOADING, loading: false });
};

export const logout = () => async dispatch => {
  try {
    await LoginApi.logout();
    dispatch({ type: LOGOUT });
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('expiredAt');
    dispatch(setValidPassport(false));
    window.addressBarUrl = undefined;
    history.push(paths.loginPage);
  } catch (e) {
    Notice.error(i18next.t('loginDucks_logoutError'));
  }
};
export const clearUser = () => async dispatch => {
  dispatch({ type: LOGOUT });
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('expiredAt');
  window.addressBarUrl = undefined;
  history.push(paths.loginPage);
};

export function handleStatusError(error, message) {
  if (error.response && error.response.data) {
    if (error.response.data.status === 'INCORRECT_USERNAME_PASSWORD') {
      Notice.error(i18next.t('loginDucks_userNotFound'));
    } else if (error.response.data.status === 'MULTISESSION_NOT_ALLOWED') {
      Notice.error(i18next.t('loginDucks_multisessionNotAllowed'));
    } else if (error.response.data.status === 'SIGNATURE_VERIFICATION_FAIL') {
      Notice.error(i18next.t('loginDucks_signatureVerificationFail'));
    } else if (error.response.data.status === 'PASSWORD_ATTEMPTS_EXCEEDED') {
      Notice.error(i18next.t('loginDucks_passwordAttemptsExceeded'));
    } else if (error.response.data.status === 'PASSWORD_IS_EXPIRED') {
      Notice.error(i18next.t('loginDucks_passwordIsExpired'));
    } else if (error.response.data.status === 'NEW_PASSWORD_IS_EQUALS_TO_PREVIOUS') {
      Notice.error(i18next.t('loginDucks_newPasswordIsEqualsToPrevious'));
    } else if (error.response.data.status === 'USER_NOT_ACTIVE') {
      Notice.error(i18next.t('loginDucks_userNotActive'));
    } else {
      handleError(error, message);
    }
  } else {
    handleError(error, message);
  }
}

const checkValidPassportKezek = (typeCode, subTypes, formOwnership) => async dispatch => {
  if (typeCode === '01' && !formOwnership) {
    dispatch(setValidPassport(true));
  } else if (
    typeCode === '08' &&
    subTypes.some(item => ['08.1', '08.2'].includes(item)) &&
    !formOwnership
  ) {
    dispatch(setValidPassport(true));
  } else {
    dispatch(setValidPassport(false));
  }
};

export const needChangePass = payload => ({ type: NEED_CHANGE_PASS, payload });
