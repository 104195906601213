import companies from './companies';
let config;
const protocol = window.location.protocol;

switch (window.location.host) {
  case 'es.e-zhetysu.kz':
    config = companies.Zhetisu;
    /*window.globalMessage = {
      type: 'warning',
      kk: '"Egov" - та техникалық жұмыстардың жүргізілуіне байланысты ЭЦҚ көмегімен өтінімдерге қол қою АЖО жүйесінде уақытша жұмыс істемейді.',
      ru: 'В связи с проведением технических работ на "Egov", подписание заявок  с помощью ЭЦП в системе АРМ временно не работает'
    };*/
    break;
  case 'es-test.e-zhetysu.kz': {
    config = {
      ...companies.Almaty,
      url: {
        base: `${protocol}//es-api-test.e-zhetysu.kz`,
        service: `${protocol}//es-services-test.e-zhetysu.kz`,
        fileStore: `${protocol}//es-filestore-api-test.e-zhetysu.kz`
      }
    };
    break;
  }
  case 'es.iac.kz':
    config = companies.MON;
    /*    window.globalMessage = {
      type: 'error',
      kk: 'Нұр-Сұлтан уақыты бойынша сағат 11.30-ға дейін техникалық жұмыстар жүргізілуіне байланысты ақпараттық жүйе қолжетімсіз',
      ru: 'Информационная система недоступна, т.к. ведутся технические работы  до 11.30 часов по времени Нур-Султан'
    };*/
    break;
  case 'es-test.iac.kz': {
    config = {
      ...companies.MON,
      url: {
        base: `${protocol}//es-api-test.iac.kz`,
        service: `${protocol}//nobd-services-test.iac.kz`,
        fileStore: `${protocol}//es-filestore-api-test.iac.kz`
      }
    };
    break;
  }
  case 'portal.gu.almobl.kz': {
    config = {...companies.Almaty};
    break;
  }
  case 'gu.almobl.kz': {
    config = { ...companies.Almaty };
    break;
  }
  case 'psod.dlife.kz:8443': {
    config = {
      ...companies.Almaty,
      url: {
        base: `${protocol}//api-psod.dlife.kz:8443`,
        service: `${protocol}//services-api-psod.dlife.kz:8443`,
        fileStore: `${protocol}//filestore-api-psod.dlife.kz:8443`
      }
    };
    break;
  }
  default: {
    const protocol = 'https:';
    config = {
      ...companies.Almaty,
      manualUrl: {
        ru: `${protocol}//es-test.e-zhetysu.kz/server/folder/media/manualAlmatyRU.pdf`,
        kk: `${protocol}//es-test.e-zhetysu.kz/server/folder/media/manualAlmatyKK.pdf`
      },
      manualUniversityGrantUrl: {
        ru: `${protocol}//es-test.e-zhetysu.kz/server/folder/media/MANUAL_UNIVERSITY_GRANT_RU.pdf`,
        kk: `${protocol}//es-test.e-zhetysu.kz/server/folder/media/MANUAL_UNIVERSITY_GRANT_KK.pdf`
      },
      url: {
        base: `${protocol}//es-api-test.e-zhetysu.kz`,
        service: `${protocol}//es-services-test.e-zhetysu.kz`,
        fileStore: `${protocol}//es-filestore-api-test.e-zhetysu.kz`
      }
    };
  }
}

export default config;
