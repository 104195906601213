import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Form from '../../../_ui/Form/Form';
import { validation } from './Validation';
import Input from '../../../_ui/Input/Input';
import { mailResetPass, resetPassModule } from '../ResetPasswordDucks';
import Button from '../../../_ui/Button/Button';

function MailLink() {
  const { t } = useTranslation();
  const { isMailing } = useSelector(state => state[resetPassModule]);
  const dispatch = useDispatch();

  return (
    <Form
      initialValues={{ username: '' }}
      validate={validation}
      onSubmit={values => dispatch(mailResetPass(values.username))}
    >
      <h2 className="mt0">{t('resetPass_recovering')}</h2>
      <b>{t('resetPass_login')}</b>
      <Input name="username"/>
      <small>{t('resetPass_info')}</small>
      <div className="mt2">
        <Button
          type="submit"
          loading={isMailing}
          text={t('resetPass_recover')}
        />
      </div>
    </Form>
  );
}

export default MailLink;