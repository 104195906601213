import React from 'react';
import Modal from '../../../_ui/Modal/Modal';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import Button from '../../../_ui/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { contingentPassportModule, addContingent } from '../ContingentPassportDucks';
import DoneIcon from '@material-ui/icons/Done';
import { Color } from '../../../components/ThemeProvider/ThemeStyle';
import {
  getFormValues,
} from '../../../passport/Passport';
import { useHistory } from "react-router-dom";

export default function ModalAdd(data) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector(state => state[contingentPassportModule].loadingSave);
  return (
    <Modal
      title={t('contingentPassport_enrollConfirmationBtn') }
      open
      onClose={data.close}
    >
      <DialogContent>
        <div className="flex justify-end pb2">
          <Button
            color="primary"
            style={{ backgroundColor: Color.bgColorSuccess }}
            loading={loading}
            icon={<DoneIcon fontSize="small" className="mr1" />}
            text={ t('contingentPassport_yes') }
            onClick={() => {
              getFormValues().then(values => {
                values['contingentChanges'] = [...values['form_contingent_do_child']];
                delete values['form_contingent_do_child']
                dispatch(
                  addContingent({ values, history, close: data.close, groupId: data?.data?.groupId })
                )
             });
            }}
          />
        </div>
      </DialogContent>
    </Modal>
  );
}