import React from 'react';
import { Color } from '../../../components/ThemeProvider/ThemeStyle';
import Modal from '../../../_ui/Modal/Modal';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '../../../_ui/Button/Button';
import { compose } from 'redux';
import withTranslation from '../../../components/_hoc/withTranslation';
import { connect } from 'react-redux';
import DoneIcon from '@material-ui/icons/Done';
import {
  clearState,
  reopenHeir,
  serviceProvidersModule
} from '../ServiceProvidersDucks';

function HeirReopenedModal({ modal, t, reopenHeir }) {
  const { bin, id } = modal.data;
  return (
    <Modal
      open
      title={t('serviceProviders_heirReopened_title')}
      onClose={modal.close}
      maxWidth="sm"
    >
      <DialogContent dividers children={t('serviceProviders_heirReopened', { bin })} />
      <DialogActions>
        <Button
          size="small"
          type="button"
          style={{ backgroundColor: Color.bgColorSuccess }}
          icon={<DoneIcon fontSize="small" className="mr1" />}
          text={t('serviceProviders_reopened')}
          onClick={() => reopenHeir(id, modal.close)}
        />
      </DialogActions>
    </Modal>
  );
}

export default compose(
  withTranslation,
  connect(
    state => ({
      heirTableData: state[serviceProvidersModule].heirTableData,
      filter: state[serviceProvidersModule].heirTableData.modalTableFilter
    }),
    {
      reopenHeir,
      clearState
    }
  )
)(HeirReopenedModal);
