import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogContent, CircularProgress } from '@material-ui/core';
import Modal from '../../../_ui/Modal/Modal';
import Button from '../../../_ui/Button/Button';
import { handleError } from '../../../utils/handleError';
import { Color } from '../../ThemeProvider/ThemeStyle';
import './PreviewDocumentTranslate';

function PreviewDocumentModal({ preview, download, onClose }) {
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const [fileUrl, setFileUrl] = useState();
  const [contentType, setContentType] = useState('');

  useEffect(() => {
    preview()
      .then(response => {
        setFileUrl(URL.createObjectURL(response.data));
        setContentType(response.headers['content-type']);
      })
      .catch(error => setError(handleError(error)));
  }, [preview]);

  const downloadDocument = () => {
    setContentType('');

    download()
      .then(response => {
        const contentDisposition = response.headers['content-disposition'];
        const filename = !!contentDisposition
          ? contentDisposition
              .split(';')[1]
              .split('=')[1]
              .replace('"', '')
              .replace('"', '')
          : 'document';

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.style.display = 'none';
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);

        onClose();
      })
      .catch(error => setError(handleError(error)));
  };

  const contType = useMemo(() => {
    if (contentType.startsWith('image')) {
      return 'image';
    }
    if (contentType.startsWith('video')) {
      return 'video';
    }
    if (contentType !== '') {
      return 'object';
    }
    return 'loading';
  }, [contentType]);

  return (
    <Modal open maxWidth="lg" onClose={onClose}>
      <DialogContent>
        {contType === 'image' && <img src={fileUrl} width="auto" height="800px" alt="" />}
        {contType === 'video' && (
          <video
            autoPlay
            controls
            controlsList="nodownload"
            width="auto"
            height="400px"
            src={fileUrl}
          />
        )}
        {contType === 'object' && (
          <object data={fileUrl} width="100%" height="800px" style={{ minWidth: 600 }}>
            <div className="bold center p3" style={{ color: Color.secondary }}>
              {t('previewDocument_showContentError')}
              <div style={{ marginTop: 10 }}>
                <Button
                  text={t('previewDocument_downloadContent')}
                  onClick={downloadDocument}
                />
              </div>
            </div>
          </object>
        )}
        {!error && contType === 'loading' && (
          <div className="center p3">
            <CircularProgress />
          </div>
        )}
        {error && (
          <div className="bold center p3" style={{ color: Color.secondary }}>
            {t('previewDocument_downloadContentError')}
            <br />
            {error}
          </div>
        )}
      </DialogContent>
    </Modal>
  );
}

export default PreviewDocumentModal;
