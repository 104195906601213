import { createReducer } from 'redux-starter-kit';
import { handleError } from '../../utils/handleError';
import i18next from 'i18next';
import Dict from '../../utils/Dict';
import { MonitoringApi } from '../../_helpers/service';

/**
 * Constants
 */
export const monitoringModule = 'monitoring';
const LOADING = `${monitoringModule}/LOADING`;
const SET_DATA = `${monitoringModule}/SET_DATA`;
const CLEAR_STATE = `${monitoringModule}/CLEAR_STATE`;

/**
 * Reducer
 */
const initialState = {
  loading: true,
  data: []
};

export default createReducer(initialState, {
  [LOADING]: (state, action) => {
    state.loading = action.payload;
  },
  [SET_DATA]: (state, action) => {
    state.data = action.payload;
  },
  [CLEAR_STATE]: () => initialState
});

/**
 * Actions
 */
export const clearState = () => ({ type: CLEAR_STATE });

export const loadData = filter => async dispatch => {
  try {
    const { fromDate, toDate } = filter;
    if (fromDate && toDate) {
      dispatch({ type: LOADING, payload: true });
      const dictServices = await Dict.itemsObject('gu_services');
      const dictKato = await Dict.itemsObject('60');
      const response = await MonitoringApi.loadData(fromDate, toDate);
      const data = response.data.content.map(item => {
        let result = {};
        for (const column of item.passportValues) {
          result[column.code] = column.value;
          if (column.code === 'service_type') {
            const value = column.value.replace('REQUEST_FORM_', '').replace('request_form_', '');
            const serviceItem =
              dictServices[value.toUpperCase()] ||
              dictServices[value.toLowerCase()] ||
              {};
            result[column.code] = value;
            result.service = serviceItem;
            result.ru_serviceName = serviceItem.ru_name;
            result.kk_serviceName = serviceItem.kk_name;
          }
          if (column.code === 'area_code') {
            const locationItem = dictKato[column.value] || {};
            result.location = locationItem.code || 'none';
            result.ru_locationName = locationItem.ru_name;
            result.kk_locationName = locationItem.kk_name;
          }
        }
        return result;
      });
      dispatch({ type: SET_DATA, payload: data });
    }
  } catch (error) {
    handleError(error, i18next.t('monitoringDucks_errorLoadTable'));
  } finally {
    dispatch({ type: LOADING, payload: false });
  }
};
