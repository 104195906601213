import { createReducer } from 'redux-starter-kit';
import i18next from 'i18next';
import { TaskApi } from '../../../../_helpers/service';
import Notice from '../../../../utils/Notice';
import { TASK_STATUS } from '../../../../_helpers/Constants';
import { userFullName } from '../../../../utils/userFullName';
import { handleTaskError } from '../handleTaskError';
import { forwardToNextTask } from '../../MyTaskDucks';

export const selectUserModule = 'taskSelectUser';
const LOADING = `${selectUserModule}/LOADING`;
const LOADING_ACTION = `${selectUserModule}/LOADING_ACTION`;
const SHORT_INFO = `${selectUserModule}/SHORT_INFO`;
const CLEAR_STATE = `${selectUserModule}/CLEAR_STATE`;
const DATA = `${selectUserModule}/DATA`;
const USERS = `${selectUserModule}/USERS`;

/**
 * Reducer
 */

const initialState = {
  loading: false,
  loadingAction: false,
  shortInfo: {},
  data: {
    configuration: {}
  },
  users: []
};

export default createReducer(initialState, {
  [LOADING]: (state, action) => {
    state.loading = action.payload;
  },
  [LOADING_ACTION]: (state, action) => {
    state.loadingAction = action.payload;
  },
  [DATA]: (state, action) => {
    state.data = action.payload;
  },
  [SHORT_INFO]: (state, action) => {
    state.shortInfo = action.payload;
  },
  [USERS]: (state, action) => {
    state.users = action.payload;
  },
  [CLEAR_STATE]: () => initialState
});

/**
 * Actions
 */

export const loadData = id => async dispatch => {
  dispatch({ type: LOADING, payload: true });
  try {
    let { data: task } = await TaskApi.loadTask(id);

    if (task.result.status === TASK_STATUS.IN_PROGRESS) {
      let { data: users } = await TaskApi.usersForSelect(id);
      dispatch({
        type: USERS,
        payload: users.result.map(item => ({
          code: item.username,
          ru_name: userFullName(item),
          kk_name: userFullName(item)
        }))
      });
    } else if (task.result.data) {
      dispatch({
        type: USERS,
        payload: [
          {
            code: task.result.data.selectedUsername,
            ru_name: task.result.data.selectedUserFullName
          }
        ]
      });
    }

    try {
      if (task.result.businessObjectId) {
        let res = await TaskApi.shortInfo(task.result.businessObjectId);
        dispatch({ type: SHORT_INFO, payload: res.data.result });
      }
    } catch (e) {}

    dispatch({ type: DATA, payload: task.result });
  } catch (error) {
    handleTaskError(error, i18next.t('taskDucks_errorLoadTask'));
  } finally {
    dispatch({ type: LOADING, payload: false });
  }
};

export const submit = (taskIds, selectedUsername, actionId, user) => async dispatch => {
  try {
    dispatch({ type: LOADING_ACTION, payload: actionId });
    let { data } = await TaskApi.usersSubmit(taskIds, actionId, {
      '@type': 'SelectUserTaskData',
      selectedUsername
    });
    const result = Object.values(data.result)[0];
    dispatch({ type: DATA, payload: result });
    await forwardToNextTask(result.processInstanceId, user, { selectedUsername }, () => {
      Notice.success(i18next.t('taskDucks_successOnApprove'));
    });
  } catch (error) {
    handleTaskError(error, i18next.t('taskDucks_errorOnApprove'));
  } finally {
    dispatch({ type: LOADING_ACTION, payload: null });
  }
};

export const save = (taskIds, selectedUsername, actionId) => async dispatch => {
  dispatch({ type: LOADING_ACTION, payload: actionId });
  try {
    let { data } = await TaskApi.usersSave(taskIds, actionId, {
      '@type': 'SelectUserTaskData',
      selectedUsername
    });
    const result = Object.values(data.result);
    dispatch({ type: DATA, payload: result[0] });
    Notice.success(i18next.t('taskDucks_successOnSave'));
  } catch (error) {
    handleTaskError(error, i18next.t('taskDucks_errorOnSave'));
  } finally {
    dispatch({ type: LOADING_ACTION, payload: null });
  }
};

export const clearState = () => ({ type: CLEAR_STATE });
