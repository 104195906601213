import React, {useEffect, useState} from 'react';
import Modal from '../../../_ui/Modal/Modal';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import Button from '../../../_ui/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { dismissStaff, colleaguesModule } from '../ColleaguesDucks';
import DoneIcon from '@material-ui/icons/Done';
import { Color } from '../../../components/ThemeProvider/ThemeStyle';
import {
  getFormValues,
} from '../../../passport/Passport';
import { useHistory } from "react-router-dom";
import { useForm } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import DatePicker from '../../../_ui/DatePicker/DatePicker';

export default function ModalDimissed(data) {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const loading = useSelector(state => state[colleaguesModule].loadingDismissed);

  const { register, handleSubmit, formState: { errors } } = useForm();
  const [period, setPeriod] = useState(null);
  const [isError, setIsError] = useState(true);
  const save = (info) => {
    if (!period) {
      setIsError(false)
    }
    if (period && isError) {
        info['dismissal_date'] = moment(period).format('YYYY-MM-DDTHH:mm:ss');
        getFormValues().then(values => {
        if (values) {
          dispatch(
            dismissStaff({id: data.id, close: data.close, history, info})
          )
        } else {
          data.close();
        }
     });
    }
  }
  useEffect(() => {
    if (period) {
      setIsError(true);
    }
  }, [period])

  return (
    <Modal
      title={t('colleaguesPassport_confirmationDismissal') }
      open
      onClose={data.close}
    >
      <DialogContent>
        <div className="flex justify-end pb2">
          <form onSubmit={handleSubmit(save)} style={{width: '100%'}}>
            <Box className='flex items-center'>
              <Typography children={t('contingentPassport_dropoutReason')} style={{width: '50%'}} />
              <Box 
                style={{
                  width: '100%',
                  maxWidth: '100%',
                }}
              >
                <TextField
                  labelId="demo-simple-select-label"
                  error={errors?.dismissal_reason && errors?.dismissal_reason.type === "required"}
                  name='dismissal_reason'
                  {...register('dismissal_reason', { required: true })}
                />
                {errors?.dismissal_reason && errors?.dismissal_reason.type === "required" && <FormHelperText error>{t('contingentPassport_requiredField')}</FormHelperText>}
              </Box>
            </Box>
            <Box className='flex items-center'>
              <Typography children={t('contingentPassport_dateDropout')} style={{width: '50%'}} />
              <Box 
                style={{
                  width: '100%',
                  maxWidth: '100%',
                }}
              >
                <DatePicker
                  lang={i18n.language}
                  withoutForm
                  value={period}
                  onChange={start => setPeriod(start)}
                  error={!isError}
                />
                {!isError && <FormHelperText error>{t('contingentPassport_requiredField')}</FormHelperText>}
              </Box>
            </Box>
            <Box className='flex justify-end mt2'>
              <Button
                color="primary"
                style={{ backgroundColor: Color.bgColorSuccess }}
                loading={loading}
                icon={<DoneIcon fontSize="small" className="mr1" />}
                text={ t('contingentPassport_yes') }
                type='submit'          
              />
            </Box>
          </form> 
        </div>
      </DialogContent>
    </Modal>
  );
}
