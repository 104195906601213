import React from 'react';
import TextField from '@material-ui/core/TextField';
import Row from '../../passport/Components/Row';
import withTranslation from '../../components/_hoc/withTranslation';

const StatementNegativeStatic = ({ info, t, lang }) => {
  const fields = [
    {
      label: t('statement_negativeReason'),
      name: 'reasonName',
      variant: `${lang}_name`,
      multiline: true,
      type: 'text'
    },
    {
      label: t('statement_negativeReasonTextKk'),
      name: 'reasonTextKk',
      multiline: true,
      type: 'text'
    },
    {
      label: t('statement_negativeReasonTextRu'),
      name: 'reasonTextRu',
      multiline: true,
      type: 'text'
    },
    {
      label: t('statement_negativeMissingDocumentsKkName'),
      name: 'missingDocumentsListKk',
      multiline: true,
      type: 'text'
    },
    {
      label: t('statement_negativeMissingDocumentsRuName'),
      name: 'missingDocumentsListRu',
      multiline: true,
      type: 'text'
    }
  ];

  return (
    <div className="my2 card p2">
      {fields
        .filter(({ name, variant }) =>
          variant ? info[name] && info[name][variant] : info[name]
        )
        .map(({ label, name, variant, type, multiline = false }, index) => {
          const key = `${name}${index}`;

          switch (type) {
            case 'text':
              return (
                <Row key={key} label={label}>
                  <TextField
                    disabled={true}
                    placeholder={label}
                    value={variant ? info[name][variant] : info[name]}
                    multiline={multiline}
                  />
                </Row>
              );

            default:
              return <div key={key}>Компонент {type} еще не разработан</div>;
          }
        })}
    </div>
  );
};

export default withTranslation(StatementNegativeStatic);
