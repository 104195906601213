import { createReducer } from 'redux-starter-kit';
import { cloneDeep } from 'lodash';
import i18next from 'i18next';
import _ from 'lodash';

import { AdministrationApi } from '../../_helpers/service';
import { handleError } from '../../utils/handleError';
import Notice from '../../utils/Notice';
import { ROLES } from '../../_helpers/Constants';
import { handleStatusError, needChangePass, setUser } from '../LoginPage/LoginDucks';
import { cloneObject } from '../../passport/Utils/cloneObject';

export const administrationModule = 'administration';
const LOADING_TABLE = `${administrationModule}/LOADING_TABLE`;
const TABLE_DATA = `${administrationModule}/TABLE_DATA`;
const CLEAR_STATE = `${administrationModule}/CLEAR_STATE`;

const LOADING_USER = `${administrationModule}/LOADING_USER`;
const USER_CHANGED = `${administrationModule}/USER_CHANGED`;

const ACTIVATE_USER = `${administrationModule}/ACTIVATE_USER`;
const DELETE_USER = `${administrationModule}/DELETE_USER`;

const EDIT_PROFILE = `${administrationModule}/EDIT_PROFILE`;

const CHANGE_PASSWORD = `${administrationModule}/CHANGE_PASSWORD`;

const LOADING_IMPORT = `${administrationModule}/LOADING_IMPORT`;

export const PROFILE_DATA = {
  ES_SERVICE_PROVIDER_ID: 'ES_SERVICE_PROVIDER_ID',
  ES_SERVICE_PROVIDER_RU_NAME: 'ES_SERVICE_PROVIDER_RU_NAME',
  ES_SERVICE_PROVIDER_KK_NAME: 'ES_SERVICE_PROVIDER_KK_NAME',
  ES_SERVICE_PROVIDER_BIN: 'ES_SERVICE_PROVIDER_BIN',
  POSITION_RU: 'POSITION_RU',
  POSITION_KK: 'POSITION_KK',
  PHONE_NUMBER: 'PHONE_NUMBER',
  ES_REGION_CODE: 'ES_REGION_CODE',
  ES_SERVICES_LIST: 'ES_SERVICES_LIST'
};

const initialState = {
  loadingTable: false,
  tableData: {
    content: [],
    totalElements: 0
  },
  loadingUser: false,
  editingProfile: false,
  changingPassword: false,
  loadingImport: false
};

export default createReducer(initialState, {
  [LOADING_TABLE]: (state, action) => {
    state.loadingTable = action.payload;
  },
  [TABLE_DATA]: (state, action) => {
    if (action.payload.pageNumber > 1) {
      state.tableData.content = [...state.tableData.content, ...action.payload.content];
    } else {
      state.tableData = action.payload;
    }
  },
  [CLEAR_STATE]: () => initialState,
  [LOADING_USER]: (state, action) => {
    state.loadingUser = action.payload;
  },
  [USER_CHANGED]: (state, action) => {
    const { index, payload } = action;
    state.tableData.content[index] = payload;
  },
  [ACTIVATE_USER]: (state, action) => {
    const { index, value } = action.payload;
    state.tableData.content[index] = {
      ...state.tableData.content[index],
      active: value
    };
  },
  [DELETE_USER]: (state, action) => {
    const { index } = action.payload;
    state.tableData.totalElements = state.tableData.totalElements - 1;
    state.tableData.content.splice(index, 1);
  },
  [EDIT_PROFILE]: (state, action) => {
    state.editingProfile = action.payload;
  },
  [CHANGE_PASSWORD]: (state, action) => {
    state.changingPassword = action.payload;
  },
  [LOADING_IMPORT]: (state, action) => {
    state.loadingImport = action.payload;
  }
});

export const clearState = () => ({ type: CLEAR_STATE });

export const loadUsers = filters => async dispatch => {
  dispatch({ type: LOADING_TABLE, payload: true });

  try {
    const page = {
      pageNumber: filters.pageNumber,
      pageSize: filters.pageSize
    };
    delete filters.pageNumber;
    delete filters.pageSize;

    const filter = [],
      attr = [];
    for (let key in filters) {
      if (filters.hasOwnProperty(key)) {
        if ([PROFILE_DATA.ES_SERVICE_PROVIDER_BIN].includes(key)) {
          attr.push({
            filterElement: key,
            filterValue: filters[key]
          });
        } else {
          filter.push({
            filterElement: key,
            filterValue: filters[key]
          });
        }
      }
    }

    let { data } = await AdministrationApi.loadUsers(
      JSON.stringify(filter),
      JSON.stringify(page),
      JSON.stringify(attr)
    );

    if (data.status === 'SUCCESS') {
      const content = [];

      for (let user of data.result.content) {
        const groupNames = user.groups.map(g => g.name);
        let roles = groupNames.filter(n => Object.values(ROLES).includes(n));

        const organization = user.profileData.find(
          p => p.name.toUpperCase() === PROFILE_DATA.ES_SERVICE_PROVIDER_ID
        );
        const organizationCode = organization ? organization.value : '';
        const ES_SERVICE_PROVIDER_RU_NAME = user.profileData.find(
          p => p.name.toUpperCase() === PROFILE_DATA.ES_SERVICE_PROVIDER_RU_NAME
        )
          ? user.profileData.find(
              p => p.name.toUpperCase() === PROFILE_DATA.ES_SERVICE_PROVIDER_RU_NAME
            ).value
          : '';
        const ES_SERVICE_PROVIDER_KK_NAME = user.profileData.find(
          p => p.name.toUpperCase() === PROFILE_DATA.ES_SERVICE_PROVIDER_KK_NAME
        )
          ? user.profileData.find(
              p => p.name.toUpperCase() === PROFILE_DATA.ES_SERVICE_PROVIDER_KK_NAME
            ).value
          : '';
        const ES_SERVICE_PROVIDER_BIN = user.profileData.find(
          p => p.name.toUpperCase() === PROFILE_DATA.ES_SERVICE_PROVIDER_BIN
        )
          ? user.profileData.find(
              p => p.name.toUpperCase() === PROFILE_DATA.ES_SERVICE_PROVIDER_BIN
            ).value
          : '';

        const position_ru = user.profileData.find(
          p => p.name.toUpperCase() === PROFILE_DATA.POSITION_RU
        )
          ? user.profileData.find(p => p.name.toUpperCase() === PROFILE_DATA.POSITION_RU)
              .value
          : '';
        const position_kk = user.profileData.find(
          p => p.name.toUpperCase() === PROFILE_DATA.POSITION_KK
        )
          ? user.profileData.find(p => p.name.toUpperCase() === PROFILE_DATA.POSITION_KK)
              .value
          : '';

        const phone_number = user.profileData.find(
          p => p.name.toUpperCase() === PROFILE_DATA.PHONE_NUMBER
        )
          ? user.profileData.find(p => p.name.toUpperCase() === PROFILE_DATA.PHONE_NUMBER)
              .value
          : '';

        const region_code = user.profileData.find(
          p => p.name.toUpperCase() === PROFILE_DATA.ES_REGION_CODE
        )
          ? user.profileData.find(
              p => p.name.toUpperCase() === PROFILE_DATA.ES_REGION_CODE
            ).value
          : '';

        const services_list = user.profileData.find(
          p => p.name.toUpperCase() === PROFILE_DATA.ES_SERVICES_LIST
        )
          ? user.profileData
              .find(p => p.name.toUpperCase() === PROFILE_DATA.ES_SERVICES_LIST)
              .value.split(',')
          : [];

        content.push({
          ...user,
          currentRoles: roles,
          roles: roles,
          currentOO: organizationCode
            ? {
                value: organizationCode,
                ru_name: ES_SERVICE_PROVIDER_RU_NAME,
                kk_name: ES_SERVICE_PROVIDER_KK_NAME,
                bin: ES_SERVICE_PROVIDER_BIN
              }
            : {},
          organization: organizationCode
            ? {
                value: organizationCode,
                ru_name: ES_SERVICE_PROVIDER_RU_NAME,
                kk_name: ES_SERVICE_PROVIDER_KK_NAME,
                bin: ES_SERVICE_PROVIDER_BIN
              }
            : {},
          currentPosition: {
            position_ru,
            position_kk
          },
          position_ru,
          position_kk,
          current_phone_number: phone_number,
          phone_number,
          current_region_code: region_code,
          region_code,
          current_services_list: services_list,
          services_list
        });
      }

      dispatch({ type: TABLE_DATA, payload: { ...data.result, content }, filters });
    } else {
      handleError(data, i18next.t('administrationDucks_errorLoadTable'));
    }
  } catch (error) {
    handleError(error, i18next.t('administrationDucks_errorLoadTable'));
  } finally {
    dispatch({ type: LOADING_TABLE, payload: false });
  }
};

export const changeUser = (formValues, closeModal) => async dispatch => {
  dispatch({ type: LOADING_USER, payload: true });

  try {
    const formData = await formDataWithGroups(formValues);
    let profileData = cloneDeep(formData.profileData);
    profileData = profileData.filter(
      p => !Object.values(PROFILE_DATA).includes(p.name.toUpperCase())
    );

    profileData.push({ name: PROFILE_DATA.POSITION_RU, value: formData.position_ru });
    profileData.push({ name: PROFILE_DATA.POSITION_KK, value: formData.position_kk });
    profileData.push({ name: PROFILE_DATA.PHONE_NUMBER, value: formData.phone_number });
    if (formData.roles.includes(ROLES.ES_REGION_EMPLOYEES)) {
      profileData.push({
        name: PROFILE_DATA.ES_REGION_CODE,
        value: formData.region_code
      });
    }
    if (
      formData.roles.includes(ROLES.ES_MINISTRY_EMPLOYEES) ||
      formData.roles.includes(ROLES.ES_REGION_EMPLOYEES)
    ) {
      profileData.push({
        name: PROFILE_DATA.ES_SERVICES_LIST,
        value: formData.services_list.join(',')
      });
    }
    if (
      !formData.roles.includes(ROLES.ES_MINISTRY_EMPLOYEES) &&
      !formData.roles.includes(ROLES.ES_REGION_EMPLOYEES)
    ) {
      profileData.push({
        name: PROFILE_DATA.ES_SERVICE_PROVIDER_ID,
        value: formData.organization.value
      });
      profileData.push({
        name: PROFILE_DATA.ES_SERVICE_PROVIDER_RU_NAME,
        value: formData.organization.ru_name
      });
      profileData.push({
        name: PROFILE_DATA.ES_SERVICE_PROVIDER_KK_NAME,
        value: formData.organization.kk_name
      });
      profileData.push({
        name: PROFILE_DATA.ES_SERVICE_PROVIDER_BIN,
        value: formData.organization.bin
      });
    }

    profileData = profileData.filter(p => p.value);

    const newData = {
      ...formData,
      profileData,
      password: formData.password || undefined,
      currentOO: undefined,
      currentRoles: undefined,
      roles: undefined,
      organization: undefined,
      index: undefined,
      position_ru: undefined,
      position_kk: undefined,
      phone_number: undefined,
      currentPosition: undefined,
      current_phone_number: undefined,
      current_region_code: undefined,
      region_code: undefined,
      current_services_list: undefined,
      services_list: undefined
    };

    const { data } = await AdministrationApi.changeUser(newData);

    if (data.status === 'SUCCESS') {
      const roles = _.flatten([formData.roles]);
      dispatch({
        type: USER_CHANGED,
        payload: {
          ...newData,
          currentOO: formData.organization,
          organization: formData.organization,
          roles: roles,
          currentRoles: roles,
          currentPosition: {
            position_ru: formData.position_ru,
            position_kk: formData.position_kk
          },
          position_ru: formData.position_ru,
          position_kk: formData.position_kk,
          current_phone_number: formData.phone_number,
          phone_number: formData.phone_number,
          current_region_code: formData.region_code,
          region_code: formData.region_code,
          current_services_list: formData.services_list,
          services_list: formData.services_list
        },
        index: formData.index
      });
      closeModal();
      Notice.success(i18next.t('administrationDucks_userChanged'));
    } else {
      handleError(data, i18next.t('administrationDucks_errorChangeUser'));
    }
  } catch (error) {
    if (error.response && error.response.data) {
      if (error.response.data.status === 'INCORRECT_USERNAME_PASSWORD') {
        Notice.error(i18next.t('loginDucks_userNotFound'));
      } else if (error.response.data.status === 'SIGNATURE_VERIFICATION_FAIL') {
        Notice.error(i18next.t('loginDucks_signatureVerificationFail'));
      } else if (error.response.data.status === 'PASSWORD_ATTEMPTS_EXCEEDED') {
        Notice.error(i18next.t('loginDucks_passwordAttemptsExceeded'));
      } else if (error.response.data.status === 'PASSWORD_IS_EXPIRED') {
        Notice.error(i18next.t('loginDucks_passwordIsExpired'));
      } else if (error.response.data.status === 'NEW_PASSWORD_IS_EQUALS_TO_PREVIOUS') {
        Notice.error(i18next.t('loginDucks_newPasswordIsEqualsToPrevious'));
      } else if (error.response.data.status === 'USER_NOT_ACTIVE') {
        Notice.error(i18next.t('loginDucks_userNotActive'));
      } else {
        handleError(error, i18next.t('administrationDucks_errorChangeUser'));
      }
    } else {
      handleError(error, i18next.t('administrationDucks_errorChangeUser'));
    }
  } finally {
    dispatch({ type: LOADING_USER, payload: false });
  }
};

export const createUser = (formValues, closeModal, isAdmin, reloadPage) => async (
  dispatch,
  getState
) => {
  dispatch({ type: LOADING_USER, payload: true });
  const formData = await formDataWithGroups(formValues);
  let profileData;
  if (!isAdmin) {
    const userProfile = getState().login.user.profileData;
    const ru_name = userProfile.find(
      p => p.name.toUpperCase() === PROFILE_DATA.ES_SERVICE_PROVIDER_RU_NAME
    );
    const kk_name = userProfile.find(
      p => p.name.toUpperCase() === PROFILE_DATA.ES_SERVICE_PROVIDER_KK_NAME
    );
    const bin = userProfile.find(
      p => p.name.toUpperCase() === PROFILE_DATA.ES_SERVICE_PROVIDER_BIN
    );
    const ES_SERVICE_PROVIDER_RU_NAME = ru_name ? ru_name.value : '';
    const ES_SERVICE_PROVIDER_KK_NAME = kk_name ? kk_name.value : '';
    const ES_SERVICE_PROVIDER_BIN = bin ? bin.value : '';
    profileData = [
      {
        name: PROFILE_DATA.ES_SERVICE_PROVIDER_RU_NAME,
        value: ES_SERVICE_PROVIDER_RU_NAME
      },
      {
        name: PROFILE_DATA.ES_SERVICE_PROVIDER_KK_NAME,
        value: ES_SERVICE_PROVIDER_KK_NAME
      },
      { name: PROFILE_DATA.ES_SERVICE_PROVIDER_BIN, value: ES_SERVICE_PROVIDER_BIN }
    ];
  } else {
    profileData = [
      {
        name: PROFILE_DATA.ES_SERVICE_PROVIDER_RU_NAME,
        value: formData.organization && formData.organization.ru_name
      },
      {
        name: PROFILE_DATA.ES_SERVICE_PROVIDER_KK_NAME,
        value: formData.organization && formData.organization.kk_name
      },
      {
        name: PROFILE_DATA.ES_SERVICE_PROVIDER_ID,
        value: formData.organization && formData.organization.value
      },
      {
        name: PROFILE_DATA.ES_SERVICE_PROVIDER_BIN,
        value: formData.organization && formData.organization.bin
      },
      { name: PROFILE_DATA.ES_REGION_CODE, value: formData.region_code }
    ];
  }

  profileData = profileData.filter(p => p.value);

  if (formData.phone_number) {
    profileData.push({ name: PROFILE_DATA.PHONE_NUMBER, value: formData.phone_number });
  }

  if (formData.services_list.length) {
    profileData.push({
      name: PROFILE_DATA.ES_SERVICES_LIST,
      value: formData.services_list.join(',')
    });
  }

  try {
    const newData = {
      ...formData,
      create: undefined,
      roles: undefined,
      position_ru: undefined,
      position_kk: undefined,
      organization: undefined,
      phone_number: undefined,
      region_code: undefined,
      services_list: undefined,
      lstModInfo: {
        lstModUser: 'nedbportal'
      },
      profileData: [
        { name: PROFILE_DATA.POSITION_RU, value: formData.position_ru },
        { name: PROFILE_DATA.POSITION_KK, value: formData.position_kk },
        ...profileData
      ],
      groups: formData.groups,
      appName: 'ES_APP',
      active: true
    };

    const { data } = await AdministrationApi.createUser(newData);
    if (data.status === 'SUCCESS') {
      Notice.success(i18next.t('administrationDucks_userCreated'));
      closeModal();
      reloadPage();
    } else {
      handleError(data, i18next.t('administrationDucks_errorCreateUser'));
    }
  } catch (error) {
    handleError(error, i18next.t('administrationDucks_errorCreateUser'));
  } finally {
    dispatch({ type: LOADING_USER, loadingUser: false });
  }
};

export const activateUser = (username, value, index) => async dispatch => {
  dispatch({ type: LOADING_TABLE, payload: true });

  try {
    const requestBody = {
      active: value,
      lstModInfo: {
        lstModUser: 'nedbportal'
      }
    };

    const { data } = await AdministrationApi.activateUser(username, requestBody);

    if (data.status === 'SUCCESS') {
      dispatch({ type: ACTIVATE_USER, payload: { value, index } });
    } else {
      handleError(data, i18next.t('administrationDucks_errorActivateUser'));
    }
  } catch (error) {
    handleError(error, i18next.t('administrationDucks_errorActivateUser'));
  } finally {
    dispatch({ type: LOADING_TABLE, payload: false });
  }
};

export const deleteUser = (userData, index) => async dispatch => {
  dispatch({ type: LOADING_TABLE, payload: true });

  try {
    const requestBody = {
      ...userData,
      currentOO: undefined,
      currentRoles: undefined,
      roles: undefined,
      organization: undefined,
      index: undefined,
      position_ru: undefined,
      position_kk: undefined,
      phone_number: undefined,
      currentPosition: undefined,
      current_phone_number: undefined,
      current_region_code: undefined,
      region_code: undefined,
      current_services_list: undefined,
      services_list: undefined
    };

    requestBody.profileData = requestBody.profileData.filter(
      p => !Object.values(PROFILE_DATA).includes(p.name)
    );

    requestBody.groups = requestBody.groups.filter(
      g => !Object.values(ROLES).includes(g.name)
    );

    const { data } = await AdministrationApi.changeUser(requestBody);

    if (data.status === 'SUCCESS') {
      dispatch({ type: DELETE_USER, payload: { index } });
    } else {
      handleError(data, i18next.t('administrationDucks_errorActivateUser'));
    }
  } catch (error) {
    handleError(error, i18next.t('administrationDucks_errorDeleteUser'));
  } finally {
    dispatch({ type: LOADING_TABLE, payload: false });
  }
};

export const editProfile = formValues => async dispatch => {
  dispatch({ type: EDIT_PROFILE, payload: true });

  try {
    const coypValue = {...formValues}
    if (coypValue.creationDt) delete coypValue.creationDt;
    if (coypValue.lastFailedAttemp) delete coypValue.lastFailedAttemp;
    if (coypValue.lastLoginDt) delete coypValue.lastLoginDt;
    if (coypValue.lastPasswordChange) delete coypValue.lastPasswordChange;
    const data = await formDataWithGroups(coypValue);
    let profileData = cloneDeep(data.profileData);
    profileData = profileData.filter(
      p => !Object.values(PROFILE_DATA).includes(p.name.toUpperCase())
    );

    profileData.push({ name: PROFILE_DATA.POSITION_RU, value: data.position_ru });
    profileData.push({ name: PROFILE_DATA.POSITION_KK, value: data.position_kk });
    profileData.push({ name: PROFILE_DATA.PHONE_NUMBER, value: data.phone_number });
    if (data.roles.includes(ROLES.ES_REGION_EMPLOYEES)) {
      profileData.push({ name: PROFILE_DATA.ES_REGION_CODE, value: data.region_code });
    }
    if (
      data.roles.includes(ROLES.ES_MINISTRY_EMPLOYEES) ||
      data.roles.includes(ROLES.ES_REGION_EMPLOYEES)
    ) {
      profileData.push({
        name: PROFILE_DATA.ES_SERVICES_LIST,
        value: data.services_list.join(',')
      });
    }
    if (
      !data.roles.includes(ROLES.ES_MINISTRY_EMPLOYEES) &&
      !data.roles.includes(ROLES.ES_REGION_EMPLOYEES)
    ) {
      profileData.push({
        name: PROFILE_DATA.ES_SERVICE_PROVIDER_ID,
        value: data.organization_id
      });
      profileData.push({
        name: PROFILE_DATA.ES_SERVICE_PROVIDER_RU_NAME,
        value: data.organization.ru_name
      });
      profileData.push({
        name: PROFILE_DATA.ES_SERVICE_PROVIDER_KK_NAME,
        value: data.organization.kk_name
      });
      profileData.push({
        name: PROFILE_DATA.ES_SERVICE_PROVIDER_BIN,
        value: data.organization.bin
      });
    }

    profileData = profileData.filter(p => p.value);
    const newData = {
      ...data,
      profileData,
      roles: undefined,
      organization_id: undefined,
      organization: undefined,
      position_ru: undefined,
      position_kk: undefined,
      phone_number: undefined,
      region_code: undefined,
      services_list: undefined
    };
    const response = await AdministrationApi.changeUser(newData);

    if (response.data.status === 'SUCCESS') {
      dispatch(setUser(response.data.result));
      Notice.success(i18next.t('administrationDucks_userChanged'));
    } else {
      handleError(response, i18next.t('administrationDucks_errorChangeUser'));
    }
  } catch (error) {
    handleError(error, i18next.t('administrationDucks_errorChangeUser'));
  } finally {
    dispatch({ type: EDIT_PROFILE, payload: false });
  }
};

export const changePassword = passwords => async (dispatch, getState) => {
  dispatch({ type: CHANGE_PASSWORD, payload: true });

  try {
    const username = getState().login.user.username;

    const { data } = await AdministrationApi.changePassword(username, {
      ...passwords,
      lstModInfo: {
        lstModUser: 'nedbportal'
      }
    });

    if (data.status === 'SUCCESS') {
      dispatch(needChangePass(false));
      Notice.success(i18next.t('administrationDucks_userChanged'));
    } else {
      handleError(data, i18next.t('administrationDucks_errorChangeUser'));
    }
  } catch (error) {
    const errorCode =
      _.get(error, 'response.data.errors[0].errorCode') ||
      _.get(error, 'response.errors[0].errorCode');
    if (errorCode === 'entitlement_password_is_not_correct') {
      Notice.error(i18next.t('administrationDucks_errorChangePassword'));
    } else {
      handleStatusError(error, i18next.t('administrationDucks_errorChangeUser'));
    }
  } finally {
    dispatch({ type: CHANGE_PASSWORD, payload: false });
  }
};

export const importUsers = (file, columns) => async dispatch => {
  dispatch({ type: LOADING_IMPORT, payload: true });

  try {
    const { data } = await AdministrationApi.importUsers(file, columns);
    const failed = data.result
      .filter(item => !item['importSuccessful'])
      .map(item => `${item['objectKey']} - ${item['responseMessage']}`);

    if (failed.length) {
      Notice.error(`
        ${i18next.t('administrationDucks_notImported')}:
        ${failed.join('\n')}
      `);
    } else {
      Notice.success(i18next.t('administrationDucks_imported'));
    }
  } catch (error) {
    handleError(error, i18next.t('administrationDucks_errorImport'));
  } finally {
    dispatch({ type: LOADING_IMPORT, payload: false });
  }
};

async function formDataWithGroups(values) {
  const formData = cloneObject(values);
  if (formData.groups instanceof Array) {
    const groups = formData.groups
      .filter(i => !Object.values(ROLES).includes(i.name))
      .map(i => ({ name: i.name }));
    formData.roles.forEach(i => groups.push({ name: i }));
    formData.groups = groups;
  } else {
    if (typeof formData?.roles !== 'undefined') {
      formData.groups = formData?.roles.map(item => ({ name: item }));
    }
  }
  return formData;
}
