import React, { useState } from 'react';

import { StyledPassField } from './PasswordFieldStyle';
import { IconButton } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import Input from '../../_ui/Input/Input';

function PasswordField(
  {
    value,
    name,
    onChange,
    placeholder,
    withoutForm = false
  }
) {
  const [showPass, setShowPass] = useState(false);
  const secured = (showPass || !value) ? '' : 'secured';

  return (
    <StyledPassField>
      {
        localStorage.getItem('savePasswordForm') !== 'true' ? (
          <Input
            name={name}
            className={secured}
            placeholder={placeholder}
            onChange={onChange}
            InputProps={{
              endAdornment: (
                <IconButton
                  style={{ padding: 2 }}
                  onClick={() => setShowPass(!showPass)}
                  children={showPass ? <VisibilityOff/> : <Visibility/>}
                />
              )
            }}
          />
        ) : (
          <Input
            withoutForm={withoutForm}
            name={name}
            placeholder={placeholder}
            type={showPass ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <IconButton
                  style={{ padding: 2 }}
                  onClick={() => setShowPass(!showPass)}
                  children={showPass ? <VisibilityOff/> : <Visibility/>}
                />
              )
            }}
          />
        )
      }
    </StyledPassField>
  );
}

export default PasswordField;
