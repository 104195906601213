import { addTranslation } from '../../_helpers/Translate';

addTranslation({
  ru: {
    contingentPassport_errorLoad: 'Не удалось загрузить данные услугодателя',
    contingentPassport_errorLoadMeta: 'Не удалось загрузить метаданные',
    contingentPassport_createForm: 'Создать',
    contingentPassport_saveForm: 'Сохранить',
    contingentPassport_saveFormSuccess: 'Данные успешно сохранены',
    contingentPassport_errorSaveForm: 'Не удалось сохранить данные',
    contingentPassport_id: 'Номер услугодателя',
    contingentPassport_iin: 'ИИН',
    contingentPassport_bin: 'БИН',
    contingentPassport_parentChoose: 'Выбрать',
    contingentPassport_infoApplicationNumber: 'Номер заявки',
    contingentPassport_infoDirectionStatus: 'Статус направления',
    contingentPassport_infoApplicantIIN: 'ИИН заявителя',
    contingentPassport_infoDirectionNumber: 'Номер направления',
    contingentPassport_invalidPassport:
      'Уважаемый пользователь! Чтобы продолжить работу, необходимо заполнить обязательные поля! После заполнения полей, нужно нажать на кнопку "Сохранить"',
    contingentPassport_iinNotFound: 'По указанному ИИН ребенка в системе не были найдены данные по выданному направлению в дошкольную организацию. Вам необходимо заполнить все данные.',
    contingentPassport_requiredField: 'Обязательное для заполнения',

    contingentPassport_yes: 'Подтверждаю',
    contingentPassport_dataRequest: 'Запрос данных',

    contingentPassport_approve: 'Подтверждение о прибытии',
    contingentPassport_approveError: 'Ошибка при подтверждении о прибытии',
    contingentPassport_approveSuccess: 'Прибытие по направлению успешно подтверждена',
    contingentPassport_approveQuestion: 'Подтверждена льгота ВО/ПО ?',
    contingentPassport_approveYesPrev: 'Да, подтверждено',
    contingentPassport_approveNo: 'Нет',

    contingentPassport_retirement: 'Выбытие',
    contingentPassport_retirementConfirmationBtn: 'Подтверждение о выбытие',
    contingentPassport_retirementSuccess: 'Подтверждена выбытие',
    contingentPassport_retirementError: 'Ошибка при выбытие направления',

    contingentPassport_enroll: 'Зачислить',
    contingentPassport_enrollConfirmationBtn: 'Подтверждение о зачислении',
    contingentPassport_enrollError: 'Ошибка при подтверждении о зачислении',
    contingentPassport_enrollSuccess: 'Успешно подтверждена',

    contingentPassport_revoke: 'Отозвать направление',
    contingentPassport_revokeSuccess: 'Направление успешно отозвано',
    contingentPassport_revokeError: 'Ошибка при отзыве направления',
    
    contingentPassport_createRevokeTitle: 'Отзыв направления',
    contingentPassport_createRevokeText: 'При отзыве, данное направление будет аннулировано и ребенок будет восстановлен в очередь',
    contingentPassport_kinderGarden: 'Заявление',
    contingentPassport_kinderGardenView: 'Посмотреть заявку',
    contingentPassport_reasonDeletion: 'Причина отзыва свободных мест',
    contingentPassport_reasonDeletionTextRu: 'Причина на русском',
    contingentPassport_reasonDeletionTextKk: 'Причина на казахском',
    contingentPassport_groupName: 'Наименование группы',
    contingentPassport_noGroup: 'Нет группа',
    contingentPassport_save: 'Cохранить',
    contingentPassport_dropoutReason: 'Причина выбытия:',
    contingentPassport_dateDropout: 'Дата выбытия:',
    contingentPassport_errorEnrolledContingent: 'Контингент с ИИН {{iin}} уже является заявителем',
    contingentPassport_errorApplicantContingent: 'Контингент с ИИН {{iin}} уже зачислен дошкольную организацию.',
    contingentPassport_errorLoadGroup: 'Не удалось загрузить группу',
    contingentPassport_noPhoto: 'Нет фото',
    contingentPassport_errorCreatingQueuedContingent: 'По указанному ИИН ребенок стоит в очереди. Чтобы зачислить ребенка необходимо Заявителю отозвать заявление из очереди',
    contingentPassport_errorCreatingApplicantContingent: 'По указанному ИИН ребенка направление получено. Чтобы зачислить ребенка необходимо перейти во вкладку "Претенденты"',
  },
  kk: {
    breadcrumbs_contingentPassport: 'Паспорт',
    contingentPassport_errorLoad: 'Қызмет көрсетушінің деректерін жүктеу мүмкін емес',
    contingentPassport_createForm: 'Создать',
    contingentPassport_saveForm: 'Сақтау',
    contingentPassport_saveFormSuccess: 'Деректер сәтті сақталды',
    contingentPassport_errorSaveForm: 'Деректерді сақтау сәтсіз аяқталды',
    contingentPassport_id: 'Қызметті көрсетушінің нөмірі',
    contingentPassport_iin: 'ЖСН',
    contingentPassport_bin: 'БСН',
    contingentPassport_parentChoose: 'Таңдау',
    contingentPassport_invalidPassport:
      'Құрметті пайдаланушы! Жұмысты жалғастыру үшін міндетті өрістерді толтыру қажет! Өрістерді толтырғаннан кейін "Сақтау" батырмасын басу керек.',
    contingentPassport_requiredField: 'Толтыру үшін міндетті',
    contingentPassport_infoApplicationNumber: 'Жолдама нөмірі',
    contingentPassport_infoDirectionStatus: 'Жолдама мәртебесі',
    contingentPassport_infoApplicantIIN: 'Өтініш берушінің ЖСН-і',

    contingentPassport_yes: 'Растаймын',
    contingentPassport_dataRequest: 'Деректер сұрау',

    contingentPassport_approve: 'Келуді растау',
    contingentPassport_approveError: 'Келуді растау сәтсіз аяқталды',
    contingentPassport_approveSuccess: 'Жолдама бойынша келуді растау сәтті аяқталды',

    contingentPassport_retirement: 'Шығу',
    contingentPassport_retirementConfirmationBtn: 'Шығуды растау',
    contingentPassport_retirementSuccess: 'Шығу расталды',
    contingentPassport_retirementError: 'Шығуды растау кезіндегі қате',

    contingentPassport_enroll: 'Тіркеу',
    contingentPassport_enrollConfirmationBtn: 'Тіркеуді растау',
    contingentPassport_enrollError: 'Тіркеуді растау кезіндегі қате',
    contingentPassport_enrollSuccess: 'Сәтті аяқталды',

    contingentPassport_revoke: 'Жолдаманы қайтарып алу',
    contingentPassport_revokeSuccess: 'Жолдама сәтті қайтарылды',
    contingentPassport_revokeError: 'Жолдаманы қайтару кезіндегі қате',
    
    contingentPassport_createRevokeTitle: 'Жолдаманы қайтару',
    contingentPassport_createRevokeText: 'Кері қайтарып алу кезінде бұл жолдама жойылады және бала кезекке қалпына келтіріледі',
    contingentPassport_kinderGarden: 'Өтінім',
    contingentPassport_kinderGardenView: 'Өтінімді қарау',
    contingentPassport_reasonDeletion: 'Бос орындарды қайтарып алу себебі',
    contingentPassport_reasonDeletionTextRu: 'Себебі орыс тілінде',
    contingentPassport_reasonDeletionTextKk: 'Себебі қазақ тілінде',
    contingentPassport_groupName: 'Топ аты',
    contingentPassport_approveQuestion: 'Кезектен тыс/бірінші кезекті орын алу құқығы бойынша жеңілдігі расталды ма?',
    contingentPassport_approveYesPrev: 'Иә, расталды',
    contingentPassport_approveNo: 'Жоқ',
    contingentPassport_noGroup: 'Топтар жоқ',
    contingentPassport_save: 'Cақтау',
    contingentPassport_dropoutReason: 'Шығу себебі:',
    contingentPassport_dateDropout: 'Шығу күні:',
    contingentPassport_errorEnrolledContingent: 'ЖСН {{iin}} контингенті қазір өтініш беруші болып табылады.',
    contingentPassport_errorApplicantContingent: 'ЖСН {{iin}} контингенті мектепке дейінгі ұйымға тіркелген.',
    contingentPassport_iinNotFound: 'Баланың көрсетілген ЖСН бойынша жүйе мектепке дейінгі ұйымға берілген жолдама туралы мәліметтерді таппады. Барлық деректерді толтыруыңыз керек.',
    contingentPassport_infoDirectionNumber: 'Жолдама нөмірі',
    contingentPassport_errorLoadGroup: 'Топты жүктеу мүмкін болмады',
    contingentPassport_noPhoto: 'Фото жоқ',
    contingentPassport_errorCreatingQueuedContingent: 'Көрсетілген ЖСН бойынша бала кезекте тұр. Баланы тіркеу үшін Өтініш беруші кезектен өтінішті қайтарып алуы керек',
    contingentPassport_errorCreatingApplicantContingent: 'Баланың ЖСН бойынша жолдама алынды. Баланы тіркеу үшін «Үміткерлер» қойындысына өту керек',
  }
});
