import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DialogContent, DialogActions } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import { Formik, Form as FormikForm } from 'formik';

import Modal from '../../../_ui/Modal/Modal';
import Input from '../../../_ui/Input/Input';
import Button from '../../../_ui/Button/Button';
import { SignUpSchema } from './UserValidation';
import Row from '../../../_ui/Row';
import Select from '../../../_ui/Select/Select';
import { administrationModule, changeUser, createUser } from '../AdministrationDucks';
import { ROLES, ROLES_NAMES, ROLES_CREATE_USER } from '../../../_helpers/Constants';
import ServiceProvidersModal from './ServiceProvidersModal';
import useSimpleModal from '../../../components/_hooks/useSimpleModal';
import useHasAccess from '../../../components/useHasAccess';
import permissions from '../../../_helpers/permissions';
import TreeSelect from '../../../_ui/TreeSelect/TreeSelect';
import useTree from '../../../components/TableFilter/ColumnsFilters/FilterTreeSelect/useTree';
import './UserTranslate';
import { IconButton } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import FormHelperText from '@material-ui/core/FormHelperText';

const UserModal = ({
  close: modalOnClose,
  data,
  loading,
  changeUser,
  createUser,
  reloadPage
}) => {
  const [showPass, setShowPass] = useState(false);
  const { t, i18n } = useTranslation();
  const [formData, setFormData] = useState(data);
  const SPModal = useSimpleModal();
  const lookupId = 60;
  const { tree, onExpand, loadingArr } = useTree({
    lookupId: lookupId
  });
  const isAdmin = useHasAccess(permissions.admin);
  const [isRoles, setIsRoles] = useState(false)
  const roles = Object.keys(ROLES_CREATE_USER).map(r => ({
    code: r,
    ru_name: ROLES_NAMES[r].ru_name,
    kk_name: ROLES_NAMES[r].kk_name,
    disabled: !isAdmin && r !== ROLES.ES_SERVICE_PROVIDER_EMPLOYEES
  }));
  useEffect(() => {
    if (formData.create) {
      formData.services_list = [];
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Modal
      open
      title={<b>{t(`userModal_${formData.create ? 'create' : 'update'}`)}</b>}
      onClose={modalOnClose}
      scroll="body"
    >
      <Formik
        initialValues={formData}
        enableReinitialize
        validate={values => {
          const errors = {};
          if (values.roles.includes(ROLES.ES_REGION_EMPLOYEES) && !values['region_code']) {
            errors.region_code = t('userModal_validationRequired');
          }
          if (
            (values.roles.includes(ROLES.ES_MINISTRY_EMPLOYEES) ||
              values.roles.includes(ROLES.ES_REGION_EMPLOYEES)) &&
            !values['services_list'].length
          ) {
            errors.services_list = t('userModal_validationRequired');
          }
          if (
            isAdmin &&
            !values.roles.includes(ROLES.ES_MINISTRY_EMPLOYEES) &&
            !values.roles.includes(ROLES.ES_REGION_EMPLOYEES) &&
            (!values.organization || !values.organization.value)
          ) {
            errors.organization = t('userModal_validationRequired');
          }
          return errors;
        }}
        onSubmit={values => {
          if (values.create) {
            if (values.roles) {
              createUser(values, modalOnClose, isAdmin, reloadPage);
            } else {
              setIsRoles(true)
            }
          } else {
            changeUser(values, modalOnClose);
          }
        }}
        validationSchema={SignUpSchema(formData.create, isAdmin)}
      >
        {({ values }) => (
          <FormikForm>
            <DialogContent>
              <Row label={t('userModal_iin')}>
                <Input
                  name="iin"
                  placeholder={t('userModal_iin')}
                  className="fullwidth"
                />
              </Row>
              <Row label={t('userModal_lastName')}>
                <Input
                  name="lastName"
                  placeholder={t('userModal_lastName')}
                  className="fullwidth"
                />
              </Row>
              <Row label={t('userModal_firstName')}>
                <Input
                  name="firstName"
                  placeholder={t('userModal_firstName')}
                  className="fullwidth"
                />
              </Row>
              <Row label={t('userModal_middleName')}>
                <Input
                  name="middleName"
                  placeholder={t('userModal_middleName')}
                  className="fullwidth"
                />
              </Row>
              <Row label={t('userModal_username')}>
                <Input
                  name="username"
                  placeholder={t('userModal_username')}
                  className="fullwidth"
                  disabled={!formData.create}
                />
              </Row>
              <Row label={t('userModal_password')}>
                <Input
                  name="password"
                  placeholder={t('userModal_password')}
                  className="fullwidth"
                  type={showPass ? 'text' : 'password'}
                  autoComplete="new-password"
                  InputProps={{
                    form: {
                      autocomplete: 'off',
                    },
                    endAdornment: (
                      <IconButton
                        style={{ padding: 2 }}
                        onClick={() => setShowPass(!showPass)}
                        children={showPass ? <VisibilityOff/> : <Visibility/>}
                      />
                    )
                  }}
                />
              </Row>
              <Row label={t('userModal_email')}>
                <Input
                  name="emailAddress"
                  placeholder={t('userModal_email')}
                  className="fullwidth"
                />
              </Row>
              <Row label={t('userModal_role')}>
                <Select
                  name="roles"
                  placeholder={t('userModal_role')}
                  className="fullwidth"
                  options={roles}
                  multiple
                  error={!values.roles && isRoles}
                />
                {(!values.roles && isRoles) && <FormHelperText error>{t('userModal_validationRequired')}</FormHelperText>}
              </Row>
              {values.roles && values.roles.includes(ROLES.ES_REGION_EMPLOYEES) && (
                <Row label={t('userModal_region')}>
                  <TreeSelect
                    name="region_code"
                    fullWidth
                    lookupId={lookupId}
                    loadingArr={loadingArr}
                    onExpand={onExpand}
                    data={tree}
                  />
                </Row>
              )}
              {values.roles &&
                (values.roles.includes(ROLES.ES_MINISTRY_EMPLOYEES) ||
                  values.roles.includes(ROLES.ES_REGION_EMPLOYEES)) && (
                  <Row label={t('userModal_services')}>
                    <Select
                      multiple
                      name="services_list"
                      fullWidth
                      lookupId="gu_services"
                      placeholder={t('userModal_services')}
                    />
                  </Row>
                )}
              {values.roles &&
                !values.roles.includes(ROLES.ES_MINISTRY_EMPLOYEES) &&
                !values.roles.includes(ROLES.ES_REGION_EMPLOYEES) && (
                  <Row label={t('userModal_organization')}>
                    <Button
                      disabled={!isAdmin}
                      className="fullwidth"
                      variant="outlined"
                      onClick={() => SPModal.open(values)}
                      text={
                        formData.organization &&
                        formData.organization[`${i18n.language}_name`]
                          ? formData.organization[`${i18n.language}_name`]
                          : t('userModal_organization')
                      }
                    />
                    <Input
                      name="organization"
                      type="hidden"
                      style={{ display: 'none', margin: 0 }}
                    />
                    {!formData.organization?.value && <FormHelperText error>{t('userModal_validationRequired')}</FormHelperText>}
                  </Row>
                )}
              <Row label={t('userModal_ruPosition')}>
                <Input
                  name="position_ru"
                  placeholder={t('userModal_ruPosition')}
                  className="fullwidth"
                />
              </Row>
              <Row label={t('userModal_kkPosition')}>
                <Input
                  name="position_kk"
                  placeholder={t('userModal_kkPosition')}
                  className="fullwidth"
                />
              </Row>
              <Row label={t('userModal_phoneNumber')}>
                <Input
                  name="phone_number"
                  placeholder={t('userModal_phoneNumber')}
                  className="fullwidth"
                  type="number"
                />
              </Row>
            </DialogContent>
            <DialogActions>
              <Button
                icon={<SaveIcon fontSize="small" className="mr1" />}
                type="submit"
                text={t('userModal_save')}
                loading={loading}
                disabled={loading}
              />
              <Button
                icon={<CancelIcon fontSize="small" className="mr1" color="action" />}
                onClick={modalOnClose}
                text={t('userModal_cancel')}
                type="button"
                color="default"
              />
            </DialogActions>
          </FormikForm>
        )}
      </Formik>
      {SPModal.isOpen && (
        <ServiceProvidersModal modal={SPModal} setFormData={setFormData} />
      )}
    </Modal>
  );
};

export default connect(
  state => ({
    loading: state[administrationModule].loadingUser
  }),
  {
    changeUser,
    createUser
  }
)(UserModal);
