import moment from 'moment';
import React from 'react';
import FilterInputField from '../../components/TableFilter/ColumnsFilters/FilterInputField';
import FilterDatePicker from '../../components/TableFilter/ColumnsFilters/FilterDatePicker';
import { CheckBox } from './JournalStyles';
import { P_STATUS, RAG_STATUS_COLOR } from '../../_helpers/Constants';
import ColumnSorting from '../../components/TableFilter/ColumnsFilters/ColumnSorting';
import FilterSelect from '../../components/TableFilter/ColumnsFilters/FilterSelect';
import FilterTreeSelect from '../../components/TableFilter/ColumnsFilters/FilterTreeSelect/FilterTreeSelect';
import IconButton from '@material-ui/core/IconButton';
import ArchiveIcon from '@material-ui/icons/Archive';
import Tooltip from '@material-ui/core/Tooltip';

export function journalColumns({
  t,
  lang,
  checkedList,
  setCheckedList,
  canEdit,
  serviceType,
  isColumnsAccess,
  isAdmin,
  deadline,
  archiveModal,
  palette,
  isAcceptDocEdu,
  typeSchool
}) {
  const handleChange = (checked, item) => {
    checked
      ? setCheckedList([...checkedList, item])
      : setCheckedList(checkedList.filter(({ id }) => id !== item.id));
  };

  const isDisabled = item => {
    if (item.serviceType === 'university_grant') {
      return true;
    }
    if (
      item.processingStatus === P_STATUS.SUBMITTED ||
      item.processingStatus === P_STATUS.IN_PROGRESS
    ) {
      return checkedList[0]
        ? checkedList[0].serviceProviderId !== item.serviceProviderId
        : false;
    } else {
      return true;
    }
  };

  const columns = [
    {
      Header: '',
      accessor: 'checkItem',
      width: 50,
      filterable: false,
      style: { textAlign: 'center', paddingTop: 0, paddingBottom: 0 },
      show: canEdit,
      Cell: ({ original: item }) =>
        (item.serviceType !== 'university_grant') && (
          <div
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
            }}
            className="center"
          >
            <CheckBox
              disabled={isDisabled(item)}
              checked={checkedList.some(i => i.id === item.id)}
              onClick={() => handleChange(!checkedList.some(i => i.id === item.id), item)}
            />
          </div>
        )
    },
    {
      Header: <ColumnSorting title={t('journalPage_columnStatementNumber')} name="id" />,
      accessor: 'id',
      style: { textAlign: 'center' },
      maxWidth: 120,
      Filter: <FilterInputField name="id" type="number" />
    },
    {
      Header: <ColumnSorting title={t('journalPage_columnNameService')} name="serviceType" />,
      accessor: 'serviceType ',
      width: 250,
      Cell: ({ original: { serviceTypeName } }) => serviceTypeName[`${lang}_name`],
      Filter: <FilterSelect name="serviceType" options={serviceType} lang={lang} />
    },
    {
      Header: t('journalPage_columnIin'),
      accessor: 'requesterIin',
      style: { textAlign: 'center' },
      maxWidth: 170,
      Filter: <FilterInputField name="requesterIinBin" type="number" />
    },
    {
      Header: (
        <ColumnSorting title={t('journalPage_columnFullName')} name="requesterName" />
      ),
      accessor: 'requesterName',
      Filter: <FilterInputField name="requesterName" type="text" />
    },
    {
      Header: <ColumnSorting title={t('journalPage_columnDate')} name="submissionDate" />,
      accessor: 'submissionDate',
      Cell: row =>
        row.original.submissionDate
          ? moment(row.original.submissionDate).format('DD.MM.YYYY / HH:mm')
          : '',
      sortable: false,
      style: { textAlign: 'center' },
      maxWidth: 180,
      Filter: <FilterDatePicker name="submissionDate" lang={lang} />
    },
    {
      Header: t('journalPage_columnChildIin'),
      accessor: 'childIin',
      style: { textAlign: 'center' },
      maxWidth: 170,
      Filter: <FilterInputField name="childIin" type="number" />
    },
    {
      Header: (
        <ColumnSorting title={t('journalPage_columnStatus')} name="processingStatus" />
      ),
      accessor: 'processingStatus',
      Cell: ({ original: { statusName } }) => statusName[`${lang}_name`],
      sortable: false,
      maxWidth: 200,
      Filter: (
        <FilterSelect name="processingStatus" dictCode="gu_status_res" lang={lang} />
      )
    },
    {
      Header: (
        <ColumnSorting title={t('journalPage_columnResolution')} name="resolutionType" />
      ),
      accessor: 'resolutionTypeName',
      Cell: ({ original: { resolutionTypeName } }) => resolutionTypeName[`${lang}_name`],
      sortable: false,
      maxWidth: 200,
      Filter: <FilterSelect name="resolutionType" dictCode="gu_status_pr" lang={lang} />
    },
    {
      Header: (
        <ColumnSorting title={t('journalPage_columnResponsible')} name="assignee" />
      ),
      accessor: 'assigneeName',
      Filter: <FilterInputField name="assignee" type="text" />
    },
    {
      Header: t('journalPage_columnExternalId'),
      accessor: 'externalRequestId',
      sortable: false,
      Filter: <FilterInputField name="externalRequestId" type="number" />
    },
    {
      Header: t('journalPage_columnAdditional_status'),
      accessor: 'additionalStatus',
      Cell: ({ original: { additionalStatusName } }) =>
        additionalStatusName[`${lang}_name`],
      sortable: false,
      Filter: (
        <FilterSelect
          name="additionalStatus"
          dictCode="gu_additional_status"
          lang={lang}
        />
      )
    }
  ];

  columns.push({
    Header: t('journalPage_columnBin'),
    accessor: 'serviceProviderBin',
    style: { textAlign: 'center' },
    maxWidth: 170,
    Filter: <FilterInputField name="serviceProviderBin" type="number" />
  });
  columns.push({
    Header: t('journalPage_columnLocation'),
    accessor: 'location',
    Cell: ({ original: { location } }) => location[`${lang}_name`],
    maxWidth: 170,
    Filter: (
      <FilterTreeSelect
        lookupId="60"
        name="serviceProviderLocation"
        lang={lang}
        filter={item => item.ARM_gu === 'true'}
      />
    )
  });

  columns.push({
    Header: t('journalPage_ragStatus'),
    Cell: ({ original: { ragStatus, ragDate, delayPeriod } }) => (
      <Tooltip placement="top" title={ragDate || ''}>
        <div
          style={{
            backgroundColor: RAG_STATUS_COLOR.getColor(ragStatus, palette),
            transform: 'scale(2)',
            marginTop: 1,
            display: 'list-item'
          }}
        >
          <span style={{ transform: 'scale(0.45)', display: 'block' }}>
            {delayPeriod
              ? t('journalDucks_delayPeriod', delayPeriod)
              : (deadline.find(item => item.code === ragStatus) || {})[`${lang}_name`]}
          </span>
        </div>
      </Tooltip>
    ),
    sortable: false,
    Filter: (
      <FilterSelect
        name="ragStatus"
        lang={lang}
        options={deadline.map(item => ({
          ...item,
          backgroundColor: RAG_STATUS_COLOR.getColor(item.code, palette)
        }))}
      />
    )
  });

  if (isAdmin) {
    columns.push({
      Header: t('journalPage_columnAction'),
      accessor: 'id',
      Cell: ({ original }) => (
        <div className="center" style={{ margin: '-5px 0' }}>
          <Tooltip placement="top" title={t('journalPage_toArchive')}>
            <IconButton
              size="small"
              children={<ArchiveIcon fontSize="small" />}
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                archiveModal.open(original);
              }}
            />
          </Tooltip>
        </div>
      ),
      width: 80,
      filterable: false
    });
  }
  if (isAcceptDocEdu) {
    columns.splice(3, 0, {
      Header: <ColumnSorting title={t('journalPage_columnType')} 
      name="typeApplic" />,
      accessor: 'typeApplic',
      Cell: ({ original: { typeApplic } }) => typeSchool.find(item => item?.code === typeApplic)?.[`${lang}_name`],
      Filter: <FilterSelect name="typeApplic" options={typeSchool} lang={lang} />
    })
  }
  return columns;
}
