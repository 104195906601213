import React from 'react';
import config from '../../../_helpers/config';

export function columns({ t, lang }) {
  return [
    {
      Header: '№',
      accessor: 'rowNumber',
      maxWidth: 60,
      filterable: false
    },
    {
      Header: t('monitoring_columnLocation'),
      accessor: `${lang}_locationName`
    },
    {
      Header: t('monitoring_columnSUBMITTED'),
      accessor: 'SUBMITTED',
      Cell: row => new Intl.NumberFormat().format(row.original.SUBMITTED),
      filterable: false,
      style: { textAlign: 'right' },
      maxWidth: 150
    },
    {
      Header: t('monitoring_columnIN_PROGRESS'),
      accessor: 'IN_PROGRESS',
      Cell: row => new Intl.NumberFormat().format(row.original.IN_PROGRESS),
      filterable: false,
      style: { textAlign: 'right' },
      maxWidth: 150
    },
    {
      Header: t('monitoring_columnREADY_FOR_SIGN'),
      accessor: 'READY_FOR_SIGN',
      Cell: row => new Intl.NumberFormat().format(row.original.READY_FOR_SIGN),
      filterable: false,
      style: { textAlign: 'right' },
      maxWidth: 150
    },
    {
      Header: t('monitoring_columnCOMPLETED_POSITIVE'),
      accessor: 'COMPLETED_POSITIVE',
      Cell: row => new Intl.NumberFormat().format(row.original.COMPLETED_POSITIVE),
      filterable: false,
      style: { textAlign: 'right' },
      maxWidth: 150
    },
    {
      Header: t('monitoring_columnCOMPLETED_NEGATIVE'),
      accessor: 'COMPLETED_NEGATIVE',
      Cell: row => new Intl.NumberFormat().format(row.original.COMPLETED_NEGATIVE),
      filterable: false,
      style: { textAlign: 'right' },
      maxWidth: 150
    },
    {
      Header: t('monitoring_columnIN_PROGRESS_IN_EXTERNAL_APP'),
      accessor: 'IN_PROGRESS_IN_EXTERNAL_APP',
      Cell: row => new Intl.NumberFormat().format(row.original.IN_PROGRESS_IN_EXTERNAL_APP),
      filterable: false,
      style: { textAlign: 'right' },
      show: config.code === 'MON',
      maxWidth: 150
    },
    {
      Header: t('monitoring_columnAllByRegion'),
      accessor: 'allByRegion',
      style: { textAlign: 'right' },
      filterable: false,
      Cell: row => (
        <div
          style={{
            backgroundColor: 'rgba(125,125,125,0.3)',
            margin: '-7px -15px',
            padding: '7px 15px',
            height: 30
          }}
          children={new Intl.NumberFormat().format(row.original.allByRegion)}
        />
      ),
      maxWidth: 150
    }
  ];
}
