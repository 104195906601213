import React from 'react';
import { useTranslation } from 'react-i18next';
import DialogContent from '@material-ui/core/DialogContent';
import Modal from '../../../_ui/Modal/Modal';
import ServiceProvidersTable from '../../ServiceProvidersPage/ServiceProvidersTable';
import { TableWrapper } from '../../ServiceProviderPage/ServiceProviderStyle';

function ServiceProvidersModal({ modal, setFormData }) {
  const { t } = useTranslation();
  const selectedId = modal.data.organization && modal.data.organization.value;
  const onClickRow = ({ original }) => {
    setFormData({
      ...modal.data,
      organization: {
        ru_name: original.ru_name,
        kk_name: original.kk_name,
        value: original.id,
        bin: original.bin
      }
    });
    modal.close();
  };

  return (
    <>
      <Modal
        open
        maxWidth="lg"
        title={t('administrationPage_heir_choose_sp')}
        onClose={modal.close}
        scroll="paper"
      >
        <DialogContent dividers>
          <TableWrapper>
            <ServiceProvidersTable
              index={2}
              config={{ isModal: true, selectedId, setSelected: onClickRow }}
              rowBgColor={row =>
                row.original.id === selectedId ? 'rgba(125,125,125,0.2)' : undefined
              }
              onClickRow={onClickRow}
            />
          </TableWrapper>
        </DialogContent>
      </Modal>
    </>
  );
}

export default ServiceProvidersModal;
