import { addTranslation } from '../../_helpers/Translate';

addTranslation({
  ru: {
    contingentSection_title: 'Контингент доп образование',
    contingentSection_studentsTitle: 'Учащиеся',
    contingentSection_departTitle: 'Выбывшие',
    contingentSection_id: 'ID',
    contingentSection_photoSchool: 'Фото учащегося',
    contingentSection_iinSchool: 'ИИН учащегося',
    contingentSection_fullName: 'ФИО учащегося',
    contingentSection_dateBirth: 'Дата рождения учащегося',
    contingentSection_phone: 'Телефон учащегося',
    contingentSection_email: 'Электронная почта учащегося',
    contingentSection_name: 'Наименование кружка, секции',
    contingentSection_dateEnrollment: 'Дата зачисления учащегося',
    contingentSection_dateDeparture: 'Дата выбытия учащегося',
    contingentSection_pupilsErrorLoadTable: 'Не удалось загрузить Школьники',
    contingentSection_exitedErrorLoadTable: 'Не удалось загрузить Выбывшие',
    contingentSection_addBtn: 'Добавить',
    contingentSection_addError: 'Ошибка при добавлении',
    contingentSection_addSuccess: 'Успешно подтверждена',
    contingentSection_errorLoadMeta: 'Не удалось загрузить мета данных',
    contingentSection_studentConfirmation: 'Подтверждение учащегося',
    contingentSection_yes: 'Подтверждаю',
    contingentSection_dropoutDate: 'Дата выбытия',
    contingentSection_dropoutReason: 'Причина выбытия',
    contingentSection_retirementError: 'Ошибка при выбытие',
    contingentSection_reportTitle: 'Табель учета посещаемости учащихся',
    contingentSection_tableFor: 'табель для',
    contingentSection_reportModalTitle: 'Основание отсутствия',
    contingentSection_reportCause: 'Причина',
    contingentSection_date: 'Дата',
    contingentSection_startDate: 'Дата начала',
    contingentSection_completionDate: 'Дата завершения',
    contingentSection_saveBtn: 'Cохранить',
    contingentSection_reference: 'Справка',
    contingentSection_export_btn: 'Экспортировать',
    contingentSection_export_select_date: 'Выберите период для экспорта',
    contingentSection_export_start_date: 'Дата от',
    contingentSection_export_end_date: 'Дата до',
    contingentSection_reportExportError: 'Не удалось экспортировать табель',
    contingentSection_reportErrorLoadTable: 'Не удалось загрузить табель',
    contingentSection_causeAddSuccess: 'Успешно сохранено',
    contingentSection_causeAddError: 'Ошибка при сохранение',
  },
  kk: {
    contingentSection_title: 'Қосымша білім беру контингенті',
    contingentSection_studentsTitle: 'Оқушы',
    contingentSection_departTitle: 'Шығып кеткендер',
    contingentSection_id: 'ID',
    contingentSection_photoSchool: 'Оқушы фотосы',
    contingentSection_iinSchool: 'Оқушы ЖСН-і',
    contingentSection_fullName: 'Оқушы аты-жөні',
    contingentSection_dateBirth: 'Оқушы туған күні',
    contingentSection_phone: 'Оқушы телефон номері',
    contingentSection_email: 'Оқушы электрондық поштасы',
    contingentSection_name: 'Үйірменің, секцияның атауы',
    contingentSection_dateEnrollment: 'Қабылдау күні',
    contingentSection_dateDeparture: 'Шығару күні',
    contingentSection_pupilsErrorLoadTable: 'Қосымша білім беру орнын жүктеу сәтсіз аяқталды',
    contingentSection_exitedErrorLoadTable: 'Шығып кеткендер туралы мәліметтерді жүктеу сәтсіз аяқталды',
    contingentSection_addBtn: 'Қосу',
    contingentSection_addError: 'Қосу кезінде қате',
    contingentSection_addSuccess: 'Сәтті расталды',
    contingentSection_errorLoadMeta: 'Мета деректер жүктелмеді',
    contingentSection_studentConfirmation: 'Оқушыны растау',
    contingentSection_yes: 'Растаймын',
    contingentSection_dropoutDate: 'Шығу күні',
    contingentSection_dropoutReason: 'Шығу себебі',
    contingentSection_retirementError: 'Шығуды растау кезіндегі қате',
    contingentSection_reportTitle: 'Оқушылардың келу кестесі',
    contingentSection_tableFor: 'кесте',
    contingentSection_reportModalTitle: 'Основание отсутствия',
    contingentSection_reportCause: 'Причина',
    contingentSection_date: 'Дата',
    contingentSection_startDate: 'Дата начала',
    contingentSection_completionDate: 'Дата завершения',
    contingentSection_saveBtn: 'Cохранить',
    contingentSection_reference: 'Справка',
    contingentSection_export_btn: 'Экспортировать',
    contingentSection_export_select_date: 'Выберите период для экспорта',
    contingentSection_export_start_date: 'Дата от',
    contingentSection_export_end_date: 'Дата до',
    contingentSection_reportExportError: 'Табельді экспорттау мүмкін болмады',
    contingentSection_reportErrorLoadTable: 'Табельді жүктеу мүмкін болмады',
    contingentSection_causeAddSuccess: 'Cәтті сақталды',
    contingentSection_causeAddError: 'Сақтау кезінде қате',
  }
});
