import React from 'react';
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs';
import { Tabs, Tab, Paper, Box, Typography } from '@material-ui/core';
import './ColleaguesTranslate';
import { useTranslation } from 'react-i18next';
import paths from '../../_helpers/paths';
import { Link } from 'react-router-dom';
import ExportIcon from '@material-ui/icons/SaveAlt';
import Button from '../../_ui/Button/Button';
import AddIcon from '@material-ui/icons/Add';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Color } from '../../components/ThemeProvider/ThemeStyle';
import EmployessPage from './Employees/EmployessPage'
import DismissedPage from './Dismissed/DismissedPage'
import useSimpleModal from '../../components/_hooks/useSimpleModal';
import ModalAdd from './Employees/modal/ModalAdd'
import { colleaguesModule } from './ColleaguesDucks';

export default function ColleaguesPage({ location: { pathname } }) {
  const { t } = useTranslation();
  const addModal = useSimpleModal();

  const breadCrumbItems = [
    { name: t('breadcrumbs_home'), href: '/' },
    { name: t('colleagues_title') }
  ];

  const employeesTotal = useSelector(state => state[colleaguesModule].employees.totalElements);
  const dismissedTotal = useSelector(state => state[colleaguesModule].dismissed.totalElements);

  const employeesUrl = paths.employeesPage;
  const dismissedUrl = paths.dismissedPage;

  return (
    <>
      <BreadCrumbs items={breadCrumbItems} />
      <Paper 
        style={{ position: 'relative' }}
      >
        <Box className='flex justify-between'>
          <Box className={Color.isDark ? 'flex contingent_dark' : 'flex contingent'} style={{zIndex: 9}}>
            <Tabs 
              value={pathname} 
              indicatorColor={Color.isDark ? '#252525' : '#fff'}
            >
              <Tab
                label={t('colleagues_employees')}
                value={employeesUrl}
                component={Link}
                to={employeesUrl}
                style={{
                  fontWeight: '700',
                  border: '1px solid #e1e1e1',
                  borderBottom: 'none',
                  borderLeft: 'none',
                  backgroundColor: '#e1e1e185'
                }}
              />
              <Tab
                label={t('colleagues_dismissed')}
                value={dismissedUrl}
                component={Link}
                to={dismissedUrl}
                style={{
                  fontWeight: '700',
                  border: '1px solid #e1e1e1',
                  borderBottom: 'none',
                  backgroundColor: '#e1e1e185'
                }}
              />
            </Tabs>
            <Box style={{ marginLeft: '10px', display: 'flex', alignItems: 'center' }}>
              <Button
                text={t('journalPage_export_btn')}
                icon={<ExportIcon fontSize="small" className="mr1" />}
                onClick={() => {}}
              />
              <Typography style={{ marginLeft: '10px'}}>
                {t('colleaguesPassport_numberEmployees')} {' '} {pathname === employeesUrl ? employeesTotal : dismissedTotal}
              </Typography>
            </Box>
          </Box>
          {
            pathname === employeesUrl &&
            <Box className='mr3' style={{marginTop: '5px'}}>
              <Button
                icon={<AddIcon fontSize="small" className="mr1" />}
                text={t('colleagues_employeesAdd')}
                component={NavLink}
                to={paths.colleaguesPassportPage}
              />
            </Box>
          }
        </Box>
        <div className="px3 pb3 pt1 relative" style={{ borderTop: '1px solid #e1e1e1', marginTop: '-1px', }}>
          {pathname === employeesUrl && <EmployessPage /> }
          {pathname === dismissedUrl && <DismissedPage />}
        </div>
        {addModal.isOpen && <ModalAdd {...addModal} />}

      </Paper>
    </>
  );
}
