import React from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import './FaqTranslate';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import useDict from '../../components/_hooks/useDict';

const Accordion = withStyles(({ palette, shape }) => ({
  root: {
    border: '1px solid ' + palette.divider,
    borderRadius: shape.borderRadius,
    boxShadow: 'none',
    padding: '3px 0px',
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      margin: 'auto'
    }
  },
  expanded: {}
}))(MuiAccordion);

function FaqPage() {
  const { t, i18n } = useTranslation();
  const faq = useDict('questions-answers');
  const breadCrumbItems = [
    { name: i18next.t('breadcrumbs_home'), href: '/' },
    { name: i18next.t('breadcrumbs_faq') }
  ];

  return (
    <div>
      <BreadCrumbs items={breadCrumbItems} />
      <Paper className="pb3 pt1 px3">
        <h3>{t('faq_title')}</h3>
        <Divider />
        <div className="mt2">
          {faq.map(item => (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <span className="px1 fw-6">{item[`${i18n.language}_name`]}</span>
              </AccordionSummary>
              <AccordionDetails>
                <div className="px1">{item[`${i18n.language}_answer`]}</div>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      </Paper>
    </div>
  );
}

export default FaqPage;
