import React from 'react';
import Modal from '../../../_ui/Modal/Modal';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import Button from '../../../_ui/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { deleteBulletin, kezekBulletinModule } from '../KezekBulletinDucks';
import CancelIcon from '@material-ui/icons/Cancel';

export default function ModalDelete({ data: id, close }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loading = useSelector(state => state[kezekBulletinModule].loadingDelete);
  return (
    <Modal title={t('kezekBulletinPage_deleteTitle')} open onClose={close}>
      <DialogContent>{t('kezekBulletinPage_deleteText', { id })}</DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          loading={loading}
          icon={<CancelIcon fontSize="small" className="mr1" />}
          text={t('kezekBulletinPage_delete')}
          onClick={() => dispatch(deleteBulletin(id, close))}
        />
      </DialogActions>
    </Modal>
  );
}
