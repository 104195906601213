import React from 'react';
import { connect } from 'react-redux';
import withTranslation from '../_hoc/withTranslation';
import { logout } from '../../pages/LoginPage/LoginDucks';
import { LoginApi } from '../../_helpers/service';
import Button from '../../_ui/Button/Button';
import Modal from '../../_ui/Modal/Modal';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import './TokenRefresherTranslate'
import { jwtDecode } from 'jwt-decode';

class TokenRefresher extends React.Component {
  state = {
    diff: 600000,
    userActive: false,
    showBtnTimer: 0,
    expiredAt: null
  };

  componentDidMount() {
    let expiredAt = localStorage.getItem('expiredAt');
    if (expiredAt && expiredAt > Date.now() + 10000) {
      window.addEventListener('mousemove', this.moveListener);
      window.addEventListener('keypress', this.moveListener);
      let diff = expiredAt - Date.now();
      this.setState({ diff, expiredAt });
      this.interval = setInterval(this.checkToken, 1000);
      this.expiredInterval = setInterval(this.checkExpiredAt, 5000);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('mousemove', this.moveListener);
    window.removeEventListener('keypress', this.moveListener);
    clearInterval(this.interval);
    clearInterval(this.expiredInterval);
  }

  checkExpiredAt = () => {
    let expiredAt = localStorage.getItem('expiredAt');
    if (expiredAt && expiredAt !== this.state.expiredAt) {
      let diff = expiredAt - Date.now();
      this.setState({ diff, expiredAt });
    }
  };

  moveListener = () => {
    const { diff, userActive } = this.state;
    if (diff > 60000 && !userActive) {
      this.setState({ userActive: true });
    }
  };

  checkToken = () => {
    const { diff, userActive, showBtnTimer } = this.state;
    if (diff <= 500) {
      this.props.logout();
    } else if (diff <= 60000 && (userActive || window.test)) {
      this.refreshToken();
    }

    if (showBtnTimer > 0) {
      this.setState({ showBtnTimer: showBtnTimer - 1 });
    }

    if (diff > 500) {
      this.setState({ diff: diff - 1000 });
    }
  };

  refreshToken = async () => {
    const refreshToken = localStorage.getItem('refreshToken');
    clearInterval(this.interval);
    try {
      if (refreshToken) {
        const response = await LoginApi.refreshUser(refreshToken);
        if (response.data.status === 'SUCCESS') {
          const decode = jwtDecode(response.data.accessToken);
          if (decode.exp) {
            const expiredAt = decode.exp * 1000 - 10000;
            localStorage.setItem('accessToken', response.data.accessToken);
            localStorage.setItem('refreshToken', response.data.refreshToken);
            localStorage.setItem('expiredAt', expiredAt);
            this.setState({ diff: expiredAt - Date.now(), userActive: false });
            this.interval = setInterval(this.checkToken, 1000);
          }
        }
      }
    } catch (ignore) {}
  };

  render() {
    const { diff, userActive } = this.state;
    const { t } = this.props;
    if (diff <= 40000 && !userActive) {
      return (
        <Modal open title={t('tokenRefresher_title')}>
          <DialogContent>
            <div style={{ fontSize: '18px' }}>
              {t('tokenRefresher_expiredAt')} {Math.round(diff / 1000)}
              {t('tokenRefresher_second')}
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.refreshToken} text={t('tokenRefresher_stay')} />
          </DialogActions>
        </Modal>
      );
    }

    return null;
  }
}

export default connect(
  null,
  { logout }
)(withTranslation(TokenRefresher));
