import React from 'react';
import Modal from '../../../_ui/Modal/Modal';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import Button from '../../../_ui/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setContingent, contingentSectionModule } from '../ContingentSectionDucks';
import DoneIcon from '@material-ui/icons/Done';
import { Color } from '../../../components/ThemeProvider/ThemeStyle';
import {
  getFormValues,
} from '../../../passport/Passport';
import { useHistory } from "react-router-dom";


export default function ModalAdd(data) {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { loadingSave } = useSelector(
    state => state[contingentSectionModule]
  );
  
  return (
    <Modal
      title={t('contingentSection_studentConfirmation') }
      open
      onClose={data.close}
    >
      <DialogContent>
        <div className="flex justify-end pb2">
          <Button
            color="primary"
            style={{ backgroundColor: Color.bgColorSuccess }}
            loading={loadingSave}
            icon={<DoneIcon fontSize="small" className="mr1" />}
            text={ t('contingentSection_yes') }
            onClick={() => {
              getFormValues().then(values => {
                dispatch(
                  setContingent({ values, close: data.close, studentIIN: data.studentIIN, history, id: data.id })
                )
              });
            }}
          />
        </div>
      </DialogContent>
    </Modal>
  );
}
