import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  PassportProvider,
  PassportExpander,
  PassportForm,
  getFormValues,
} from '../../passport/Passport';
import { Color } from '../../components/ThemeProvider/ThemeStyle';
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary';
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs';
import { colleaguesModule, clearState, getMeta, getItemEmployees } from './ColleaguesDucks';
import { Wrapper, ButtonsWrapper } from './ColleaguesStyle';
import { useTranslation } from 'react-i18next';
import PageLoading from '../../components/PageLoader/PageLoading';
import './ColleaguesTranslate';
import i18next from 'i18next';
import { useParams } from 'react-router';
import paths from './../../_helpers/paths';
import Button from '../../_ui/Button/Button';
import SaveIcon from '@material-ui/icons/Save';
import ModalAdd from './modal/ModalAdd'
import useSimpleModal from '../../components/_hooks/useSimpleModal';
import Row from '../../passport/Components/Row';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import ModalDimissed from './modal/ModalDimissed';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import MuiSelect from '@material-ui/core/Select';
export default function ColleaguesPassportPage() {
  const { t, i18n  } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const addModal = useSimpleModal();
  const modalDimissed = useSimpleModal();
  const [staffIin, setStaffIin] = useState();
  const [positionCode, setPositionCode] = useState();
  const { loading, data, meta, position, loadingSave, loadingDismissed } = useSelector(
    state => state[colleaguesModule]
  );

  const breadCrumbItems = [
    { name: i18next.t('breadcrumbs_home'), href: '/' },
    { name: i18next.t('breadcrumbs_colleagues'), href: paths.employeesPage },
    { name: i18next.t('breadcrumbs_colleaguesPassport') }
  ];
  useEffect(() => {
    dispatch(getMeta());
    if (id) {
      dispatch(getItemEmployees({ id: id }))
    } else {
      dispatch(clearState())
    }
   //return () => dispatch(clearState());
  }, []);

  const onAddEmployees = () => {
    getFormValues().then(values => {
      if (values && (staffIin && staffIin?.length === 12) && (positionCode || data?.staffType)) {
        addModal.open(true)
      }
    })
  }

  useEffect(() => {
    if (data) {
      setStaffIin(data.iin)
    }
  }, [data.iin])

  return (
    <PageLoading loading={loading}>
      <BreadCrumbs items={breadCrumbItems} />
      <Wrapper className="px3 pb3">
        <ErrorBoundary>
          <PassportProvider
            withoutScroll={false}
            stickyTop={Color.headerHeight}
            data={JSON.stringify(data) === '{}' ? {metadataKey: "form_staff"} : data}
            meta={meta}
            config={{
              documentsProps: {
                mimeType: 'image/jpeg,image/png,application/pdf',
                maxSize: 10000000,
              },
            }}
          >
            <PassportExpander />
            <div className='mt2'>
              <Row label={t('colleagues_iin')}>
                <TextField
                  placeholder={ t('colleagues_iin')}
                  error={!staffIin || staffIin?.length >= 13 || staffIin?.length <= 11}
                  required={true}
                  value={staffIin}
                  type="number"
                  disabled={data.staffStatus === 'DISMISSED'}
                  onChange={(e) => setStaffIin(e.target.value)}
                />
                <FormHelperText error={!staffIin || staffIin?.length >= 13 || staffIin?.length <= 11}>
                  { !staffIin && t('colleaguesPassport_requiredField') }
                  { staffIin?.length && staffIin?.length >= 13 && t('colleaguesPassport_correctValue') }
                  { staffIin?.length && staffIin?.length <= 11 && t('colleaguesPassport_correctValue') }
                </FormHelperText>
              </Row>
            </div>
            <PassportForm loading={loading} disabled={false} />
            <div className='mt2'>
              <Row label={t('colleagues_employeePosition')}>
                <FormControl fullWidth>
                  <MuiSelect
                    value={positionCode ? positionCode : data?.staffType}
                    error={!(!!positionCode || data?.staffType)}
                    disabled={data?.staffType}
                  >
                    {
                      position.map((item) => {
                        return <MenuItem value={item.code} onClick={() => setPositionCode(item.code)} key={item.code}>{item[`${i18n.language}_name`]}</MenuItem>
                      })
                    }
                  </MuiSelect>      
                </FormControl>
                {
                  !(!!positionCode || data?.staffType) &&
                  <FormHelperText error>
                    { t('colleaguesPassport_requiredField') }
                  </FormHelperText>
                }
              </Row>
            </div>
          </PassportProvider>
          <ButtonsWrapper>
            {
              data.staffStatus === 'WORKING' &&
              <Button
                loading={loadingDismissed || loading}
                style={{ marginRight: 10 }}
                disabled={loadingDismissed || loading}
                size="small"
                color="secondary"
                text={t('colleagues_dismissalBtn')}
                onClick={() => modalDimissed.open(true)}
              />
            }
            {
              data.staffStatus !== 'DISMISSED' &&  
              <Button
                icon={<SaveIcon fontSize="small" style={{marginRight: 10}}/>}
                loading={loadingSave || loading}
                style={{ backgroundColor: Color.bgColorSuccess }}
                disabled={loadingSave || loading}
                size="small"
                color="primary"
                text={id ? t('colleagues_save') : t('colleagues_employeesAdd')}
                onClick={onAddEmployees}
              />
            }
          </ButtonsWrapper>
          {addModal.isOpen && <ModalAdd {...addModal} staffIin = {staffIin} close={addModal.close} id={id} positionCode={positionCode} />}
          {modalDimissed.isOpen && <ModalDimissed {...addModal} id={id} close={modalDimissed.close} /> }
        </ErrorBoundary>
      </Wrapper>
    </PageLoading>
  );
}
