import i18next from 'i18next';
import { useEffect } from 'react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import useTableFilter from '../../components/TableFilter/useTableFilter';
import { statusColor } from '../../utils/statusColors';
import {
  kezekDirectionTableModule,
  loadData,
  clearState
} from './KezekDirectionTableDucks';
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs';
import StaticTablePagination from '../../_ui/Table/StaticTablePaginated';
import { kezekDirectionTableColumns } from './KezekDirectionTableColumns';
import './KezekDirectionTableTranslate';
import { useTranslation } from 'react-i18next';
import paths from '../../_helpers/paths';

export default function KezekDirectionTablePage() {
  const { t, i18n } = useTranslation();
  const loading = useSelector(state => state[kezekDirectionTableModule].loadingTable);
  const data = useSelector(state => state[kezekDirectionTableModule].tableData);
  const dispatch = useDispatch();
  const { nextPage } = useTableFilter(filter => dispatch(loadData(filter)));
  const breadCrumbItems = [
    { name: i18next.t('breadcrumbs_home'), href: '/' },
    { name: i18next.t('breadcrumbs_kezekDirectionList') }
  ];

  useEffect(() => {
    dispatch(clearState());
  }, [dispatch]);

  return (
    <>
      <BreadCrumbs items={breadCrumbItems} />
      <Paper className="pb3 pt1 px3" style={{ minWidth: 1800 }}>
        <h3>{t('kezekDirectionTable_title')}</h3>
        <StaticTablePagination
          columns={kezekDirectionTableColumns({ t, lang: i18n.language })}
          data={data.content}
          sortable={false}
          urlOnClick={row => paths.kezekDirection.replace(':id', row.original.id)}
          totalElements={data.totalElements}
          loading={loading}
          onClickLoadMore={() => nextPage()}
          rowBgColor={row => statusColor[row.original.resolutionType]}
        />
      </Paper>
    </>
  );
}
