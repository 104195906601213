import lodash from 'lodash';
import { P_STATUS } from '../../_helpers/Constants';
import { StatementApi } from '../../_helpers/service';
import moment from 'moment';

export const buttonRules = async requestForm => {
  const rules = {};
  const serviceType = requestForm.serviceType.toLowerCase();
  const hideButtons = hideAllButtons(requestForm, serviceType);
  const getValue = code => {
    const key = `questionnaire.questionCodeToAnswers.${code}.values[0].value`;
    return lodash.get(requestForm, key);
  };

  if (hideButtons) return hideButtons;

  if (requestForm.processingStatus === P_STATUS.IN_PROGRESS) {
    rules.save = true;
    rules.intermediate = await intermediateRules(requestForm, getValue);

    if (localStorage.getItem('statementTest')) {
      rules.positive = true;
      rules.negative = true;
    } else {
      rules.positive = positiveRules(serviceType, rules.intermediate, getValue);
      rules.negative = negativeRules(serviceType, getValue);

      if (serviceType === 'university_grant') {
        rules.save = false;
      }
    }
  }
  return rules;
};

const hideAllButtons = (requestForm, serviceType) => {
  if (localStorage.getItem('statementTest') === 'true') {
    return false;
  }

  if (serviceType === 'university_grant') {
    return { hideAllButtons: true };
  }
  if (serviceType === 'doc_queued_ddo') {
    return { hideAllButtons: true };
  }

  if (
    requestForm.processingStatus !== P_STATUS.COMPLETED &&
    moment(requestForm.submissionDate).year() === 2020 &&
    (serviceType === 'accept_doc_edu' || serviceType === 'graduate_admission')
  ) {
    return { hideAllButtons: true, impossibleProcMsg: true };
  }
};

const positiveRules = (serviceType, isIntermediate, getValue) => {
  let ins = getValue('intermediate_notification_sent');
  let at = getValue('answer_type');
  let odt = getValue('output_document_type');
  let atd = getValue('answer_type_doc');
  let wot = getValue('ward_outputdoc_type');
  let dt = getValue('decisiondoc_type');
  let fdt = getValue('finaldoc_ddo_type');
  let ot = getValue('output_type');
  let answer = getValue('answer');
  let ea = getValue('extern_answer');
  let showPositive = isIntermediate ? ins : true;
  let otd = getValue('Output_Type_doc');
  let otdLower = getValue('output_Type_doc');
  let final_answer = getValue('final_answer');

  if (serviceType === 'university_admission') {
    showPositive = odt === '001' || odt === '003';
  }
  if (serviceType === 'graduate_admission') {
    showPositive = odt === '001';
  }
  if (showPositive && serviceType === 'accept_doc_edu') {
    showPositive = ['1', '2', '4', '5', '6'].includes(atd);
  }
  if (serviceType === 'university_grant') {
    showPositive = false;
  }
  if (serviceType === 'payment_patronage') {
    showPositive = wot === '1' || wot === '2';
  }
  if (serviceType === 'payment_family') {
    showPositive = wot === '001' || wot === '002';
  }
  if (serviceType === 'children_disposable') {
    showPositive = dt === '001';
  }
  if (serviceType === 'international_edu') {
    showPositive = [true, 'true'].includes(answer);
  }
  if (serviceType === 'payment_care_define') {
    showPositive = ['002', '003', '004', '005'].includes(wot);
  }
  if (serviceType === 'doc_queued_ddo') {
    showPositive = fdt === '001';
  }
  if (serviceType === 'letter_guarantee') {
    showPositive = wot === '001';
  }
  if (serviceType === 'realestate_bolashak') {
    showPositive = ot === true;
  }
  if (serviceType === 'advance_payment') {
    showPositive = at === '001';
  }
  if (serviceType === 'extern_study') {
    showPositive = ea === '1';
  }
  if (serviceType === 'children_turn') {
    showPositive = dt === '1';
  }
  if (serviceType === 'doc_acceptance_ddo') {
    showPositive = fdt === '001';
  }
  if (serviceType === 'children_adoption') {
    showPositive = wot === '1';
  }
  if (serviceType === 'pmpk') {
    showPositive = otd === '3';
  }
  if (serviceType === 'organization_type') {
    showPositive = otd === '001' || otd === '002';
  }
  if (serviceType === 'college') {
    showPositive = ['1', '2', '4', '5'].includes(otd);
  }
  if (serviceType === 'acceptance_olymp_reserve') {
    showPositive = otdLower === '1';
  }
  if (serviceType === 'acceptance_odo') {
    showPositive = otdLower === '1';
  }
  if (serviceType === 'status_assignment') {
    showPositive = final_answer === '001';
  }
  if (serviceType === 'home_for_champions') {
    showPositive = final_answer === '001';
  }
  if (showPositive && serviceType === 'accept_school') {
    showPositive = ['1'].includes(atd);
  }
  return showPositive;
};

const negativeRules = (serviceType, getValue) => {
  let showNegative = true;
  let da = getValue('documents_accepted');
  let at = getValue('answer_type');
  let atd = getValue('answer_type_doc');
  let odt = getValue('output_document_type');
  let wot = getValue('ward_outputdoc_type');
  let dt = getValue('decisiondoc_type');
  let ot = getValue('output_type');
  let fdt = getValue('finaldoc_ddo_type');
  let answer = getValue('answer');
  let ea = getValue('extern_answer');
  let otd = getValue('Output_Type_doc');
  let otdLower = getValue('output_Type_doc');
  let final_answer = getValue('final_answer');
  if (serviceType === 'university_grant') {
    showNegative = da === false && odt === '001';
  }
  if (serviceType === 'university_admission') {
    showNegative = odt === '002' || odt === '004';
  }
  if (serviceType === 'graduate_admission') {
    showNegative = odt === '002';
  }
  if (serviceType === 'payment_patronage') {
    showNegative = wot === '4';
  }
  if (serviceType === 'payment_family') {
    showNegative = wot === '004';
  }
  if (serviceType === 'children_disposable') {
    showNegative = dt === '003' || dt === '002';
  }
  if (serviceType === 'international_edu') {
    showNegative = [false, 'false'].includes(answer);
  }
  if (serviceType === 'payment_care_define') {
    showNegative = ['007'].includes(wot);
  }
  if (serviceType === 'doc_queued_ddo') {
    showNegative = fdt === '002';
  }
  if (serviceType === 'letter_guarantee') {
    showNegative = wot === '002';
  }
  if (serviceType === 'realestate_bolashak') {
    showNegative = ot === false;
  }
  if (serviceType === 'advance_payment') {
    showNegative = at === '002';
  }
  if (serviceType === 'extern_study') {
    showNegative = ea === '2';
  }
  if (serviceType === 'children_turn') {
    showNegative = dt === '2';
  }
  if (serviceType === 'doc_acceptance_ddo') {
    showNegative = fdt === '002';
  }
  if (serviceType === 'accept_doc_edu') {
    showNegative = atd === '3';
  }
  if (serviceType === 'children_adoption') {
    showNegative = wot === '0';
  }
  if (serviceType === 'pmpk') {
    showNegative = otd === '2';
  }
  if (serviceType === 'organization_type') {
    showNegative = otd === '004';
  }
  if (serviceType === 'college') {
    showNegative = otd === '3';
  }
  if (serviceType === 'acceptance_olymp_reserve') {
    showNegative = otdLower === '2';
  }
  if (serviceType === 'acceptance_odo') {
    showNegative = otdLower === '2';
  }
  if (serviceType === 'status_assignment') {
    showNegative = final_answer === '002';
  }
  if (serviceType === 'home_for_champions') {
    showNegative = final_answer === '002';
  }
  if (serviceType === 'accept_school') {
    showNegative = atd === '3';
  }
  return showNegative;
};

const intermediateRules = async (requestForm, getValue) => {
  let showIntermediate = false;
  let { data } = await StatementApi.availableTransition(requestForm.id);
  if (data.result.length > 0) {
    showIntermediate = data.result[0].code === 'COMMIT_INTERMEDIATE';
  }
  let ins = getValue('intermediate_notification_sent');
  let wot = getValue('ward_outputdoc_type');
  let otd = getValue('Output_Type_doc');

  if (requestForm.serviceType === 'payment_care_define') {
    showIntermediate = wot === '001' && !ins;
  }
  if (requestForm.serviceType === 'payment_patronage') {
    showIntermediate = wot === '3' && !ins;
  }
  if (requestForm.serviceType === 'payment_family') {
    showIntermediate = wot === '003' && !ins;
  }
  if (requestForm.serviceType === 'pmpk') {
    showIntermediate = otd === '1' && !ins;
  }
  return showIntermediate;
};
