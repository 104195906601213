import { addTranslation } from '../../_helpers/Translate';

addTranslation({
  ru: {
    archivePage_title: 'Архив',
    archivePage_columnType: 'Тип заявки',
    archivePage_columnStatementNumber: 'Номер заявки',
    archivePage_columnIin: 'ИИН',
    archivePage_columnFullName: 'ФИО',
    archivePage_columnDate: 'Дата, время',
    archivePage_columnStatus: 'Статус',
    archivePage_columnResolution: 'Решение',
    archivePage_columnResponsible: 'Ответственный ',
    archivePage_columnExternalId: 'Номер ПЭП ',
    archivePage_columnServiceProvider: 'Услугодатель',
    archivePage_columnActions: 'Действие',
    archiveDucks_errorLoadTable: 'Не удалось загрузить архив',
    archiveDucks_errorChangeFilter: 'Не удалось поменять фильтр',
    archiveDucks_tableFilter_minSymbol: 'минимум 3 символа'
  },
  kk: {
    archivePage_title: 'Архив',
    archivePage_columnType: 'Өтінім түрі',
    archivePage_columnStatementNumber: 'Өтінім нөмірі',
    archivePage_columnIin: 'ЖСН',
    archivePage_columnFullName: 'ТӘА',
    archivePage_columnDate: 'Күні, уақыты',
    archivePage_columnStatus: 'Мәртебесі',
    archivePage_columnResolution: 'Шешім',
    archivePage_columnResponsible: 'Жауапты',
    archivePage_columnExternalId: 'ЭҮП нөмірі ',
    archivePage_columnServiceProvider: 'Қызмет көрсетуші',
    archivePage_columnActions: 'Әрекет',
    archiveDucks_errorLoadTable: 'Архивті жүктеу мүмкін емес',
    archiveDucks_errorChangeFilter: 'Сүзгіні өзгерту мүмкін болмады',
    archiveDucks_tableFilter_minSymbol: 'кемінде 3 таңба'
  }
});
