import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bpModule, loadData, clearState } from './BusinessProcessDucks';
import { Card, IconButton, Tooltip, useTheme, Link as MuiLink } from '@material-ui/core';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import CircularProgress from '@material-ui/core/CircularProgress';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import StartIcon from '@material-ui/icons/PlayCircleFilled';
import StopIcon from '@material-ui/icons/StopRounded';
import { useTranslation } from 'react-i18next';
import capitalize from '@material-ui/core/utils/capitalize';
import './BusinessProcessTranslate';
import { TASK_STATUS_COLOR, PROCESS_STATUS_COLOR } from '../../../_helpers/Constants';
import styled from 'styled-components';
import useSimpleModal from '../../../components/_hooks/useSimpleModal';
import StartModal from './StartModal';
import StopProcessModal from './StopProcessModal';
// import StopTaskModal from './StopTaskModal';

const StopButton = styled(IconButton)`
  :hover {
    color: ${props => props.theme.palette.error.main};
  }
`;

const StartButton = styled(IconButton)`
  :hover {
    color: ${props => props.theme.palette.success.main};
  }
`;

const TaskItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  border-radius: ${props => props.theme.shape.borderRadius};
  padding: 5px;
  :hover {
    background-color: ${props => (props.theme.isDark ? '#2E2E2E' : '#f9f9f9')};
  }
`;

const ErrorMessage = styled.div`
  color: ${props => props.theme.palette.error.main}
  border: 1px solid ${props => props.theme.palette.divider};
  border-radius: ${props => props.theme.shape.borderRadius};
`;

function BusinessProcess({ boMeta, boData, disabled }) {
  const { t, i18n } = useTranslation();
  const { palette } = useTheme();
  const data = useSelector(state => state[bpModule].data) || [];
  const loading = useSelector(state => state[bpModule].loading);
  const error = useSelector(state => state[bpModule].error);
  const dispatch = useDispatch();
  const startModal = useSimpleModal();
  const stopModal = useSimpleModal();
  // const stopTaskModal = useSimpleModal();

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    if (boData.id) {
      dispatch(loadData(boData, boMeta));
    }
    return () => dispatch(clearState());
  }, [dispatch, boMeta, boData]);

  if (error) {
    return <ErrorMessage className="p2 my2">{error}</ErrorMessage>;
  }

  if (loading) {
    return (
      <Card className="py2 mb2 px2 relative center" variant="outlined">
        <CircularProgress />
      </Card>
    );
  }

  if (data === null) return null;

  return (
    <div className="mb2">
      <Accordion variant="outlined">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          style={{ backgroundColor: 'transparent' }}
        >
          {t('businessProcess_title')}
        </AccordionSummary>
        <AccordionDetails>
          {data.map(item => (
            <div key={item.processId}>
              <div className="py1 flex items-center justify-between">
                <div>{item[`name${capitalize(i18n.language)}`]}</div>
                {!disabled && (
                  <Tooltip title={t('businessProcess_startProcess')}>
                    <StartButton onClick={() => startModal.open(item.processId)}>
                      <StartIcon />
                    </StartButton>
                  </Tooltip>
                )}
              </div>
              <div>
                {item.instances &&
                  item.instances.map(instance => (
                    <Accordion
                      key={instance.processInstanceId}
                      expanded={expanded === instance.processInstanceId}
                      onChange={handleChange(instance.processInstanceId)}
                      variant="outlined"
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <div className="fullwidth fs-12 flex items-center justify-between">
                          <div>
                            <div>
                              <span>{instance.startTime}</span>
                              {instance.endTime && (
                                <>
                                  <span className="mx1"> - </span>
                                  <span>{instance.endTime}</span>
                                </>
                              )}
                            </div>
                            <div
                              style={{
                                color: PROCESS_STATUS_COLOR[instance.status](palette)
                              }}
                            >
                              {t(`businessProcess_process${instance.status}`)}
                            </div>
                          </div>
                          {instance.status === 'IN_PROGRESS' && (
                            <Tooltip title={t('businessProcess_endProcess')}>
                              <StopButton
                                size="small"
                                onClick={e => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  stopModal.open(instance);
                                }}
                              >
                                <StopIcon />
                              </StopButton>
                            </Tooltip>
                          )}
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        {instance.tasks.map(task => (
                          <TaskItem key={task.id}>
                            <div>
                              {task.isUserTask ? (
                                <MuiLink
                                  className="pointer"
                                  target="_blank"
                                  href={`/task/${task.id}?taskType=${task.type}`}
                                  children={task[`name${capitalize(i18n.language)}`]}
                                />
                              ) : (
                                <div>{task[`name${capitalize(i18n.language)}`]}</div>
                              )}

                              <div>
                                <span>{task.startTime}</span>
                                {task.endTime && (
                                  <>
                                    <span className="mx1"> - </span>
                                    <span>{task.endTime}</span>
                                  </>
                                )}
                              </div>
                              <div
                                style={{ color: TASK_STATUS_COLOR[task.status](palette) }}
                              >
                                {t(`businessProcess_task${task.status}`)}
                              </div>
                            </div>
                            {/*{task.status === 'IN_PROGRESS' && (
                          <Tooltip title={t('businessProcess_endTask')}>
                            <StopButton
                              size="small"
                              onClick={() => stopTaskModal.open(task)}
                            >
                              <StopIcon />
                            </StopButton>
                          </Tooltip>
                        )}*/}
                          </TaskItem>
                        ))}
                      </AccordionDetails>
                    </Accordion>
                  ))}
              </div>
            </div>
          ))}
        </AccordionDetails>
      </Accordion>
      {startModal.isOpen && <StartModal {...startModal} boId={boData.id} />}
      {stopModal.isOpen && <StopProcessModal {...stopModal} />}
      {/*{stopTaskModal.isOpen && (
        <StopTaskModal {...stopTaskModal} boMeta={boMeta} boData={boData} />
      )}*/}
    </div>
  );
}

export default BusinessProcess;
