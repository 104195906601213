import React from 'react';
import RejectModal from './RejectModal';
import PositiveModal from './PositiveModal';
import NegativeModal from './NegativeModal';
import AcceptModal from './AcceptModal';
import DeclineModal from './DeclineModal';
import IntermediateModal from './IntermediateModal';

function OperationsModals({ data, close }) {
  return (
    <>
      {data.name === 'accept' && <AcceptModal id={data.id} onClose={close} />}
      {data.name === 'reject' && <RejectModal id={data.id} onClose={close} />}
      {data.name === 'positive' && <PositiveModal id={data.id} onClose={close} />}
      {data.name === 'negative' && <NegativeModal id={data.id} onClose={close} />}
      {data.name === 'revision' && <DeclineModal id={data.id} onClose={close} />}
      {data.name === 'intermediate' && <IntermediateModal id={data.id} onClose={close} />}
    </>
  );
}

export default OperationsModals;
