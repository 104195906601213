import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import withTranslation from '../../_hoc/withTranslation';
import Typography from '@material-ui/core/Typography';
import Access from '../../Access';

const Panel = withStyles({
  root: {
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      margin: 'auto'
    }
  },
  expanded: {}
})(Accordion);

const Summary = withStyles({
  root: {
    paddingLeft: 0,
    maxHeight: '43px',
    '&$expanded': {
      maxHeight: '43px'
    },
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)'
    }
  },
  content: {
    '&$expanded': {
      margin: '12px 0'
    }
  },
  expanded: {}
})(AccordionSummary);

const Details = withStyles({
  root: {
    padding: 0,
    '& a': {
      width: '100%'
    }
  }
})(AccordionDetails);

const SideBarPanel = ({ items = [], icon, translate, t, onClick, showSideBar }) => {
  const { pathname } = useLocation();
  const [expanded, setExpanded] = useState(items.some(item => item.path === pathname));

  useEffect(() => {
    if (!showSideBar && expanded) {
      setExpanded(false);
    }
    // eslint-disable-next-line
  }, [showSideBar]);

  return (
    <Panel
      expanded={expanded}
      onChange={(e, value) => {
        onClick(isOpen => {
          if (!isOpen) {
            setExpanded(true);
          } else {
            setExpanded(value);
          }
          return true;
        });
      }}
    >
      <Summary expandIcon={<ExpandMoreIcon />}>
        <ListItem  style={{ paddingLeft: 20 }}>
          <ListItemIcon>{icon}</ListItemIcon>
          <Typography variant="body2" color="textPrimary">
            {t(translate)}
          </Typography>
        </ListItem>
      </Summary>
      {items.map(i => (
        <Access ps={i.permissions} key={i.path} accessible>
          <Details>
            <Link to={i.path} onClick={() => onClick(false)}>
              <ListItem button selected={window.location.pathname === i.path}>
                <ListItemIcon
                  style={{ marginLeft: 15 }}
                  children={i.icon || <span className="display-none" />}
                />
                <Typography variant="body2" color="textPrimary">
                  {t(i.translate)}
                </Typography>
              </ListItem>
            </Link>
          </Details>
        </Access>
      ))}
    </Panel>
  );
};

export default withTranslation(SideBarPanel);
