import React from 'react';
import { Select, FormControl, IconButton, MenuItem } from '@material-ui/core';
import useDict from '../../_hooks/useDict';
import { compose } from 'redux';
import withTranslation from '../../_hoc/withTranslation';
import useColumnFilter from '../useColumnFilter';
import CloseIcon from '@material-ui/icons/Close';

function FilterSelect({
  name,
  t,
  tReady,
  lang,
  dictCode,
  dictFilter,
  options,
  queryIndex = '',
  fullWidth = true,
  allowClear = true,
  ...rest
}) {
  const { value, setFilter } = useColumnFilter(name, queryIndex);
  let dict = useDict(dictCode);
  if (dictFilter && typeof dictFilter === 'function') {
    dict = dict.filter(dictFilter);
  }
  const option = (options || dict).find(item => item.code === value) || {};

  const clearFiled = value &&
    allowClear && {
      IconComponent: () => (
        <IconButton
          onClick={() => setFilter(null)}
          className="show-on-hover"
          size="small"
          style={{ marginRight: 5 }}
          children={<CloseIcon fontSize="small" />}
        />
      )
    };

  return (
    <FormControl fullWidth={fullWidth} margin="none" className="left-align">
      <Select
        MenuProps={{ MenuListProps: { style: { maxWidth: 550, minWidth: 250 } } }}
        value={value || ''}
        displayEmpty
        onChange={e => setFilter(e.target.value)}
        SelectDisplayProps={{
          style: {
            paddingRight: 0,
            paddingLeft: 8,
            backgroundColor: option.backgroundColor
          }
        }}
        {...clearFiled}
        {...rest}
      >
        {rest.placeholder && (
          <MenuItem value="" disabled>
            <span style={{ opacity: 0.4 }}>{rest.placeholder}</span>
          </MenuItem>
        )}
        {(options || dict).map(
          item =>
            item && (
              <MenuItem
                style={{ backgroundColor: item.backgroundColor }}
                key={item.code}
                value={item.code}
              >
                {item[`${lang}_name`]}
              </MenuItem>
            )
        )}
      </Select>
    </FormControl>
  );
}

export default compose(withTranslation)(FilterSelect);
