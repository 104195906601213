import { addTranslation } from '../../_helpers/Translate';

addTranslation({
  ru: {
    contingentPage_title: 'Контингент ДДО',
    contingentPage_candidate: 'Претенденты',
    contingentPage_enrolled: 'Зачисленные',
    contingentPage_exited: 'Выбывшие',
    contingentPage_columnDirectionNumber: 'Номер направления',
    contingentPage_columnChild_iin: 'ИИН ребенка',
    contingentPage_columnFullName: 'ФИО ребенка',
    contingentPage_columnAgeGroup: 'Возрастная группа',
    contingentPage_columnQueuedType: 'Тип очереди',
    contingentPage_columnNameGroup: 'Наименование группы',
    contingentPage_columnDirectionDate: 'Срок направления',
    contingentPage_columnStatusDirection: 'Статус направления',
    contingentPage_columnDateNumberBulletin: 'Дата и номер бюллетени',
    contingentPage_columnBin: 'БИН',
    contingentPage_columnLegalLocality: 'Населенный пункт',
    contingentPage_columnApplicationNumberEnrollment: 'Номер заявления по зачислению',
    contingentPage_columnPrivilegeType: 'Вид льготы',
    contingentPage_columnOrderNum: 'Номер приказа',
    contingentPage_columnOrderDate: 'Дата приказа',
    contingentPage_columnBeginAcademicYear: 'Учебный год',
    contingentPage_columnLanguage: 'Язык обучения',
    contingentPage_columnEnrollmentDate: 'Дата зачисления',
    contingentPage_columnRetirementDate: 'Дата выбытия',
    contingentPage_columnOf: 'от',
    contingentPage_columnAction: 'Действие',
    contingentPage_columnDelete: 'Удалить',
    contingentPage_candidateErrorLoadTable: 'Не удалось загрузить Претенденты',
    contingentPage_enrolledErrorLoadTable: 'Не удалось загрузить Зачисленные',
    contingentPage_exitedErrorLoadTable: 'Не удалось загрузить Выбывшие',
    contingentPage_columnBinOrg: 'БИН организации',
    contingentPage_reportTitle: 'Табель учета посещаемости ребенка',
    contingentPage_tableFor: 'табель для',
    contingentPage_reportModalTitle: 'Основание отсутствия',
    contingentPage_reportCause: 'Причина',
    contingentPage_date: 'Дата',
    contingentPage_startDate: 'Дата начала',
    contingentPage_completionDate: 'Дата завершения',
    contingentPage_saveBtn: 'Cохранить',
    contingentPage_reference: 'Справка',
    contingentPage_export_btn: 'Экспортировать',
    contingentPage_export_select_date: 'Выберите период для экспорта',
    contingentPage_export_start_date: 'Дата от',
    contingentPage_export_end_date: 'Дата до',
    contingentPage_reportExportError: 'Не удалось экспортировать табель',
    contingentPage_reportErrorLoadTable: 'Не удалось загрузить табель',
    contingentPage_requiredField: 'Обязательное для заполнения',
    contingentPage_group: 'Группа',
    contingentSection_groupId: 'ID группа',
    contingentPage_causeAddSuccess: 'Успешно сохранено',
    contingentPage_causeAddError: 'Ошибка при сохранение',
    contingentPage_isShowDataTable: 'Для отображения данных необходимо заполнить населенный пункт',
    contingentPage_noGroup: 'Нет группа',
    contingentPage_noOrg: 'Нет организация',
    contingentPage_arrivalDate: 'Дата прихода',
    contingentPage_scanTime: 'Время сканирования QR кода:',
  },
  kk: {
    contingentPage_title: 'МДҰ контингенті',
    contingentPage_candidate: 'Үміткерлер',
    contingentPage_enrolled: 'Тіркелгендер',
    contingentPage_exited: 'Шығып кеткендер',
    contingentPage_columnDirectionNumber: 'Жолдама нөмірі',
    contingentPage_columnChild_iin: 'Баланың ЖСН',
    contingentPage_columnFullName: 'Баланың аты-жөні',
    contingentPage_columnAgeGroup: 'Жас тобы',
    contingentPage_columnQueuedType: 'Кезек түрі',
    contingentPage_columnNameGroup: 'Топ аты',
    contingentPage_columnDirectionDate: 'Жолдама мерзімі',
    contingentPage_columnStatusDirection: 'Жолдама мәртебесі',
    contingentPage_columnDateNumberBulletin: 'Бюллетеньдің күні мен нөмірі',
    contingentPage_columnBin: 'БИН',
    contingentPage_columnLegalLocality: 'Елді мекен',
    contingentPage_columnApplicationNumberEnrollment: 'Тіркелу үшін өтініш нөмірі',
    contingentPage_columnPrivilegeType: 'Жеңілдік түрі',
    contingentPage_columnOrderNum: 'Бұйрық нөмірі',
    contingentPage_columnOrderDate: 'Бұйрық күні',
    contingentPage_columnBeginAcademicYear: 'Оқу жылы',
    contingentPage_columnLanguage: 'Оқыту тілі',
    contingentPage_columnEnrollmentDate: 'Тіркелу күні',
    contingentPage_columnRetirementDate: 'Шығу күні',
    contingentPage_columnOf: 'бастап',
    contingentPage_columnAction: 'Әрекет',
    contingentPage_columnDelete: 'Жою',
    contingentPage_candidateErrorLoadTable: 'Өтініш берушіні жүктеу мүмкін болмады',
    contingentPage_enrolledErrorLoadTable: 'Тіркелгендерді жүктеу мүмкін болмады',
    contingentPage_exitedErrorLoadTable: 'Оқудан шыққандарды жүктеу мүмкін болмады',
    contingentPage_columnBinOrg: 'Ұйымның БСН',
    contingentPage_reportTitle: 'Баланың қатысуын есепке алу табелі',
    contingentPage_tableFor: 'кесте',
    contingentPage_reportModalTitle: 'Болмау негізі',
    contingentPage_reportCause: 'Себебі',
    contingentPage_date: 'Күні',
    contingentPage_startDate: 'Басталу күні',
    contingentPage_completionDate: 'Аяқталу күні',
    contingentPage_saveBtn: 'Сақтау',
    contingentPage_reference: 'Анықтама',
    contingentPage_export_btn: 'Экспорттау',
    contingentPage_export_select_date: 'Экспорттауға арналған кезеңді таңдаңыз',
    contingentPage_export_start_date: 'Кезеңнің басы',
    contingentPage_export_end_date: 'Кезеңнің соңы',
    contingentPage_reportExportError: 'Табельді экспорттау мүмкін болмады',
    contingentPage_reportErrorLoadTable: 'Табельді жүктеу мүмкін болмады',
    contingentPage_requiredField: 'Толтыру үшін міндетті',
    contingentPage_group: 'Топ',
    contingentSection_groupId: 'ID топ',
    contingentPage_causeAddSuccess: 'Cәтті сақталды',
    contingentPage_causeAddError: 'Сақтау кезінде қате',
    contingentPage_isShowDataTable: 'Деректерді көрсету үшін елді мекенді таңдау керек',
    contingentPage_noGroup: 'Топтар жоқ',
    contingentPage_noOrg: 'Ұйымдар жоқ',
    contingentPage_arrivalDate: 'Келу күні',
    contingentPage_scanTime: 'QR кодын сканерлеу уақыты:',
  }
});
