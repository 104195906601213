import { createReducer } from 'redux-starter-kit';
import { handleError } from '../../utils/handleError';
import { DirectionApi } from '../../_helpers/service';
import Dict from '../../utils/Dict';
import i18next from 'i18next';

/**
 * Constants
 */

export const kezekDirectionTableModule = 'kezekDirectionTable';
const LOADING_TABLE = `${kezekDirectionTableModule}/LOADING_TABLE`;
const TABLE_DATA = `${kezekDirectionTableModule}/TABLE_DATA`;
const CLEAR_STATE = `${kezekDirectionTableModule}/CLEAR_STATE`;

/**
 * Reducer
 */

const initialState = {
  loadingTable: false,
  tableData: {
    content: [],
    totalElements: 0
  }
};

export default createReducer(initialState, {
  [LOADING_TABLE]: (state, action) => {
    state.loadingTable = action.payload;
  },
  [TABLE_DATA]: (state, action) => {
    const filter = action.filter || {};
    if (filter.pageNumber && filter.pageNumber !== 1) {
      state.tableData.content = [...state.tableData.content, ...action.payload.content];
    } else {
      state.tableData = action.payload;
    }
  },
  [CLEAR_STATE]: () => initialState
});

/**
 * Actions
 */

export const clearState = () => ({ type: CLEAR_STATE });

export const loadData = filter => async dispatch => {
  try {
    dispatch({ type: LOADING_TABLE, payload: true });
    let { data } = await DirectionApi.loadData(handleFilter(filter));
    if (data.status === 'SUCCESS') {
      const dictKato = await Dict.itemsObject('60');
      const dictAgeGroup = await Dict.itemsObject('gu_age_group');
      const dictQueuedType = await Dict.itemsObject('gu_queued_type');
      const dictTypeBulletin = await Dict.itemsObject('gu_type_bulletin');
      const dictStatusWeb = await Dict.itemsObject('gu_bulletin_status_web');
      const dictStatusDirection = await Dict.itemsObject('gu_status_direction');
      for (let row of data.result.content) {
        row.serviceProviderLocation = dictKato[row.serviceProviderLocation] || {};
        row.ageGroupName = dictAgeGroup[row.ageGroup] || {};
        row.queuedTypeName = dictQueuedType[row.queuedType] || {};
        row.bulletinTypeName = dictTypeBulletin[row.typeBulletin] || {};
        row.statusWebName = dictStatusWeb[row.statusWeb] || {};
        row.statusDirectionName = dictStatusDirection[row.statusDirection] || {};
        row.childName = `${row.childSurname || ''} ${row.childName ||
          ''} ${row.childMiddlename || ''}`;
      }
      dispatch({ type: TABLE_DATA, payload: data.result, filter });
    } else {
      handleError(data, i18next.t('kezekBulletinDucks_errorLoadTable'));
    }
  } catch (error) {
    handleError(error, i18next.t('kezekBulletinDucks_errorLoadTable'));
  } finally {
    dispatch({ type: LOADING_TABLE, payload: false });
  }
};

function handleFilter(filter) {
  const filterData = { ...filter };
  if (filterData.time24Direction && filterData.directionDate) {
    filterData.betweenDates = [
      {
        field: 'time24Direction',
        from: filterData.time24Direction,
        to: filterData.time24Direction.replace('T00:00:00', 'T23:59:00'),
      },
      {
        field: 'directionDate',
        from: filterData.directionDate,
        to: filterData.directionDate.replace('T00:00:00', 'T23:59:00'),
      }
    ];
    delete filterData.time24Direction;
    delete filterData.directionDate;
  } else if (filterData.time24Direction) {
    filterData.betweenDate = {
      field: 'time24Direction',
      from: filterData.time24Direction,
      to: filterData.time24Direction.replace('T00:00:00', 'T23:59:00'),
    };
    delete filterData.time24Direction;
  } else if (filterData.directionDate) {
    filterData.betweenDate = {
      field: 'directionDate',
      from: filterData.directionDate,
      to: filterData.directionDate.replace('T00:00:00', 'T23:59:00'),
    };
    delete filterData.directionDate;
  }
  return JSON.stringify(filterData);
}