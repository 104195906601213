export const statusColor = {
  FAILED: 'rgba(255,0,0,0.15)',
  NEGATIVE: 'rgba(255,0,0,0.15)',
  NO_RESOLUTION: 'rgba(0,0,0,0)',
  POSITIVE: 'rgba(0,255,0,0.15)',
  HARD_FAILED: '#da8f89',
  HARD_NEGATIVE: '#da8f89',
  HARD_NO_RESOLUTION: '#a6a9a8',
  HARD_POSITIVE: '#82c384'
};
