import React from 'react';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/styles';
import { ThemeWrapper, theme } from './ThemeStyle';
import { ThemeProvider as StyledProvider } from 'styled-components';

export default function ThemeProvider({ children }) {
  return (
    <MuiThemeProvider theme={theme}>
      <StyledProvider theme={theme}>
        <ThemeWrapper>{children}</ThemeWrapper>
      </StyledProvider>
    </MuiThemeProvider>
  );
}
