import React, { useState } from 'react';
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs';
import Paper from '@material-ui/core/Paper';
import './InstructionTranslate';
import { useTranslation } from 'react-i18next';
import useDict from '../../components/_hooks/useDict';
import { Card, Link, withStyles } from '@material-ui/core';
import PlayIcon from '@material-ui/icons/PlayCircleFilled';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Dialog from '@material-ui/core/Dialog';
import MuiAccordion from '@material-ui/core/Accordion';

const Accordion = withStyles(({ palette, shape }) => ({
  root: {
    border: '1px solid ' + palette.divider,
    borderRadius: shape.borderRadius,
    boxShadow: 'none',
    padding: '3px 0px',
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      margin: 'auto'
    }
  },
  expanded: {}
}))(MuiAccordion);

export default function InstructionPage() {
  const { t, i18n } = useTranslation();
  const breadCrumbItems = [
    { name: t('breadcrumbs_home'), href: '/' },
    { name: t('breadcrumbs_instruction'), href: '/instruction' }
  ];
  const services = useDict('gu_services');
  const [selected, setSelected] = useState();
  const mainVideo = {
    code: 'MAIN_VIDEO',
    ru_name: 'Ознакомление с системой автоматизации государственных услуг МОН РК',
    kk_name: 'ҚР БҒМ мемлекеттік қызметтерін автоматтандыру жүйесімен танысу'
  };

  return (
    <>
      <BreadCrumbs items={breadCrumbItems} />
      <Paper className="pb3 pt1 px3">
        <h3>{t('instruction_title')}</h3>
        <div>
          <Card
            key={mainVideo.code}
            className="my1 p2 pointer flex items-center fw-6"
            variant="outlined"
            onClick={() => setSelected(mainVideo)}
          >
            <PlayIcon color="action" className="mr2" />
            <Link color="textPrimary">{mainVideo[`${i18n.language}_name`]}</Link>
          </Card>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <div className="px1 fw-6">{t('instruction_services')}</div>
            </AccordionSummary>
            <AccordionDetails>
              <div>
                {services.map(
                  item =>
                    item.has_video_instruction === 'true' && (
                      <Card
                        key={item.code}
                        className="my1 p2 pointer flex items-center"
                        variant="outlined"
                        onClick={() => setSelected(item)}
                      >
                        <PlayIcon color="action" className="mr2" />
                        <Link color="textPrimary">{item[`${i18n.language}_name`]}</Link>
                      </Card>
                    )
                )}
              </div>
            </AccordionDetails>
          </Accordion>
          {selected && (
            <Dialog open={true} onClose={() => setSelected(null)} maxWidth="lg">
              <div className="p2 fs-16 fw-6">{selected[`${i18n.language}_name`]}</div>
              <video key={i18n.language} width="100%" controls="controls">
                <source
                  src={`/server/folder/media/${selected.code.toUpperCase()}_${i18n.language.toUpperCase()}.mp4`}
                  type="video/mp4"
                />
              </video>
            </Dialog>
          )}
        </div>
      </Paper>
    </>
  );
}
