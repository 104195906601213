import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import SaveIcon from '@material-ui/icons/Save';
import Button from '../../_ui/Button/Button';
import withTranslation from '../../components/_hoc/withTranslation';
import { kezekPassportModule } from './KezekPassportDucks';
import { ButtonsWrapper } from './KezekPassportStyle';
import { useTranslation } from 'react-i18next';

const SaveButton = ({ loadingSave, onClick }) => {
  const { t } = useTranslation();
  return (
    <ButtonsWrapper>
      <Button
        type="button"
        size="small"
        loading={loadingSave}
        icon={<SaveIcon fontSize="small" className="mr1" />}
        text={t('serviceProvider_saveForm')}
        onClick={onClick}
      />
    </ButtonsWrapper>
  );
};

export default compose(
  withTranslation,
  connect(state => ({
    loadingSave: state[kezekPassportModule].loadingSave
  }))
)(SaveButton);
