import React from 'react';
import { P_STATUS } from '../../../_helpers/Constants';
import { Color } from '../../../components/ThemeProvider/ThemeStyle';
import CancelIcon from '@material-ui/icons/Cancel';
import DoneIcon from '@material-ui/icons/Done';
import SaveIcon from '@material-ui/icons/Save';
import { compose } from 'redux';
import withTranslation from '../../../components/_hoc/withTranslation';
import { connect } from 'react-redux';
import { complete, saveStatement, statementModule } from '../StatementDucks';
import { ButtonsWrapper } from '../StatementStyle';
import Button from '../../../_ui/Button/Button';
import Notice from '../../../utils/Notice';
import { getFormValues } from '../../../passport/Passport';
import Access from '../../../components/Access';
import permissions from '../../../_helpers/permissions';

/**
 * @return {null}
 */
function OperationsButtons({
  t,
  info,
  modal,
  complete,
  saveStatement,
  loadingInfo,
  loadingAccept,
  loadingSave,
  loadingPositive,
  loadingNegative,
  loadingDecline,
  loadingComplete
}) {
  const loading =
    loadingInfo ||
    loadingAccept ||
    loadingSave ||
    loadingPositive ||
    loadingNegative ||
    loadingDecline ||
    loadingComplete;
  const status = info.requestForm.processingStatus;
  const openModal = name => modal.open({ name, id: info.requestForm.id });
  const handleCommit = async type => {
    let values = await getFormValues();
    if (values) {
      openModal(type);
    } else {
      Notice.warning(t('statement_beforeSaveData'));
    }
  };

  if ([P_STATUS.COMPLETED, P_STATUS.FAILED].includes(status)) {
    return null;
  }

  if (info.buttonRules.hideAllButtons) {
    return null;
  }

  return (
    <ButtonsWrapper>
      {status === P_STATUS.SUBMITTED && (
        <>
          <Button
            icon={<DoneIcon fontSize="small" className="mr1" />}
            loading={loadingAccept}
            disabled={loading}
            size="small"
            style={{ backgroundColor: Color.bgColorSuccess }}
            text={t('statement_accept')}
            onClick={() => openModal('accept')}
          />
          {/*<Button
            icon={<CancelIcon fontSize="small" className="mr1" />}
            loading={loadingReject}
            size="small"
            color="secondary"
            text={t('statement_reject')}
            onClick={() => openModal('reject')}
          />*/}
        </>
      )}

      {info.buttonRules.save && (
        <Button
          icon={<SaveIcon fontSize="small" className="mr1" />}
          loading={loadingSave}
          disabled={loading}
          size="small"
          text={t('statement_saveForm')}
          onClick={() => {
            getFormValues().then(values => {
              if (values) saveStatement(info, values);
            });
          }}
        />
      )}
      {info.buttonRules.intermediate && (
        <Button
          icon={<DoneIcon fontSize="small" className="mr1" />}
          disabled={loading}
          loading={loadingAccept}
          size="small"
          text={t('statement_commitIntermediate')}
          onClick={() => openModal('intermediate')}
        />
      )}
      {status === P_STATUS.IN_PROGRESS && (
        <>
          {info.buttonRules.negative && (
            <Button
              icon={<CancelIcon fontSize="small" className="mr1" />}
              loading={loadingNegative}
              disabled={loading}
              text={t('statement_commitNegative')}
              size="small"
              color="secondary"
              onClick={() => handleCommit('negative')}
            />
          )}
          {info.buttonRules.positive && (
            <Button
              icon={<DoneIcon fontSize="small" className="mr1" />}
              loading={loadingPositive}
              disabled={loading}
              size="small"
              style={{ backgroundColor: Color.bgColorSuccess }}
              text={t('statement_commitPositive')}
              onClick={() => handleCommit('positive')}
            />
          )}
        </>
      )}
      {status === P_STATUS.READY_FOR_SIGN && (
        <>
          <Access ps={permissions.CAN_SIGN_REQUEST_FORM}>
            <Button
              icon={<DoneIcon fontSize="small" className="mr1" />}
              loading={loadingComplete}
              disabled={loading}
              size="small"
              style={{ backgroundColor: Color.bgColorSuccess }}
              text={t('statement_completeSign')}
              onClick={() => complete(info.requestForm.id)}
            />
          </Access>
          <Button
            icon={<CancelIcon fontSize="small" className="mr1" />}
            loading={loadingDecline}
            disabled={loading}
            size="small"
            color="secondary"
            text={t('statement_declineTitle')}
            onClick={() => openModal('revision')}
          />
        </>
      )}
    </ButtonsWrapper>
  );
}

export default compose(
  withTranslation,
  connect(
    state => ({
      loadingInfo: state[statementModule].loadingInfo,
      loadingAccept: state[statementModule].loadingAccept,
      loadingReject: state[statementModule].loadingReject,
      loadingSave: state[statementModule].loadingSave,
      loadingPositive: state[statementModule].loadingPositive,
      loadingNegative: state[statementModule].loadingNegative,
      loadingDecline: state[statementModule].loadingDecline,
      loadingComplete: state[statementModule].loadingComplete,
      info: state[statementModule].info
    }),
    { saveStatement, complete }
  )
)(OperationsButtons);
