import React, { useEffect, useState } from 'react';
import { TextField, MenuItem } from '@material-ui/core';
import { statusColumns } from '../statusColumns';
import { renderHorizontalCharts } from './renderHorizontalCharts';
import { renderVerticalCharts } from './renderVerticalCharts';
import { useTranslation } from 'react-i18next';

export default function MonitoringCharts({ data }) {
  const { t, i18n } = useTranslation();
  const [status, setStatus] = useState(null);
  const [service, setService] = useState(null);
  const menuItems = data.reduce((result = [], item) => {
    if (result.some(rItem => rItem.service.code === item.service.code)) {
      return result;
    } else {
      result.push(item);
      return result;
    }
  }, []);

  useEffect(() => {
    renderVerticalCharts(t, i18n.language, [...data], service);
  }, [t, i18n.language, data, service]);

  useEffect(() => {
    renderHorizontalCharts(t, i18n.language, [...data], status);
  }, [t, i18n.language, data, status]);

  return (
    <div className="clearfix">
      <div className="col col-6 pr2">
        <div className="center p2 card">
          <div className="mb1 bold">{t('monitoring_chartsStatus')}</div>
          <TextField
            select
            fullWidth
            value={service}
            onChange={e => setService(e.target.value || null)}
            placeholder={t('monitoring_status')}
            SelectProps={{ MenuProps: { style: { maxWidth: 500 } } }}
          >
            {menuItems.map((item, index) => (
              <MenuItem key={index} value={item.service.code}>
                {item.service[`${i18n.language}_name`]}
              </MenuItem>
            ))}
          </TextField>
          <div id="vertical" className="mt2" />
        </div>
      </div>
      <div className="col col-6">
        <div className="center p2 card">
          <div className="mb1 bold">{t('monitoring_chartsService')}</div>
          <TextField
            select
            fullWidth
            value={status}
            onChange={e => setStatus(e.target.value || null)}
            placeholder={t('monitoring_service')}
            SelectProps={{ MenuProps: { style: { maxWidth: 500 } } }}
          >
            {Object.keys(statusColumns).map(status => (
              <MenuItem key={status} value={status}>
                {t(`monitoring_column${status}`)}
              </MenuItem>
            ))}
          </TextField>
          <div id="horizontal" className="mt2" />
        </div>
      </div>
    </div>
  );
}
