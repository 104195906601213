import moment from 'moment';

export const handbookColumns = ({ t }) => {
  return [
    {
      Header: '№',
      accessor: 'rowNumber',
      width: 60,
      filterable: false
    },
    {
      Header: t('handbookPage_columnCode'),
      accessor: 'code'
    },
    {
      Header: t('handbookPage_columnDescription'),
      accessor: 'description'
    },
    {
      Header: t('handbookPage_columnKkName'),
      accessor: 'kkName'
    },
    {
      Header: t('handbookPage_columnRuName'),
      accessor: 'ruName'
    },
    {
      Header: t('handbookPage_columnLastUpdated'),
      accessor: 'lastUpdated',
      Cell: row => moment(row.original.lastUpdated).format('DD.MM.YYYY HH:mm'),
      width: 250,
      filterable: false
    }
  ];
};
