import { addTranslation } from '../../_helpers/Translate';

addTranslation({
  ru: {
    classListPage_edit: 'Редактировать данные класса',
    classListPage_add: 'Добавить',
    classListPage_enrollmentDate: 'Дата зачисления',
    classListPage_childIin: 'ИИН школьника',
    classListPage_fullName: 'ФИО',
    classListPage_birthday: 'Дата рождения',
    classListPage_date: 'Дата зачисления',
    classListPage_columnLanguage: 'Язык обучения',
    classListPage_modalAddChild: 'Добавить ребенок',
    classListPage_modalSelectChild: 'Выберите ребенок',
    classListPage_requiredField: 'Обязательное для заполнения',
    classListPage_errorLoadTable: 'Не удалось загрузить класс',
    classListPage_errorLoadChild: 'Не удалось загрузить список детей',
    classListPage_errorSaveForm: 'Не удалось сохранить данные',
    classListPage_saveFormSuccess: 'Данные успешно сохранены',
  },
  kk: {
    classListPage_edit: 'Сынып деректерін өзгерту',
    classListPage_add: 'Қосу',
    classListPage_enrollmentDate: 'Тіркелу күні',
    classListPage_childIin: 'Оқушы ЖСН-і',
    classListPage_fullName: 'Аты-жөні',
    classListPage_birthday: 'Туған күні',
    classListPage_date: 'Тіркелу күні',
    classListPage_columnLanguage: 'Оқыту тілі',
    classListPage_modalAddChild: 'Оқушы қосу',
    classListPage_modalSelectChild: 'Оқушыны таңдаңыз',
    classListPage_requiredField: 'Міндетті өріс',
    classListPage_errorLoadTable: 'Сынып жүкелмеді',
    classListPage_errorLoadChild: 'Оқушылар тізімі жүктелмеді',
    classListPage_errorSaveForm: 'Деректерді сақтау мүмкін болмады',
    classListPage_saveFormSuccess: 'Деректер сәтті сақталды',
  }
});
