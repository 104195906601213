import { createReducer } from 'redux-starter-kit';
import { handleError } from '../../utils/handleError';
import i18next from 'i18next';
import { NCALayerSign } from '../../components/NCALayer/NCALayerSign';
import { ColleaguesApi } from '../../_helpers/service';
import Notice from '../../utils/Notice';
import Dict from '../../utils/Dict';
import { loginModule } from '../LoginPage/LoginDucks';
import {ROLES} from '../../_helpers/Constants'
import moment from 'moment';

/**
 * Constants
 */

export const colleaguesModule = 'colleagues';
const LOADING = `${colleaguesModule}/LOADING`;
const LOADING_TABLE = `${colleaguesModule}/LOADING_TABLE`;
const LOADING_DIMISSED = `${colleaguesModule}/LOADING_DIMISSED`;
const CLEAR_STATE = `${colleaguesModule}/CLEAR_STATE`;
const SET_EMPLOYEES = `${colleaguesModule}/SET_EMPLOYEES`;
const SET_DISMISSED = `${colleaguesModule}/SET_DISMISSED`;
const SET_META = `${colleaguesModule}/SET_META`;
const SET_GROUP = `${colleaguesModule}/SET_GROUP`;
const SET_DATA = `${colleaguesModule}/SET_DATA`;
const SET_POSITION = `${colleaguesModule}/SET_POSITION`;
const LOADING_SAVE = `${colleaguesModule}/LOADING_SAVE`;
const SET_REPORT = `${colleaguesModule}/SET_REPORT`;
const SET_CAUSE = `${colleaguesModule}/SET_CAUSE`;
const SET_ORGANIZATIONSE = `${colleaguesModule}/SET_ORGANIZATIONSE`;
const LOADING_EXPORT_DATA = `${colleaguesModule}/LOADING_EXPORT_DATA`;

/**
 * Reducer
 */

const initialState = {
  loading: false,
  loadingTable: false,
  loadingDismissed: false,
  loadingSave: false,
  loadingExport: false,
  employees: {
    content: [],
    totalElements: 0,
  },
  dismissed: {
    content: [],
    totalElements: 0,
  },
  report: {
    content: [],
    totalElements: 0
  },
  meta: {},
  data: {},
  group: [],
  position: [],
  cause: [],
  organizations: []
};

export default createReducer(initialState, {
  [SET_EMPLOYEES]: (state, action) => {
    const filter = action.filter || {};
    if (filter.pageNumber && filter.pageNumber !== 1) {
      state.employees.content = [...state.employees.content, ...action.payload.content];
    } else {
      state.employees = action.payload;
    }
  },
  [SET_DISMISSED]: (state, action) => {
    const filter = action.filter || {};
    if (filter.pageNumber && filter.pageNumber !== 1) {
      state.dismissed.content = [...state.dismissed.content, ...action.payload.content];
    } else {
      state.dismissed = action.payload;
    }
  },
  [SET_META]: (state, action) => {
    state.meta = action.payload;
  },
  [SET_GROUP]: (state, action) => {
    state.group = action.payload;
  },
  [SET_DATA]: (state, action) => {
    state.data = action.payload;
  },
  [SET_POSITION]: (state, action) => {
    state.position = action.payload;
  },
  [LOADING]: (state, action) => {
    state.loading = action.payload;
  },
  [LOADING_TABLE]: (state, action) => {
    state.loadingTable = action.payload;
  },
  [LOADING_DIMISSED]: (state, action) => {
    state.loadingDismissed = action.payload;
  },
  [LOADING_SAVE]: (state, action) => {
    state.loadingSave = action.payload;
  },
  [SET_REPORT]: (state, action) => {
    const filter = action.filter || {};
    if (filter.pageNumber && filter.pageNumber !== 1) {
      state.report.content = [...state.report.content, ...action.payload.content];
    } else {
      state.report = action.payload;
    }
  },
  [SET_CAUSE]: (state, action) => {
    state.cause = action.payload;
  },
  [SET_ORGANIZATIONSE]: (state, action) => {
    state.organizations = action.payload.map((item) => { return {...item, code: item.id } });
  },
  [LOADING_EXPORT_DATA]: (state, action) => {
    state.loadingExport = action.payload;
  },
  [CLEAR_STATE]: () => initialState
});

/**
 * Actions
 */

export const clearState = () => ({ type: CLEAR_STATE });

export const loadEmployees = ({filter, type}) => async (dispatch, getState) => {
  try {
    dispatch({ type: LOADING_TABLE, payload: true });
    filter['staffStatus'] = type;
    const {user} = getState()[loginModule];
    let dictStaffType = null;
    const {data} = await ColleaguesApi.getEmployees(JSON.stringify(filter));
    if (data.status === 'SUCCESS') {
      if (user.organization.orgType === '01') {
        dictStaffType = await Dict.itemsObject('ddo_staff_type');
      } else if (user.organization.orgType === '02') {
        dictStaffType = await Dict.itemsObject('school_staff_type');
      } else if (user.organization.orgType === '10') {
        dictStaffType = await Dict.itemsObject('section_staff_type');
      } else if (user.organization.orgType === '12') {
        dictStaffType = await Dict.itemsObject('akimat_staff_type');
      } else {
        dictStaffType = await Dict.itemsObject('gu_staff_type');
      }
      const dictStaffStatus = await Dict.itemsObject('gu_staff_status');

      for (let row of data.result.content) {
        row.staffTypeName = dictStaffType[row.staffType] || {};
        row.staffStatus = dictStaffStatus[row.staffStatus] || {};
      }
    }
    dispatch({ type: SET_EMPLOYEES, payload: data.result, filter });
  } catch (error) {
    handleError(error, i18next.t('colleagues_employeesErrorLoad'));
  } finally {
    dispatch({ type: LOADING_TABLE, payload: false });
  }
};

export const loadDismissed = ({filter, type}) => async (dispatch, getState) => {
    try {
      dispatch({ type: LOADING_TABLE, payload: true });
      filter['staffStatus'] = type 
      const {user} = getState()[loginModule];
      let dictStaffType = null;
      const {data} = await ColleaguesApi.getEmployees(JSON.stringify(filter));
      if (data.status === 'SUCCESS') {
        if (user.organization.orgType === '01') {
          dictStaffType = await Dict.itemsObject('ddo_staff_type');
        } else if (user.organization.orgType === '02') {
          dictStaffType = await Dict.itemsObject('school_staff_type');
        } else if (user.organization.orgType === '10') {
          dictStaffType = await Dict.itemsObject('section_staff_type');
        } else if (user.organization.orgType === '12') {
          dictStaffType = await Dict.itemsObject('akimat_staff_type');
        } else {
          dictStaffType = await Dict.itemsObject('gu_staff_type');
        }
        
        const dictStaffStatus = await Dict.itemsObject('gu_staff_status');
  
        for (let row of data.result.content) {
          row.staffTypeName = dictStaffType[row.staffType] || {};
          row.staffStatus = dictStaffStatus[row.staffStatus] || {};
        }
      }
      dispatch({ type: SET_DISMISSED, payload: data.result, filter });
    } catch (error) {
      handleError(error, i18next.t('colleagues_dismissedErrorLoad'));
    } finally {
      dispatch({ type: LOADING_TABLE, payload: false });
    }
  };

export const addEmployee = ({values, close, staffIin, history, id, positionCode  }) => async dispatch => {
    try {
      dispatch({ type: LOADING_SAVE, payload: true })
      values['staffChanges'] = [...values['form_staff']];
      delete values['form_staff']
      let data = null
      if (id) {
        data = await ColleaguesApi.updateEmployee({
          iin: staffIin,
          staffType: positionCode,
          id: id,
          ...values
        });
      } else {
        data = await ColleaguesApi.setEmployee({
          iin: staffIin,
          staffType: positionCode,
          ...values
        });
      }
      Notice.success(i18next.t('colleagues_employeesAddSuccess'));
      history.replace('/colleague/employees')    
    } catch (error) {
      if (error?.response?.data?.errors[0]?.code === 'error.staff_with_iin_already_working_in_current_organization') {
        Notice.error(i18next.t('colleaguesPassport_errorCurrentEmployees', { staffIin }))
        close()
      } else {
        handleError(error, i18next.t('colleagues_employeesAddError'));
      }
    } finally {
      dispatch({ type: LOADING_SAVE, payload: false });
    }
  };

  export const getMeta = () => async (dispatch, getState) => {
    try {
      dispatch({ type: LOADING, payload: true });
      let data = {
        metadataKey: 'form_staff'
      }
      const {user} = getState()[loginModule];
      const { data: meta } = await ColleaguesApi.loadMeta(data.metadataKey);
      let dictStaffType = null;
      if (user.organization.orgType === '01') {
        dictStaffType = await Dict.itemsObject('ddo_staff_type');
      } else if (user.organization.orgType === '02') {
        dictStaffType = await Dict.itemsObject('school_staff_type');
      } else if (user.organization.orgType === '10') {
        dictStaffType = await Dict.itemsObject('section_staff_type');
      } else if (user.organization.orgType === '12') {
        dictStaffType = await Dict.itemsObject('akimat_staff_type');
      } else {
        dictStaffType = await Dict.itemsObject('gu_staff_type');
      }
      let arr = []
      for (let variable in dictStaffType) {
        arr.push(dictStaffType[variable])
      }
      dispatch({ type: SET_POSITION, payload: arr });
      dispatch({ type: SET_META, payload: meta });
    } catch (error) {
      handleError(error, i18next.t('colleagues_errorLoadMeta'));
    } finally {
      dispatch({ type: LOADING, payload: false });
    }
  }

  export const getGroup = (id) => async dispatch => {
    try {
      dispatch({ type: LOADING, payload: true });
      if (id) {
        let filter = {
          serviceProviderId: id
        }
        const {data} = await ColleaguesApi.getGroup(JSON.stringify(filter));
        dispatch({ type: SET_GROUP, payload: data.result.content })
      }
    } catch (error) {
      handleError(error, i18next.t('colleagues_errorLoadGroup'));
    } finally {
      dispatch({ type: LOADING, payload: false });
    }
  }

  export const getItemEmployees = ({ id }) => async dispatch => {
    try {
      dispatch({ type: LOADING, payload: true });
      const {data} = await ColleaguesApi.getItemEmployees(id);
      dispatch({ type: SET_DATA, payload: data })
    } catch (error) {
      handleError(error, i18next.t('groupPage_errorLoadEducators'));
    } finally {
      dispatch({ type: LOADING, payload: false });
    }
  } 

  export const dismissStaff = ({ id, history, close, info }) => async dispatch => {
    try {
      dispatch({ type: LOADING_DIMISSED, payload: true });
      const response = await ColleaguesApi.getSignString({ id: id, dismissalDate: info.dismissal_date, dismissalReason: info.dismissal_reason });
      const signature = await NCALayerSign(response.data.result.signature);
      if (signature) {
        let data = await ColleaguesApi.dismissStaff({
          id: id,
          signature: signature,
          dismissalDate: info.dismissal_date, 
          dismissalReason: info.dismissal_reason
        })
        if (data.status === 200) {
          close();
          history.push('/colleague/employees');
          Notice.success(i18next.t('colleaguesPassport_successfullyConfirmed'));
        }
      }
    } catch (error) {
      handleError(error, i18next.t('colleaguesPassport_enrollError'));
    } finally {
      dispatch({ type: LOADING_DIMISSED, payload: false });
    }
  }

  export const loadDateReport = ({filter, minDate, maxDate}) => async (dispatch, getState) => {
    try {
      dispatch({ type: LOADING_TABLE, payload: true });
      const userProfile = getState().login.user;
      filter['dateMin'] = `${minDate}T00:00:00`;
      filter['dateMax'] = `${maxDate}T00:00:00`;
      if (userProfile) {
        if (!(userProfile.roles.includes(ROLES.ES_REGION_EMPLOYEES) ||
            userProfile.roles.includes(ROLES.ES_REGION_MIO_EMPLOYEES) ||
            userProfile.roles.includes(ROLES.ES_GODS))) {
          filter['serviceProviderId'] = userProfile?.organization_id;
        }
      } 
      if (filter.serviceProviderLocation) {
        let locationOrganizations = await ColleaguesApi.getOrganizations(filter.serviceProviderLocation);
        dispatch({ type: SET_ORGANIZATIONSE, payload: locationOrganizations.data.result })
      }
      if (filter.serviceProviderLocation) {
        let {data} = await ColleaguesApi.loadDataReport(JSON.stringify(filter));
        if (data.status === 'SUCCESS') {
          let reasonStaff = await Dict.itemsObject('absence_reason_staff');
          let reasonList = []
          for (let variable in reasonStaff) {
            reasonList.push(reasonStaff[variable])
          }
          dispatch({ type: SET_CAUSE, payload: reasonList })
          dispatch({ type: SET_REPORT, payload: data.result, filter })
        }
      } else {
        dispatch({ 
          type: SET_REPORT, 
          payload: {
            content: [],
            totalElements: 0 
          }, 
          filter
        })
      }
    } catch (error) {
      handleError(error, i18next.t('colleagues_reportErrorLoadTable'));
    } finally {
      dispatch({ type: LOADING_TABLE, payload: false });
    }
  };

  export const saveCause = ({ causeCode, userID, logDate, close }) => async (dispatch, getState) => {
    try {
      dispatch({ type: LOADING_SAVE, payload: true });
      const state = getState()[colleaguesModule];
      const stateCopy = JSON.parse(JSON.stringify(state.report));
      const {data} = await ColleaguesApi.saveItemReport({ id: userID, date: logDate, absenceReason: causeCode });
      if (data) {
        stateCopy.content.forEach((item) => {
          if (item.visitsInfo) {
            item.visitsInfo.forEach((visitInfo, index) => {
              if (item.staffId === userID && moment(visitInfo.logDate).format('DD') ===  moment(data.result.logDate).format('DD')) {
                item.visitsInfo[index] = { ...data.result, logDate: moment(data.result.logDate).format('YYYY-MM-DD') }
                if (!visitInfo.hasOwnProperty('absenceReason')) {
                  item.unvisitedDaysWithReason = item.unvisitedDaysWithReason + 1;
                  item.unvisitedDaysWithoutReason = item.unvisitedDaysWithoutReason - 1;
                }
              }
            })
          }
        })
        dispatch({ type: SET_REPORT, payload: stateCopy })
        Notice.success(i18next.t('contingentPage_causeAddSuccess'));
        close()
      }
    } catch (error) {
      handleError(error, i18next.t('contingentPage_causeAddError'));
    } finally {
      dispatch({ type: LOADING_SAVE, payload: false });
    }
  }

  export const exportData = ({
    minDate,
    maxDate,
    filter,
    currentOrg
  }) => async dispatch => {
    try {
      dispatch({ type: LOADING_EXPORT_DATA, payload: true });
      delete filter.pageNumber;
      delete filter.pageSize;
      filter['dateMin'] = `${minDate}T00:00:00`;
      filter['dateMax'] = `${maxDate}T00:00:00`;
      if (!filter?.serviceProviderId) {
        if (currentOrg) {
          filter['serviceProviderId'] = currentOrg;
        } else {
          delete filter['serviceProviderId'];
        }
      }
      const response = await ColleaguesApi.exportDataColleagues(JSON.stringify(filter));
      download(response.data, `${i18next.t('colleagues_reportTitle')}.xlsx`);
    } catch (error) {
      handleError(error, i18next.t('colleagues_reportExportError'));
    } finally {
      dispatch({ type: LOADING_EXPORT_DATA, payload: false });
    }
  };
  
  const download = (data, fileName) => {
    let element = document.createElement('a');
    document.body.appendChild(element);
    element.style.display = 'none';
    let blob = new Blob([data]);
    let url = window.URL.createObjectURL(blob);
    element.href = url;
    element.download = fileName;
    element.click();
    window.URL.revokeObjectURL(url);
  };
