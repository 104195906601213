import styled from 'styled-components';

export const Wrapper = styled.div`
  .MuiFormControlLabel-root {
    margin: 0 !important;
  }
`;

export const ImportContainer = styled.div`
  display: flex;
  align-items: center;
  border: 2px solid #8080806e;
  border-radius: 5px;
  padding: 5px;
  
  #file-name {
    flex-grow: 1;
    margin-right: 10px;
    overflow: hidden;
  }
  
  span {
    background: darkslategrey;
    padding: 3px 10px;
    .MuiButton-label {
      z-index: 1;
    }
  }
`;
