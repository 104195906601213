import React from 'react';
import { Switch, IconButton, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import FilterInputField from '../../components/TableFilter/ColumnsFilters/FilterInputField';
import FilterSelect from '../../components/TableFilter/ColumnsFilters/FilterSelect';
import { PROFILE_DATA } from './AdministrationDucks';

const StatusOptions = [
  { code: 'true', ru_name: 'Активен', kk_name: 'Активен' },
  { code: 'false', ru_name: 'Не активен', kk_name: 'Не активен' }
];

const styles = {
  textAlign: 'center',
  alignSelf: 'center'
};

export const administrationColumns = ({ t, lang, activateUser, onDelete }) => {
  return [
    {
      Header: t('administrationPage_columnID'),
      accessor: 'id',
      width: 150,
      Filter: <FilterInputField name="id" type="number" lang={lang}/>,
      style: styles
    },
    {
      Header: t('administrationPage_columnIin'),
      accessor: 'iin',
      Filter: <FilterInputField name="iin" type="number" lang={lang}/>,
      style: styles
    },
    {
      Header: t('administrationPage_columnBin'),
      accessor: 'currentOO',
      Filter: <FilterInputField name={PROFILE_DATA.ES_SERVICE_PROVIDER_BIN} lang={lang}/>,
      Cell: ({ value }) => value && value['bin'],
      style: styles
    },
    {
      Header: t('administrationPage_columnLastName'),
      accessor: 'lastName',
      Filter: <FilterInputField name="lastName" type="text" lang={lang}/>
    },
    {
      Header: t('administrationPage_columnFirstName'),
      accessor: 'firstName',
      Filter: <FilterInputField name="firstName" type="text" lang={lang}/>
    },
    {
      Header: t('administrationPage_columnMiddleName'),
      accessor: 'middleName',
      Filter: <FilterInputField name="middleName" type="text" lang={lang}/>
    },
    {
      Header: t('administrationPage_columnUsername'),
      accessor: 'username',
      Filter: <FilterInputField name="username" type="text" lang={lang}/>,
      style: styles
    },
    {
      Header: t('administrationPage_columnStatus'),
      accessor: 'active',
      Filter: <FilterSelect name="active" lang={lang} options={StatusOptions}/>,
      width: 200,
      style: styles,
      Cell: ({ value, original, index }) => (
        <div style={{ margin: '-5px 0' }}>
          <Switch
            size="small"
            color="primary"
            checked={value}
            onClick={e => {
              e.stopPropagation();
              activateUser(original.username, !value, index);
            }}
          />
        </div>
      )
    },
    {
      Header: t('administrationPage_columnAction'),
      style: styles,
      filterable: false,
      width: 150,
      Cell: ({ original, index }) => (
        <div style={{ margin: '-5px 0' }}>
          <Tooltip placement="top" title={t('administrationPage_columnDelete')}>
            <IconButton
              color="secondary"
              size="small"
              children={<DeleteIcon fontSize="small"/>}
              onClick={e => {
                e.stopPropagation();
                onDelete(original, index);
              }}
            />
          </Tooltip>
        </div>
      )
    }
  ];
};
