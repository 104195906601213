import i18next from 'i18next';
import { useEffect } from 'react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import useTableFilter from '../../components/TableFilter/useTableFilter';
import { statusColor } from '../../utils/statusColors';
import { kezekBulletinTableModule, loadData, clearState } from './KezekBulletinTableDucks';
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs';
import StaticTablePagination from '../../_ui/Table/StaticTablePaginated';
import { kezekBulletinColumns } from './KezekBulletinColumns';
import './KezekBulletinTableTranslate';
import { useTranslation } from 'react-i18next';
import AddIcon from '@material-ui/icons/Add';
import Button from '../../_ui/Button/Button';
import { NavLink } from 'react-router-dom';
import paths from '../../_helpers/paths';
import useHasAccess from '../../components/useHasAccess';
import permissions from '../../_helpers/permissions';

export default function KezekBulletinTablePage() {
  const { t, i18n } = useTranslation();
  const loading = useSelector(state => state[kezekBulletinTableModule].loadingTable);
  const data = useSelector(state => state[kezekBulletinTableModule].tableData);
  const dispatch = useDispatch();
  const { nextPage } = useTableFilter(filter => dispatch(loadData(filter)));
  const isAdmin = useHasAccess(permissions.admin);

  const breadCrumbItems = [
    { name: i18next.t('breadcrumbs_home'), href: '/' },
    { name: i18next.t('breadcrumbs_kezekBulletin') }
  ];

  useEffect(() => {
    dispatch(clearState());
  }, [dispatch]);

  return (
    <>
      <BreadCrumbs items={breadCrumbItems} />
      <Paper className="pb3 pt1 px3" style={{ minWidth: 1800 }}>
        <h3>{t('kezekBulletin_title')}</h3>
        <div className="mb2">
          <Button
            icon={<AddIcon fontSize="small" className="mr1" />}
            size="small"
            text={t('kezekBulletinPage_add')}
            component={NavLink}
            to={paths.kezekBulletin.replace('/:id?', '')}
          />
        </div>
        <StaticTablePagination
          columns={kezekBulletinColumns({ t, lang: i18n.language, isAdmin })}
          data={data.content}
          sortable={false}
          urlOnClick={row => `/bulletin/${row.original.id}`}
          totalElements={data.totalElements}
          loading={loading}
          onClickLoadMore={() => nextPage()}
          rowBgColor={row => statusColor[row.original.resolutionType]}
        />
      </Paper>
    </>
  );
}
