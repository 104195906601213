import * as Yup from 'yup';
import i18next from 'i18next';

export const SignInSchema = Yup.object().shape({
  username: Yup.string()
    .required(() => i18next.t('loginPage_userNameRequired'))
    .test(
      'has-russian-letters',
      () => i18next.t('loginPage_nameValidationNotCyrillic'),
      pwd => pwd && !pwd.match(/[а-яА-ЯЁё]/)
    ),
  password: Yup.string().required(() => i18next.t('loginPage_passwordRequired'))
});
