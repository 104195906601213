import * as Yup from 'yup';
import i18next from 'i18next';

export const SignUpSchema = isCreate =>
  Yup.object().shape({
    iin: Yup.string()
      .required(() => i18next.t('userModal_validationRequired'))
      .min(12, () => i18next.t('userModal_validationWrongIin'))
      .max(12, () => i18next.t('userModal_validationWrongIin'))
      .matches(/\d{12}/, {
        message: () => i18next.t('userModal_validationWrongIin'),
        excludeEmptyString: true
      }),
    lastName: Yup.string().required(() => i18next.t('userModal_validationRequired')),
    firstName: Yup.string().required(() => i18next.t('userModal_validationRequired')),
    username: Yup.string()
      .required(() => i18next.t('userModal_validationRequired'))
      .test(
        'has-russian-letters',
        () => i18next.t('userModal_validationNotCyrillic'),
        pwd => pwd && !pwd.match(/[а-яА-ЯЁё]/)
      ),
    password: isCreate
      ? Yup.string()
          .required(() => i18next.t('resetPass_required'))
          .min(8, () => i18next.t('resetPass_wrongPasswordLength'))
          .test(
            'has-russian-letters',
            () => i18next.t('resetPass_wrongPasswordNotCyrillic'),
            pwd => pwd && !pwd.match(/[а-яА-ЯЁё]/)
          )
          .matches(/\d/, {
            message: () => i18next.t('resetPass_wrongPasswordDigits'),
            excludeEmptyString: true
          })
          .matches(/[a-z]/, {
            message: () => i18next.t('resetPass_wrongPasswordLowercase'),
            excludeEmptyString: true
          })
          .matches(/[A-Z]/, {
            message: () => i18next.t('resetPass_wrongPasswordUppercase'),
            excludeEmptyString: true
          })
          .matches(/[\\~[{}!@#$*%^&<>()_+|/'="№;:?\],.-]/, {
            message: () => i18next.t('resetPass_wrongPasswordSymbols'),
            excludeEmptyString: true
          })
      : Yup.string()
          .notRequired()
          .min(8, () => i18next.t('resetPass_wrongPasswordLength'))
          .test(
            'has-russian-letters',
            i18next.t('resetPass_wrongPasswordNotCyrillic'),
            pwd => !pwd || (pwd && !pwd.match(/[а-яА-ЯЁё]/))
          )
          .matches(/\d/, {
            message: () => i18next.t('resetPass_wrongPasswordDigits'),
            excludeEmptyString: true
          })
          .matches(/[a-z]/, {
            message: () => i18next.t('resetPass_wrongPasswordLowercase'),
            excludeEmptyString: true
          })
          .matches(/[A-Z]/, {
            message: () => i18next.t('resetPass_wrongPasswordUppercase'),
            excludeEmptyString: true
          })
          .matches(/[\\~[{}!@#$*%^&<>()_+|/'="№;:?\],.-]/, {
            message: () => i18next.t('resetPass_wrongPasswordSymbols'),
            excludeEmptyString: true
          }),
    emailAddress: Yup.string()
      .required(() => i18next.t('userModal_validationRequired'))
      .email(() => i18next.t('userModal_validationWrongEmail')),
    roles: Yup.string().required(() => i18next.t('userModal_validationRequired')),
    position_ru: Yup.string().required(() => i18next.t('userModal_validationRequired')),
    position_kk: Yup.string().required(() => i18next.t('userModal_validationRequired')),
    /*phone_number: Yup.string()
      .required(() => i18next.t('userModal_validationRequired'))
      .matches(/\d/, {
        message: () => i18next.t('resetPass_wrongPasswordDigits'),
        excludeEmptyString: true
      }) */
  });
