import * as Yup from 'yup';
import i18next from 'i18next';

export const ProfileUpdateSchema = () => Yup.object().shape({
  iin: Yup.string()
    .required(() => i18next.t('userModal_validationRequired'))
    .min(12, () => i18next.t('userModal_validationWrongIin'))
    .max(12, () => i18next.t('userModal_validationWrongIin'))
    .matches(/\d{12}/, {
      message: () => i18next.t('userModal_validationWrongIin'),
      excludeEmptyString: true
    }),
  lastName: Yup.string()
    .required(() => i18next.t('userModal_validationRequired')),
  firstName: Yup.string()
    .required(() => i18next.t('userModal_validationRequired')),
  username: Yup.string()
    .required(() => i18next.t('userModal_validationRequired'))
    .test(
      'has-russian-letters',
      () => i18next.t('userModal_validationNotCyrillic'),
      pwd => pwd && !pwd.match(/[а-яА-ЯЁё]/)
    ),
  emailAddress: Yup.string()
    .required(() => i18next.t('userModal_validationRequired'))
    .email(() => i18next.t('userModal_validationWrongEmail')),
  roles: Yup.string()
    .required(() => i18next.t('userModal_validationRequired')),
  position_ru: Yup.string()
    .required(() => i18next.t('userModal_validationRequired')),
  position_kk: Yup.string()
    .required(() => i18next.t('userModal_validationRequired'))
});
