import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import StaticTablePagination from '../../../_ui/Table/StaticTablePaginated';
import { DismissedColumns } from './DismissedColumns'
import { loadDismissed, colleaguesModule } from '../ColleaguesDucks';
import useTableFilter from '../../../components/TableFilter/useTableFilter';
import { loginModule } from '../../LoginPage/LoginDucks';
import {ROLES_ADMIN} from '../../../_helpers/Constants'
import FilterInputField from '../../../components/TableFilter/ColumnsFilters/FilterInputField';

export default function DismissedPage() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [staffType, setStaffType] = useState('gu_staff_type')
  const [isAdmin, setIsAdmin] = useState(false);

  const data = useSelector(state => state[colleaguesModule].dismissed);
  const { user } = useSelector(
    state => state[loginModule]
  );

  useEffect(() => {
    user.roles.forEach((item) => {
      if (item === ROLES_ADMIN.ES_ADMINS || item === ROLES_ADMIN.ES_GODS) {
        setIsAdmin(true)
      }
    })
  }, [user.roles])

  const loadingTable = useSelector(state => state[colleaguesModule].loadingTable);
  const { nextPage } = useTableFilter(filter =>
    dispatch(loadDismissed({ filter, type: 'DISMISSED' }))
  ); 

  useEffect(() => {
    if (user.organization.orgType === '01') {
      setStaffType('ddo_staff_type');
    } else if (user.organization.orgType === '02') {
      setStaffType('school_staff_type');
    } else if (user.organization.orgType === '10') {
      setStaffType('section_staff_type');
    } else {
      setStaffType('gu_staff_type');
    }
  }, [])

  return (
    <div className='mt1'>
      <StaticTablePagination
        sortable={false}
        loading={loadingTable}
        columns={
          !isAdmin ? [
            ... DismissedColumns({
              t,
              lang: i18n.language,
              staffType
             })
          ] : 
          [
            ... DismissedColumns({
              t,
              lang: i18n.language,
              staffType
             }),
             {
              Header: t('contingentPage_columnBinOrg'),
              accessor: 'bin',
              Filter: <FilterInputField name="bin" type="number" />
            },
          ]
        }
        totalElements={data.totalElements}
        data={data.content}
        urlOnClick={row => `/colleagues/save/${row.original.id}`}
        onClickLoadMore={() => nextPage()}
      />
    </div>
  );
}

