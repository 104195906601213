import React, { useState, useEffect } from 'react';
import Modal from '../../../_ui/Modal/Modal';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import withTranslation from '../../../components/_hoc/withTranslation';
import Button from '../../../_ui/Button/Button';
import SignIcon from '@material-ui/icons/DoneAll';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { commitIntermediate, statementModule } from '../StatementDucks';
import { StatementApi } from '../../../_helpers/service';
import { handleError } from '../../../utils/handleError';

function IntermediateModal({ onClose, id, commitIntermediate, t, loading }) {
  const [file, setFile] = useState();
  useEffect(() => {
    StatementApi.downloadCommitFile(id, {})
      .then(response => {
        setFile(response.data.result);
      })
      .catch(function(error) {
        handleError(error, t('statement_positivePreview'));
      });
  }, [id, t]);

  return (
    <Modal
      open
      title={t('statement_intermediateTitle')}
      onClose={onClose}
      maxWidth="md"
    >
      <DialogContent dividers>
        <div className="center mb2">
          {file && (
            <object
              type="application/pdf"
              data={`data:application/pdf;base64,${file.fileContent}`}
              width="800px"
              height="1184px"
            >
              <p>{t('statement_positivePreview')}</p>
            </object>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          icon={<SignIcon style={{ marginRight: 5 }} />}
          text={t('statement_completeSign')}
          loading={loading}
          onClick={() => commitIntermediate(id, onClose)}
        />
      </DialogActions>
    </Modal>
  );
}

export default compose(
  withTranslation,
  connect(
    state => ({
      loading: state[statementModule].loadingIntermediate
    }),
    { commitIntermediate }
  )
)(IntermediateModal);
