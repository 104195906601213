import React, { useState } from 'react';
import Modal from '../../../_ui/Modal/Modal';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import Button from '../../../_ui/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { contingentPassportModule, revokeContingent } from '../ContingentPassportDucks';
import CancelIcon from '@material-ui/icons/Cancel';
import Form from '../../../_ui/Form/Form';
import Row from '../../../_ui/Row';
import Select from '../../../_ui/Select/Select';
import Input from '../../../_ui/Input/Input';
import * as Yup from 'yup';
import i18next from 'i18next';
import Alert from '@material-ui/lab/Alert';

const ValidationSchema = Yup.object().shape({
  reasonDeletion: Yup.string().required(() => i18next.t('kezekDirection_requiredField')),
  reasonDeletionTextRu: Yup.string().when(['reasonDeletion'], {
    is: reasonDeletion => reasonDeletion === '005',
    then: Yup.string().required(() => i18next.t('kezekDirection_requiredField'))
  }),
  reasonDeletionTextKk: Yup.string().when(['reasonDeletion'], {
    is: reasonDeletion => reasonDeletion === '005',
    then: Yup.string().required(() => i18next.t('kezekDirection_requiredField'))
  })
});
//{ data: directionId, close }
export default function ModalRevoke(data) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loading = useSelector(state => state[contingentPassportModule].loadingRevoke);
  const [showReason, setShowReason] = useState(false);
  return (
    <Modal title={t('contingentPassport_createRevokeTitle')} open onClose={data.close}>
      <Form
        initialValues={{
          reasonDeletion: '',
          reasonDeletionTextRu: '',
          reasonDeletionTextKk: ''
        }}
        onSubmit={values => dispatch(revokeContingent(data.directionId, values, data.close, data.contingentId))}
        validate={ValidationSchema}
      >
        <DialogContent dividers>
          <Row label={t('contingentPassport_reasonDeletion')}>
            <Select
              name="reasonDeletion"
              lookupId="gu_reason_deletion"
              placeholder={t('contingentPassport_reasonDeletion')}
              onChange={event => {
                const value = event.target.value;
                if (value === '005' && !showReason) setShowReason(true);
                else if (showReason) setShowReason(false);
              }}
            />
          </Row>
          {showReason && (
            <>
              <Row
                label={t('contingentPassport_reasonDeletionTextRu')}
                className="flex items-start"
              >
                <Input
                  name="reasonDeletionTextRu"
                  placeholder={t('contingentPassport_reasonDeletionTextRu')}
                  multiline
                />
              </Row>
              <Row
                label={t('contingentPassport_reasonDeletionTextKk')}
                className="flex items-start"
              >
                <Input
                  name="reasonDeletionTextKk"
                  placeholder={t('contingentPassport_reasonDeletionTextKk')}
                  multiline
                />
              </Row>
            </>
          )}
          <Alert severity="info" className="mt3 mb2">{t('contingentPassport_createRevokeText')}</Alert>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            icon={<CancelIcon fontSize="small" className="mr1" />}
            text={t('contingentPassport_revoke')}
            type="submit"
            loading={loading}
          />
        </DialogActions>
      </Form>
    </Modal>
  );
}
