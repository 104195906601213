import { addTranslation } from '../../../_helpers/Translate';

addTranslation({
  ru: {
    taskPage_title: 'Мои задачи',
    taskPage_columnTaskNumber: 'Номер задачи',
    taskPage_columnName: 'Наименование',
    taskPage_columnType: 'Тип задачи',
    taskPage_columnStatus: 'Статус',
    taskPage_columnStartTime: 'Начала выполнения',
    taskPage_errorLoadTable: 'Не удалось загрузить список задач',
    taskPage_onApprove_Btn: 'Отправить',
    taskPage_onSave_Btn: 'Сохранить',

    taskDucks_errorLoadTask: 'Не удалось загрузить данные задачи',
    taskDucks_errorLoad403: 'У вас недостаточно прав для выполнения данного действия',
    taskDucks_errorLoadExecutors: 'Не удалось загрузить список исполнителей',
    taskDucks_errorOnApprove: 'Не удалось оправить данные задачи',
    taskDucks_successOnApprove: 'Данные успешно отправлены',
    taskDucks_successOnSave: 'Данные успешно сохранены',
    taskDucks_successExecutorAssign: 'Исполнитель назначен',
    taskDucks_errorOnSave: 'Не удалось сохранить данные задачи',

    assignExecutor_title: 'Назначение исполнителя',
    assignExecutor_columnNumber: 'Задача',
    assignExecutor_columnExecutorName: 'Исполнитель',
    assignExecutor_columnDueDate: 'Срок исполнения',
    assignExecutor_button: 'Назначить исполнителя',
    assignExecutor_list_empty: 'Список пуст',
    assignExecutor_errorLoadTable: 'Не удалось загрузить список задач',

    executorModal_executorName: 'Исполнитель',
    executorModal_dueDate: 'Срок исполнения',
    executorModal_onCancel_Btn: 'Отмена',
    executorModal_onSelect_Btn: 'Выбрать',
    executorModal_requiredField: 'Обязательное для заполнения',

    signTask_sign: 'Подписать',
    signTask_reject: 'Отказать',
    signTask_rejectModalTitle: 'Подтверждение отказа',
    signTask_rejectModalReason: 'Причина отказа',
    signTask_rejectModalRequired: 'Обязательное для заполнения',
    signTask_errorReject: 'Ошибка при отказе, попробуйте позже',
    signTask_successReject: 'Успешно отказано',
    signTask_successRejectExec: 'Заявка № {{businessObjectId}} возвращена ответственному исполнителю',
    signTask_errorSign: 'Не удалось подписать документ',
    signTask_errorSignatureValidation: 'ИИН ЭЦП для подписи не соответствует ИИН логину, под которым вы работаете. Проверьте срок действия ЭЦП.',
    signTask_successSign: 'Документ успешно подписан',
    signTask_docPreview: 'Не удалось загрузить файл предпросмотра',

    taskInfo_number: 'Номер задачи',
    taskInfo_statementId: 'Номер заявки',
    taskInfo_statementType: 'Тип заявки',
    taskInfo_status: 'Статус',
    taskInfo_startTime: 'Начала выполнения',
    taskInfo_endTime: 'Время окончания',
    taskInfo_signerName: 'Подписавший',
    taskInfo_signerDate: 'Дата и время подписи',
    taskInfo_declineSignatureReason: 'Причина возврата на доработку',
    taskInfo_requestDate: 'Дата заявки',
    taskInfo_requesterIin: 'ИИН заявителя ',
    taskInfo_pepNumber: 'Номер заявки ПЭП',
    taskInfo_serviceProviderNumber: 'Номер услугодателя',
    taskInfo_serviceProviderBin: 'БИН услугодателя',
    taskInfo_serviceProviderIin: 'ИИН услугодателя',
    taskInfo_serviceProvider: 'Услугодатель',

    taskSelectUser_selectUser: 'Выберите ответственного',
  },
  kk: {
    taskPage_title: 'Мои задачи',
    taskPage_columnTaskNumber: 'Тапсырма нөмірі',
    taskPage_columnName: 'Атауы',
    taskPage_columnType: 'Тапсырма түрі',
    taskPage_columnStatus: 'Мәртебесі',
    taskPage_columnStartTime: 'Орындаудың басталуы',
    taskPage_errorLoadTable: 'Тапсырмалар тізімін жүктеу мүмкін емес',
    taskPage_onApprove_Btn: 'Жіберу',
    taskPage_onSave_Btn: 'Сақтау',

    taskDucks_errorLoadTask: 'Тапсырмалар деректерін жүктеу мүмкін емес',
    taskDucks_errorLoad403: 'Сізде бұл әрекетті орындау үшін жеткілікті құқықтар жоқ',
    taskDucks_errorLoadExecutors: 'Орындаушылар тізімін жүктеу мүмкін емес',
    taskDucks_errorOnApprove: 'Тапсырма деректерін жіберу сәтсіз аяқталды',
    taskDucks_successOnApprove: 'Тапсырма деректерін жіберу сәтті аяқталды',
    taskDucks_successOnSave: 'Деректерді сақтау сәтті аяқталды',
    taskDucks_successExecutorAssign: 'Орындаушы тағайындалды',
    taskDucks_errorOnSave: 'Тапсырманы сақтау сәтсіз аяқталды',

    assignExecutor_title: 'Орындаушыны тағайындау',
    assignExecutor_columnNumber: 'Тапсырма',
    assignExecutor_columnExecutorName: 'Орындаушы',
    assignExecutor_columnDueDate: 'Орындау мерзімі',
    assignExecutor_button: 'Орындаушыны тағайындау',
    assignExecutor_list_empty: 'Тізім бос',
    assignExecutor_errorLoadTable: 'Тапсырмалар тізімін жүктеу мүмкін емес',

    executorModal_executorName: 'Орындаушыны',
    executorModal_dueDate: 'Орындау уақыты',
    executorModal_onCancel_Btn: 'Болдырмау',
    executorModal_onSelect_Btn: 'Таңдаңыз',
    executorModal_requiredField: 'Міндетті өріс',

    signTask_sign: 'Қол қою',
    signTask_reject: 'Бас тарту',
    signTask_rejectModalTitle: 'Бас тартуды растау',
    signTask_rejectModalReason: 'Бас тартудың себебі',
    signTask_rejectModalRequired: 'Міндетті өріс',
    signTask_errorReject: 'Сәтсіздік, кейінірек көріңіз',
    signTask_successReject: 'Бас тарту сәтті аяқталды',
    signTask_successRejectExec: '№ {{businessObjectId}} жауапты орындаушыға қайтарылды',
    signTask_errorSign: 'Құжатқа қол қою сәтсіз аяқталды',
    signTask_errorSignatureValidation: 'Қол қою үшін ЭЦҚ ЖСН-і сіз жұмыс істейтін логиндегі ЖСН-мен сәйкес келмейді. ЭЦҚ жарамдылық мерзімін тексеріңіз',
    signTask_successSign: 'Құжатқа сәтті қол қойылды',
    signTask_docPreview: 'Алдын ала қарау файлын жүктеу сәтсіз аяқталды',

    taskInfo_number: 'Тапсырманың нөмірі',
    taskInfo_statementId: 'Өтінім нөмірі',
    taskInfo_statementType: 'Өтінім түрі',
    taskInfo_status: 'Мәртебесі',
    taskInfo_startTime: 'Орындаудың басталуы',
    taskInfo_endTime: 'Аяқталу уақыты',
    taskInfo_signerName: 'Қол қоюшы',
    taskInfo_signerDate: 'Қолы қойылған күні мен уақыты',
    taskInfo_declineSignatureReason: 'Қайта қарау үшін қайтару себебі',
    taskInfo_requestDate: 'Өтініш күні',
    taskInfo_requesterIin: 'Өтініш берушінің ЖСН-і',
    taskInfo_pepNumber: 'ЭҮП өтініш нөмірі',
    taskInfo_serviceProviderNumber: 'Қызмет көрсетушінің нөмірі',
    taskInfo_serviceProviderBin: 'Қызмет көрсетушінің БСН',
    taskInfo_serviceProviderIin: 'Қызмет көрсетушінің ЖСН-і',
    taskInfo_serviceProvider: 'Қызмет көрсетуші',

    taskSelectUser_selectUser: 'Жауапты адамды таңдаңыз',
  }
});
