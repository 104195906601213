import './ContingentSchoolTranslate';
import React, { useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import MuiSelect from '@material-ui/core/Select';
import { useDispatch, useSelector } from 'react-redux';
import {
  getClasses,
  loadDateFoodReport,
  contingentSchoolModule, exportDataFree,
} from './ContingentSchoolDucks';
import StaticTablePagination from '../../_ui/Table/StaticTablePaginated';
import useTableFilter from '../../components/TableFilter/useTableFilter';
import { MenuItem } from '@material-ui/core';
import ColorInfo from '../../components/PageReport/ColorInfo';
import paths from '../../_helpers/paths';
import { ROLES } from '../../_helpers/Constants';
import FormHelperText from '@material-ui/core/FormHelperText';
import {
  monthCurrent,
  createTableCell,
  getLastDayOfMonth
} from '../../components/PageReport/reportUtils';
import { ContingentSchoolRpfColumns } from './ContingentSchoolRpfColumns';
import useDict from '../../components/_hooks/useDict';
import useUser from '../LoginPage/useUser';
import moment from 'moment';
import FilterDatePickerMonth from '../../components/TableFilter/ColumnsFilters/FilterDatePickerMonth';
import FilterTreeSelect from '../../components/TableFilter/ColumnsFilters/FilterTreeSelect/FilterTreeSelect';
import Autocomplete from '../../components/TableFilter/ColumnsFilters/FilterAutocomplete';
import SaveIcon from '@material-ui/icons/Save';
import Button from '../../_ui/Button/Button';

export default function ContingentFoodReport() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const user = useUser();
  const [days, setDays] = useState([]);
  const [currentClass, setCurrentClass] = useState();
  const classNumber = useDict('gu_class_type');
  const classLetter = useDict('39051');
  const [isEdit, setIsEdit] = useState(true);
  const [currentOrg, setCurrentOrg] = useState(user?.organization_id)
  const [copyFilter, setCopyFilter] = useState({});
  const [counterTotalAte, setCounterTotalAte] = useState(0);
  const [counterFreeMealAAll, setCounterFreeMealAAll] = useState(0);
  const [counterBeneficiaryAll, setCounterBeneficiaryAll] = useState(0);
  const [counterDeclinedAll, setCounterDeclinedAll] = useState(0);

  useEffect(() => {
    if (user) {
      user.roles.forEach((item) => {
        if (item === ROLES.ES_REGION_MIO_EMPLOYEES || item === ROLES.ES_REGION_EMPLOYEES || item === ROLES.ES_GODS) {
          setIsEdit(false)
          setCurrentOrg(false)
        }
      })
    }
  }, [user]);

  const breadCrumbItems = [
    { name: i18n.t('breadcrumbs_home'), href: '/' },
    { name: i18n.t('breadcrumbs_contingentSchool'), href: paths.contingentPupilsPage },
    { name: i18n.t('breadcrumbs_reportFood') }
  ];

  const { reportFood, loadingTable, classes, organizations, classesOrg, loadingExport } = useSelector(
    state => state[contingentSchoolModule]
  );

  const getFullYear = new Date().getFullYear();
  const [currentMonth, setCurrentMonth] = useState(monthCurrent(new Date().getMonth() + 1));

  const { nextPage } = useTableFilter(filter => {
    let activeMonth = moment(filter.dateMin).format('MM');
    const arrDays = createTableCell(getLastDayOfMonth(getFullYear, activeMonth));
    if (arrDays.length) {
      setDays(arrDays)
    }
    dispatch(getClasses());
    filter['schoolGroupId'] = currentClass;
    setCurrentMonth(activeMonth);
    filter['dateMax'] = `${getFullYear}-${activeMonth}-${getLastDayOfMonth(getFullYear, activeMonth)}T00:00:00`
    setCopyFilter({...filter})
    dispatch(loadDateFoodReport({filter}));
    setCurrentClass('');
  }, {
    pageNumber: 1,
    pageSize: 20,
    dateMin: `${getFullYear}-${currentMonth}-01T00:00:00`
  });

  useEffect(() => {
    if (copyFilter.dateMin) {
      copyFilter['schoolGroupId'] = currentClass;
      dispatch(loadDateFoodReport({filter: copyFilter}))
    }
  }, [currentClass])

  useEffect(() => {
    let totalAte = 0;
    let isFreeMealAAll = 0;
    let isBeneficiaryAll = 0;
    let isDeclinedAll = 0;
    reportFood.forEach((item) => {
      setCounterTotalAte(totalAte += item.totalAte);
      setCounterFreeMealAAll(isFreeMealAAll += item.isFreeMeal);
      setCounterBeneficiaryAll(isBeneficiaryAll += item.isBeneficiary);
      setCounterDeclinedAll(isDeclinedAll += item.isDeclined)
    })
  }, [reportFood]);

  const onExportHandle = () => {
    dispatch(exportDataFree({filter: copyFilter, currentOrg}))
  };

  return (
    <>
      <BreadCrumbs items={breadCrumbItems} />
      <Paper className="pb3 pt1 px3" style={{ minWidth: 1800 }}>
        <div className="flex justify-between items-center">
          <h3>{t('contingentSchool_reportFoodTitle')}</h3>
        </div>
        <div className="flex justify-between items-center mb2">
          <Box className="flex justify-between items-center">
            <Box className="flex justify-between items-center mr2">
              <Typography style={{fontSize: '12px', width: '100px'}}>{t('contingentSchool_tableFoodFor')}</Typography>
              <Box style={{width: '250px'}}>
                <FilterDatePickerMonth
                  name="dateMin"
                  lang={i18n.language}
                  currentMonth={currentMonth}
                />
              </Box>
            </Box>
            {
              !isEdit &&
              <>
                <Box className="flex justify-between items-center mr2">
                  <Typography style={{fontSize: '12px', width: '180px'}}>{t('colleagues_location')}:</Typography>
                  <FilterTreeSelect
                    lookupId="60"
                    name="serviceProviderLocation"
                    lang={i18n.language}
                    filter={item => item.ARM_gu === 'true'}
                  />
                </Box>

                <Box className="flex justify-between items-center mr2">
                  <Typography style={{fontSize: '12px', width: '150px'}}>{t('colleagues_organizations')}</Typography>
                  <Autocomplete
                    name="serviceProviderId"
                    options={organizations}
                  />
                </Box>
              </>
            }
            {(currentOrg || copyFilter?.serviceProviderId) && <Box className="flex justify-between items-center mr2">
              <Typography style={{ fontSize: '12px', width: '80px' }}>
                {t('contingentSchool_classSelect')}
              </Typography>
              <FormControl fullWidth>
                <MuiSelect value={currentClass}>
                  {classesOrg.map(item => {
                    const findClass = classNumber.find(
                      el => el.code === item.classNumber
                    );
                    const findCLetter = classLetter.find(el => el.code === item.letter);
                    let name = '';
                    if (findClass && findCLetter) {
                      name = `${findClass[`${i18n.language}_name`]} ${
                        findCLetter[`${i18n.language}_name`]
                      }`;
                    }
                    return (
                      <MenuItem value={item.id} onClick={() => setCurrentClass(item.id)}>
                        {' '}
                        {name}{' '}
                      </MenuItem>
                    );
                  })}
                  {classesOrg.length === 0 && (
                    <MenuItem>{t('contingentSchool_noClass')}</MenuItem>
                  )}
                </MuiSelect>
              </FormControl>
            </Box>}
          </Box>
          <Box className="flex justify-between items-center">
            <Button
              onClick={onExportHandle}
              icon={<SaveIcon fontSize="small" className="mr1" />}
              text={t('colleagues_export_btn')}
              loading={loadingExport}
            />
            <Box className="mr2"></Box>
            <ColorInfo isGood={true} />
          </Box>
        </div>
        {!currentClass && (
          <FormHelperText error style={{ marginBottom: '10px', fontSize: '14px' }}>
            {t('contingentSchool_isShowDataTable')}
          </FormHelperText>
        )}
        <Box className="reportСard">
          <StaticTablePagination
            sortable={false}
            loading={loadingTable}
            columns={ContingentSchoolRpfColumns({
              t,
              days,
              lang: i18n.language
            })}
            data={reportFood.length ? [...reportFood, {
              studentName: t('pageReport_total'),
              totalAte: counterTotalAte,
              totalFinish: true,
              isFreeMealAAll: counterFreeMealAAll,
              isBeneficiaryAll: counterBeneficiaryAll,
              isDeclinedAll: counterDeclinedAll,
            }] : []}
          />
        </Box>
      </Paper>
    </>
  );
}
