import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  PassportProvider,
  PassportExpander,
  PassportForm,
  getFormValues,
  PassportNavigation
} from '../../passport/Passport';
import { Color } from '../../components/ThemeProvider/ThemeStyle';
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary';
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs';
import { contingentSectionModule, clearState, getMeta, loadData } from './ContingentSectionDucks';
import { Wrapper, ButtonsWrapper } from './ContingentSectionStyle';
import { useTranslation } from 'react-i18next';
import PageLoading from '../../components/PageLoader/PageLoading';
import './ContingentSectionTranslate';
import i18next from 'i18next';
import { useParams } from 'react-router';
import paths from './../../_helpers/paths';
import Button from '../../_ui/Button/Button';
import SaveIcon from '@material-ui/icons/Save';
import ModalAdd from './modal/ModalAdd'
import useSimpleModal from '../../components/_hooks/useSimpleModal';
import ModalDisposal from './modal/ModalDisposal'
import { CONTINGENT_SECTION_STATUS } from '../../_helpers/Constants';
import ContingentSectionInfo from './ContingentSectionInfo'

export default function ContingentSectionPassport() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();

  const { loading, data, meta, loadingDismissed, loadingSave } = useSelector(
    state => state[contingentSectionModule]
  );

  const addModal = useSimpleModal();
  const disposalModal = useSimpleModal();

  const [studentIIN, setStudentIIN] = useState()

  const breadCrumbItems = [
    { name: i18next.t('breadcrumbs_home'), href: '/' },
    { name: i18next.t('breadcrumbs_contingentSection'), href: paths.contingentStudentPage },
    { name: i18next.t('breadcrumbs_contingentPassport') }
  ];
 

  useEffect(() => {
    dispatch(getMeta());
    if (id) {
      dispatch(loadData(id));
    }
    return () => dispatch(clearState());
  }, [dispatch, id]);

  const onAddSchool = () => {
    getFormValues().then(values => {
      if (values) {
        addModal.open(true)
      }
    })
  }
  
  function extraF(code, value) {
    if (code === 'form_contingent_section_visitor.visitor_iin') {
      setStudentIIN(value)
    }
  }

  return (
    <PageLoading>
      <BreadCrumbs items={breadCrumbItems} />
      <Wrapper className="px3 pb3">
        <ErrorBoundary>
          <PassportProvider
            withoutScroll={false}
            stickyTop={Color.headerHeight}
            data={JSON.stringify(data) === '{}' ? {metadataKey: "form_contingent_section_visitor"} : data}
            meta={meta}
            questionRender={extraF}
            config={{
              documentsProps: {
                mimeType: 'image/jpeg,image/png,application/pdf',
                maxSize: 10000000,
              },
            }}
          >
            <PassportExpander />
            <div className='flex'>
              {
                JSON.stringify(data) !== '{}' &&
                <div className="left-info">
                  <ContingentSectionInfo data={data} t={t} />
                  <PassportNavigation />
                </div>
              }
              <div className="ml2 flex-auto">
                <PassportForm loading={loading} disabled={false} />
              </div>
            </div>
          </PassportProvider>
          
          <ButtonsWrapper>
            {
              data.contingentStatus === CONTINGENT_SECTION_STATUS.VISITS_SECTION && 
              <Button
                loading={loading || loadingDismissed}
                style={{ marginRight: 10 }}
                disabled={loading || loadingDismissed}
                size="small"
                color="secondary"
                text={t('contingentSchool_disposal')}
                onClick={() => disposalModal.open(true)}
              />
            }
            {
               data.contingentStatus !== CONTINGENT_SECTION_STATUS.LEFT_SECTION && 
               <Button
                icon={<SaveIcon fontSize="small" style={{marginRight: 10}}/>}
                loading={loading || loadingSave}
                style={{ backgroundColor: Color.bgColorSuccess }}
                disabled={loading || loadingSave}
                size="small"
                color="primary"
                text={id ? t('contingentSchool_changeBtn') : t('contingentSchool_addBtn')}
                onClick={onAddSchool}
              />
            }
          </ButtonsWrapper>
          {addModal.isOpen && <ModalAdd {...addModal} studentIIN = {studentIIN} close={addModal.close} id={id} />}
          {disposalModal.isOpen && <ModalDisposal {...disposalModal} studentIIN = {studentIIN} close={disposalModal.close} id={id} />}
        </ErrorBoundary>
      </Wrapper>
    </PageLoading>
  );
}
