import React from 'react';
import Modal from '../../_ui/Modal/Modal';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import Button from '../../_ui/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { kezekDirectionModule, approveDirection } from './KezekDirectionDucks';
import DoneIcon from '@material-ui/icons/Done';
import CancelIcon from '@material-ui/icons/Cancel';
import { Color } from '../../components/ThemeProvider/ThemeStyle';

export default function ModalApprove({ data: id, close }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loading = useSelector(state => state[kezekDirectionModule].loadingApprove);
  const hasPrivilege = useSelector(
    state => state[kezekDirectionModule].data.hasPrivilege
  );
  return (
    <Modal
      title={
        hasPrivilege
          ? t('kezekDirection_approveQuestion')
          : t('kezekDirection_approveTitle')
      }
      open
      onClose={close}
    >
      <DialogContent>
        <div className="flex justify-between pb2">
          <Button
            color="primary"
            style={{ backgroundColor: Color.bgColorSuccess }}
            loading={loading}
            icon={<DoneIcon fontSize="small" className="mr1" />}
            text={
              hasPrivilege
                ? t('kezekDirection_approveYesPrev')
                : t('kezekDirection_approveYes')
            }
            onClick={() =>
              dispatch(
                approveDirection(id, hasPrivilege ? 'confirmed' : 'not_benefits', close)
              )
            }
          />
          {hasPrivilege && (
            <Button
              color="secondary"
              loading={loading}
              icon={<CancelIcon fontSize="small" className="mr1" />}
              text={t('kezekDirection_approveNo')}
              onClick={() => dispatch(approveDirection(id, 'not_confirmed', close))}
            />
          )}
        </div>
        {hasPrivilege && (
          <div className="center px2 pb2">{t('kezekDirection_approveText')}</div>
        )}
      </DialogContent>
    </Modal>
  );
}
