import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import useTableFilter from '../../components/TableFilter/useTableFilter';
import { serviceProvidersColumns } from './ServiceProvidersColumns';
import { useTranslation } from 'react-i18next';
import {
  serviceProvidersModule,
  loadTableData,
  clearState,
  loadChild
} from './ServiceProvidersDucks';
import TableSubComponent from './TableSubComponent';
import useTableExpander from './useTableExpander';
import TableFilterProvider from '../../components/TableFilter/TableFilterProvider';
import { Table } from './ServiceProvidersStyles';
import useHasAccess from '../../components/useHasAccess';
import permissions from '../../_helpers/permissions';

const ServiceProvidersTable = ({
  loading,
  tableData,
  loadTableData,
  loadingChild,
  clearState,
  config = {},
  loadChild,
  index,
  ...rest
}) => {
  const { t, i18n } = useTranslation();
  const filter = useTableFilter(loadTableData, undefined, index);
  useEffect(() => clearState, [clearState]);
  const { expanded, editExpanded } = useTableExpander();
  const canEdit = useHasAccess(permissions.serviceProviderPage);

  const subComponent = row => {
    return (
      <TableSubComponent
        config={config}
        data={row.original}
        loadChild={loadChild}
        {...rest}
      />
    );
  };

  return (
    <TableFilterProvider index={filter.index}>
      <Table
        loading={loading}
        columns={serviceProvidersColumns(
          t,
          i18n.language,
          { ...config, canEdit },
          editExpanded,
          loadingChild
        )}
        data={tableData.content}
        totalElements={tableData.totalElements}
        onClickLoadMore={filter.nextPage}
        expanded={expanded}
        SubComponent={subComponent}
        {...rest}
      />
    </TableFilterProvider>
  );
};

export default connect(
  state => ({
    loading: state[serviceProvidersModule].loading,
    loadingChild: state[serviceProvidersModule].loadingChild,
    tableData: state[serviceProvidersModule].tableData
  }),
  {
    loadTableData,
    clearState,
    loadChild
  }
)(ServiceProvidersTable);
