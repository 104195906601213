import React, { useEffect, useState } from 'react';
import Modal from '../../_ui/Modal/Modal';
import Dict from '../../utils/Dict';
import { compose } from 'redux';
import withTranslation from '../../components/_hoc/withTranslation';
import StaticTablePagination from '../../_ui/Table/StaticTablePaginated';
import { handbookItemOptionColumns } from './HandbookItemOptionColumns';
import Row from '../../_ui/Row';
import moment from 'moment';

function HandbookViewModal({ t, modal }) {
  const [dictOptions, setDictOptions] = useState([]);
  const { data, close } = modal;

  useEffect(() => {
    Dict.items(data.code).then(dictOptions => setDictOptions(dictOptions));
  }, [data]);

  return (
    <Modal
      open
      title={t('handbookPage_data_title')}
      onClose={close}
      maxWidth="lg"
      scroll="body"
    >
      <div className="px2 pb3">
        <div className="pb1">
          <Row label={t('handbookPage_columnCode')} children={data.code} />
          {data.description && (
            <Row
              label={t('handbookPage_columnDescription')}
              children={data.description}
            />
          )}
          {data.kkName && (
            <Row label={t('handbookPage_columnKkName')} children={data.kkName} />
          )}
          {data.ruName && (
            <Row label={t('handbookPage_columnRuName')} children={data.ruName} />
          )}
          {data.lastUpdated && (
            <Row
              label={t('handbookPage_columnLastUpdated')}
              children={moment(data.lastUpdated).format('DD.MM.YYYY HH:mm')}
            />
          )}
        </div>
        <StaticTablePagination
          columns={handbookItemOptionColumns({ t })}
          totalElements={dictOptions.length}
          data={dictOptions}
          sortable={false}
        />
      </div>
    </Modal>
  );
}

export default compose(withTranslation)(HandbookViewModal);
