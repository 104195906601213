import React, { useEffect, useState } from 'react';
import { Wrapper } from './StatementStyle';
import StatementInfo from './StatementInfo';
import { useSelector, useDispatch } from 'react-redux';
import { statementModule, loadStatement, clearState } from './StatementDucks';
import PageLoader from '../../components/PageLoader/PageLoader';
import Divider from '@material-ui/core/Divider';
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary';
import OperationsButtons from './Operations/OperationsButtons';
import OperationsModals from './Operations/OperationsModals';
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs';
import { ErrorMessage } from './StatementStyle';
import Paper from '@material-ui/core/Paper';
import { P_STATUS } from '../../_helpers/Constants';
import { StatementApi } from '../../_helpers/service';
import { PassportForm, PassportProvider } from '../../passport/Passport';
import { PassportExpander, PassportNavigation } from '../../passport/Passport';
import StatementNegativeStatic from './StatementNegativeStatic';
import './StatementTranslate';
import useSimpleModal from '../../components/_hooks/useSimpleModal';
import { Color } from '../../components/ThemeProvider/ThemeStyle';
import PreviewDocumentModal from '../../components/Modals/PreviewDocumentModal/PreviewDocumentModal';
import { useTranslation } from 'react-i18next';
import ServiceProvidersModal from './ServiceProvidersModal';
import Button from '../../_ui/Button/Button';
import Access from '../../components/Access';
import permissions from '../../_helpers/permissions';
import BusinessProcess from './BusinessProcess/BusinessProcess';
import TextField from '@material-ui/core/TextField';
import Row from '../../passport/Components/Row';

export default function StatementPage({ match }) {
  const { t, i18n } = useTranslation();
  const modal = useSimpleModal();
  const dispatch = useDispatch();
  const error = useSelector(state => state[statementModule].error);
  const loading = useSelector(state => state[statementModule].loading);
  const { info, loadingInfo } = useSelector(state => state[statementModule]);
  const requestForm = useSelector(state => state[statementModule].requestForm);
  const requestFormMeta = useSelector(state => state[statementModule].requestFormMeta);
  const requester = useSelector(state => state[statementModule].requester);
  const requesterMeta = useSelector(state => state[statementModule].requesterMeta);
  const changeProviderModal = useSimpleModal();
  const [previewStatementOpen, setPreviewStatementOpen] = useState(false);
  const status = info.requestForm.processingStatus;
  const readOnly = window.location.pathname.indexOf('/statementView') !== -1;

  let breadCrumbItems = [
    { name: t('breadcrumbs_home'), href: '/' },
    !readOnly && { name: t('breadcrumbs_journal'), href: '/journal' },
    { name: t('statement_title') }
  ];

  useEffect(() => {
    dispatch(loadStatement(match.params.id));
    return () => dispatch(clearState());
  }, [dispatch, match.params.id]);

  const onChangeProvider = () => {
    changeProviderModal.open({
      id: match.params.id,
      serviceProviderId: info.requestForm.serviceProviderId
    });
  };

  const canDownloadStatement =
    requestFormMeta.id &&
    requestFormMeta.id.toUpperCase() === 'REQUEST_FORM_DUPLIC_EDU' &&
    [P_STATUS.IN_PROGRESS, P_STATUS.READY_FOR_SIGN, P_STATUS.COMPLETED].includes(status);

  const showSignatureReason =
    requestFormMeta.id === 'request_form_doc_queued_ddo'
      ? [P_STATUS.IN_PROGRESS, P_STATUS.READY_FOR_SIGN].includes(requestForm.processingStatus)
      : true;

  if (loading) return <PageLoader />;
  if (error) {
    return (
      <>
        <BreadCrumbs items={breadCrumbItems} />
        <Paper className="p3">
          <ErrorMessage>
            <code>{error}</code>
          </ErrorMessage>
        </Paper>
      </>
    );
  }

  return (
    <>
      <BreadCrumbs items={breadCrumbItems} />
      <Wrapper className="p3">
        <ErrorBoundary>
          <div className="title">{info[`${i18n.language}_name`]}</div>
          <Divider />
          <PassportProvider
            info={info}
            withoutScroll={false}
            stickyTop={Color.headerHeight}
            data={[requester, requestForm]}
            meta={[requesterMeta, requestFormMeta]}
            config={{
              documentsProps: {
                mimeType: 'image/jpeg,image/png,application/pdf',
                maxSize: 10000000,
              },
            }}
          >
            <PassportExpander />
            <div className="flex">
              <div className="left-info">
                <StatementInfo />
                {canDownloadStatement && (
                  <Button
                    fullWidth
                    color="default"
                    style={{ marginBottom: 10 }}
                    loading={loadingInfo}
                    text={t('statement_downloadStatement')}
                    onClick={() => setPreviewStatementOpen(true)}
                  />
                )}
                {!readOnly && (
                  <Access ps={permissions.CAN_CHANGE_STATEMENT_SERVICE_PROVIDER}>
                    <div className="mb2">
                      <Button
                        fullWidth
                        loading={loadingInfo}
                        text={t('statement_changeServiceProvider')}
                        onClick={onChangeProvider}
                      />
                    </div>
                  </Access>
                )}
                {!readOnly && (
                  <BusinessProcess
                    disabled={status !== P_STATUS.IN_PROGRESS}
                    boMeta={requestFormMeta}
                    boData={requestForm}
                  />
                )}
                <PassportNavigation />
              </div>
              <div className="ml2 flex-auto">
                <PassportForm
                  loading={loading}
                  disabled={readOnly || status !== P_STATUS.IN_PROGRESS}
                />

                {info.requestForm.resolutionType === 'NEGATIVE' && (
                  <StatementNegativeStatic info={info.requestForm.negativeResolution} />
                )}
                {showSignatureReason && requestForm.declineSignatureReason && (
                  <Row label={t('statement_declineSignatureReason')}>
                    <TextField
                      disabled
                      placeholder={t('statement_declineSignatureReason')}
                      value={requestForm.declineSignatureReason}
                      multiline
                    />
                  </Row>
                )}
                {!readOnly && <OperationsButtons modal={modal} />}

                {info.buttonRules.impossibleProcMsg && (
                  <div className="st-error">{t('statement_acceptDocEduDisable')}</div>
                )}
              </div>
              {modal.data && <OperationsModals {...modal} />}
            </div>
          </PassportProvider>
          {changeProviderModal.isOpen && (
            <ServiceProvidersModal modal={changeProviderModal} />
          )}
          {previewStatementOpen && (
            <PreviewDocumentModal
              onClose={() => setPreviewStatementOpen(false)}
              preview={() => StatementApi.previewStatement(match.params.id)}
              download={() => StatementApi.downloadStatement(match.params.id)}
            />
          )}
        </ErrorBoundary>
      </Wrapper>
    </>
  );
}
