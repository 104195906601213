import React from 'react';
import { ErrorMessage } from './BadGatewayStyle';
import { useSelector } from 'react-redux';
import { badGatewayModule } from './BadGatewayDucks';
import { useTranslation } from 'react-i18next';
import './BadGatewayTranslate';
export default function BadGateway({ children }) {
  const { t } = useTranslation();
  const error = useSelector(state => state[badGatewayModule].error);
  if (error) {
    return (
      <div style={{ display: 'flex', position: 'relative', height: '100vh' }}>
        <ErrorMessage>{t('errors_BAD_GATEWAY')}</ErrorMessage>
      </div>
    );
  } else {
    return children;
  }
}
