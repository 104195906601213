import React, { useState } from 'react';
import Modal from '../../../_ui/Modal/Modal';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import Button from '../../../_ui/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { kezekBulletinModule, revokeBulletin } from '../KezekBulletinDucks';
import CancelIcon from '@material-ui/icons/Cancel';
import Form from '../../../_ui/Form/Form';
import Row from '../../../_ui/Row';
import Select from '../../../_ui/Select/Select';
import Input from '../../../_ui/Input/Input';
import * as Yup from 'yup';
import i18next from 'i18next';

const ValidationSchema = Yup.object().shape({
  reasonDeletion: Yup.string().required(() =>
    i18next.t('kezekBulletinPage_requiredField')
  ),
  deletedPlaces: Yup.string().required(() =>
    i18next.t('kezekBulletinPage_requiredField')
  ),
  reasonDeletionTextRu: Yup.string().when(['reasonDeletion'], {
    is: reasonDeletion => reasonDeletion === '005',
    then: Yup.string().required(() => i18next.t('kezekBulletinPage_requiredField'))
  }),
  reasonDeletionTextKk: Yup.string().when(['reasonDeletion'], {
    is: reasonDeletion => reasonDeletion === '005',
    then: Yup.string().required(() => i18next.t('kezekBulletinPage_requiredField'))
  }),
});

export default function ModalCreateRevoke({ data: id, close }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const data = useSelector(state => state[kezekBulletinModule].data);
  const loading = useSelector(state => state[kezekBulletinModule].loadingRevoke);
  const [hasError, setHasError] = useState(false);
  const [showReason, setShowReason] = useState(false);
  return (
    <Modal title={t('kezekBulletinPage_createRevokeTitle')} open onClose={close}>
      <Form
        initialValues={{
          reasonDeletion: '',
          deletedPlaces: '',
          reasonDeletionTextRu: '',
          reasonDeletionTextKk: ''
        }}
        onSubmit={values => !hasError && dispatch(revokeBulletin(id, values, close))}
        validate={ValidationSchema}
      >
        <DialogContent dividers>
          <Row label={t('kezekBulletinPage_reasonDeletion')}>
            <Select
              name="reasonDeletion"
              lookupId="gu_reason_deletion"
              placeholder={t('kezekBulletinPage_reasonDeletion')}
              onChange={event => {
                const value = event.target.value;
                if (value === '005' && !showReason) setShowReason(true);
                else if (showReason) setShowReason(false);
              }}
            />
          </Row>
          <Row label={t('kezekBulletinPage_deletedPlaces')} className="flex items-start">
            <Input
              name="deletedPlaces"
              placeholder={t('kezekBulletinPage_deletedPlaces')}
              type="number"
              onChange={event => {
                let value = event.target.value;
                if (value && +data.availableSeats < +value && !hasError) {
                  setHasError(true);
                } else if (hasError) {
                  setHasError(false);
                }
              }}
              error={hasError}
              helperText={hasError ? t('kezekBulletinPage_errorDeletedPlaces') : ''}
            />
          </Row>
          {showReason && (
            <>
              <Row
                label={t('kezekBulletinPage_reasonDeletionTextRu')}
                className="flex items-start"
              >
                <Input
                  name="reasonDeletionTextRu"
                  placeholder={t('kezekBulletinPage_reasonDeletionTextRu')}
                  multiline
                />
              </Row>
              <Row
                label={t('kezekBulletinPage_reasonDeletionTextKk')}
                className="flex items-start"
              >
                <Input
                  name="reasonDeletionTextKk"
                  placeholder={t('kezekBulletinPage_reasonDeletionTextKk')}
                  multiline
                />
              </Row>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            icon={<CancelIcon fontSize="small" className="mr1" />}
            text={t('kezekBulletinPage_revoke')}
            type="submit"
            loading={loading}
          />
        </DialogActions>
      </Form>
    </Modal>
  );
}
