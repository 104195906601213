import { createReducer } from 'redux-starter-kit';
import { handleError } from '../../utils/handleError';
import { GroupApi } from '../../_helpers/service';
import Notice from '../../utils/Notice';
import i18next from 'i18next';
import Dict from '../../utils/Dict';
import lodash from 'lodash'


/**
 * Constants
 */

export const groupListModule = 'groupList';
const LOADING = `${groupListModule}/LOADING`;
const SET_DATA = `${groupListModule}/SET_DATA`;
const CLEAR_STATE = `${groupListModule}/CLEAR_STATE`;
const SET_CHILD = `${groupListModule}/SET_CHILD`;
const FREE_KIDS = `${groupListModule}/FREE_KIDS`;

/**
 * Reducer
 */

const initialState = {
  loading: false,
  data: {},
  child: {
    content: [],
    totalElements: 0
  },
  freeKids: [],
};

export default createReducer(initialState, {
  [SET_DATA]: (state, action) => {
    state.data = action.payload;
  },
  [SET_CHILD]: (state, action) => {
    const filter = action.filter || {};
    if (filter.pageNumber && filter.pageNumber !== 1) {
      state.child.content = [...state.child.content, ...action.payload.content];
    } else {
      state.child = action.payload;
    }
  },
  [FREE_KIDS]: (state, action) => {
    state.freeKids = action.payload;
  },   
  [LOADING]: (state, action) => {
    state.loading = action.payload;
  },
  [CLEAR_STATE]: () => initialState
});

/**
 * Actions
 */

export const loadData = ({id}) => async dispatch => {
  try {
    dispatch({ type: LOADING, payload: true });
    let { data } = await GroupApi.getItemGroup({id});
    let info = await GroupApi.loadRequestForm(data.serviceProviderId);
    const dictAcademicYear = await Dict.itemsObject('gu_academic_year_do');
    const dictlanguage = await Dict.itemsObject('gu_language');
    const dictKato = await Dict.itemsObject('60');
    data['language'] = dictlanguage[lodash.get(data, 'questionnaire.questionCodeToAnswers.language.values[0].value', null)] || {};
    data['academicYearName'] = dictAcademicYear[lodash.get(data, 'questionnaire.questionCodeToAnswers.begin_academic_year.values[0].value', null)] || {};
    data['nameGroup'] = lodash.get(data, 'questionnaire.questionCodeToAnswers.name_group.values[0].value', null);
    data['stateOrder'] = lodash.get(data, 'questionnaire.questionCodeToAnswers.state_order.values[0].value', null);
    data['location'] = dictKato[lodash.get(info, 'data.questionnaire.questionCodeToAnswers.legal_locality.values[0].value', null)] || {};
    data['bin'] = info.data.bin;
    data['numberOrg'] = info.data.id;
    dispatch({type: SET_DATA, payload: data})
  } catch (error) {
    handleError(error, i18next.t('groupListPage_errorLoadTable'));
  } finally {
    dispatch({ type: LOADING, payload: false });
  }
};

export const getGroupChild = ({filter, id}) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const {data} = await GroupApi.getGroupChild({filter: JSON.stringify(filter), id});
    const dictAgeGroup = await Dict.itemsObject('gu_age_group');
    const dictQueuedType = await Dict.itemsObject('gu_queued_type');
    const dictDirectionStatus = await Dict.itemsObject('gu_status_direction');
    const dictAcademicYear = await Dict.itemsObject('gu_academic_year_do');
    const dictlanguage = await Dict.itemsObject('gu_language');
    const dictPrivilege = await Dict.itemsObject('gu_privilege');  
      for (let row of data.result.content) {
        row.ageGroupName = dictAgeGroup[row.ageGroup] || {};
        row.queuedTypeName = dictQueuedType[row.queuedType] || {};
        row.directionStatus = dictDirectionStatus[row.statusDirection] || {};
        row.academicYearName = dictAcademicYear[row.beginAcademicYear] || {};
        row.languageChild = dictlanguage[row.language] || {};
        row.privilege = dictPrivilege[row.privilegeType] || {};
      }
    dispatch({ type: SET_CHILD, payload: data.result, filter });
  } catch (error) {
    handleError(error, i18next.t('groupListPage_errorLoadChild'));
  } finally {
    dispatch({ type: LOADING, payload: false });
  }
}

export const getFreeKids = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const {data} = await GroupApi.getStudyGroup(id);
    dispatch({ type: FREE_KIDS, payload: data.result })
  } catch (error) {
    handleError(error, i18next.t('groupListPage_errorLoadChild'));
  } finally {
    dispatch({ type: LOADING, payload: false });
  }
} 

export const addChild = ({ groupId, childId, filter, id }) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const data = await GroupApi.addChildGroup({groupId, params: {id: childId}})
    if (data.status === 200) {
      dispatch(getGroupChild({ filter, id }))  
      Notice.success(i18next.t('groupListPage_saveFormSuccess'));
    }
  } catch (error) {
    handleError(error, i18next.t('groupListPage_errorSaveForm'));
  } finally {
    dispatch({ type: LOADING, payload: false });    
  }
}

export const clearState = () => ({ type: CLEAR_STATE });
