import React from 'react';
import moment from 'moment';

const styles = {
  textAlign: 'center',
  alignSelf: 'center'
};

export function GroupListColumns({ t, lang }) {
  return [
    {
      Header: '№',
      accessor: 'id',
      filterable: false,
      width: 60,
      style: styles,
    },
    {
      Header: t('groupListPage_enrollmentDate'),
      accessor: 'enrollmentDate',
      filterable: false,
      Cell: row => moment(row.original.enrolledDate).format('DD.MM.YYYY'),
      style: styles,
    },
    {
      Header: t('groupListPage_childIin'),
      accessor: 'childIin',
      filterable: false,
      style: styles,
    },
    {
      Header: t('groupListPage_fullName'),
      accessor: 'childName',
      filterable: false,
      Cell: ({ original }) => {
        return (
          <div>
            { original?.childName } {original?.childSurname} {original?.childMiddlename}
          </div>
        ) 
      }},
    {
      Header: t('groupListPage_ageGroup'),
      accessor: 'age_group',
      filterable: false,
      style: styles,
      Cell: ({ original: { ageGroupName } }) => ageGroupName[`${lang}_name`],
    },
    {
      Header: t('groupListPage_queuedType'),
      accessor: 'queuedType',
      filterable: false,
      style: styles,
      Cell: ({ original: { queuedTypeName } }) => queuedTypeName[`${lang}_name`],
    },
    {
      Header: t('groupListPage_privilegeType'),
      accessor: 'privilege_type',
      Cell: ({ original: { privilege } }) => privilege[`${lang}_name`],
      filterable: false,
      style: styles,
    },
    {
      Header: t('groupsTablePage_beginAcademicYear'),
      accessor: 'beginAcademicYear',
      filterable: false,
      style: styles,
      Cell: ({ original: { academicYearName } }) => academicYearName[`${lang}_name`],
    },
    {
      Header: t('groupListPage_columnLanguage'),
      accessor: 'language',
      filterable: false,
      style: styles,
      Cell: ({ original: { languageChild } }) => languageChild[`${lang}_name`],
    },
  ];
}
