import { createReducer } from 'redux-starter-kit';
import { ServiceProvidersApi } from '../../_helpers/service';
import { handleError } from '../../utils/handleError';
import Notice from '../../utils/Notice';
import i18next from 'i18next';
import { history } from '../../_helpers/history';

export const serviceProviderModule = 'serviceProvider';
const LOADING = `${serviceProviderModule}/LOADING`;
const SET_INFO = `${serviceProviderModule}/SET_INFO`;
const ERROR = `${serviceProviderModule}/ERROR`;
const LOADING_SAVE = `${serviceProviderModule}/LOADING_SAVE`;
const CLEAR_STATE = `${serviceProviderModule}/CLEAR_STATE`;

/**
 * Reducer
 */

const initialState = {
  loading: false,
  info: {},
  data: {},
  metadata: {},
  error: null,
  loadingSave: false
};

export default createReducer(initialState, {
  [LOADING]: (state, action) => {
    state.loading = action.payload;
  },
  [SET_INFO]: (state, action) => {
    const { info, metadata, data } = action.payload;
    state.info = info;
    state.metadata = metadata;
    state.data = data;
  },
  [ERROR]: (state, action) => {
    state.error = action.payload;
  },
  [LOADING_SAVE]: (state, action) => {
    state.loadingSave = action.payload;
  },
  [CLEAR_STATE]: () => initialState
});

/**
 * Actions
 */

export const loadData = id => async dispatch => {
  dispatch({ type: LOADING, payload: true });

  try {
    let { data: metadata } = await ServiceProvidersApi.loadMetaData();

    if (id) {
      let { data } = await ServiceProvidersApi.load(id);

      let info = {
        id: data.id,
        bin: data.bin,
        iin: data.iin,
        nedbId: data.nedbId,
        ru_name: metadata.ru_name,
        kk_name: metadata.kk_name,
        en_name: metadata.en_name,
        metadataKey: metadata.id,
        parentOrg: {
          id: data.parentServiceProviderId,
          kk_name: data.kk_parentServiceProviderName,
          ru_name: data.ru_parentServiceProviderName
        }
      };

      dispatch({ type: SET_INFO, payload: { info, metadata, data } });
    } else {
      let info = {
        id: '',
        bin: '',
        iin: '',
        nedbId: '',
        ru_name: metadata.ru_name,
        kk_name: metadata.kk_name,
        en_name: metadata.en_name,
        metadataKey: metadata.id,
        parentOrg: {}
      };
      dispatch({ type: SET_INFO, payload: { info, metadata, data: {} } });
    }
  } catch (error) {
    handleError(error, i18next.t('serviceProvider_errorLoad'));
    dispatch({ type: ERROR, payload: JSON.stringify(error, null, 4) });
  } finally {
    dispatch({ type: LOADING, payload: false });
  }
};

export const onSave = (info, values) => async dispatch => {
  try {
    dispatch({ type: LOADING_SAVE, payload: true });

    let response = await ServiceProvidersApi.updateServiceProvider({
      id: info.id,
      bin: info.bin,
      iin: info.iin,
      nedbId: info.nedbId,
      parentServiceProviderId: info.parentServiceProviderId,
      serviceProviderChanges: values[info.metadataKey]
    });

    if (response.data.results.length === 0) {
      Notice.success(i18next.t('serviceProvider_saveFormSuccess'));
    } else {
      handleError(response.data, i18next.t('serviceProvider_errorSaveForm'), false);
    }
  } catch (error) {
    handleError(error, i18next.t('serviceProvider_errorSaveForm'));
  } finally {
    dispatch({ type: LOADING_SAVE, payload: false });
  }
};

export const onCreate = (info, values) => async dispatch => {
  dispatch({ type: LOADING_SAVE, payload: true });

  try {
    let response = await ServiceProvidersApi.createServiceProvider({
      bin: info.bin,
      iin: info.iin,
      nedbId: info.nedbId,
      parentServiceProviderId: info.parentServiceProviderId,
      serviceProviderChanges: values[info.metadataKey]
    });

    if (response.data.results.length === 0) {
      Notice.success(i18next.t('serviceProvider_saveFormSuccess'));
      history.push(`/serviceProviders/edit/${response.data.entity.id}`);
    } else {
      handleError(response.data, i18next.t('serviceProvider_errorSaveForm'), false);
    }
  } catch (error) {
    handleError(error, i18next.t('serviceProvider_errorSaveForm'));
  } finally {
    dispatch({ type: LOADING_SAVE, payload: false });
  }
};

export const clearState = () => ({ type: CLEAR_STATE });
