import { useState, useEffect } from 'react';
import Dict from '../../utils/Dict';

export default function useDict(code, isObject) {
  const [dictList, setDictList] = useState(isObject ? {} : []);
  useEffect(() => {
    if (code) {
      let isCancelled = false;
      if (isObject) {
        Dict.itemsObject(code).then(list => {
          if (!isCancelled) {
            setDictList(list);
          }
        });
      } else {
        Dict.items(code).then(list => {
          if (!isCancelled) {
            setDictList(list);
          }
        });
      }

      return () => {
        isCancelled = true;
      };
    }
  }, [code, isObject]);
  return dictList;
}
