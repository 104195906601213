import moment from 'moment';
import React from 'react';
import FilterInputField from '../../components/TableFilter/ColumnsFilters/FilterInputField';
import FilterDatePicker from '../../components/TableFilter/ColumnsFilters/FilterDatePicker';
import ColumnSorting from '../../components/TableFilter/ColumnsFilters/ColumnSorting';
import FilterSelect from '../../components/TableFilter/ColumnsFilters/FilterSelect';
import { capitalize } from 'lodash';
import FilterTreeSelect from '../../components/TableFilter/ColumnsFilters/FilterTreeSelect/FilterTreeSelect';

export function kezekRequestColumns({ t, lang }) {
  return [
    {
      Header: '№',
      accessor: 'rowNumber',
      width: 60,
      filterable: false
    },
    {
      Header: <ColumnSorting title={t('kezekRequest_columnID')} name='id' />,
      accessor: 'id',
      width: 80,
      style: { textAlign: 'center' },
      Filter: <FilterInputField name='id' type='number' />
    },
    {
      Header: <ColumnSorting title={t('kezekRequest_columnDate')} name='revokeRequestDate' />,
      accessor: 'revokeRequestDate',
      Cell: row => row.original.revokeRequestDate ? moment(row.original.revokeRequestDate).format('DD.MM.YYYY HH:mm') : row.original.revokeRequestDate,
      width: 120,
      sortable: false,
      style: { textAlign: 'center' },
      Filter: <FilterDatePicker name='revokeRequestDate' lang={lang} />
    },
    {
      Header: t('kezekRequest_columnServiceProvider'),
      accessor: 'serviceProviderName',
      sortable: false,
      Cell: ({ original }) => original[`serviceProvider${capitalize(lang)}Name`],
      Filter: <FilterInputField name="serviceProviderName" />
    },
    {
      Header: t('kezekRequest_columnGroupName'),
      accessor: 'nameGroupName',
      sortable: false,
      Cell: ({ original }) => original[`nameGroup${capitalize(lang)}`],
      Filter: <FilterInputField name="nameGroup" />
    },
    {
      Header: <ColumnSorting title={t('kezekRequest_columnAgeGroup')} name='ageGroup' />,
      accessor: 'ageGroup ',
      Cell: ({ original: { ageGroupName } }) => ageGroupName[`${lang}_name`],
      Filter: <FilterSelect name='ageGroup' dictCode='gu_age_group' lang={lang} />
    },
    {
      Header: <ColumnSorting title={t('kezekRequest_columnQueuedType')} name='queuedType' />,
      accessor: 'queuedType ',
      Cell: ({ original: { queuedTypeName } }) => queuedTypeName[`${lang}_name`],
      Filter: <FilterSelect name='queuedType' dictCode='gu_queued_type' lang={lang} />
    },
    {
      Header: <ColumnSorting title={t('kezekRequest_columnTypeBulletin')} name='typeBulletin' />,
      accessor: 'typeBulletin ',
      Cell: ({ original: { bulletinTypeName } }) => bulletinTypeName[`${lang}_name`],
      Filter: <FilterSelect name='typeBulletin' dictCode='gu_type_bulletin' lang={lang} />
    },
    {
      Header: t('kezekRequest_columnNumberSeats'),
      accessor: 'numberSeats',
      style: { textAlign: 'right' },
      width: 150,
      Filter: <FilterInputField name='numberSeats' type='number' />
    },
    {
      Header: t('kezekRequest_columnDeletedPlaces'),
      accessor: 'deletedPlaces',
      width: 150,
      style: { textAlign: 'right' },
      Filter: <FilterInputField name='deletedPlaces' type='number' />
    },
    {
      Header: t('kezekRequest_columnAcademicYear'),
      accessor: 'beginAcademicYear',
      Cell: ({ original: { academicYearName } }) => academicYearName[`${lang}_name`],
      style: { textAlign: 'center' },
      width: 150,
      Filter: <FilterSelect name='beginAcademicYear' dictCode='gu_academic_year_do' lang={lang} />
    },
    {
      Header: <ColumnSorting title={t('kezekRequest_columnApprovalStatus')} name='approvalStatus' />,
      accessor: 'approvalStatus ',
      Cell: ({ original: { approvalStatusName } }) => approvalStatusName[`${lang}_name`],
      Filter: <FilterSelect name='approvalStatus' dictCode='gu_approval_status' lang={lang} />
    },
    {
      Header: <ColumnSorting title={t('kezekRequest_columnReasonDeletion')} name='reasonDeletion' />,
      accessor: 'reasonDeletion ',
      Cell: ({ original: { reasonDeletionName } }) => reasonDeletionName[`${lang}_name`],
      Filter: <FilterSelect name='reasonDeletion' dictCode='gu_reason_deletion' lang={lang} />
    },
    {
      Header: t('kezekRequest_columnBin'),
      accessor: 'serviceProviderBin',
      width: 150,
      style: { textAlign: 'center' },
      Filter: <FilterInputField name='serviceProviderBin' type='number' />
    },
    {
      Header: t('kezekRequest_columnServiceProviderLocation'),
      accessor: 'serviceProviderLocation',
      Cell: ({ original: { serviceProviderLocation } }) => serviceProviderLocation[`${lang}_name`],
      Filter: (
        <FilterTreeSelect lookupId='60' name='serviceProviderLocation' lang={lang} />
      )
    },
  ];
}
