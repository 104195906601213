import React, { useEffect, useState } from 'react';
import { ErrorMessage } from './chekTabNumbersStyle';
import './ChekTabNumbersTranslate';
import { compose } from 'redux';
import withTranslation from '../_hoc/withTranslation';
function CheckTabNumbers({ children, t }) {
  const [closeTab, setCloseTab] = useState(false);
  useEffect(() => {
    if (localStorage.getItem('tabClose') === 'true') {
      setCloseTab(true);
    } else {
      setCloseTab(false);
    }
  }, []);
  if (closeTab) {
    return <ErrorMessage>{t('error_window_number_tab')}</ErrorMessage>;
  } else {
    return children;
  }
}
export default compose(withTranslation)(CheckTabNumbers);
