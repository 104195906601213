import _ from 'lodash';
import i18next from 'i18next';
import { handleError } from '../../../utils/handleError';
import Notice from '../../../utils/Notice';

export const handleTaskError = (error, translateMessage, showNotice = true) => {
  try {
    const errorCode = _.get(error, 'response.data.errors[0].code');
    const errorCode2 = _.get(error, 'response.data.status');
    if ([errorCode, errorCode2].includes('ACCESS_RIGHTS_NOT_ENOUGH')) {
      if (showNotice) {
        Notice.error(i18next.t('taskDucks_errorLoad403'));
      }
      return i18next.t('taskDucks_errorLoad403');
    } else {
      return handleError(error, translateMessage);
    }
  } catch (e) {
    return handleError(e, translateMessage);
  }
};
