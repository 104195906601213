import React, { useEffect } from 'react';
import { ButtonsWrapper } from '../../MyTaskStyles';
import Button from '../../../../_ui/Button/Button';
import SaveIcon from '@material-ui/icons/Save';
import { questionnaireTaskModule as module } from './QuestionnaireTaskDucks';
import { loadData, complete, save, clearState } from './QuestionnaireTaskDucks';
import { Color } from '../../../../components/ThemeProvider/ThemeStyle';
import CompleteIcon from '@material-ui/icons/DoneAll';
import { useDispatch, useSelector } from 'react-redux';
import { TASK_STATUS } from '../../../../_helpers/Constants';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';
import {
  PassportProvider,
  PassportForm,
  getFormValues
} from '../../../../passport/Passport';
import TaskInfo from '../TaskInfo';
import Notice from '../../../../utils/Notice';
import useUser from '../../../LoginPage/useUser';

function QuestionnaireTask({ taskId }) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const loading = useSelector(state => state[module].loading);
  const loadingAction = useSelector(state => state[module].loadingAction);
  const info = useSelector(state => state[module].info) || {};
  const shortInfo = useSelector(state => state[module].shortInfo) || {};
  const { data, configuration, status, staticFields } = info;
  const isDisabled = status !== TASK_STATUS.IN_PROGRESS;
  const metaData = configuration['taskMetadata'] || {};
  const user = useUser();

  useEffect(() => {
    dispatch(loadData(taskId));
    return () => dispatch(clearState());
  }, [taskId, dispatch]);

  return (
    <>
      <h3>{configuration[`name${capitalize(i18n.language)}`]}</h3>
      <TaskInfo {...info} shortInfo={shortInfo} />
      <PassportProvider
        info={staticFields}
        data={data['taskBusinessObject'] || { metadataKey: metaData.id }}
        meta={metaData}
        config={{
          documentsProps: {
            mimeType: 'image/jpeg,image/png,application/pdf',
            maxSize: 10000000,
          },
        }}
      >
        <PassportForm
          loading={loading}
          disabled={isDisabled}
          buttonRender={() =>
            !isDisabled && (
              <ButtonsWrapper>
                {configuration.actions.map(action => {
                  switch (action.action) {
                    case 'save':
                      return (
                        <Button
                          icon={<SaveIcon fontSize="small" className="mr1" />}
                          size="small"
                          text={action.renderer[`caption${capitalize(i18n.language)}`]}
                          loading={loadingAction === action.id}
                          disabled={loadingAction}
                          onClick={() => {
                            getFormValues().then(fv => {
                              if (fv) {
                                const changes = fv[metaData.id];
                                dispatch(save(changes, action.id));
                              }
                            });
                          }}
                        />
                      );
                    case 'submit':
                      return (
                        <Button
                          loading={loadingAction === action.id}
                          size="small"
                          style={{ backgroundColor: Color.bgColorSuccess }}
                          disabled={loadingAction}
                          icon={<CompleteIcon style={{ marginRight: 5 }} />}
                          text={action.renderer[`caption${capitalize(i18n.language)}`]}
                          onClick={() => {
                            getFormValues(false, true).then(fv => {
                              if (fv) {
                                const changes = fv[metaData.id];
                                dispatch(complete(changes, action.id, user));
                              } else {
                                Notice.warning(t('myTaskPage_requiredFields'))
                              }
                            });
                          }}
                        />
                      );
                    default:
                      return null;
                  }
                })}
              </ButtonsWrapper>
            )
          }
        />
      </PassportProvider>
    </>
  );
}

export default QuestionnaireTask;
