import { createReducer } from 'redux-starter-kit';
import { handleError } from '../../utils/handleError';
import { ClassApi } from '../../_helpers/service';
import Notice from '../../utils/Notice';
import i18next from 'i18next';
import paths from './../../_helpers/paths';
/**
 * Constants
 */

export const classPassportModule = 'classPassport';
const LOADING = `${classPassportModule}/LOADING`;
const LOADING_SAVE = `${classPassportModule}/LOADING_SAVE`;

const SET_DATA = `${classPassportModule}/SET_DATA`;
const CLEAR_STATE = `${classPassportModule}/CLEAR_STATE`;
const SET_META = `${classPassportModule}/SET_META`;
const SER_EDUCATORS = `${classPassportModule}/SER_EDUCATORS`;

/**
 * Reducer
 */

const initialState = {
  loading: false,
  loadingSave: false,
  data: {},
  meta: {},
  classItem: {},
  educators: []
};

export default createReducer(initialState, {
  [SET_DATA]: (state, action) => {
    state.classItem = action.payload;
  },
  [LOADING]: (state, action) => {
    state.loading = action.payload;
  },
  [LOADING_SAVE]: (state, action) => {
    state.loadingSave = action.payload;
  },
  [SET_META]: (state, action) => {
    state.meta = action.payload;
  },
  [SER_EDUCATORS]: (state, action) => {
    state.educators = action.payload;
  }, 
  [CLEAR_STATE]: () => initialState
});

/**
 * Actions
 */

export const saveClass = (values, id, history, valueEducators) => async dispatch => {
  try {
    let staffLists = valueEducators.map((item) => item.id)
    dispatch({ type: LOADING_SAVE, payload: true });
    if (id) {
      await ClassApi.updateClass({
        id: id,
        staffIds: staffLists,
        studyGroupChanges: values['form_study_group_school']
      });
    } else {
      await ClassApi.saveClass({
        staffIds: staffLists,
        studyGroupType:"SCHOOL",
        studyGroupChanges: values['form_study_group_school']
      });
    }
    history.replace(paths.classPage)
    Notice.success(i18next.t('classItemPage_saveFormSuccess'));
  } catch (error) {
    if (error.response.data?.errors.length>0 && error.response.data?.errors[0]?.code ==="error.creating_already_existing_school_class"){
      Notice.error(i18next.t("classItemPage_errorSaveClassDuplicate"))
      return
    }
    handleError(error, i18next.t('classItemPage_errorSaveForm'));
  } finally {
    dispatch({ type: LOADING_SAVE, payload: false });
  }
};

export const getMeta = () => async dispatch => {
  try {
    dispatch({ type: LOADING, payload: true });
    let data = {
      metadataKey: 'form_study_group_school'
    }
    const { data: meta } = await ClassApi.loadMeta(data.metadataKey);
    dispatch({ type: SET_META, payload: meta });
  } catch (error) {
    handleError(error, i18next.t('classItemPage_errorLoadMeta'));
  } finally {
    dispatch({ type: LOADING, payload: false });
  }
}

export const getClass = ({id}) => async dispatch => {
  try {
    dispatch({ type: LOADING, payload: true });
    const { data } = await ClassApi.getItemClass({id});
    dispatch({ type: SET_DATA, payload: data })
  } catch (error) {
    handleError(error, i18next.t('classItemPage_errorLoadItem'));
  } finally {
    dispatch({ type: LOADING, payload: false });
  }
}

export const getEducators = () => async dispatch => {
  try {
    dispatch({ type: LOADING, payload: true });
    const {data} = await ClassApi.getEducators();
    dispatch({ type: SER_EDUCATORS, payload: data.result })
  } catch (error) {
    handleError(error, i18next.t('classItemPage_errorLoadEducators'));
  } finally {
    dispatch({ type: LOADING, payload: false });
  }
} 

export const clearState = () => ({ type: CLEAR_STATE });
