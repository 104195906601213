const permissions = {
  homePage: 'permission_homePage',
  archivePage: 'permission_archivePage',
  statementPage: 'permission_statementPage',
  statementViewPage: 'permission_statementViewPage',
  journalPage: 'permission_journalPage',
  journalPage_columnBin: 'permission_journalPage_columnBin',
  journalPage_columnLocations: 'permission_journalPage_columnLocations',
  monitoringTablePage: 'permission_monitoringTablePage',
  monitoringChartsPage: 'permission_monitoringChartsPage',
  handbookPage: 'permission_handbookPage',
  serviceProvidersPage: 'permission_serviceProvidersPage',
  serviceProviderPage: 'permission_serviceProviderPage',
  serviceProviderViewPage: 'permission_serviceProviderViewPage',
  administrationPage: 'permission_administrationPage',
  admin: 'permission_adminPermissions',
  instructionPage: 'permission_instructionPage',
  manualPage: 'permission_manualPage',
  faqPage: 'permission_faq',
  myTaskPage: 'permission_myTaskPage',
  contingentPage: 'permission_contingentPage',
  groupPage: 'permission_groupPage',
  colleaguePage: 'permission_colleaguePage',
  contingentSchoolPage: 'permission_schoolPage',
  contingentSectionPage: 'permission_sectionPage',
  taskPage: 'permission_taskPage',
  CAN_VIEW_QUEUE: 'CAN_VIEW_QUEUE',
  CAN_SIGN_REQUEST_FORM: 'CAN_SIGN_REQUEST_FORM',
  CAN_EXPORT_REQUEST_FORM: 'CAN_EXPORT_REQUEST_FORM',
  ES_HEAD: 'ES_HEAD',
  CAN_VIEW_JOURNAL_COLUMN_BIN: 'CAN_VIEW_JOURNAL_COLUMN_BIN',
  CAN_VIEW_JOURNAL_COLUMN_LOCATION: 'CAN_VIEW_JOURNAL_COLUMN_LOCATION',
  CAN_CHANGE_STATEMENT_SERVICE_PROVIDER: 'CAN_CHANGE_STATEMENT_SERVICE_PROVIDER',
  DDOReportPage: 'permission_DDOReportPage',
  schoolReportPage: 'permission_schoolReportPage',
  sectionReportPage: 'permission_sectionReportPage',
  colleagueReportPage: 'permission_colleagueReportPage',
  foodReportPage: 'permission_foodReportPage',

  DDO_EMPLOYEE: 'DDO_EMPLOYEE',
  MIO_EMPLOYEE: 'MIO_EMPLOYEE',

};

export default permissions;
