import React from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '../../../../_ui/Button/Button';
import CancelIcon from '@material-ui/icons/Cancel';
import Modal from '../../../../_ui/Modal/Modal';
import { reject, signTaskModule } from './SignTaskDucks';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { TextField, useTheme } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import formErrors from '../../../../utils/formErrors';
import useUser from '../../../LoginPage/useUser';

function SignTaskRejectModal({ close }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { palette } = useTheme();
  const rejecting = useSelector(state => state[signTaskModule].rejecting);
  const { register, handleSubmit, formState } = useForm();
  const user = useUser();
  const onSubmit = data => dispatch(reject(data, user, close));
  const errors = formErrors(formState);

  return (
    <Modal title={t('signTask_rejectModalTitle')} open onClose={close}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent className="disable">
          <div className="mb1 fs">{t('signTask_rejectModalReason')}</div>
          <TextField
            multiline
            rows={2}
            size="medium"
            fullWidth
            placeholder={t('signTask_rejectModalReason')}
            error={!!errors.signatureRejectionReason}
            helperText={errors.signatureRejectionReason}
            {...register('signatureRejectionReason', {
              required: t('signTask_rejectModalRequired')
            })}
          />
        </DialogContent>
        <DialogActions>
          <Button
            loading={rejecting}
            size="small"
            style={{ backgroundColor: palette.error.main }}
            icon={<CancelIcon style={{ marginRight: 5 }} />}
            text={t('signTask_reject')}
            type="submit"
          />
        </DialogActions>
      </form>
    </Modal>
  );
}

export default SignTaskRejectModal;
