import React, {useState} from 'react';
import Modal from '../../../_ui/Modal/Modal';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import Button from '../../../_ui/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DoneIcon from '@material-ui/icons/Done';
import { Color } from '../../../components/ThemeProvider/ThemeStyle';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { colleaguesModule, saveCause } from '../ColleaguesDucks'
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import MuiSelect from '@material-ui/core/Select';

export default function ModalReportСard(data) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [causeCode, setCauseCode] = useState(data.data?.absenceReason);
  const [isErrorCause, setIsErrorCause] = useState(false);

  const { cause } = useSelector(
    state => state[colleaguesModule]
  );

  const save = () => {
    if (causeCode) {
      dispatch(saveCause({ 
        causeCode, 
        userID: data.data?.userID, 
        logDate: data.data.logDate, 
        close: data.close 
      }))
    } else {
      setIsErrorCause(true)
    }
  }

  return (
    <Modal
      title={t('colleagues_reportModalTitle') }
      open
      onClose={data.close}
    >
      <DialogContent>
        <Box className='flex items-center'>
          <Typography children={t('colleagues_reportCause')} style={{width: '50%'}} />
          <Box 
            style={{
              width: '100%',
              maxWidth: '100%',
            }}
          >
            <FormControl fullWidth>
              <MuiSelect value={causeCode} error={isErrorCause}>
                {
                  cause.map((item) => {
                    return <MenuItem 
                      value={item.code} 
                      onClick={() => {
                        setCauseCode(item.code)
                        setIsErrorCause(false)
                      }} 
                      key={item.code}
                    >
                      {item[`${i18n.language}_name`]}
                    </MenuItem>
                  })
                }
              </MuiSelect>
              {isErrorCause && <FormHelperText error>{t('colleagues_modalRequired')}</FormHelperText>}   
            </FormControl>
          </Box>
        </Box>
            
        <Box className="flex justify-end pt2">
          <Button
            color="primary"
            style={{ backgroundColor: Color.bgColorSuccess }}
            loading={false}
            icon={<DoneIcon fontSize="small" className="mr1" />}
            text={ t('colleagues_saveBtn') }
            type='submit'
            onClick={() => save()}
          />
        </Box>
      </DialogContent>
    </Modal>
  );
}
