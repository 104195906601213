import React from 'react';
import MonitoringIcon from '@material-ui/icons/Equalizer';
import JournalIcon from '@material-ui/icons/Assignment';
import ArchiveIcon from '@material-ui/icons/Archive';
import HandbookIcon from '@material-ui/icons/ImportContacts';
import ServiceProvidersIcon from '@material-ui/icons/Work';
import ViewListIcon from '@material-ui/icons/ViewList';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import { ViewList, Receipt, Assignment, PanTool, BusinessCenterSharp, Archive } from '@material-ui/icons';
import HelpIcon from '@material-ui/icons/Help';
import MyTaskIcon from '@material-ui/icons/HowToReg';
import paths from '../../../_helpers/paths';
import permissions from '../../../_helpers/permissions';
import GroupIcon from '@material-ui/icons/Group';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import ExtensionIcon from '@material-ui/icons/Extension';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import NotesIcon from '@material-ui/icons/Notes';
import {checkOrg} from './../../../utils/checkOrg'

export const sideBarItems = [
  {
    translate: 'sidebar_myTask',
    path: paths.myTaskPage + '?filter=%7B"pageNumber"%3A1%2C"pageSize"%3A20%2C"taskStatus"%3A"IN_PROGRESS"%7D',
    icon: <MyTaskIcon color="action" />,
    permissions: [permissions.myTaskPage]
  },
  {
    translate: 'sidebar_journal',
    path: paths.journalPage,
    icon: <JournalIcon color="action" />,
    permissions: [permissions.journalPage]
  },
  {
    translate: 'sidebar_archive',
    path: paths.archivePage,
    icon: <ArchiveIcon color="action" />,
    permissions: [permissions.archivePage]
  },
  {
    translate: 'sidebar_monitoring',
    path: paths.monitoringTablePage,
    icon: <MonitoringIcon color="action" />,
    permissions: [permissions.monitoringTablePage]
  },
  {
    translate: 'sidebar_queue',
    path: paths.queueTab1Page,
    icon: <ViewListIcon color="action" />,
    permissions: [permissions.CAN_VIEW_QUEUE]
  },
  {
    isDivider: true,
    permissions: [permissions.serviceProvidersPage, permissions.administrationPage]
  },
  {
    translate: 'sidebar_serviceProviders',
    path: paths.serviceProvidersPage,
    icon: <ServiceProvidersIcon color="action" />,
    permissions: [permissions.serviceProvidersPage]
  },
  {
    translate: 'sidebar_userManagement',
    icon: <SupervisorAccountIcon color="action" />,
    path: paths.administrationPage,
    permissions: [permissions.administrationPage]
  },
  { isDivider: true, permissions: [] },
  {
    translate: 'sidebar_handbook',
    path: paths.handbookPage,
    icon: <HandbookIcon color="action" />,
    permissions: [permissions.handbookPage]
  },
  {
    translate: 'sidebar_instruction',
    icon: <HelpIcon color="action" />,
    permissions: [
      permissions.instructionPage,
      permissions.manualPage,
      permissions.faqPage,
    ],
    items: [
      {
        translate: 'sidebar_videoInstruction',
        path: paths.instructionPage,
        permissions: [permissions.instructionPage]
      },
      {
        translate: 'sidebar_contactSupport',
        path: paths.contactSupportPage,
      },
      {
        translate: 'sidebar_manual',
        path: paths.manualPage,
        permissions: [permissions.manualPage]
      },
      {
        translate: 'sidebar_faq',
        path: paths.faqPage,
        permissions: [permissions.faqPage]
      }
    ]
  },
  {
    translate: 'sidebar_kezekDDOBulletin',
    icon: <ViewList color="action" />,
    path: paths.kezekBulletinTable,
    permissions: [permissions.DDO_EMPLOYEE]
  },
  {
    translate: 'sidebar_kezekDDODirection',
    icon: <Receipt color="action" />,
    path: paths.kezekDirectionTable,
    permissions: [permissions.DDO_EMPLOYEE]
  },
/*  {
    translate: 'sidebar_kezekDDOContingent',
    icon: <Group color="action" />,
    path: paths.kezekContingent,
    permissions: [permissions.DDO_EMPLOYEE]
  },*/
  {
    translate: 'sidebar_kezekDDOPassport',
    icon: <BusinessCenterSharp color="action" />,
    path: paths.kezekPassport,
    permissions: [permissions.DDO_EMPLOYEE]
  },
  {
    translate: 'sidebar_kezekRequest',
    icon: <Assignment color="action" />,
    path: paths.kezekRequest,
    permissions: [permissions.MIO_EMPLOYEE]
  },
  {
    translate: 'sidebar_kezekStopList',
    icon: <PanTool color="action" />,
    path: paths.kezekStopList,
    permissions: [permissions.MIO_EMPLOYEE]
  },
  {
    translate: 'sidebar_kezekArchive',
    icon: <Archive color="action" />,
    path: paths.kezekArchive,
    permissions: [permissions.MIO_EMPLOYEE]
  },
  {
    translate: 'sidebar_colleagues',
    path: paths.employeesPage,
    icon: <PermContactCalendarIcon color="action" />,
    permissions: [permissions.colleaguePage]
  },
  {
    translate: 'sidebar_attendances',
    path: paths.colleaguesReportPage,
    icon: <BorderColorIcon color="action" />,
    permissions: [permissions.colleagueReportPage]
  },
  {
    translate: 'sidebar_contingent',
    icon: <GroupIcon color="action" />,
    path: paths.contingentCandidatePage,
    permissions: [permissions.contingentPage]
  },
 /* {
    translate: 'sidebar_reportDDO',
    path: paths.contingentReportDDOPage,
    icon: <NotesIcon color="action" />,
    permissions: [permissions.DDOReportPage, permissions.MIO_EMPLOYEE]
  },*/
  {
    translate: 'sidebar_groups',
    path: paths.groupsTabelPage,
    icon: <ViewModuleIcon color="action" />,
    permissions: [permissions.groupPage]
  },
  {
    translate: 'sidebar_contingentSchool',
    path: paths.contingentPupilsPage,
    icon: <AccountBalanceIcon color="action" />,
    permissions: [permissions.contingentSchoolPage]
  },
  {
    translate: 'sidebar_class',
    path: paths.classPage,
    icon: <ViewModuleIcon color="action" />,
    permissions: [permissions.contingentSchoolPage]
  },
  {
    translate: 'sidebar_reportSchool',
    path: paths.contingentReportSchoolPage,
    icon: <NotesIcon color="action" />,
    permissions: checkOrg() ? [permissions.schoolReportPage, permissions.MIO_EMPLOYEE] : [permissions.schoolReportPage]
  },
  {
    translate: 'sidebar_contingentSection',
    path: paths.contingentStudentPage,
    icon: <ExtensionIcon color="action" />,
    permissions: [permissions.contingentSectionPage]
  },
  {
    translate: 'sidebar_reportSection',
    path: paths.contingentReportSectionPage,
    icon: <NotesIcon color="action" />,
    permissions: checkOrg() ? [permissions.sectionReportPage, permissions.MIO_EMPLOYEE] : [permissions.sectionReportPage]
  },
  {
    translate: 'sidebar_reportFood',
    path: paths.contingentReportFoodPage,
    icon: <NotesIcon color="action" />,
    permissions: checkOrg() ? [permissions.foodReportPage, permissions.MIO_EMPLOYEE] : [permissions.foodReportPage]
  },
];
