import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  PassportProvider,
  PassportExpander,
  getFormValues,
  PassportForm,
} from '../../passport/Passport';
import { Color } from '../../components/ThemeProvider/ThemeStyle';
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary';
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs';
import { Wrapper, ButtonsWrapper } from './GroupPassportStyle';
import { useTranslation } from 'react-i18next';
import PageLoading from '../../components/PageLoader/PageLoading';
import './GroupPassportTranslate';
import i18next from 'i18next';
import { useParams } from 'react-router';
import paths from './../../_helpers/paths';
import {groupPassportModule, getMeta, saveGroup, getGroup, clearState, getEducators} from './GroupPassportDucks'
import SaveIcon from '@material-ui/icons/Save';
import Button from '../../_ui/Button/Button';
import { useHistory } from "react-router-dom";
import Row from '../../passport/Components/Row';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import MuiSelect from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import {OutlinedInput, Checkbox} from '@material-ui/core';
import useSimpleModal from '../../components/_hooks/useSimpleModal';
import ModalConfirmation from './components/ModalСonfirmation';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function GroupPassportPage({ location: { pathname } }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const [valueEducators, setValueEducators] = useState([]);
  const { loading, meta, group, educators } = useSelector(
    state => state[groupPassportModule]
  );
  const modalConfirmation = useSimpleModal();
  const breadCrumbItems = [
    { name: i18next.t('breadcrumbs_home'), href: '/' },
    { name: i18next.t('groupsTablePage_title'), href: paths.groupsTabelPage },
    { name: t('groupPage_data'), href: paths.groupPage }
  ];

  useEffect(() => {
    dispatch(getMeta());
    dispatch(getEducators())
    if (id) {
      dispatch(getGroup({id}))
    } else {
      dispatch(clearState())
    }
  }, [])
    
    useEffect(() => {
     const sortEducators = [] 
     if (group?.staffList) {
      educators.forEach((itemLang) => {
        group.staffList.map((itemTranslation) => {
            if (itemLang.id === itemTranslation.id) {
              sortEducators.push(itemLang)
            } 
          })
        });
        setValueEducators(sortEducators)
     } else if(pathname === '/groups/add') {
        setValueEducators([])
     }
    }, [group])

    const handleChange = (event) => {
      const {
        target: { value },
      } = event;
  
      let duplicateRemoved = [];
  
      value.forEach((item) => {
        if (duplicateRemoved.findIndex((o) => o.id === item.id) >= 0) {
          duplicateRemoved = duplicateRemoved.filter((x) => x.id === item.id);
        } else {
          duplicateRemoved.push(item);
        }
      });
      setValueEducators(duplicateRemoved);
    };

  return (
    <PageLoading loading={loading}>
      <BreadCrumbs items={breadCrumbItems} />
      <Wrapper className="px3 pb3">
        <ErrorBoundary>
          <PassportProvider
            withoutScroll={false}
            stickyTop={Color.headerHeight}
            data={JSON.stringify(group) === '{}' ? {metadataKey: "form_study_group_do"} : group}
            meta={meta}
            config={{
              documentsProps: {
                mimeType: 'image/jpeg,image/png,application/pdf',
                maxSize: 10000000,
              },
            }}
          >
            <PassportExpander />
            <PassportForm loading={loading} disabled={false} />
            <div className='mt2'>
              <Row label={t('groupPage_educators')}>
                <FormControl fullWidth>
                  <MuiSelect
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={valueEducators}
                    onChange={handleChange}
                    error={!valueEducators.length}
                    input={<OutlinedInput />}
                    renderValue={(selected) => selected.map((x) => x?.staffSurname + ' ' + x?.staffName).join(', ')}
                    MenuProps={MenuProps}
                  >
                    {educators.map((variant) => (
                      <MenuItem key={variant.id} value={variant}>
                        <Checkbox
                          checked={
                            valueEducators.findIndex((item) => item.id === variant.id) >= 0
                          }
                        />
                        {variant?.staffSurname} {variant?.staffName} {variant?.staffMiddlename}
                      </MenuItem>
                    ))}
                    {
                      educators.length === 0 &&
                      <MenuItem>{t('groupPage_noEducators')}</MenuItem>
                    }
                  </MuiSelect>
                  {
                    !(valueEducators.length) && <FormHelperText error>{t('groupPage_chooseCaregiver')}</FormHelperText>
                  }

                </FormControl>
              </Row>
            </div>
          </PassportProvider>
          <ButtonsWrapper>
            <Button
              icon={<SaveIcon fontSize="small" className="mr1" />}
              style={{ marginLeft: 10 }}
              color="primary"
              text={t('userModal_save')}
              onClick={() => {
                getFormValues().then(values => {
                  if (values && valueEducators.length) {
                    modalConfirmation.open({})
                  }
                });
              }}
            />
          </ButtonsWrapper>
          {modalConfirmation.isOpen && <ModalConfirmation groupId={id} close={modalConfirmation.close} valueEducators={valueEducators} />}
        </ErrorBoundary>
      </Wrapper>
    </PageLoading>
  );
}

export default GroupPassportPage;
