import { createReducer } from 'redux-starter-kit';
import { handleError } from '../../utils/handleError';
import { BulletinApi, DirectionApi } from '../../_helpers/service';
import Notice from '../../utils/Notice';
import i18next from 'i18next';
import Dict from '../../utils/Dict';
import { NCALayerSign } from '../../components/NCALayer/NCALayerSign';

/**
 * Constants
 */

export const kezekDirectionModule = 'kezekDirection';
const LOADING = `${kezekDirectionModule}/LOADING`;
const LOADING_REVOKE = `${kezekDirectionModule}/LOADING_REVOKE`;
const LOADING_APPROVE = `${kezekDirectionModule}/LOADING_APPROVE`;

const SET_DATA = `${kezekDirectionModule}/SET_DATA`;
const SET_INFO = `${kezekDirectionModule}/SET_INFO`;
const CLEAR_STATE = `${kezekDirectionModule}/CLEAR_STATE`;
const SET_ERROR = `${kezekDirectionModule}/SET_ERROR`;

/**
 * Reducer
 */

const initialState = {
  loading: false,
  loadingRevoke: false,
  loadingApprove: false,
  info: {},
  data: {},
  meta: {},
  kgData: {},
  kgMeta: {},
  bData: {},
  bMeta: {},
  error: null
};

export default createReducer(initialState, {
  [SET_DATA]: (state, { payload }) => {
    if (payload.data) state.data = payload.data;
    if (payload.meta) state.meta = payload.meta;
    if (payload.kgMeta) state.kgMeta = payload.kgMeta;
    if (payload.kgData) state.kgData = payload.kgData;
    if (payload.bMeta) state.bMeta = payload.bMeta;
    if (payload.bData) state.bData = payload.bData;
  },
  [SET_INFO]: (state, action) => {
    state.info = action.payload;
  },
  [SET_ERROR]: (state, action) => {
    state.error = action.payload;
  },
  [LOADING]: (state, action) => {
    state.loading = action.payload;
  },
  [LOADING_APPROVE]: (state, action) => {
    state.loadingApprove = action.payload;
  },
  [LOADING_REVOKE]: (state, action) => {
    state.loadingRevoke = action.payload;
  },
  [CLEAR_STATE]: () => initialState
});

/**
 * Actions
 */

export const loadData = id => async dispatch => {
  try {
    dispatch({ type: LOADING, payload: true });
    let { data } = await DirectionApi.getDirection(id);
    let { data: kgData } = await DirectionApi.getKinderGarden(data.kindergartenQueueId);
    let { data: bData } = await BulletinApi.getBulletin(data.bulletinId);
    let { data: meta } = await DirectionApi.loadMeta(data.metadataKey);
    let { data: kgMeta } = await DirectionApi.loadMeta(kgData.metadataKey);
    let { data: bMeta } = await DirectionApi.loadMeta(bData.metadataKey);
    dispatch(setData({ meta, data, kgData, kgMeta, bData, bMeta }));
  } catch (error) {
    let msg = handleError(error, i18next.t('kezekDirection_errorLoad'));
    dispatch({ type: SET_ERROR, payload: msg });
  } finally {
    dispatch({ type: LOADING, payload: false });
  }
};

export const revokeDirection = (id, values, closeModal) => async dispatch => {
  try {
    dispatch({ type: LOADING_REVOKE, payload: true });
    const response = await DirectionApi.getSignString({ id, ...values });
    const signature = await NCALayerSign(response.data.result.signature);
    if (signature) {
      const { data } = await DirectionApi.revoke(id, { id, signature, ...values });
      dispatch(setData({ data: data.result }));
      Notice.success(i18next.t('kezekDirection_revokeSuccess'));
      closeModal();
    }
  } catch (error) {
    handleError(error, i18next.t('kezekDirection_revokeError'));
  } finally {
    dispatch({ type: LOADING_REVOKE, payload: false });
  }
};

export const approveDirection = (id, confBenefit, closeModal) => async dispatch => {
  try {
    dispatch({ type: LOADING_APPROVE, payload: true });
    const response = await DirectionApi.getSignString({ id, confBenefit });
    const signature = await NCALayerSign(response.data.result.signature);
    if (signature) {
      const { data } = await DirectionApi.approve(id, {id, signature, confBenefit});
      dispatch(setData({ data: data.result }));
      Notice.success(i18next.t('kezekDirection_approveSuccess'));
      closeModal();
    }
  } catch (error) {
    handleError(error, i18next.t('kezekDirection_approveError'));
  } finally {
    dispatch({ type: LOADING_APPROVE, payload: false });
  }
};

const setData = ({ data, ...props }) => async (dispatch, getState) => {
  data.providerLocation = await Dict.item('60', data.serviceProviderLocation);
  data.statusDirectionName = await Dict.item('gu_status_direction', data.statusDirection);
  data.statusDirectionWebName = await Dict.item('gu_status_direction_web', data.statusDirectionWeb);

  if (props.bData && props.kgData) {
    data.serviceProviderId = props.bData.serviceProviderId;
    data.requestFormId = props.kgData.requestFormId;
    data.requestFormDate = props.kgData.submissionDate;
    data.serviceProviderId = props.bData.serviceProviderId;
    hideQuestions(props.kgMeta, props.bMeta);
  } else {
    const oldData = getState()[kezekDirectionModule].data;
    data.serviceProviderId = oldData.serviceProviderId;
    data.requestFormId = oldData.requestFormId;
    data.requestFormDate = oldData.requestFormDate;
    data.serviceProviderId = oldData.serviceProviderId;
  }

  const info = {
    id: data.id,
    statusDirection: data.statusDirection,
    serviceProviderBin: data.serviceProviderBin
  };
  dispatch({ type: SET_INFO, payload: info });
  dispatch({ type: SET_DATA, payload: { data, ...props } });
  dispatch({ type: LOADING, payload: false });
};

export const clearState = () => ({ type: CLEAR_STATE });

function hideQuestions(kgMeta, bMeta) {
  const kdGroup = kgMeta.group.subGroups.find(item => item.id === 'info_child');
  kdGroup.kk_name = 'Бала және өтініш туралы мәліметтер';
  kdGroup.ru_name = 'Сведения о ребенке и заявлении';
  kdGroup.sections[0].questionCodes = [
    'child_iin',
    'child_surname',
    'child_name',
    'child_middlename',
    'child_birthday',
    'privilege_type',
    'priority_queued',
  ];
  kgMeta.group.subGroups = [kdGroup];

  const bGroup = bMeta.group.subGroups.find(item => item.id === 'info_bulletin');
  bGroup.kk_name = 'Таңдалған орын туралы мәліметтер';
  bGroup.ru_name = 'Сведения по выбранному месту';
  bGroup.sections[0].questionCodes = [
    'queued_type',
    'correction_type',
    'rehab_type',
    'type_bulletin',
    'begin_academic_year',
    'end_academic_year',
    'age_group',
    'year_queue',
    'name_group_kk',
    'name_group_ru',
    'language'
  ];
  bMeta.group.subGroups = [bGroup];
}
