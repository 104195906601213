import React from 'react';
import Modal from '../../../_ui/Modal/Modal';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import withTranslation from '../../../components/_hoc/withTranslation';
import Button from '../../../_ui/Button/Button';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { accept, statementModule } from '../StatementDucks';
import { Color } from '../../../components/ThemeProvider/ThemeStyle';

function AcceptModal({ onClose, id, accept, t, loading }) {
  return (
    <Modal open title={t('statement_acceptTitle')} onClose={onClose} maxWidth="sm">
      <DialogContent dividers children={t('statement_acceptQuestion', {id})} />
      <DialogActions>
        <Button
          type="submit"
          style={{ marginRight: 10, backgroundColor: Color.bgColorSuccess }}
          text={t('statement_accept')}
          onClick={() => accept(id, onClose)}
          loading={loading}
        />
      </DialogActions>
    </Modal>
  );
}

export default compose(
  withTranslation,
  connect(
    state => ({
      loading: state[statementModule].loadingAccept
    }),
    { accept }
  )
)(AcceptModal);
