import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import { loadJournal, journalModule, clearState } from './JournalDucks';
import { journalColumns } from './JournalColumns';
import StaticTablePagination from '../../_ui/Table/StaticTablePaginated';
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs';
import { Wrapper, Tooltip } from './JournalStyles';
import ResponsibleModal from './Responsible/ResponsibleModal';
import Button from '../../_ui/Button/Button';
import useTableFilter from '../../components/TableFilter/useTableFilter';
import ResponsibleIcon from '@material-ui/icons/HowToReg';
import { statusColor } from '../../utils/statusColors';
import i18next from 'i18next';
import './JournalTranslate';
import useHasAccess from '../../components/useHasAccess';
import permissions from '../../_helpers/permissions';
import useDict from '../../components/_hooks/useDict';
import { loginModule } from '../LoginPage/LoginDucks';
import { useTranslation } from 'react-i18next';
import useSimpleModal from '../../components/_hooks/useSimpleModal';
import ArchiveModal from './ArchiveModal';
import Access from '../../components/Access';
import { useTheme } from '@material-ui/core';
import Export from './Export';

export default function JournalPage() {
  const dispatch = useDispatch();
  const { palette } = useTheme();
  const { t, i18n } = useTranslation();
  const modal = useSimpleModal();
  const archiveModal = useSimpleModal();
  const { loadingTable, tableData } = useSelector(state => state[journalModule]);
  const { services } = useSelector(state => state[loginModule].user.organization);
  const [checkedList, setCheckedList] = useState([]);
  const [isAcceptDocEdu, setAcceptDocEdu] = useState(false);

  const { filter, nextPage, reloadPage } = useTableFilter(filter => {
    if (filter['serviceType'] === "accept_doc_edu") {
      setAcceptDocEdu(true)
    } else {
      setAcceptDocEdu(false)
    }
    dispatch(loadJournal(filter))
  });
  const canEdit = useHasAccess(permissions.statementPage);
  const isColumnsAccess = useHasAccess([
    permissions.journalPage_columnBin,
    permissions.journalPage_columnLocations,
    permissions.CAN_VIEW_JOURNAL_COLUMN_BIN,
    permissions.CAN_VIEW_JOURNAL_COLUMN_LOCATION,
    permissions.MIO_EMPLOYEE,
    permissions.ES_MINISTRY_EMPLOYEES
  ]);
  const isAdmin = useHasAccess([permissions.admin]);

  const deadline = useDict('gu_deadline');
  const typeSchool = useDict('gu_type_add_school');
  const serviceType = useDict('gu_services').filter(item =>
    services ? services.includes(item.code) : true
  );
  const breadCrumbItems = [
    { name: i18next.t('breadcrumbs_home'), href: '/' },
    { name: i18next.t('breadcrumbs_journal'), href: '/journal' }
  ];

  useEffect(() => () => dispatch(clearState()), [dispatch]);

  return (
    <Wrapper>
      <BreadCrumbs items={breadCrumbItems} />
      <Paper className="pb3 pt1 px3">
        <h3>{t('journalPage_title')}</h3>
        <div className="flex mb1">
          {canEdit && (
            <Access ps={permissions.ES_HEAD}>
              <div className="mr1">
                <Button
                  icon={<ResponsibleIcon style={{ marginRight: 5 }} />}
                  disabled={checkedList.length === 0}
                  text={t('journalDucks_appoint_responsible')}
                  onClick={e => {
                    e.stopPropagation();
                    modal.open(checkedList);
                  }}
                />
              </div>
            </Access>
          )}
          <Access ps={permissions.CAN_EXPORT_REQUEST_FORM}>
            <Tooltip placement="top-end" title={t('journalPage_export_btn_info')}>
              <div>
                <Export filter={filter} />
              </div>
            </Tooltip>
          </Access>
        </div>
        <StaticTablePagination
          columns={journalColumns({
            t,
            lang: i18n.language,
            checkedList,
            setCheckedList,
            canEdit,
            serviceType,
            isColumnsAccess,
            isAdmin,
            archiveModal,
            deadline,
            palette,
            isAcceptDocEdu,
            typeSchool
          })}
          data={tableData.content}
          sortable={false}
          urlOnClick={row =>
            canEdit
              ? `/statement/${row.original.id}`
              : `/statementView/${row.original.id}`
          }
          totalElements={tableData.totalElements}
          loading={loadingTable}
          onClickLoadMore={() => nextPage()}
          rowBgColor={row => {
            if (String(row.original['documentsAccepted']) === 'true') {
              return statusColor.POSITIVE;
            } else {
              return statusColor[row.original.resolutionType];
            }
          }}
        />
      </Paper>
      {modal.isOpen && (
        <ResponsibleModal {...modal} clearCheckedList={() => setCheckedList([])} />
      )}
      {archiveModal.isOpen && <ArchiveModal {...archiveModal} reloadPage={reloadPage} />}
    </Wrapper>
  );
}
