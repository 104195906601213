import React, { useEffect } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../../../_ui/Modal/Modal';
import Button from '../../../../_ui/Button/Button';
import { assignExecutorModule as module } from './AssignExecutorDucks';
import { clearExecutors, executorAssign, loadExecutors } from './AssignExecutorDucks';
import Select from '../../../../_ui/Select/Select';
import DatePicker from '../../../../_ui/DatePicker/DatePicker';
import { useTranslation } from 'react-i18next';
import Form from '../../../../_ui/Form/Form';
import Row from '../../../../_ui/Row';
import * as Yup from 'yup';
import i18next from 'i18next';
import CancelIcon from '@material-ui/icons/Cancel';
import DoneIcon from '@material-ui/icons/Done';
import { Loading } from '../../MyTaskStyles';
import { findIndex, some } from 'lodash';

const ValidationSchema = Yup.object().shape({
  makerUsername: Yup.string().required(() => i18next.t('executorModal_requiredField')),
  dueDate: Yup.string().required(() => i18next.t('executorModal_requiredField'))
});

function ExecutorsModal({ data, close: modalOnClose, taskId }) {
  const { t } = useTranslation();
  const loading = useSelector(state => state[module].loadingExecutors);
  const executors = useSelector(state => state[module].executors);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadExecutors(taskId));
    return () => dispatch(clearExecutors());
  }, [taskId, dispatch]);

  const executorOptions = executors.map(item => ({
    code: item.username,
    [`${i18next.language}_name`]: item.name
  }));

  const onSubmit = values => {
    const makerDisplayName = some(executors, ['username', values['makerUsername']])
      ? executors[findIndex(executors, ['username', values['makerUsername']])].name
      : '';
    const makers = data.map(item => ({
      ...item,
      ...values,
      makerDisplayName: makerDisplayName
    }));
    dispatch(executorAssign(makers));
    modalOnClose();
  };
  return (
    <div>
      <Modal title={t('assignExecutor_title')} open onClose={modalOnClose}>
        <Form onSubmit={onSubmit} validate={ValidationSchema}>
          <DialogContent className="disable">
            {loading ? (
              <Loading />
            ) : (
              <div>
                <Row label={t('executorModal_executorName')}>
                  <Select
                    name="makerUsername"
                    placeholder="Не выбрано"
                    options={executorOptions}
                  />
                </Row>
                <Row label={t('executorModal_dueDate')}>
                  <DatePicker name="dueDate" />
                </Row>
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              icon={<DoneIcon fontSize="small" className="mr1" />}
              type="submit"
              disabled={loading}
              text={t('executorModal_onSelect_Btn')}
            />
            <Button
              text={t('executorModal_onCancel_Btn')}
              type="button"
              color="default"
              icon={<CancelIcon fontSize="small" className="mr1" color="action" />}
              onClick={() => modalOnClose()}
            />
          </DialogActions>
        </Form>
      </Modal>
    </div>
  );
}

export default ExecutorsModal;
