export default function getStyle(config = {}) {
  let bgColor = 'FFFFFFFF';
  let align = 'right';

  if (config['isHeader']) {
    bgColor = 'FFBFBFBF';
    align = 'center';
  }

  if (config['isSum']) {
    bgColor = 'FFD6D6D6';
  }

  if (config['isLabel']) {
    align = 'left';
  }

  return {
    alignment: {
      wrapText: false,
      vertical: 'center',
      horizontal: align
    },
    fill: {
      fgColor: { rgb: bgColor }
    },
    color: { rgb: '#3d3d3d' },
    border: {
      bottom: { style: 'thin', color: { rgb: '#3d3d3d' } },
      top: { style: 'thin', color: { rgb: '#3d3d3d' } },
      left: { style: 'thin', color: { rgb: '#3d3d3d' } },
      right: { style: 'thin', color: { rgb: '#3d3d3d' } }
    },
    numFmt: '# ##0'
  };
}
