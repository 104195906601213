import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { signTaskModule, clearState, loadData, complete } from './SignTaskDucks';
import { TASK_STATUS } from '../../../../_helpers/Constants';
import { capitalize } from 'lodash';
import { TaskApi } from '../../../../_helpers/service';
import { CircularProgress, useTheme } from '@material-ui/core';
import Button from '../../../../_ui/Button/Button';
import { Color } from '../../../../components/ThemeProvider/ThemeStyle';
import CompleteIcon from '@material-ui/icons/DoneAll';
import CancelIcon from '@material-ui/icons/Cancel';
import { ButtonsWrapper, ErrorLoadingFile } from '../../MyTaskStyles';
import TaskInfo from '../TaskInfo';
import useModal from '../../../../components/_hooks/useModal';
import SignTaskRejectModal from './SignTaskRejectModal';
import { handleTaskError } from '../handleTaskError';

function SignTask({ taskId }) {
  const { t, i18n } = useTranslation();
  const { palette } = useTheme();
  const dispatch = useDispatch();
  const loading = useSelector(state => state[signTaskModule].loading);
  const signing = useSelector(state => state[signTaskModule].signing);
  const rejecting = useSelector(state => state[signTaskModule].rejecting);
  const info = useSelector(state => state[signTaskModule].info) || {};
  const shortInfo = useSelector(state => state[signTaskModule].shortInfo) || {};
  const { configuration, status } = info;
  const isDisabled = status !== TASK_STATUS.IN_PROGRESS;
  const [file, setFile] = useState();
  const [fileError, setFileError] = useState();
  const rejectModal = useModal('reject');

  useEffect(() => {
    dispatch(loadData(taskId));
    return () => dispatch(clearState());
  }, [taskId, dispatch]);

  useEffect(() => {
    TaskApi.documentPreView(taskId)
      .then(response => {
        setFile(response.data.result);
      })
      .catch(function(error) {
        setFile(null);
        const msg = handleTaskError(error, t('signTask_docPreview'), false)
        setFileError(msg);
      });
  }, [taskId, t]);

  return (
    <>
      <h3>{configuration[`name${capitalize(i18n.language)}`]}</h3>
      <TaskInfo {...info} shortInfo={shortInfo} />
      <div className="center mb2">
        {(loading || file === undefined) && <CircularProgress />}
        {file && (
          <object
            type="application/pdf"
            data={`data:application/pdf;base64,${file}`}
            width="800px"
            height="1184px"
          >
            <p>{t('signTask_docPreview')}</p>
          </object>
        )}
      </div>
      {fileError && <ErrorLoadingFile children={fileError} />}
      {!isDisabled && (
        <ButtonsWrapper>
          <Button
            loading={signing}
            size="small"
            style={{ backgroundColor: Color.bgColorSuccess }}
            icon={<CompleteIcon style={{ marginRight: 5 }} />}
            text={t('signTask_sign')}
            onClick={() => dispatch(complete())}
          />
          <Button
            loading={rejecting}
            size="small"
            style={{ backgroundColor: palette.error.main }}
            icon={<CancelIcon style={{ marginRight: 5 }} />}
            text={t('signTask_reject')}
            onClick={() => rejectModal.open(true)}
          />
        </ButtonsWrapper>
      )}
      {rejectModal.isOpen && <SignTaskRejectModal {...rejectModal} />}
    </>
  );
}

export default SignTask;
