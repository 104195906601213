import { addTranslation } from '../../_helpers/Translate';

addTranslation({
  ru: {
    kezekArchive_title: 'Архив очередей',
    kezekArchive_columnID: '№ заявки',
    kezekArchive_childIIN: 'ИИН ребенка',
    kezekArchive_requesterIIN: 'ИИН заявителя',
    kezekArchive_childName: 'ФИО ребенка',
    kezekArchive_childBirthday: 'Дата рождения ребенка',
    kezekArchive_requesterName: 'ФИО заявителя',
    kezekArchive_columnDate: 'Дата, время',
    kezekArchive_queuedType: 'Тип очереди',
    kezekArchive_appStatus: 'Статус',
    kezekArchive_priorityQueued: 'Приоритетность очереди',
    kezekArchive_legalLocality: 'Населенный пункт',
    kezekArchive_serviceProviderBin: 'БИН',
    kezekArchive_startArchived: 'Дата перехода в архив',

    kezekArchiveDucks_errorLoadTable: 'Не удалось загрузить архив очередей',
  },
  kk: {
    kezekArchive_title: 'Архив очередей',
    kezekArchive_columnID: 'Өтініш №',
    kezekArchive_childIIN: 'Баланың ЖСН',
    kezekArchive_requesterIIN: 'Өтініш берушінің ЖСН-і',
    kezekArchive_childName: 'Баланың аты-жөні',
    kezekArchive_childBirthday: 'Баланың туған күні',
    kezekArchive_requesterName: 'Өтініш берушінің аты-жөні',
    kezekArchive_columnDate: 'Күні мен уақыты',
    kezekArchive_queuedType: 'Кезек түрі',
    kezekArchive_appStatus: 'Статус',
    kezekArchive_priorityQueued: 'Кезек басымдығы',
    kezekArchive_legalLocality: 'Елді мекен',
    kezekArchive_serviceProviderBin: 'БСН',
    kezekArchive_startArchived: 'Архив тізіміне көшу күні',

    kezekArchiveDucks_errorLoadTable: 'Кезек мұрағатын жүктеу мүмкін болмады',
  }
});