import styled from 'styled-components';
import Drawer from '@material-ui/core/Drawer';
import { Button } from '@material-ui/core';

export const StyledButton = styled(Button)`
  height: 64px !important;
  min-width: 64px !important;
  width: 64px !important;
  border-radius: 0 !important;
  z-index: 1600;
  position: fixed !important;
  top: 0;
  left: 0;
`;

export const Wrapper = styled.div`
  width: 330px;
  padding-top: 70px;

  .MuiListItem-gutters {
    padding-right: 20px !important;
  }

  .MuiListItem-dense {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .MuiSvgIcon-root {
    width: 1em !important;
  }

  .MuiListItemIcon-root {
    min-width: 20px !important;
  }

  .MuiTypography-body2 {
    white-space: pre-wrap;
    margin-left: 23px;
    font-size: 14px !important;
    letter-spacing: 0.00938em !important;
  }
`;

export const StyledDrawer = styled(Drawer)`
  width: 330px;
  flex-shrink: 0;
  white-space: nowrap;
  box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%),
    0px 1px 3px 0px rgb(0 0 0 / 12%) !important;

  &,
  & > .MuiPaper-root {
    transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    ${({ open }) =>
      open
        ? `
        width: 330px !important;
        overflow-x: hidden !important;
      `
        : `
        overflow-x: hidden !important;
        width: 64px !important;
      `}
  }

  & > .MuiPaper-root {
    ::-webkit-scrollbar {
      width: 8px !important;
    }

    ::-webkit-scrollbar-track {
      background-color: transparent !important;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #d6dee1 !important;
      border: 2px solid transparent !important;
      background-clip: content-box !important;
    }

    ::-webkit-scrollbar-thumb:hover {
      background-color: #a8bbbf !important;
    }
  }
`;
