import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Button from '../../../_ui/Button/Button';
import Form from '../../../_ui/Form/Form';
import { validation } from './Validation';
import { resetPass, resetPassModule } from '../ResetPasswordDucks';
import PasswordField from '../../../components/PasswordField/PasswordField';


function ResetPass({ id }) {
  const { t } = useTranslation();
  const { isChanging } = useSelector(state => state[resetPassModule]);
  const dispatch = useDispatch();

  return (
    <Form
      initialValues={{ confirmationPassword: '', newPassword: '' }}
      validate={validation}
      onSubmit={values => {
        dispatch(resetPass({ ...values, oneTimePassword: id }));
      }}
      render={(_, { values, handleChange }) => (
        <>
          <h2 className="mt0">{t('resetPass_changing')}</h2>
          <b>{t('resetPass_newPass')}</b>
          <PasswordField
            value={values.confirmationPassword}
            name="confirmationPassword"
            onChange={handleChange}
            placeholder={t('resetPass_newPass')}
          />
          <div className="mt1"/>
          <b>{t('resetPass_repeatPass')}</b>
          <PasswordField
            name="newPassword"
            value={values.newPassword}
            onChange={handleChange}
            placeholder={t('resetPass_repeatPass')}
          />
          <div className="mt2">
            <Button
              type="submit"
              loading={isChanging}
              text={t('resetPass_change')}
            />
          </div>
        </>
      )}
    />
  );
}

export default ResetPass;
