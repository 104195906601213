import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import PassportForm from '../../../../passport/FormData/PassportForm';
import { getGroups } from './../../ContingentPassportDucks'

function SearchGroup({ value }) {
    const dispatch = useDispatch();
    const ageGroup = PassportForm.values['form_contingent_do_child.age_group'];
    const correctionType = PassportForm.values['form_contingent_do_child.correction_type'];
    const queueType = PassportForm.values['form_contingent_do_child.queued_type'];
    useEffect(() => {
        if (ageGroup) {
            dispatch(getGroups({
                queuedType: queueType,
                correctionType: correctionType,
                ageGroup: ageGroup,
            }))
        }
    }, [ageGroup, correctionType, queueType])
    return null;
}
  
export default SearchGroup;