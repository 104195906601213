import React from 'react';
import { Redirect } from 'react-router-dom';
import paths from '../../_helpers/paths';
import useHasAccess from '../../components/useHasAccess';
import permissions from '../../_helpers/permissions';

export default function HomePage() {
  const canViewJournal = useHasAccess(permissions.journalPage);
  return <Redirect to={canViewJournal ? paths.journalPage : paths.myTaskPage} />;
}
