import React, {useEffect, useState} from 'react';
import PassportForm from '../../passport/FormData/PassportForm';
import {DocumentApi} from '../../_helpers/service';
import { PhotoWrapper } from './ContingentSchoolStyle'
import { CONTINGENT_SCHOOL_STATUS } from '../../_helpers/Constants';


export default function ContingentSchoolInfo({ data, t }) {
  const [urlImg, setUrlImg] = useState('')
  useEffect(() => {
    let value = PassportForm.values['form_contingent_school_student.Foto'];
    if (value) {
      DocumentApi.view(value['downloadLink'], value.version)
        .then(response => {
          setUrlImg(URL.createObjectURL(response.data));
        });
    }
  }, [PassportForm.values['form_contingent_school_student.Foto']]);

  useEffect(() => {
    setTimeout(() => {
      const elemet = document.getElementById('attach_form_contingent_school_student.Foto');
      elemet && elemet.addEventListener("change", (event) => {
        setUrlImg(URL.createObjectURL(event.target.files[0]));
      })
    }, 2000);
  }, [])

  return (
    <>
      
      {
        (data.contingentStatus === CONTINGENT_SCHOOL_STATUS.STUDIES_SCHOOL || data.contingentStatus === CONTINGENT_SCHOOL_STATUS.LEFT_SCHOOL) &&
        <div className="mb2">
          {
            urlImg ? 
            <div style={{ width: '220px', height: '240px' }}>
              <img 
                src={urlImg}  
                style={{
                  width: '100%',
                  height: '100%',
                  borderRadius: '6px',
                }}
                alt=''
              />
            </div> :
            <PhotoWrapper> <p> {t('contingentPassport_noPhoto')} </p> </PhotoWrapper>
          }
        </div>
      }
    </>
  );
}
