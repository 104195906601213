import * as Yup from 'yup';
import i18next from 'i18next';

export const validation = Yup.object().shape({
  confirmationPassword: Yup.string()
    .required(() => i18next.t('resetPass_required'))
    .min(8, () => i18next.t('resetPass_wrongPasswordLength'))
    .test('has-russian-letters', () => i18next.t('resetPass_wrongPasswordNotCyrillic'), pwd => pwd && !pwd.match(/[а-яА-ЯЁё]/))
    .matches(/\d/, {
      message: () => i18next.t('resetPass_wrongPasswordDigits'),
      excludeEmptyString: true
    })
    .matches(/[a-z]/, {
      message: () => i18next.t('resetPass_wrongPasswordLowercase'),
      excludeEmptyString: true
    })
    .matches(/[A-Z]/, {
      message: () => i18next.t('resetPass_wrongPasswordUppercase'),
      excludeEmptyString: true
    })
    .matches(/[\\~[{}!@#$*%^&<>()_+|/'="№;:?\],.-]/, {
      message: () => i18next.t('resetPass_wrongPasswordSymbols'),
      excludeEmptyString: true
    }),
  newPassword: Yup.string()
    .required(() => i18next.t('resetPass_required'))
    .oneOf([Yup.ref('confirmationPassword')], () => i18next.t('resetPass_passNotEquals'))
    .min(8, () => i18next.t('resetPass_wrongPasswordLength'))
    .test('has-russian-letters', () => i18next.t('resetPass_wrongPasswordNotCyrillic'), pwd => pwd && !pwd.match(/[а-яА-ЯЁё]/))
    .matches(/\d/, {
      message: () => i18next.t('resetPass_wrongPasswordDigits'),
      excludeEmptyString: true
    })
    .matches(/[a-z]/, {
      message: () => i18next.t('resetPass_wrongPasswordLowercase'),
      excludeEmptyString: true
    })
    .matches(/[A-Z]/, {
      message: () => i18next.t('resetPass_wrongPasswordUppercase'),
      excludeEmptyString: true
    })
    .matches(/[\\~[{}!@#$*%^&<>()_+|/'="№;:?\],.-]/, {
      message: () => i18next.t('resetPass_wrongPasswordSymbols'),
      excludeEmptyString: true
    }),
});
