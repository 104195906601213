import { addTranslation } from '../../_helpers/Translate';

addTranslation({
  ru: {
    serviceProvider_title: 'Реестр услугодателей',
    serviceProvider_errorLoad: 'Не удалось загрузить данные услугодателя',
    serviceProvider_createForm: 'Создать',
    serviceProvider_saveForm: 'Сохранить',
    serviceProvider_saveFormSuccess: 'Данные успешно сохранены',
    serviceProvider_errorSaveForm: 'Не удалось сохранить данные',
    serviceProvider_id: 'Номер услугодателя',
    serviceProvider_iin: 'ИИН',
    serviceProvider_bin: 'БИН',
    serviceProvider_nedbId: 'ID НОБД',
    serviceProvider_parent: 'Родительская организация',
    serviceProvider_parentChoose: 'Выбрать'
  },
  kk: {
    serviceProvider_title: 'Қызмет көрсетушілердің тізілімі',
    serviceProvider_errorLoad: 'Қызмет көрсетушінің деректерін жүктеу мүмкін емес',
    serviceProvider_createForm: 'Создать',
    serviceProvider_saveForm: 'Сақтау',
    serviceProvider_saveFormSuccess: 'Деректер сәтті сақталды',
    serviceProvider_errorSaveForm: 'Деректерді сақтау сәтсіз аяқталды',
    serviceProvider_id: 'Қызметті көрсетушінің нөмірі',
    serviceProvider_iin: 'ЖСН',
    serviceProvider_bin: 'БСН',
    serviceProvider_nedbId: 'ID НОБД',
    serviceProvider_parent: 'Бас ұйым',
    serviceProvider_parentChoose: 'Таңдау'
  }
});
