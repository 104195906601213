import React, { useEffect } from 'react';
import StaticTablePagination from '../../../_ui/Table/StaticTablePaginated';
import { useTranslation } from 'react-i18next';
import { CandidateColumns } from './CandidateColumns'
import useDict from '../../../components/_hooks/useDict';
import { useTheme } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { loadData, contingentModule, clearState } from '../ContingentDucks';
import useTableFilter from '../../../components/TableFilter/useTableFilter';
import { Color } from '../../../components/ThemeProvider/ThemeStyle';

export default function ContingentCandidate() {
  const { t, i18n } = useTranslation();
  const { palette } = useTheme();
  const dispatch = useDispatch();
  const statusDirection = useDict('gu_status_direction');
  const data = useSelector(state => state[contingentModule].tableData);
  const loadingTable = useSelector(state => state[contingentModule].loadingTable);
  const { nextPage } = useTableFilter(filter =>
    dispatch(loadData({filter, type: 'APPLICANT'}))
  );  
  useEffect(() => {
    dispatch(clearState());
  }, [dispatch]);
  return (
    <div className='mt1'>
      <StaticTablePagination
        loading={loadingTable}
        columns={CandidateColumns({
          t,
          lang: i18n.language,
          statusDirection,
          palette
        })}
        sortable={false}
        data={data.content}
        urlOnClick={row => `/contingent/item/${row.original.id}`}
        totalElements={data.totalElements}
        onClickLoadMore={() => nextPage()}
        rowBgColor={({row}) => {
          if (row.statusDirection === 'RECEIVED' || row.statusDirection === 'PROLONGED') {
            return Color.isDark ? `${palette.error.dark}70` : `${palette.error.light}90`
          } else if (row.statusDirection === 'ARRIVED_TO_PO') {
            return Color.isDark ? `${palette.success.dark}70` : `${palette.success.light}90`;
          }
        }}
      />
    </div>
  );
}

