export const getLastDayOfMonth = (year, month) => {
  let date = new Date(year, month, 0);
  return date.getDate();
}
  
export const createTableCell = (allDays) => {
  let arr = [];
  for(let i = 1; i <= allDays; i++) {
    if (i < 10) {
      arr.push(String('0'+i));
    } else {
      arr.push(String(i));
    }
  }
  return arr;
}
  
export const monthCurrent = (month) => {
  if (month < 10) {
    return String('0'+month)
  } else {
    return month
  }
}