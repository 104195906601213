import { addTranslation } from '../../_helpers/Translate';

addTranslation({
  ru: {
    journalPage_title: 'Журнал заявлений',
    journalPage_columnType: 'Тип заявки',
    journalPage_columnNameService: 'Наименование услуги',
    journalPage_columnStatementNumber: 'Номер заявки',
    journalPage_columnIin: 'ИИН',
    journalPage_columnBin: 'БИН',
    journalPage_columnLocation: 'Населенный пункт',
    journalPage_columnFullName: 'ФИО',
    journalPage_columnDate: 'Дата, время',
    journalPage_columnStatus: 'Статус',
    journalPage_columnResolution: 'Решение',
    journalPage_ragStatus: 'Срок исполнения',
    journalPage_columnExternalId: 'Номер ПЭП ',
    journalPage_columnAdditional_status: 'Дополнительный статус',
    journalPage_columnAction: 'Действия',
    journalPage_columnResponsible: 'Ответственный ',
    journalPage_toArchive: 'Архивировать',
    journalPage_export_btn: 'Экспортировать',
    journalPage_export_select_date: 'Выберите период для экспорта',
    journalPage_export_limit_period: 'Журнал заявлений возможно экспортировать только за период в 31 день',
    journalPage_export_start_date: 'Дата от',
    journalPage_export_end_date: 'Дата до',
    journalPage_export_btn_info: 'Передача персональных данных третьим лицам ЗАПРЕЩАЕТСЯ согласно Закону Республики Казахстан "О персональных данных"',
    journalPage_exportNotice: 'Выберите наименование государственной услуги в поле "Тип заявки"',
    journalDucks_appoint_responsible: 'Назначить ответственного',
    journalDucks_errorLoadTable: 'Не удалось загрузить журнал заявлениий',
    journalDucks_archiveModalTitle: 'Архивирование',
    journalDucks_archiveModalText: 'Архивировать заявку с номером {{id}} ?',
    journalDucks_archiveError: 'Не удалось архивировать заявку',
    journalDucks_delayPeriod: '{{day}} д. {{hours}} ч. {{minutes}} мин.',
    journalDucks_exportError: 'Не удалось экспортировать журнал',
    journalPage_columnChildIin: 'ИИН ребенка',
  },
  kk: {
    journalPage_title: 'Өтініштер журналы',
    journalPage_columnType: 'Өтінім түрі',
    journalPage_columnNameService: 'Қызмет атауы',
    journalPage_columnStatementNumber: 'Өтінім нөмірі',
    journalPage_columnIin: 'ЖСН',
    journalPage_columnBin: 'БСН',
    journalPage_columnLocation: 'Аймақ',
    journalPage_columnFullName: 'ТӘА',
    journalPage_columnDate: 'Күні, уақыты',
    journalPage_columnStatus: 'Мәртебесі',
    journalPage_columnResolution: 'Шешім',
    journalPage_ragStatus: 'Орындау уақыты',
    journalPage_columnExternalId: 'ЭҮП нөмірі',
    journalPage_columnAdditional_status: 'Қосымша мәртебе',
    journalPage_columnAction: 'Әрекеттер',
    journalPage_columnResponsible: 'Жауапты',
    journalPage_toArchive: 'Мұрағатқа жіберу',
    journalPage_export_btn: 'Экспорттау',
    journalPage_export_select_date: 'Экспорттауға арналған кезеңді таңдаңыз',
    journalPage_export_start_date: 'Кезеңнің басы',
    journalPage_export_limit_period: 'Өтінімдер журналын 31 күндік кезеңде ғана экспорттауға болады',
    journalPage_export_end_date: 'Кезеңнің соңы',
    journalPage_export_btn_info: ' "Дербес деректер туралы" Қазақстан Республикасының Заңына сәйкес дербес деректерді үшінші тұлғаларға беруге ТЫЙЫМ салынады',
    journalPage_exportNotice: '"Өтінім типі" өрісінде мемлекеттік қызметтің атауын таңдау қажет',
    journalDucks_appoint_responsible: 'Жауаптыны тағайындау',
    journalDucks_errorLoadTable: 'Өтініштер журналын жүктеу мүмкін емес',
    journalDucks_archiveModalTitle: 'Мұрағаттау',
    journalDucks_archiveModalText: 'Нөмірі {{id}} өтінішті архивке жібересіз бе?',
    journalDucks_archiveError: 'Өтінішті мұрағаттау сәтсіз аяқталды',
    journalDucks_delayPeriod: '{{day}} к. {{hours}} с. {{minutes}} мин.',
    journalDucks_exportError: 'Журналды экспорттау сәтсіз аяқталды',
    journalPage_columnChildIin: 'Баланың ЖСН',
  }
});
