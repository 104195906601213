import { addTranslation } from '../../_helpers/Translate';

addTranslation({
  ru: {
    monitoring_tables: 'Таблица',
    monitoring_charts: 'Диаграмма',
    monitoring_fromDate: 'дата с',
    monitoring_toDate: 'дата по',
    monitoring_service: 'Выберите услугу',
    monitoring_status: 'Выберите статус',
    monitoring_chartsStatus: 'Количество заявок в разрезе статусов, ед.',
    monitoring_chartsService: 'Количество заявок в разрезе услуг, ед.',
    monitoring_columnService: 'Наименование',
    monitoring_columnLocation: 'Населенный пункт',
    monitoring_columnSUBMITTED: 'Поступило',
    monitoring_columnIN_PROGRESS: 'В обработке',
    monitoring_columnREADY_FOR_SIGN: 'На подписи',
    monitoring_columnREADY_FOR_SIGN_POSITIVE: 'Ожидает подпись на положительный ответ',
    monitoring_columnREADY_FOR_SIGN_NEGATIVE: 'Ожидает подпись на отрицательный ответ',
    monitoring_columnIN_PROGRESS_IN_EXTERNAL_APP: 'Передано в ИС ОО',
    monitoring_columnCOMPLETED_POSITIVE: 'Положительно',
    monitoring_columnCOMPLETED_NEGATIVE: 'Отрицательно',
    monitoring_columnAllByRegion:'Всего по региону',
    monitoring_columnAllByStatus:'Всего по статусу',
    monitoringDucks_errorLoadTable: 'Не удалось загрузить данные',
    monitoring_excelAllServices: 'Все услуги',
    monitoring_excelPeriod: 'Период: с {{period.fromDate}} - по {{period.toDate}}',
    monitoring_excelService: 'Услуга: {{service}}',
    monitoring_excel_sheet_title: 'Отчеты',
    monitoring_export_btn:'Экспортировать',
    monitoring_report_btn:'Загрузить отчет',
  },
  kk: {
    monitoring_tables: 'Кесте',
    monitoring_charts: 'Диаграмма',
    monitoring_fromDate: 'күні,бастап',
    monitoring_toDate: 'күні,дейін',
    monitoring_service: 'Қызметті таңдаңыз',
    monitoring_status: 'Мәртебені таңдаңыз',
    monitoring_chartsStatus: 'Мәртебелер бөлінісінде өтінімдер саны, бірлік',
    monitoring_chartsService: 'Қызметтер бөлінісіндегі өтінімдер саны, бірлік',
    monitoring_columnService: 'Атауы',
    monitoring_columnLocation: 'Аймақ',
    monitoring_columnSUBMITTED: 'Келіп түсті',
    monitoring_columnIN_PROGRESS: 'Өңделуде',
    monitoring_columnREADY_FOR_SIGN: 'Қолтаңба күтуде',
    monitoring_columnREADY_FOR_SIGN_POSITIVE: 'Оң жауап үшін қолтаңбаны күтуде',
    monitoring_columnREADY_FOR_SIGN_NEGATIVE: 'Теріс жауап үшін қолтаңбаны күтуде',
    monitoring_columnIN_PROGRESS_IN_EXTERNAL_APP: 'ББҰ АЖ-не берілді',
    monitoring_columnCOMPLETED_POSITIVE: 'Оң',
    monitoring_columnCOMPLETED_NEGATIVE: 'Теріс',
    monitoring_columnAllByRegion:'Жалпы облыс бойынша',
    monitoring_columnAllByStatus:'Жалпы мәртебе',
    monitoringDucks_errorLoadTable: 'Деректерді жүктеу мүмкін емес',
    monitoring_excelAllServices: 'Барлық қызметтер',
    monitoring_excelPeriod: 'Кезең: {{period.fromDate}} - {{period.toDate}}',
    monitoring_excelService: 'Қызметі: {{service}}',
    monitoring_excel_sheet_title: 'Есептер',
    monitoring_export_btn: 'Экспорттау',
    monitoring_report_btn: 'Есептерді жүктеу ',
  }
});
