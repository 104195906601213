import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import {
  PassportProvider,
  PassportExpander,
  PassportForm
} from '../../passport/Passport';
import { getFormValues } from '../../passport/Passport';
import { Color } from '../../components/ThemeProvider/ThemeStyle';
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary';
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs';
import SaveButton from './SaveButton';
import { kezekPassportModule, loadData, clearState, onSave } from './KezekPassportDucks';
import { Wrapper, ErrorMessage } from './KezekPassportStyle';
import TextField from '@material-ui/core/TextField';
import Row from '../../passport/Components/Row';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PageLoading from '../../components/PageLoader/PageLoading';
import useSimpleModal from '../../components/_hooks/useSimpleModal';
import ParentOrganizationModal from './ParentOrganizationModal';
import './KezekPassportTranslate';
import i18next from 'i18next';
import useUser from '../LoginPage/useUser';
import Notice from '../../utils/Notice';

export default function KezekPassportPage() {
  const modal = useSimpleModal();
  const { register, handleSubmit } = useForm();
  const { t } = useTranslation();
  const [parentOrg, setParentOrg] = useState({});

  const user = useUser();
  const dispatch = useDispatch();
  const { loading, info, error, data, metadata,invalidPassport } = useSelector(
    state => state[kezekPassportModule]
  );

  const breadCrumbItems = [
    { name: i18next.t('breadcrumbs_home'), href: '/' },
    { name: i18next.t('breadcrumbs_kezekPassport') }
  ];

  const onSubmit = staticValues => {
    let values = { ...staticValues, metadataKey: info.metadataKey };
    if (parentOrg.id) values.parentServiceProviderId = parentOrg.id;
    getFormValues().then(formValues => {
      if (formValues) {
        dispatch(onSave({ ...values, id: user.organization_id }, formValues));
      }
    });
  };

  useEffect(() => {
    if (invalidPassport) {
      Notice.info(i18next.t('kezekPassport_invalidPassport'));
    }
  }, [invalidPassport]);

  useEffect(() => {
    dispatch(loadData(user.organization_id));
    return () => dispatch(clearState());
  }, [dispatch, user.organization_id]);

  useEffect(() => {
    setParentOrg(info.parentOrg);
  }, [info.parentOrg]);

  if (error) {
    return (
      <>
        <BreadCrumbs items={breadCrumbItems} />
        <Paper className="p3">
          <ErrorMessage>
            <code>{error}</code>
          </ErrorMessage>
        </Paper>
      </>
    );
  }

  return (
    <PageLoading loading={loading}>
      <BreadCrumbs items={breadCrumbItems} />
      <Wrapper className="px3 pb3">
        <ErrorBoundary>
          <div style={{ transform: 'translateY(30px)' }}>
            <span className="bold">{t('serviceProvider_id')}</span>: {info.id}
          </div>
          <PassportProvider
            info={info}
            withoutScroll={false}
            stickyTop={Color.headerHeight}
            meta={metadata}
            data={{ metadataKey: metadata.id, ...data }}
            config={{
              documentsProps: {
                mimeType: 'image/jpeg,image/png',
                maxSize: 10000000,
              },
            }}
          >
            <PassportExpander />
            <PassportForm
              info={info}
              betweenRender={(_, nextQuestionCode) =>
                nextQuestionCode === 'service_provider.ru_name' && (
                  <>
                    <Row label={t('serviceProvider_bin')}>
                      <TextField
                        placeholder={t('serviceProvider_bin')}
                        defaultValue={info.bin}
                        {...register('bin')}
                        disabled
                      />
                    </Row>
                    <Row label={t('serviceProvider_iin')}>
                      <TextField
                        placeholder={t('serviceProvider_iin')}
                        defaultValue={info.iin}
                        {...register('iin')}
                        disabled
                      />
                    </Row>
                  </>
                )
              }
            />
            {modal.isOpen && (
              <ParentOrganizationModal
                parentOrg={parentOrg}
                modal={modal}
                onSelect={row => {
                  setParentOrg(row);
                  modal.close();
                }}
              />
            )}
            <SaveButton onClick={handleSubmit(onSubmit)} />
          </PassportProvider>
        </ErrorBoundary>
      </Wrapper>
    </PageLoading>
  );
}
