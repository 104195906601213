import React from 'react';
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs';
import i18next from 'i18next';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
import { useTranslation } from 'react-i18next';
import image504 from './image504.png';
import './ContactSupportTranslate';
import config from '../../_helpers/config';

function ContactSupportPage() {
  const { t } = useTranslation();
  const breadCrumbItems = [
    { name: i18next.t('breadcrumbs_home'), href: '/' },
    { name: i18next.t('breadcrumbs_contactSupport') }
  ];
  return (
    <div>
      <BreadCrumbs items={breadCrumbItems} />
      <Paper className="pb3 pt1 px3">
        <h3>{t('contactSupport_title')}</h3>
        <Divider />
        <div className="mt2">
          {(config.code === 'Almaty' || config.code === 'Zhetisu') && (
            <div>
              {t('contactSupport_eZhetysu')}{' '}
              <Link href="https://e-zhetysu.supportsystem.com/index.php">
                e-zhetysu.supportsystem.com
              </Link>
            </div>
          )}
          {config.supportPhones && (
            <div>
              {t('contactSupport_telephones')}{' '}
              <span className="bold">{config.supportPhones}</span>
            </div>
          )}
          {config.skypeUrl && (
            <div>
              {t('contactSupport_skypeChat')}{' '}
              {
                <a
                  className="underline bold"
                  href={config.skypeUrl}
                  children={config.skypeUrl}
                />
              }
            </div>
          )}
          {t('contactSupport_description')}
          <a
            className="color-secondary bold ml1"
            href={`mailto:${config.email}`}
            children={config.email}
          />
        </div>

        <div className="bold mt2">{t('contactSupport_messageDescription')}</div>
        <ol className="mt1">
          <li>{t('contactSupport_message1')}</li>
          <li>{t('contactSupport_message2')}</li>
          <li>{t('contactSupport_message3')}</li>
          <li>{t('contactSupport_message4')}</li>
        </ol>

        <div className="bold mt2">{t('contactSupport_example')}</div>
        <ul className="mt1">
          <li>{t('contactSupport_exampleText1')}</li>
          <li>{t('contactSupport_exampleText2')}</li>
          <li>{t('contactSupport_exampleText3')}</li>
          <li>{t('contactSupport_exampleText4')}</li>
          <li>
            <div>{t('contactSupport_exampleText5')}</div>
            <img src={image504} alt="screen" />
          </li>
        </ul>
      </Paper>
    </div>
  );
}

export default ContactSupportPage;
