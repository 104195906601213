import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Form as FormikForm, Formik } from 'formik';
import { DialogActions, DialogContent } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import { useTranslation } from 'react-i18next';
import Row from '../../../_ui/Row';
import Input from '../../../_ui/Input/Input';
import Select from '../../../_ui/Select/Select';
import Button from '../../../_ui/Button/Button';
import Modal from '../../../_ui/Modal/Modal';
import { ProfileUpdateSchema } from './ProfileValidation';
import { ROLES, ROLES_NAMES } from '../../../_helpers/Constants';
import { loginModule } from '../../../pages/LoginPage/LoginDucks';
import ServiceProvidersModal from '../../../pages/AdministrationPage/User/ServiceProvidersModal';
import useSimpleModal from '../../_hooks/useSimpleModal';
import {
  administrationModule,
  editProfile
} from '../../../pages/AdministrationPage/AdministrationDucks';
import TreeSelect from '../../../_ui/TreeSelect/TreeSelect';
import useTree from '../../TableFilter/ColumnsFilters/FilterTreeSelect/useTree';
import Divider from '@material-ui/core/Divider';

function ProfileModal({ data, loading, onClose, editProfile }) {
  const [formData, setFormData] = useState(data);
  const { t, i18n } = useTranslation();
  const SPModal = useSimpleModal();
  const lookupId = 60;
  const { tree, onExpand, loadingArr } = useTree({
    lookupId: lookupId
  });

  const roles = Object.keys(ROLES).map(r => ({
    code: r,
    ru_name: ROLES_NAMES[r]?.ru_name,
    kk_name: ROLES_NAMES[r]?.kk_name
  }));

  return (
    <Modal
      open
      title={<b>{t('userModal_editProfile')}</b>}
      onClose={onClose}
      scroll="body"
    >
      <Formik
        enableReinitialize
        initialValues={formData}
        onSubmit={values => editProfile(values)}
        validationSchema={ProfileUpdateSchema}
      >
        {({ values }) => (
          <FormikForm>
            <DialogContent>
              <Row label={t('userModal_iin')}>
                <Input
                  disabled
                  name="iin"
                  placeholder={t('userModal_iin')}
                  className="fullwidth"
                />
              </Row>
              <Row label={t('userModal_lastName')}>
                <Input
                  disabled
                  name="lastName"
                  placeholder={t('userModal_lastName')}
                  className="fullwidth"
                />
              </Row>
              <Row label={t('userModal_firstName')}>
                <Input
                  disabled
                  name="firstName"
                  placeholder={t('userModal_firstName')}
                  className="fullwidth"
                />
              </Row>
              <Row label={t('userModal_middleName')}>
                <Input
                  disabled
                  name="middleName"
                  placeholder={t('userModal_middleName')}
                  className="fullwidth"
                />
              </Row>
              <Row label={t('userModal_username')}>
                <Input
                  name="username"
                  placeholder={t('userModal_username')}
                  className="fullwidth"
                  disabled
                />
              </Row>
              <Row label={t('userModal_email')}>
                <Input
                  name="emailAddress"
                  placeholder={t('userModal_email')}
                  className="fullwidth"
                />
              </Row>
              <Row label={t('userModal_role')}>
                <Select
                  disabled
                  name="roles"
                  placeholder={t('userModal_role')}
                  className="fullwidth"
                  options={roles}
                />
              </Row>
              {values.roles && values.roles.includes(ROLES.ES_REGION_EMPLOYEES) && (
                <Row label={t('userModal_region')}>
                  <TreeSelect
                    disabled
                    name="region_code"
                    fullWidth
                    lookupId={lookupId}
                    loadingArr={loadingArr}
                    onExpand={onExpand}
                    data={tree}
                  />
                </Row>
              )}
              {values.roles &&
                (values.roles.includes(ROLES.ES_MINISTRY_EMPLOYEES) ||
                  values.roles.includes(ROLES.ES_REGION_EMPLOYEES)) && (
                  <Row label={t('userModal_services')}>
                    <Select
                      disabled
                      multiple
                      name="services_list"
                      fullWidth
                      lookupId="gu_services"
                      placeholder={t('userModal_services')}
                    />
                  </Row>
                )}
              {values.roles &&
                !values.roles.includes(ROLES.ES_MINISTRY_EMPLOYEES) &&
                !values.roles.includes(ROLES.ES_REGION_EMPLOYEES) && (
                  <Row label={t('userModal_organization')}>
                    <Button
                      disabled
                      className="fullwidth"
                      variant="outlined"
                      onClick={() => SPModal.open(values)}
                      text={
                        formData && formData['organization'][`${i18n.language}_name`]
                          ? formData['organization'][`${i18n.language}_name`]
                          : t('userModal_organization')
                      }
                    />
                    <Input
                      name="organization"
                      type="hidden"
                      style={{ display: 'none', margin: 0 }}
                    />
                  </Row>
                )}
              <Row label={t('userModal_ruPosition')}>
                <Input
                  name="position_ru"
                  placeholder={t('userModal_ruPosition')}
                  className="fullwidth"
                />
              </Row>
              <Row label={t('userModal_kkPosition')}>
                <Input
                  name="position_kk"
                  placeholder={t('userModal_kkPosition')}
                  className="fullwidth"
                />
              </Row>
              <Row label={t('userModal_phoneNumber')}>
                <Input
                  name="phone_number"
                  placeholder={t('userModal_phoneNumber')}
                  className="fullwidth"
                  type="number"
                />
              </Row>
              <div className="my2">
                <Divider/>
              </div>
              <div className="mb2" style={{ color: 'grey'}}>
                <Row label={t('userModal_creationDt')}>
                  <div style={{ padding: '10px 0' }}>{formData.creationDt}</div>
                </Row>
                <Row label={t('userModal_lastLoginDt')}>
                  <div style={{ padding: '10px 0' }}>{formData.lastLoginDt}</div>
                </Row>
                <Row label={t('userModal_lastPasswordChange')}>
                  <div style={{ padding: '10px 0' }}>{formData.lastPasswordChange}</div>
                </Row>
                <Row label={t('userModal_lastFailedAttemp')}>
                  <div style={{ padding: '10px 0' }}>{formData.lastFailedAttemp}</div>
                </Row>
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                icon={<SaveIcon fontSize="small" className="mr1" />}
                type="submit"
                text={t('userModal_save')}
                loading={loading}
                disabled={loading}
              />
              <Button
                icon={<CancelIcon fontSize="small" className="mr1" color="action" />}
                onClick={onClose}
                text={t('userModal_cancel')}
                type="button"
                color="default"
              />
            </DialogActions>
          </FormikForm>
        )}
      </Formik>
      {SPModal.isOpen && (
        <ServiceProvidersModal modal={SPModal} setFormData={setFormData} />
      )}
    </Modal>
  );
}

export default connect(
  state => ({
    data: state[loginModule].user,
    loading: state[administrationModule].editingProfile
  }),
  {
    editProfile
  }
)(ProfileModal);
