import React from 'react';
import Modal from '../../../_ui/Modal/Modal';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import Button from '../../../_ui/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { contingentPassportModule, approveContingent } from '../ContingentPassportDucks';
import DoneIcon from '@material-ui/icons/Done';
import CancelIcon from '@material-ui/icons/Cancel';
import { Color } from '../../../components/ThemeProvider/ThemeStyle';

export default function ModalApprove(data) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loading = useSelector(state => state[contingentPassportModule].loadingApprove);
  const hasPrivilege = useSelector(state => state[contingentPassportModule].data.hasPrivilege);
  return (
    <Modal
      title={
        hasPrivilege
          ? t('contingentPassport_approveQuestion')
          : t('contingentPassport_approve')
      }
      open
      onClose={data.close}
    >
      <DialogContent>
        <div className="flex justify-end pb2">
          <div className="mr2">
            {hasPrivilege && (
              <Button
                color="secondary"
                loading={loading}
                icon={<CancelIcon fontSize="small" className="mr1" />}
                text={t('contingentPassport_approveNo')}
                onClick={() => dispatch(approveContingent(data.directionId, 'not_confirmed', data.close, data.contingentId))}
              />
            )}
          </div>
          <div>
            <Button
              color="primary"
              style={{ backgroundColor: Color.bgColorSuccess }}
              loading={loading}
              icon={<DoneIcon fontSize="small" className="mr1" />}
              text={hasPrivilege
                ? t('contingentPassport_approveYesPrev')
                : t('contingentPassport_yes')}
              onClick={() =>
                dispatch(
                  approveContingent(data.directionId, hasPrivilege ? 'confirmed' : 'not_benefits', data.close, data.contingentId)
                )
              }
            />
          </div>
        </div>
      </DialogContent>
    </Modal>
  );
}