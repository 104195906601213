import React, { useState } from 'react';
import Modal from '../../../_ui/Modal/Modal';
import withTranslation from '../../../components/_hoc/withTranslation';
import { compose } from 'redux';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '../../../_ui/Button/Button';
import { connect } from 'react-redux';
import { Table } from '../ServiceProvidersStyles';
import CloseIcon from '@material-ui/icons/Close';
import useTableFilter from '../../../components/TableFilter/useTableFilter';
import { serviceProvidersColumns } from '../ServiceProvidersColumns';
import TableFilterProvider from '../../../components/TableFilter/TableFilterProvider';
import TableSubComponent from '../TableSubComponent';
import useTableExpander from '../useTableExpander';
import {
  clearState,
  closeHeir,
  loadHeirChild,
  loadHeirTableData,
  serviceProvidersModule
} from '../ServiceProvidersDucks';

function HeirCloseModal({
  t,
  lang,
  modal,
  tableData,
  loadTableData,
  loadingChild,
  closeHeir,
  loadHeirChild,
  loadingHeirTable
}) {
  const [selectedId, setSelectedId] = useState(null);
  const filter = useTableFilter(loadTableData, undefined, '2');
  const { expanded, editExpanded } = useTableExpander();
  const onClickRow = ({ original }) =>
    setSelectedId(selectedId === original.id ? null : original.id);
  const config = { isModal: true, selectedId, setSelected: onClickRow };

  const subComponent = row => {
    return (
      <TableSubComponent
        onClickRow={onClickRow}
        config={config}
        data={row.original}
        loadChild={loadHeirChild}
      />
    );
  };
  return (
    <>
      <Modal
        open
        maxWidth="lg"
        title={t('serviceProviders_heirCloseTitle')}
        onClose={modal.close}
        scroll="paper"
      >
        <DialogContent dividers>
          <div style={{ fontSize: 15, marginBottom: 18 }}>
            {t('serviceProviders_heir_choose_providers')}
          </div>
          <TableFilterProvider index={filter.index}>
            <Table
              onClickRow={onClickRow}
              loading={loadingHeirTable}
              columns={serviceProvidersColumns(
                t,
                lang,
                config,
                editExpanded,
                loadingChild
              )}
              rowBgColor={row =>
                row.original.id === selectedId ? 'rgba(125,125,125,0.2)' : undefined
              }
              data={tableData.content}
              totalElements={tableData.totalElements}
              onClickLoadMore={() => filter.nextPage()}
              expanded={expanded}
              SubComponent={subComponent}
            />
          </TableFilterProvider>
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            type="button"
            color="secondary"
            icon={<CloseIcon fontSize="small" className="mr1" />}
            text={t('serviceProviders_close')}
            onClick={() =>
              closeHeir(
                modal.data.id,
                modal.data.parentServiceProviderId,
                selectedId,
                modal.close
              )
            }
          />
        </DialogActions>
      </Modal>
    </>
  );
}

export default compose(
  withTranslation,
  connect(
    state => ({
      loadingChild: state[serviceProvidersModule].loadingChild,
      tableData: state[serviceProvidersModule].heirTableData,
      loadingHeirTable: state[serviceProvidersModule].loadingHeirTable
    }),
    {
      loadTableData: loadHeirTableData,
      loadHeirChild,
      closeHeir,
      clearState
    }
  )
)(HeirCloseModal);
