import React from 'react';
import Modal from '../../../_ui/Modal/Modal';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Input from '../../../_ui/Input/Input';
import Select from '../../../_ui/Select/Select';
import withTranslation from '../../../components/_hoc/withTranslation';
import Form from '../../../_ui/Form/Form';
import Row from '../../../_ui/Row';
import * as Yup from 'yup';
import i18next from 'i18next';
import Button from '../../../_ui/Button/Button';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reject, statementModule } from '../StatementDucks';

const ValidationSchema = Yup.object().shape({
  negativeResolutionReason: Yup.string().required(() =>
    i18next.t('statement_rejectRequired')
  )
});

function RejectModal({ onClose, id, reject, t, loading }) {
  return (
    <Modal open title={t('statement_rejectTitle')} onClose={onClose} maxWidth="sm">
      <Form
        initialValues={{
          negativeResolutionReason: '',
          negativeResolutionReasonTextKk: '',
          negativeResolutionReasonTextRu: ''
        }}
        onSubmit={values => reject(id, values, onClose)}
        validate={ValidationSchema}
      >
        <DialogContent dividers>
          <Row label={t('statement_rejectReason')}>
            <Select
              name="negativeResolutionReason"
              lookupId="gu_refuse"
              placeholder={t('statement_rejectReason')}
            />
          </Row>
          <Row label={t('statement_rejectReasonTextKk')} className="flex items-start">
            <Input
              name="negativeResolutionReasonTextKk"
              placeholder={t('statement_rejectReasonTextKk')}
              multiline
            />
          </Row>
          <Row label={t('statement_rejectReasonTextRu')} className="flex items-start">
            <Input
              name="negativeResolutionReasonTextRu"
              placeholder={t('statement_rejectReasonTextRu')}
              multiline
            />
          </Row>
        </DialogContent>
        <DialogActions>
          <Button type="submit" color="secondary" text={t('statement_reject')} loading={loading}/>
        </DialogActions>
      </Form>
    </Modal>
  );
}

export default compose(
  withTranslation,
  connect(
    state => ({
      loading: state[statementModule].loadingReject
    }),
    { reject }
  )
)(RejectModal);
