import React, { useEffect, useState } from 'react';
import Modal from '../../_ui/Modal/Modal';
import { DialogActions, DialogContent, Typography } from '@material-ui/core';
import Button from '../../_ui/Button/Button';
import SaveIcon from '@material-ui/icons/Save';
import { useTranslation } from 'react-i18next';
import Row from '../../_ui/Row';
import moment from 'moment';
import ExportIcon from '@material-ui/icons/SaveAlt';
import Notice from '../../utils/Notice';
import { exportData, journalModule } from './JournalDucks';
import { useDispatch, useSelector } from 'react-redux';
import useSimpleModal from '../../components/_hooks/useSimpleModal';
import DatePicker from '../../_ui/DatePicker/DatePicker';

const defaultPeriod = { start: null, end: null };
export default function Export({ filter }) {
  const { loadingExport } = useSelector(state => state[journalModule]);
  const [period, setPeriod] = useState(defaultPeriod);
  const dispatch = useDispatch();
  const modal = useSimpleModal();
  const { t, i18n } = useTranslation();

  const getMaxDate = () => {
    return !period.start ? moment() : moment(period.start).add(30, 'days');
  };

  useEffect(() => {
    !modal.isOpen && setPeriod(defaultPeriod);
    // eslint-disable-next-line
  }, [modal.isOpen]);

  useEffect(() => {
    if (period.start && !period.end) {
      const end = getMaxDate().format('YYYY-MM-DDTHH:mm:ss');
      setPeriod({ ...period, end });
    }
    // eslint-disable-next-line
  }, [period]);

  const onExportHandle = () => {
    if (!filter.serviceType) {
      Notice.info(t('journalPage_exportNotice'));
    } else {
      if (filter.submissionDate) {
        dispatch(exportData(filter));
      } else if (period.start) {
        const newFilter = {
          ...filter,
          submissionAfterDate: period.start,
          submissionBeforeDate: period.end
        };
        dispatch(exportData(newFilter, modal.close));
      } else {
        modal.open({});
      }
    }
  };

  return (
    <div>
      <Button
        loading={loadingExport}
        text={t('journalPage_export_btn')}
        icon={<ExportIcon fontSize="small" className="mr1" />}
        onClick={onExportHandle}
      />
      {modal.isOpen && (
        <Modal
          open
          maxWidth="xs"
          onClose={modal.close}
          title={t('journalPage_export_select_date')}
          disableEnforceFocus
        >
          <DialogContent>
            <div className="flex  my2">
              <Typography variant={'subtitle2'} color={'textSecondary'}>
                {t('journalPage_export_limit_period')}
              </Typography>
            </div>
            <div className="col-12">
              <Row
                label={t('journalPage_export_start_date')}
                className="flex items-center mb1"
              >
                <DatePicker
                  lang={i18n.language}
                  withoutForm
                  value={period.start}
                  onChange={start => setPeriod({ ...period, start })}
                />
              </Row>
              <Row label={t('journalPage_export_end_date')}>
                <DatePicker
                  lang={i18n.language}
                  disabled={!period.start}
                  withoutForm
                  maxDate={getMaxDate()}
                  minDate={period.start}
                  value={period.end}
                  onChange={end => setPeriod({ ...period, end })}
                />
              </Row>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              disabled={!(period.start && period.end)}
              onClick={onExportHandle}
              icon={<SaveIcon fontSize="small" className="mr1" />}
              text={t('journalPage_export_btn')}
            />
          </DialogActions>
        </Modal>
      )}
    </div>
  );
}
