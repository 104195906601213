import { addTranslation } from '../../_helpers/Translate';

addTranslation({
  ru: {
    errors_BAD_GATEWAY: 'В данный момент ведутся технические работы. Просим войти позже.'
  },
  kk: {
    errors_BAD_GATEWAY:
      'Қазіргі уақытта техникалық жұмыстар жүргізілуде. Кейінірек кіруіңізді өтінеміз.'
  }
});
