import { createReducer } from 'redux-starter-kit';
import { handleError } from '../../utils/handleError';
import { ArchiveApi} from '../../_helpers/service';
import Dict from '../../utils/Dict';
import i18next from 'i18next';

/**
 * Constants
 */

export const kezekContingentModule = 'kezekContingent';
const LOADING_TABLE = `${kezekContingentModule}/LOADING_TABLE`;
const TABLE_DATA = `${kezekContingentModule}/TABLE_DATA`;
const CLEAR_STATE = `${kezekContingentModule}/CLEAR_STATE`;

/**
 * Reducer
 */

const initialState = {
  loadingTable: false,
  tableData: {
    content: [],
    totalElements: 0
  }
};

export default createReducer(initialState, {
  [LOADING_TABLE]: (state, action) => {
    state.loadingTable = action.payload;
  },
  [TABLE_DATA]: (state, action) => {
    const filter = action.filter || {};
    if (filter.pageNumber && filter.pageNumber !== 1) {
      state.tableData.content = [...state.tableData.content, ...action.payload.content];
    } else {
      state.tableData = action.payload;
    }
  },
  [CLEAR_STATE]: () => initialState
});

/**
 * Actions
 */

export const clearState = () => ({ type: CLEAR_STATE });

export const loadArchive = filter => async dispatch => {
  try {
    dispatch({ type: LOADING_TABLE, payload: true });
    let { data } = await ArchiveApi.loadArchive(JSON.stringify(filter));
    if (data.status === 'SUCCESS') {
      const dictServices = await Dict.itemsObject('gu_services');
      const dictStatuses = await Dict.itemsObject('gu_status_res');
      const dictResolution = await Dict.itemsObject('gu_status_pr');
      for (let row of data.result.content) {
        row.serviceTypeName = dictServices[row.serviceType] || {};
        row.statusName = dictStatuses[row.processingStatus] || {};
        row.resolutionTypeName = dictResolution[row.resolutionType] || {};
      }
      dispatch({ type: TABLE_DATA, payload: data.result, filter });
    } else {
      handleError(data, i18next.t('archiveDucks_errorLoadTable'));
    }
  } catch (error) {
    handleError(error, i18next.t('archiveDucks_errorLoadTable'));
  } finally {
    dispatch({ type: LOADING_TABLE, payload: false });
  }
};
