import React from 'react';
import Modal from '../../../_ui/Modal/Modal';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import Button from '../../../_ui/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { kezekBulletinModule, refuseBulletin } from '../KezekBulletinDucks';
import CancelIcon from '@material-ui/icons/Cancel';
import Form from '../../../_ui/Form/Form';
import Row from '../../../_ui/Row';
import Input from '../../../_ui/Input/Input';
import * as Yup from 'yup';
import i18next from 'i18next';

const ValidationSchema = Yup.object().shape({
  reasonMioRu: Yup.string().required(() => i18next.t('kezekBulletinPage_requiredField')),
  reasonMioKk: Yup.string().required(() => i18next.t('kezekBulletinPage_requiredField'))
});

export default function ModalRefusedRevoke({ data: id, close }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loading = useSelector(state => state[kezekBulletinModule].loadingRefuse);
  return (
    <Modal title={t('kezekBulletinPage_createRevokeTitle')} open onClose={close}>
      <Form
        initialValues={{ reasonMioRu: '', reasonMioKk: '' }}
        onSubmit={values => dispatch(refuseBulletin(id, values, close))}
        validate={ValidationSchema}
      >
        <DialogContent dividers>
          <Row
            label={t('kezekBulletinPage_reasonDeletionTextRu')}
            className="flex items-start"
          >
            <Input
              name="reasonMioRu"
              placeholder={t('kezekBulletinPage_reasonDeletionTextRu')}
              multiline
            />
          </Row>
          <Row
            label={t('kezekBulletinPage_reasonDeletionTextKk')}
            className="flex items-start"
          >
            <Input
              name="reasonMioKk"
              placeholder={t('kezekBulletinPage_reasonDeletionTextKk')}
              multiline
            />
          </Row>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            icon={<CancelIcon fontSize="small" className="mr1" />}
            text={t('kezekBulletinPage_refuse')}
            type="submit"
            loading={loading}
          />
        </DialogActions>
      </Form>
    </Modal>
  );
}
