import React from 'react';
import { useTranslation } from 'react-i18next';
import {loadRetired, contingentSchoolModule} from '../ContingentSchoolDucks'
import { useDispatch, useSelector } from 'react-redux';
import useTableFilter from '../../../components/TableFilter/useTableFilter';
import {RetiredColumns} from './RetiredColumns'
import StaticTablePagination from '../../../_ui/Table/StaticTablePaginated';

export default function RetiredPage() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const data = useSelector(state => state[contingentSchoolModule].retired);
  const loadingTable = useSelector(state => state[contingentSchoolModule].loadingTabel);
  const { nextPage } = useTableFilter(filter =>
    dispatch(loadRetired({filter}))
  );
  return (
    <div className='mt1'>
      <StaticTablePagination
        sortable={false}
        loading={loadingTable}
        columns={RetiredColumns({
          t,
          lang: i18n.language,
         })
        }
        totalElements={data.totalElements}
        data={data.content}
        urlOnClick={row => `/contingent/school/${row.original.id}`}
        onClickLoadMore={() => nextPage()}
      />
    </div>
  );
}

