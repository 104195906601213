import React, { useEffect, useState } from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import useColumnFilter from '../useColumnFilter';
import { useTranslation } from 'react-i18next';

function FilterDatePickerMonth({ name, endDate, style, placeholder, currentMonth }) {
  const { i18n } = useTranslation();
  const { value: filterValue, setFilter } = useColumnFilter(name);
  const getFullYear = new Date().getFullYear();
  const [value, setValue] = useState(`${getFullYear}-${currentMonth}-01T00:00:00`);
  const [error, setError] = useState();

  useEffect(() => {
    if (filterValue && !value) {
      setValue(filterValue);
    }
    // eslint-disable-next-line
  }, [filterValue, setValue]);

  return (
    <KeyboardDatePicker
      name={name}
      value={value}
      error={!!error}
      onError={error => setError(error)}
      variant="inline"
      format="MMMM"
      autoOk
      views={["month"]}
      openTo="month"
      invalidDateMessage={
        i18n.language === 'ru' ? 'Недопустимый формат даты' : 'Қате күн мәні'
      }
      minDateMessage={
        i18n.language === 'ru'
          ? 'Дата не должна быть раньше минимальной даты'
          : 'Қате күн мәні'
      }
      maxDateMessage={
        i18n.language === 'ru'
          ? 'Дата не должна быть после максимальной даты'
          : 'Қате күн мәні'
      }
      onChange={(date, dateString) => {
        if (date && date.isValid()) {
          const dateMoment = endDate
            ? date
                .hours(23)
                .minutes(59)
                .seconds(59)
                .milliseconds(0)
            : date
                .hours(0)
                .minutes(0)
                .seconds(0)
                .milliseconds(0);
          dateMoment.set('date', 1)
          setValue(dateMoment.format('YYYY-MM-DDTHH:mm:ss'));
          setFilter(dateMoment.format('YYYY-MM-DDTHH:mm:ss'));
        } else {
          setValue(dateString);
          setFilter(null);
        }
      }}
      placeholder={placeholder}
      style={style}
    />
  );
}

export default FilterDatePickerMonth;
