import { createReducer } from 'redux-starter-kit';
import { handleError } from '../../utils/handleError';
import i18next from 'i18next';
import { ContingentCommonApi } from '../../_helpers/service';
import Notice from '../../utils/Notice';
import Dict from '../../utils/Dict';
import { NCALayerSign } from '../../components/NCALayer/NCALayerSign';
import { ROLES } from '../../_helpers/Constants';

/**
 * Constants
 */

export const contingentSchoolModule = 'сontingentSchool';
const LOADING = `${contingentSchoolModule}/LOADING`;
const LOADING_TABEL = `${contingentSchoolModule}/LOADING_TABEL`;
const CLEAR_STATE = `${contingentSchoolModule}/CLEAR_STATE`;
const SET_PUPILS = `${contingentSchoolModule}/SET_PUPILS`;
const SET_RETIRED = `${contingentSchoolModule}/SET_RETIRED`;
const SET_META = `${contingentSchoolModule}/SET_META`;
const SET_DATA = `${contingentSchoolModule}/SET_DATA`;
const LOADING_SAVE = `${contingentSchoolModule}/LOADING_SAVE`;
const LOADING_DIMISSED = `${contingentSchoolModule}/LOADING_DIMISSED`;
const SET_REPORT = `${contingentSchoolModule}/SET_REPORT`;
const SET_REPORT_FOOD = `${contingentSchoolModule}/SET_REPORT_FOOD`;
const SET_CAUSE = `${contingentSchoolModule}/SET_CAUSE`;
const SET_CLASSES = `${contingentSchoolModule}/SET_CLASSES`;
const LOADING_CLASSES_CHILD = `${contingentSchoolModule}/LOADING_CLASSES_CHILD`;
const SET_ORGANIZATIONSE = `${contingentSchoolModule}/SET_ORGANIZATIONSE`;
const LOADING_EXPORT_DATA = `${contingentSchoolModule}/LOADING_EXPORT_DATA`;
const SET_CLASSES_ORG = `${contingentSchoolModule}/SET_CLASSES_ORG`;

/**
 * Reducer
 */

const initialState = {
  loading: false,
  loadingTabel: false,
  loadingSave: false,
  loadingDismissed: false,
  loadingClasses: false,
  loadingExport: false,
  pupils: {
    content: [],
    totalElements: 0
  },
  retired: {
    content: [],
    totalElements: 0
  },
  meta: {},
  data: {},
  report: {
    content: [],
    totalElements: 0
  },
  reportFood: [],
  cause: [],
  organizations: [],
  classes: [],
  classesOrg: [],
};

export default createReducer(initialState, {
  [SET_PUPILS]: (state, action) => {
    const filter = action.filter || {};
    if (filter.pageNumber && filter.pageNumber !== 1) {
      state.pupils.content = [...state.pupils.content, ...action.payload.content];
    } else {
      state.pupils = action.payload;
    }
  },
  [SET_RETIRED]: (state, action) => {
    const filter = action.filter || {};
    if (filter.pageNumber && filter.pageNumber !== 1) {
      state.retired.content = [...state.retired.content, ...action.payload.content];
    } else {
      state.retired = action.payload;
    }
  },
  [SET_META]: (state, action) => {
    state.meta = action.payload;
  },
  [LOADING]: (state, action) => {
    state.loading = action.payload;
  },
  [LOADING_TABEL]: (state, action) => {
    state.loadingTabel = action.payload;
  },
  [SET_DATA]: (state, action) => {
    state.data = action.payload;
  },
  [LOADING_SAVE]: (state, action) => {
    state.loadingSave = action.payload;
  },
  [LOADING_DIMISSED]: (state, action) => {
    state.loadingDismissed = action.payload;
  },
  [SET_REPORT]: (state, action) => {
    const filter = action.filter || {};
    if (filter.pageNumber && filter.pageNumber !== 1) {
      state.report.content = [...state.report.content, ...action.payload.content];
    } else {
      state.report = action.payload;
    }
  },
  [SET_REPORT_FOOD]: (state, action) => {
    state.reportFood = action.payload;
  },
  [SET_CAUSE]: (state, action) => {
    state.cause = action.payload;
  },
  [SET_CLASSES]: (state, action) => {
    state.classes = action.payload;
  },
  [LOADING_CLASSES_CHILD]: (state, action) => {
    state.loadingClasses = action.payload;
  },
  [SET_ORGANIZATIONSE]: (state, action) => {
    state.organizations = action.payload.map((item) => { return {...item, code: item.id } });
  },
  [LOADING_EXPORT_DATA]: (state, action) => {
    state.loadingExport = action.payload;
  },
  [SET_CLASSES_ORG]: (state, action) => {
    state.classesOrg = action.payload;
  },
  [CLEAR_STATE]: () => initialState
});

/**
 * Actions
 */

export const clearState = () => ({ type: CLEAR_STATE });

export const loadPupils = ({ filter }) => async dispatch => {
  try {
    dispatch({ type: LOADING_TABEL, payload: true });
    filter['contingentStatus'] = 'STUDIES_SCHOOL';
    const { data } = await ContingentCommonApi.loadChildSchool(JSON.stringify(filter));
    if (data.status === 'SUCCESS') {
      const dictClassType = await Dict.itemsObject('gu_class_type');
      const dictletter = await Dict.itemsObject('39051');
      for (let row of data.result.content) {
        row.classTypeName = dictClassType[row.studentGrade] || {};
        row.letterName = dictletter[row.letter] || {};
      }
    }
    dispatch({ type: SET_PUPILS, payload: data.result, filter });
  } catch (error) {
    handleError(error, i18next.t('contingentSchool_pupilsErrorLoadTable'));
  } finally {
    dispatch({ type: LOADING_TABEL, payload: false });
  }
};

export const setContingent = ({
  values,
  close,
  studentIIN,
  history,
  id,
  childGroupId
}) => async dispatch => {
  try {
    dispatch({ type: LOADING_SAVE, payload: true });
    values['contingentChanges'] = [...values['form_contingent_school_student']];
    delete values['form_contingent_school_student'];
    if (id) {
      await ContingentCommonApi.changeContingent({
        id: id,
        iin: studentIIN,
        contingentType: 'SCHOOL_STUDENT',
        isChangingGroup: true,
        childGroupId,
        ...values
      });
    } else {
      await ContingentCommonApi.setContingent({
        iin: studentIIN,
        contingentType: 'SCHOOL_STUDENT',
        childGroupId,
        isChangingGroup: true,
        ...values
      });
    }
    history.push('/contingent/pupils');
    Notice.success(i18next.t('contingentSchool_saveSuccess'));
    close();
  } catch (error) {
    handleError(error, i18next.t('contingentSchool_addError'));
  } finally {
    dispatch({ type: LOADING_SAVE, payload: false });
  }
};

export const loadRetired = ({ filter }) => async dispatch => {
  try {
    dispatch({ type: LOADING_TABEL, payload: true });
    filter['contingentStatus'] = 'LEFT_SCHOOL';
    const { data } = await ContingentCommonApi.loadChildSchool(JSON.stringify(filter));
    if (data.status === 'SUCCESS') {
      const dictClassType = await Dict.itemsObject('gu_class_type');
      const dictletter = await Dict.itemsObject('39051');
      for (let row of data.result.content) {
        row.classTypeName = dictClassType[row.studentGrade] || {};
        row.letterName = dictletter[row.letter] || {};
      }
    }
    dispatch({ type: SET_RETIRED, payload: data.result, filter });
  } catch (error) {
    handleError(error, i18next.t('contingentSchool_exitedErrorLoadTable'));
  } finally {
    dispatch({ type: LOADING_TABEL, payload: false });
  }
};

export const getMeta = () => async dispatch => {
  try {
    dispatch({ type: LOADING, payload: true });
    let data = {
      metadataKey: 'form_contingent_school_student'
    };
    const { data: meta } = await ContingentCommonApi.loadMeta(data.metadataKey);
    dispatch({ type: SET_META, payload: meta });
  } catch (error) {
    handleError(error, i18next.t('contingentSchool_errorLoadMeta'));
  } finally {
    dispatch({ type: LOADING, payload: false });
  }
};

export const loadData = id => async dispatch => {
  try {
    dispatch({ type: LOADING, payload: true });
    if (id) {
      const { data } = await ContingentCommonApi.getContingentItem(id);
      // const { data: meta }  = await ContingentCommonApi.loadMeta(data.metadataKey);
      // dispatch({ type: SET_META, payload: meta });
      dispatch({ type: SET_DATA, payload: data });
    }
  } catch (error) {
    handleError(error, i18next.t('contingentPassport_errorLoad'));
  } finally {
    dispatch({ type: LOADING, payload: false });
  }
};

export const disposalSchool = ({
  id,
  history,
  close,
  values,
  studentIIN,
  info
}) => async dispatch => {
  try {
    dispatch({ type: LOADING_DIMISSED, payload: true });
    values['contingentChanges'] = [...values['form_contingent_school_student']];
    delete values['form_contingent_school_student'];
    const response = await ContingentCommonApi.getSignString({
      iin: studentIIN,
      contingentType: 'SCHOOL_STUDENT',
      ...values
    });
    const signature = await NCALayerSign(response.data.result.signature);
    if (signature) {
      let data = await ContingentCommonApi.disposalSchool({
        id: id,
        signature: signature,
        dateDropout: info.dateDropout,
        dropoutReason: info.dropoutReason
      });
      if (data.status === 200) {
        close();
        history.push('/contingent/pupils');
        Notice.success(i18next.t('contingentSchool_addSuccess'));
      }
    }
  } catch (error) {
    handleError(error, i18next.t('contingentSchool_retirementError'));
  } finally {
    dispatch({ type: LOADING_DIMISSED, payload: false });
  }
};

export const loadDateReport = ({
  filter,
  minDate,
  maxDate,
}) => async (dispatch, getState) => {
  try {
    dispatch({ type: LOADING_TABEL, payload: true });
    const userProfile = { ...getState().login.user };
    filter['dateMin'] = `${minDate}T00:00:00`;
    filter['dateMax'] = `${maxDate}T00:00:00`;
    if (userProfile) {
      if (userProfile.roles.includes(ROLES.ES_REGION_EMPLOYEES) ||
          userProfile.roles.includes(ROLES.ES_REGION_MIO_EMPLOYEES) ||
          userProfile.roles.includes(ROLES.ES_GODS)) {
        delete userProfile.organization_id;
        if (!filter.serviceProviderLocation) {
          delete filter['serviceProviderId'];
          delete filter['groupId']
        } else if (!filter['serviceProviderId']) {
          delete filter['groupId']
        }
      } else {
        filter['serviceProviderId'] = userProfile?.organization_id;
      }
    }
    if (filter?.serviceProviderId) {
      dispatch(getClassesOrg(filter?.serviceProviderId))
    }
    if (filter.serviceProviderLocation || filter.serviceProviderId) {
      const { data } = await ContingentCommonApi.loadSchoolReport(JSON.stringify(filter));
      if (filter.serviceProviderLocation) {
        let locationOrganizations = await ContingentCommonApi.getSchoolsOrganizations(
          filter.serviceProviderLocation
        );
        dispatch({ type: SET_ORGANIZATIONSE, payload: locationOrganizations.data.result });
      }
      if (data.status === 'SUCCESS') {
        let reasonChildren = await Dict.itemsObject('absence_reason_children');
        let reasonList = [];
        for (let variable in reasonChildren) {
          reasonList.push(reasonChildren[variable]);
        }
        dispatch({ type: SET_CAUSE, payload: reasonList });
        dispatch({ type: SET_REPORT, payload: data.result, filter });
      }
    } else {
      dispatch({ 
        type: SET_REPORT, 
        payload: {
          content: [],
          totalElements: 0 
        }, 
        filter
      })
    }
  } catch (error) {
    handleError(error, i18next.t('contingentSchool_reportErrorLoadTable'));
  } finally {
    dispatch({ type: LOADING_TABEL, payload: false });
  }
};

export const loadDateFoodReport = ({ filter}) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: LOADING_TABEL, payload: true });

    const userProfile = { ...getState().login.user };
    if (userProfile) {
      if (userProfile.roles.includes(ROLES.ES_REGION_EMPLOYEES) ||
        userProfile.roles.includes(ROLES.ES_REGION_MIO_EMPLOYEES) ||
        userProfile.roles.includes(ROLES.ES_GODS)) {
        delete userProfile.organization_id;
        if (!filter.serviceProviderLocation) {
          delete filter['serviceProviderId'];
          delete filter['groupId']
        } else if (!filter['serviceProviderId']) {
          delete filter['groupId']
        }
      } else {
        filter['serviceProviderId'] = userProfile?.organization_id;
      }
    }
    if (filter?.serviceProviderId) {
      dispatch(getClassesOrg(filter?.serviceProviderId))
    }
    if (filter.serviceProviderLocation || filter.serviceProviderId) {
      const { data } = await ContingentCommonApi.loadSchoolFoodReport(JSON.stringify(filter));
      if (filter.serviceProviderLocation) {
        let locationOrganizations = await ContingentCommonApi.getSchoolsOrganizations(
          filter.serviceProviderLocation
        );
        dispatch({ type: SET_ORGANIZATIONSE, payload: locationOrganizations.data.result });
      }
      if (data.status === 'SUCCESS') {
        console.log('data.result', data.result)
        dispatch({ type: SET_REPORT_FOOD, payload: data.result });
      }
    } else {
      dispatch({
        type: SET_REPORT_FOOD,
        payload: []
      })
    }
  } catch (error) {
    handleError(error, i18next.t('contingentSchool_reportErrorLoadTable'));
  } finally {
    dispatch({ type: LOADING_TABEL, payload: false });
  }
};

export const saveCause = ({ causeCode, id, close }) => async (dispatch, getState) => {
  try {
    dispatch({ type: LOADING_SAVE, payload: true });
    const state = getState()[contingentSchoolModule];
    const stateCopy = JSON.parse(JSON.stringify(state.report));
    const { data } = await ContingentCommonApi.saveItemReport({
      id: id,
      absenceReason: causeCode
    });
    if (data) {
      stateCopy.content.forEach(item => {
        if (item.visitsInfo) {
          item.visitsInfo.forEach((visitInfo, index) => {
            if (visitInfo.id === id) {
              item.visitsInfo[index] = { ...data.result };
              if (!visitInfo.hasOwnProperty('absenceReason')) {
                item.unvisitedDaysWithReason = item.unvisitedDaysWithReason + 1;
                item.unvisitedDaysWithoutReason = item.unvisitedDaysWithoutReason - 1;
              }
            }
          });
        }
      });
      dispatch({ type: SET_REPORT, payload: stateCopy });
      Notice.success(i18next.t('contingentSchool_causeAddSuccess'));
      close();
    }
  } catch (error) {
    handleError(error, i18next.t('contingentSchool_causeAddError'));
  } finally {
    dispatch({ type: LOADING_SAVE, payload: false });
  }
};

export const getClasses = () => async dispatch => {
  try {
    dispatch({ type: LOADING_CLASSES_CHILD, payload: true });
    const { data } = await ContingentCommonApi.getClasses();
    if (data.status === 'SUCCESS') {
      dispatch({ type: SET_CLASSES, payload: data.result });
    }
  } catch (error) {
    handleError(error, i18next.t('contingentSchool_classAddError'));
  } finally {
    dispatch({ type: LOADING_CLASSES_CHILD, payload: false });
  }
};

export const getClassesOrg = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING_SAVE, payload: true });
    if (id) {
      const {data} = await ContingentCommonApi.getClassesOrg(id);
      if (data.status === 'SUCCESS') {
        dispatch({ type: SET_CLASSES_ORG, payload: data.result })
      }
    }
  } catch (error) {
    handleError(error, i18next.t('contingentPage_reportErrorLoadTable'));
  } finally {
    dispatch({ type: LOADING_SAVE, payload: false });
  }
};

export const exportData = ({
  minDate,
  maxDate,
  filter,
  currentOrg
}) => async dispatch => {
  try {
    dispatch({ type: LOADING_EXPORT_DATA, payload: true });
    delete filter.pageNumber;
    delete filter.pageSize;
    filter['dateMin'] = `${minDate}T00:00:00`;
    filter['dateMax'] = `${maxDate}T00:00:00`;
    if (!filter?.serviceProviderId) {
      if (currentOrg) {
        filter['serviceProviderId'] = currentOrg;
      } else {
        delete filter['serviceProviderId'];
      }
    }
    const response = await ContingentCommonApi.exportDataSchool(JSON.stringify(filter));
    download(response.data, `${i18next.t('contingentSchool_reportTitle')}.xlsx`);
  } catch (error) {
    handleError(error, i18next.t('contingentSchool_reportExportError'));
  } finally {
    dispatch({ type: LOADING_EXPORT_DATA, payload: false });
  }
};

export const exportDataFree = ({
                                 filter,
                                 currentOrg
                               }) => async dispatch => {
  try {
    dispatch({ type: LOADING_EXPORT_DATA, payload: true });
    delete filter.pageNumber;
    delete filter.pageSize;
    if (!filter?.serviceProviderId) {
      if (currentOrg) {
        filter['serviceProviderId'] = currentOrg;
      } else {
        delete filter['serviceProviderId'];
      }
    }
    const response = await ContingentCommonApi.exportDataFree(JSON.stringify(filter));
    download(response.data, `${i18next.t('contingentSchool_reportFoodTitle')}.xlsx`);
  } catch (error) {
    handleError(error, i18next.t('contingentSchool_reportExportError'));
  } finally {
    dispatch({ type: LOADING_EXPORT_DATA, payload: false });
  }
};

const download = (data, fileName) => {
  let element = document.createElement('a');
  document.body.appendChild(element);
  element.style.display = 'none';
  let blob = new Blob([data]);
  let url = window.URL.createObjectURL(blob);
  element.href = url;
  element.download = fileName;
  element.click();
  window.URL.revokeObjectURL(url);
};
