import { createReducer } from 'redux-starter-kit';
import { handleError } from '../../utils/handleError';
import { ClassApi } from '../../_helpers/service';
import Notice from '../../utils/Notice';
import i18next from 'i18next';
import Dict from '../../utils/Dict';


/**
 * Constants
 */

export const classPageModule = 'classPage';
const LOADING = `${classPageModule}/LOADING`;
const SET_DATA = `${classPageModule}/SET_DATA`;
const CLEAR_STATE = `${classPageModule}/CLEAR_STATE`;

/**
 * Reducer
 */

const initialState = {
  loading: false,
  loadingSave: false,
  data: {
    content: [],
    totalElements: 0

  },
};

export default createReducer(initialState, {
  [SET_DATA]: (state, action) => {
    const filter = action.filter || {};
    if (filter.pageNumber && filter.pageNumber !== 1) {
      state.data.content = [...state.data.content, ...action.payload.content];
    } else {
      state.data = action.payload;
    }
  },
  [LOADING]: (state, action) => {
    state.loading = action.payload;
  },
  [CLEAR_STATE]: () => initialState
});

/**
 * Actions
 */

export const loadData = ({filter}) => async dispatch => {
  try {
    dispatch({ type: LOADING, payload: true });
    let hasKey = Reflect.has(filter, 'stateOrder');
    if (hasKey) {
      if (filter['stateOrder'] === 'true') {
        filter['stateOrder'] = true;
      } else {
        filter['stateOrder'] = false;
      }
    }
    let {data} = await ClassApi.getClass(JSON.stringify(filter));
    if (data.status === 'SUCCESS') {
      const distEduForm = await Dict.itemsObject('46');
      const distClassNumberType = await Dict.itemsObject('gu_class_type');
      const dictLiter = await Dict.itemsObject('39051');
      const dictlanguage = await Dict.itemsObject('gu_language');
      for (let row of data.result.content) {
        row.distEduForm = distEduForm[row.eduForm] || {};
        row.distClassNumberType = distClassNumberType[row.classNumber] || {};
        row.dictLiter = dictLiter[row.letter] || {};
        row.languageChild = dictlanguage[row.language] || {};
      }
    }
    dispatch({ type: SET_DATA, payload: data.result, filter });
  } catch (error) {
    handleError(error, i18next.t('classPage_errorLoadTable'));
  } finally {
    dispatch({ type: LOADING, payload: false });
  }
};

export const clearState = () => ({ type: CLEAR_STATE });

export const removeClass = ({id}) => async (dispatch, getState) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const state = getState()[classPageModule].data;
    const stateCopy = JSON.parse(JSON.stringify(state.content));
    const {data} = await ClassApi.removeClass({id});
    dispatch({ 
      type: SET_DATA, 
      payload: { 
        content: stateCopy.filter((item) => {
          return item.id !== data.result.id
        }), 
        totalElements: state.totalElements - 1  
      } 
    })
    Notice.success(i18next.t('classPage_deleteSuccess'));
  } catch (error) {
    if (error.response.data.errors[0].code === 'error.group_deletion_with_children') {
      Notice.error(i18next.t('classPage_errorRemoveInfo'))
    } else {
      handleError(error, i18next.t('classPage_errorRemove'));
    }
  } finally {
    dispatch({ type: LOADING, payload: false });    
  }
} 
