import { createReducer } from 'redux-starter-kit';
import { handleError } from '../../utils/handleError';
import { KezekArchiveApi } from '../../_helpers/service';
import Dict from '../../utils/Dict';
import i18next from 'i18next';

/**
 * Constants
 */

export const kezekArchiveModule = 'kezekArchive';
const LOADING_TABLE = `${kezekArchiveModule}/LOADING_TABLE`;
const TABLE_DATA = `${kezekArchiveModule}/TABLE_DATA`;
const CLEAR_STATE = `${kezekArchiveModule}/CLEAR_STATE`;

/**
 * Reducer
 */

const initialState = {
  loadingTable: false,
  tableData: {
    content: [],
    totalElements: 0
  }
};

export default createReducer(initialState, {
  [LOADING_TABLE]: (state, action) => {
    state.loadingTable = action.payload;
  },
  [TABLE_DATA]: (state, action) => {
    const filter = action.filter || {};
    if (filter.pageNumber && filter.pageNumber !== 1) {
      state.tableData.content = [...state.tableData.content, ...action.payload.content];
    } else {
      state.tableData = action.payload;
    }
  },
  [CLEAR_STATE]: () => initialState
});

/**
 * Actions
 */

export const clearState = () => ({ type: CLEAR_STATE });

export const loadData = filter => async dispatch => {
  try {
    dispatch({ type: LOADING_TABLE, payload: true });
    const filterData = { isArchived: true, isActive: false, sorting: {},...filter };
    let { data } = await KezekArchiveApi.loadData(JSON.stringify(filterData));
    if (data.status === 'SUCCESS') {
      const dictQueuedType = await Dict.itemsObject('gu_queued_type');
      const dictPriorityQueued = await Dict.itemsObject('gu_priority_queued');
      const dictAppStatus = await Dict.itemsObject('gu_app_status');
      const dictKato = await Dict.itemsObject('60');

      for (let row of data.result.content) {
        row.legalLocality = dictKato[row.legalLocality] || {};
        row.appStatusName = dictAppStatus[row.appStatus] || {};
        row.queuedTypeName = dictQueuedType[row.queuedType] || {};
        row.priorityQueuedName = dictPriorityQueued[row.priorityQueued] || {};
        row.childName = `${row.childSurname || ''} ${row.childName ||
          ''} ${row.childMiddleName || ''}`;
        row.requesterName = `${row.requesterSurname || ''} ${row.requesterName || ''} `;
      }
      dispatch({ type: TABLE_DATA, payload: data.result, filter });
    } else {
      handleError(data, i18next.t('kezekArchiveDucks_errorLoadTable'));
    }
  } catch (error) {
    handleError(error, i18next.t('kezekArchiveDucks_errorLoadTable'));
  } finally {
    dispatch({ type: LOADING_TABLE, payload: false });
  }
};
