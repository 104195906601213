import React from 'react';
import PropTypes from 'prop-types';
import Field from '../Form/Field';
import TextField from '@material-ui/core/TextField';

const Input = props => {
  const { withoutForm, name, ...restProps } = props;

  return withoutForm ? (
    <TextField
      name={name}
      onChange={props.onChange}
      {...restProps}
    />
  ) : (
    <Field name={name}>
      {({ onChange, ...fieldProps }) => (
        <TextField
          name={name}
          {...restProps}
          {...fieldProps}
          onChange={(event) => {
            onChange(event.target.value);
            props.onChange && props.onChange(event);
          }}
          helperText={fieldProps.helperText || props.helperText}
          error={fieldProps.error || props.error}
        />
      )}
    </Field>
  );
};

Input.propsTypes = {
  withoutForm: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
};

Input.defaultProps = {
  withoutForm: false,
  label: '',
};

export default Input;
