import React, { useState } from 'react';
import Modal from '../../../_ui/Modal/Modal';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Input from '../../../_ui/Input/Input';
import Select from '../../../_ui/Select/Select';
import withTranslation from '../../../components/_hoc/withTranslation';
import Form from '../../../_ui/Form/Form';
import Row from '../../../_ui/Row';
import * as Yup from 'yup';
import i18next from 'i18next';
import Button from '../../../_ui/Button/Button';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { commitNegative, statementModule } from '../StatementDucks';
import SignIcon from '@material-ui/icons/DoneAll';
import SendIcon from '@material-ui/icons/Done';
import { StatementApi } from '../../../_helpers/service';
import { handleError } from '../../../utils/handleError';
import useDict from '../../../components/_hooks/useDict';

const ValidationSchema = Yup.object().shape({
  negativeResolutionReason: Yup.string().required(() =>
    i18next.t('statement_negativeRequired')
  )
});

function NegativeModal({ onClose, id, commitNegative, t, loading, serviceType }) {
  const [file, setFile] = useState();
  const [values, setValues] = useState();
  const gu_refuse = useDict('gu_refuse');
  const loadFile = values => {
    StatementApi.downloadCommitFile(id, values)
      .then(response => {
        setFile(response.data.result);
      })
      .catch(function(error) {
        handleError(error, t('statement_negativePreview'));
      });
  };
  return (
    <Modal
      open
      title={t('statement_negativeTitle')}
      onClose={onClose}
      maxWidth="md"
    >
      <Form
        initialValues={{
          negativeResolutionReason: '',
          negativeResolutionReasonTextKk: '',
          negativeResolutionReasonTextRu: ''
        }}
        onSubmit={values => {
          loadFile(values);
          setValues(values);
        }}
        validate={ValidationSchema}
        render={({ negativeResolutionReason }) => {
          return (
            <>
              <DialogContent dividers>
                <div className="center mb2">
                  {file && (
                    <object
                      type="application/pdf"
                      data={`data:application/pdf;base64,${file.fileContent}`}
                      width="800px"
                      height="1184px"
                    >
                      <p>{t('statement_positivePreview')}</p>
                    </object>
                  )}
                </div>
                {!file && (
                  <div>
                    <Row label={t('statement_negativeReason')}>
                      <Select
                        name="negativeResolutionReason"
                        options={
                          gu_refuse.some(
                            item =>
                              item.hasOwnProperty(serviceType.toLowerCase()) ||
                              item.hasOwnProperty(serviceType.toUpperCase())
                          )
                            ? gu_refuse.filter(
                                item =>
                                  item[serviceType.toLowerCase()] === 'true' ||
                                  item[serviceType.toUpperCase()] === 'true'
                              )
                            : gu_refuse
                        }
                        placeholder={t('statement_negativeReason')}
                      />
                    </Row>
                    {['001', '002', '003'].includes(negativeResolutionReason) && (
                      <div>
                        <Row
                          label={t('statement_negativeMissingDocumentsKkName')}
                          className="flex items-start"
                        >
                          <Input
                            name="negativeResolutionMissingDocumentsListKk"
                            placeholder={t('statement_negativeMissingDocumentsKkName')}
                            multiline
                          />
                        </Row>
                        <Row
                          label={t('statement_negativeMissingDocumentsRuName')}
                          className="flex items-start"
                        >
                          <Input
                            name="negativeResolutionMissingDocumentsListRu"
                            placeholder={t('statement_negativeMissingDocumentsRuName')}
                            multiline
                          />
                        </Row>
                      </div>
                    )}
                    {negativeResolutionReason === '007' && (
                      <div>
                        <Row
                          label={t('statement_negativeReasonTextKk')}
                          className="flex items-start"
                        >
                          <Input
                            name="negativeResolutionReasonTextKk"
                            placeholder={t('statement_negativeReasonTextKk')}
                            multiline
                          />
                        </Row>
                        <Row
                          label={t('statement_negativeReasonTextRu')}
                          className="flex items-start"
                        >
                          <Input
                            name="negativeResolutionReasonTextRu"
                            placeholder={t('statement_negativeReasonTextRu')}
                            multiline
                          />
                        </Row>
                      </div>
                    )}
                  </div>
                )}
              </DialogContent>
              <DialogActions>
                {file ? (
                  <Button
                    icon={<SignIcon style={{ marginRight: 5 }} />}
                    text={t('statement_negativeSaveAction')}
                    onClick={() => commitNegative(id, values, onClose)}
                    loading={loading}
                  />
                ) : (
                  <Button
                    icon={<SendIcon style={{ marginRight: 5 }} />}
                    type="submit"
                    color="secondary"
                    text={t('statement_negativeAction')}
                    loading={loading}
                  />
                )}
              </DialogActions>
            </>
          );
        }}
      />
    </Modal>
  );
}

export default compose(
  withTranslation,
  connect(
    state => ({
      loading: state[statementModule].loadingNegative,
      serviceType: state[statementModule].requestForm.serviceType
    }),
    { commitNegative }
  )
)(NegativeModal);
