import { addTranslation } from '../../../_helpers/Translate';

addTranslation({
  ru: {
    previewDocument_showContentError: 'Не удалось отобразить документ',
    previewDocument_downloadContentError: 'Не удалось загрузить документ',
    previewDocument_downloadContent: 'Скачать документ'
  },
  kk: {
    previewDocument_showContentError: 'Не удалось отобразить документ',
    previewDocument_downloadContentError: 'Не удалось загрузить документ',
    previewDocument_downloadContent: 'Скачать документ'
  }
});
