import XLSX from 'xlsx';
import tempaXLSX from 'tempa-xlsx';

export default function BuildExcel(data = [], columnsWidth = [], fileName) {
  const wb = XLSX.utils.book_new();
  const ws = buildData(data, columnsWidth);
  XLSX.utils.book_append_sheet(wb, ws, 'Лист 1');
  const option = { bookType: 'xlsx', bookSST: true, type: 'binary' };
  const wbOut = tempaXLSX.write(wb, option);
  const blob = buildBlob(wbOut);
  downloadFile(blob, `${fileName}.xlsx` || 'excelFile.xlsx');
}

function buildData(data, columnsWidth) {
  const cellsValue = data.map(row => row.map(cell => cell.v));
  const ws = XLSX.utils.aoa_to_sheet(cellsValue);
  data.forEach((row, r) => {
    row.forEach((cell, c) => {
      const cellRef = tempaXLSX.utils.encode_cell({ c, r });
      if (ws.hasOwnProperty(cellRef)) {
        ws[cellRef].s = cell.s;
      }
    });
  });
  ws['!cols'] = columnsWidth.map(size => ({ wch: size }));
  return ws;
}

const buildBlob = wbOut => {
  const option = { type: 'application/octet-stream' };
  const buf = new ArrayBuffer(wbOut.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i !== wbOut.length; ++i) {
    view[i] = wbOut.charCodeAt(i) & 0xff;
  }
  return new Blob([buf], option);
};

function downloadFile(blob, fileName) {
  let element = document.createElement('a');
  document.body.appendChild(element);
  element.style.display = 'none';
  let url = window.URL.createObjectURL(blob);
  element.href = url;
  element.download = fileName;
  element.click();
  window.URL.revokeObjectURL(url);
}
