import React from 'react';
import { compose } from 'redux';
import withTranslation from '../../components/_hoc/withTranslation';
import { connect } from 'react-redux';
import { statementModule } from './StatementDucks';
import moment from 'moment';
import { useTheme } from '@material-ui/core';
import { RAG_STATUS_COLOR } from '../../_helpers/Constants';
import Tooltip from '@material-ui/core/Tooltip';

const getPassportInfo = (info, t, lang) => {
  let result = [{ label: t('statement_number'), value: info.requestForm.id }];
  const requestForm = info.requestForm || {};
  const serviceProvider = requestForm.serviceProvider || {};

  requestForm.processingStatusName &&
    result.push({
      label: t('statement_status'),
      value: requestForm.processingStatusName[`${lang}_name`]
    });
  requestForm.resolutionTypeName &&
    result.push({
      label: t('statement_processing_result'),
      value: requestForm.resolutionTypeName[`${lang}_name`]
    });
  result.push({
    label: t('statement_date'),
    value: moment(requestForm.submissionDate).format('DD.MM.YYYY HH:mm')
  });
  if (requestForm.resolutionDate) {
    result.push({
      label: t('statement_resolutionDate'),
      value: moment(requestForm.resolutionDate).format('DD.MM.YYYY HH:mm')
    });
  }
  result.push({
    label: t('statement_iin'),
    value: info.requester.iin
  });
  requestForm.externalRequestId &&
    result.push({
      label: t('statement_PEP_number'),
      value: requestForm.externalRequestId
    });

  if (requestForm.serviceProvider) {
    serviceProvider.id &&
      result.push({
        label: t('statement_providerId'),
        value: serviceProvider.id
      });
    serviceProvider.bin &&
      result.push({
        label: t('statement_providerBin'),
        value: serviceProvider.bin
      });
    serviceProvider[`${lang}_name`] &&
      result.push({
        label: t('statement_providerName'),
        value: serviceProvider[`${lang}_name`]
      });
  }

  if (requestForm.ragStatus) {
    result.push({
      label: t('statement_ragStatus'),
      ragStatus: requestForm.ragStatus,
      ragStatusValidUntil: requestForm.ragStatusValidUntil
    });
  }
  return result;
};

function StatementInfo({ info, t, lang }) {
  const { palette } = useTheme();

  return (
    <div className="mb2 card p2">
      {getPassportInfo(info, t, lang).map((info, index) =>
        info.ragStatus ? (
          <div className="pd1 flex" key={index}>
            <span className="bold textSecondary mr1">{info.label}:</span>
            <Tooltip placement="top" title={info.ragStatusValidUntil}>
              <span
                style={{
                  height: 20,
                  flexGrow: 1,
                  backgroundColor: RAG_STATUS_COLOR.getColor(info.ragStatus, palette)
                }}
              />
            </Tooltip>
          </div>
        ) : (
          <div className="pd1" key={index}>
            <span className="bold textSecondary mr1">{info.label}:</span>
            <span>{info.value}</span>
          </div>
        )
      )}
    </div>
  );
}

export default compose(
  withTranslation,
  connect(
    state => ({
      info: state[statementModule].info
    }),
    null
  )
)(StatementInfo);
