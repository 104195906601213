import React, { useCallback, useMemo, useState } from 'react';
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs';
import Paper from '@material-ui/core/Paper';
import { useTranslation } from 'react-i18next';
import './ManualTranslate';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ManualModal from './ManualModal';
import ManualCard from './ManualCard';

const useStyles = makeStyles({
  paper: {
    width: "100%"
  }
})

function ManualPage() {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [selectedInstructionKey, setSelectedInstructionKey] = useState(null);
  const handleChangeSelectedInstruction = useCallback(
    event => {
      setSelectedInstructionKey(event.currentTarget.dataset.keyofinstruction);
    },
    []
  )

  const handleCloseModal = useCallback(
    () => {
      setSelectedInstructionKey(null)
    },
    []
  );

  const breadCrumbItems = useMemo(
    () => [
      {
        name: t('breadcrumbs_home'),
        href: '/'
      },
      {
        name: t('breadcrumbs_manual'),
        href: '/instruction'
      }
    ],
    [t]
  );

  return (
    <>
      <BreadCrumbs items={breadCrumbItems} />
      <Paper className="pb3 pt1 px3">
        <h3>{t('manual_title')}</h3>
        <div className="center">
          <ManualCard
            t={t}
            onChangeSelectedInstruction={handleChangeSelectedInstruction}
          />

          <ManualModal
            t={t}
            onClose={handleCloseModal}
            classes={{paper: classes.paper}}
            selectedInstructionKey={selectedInstructionKey}
            language={i18n.language}
          />
        </div>
      </Paper>
    </>
  );
}

export default ManualPage;
