import { addTranslation } from '../../_helpers/Translate';

addTranslation({
  ru: {
    kezekBulletin_title: 'Бюллетень свободных мест',
    kezekBulletin_columnID: 'Номер',
    kezekStopList_childIIN: 'ИИН ребенка',
    kezekBulletin_columnBin: 'БИН',
    kezekBulletin_columnDate: 'Дата создания',
    kezekBulletin_columnServiceProvider: 'Наименование организаций',
    kezekBulletin_columnServiceProviderLocation: 'Населенный пункт',
    kezekBulletin_columnQueuedType: 'Тип очереди',
    kezekBulletin_columnTypeBulletin: 'Вид бюллетени',
    kezekBulletin_columnStatusWeb: 'Статус',
    kezekBulletin_columnGroupName: 'Группа',
    kezekBulletin_columnAgeGroup: 'Возрастная группа',
    kezekBulletin_columnNumberSeats: 'Количество опубликованных мест',
    kezekBulletin_columnFreeSeats: 'Количество свободных мест',
    kezekBulletin_columnAcademicYear: 'Учебный год',

    kezekBulletinDucks_errorLoadTable: 'Не удалось загрузить бюллетени',
    kezekBulletinDucks_errorChangeFilter: 'Не удалось поменять фильтр',
  },
  kk: {
    kezekBulletin_title: 'Бос орындар бюллетені',
    kezekBulletin_columnID: 'Нөмірі',
    kezekBulletin_columnBin: 'БСН',
    kezekBulletin_columnDate: 'Құрылған күні',
    kezekBulletin_columnServiceProvider: 'Ұйымның атауы',
    kezekBulletin_columnServiceProviderLocation: 'Аймақ',
    kezekBulletin_columnQueuedType: 'Кезек түрі',
    kezekBulletin_columnTypeBulletin: 'Бюллетень түрі',
    kezekBulletin_columnStatusWeb: 'Мәртебесі',
    kezekBulletin_columnGroupName: 'Тобы',
    kezekBulletin_columnAgeGroup: 'Жас тобы',
    kezekBulletin_columnNumberSeats: 'Жарияланған орындар саны',
    kezekBulletin_columnFreeSeats: 'Бос орындар саны',
    kezekBulletin_columnAcademicYear: 'Оқу жылы',

    kezekBulletinDucks_errorLoadTable: 'Бюллетеньдерді жүктеу мүмкін емес',
    kezekBulletinDucks_errorChangeFilter: 'Сүзгіні өзгерту мүмкін болмады',
  }
});
