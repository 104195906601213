import { addTranslation } from '../../_helpers/Translate';

addTranslation({
  ru: {
    statement_title: 'Заявка',
    statement_number: 'Номер заявки',
    statement_status: 'Статус заявки',
    statement_processing_result: 'Результат обработки',
    statement_date: 'Дата заявки',
    statement_iin: 'ИИН заявителя',
    statement_PEP_number: 'Номер заявки ПЭП',
    statement_providerId: 'Номер услугодателя',
    statement_providerBin: 'БИН услугодателя',
    statement_providerName: 'Услугодатель',
    statement_resolutionDate: 'Дата обработки заявки',
    statement_errorLoad: 'Не удалось загрузить данные заявки',
    statement_saveForm: 'Сохранить',
    statement_accept: 'Принять на обработку',
    statement_commitPositive: 'Обработать положительно',
    statement_commitNegative: 'Обработать отрицательно',
    statement_commitIntermediate: 'Отправить уведомление',
    statement_reject: 'Отклонить',
    statement_beforeSaveData:
      'Вам необходимо заполнить все обязательные поля',
    statement_errorSaveForm: 'Не удалось сохранить данные',
    statement_saveFormSuccess: 'Данные успешно сохранены',
    statement_rejectTitle: 'Отклонение заявки',
    statement_rejectRequired: 'Обязательное для заполнения',
    statement_rejectReason: 'Отказ',
    statement_rejectReasonTextKk: 'Причина отказа на казахском языке',
    statement_rejectReasonTextRu: 'Причина отказа на русском языке',
    statement_rejectSuccess: 'Заявка успешно отклонена',
    statement_rejectError: 'Не удалось отклонить заявку',
    statement_positiveTitle: 'Обработка заявки положительно',
    statement_intermediateTitle: 'Отправка уведомления',
    statement_positivePreview: 'Не удалось загрузить файл предпросмотра',
    statement_positiveAction: 'Отправить',
    statement_positiveSendAction: 'Отправить',
    statement_positiveSuccess: 'Заявка успешно отправлено',
    statement_intermediateSuccess: 'Уведомление успешно отправлено',
    statement_positiveError: 'Не удалось отправить заявку',
    statement_intermediateError: 'Не удалось отправить уведомление',
    statement_negativeTitle: 'Обработка заявки отрицательно',
    statement_negativeReason: 'Отказ',
    statement_negativeReasonText: 'Причина отказа',
    statement_declineSignatureReason: 'Причина возврата на доработку',
    statement_negativeMissingDocumentsRuName:
      'Перечень отсутствующих документов на русском языке',
    statement_negativeMissingDocumentsKkName:
      'Перечень отсутствующих документов на казахском языке',
    statement_negativeReasonTextRu: 'Уточнение причины отказа на русском языке',
    statement_negativeReasonTextKk: 'Уточнение причины отказа на казахском языке',
    statement_negativeRequired: 'Обязательное для заполнения',
    statement_negativePreview: 'Не удалось загрузить файл предпросмотра',
    statement_negativeAction: 'Отправить',
    statement_negativeSaveAction: 'Сохранить',
    statement_negativeSuccess: 'Заявка успешно отправлено',
    statement_negativeError: 'Не удалось отправить заявку',
    statement_declineTitle: 'Отправить на доработку',
    statement_declineComment: 'Комментарий',
    statement_declineRequired: 'Обязательное для заполнения',
    statement_declineSendAction: 'Отправить',
    statement_declineSuccess: 'Заявка успешно отправлена на доработку',
    statement_declineError: 'Не удалось отправить заявку',
    statement_completeSign: 'Подписать',
    statement_completeSuccess: 'Заявка успешно подписана',
    statement_completeError: 'Не удалось подписать заявку',
    statement_completeErrorSign: 'ИИН ЭЦП для подписи не соответствует ИИН логину, под которым вы работаете. Проверьте срок действия ЭЦП.',
    statement_acceptTitle: 'Принятие заявки',
    statement_acceptQuestion: 'Вы хотите принять заявку с номером {{id}}?',
    statement_acceptError: 'Не удалось принять',
    statement_acceptSuccess: 'Заявка успешно принята',
    statement_choose_sp: 'Выберите услугодателя',
    statement_changeServiceProvider: 'Сменить услугодателя',
    statement_successChangeProvider: 'Услугодатель успешно сохранен',
    statement_errorChangeProvider: 'Не удалось сохранить услугодателя',
    statement_ragStatus: 'Срок исполнения',
    statement_downloadStatement: 'Скачать завление',
    statement_downloadStatementError: 'Не удалось загрузить заявление',
    statement_acceptDocEduDisable: 'Обработка заявки невозможна, так как превышено регламентное время обработки',
  },
  kk: {
    statement_title: 'Өтінім',
    statement_number: 'Өтінім нөмірі',
    statement_status: 'Өтінімнің мәртебесі',
    statement_processing_result: 'Өңдеу нәтижесі',
    statement_date: 'Өтінім күні',
    statement_iin: 'Өтініш берушінің ЖСН-і',
    statement_PEP_number: 'ЭҮП өтінім нөмірі',
    statement_providerId: 'Кызметті көрсетушінің нөмірі',
    statement_providerBin: 'Қызметті көрсетушінің БСН-і',
    statement_providerName: 'Қызмет көрсетуші',
    statement_resolutionDate: 'Өтінімді қарау күні',
    statement_errorLoad: 'Өтінім деректерін жүктеу мүмкін болмады',
    statement_saveForm: 'Сақтау',
    statement_accept: 'Өңдеуге қабылдау',
    statement_commitPositive: 'Оң шешіммен өңдеу',
    statement_commitNegative: 'Теріс шешіммен өңдеу',
    statement_commitIntermediate: 'Хабарлама жіберу',
    statement_reject: 'Қабылдамау',
    statement_beforeSaveData:
      'Сізге барлық қажетті жолдарды толтыру қажет',
    statement_errorSaveForm: 'Деректерді сақтау сәтсіз аяқталды',
    statement_saveFormSuccess: 'Деректер сәтті сақталды',
    statement_rejectTitle: 'Өтінімді қабылдамау',
    statement_rejectRequired: 'Толтыру үшін міндетті',
    statement_rejectReason: 'Бас тарту',
    statement_rejectReasonTextKk: 'Бас тарту себебі қазақ тілінде',
    statement_rejectReasonTextRu: 'Бас тарту себебі орыс тілінде',
    statement_rejectSuccess: 'Өтінім сәтті қабылданбады',
    statement_rejectError: 'Өтінімді қабылдамау мүмкін болмады',
    statement_positiveTitle: 'Өтінімді оң шешіммен өңдеу',
    statement_intermediateTitle: 'Хабарламаны жіберу',
    statement_positivePreview: 'Алдын-ала қарау файлын жүктеу сәтсіз аяқталды',
    statement_positiveAction: 'Жіберу',
    statement_positiveSendAction: 'Жіберу',
    statement_positiveSuccess: 'Өтінім сәтті жіберілді',
    statement_intermediateSuccess: 'Хабарлама сәтті жіберілді',
    statement_positiveError: 'Өтінімді жіберу мүмкін емес',
    statement_intermediateError: 'Хабарламаны жіберу мүмкін емес',
    statement_negativeTitle: 'Өтінімді теріс шешіммен өңдеу',
    statement_negativeReason: 'Бас тарту',
    statement_negativeReasonText: 'Бас тарту себебі',
    statement_declineSignatureReason: 'Қайта қарау үшін қайтару себебі',
    statement_negativeMissingDocumentsRuName: 'Орыс тілінде жоқ құжаттардың тізбесі',
    statement_negativeMissingDocumentsKkName: 'Қазақ тілінде жоқ құжаттардың тізбесі',
    statement_negativeReasonTextRu: 'Бас тарту себебін орыс тілінде нақтылау',
    statement_negativeReasonTextKk: 'Бас тарту себебін қазақ тілінде нақтылау',
    statement_negativeRequired: 'Толтыру үшін міндетті',
    statement_negativePreview: 'Алдын-ала қарау файлын жүктеу сәтсіз аяқталды',
    statement_negativeAction: 'Жіберу',
    statement_negativeSaveAction: 'Сақтау',
    statement_negativeSuccess: 'Өтінім сәтті жіберілді',
    statement_negativeError: 'Өтінімді жіберу мүмкін емес',
    statement_declineTitle: 'Қайта жөндеуге жіберу',
    statement_declineComment: 'Түсініктеме',
    statement_declineRequired: 'Толтыру үшін міндетті',
    statement_declineSendAction: 'Жіберу',
    statement_declineSuccess: 'Өтінім қайта жөндеуге сәтті жіберілді',
    statement_declineError: 'Өтінімді жіберу мүмкін емес',
    statement_completeSign: 'Қол қою',
    statement_completeSuccess: 'Өтінімге сәтті қол қойылды',
    statement_completeError: 'Өтінімге қол қою мүмкін емес',
    statement_completeErrorSign: 'Қол қою үшін ЭЦҚ ЖСН-і сіз жұмыс істейтін логиндегі ЖСН-мен сәйкес келмейді. ЭЦҚ жарамдылық мерзімін тексеріңіз',
    statement_acceptTitle: 'Өтінімді қабылдау',
    statement_acceptQuestion: 'Сіз {{id}}нөмірлі  өтінімді қабылдағыңыз келеді ме?',
    statement_acceptError: 'Қабылдау мүмкін болмады',
    statement_acceptSuccess: 'Өтінім сәтті қабылданды',
    statement_choose_sp: 'Қызмет көрсетушіні таңдаңыз',
    statement_changeServiceProvider: 'Қызмет көрсетушіні ауыстыру',
    statement_successChangeProvider: 'Қызмет көрсетуші сәтті сақталды',
    statement_errorChangeProvider: 'Қызмет көрсетушіні сақтау сәтсіз аяқталды',
    statement_ragStatus: 'Орындау уақыты',
    statement_downloadStatement: 'Өтінішті жүктеу',
    statement_downloadStatementError: 'Мәлімдеме жүктелмеді',
    statement_acceptDocEduDisable: 'Өңдеудің регламенттік уақыты асып кеткендіктен, өтінімді өңдеу мүмкін емес',
  }
});
