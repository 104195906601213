import config from './../_helpers/config';
import { COMPANY_NAME_ZHETISU } from './../_helpers/Constants';

export function checkOrg() {
  let isZhetysu = false;
  if (
    config.companyName.ru === COMPANY_NAME_ZHETISU.ru ||
    config.companyName.kk === COMPANY_NAME_ZHETISU.kk
  ) {
    isZhetysu = true;
  }
  return isZhetysu;
}
