import { addTranslation } from '../../_helpers/Translate';

addTranslation({
  ru: {
    changePassword_title: 'Вам необходимо изменить пароль чтобы войти в портал'
  },
  kk: {
    changePassword_title: 'Порталға кіру үшін парольді өзгерту керек'
  },
})
