import React, { useEffect } from 'react';
import StaticTablePagination from '../../_ui/Table/StaticTablePaginated';
import { serviceProvidersColumns } from './ServiceProvidersColumns';
import { useTranslation } from 'react-i18next';
import useTableExpander from './useTableExpander';
import { Wrapper } from './ServiceProvidersStyles';
import { connect } from 'react-redux';
import { serviceProvidersModule } from './ServiceProvidersDucks';

function TableSubComponent({
  data,
  config,
  loadChild,
  level = 1,
  loadingChild,
  ...props
}) {
  const { t, i18n } = useTranslation();
  const { expanded, editExpanded } = useTableExpander();

  useEffect(() => {
    !data.subServiceProviders && loadChild(data.id);
  }, [data, loadChild]);
  level++;
  return (
    <Wrapper>
      {data['subServiceProviders'] && (
        <StaticTablePagination
          TheadComponent={() => null}
          sortable={false}
          filterable={false}
          columns={serviceProvidersColumns(
            t,
            i18n.language,
            config,
            editExpanded,
            loadingChild,
            level
          )}
          data={data.subServiceProviders || []}
          expanded={expanded}
          {...props}
          SubComponent={row => (
            <TableSubComponent
              data={row.original}
              config={config}
              loadChild={loadChild}
              level={level}
              {...props}
            />
          )}
        />
      )}
    </Wrapper>
  );
}

export default connect(
  state => ({
    loadingChild: state[serviceProvidersModule].loadingChild
  }),
  null
)(TableSubComponent);
