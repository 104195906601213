import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../_ui/Button/Button';
import ExportIcon from '@material-ui/icons/SaveAlt';
import handleData from './handleData';
import BuildExcel from './BuildExcel';
import { MonitoringApi } from '../../../../_helpers/service';
import { useTheme, useMediaQuery } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';


const useStyles = makeStyles ({
  paper: {
    position: 'absolute', 
    top: 9, 
    right: 32 
  },
  paperSm: {
    position: 'relative',
    marginTop: '10px',
    marginBottom: '20px',
  }
})

function downloadFile(filter) {
  const download = response => {
    const data = response.data;
    console.log(response)
    const element = document.createElement('a');
    document.body.appendChild(element);
    element.style.display = 'none';

    const blob = new Blob([data], { type: 'octet/stream' });
    const url = window.URL.createObjectURL(blob);
    element.href = url;
    element.download = 'Report.xlsx';
    element.click();
    window.URL.revokeObjectURL(url);
  };

  if (filter.reportType === '1') {
    MonitoringApi.kindergartenDirected(filter).then(download);
  } else if (filter.reportType === '2') {
    MonitoringApi.kindergartenRequestsQueue().then(download);
  } else if (filter.reportType === '3') {
    MonitoringApi.kindergartenAccepted(filter).then(download);
  } else if (filter.reportType === '4') {
    MonitoringApi.kindergartenDirectedRegion(filter).then(download);
  } else if (filter.reportType === '5') {
    MonitoringApi.kindergartenRequestsQueueRegion().then(download);
  } else if (filter.reportType === '6') {
    MonitoringApi.kindergartenAcceptedRegion(filter).then(download);
  } else if (filter.reportType === '7') {
    MonitoringApi.kindergartenReportToMA().then(download);
  }
}

export default function ExportFile({ data, filter }) {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();
  const columnsWidth = [35, 18, 18, 18, 18, 18, 18, 18];
  const excelData = handleData(t, i18n.language, data, filter);
  const fileName = t('monitoring_excel_sheet_title');
  const exportData = () => BuildExcel(excelData, columnsWidth, fileName);
  const matchesSm = useMediaQuery(theme.breakpoints.down("md"));
    
  return (
    <div className={matchesSm ? classes.paperSm : classes.paper}>
      {filter.reportType && (
        <Button
          disabled={['1', '3', '4', '6'].includes(filter.reportType) && !(filter.fromDate && filter.fromDate) }
          text={t('monitoring_report_btn')}
          icon={<ExportIcon fontSize="small" className="mr1" />}
          onClick={() => downloadFile(filter)}
        />
      )}
      <Button
        style={{ marginLeft: 12 }}
        text={t('monitoring_export_btn')}
        icon={<ExportIcon fontSize="small" className="mr1" />}
        onClick={exportData}
      />
    </div>
  );
}
