import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';
import { StyledDrawer, Wrapper } from './SideBarStyle';
import SideBarPanel from './SideBarPanel';
import { sideBarItems } from './SideBarItems';
import Access from '../../Access';
import './SideBarTranslate';

export const SideBar = ({showSideBar, setShowSideBar}) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  return (
    <StyledDrawer
      variant="permanent"
      open={showSideBar}
      onClose={() => setShowSideBar(false)}
    >
      <Wrapper showSideBar={showSideBar}>
        <List>
          {sideBarItems.map((item, key) => (
            <Access key={key} ps={item.permissions}>
              {item.items && (
                <SideBarPanel
                  {...item}
                  showSideBar={showSideBar}
                  onClick={setShowSideBar}
                />
              )}
              {!item.items && !item.isDivider && (
                <Link to={item.path} onClick={() => setShowSideBar(false)}>
                  <ListItem
                    button
                    style={{ paddingLeft: 20 }}
                    selected={pathname === item.path}
                  >
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <Typography variant="body2" color="textPrimary">
                      {t(item.translate)}
                    </Typography>
                  </ListItem>
                </Link>
              )}
              {item.isDivider && <Divider />}
            </Access>
          ))}
        </List>
      </Wrapper>
    </StyledDrawer>
  );
};

export default SideBar;
