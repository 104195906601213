import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs';
import { Button, Paper, Tab, Tabs, TextField } from '@material-ui/core';
import { Link } from 'react-router-dom';
import paths from '../../_helpers/paths';
import DateTimePicker from '../../components/TableFilter/ColumnsFilters/FilterDateTimePicker';
import Select from '../../components/TableFilter/ColumnsFilters/FilterSelect';
import './QueueTranslate';
import useTableFilter from '../../components/TableFilter/useTableFilter';
import { queueFilterValues, queueByNameValues, queueNames } from './QueueConstans';
import StaticTable from '../../_ui/Table/StaticTable';
import { columnsTab1, columnsTab2 } from './QueueColumns';
import { queueModule, cancelQueue, loadTab1Data, loadTab2Data } from './QueueDucks';
import useDict from '../../components/_hooks/useDict';

function QueuePage({ location: { pathname, search } }) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const tab1 = paths.queueTab1Page;
  const tab2 = paths.queueTab2Page;
  const loading = useSelector(state => state[queueModule].loading);
  const tab1Data = useSelector(state => state[queueModule].tab1Data);
  const tab2Data = useSelector(state => state[queueModule].tab2Data);
  const columnTranslate = useDict('gu_message_queue', true);
  const [requestId, setRequestId] = useState();
  const breadCrumbItems = [
    { name: t('breadcrumbs_home'), href: '/' },
    { name: t('breadcrumbs_monitoringQueue') }
  ];

  const { filter, reloadPage } = useTableFilter(filter => {
    dispatch(loadTab1Data(filter));
  }, queueFilterValues);

  return (
    <>
      <BreadCrumbs items={breadCrumbItems} />
      <Paper>
        <Tabs value={pathname}>
          <Tab
            label={t('queuePage_tab1')}
            value={tab1}
            component={Link}
            to={tab1 + search}
          />
          <Tab
            label={t('queuePage_tab2')}
            value={tab2}
            component={Link}
            to={tab2 + search}
          />
        </Tabs>
        <div className="px3 pb3 pt1 relative">
          {pathname === tab1 && (
            <>
              <div className="flex mb1">
                <Select
                  name="queue_name"
                  options={queueNames}
                  allowClear={false}
                  fullWidth={false}
                  placeholder={t('queuePage_name')}
                  style={{ marginRight: 10, width: 230 }}
                />
                <DateTimePicker
                  allowClear={false}
                  name="datetime_begin"
                  placeholder={t('queuePage_fromDate')}
                  style={{ marginRight: 10, width: 230 }}
                />
                <DateTimePicker
                  allowClear={false}
                  name="datetime_end"
                  placeholder={t('queuePage_toDate')}
                  style={{ marginRight: 10, width: 230 }}
                />
              </div>
              <StaticTable
                filterable={false}
                loading={loading}
                columns={columnsTab1({ t, lang: i18n.language, columnTranslate })}
                data={tab1Data}
                urlOnClick={row => {
                  const qp = {
                    ...queueByNameValues,
                    app_name: row.original.external_app_name,
                    queue_name: filter.queue_name
                  };
                  const params = new URLSearchParams();
                  params.set('filter', JSON.stringify(qp));
                  return `/queue/queueByName?${params.toString()}`;
                }}
              />
            </>
          )}
          {pathname === tab2 && (
            <>
              <div className="mb1">
                <TextField
                  value={requestId}
                  onChange={e => setRequestId(e.target.value)}
                  placeholder={t('queuePage_requestId')}
                  style={{ marginRight: 10, width: 230 }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => dispatch(loadTab2Data(requestId))}
                  children={t('queuePage_search')}
                />
              </div>

              <StaticTable
                filterable={false}
                loading={loading}
                columns={columnsTab2({
                  t,
                  lang: i18n.language,
                  columnTranslate,
                  cancelQueue: id => dispatch(cancelQueue(id, reloadPage))
                })}
                data={tab2Data}
              />
            </>
          )}
        </div>
      </Paper>
    </>
  );
}

export default QueuePage;
