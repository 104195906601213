import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {StudentsColumns} from './StudentsColumns'
import {contingentSectionModule, loadStudents} from '../ContingentSectionDucks';
import StaticTablePagination from '../../../_ui/Table/StaticTablePaginated';
import useTableFilter from '../../../components/TableFilter/useTableFilter';

export default function StudentsPage() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const data = useSelector(state => state[contingentSectionModule].student);
  const loadingTable = useSelector(state => state[contingentSectionModule].loadingTable);

  const { nextPage } = useTableFilter(filter =>
    dispatch(loadStudents({filter}))
  );
  return (
    <div className='mt1'>
      <StaticTablePagination
        sortable={false}
        loading={loadingTable}
        columns={StudentsColumns({
          t,
          lang: i18n.language,
         })
        }
        totalElements={data.totalElements}
        data={data.content}
        urlOnClick={row => `/contingent/section/${row.original.id}`}
        onClickLoadMore={() => nextPage()}
      />
    </div>
  );
}

