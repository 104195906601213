import React, { useEffect, useState } from 'react';
import Modal from '../../../_ui/Modal/Modal';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { compose } from 'redux';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import withTranslation from '../../../components/_hoc/withTranslation';
import { connect } from 'react-redux';
import { loadResponsible, responsibleModule, clearState } from './ResponsibleDucks';
import Button from '../../../_ui/Button/Button';
import { assignResponsible } from '../JournalDucks';
import PageLoader from '../../../components/PageLoader/PageLoader';
import Typography from '@material-ui/core/Typography';
import './ResponsibleTranslate';

function ResponsibleModal({
  t,
  data,
  responsibleList,
  loadingResponsible,
  loadResponsible,
  assignResponsible,
  close: modalOnClose,
  clearCheckedList,
  clearState
}) {
  useEffect(() => {
    loadResponsible(data[0].serviceProviderId);
  }, [loadResponsible, data]);
  useEffect(() => clearState, [clearState]);
  const [selected, setSelected] = useState(null);

  if (loadingResponsible) {
    return <PageLoader opacity={60} />;
  }

  return (
    <div>
      <Modal title={t('responsible_assign_responsible')} open onClose={modalOnClose}>
        <DialogContent>
          {responsibleList.length > 0 ? (
            <List component="nav">
              {responsibleList.map((item, index) => (
                <ListItem
                  key={`${item.id}${index}`}
                  button
                  onClick={() => setSelected(item.username)}
                  selected={selected === item.username}
                >
                  <ListItemText primary={item.fullName} />
                </ListItem>
              ))}
            </List>
          ) : (
            <Typography>{t('responsible_assign_responsible_list_empty')}</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            text={t('responsible_assign_responsible_Btn')}
            disabled={!selected}
            onClick={() =>
              assignResponsible(
                { assignee: selected, ids: data.map(it => it.id) },
                () => {
                  modalOnClose();
                  clearCheckedList();
                }
              )
            }
          />
        </DialogActions>
      </Modal>
    </div>
  );
}

export default compose(
  withTranslation,
  connect(
    state => ({
      loadingResponsible: state[responsibleModule].loadingResponsible,
      responsibleList: state[responsibleModule].responsibleList
    }),
    { loadResponsible, clearState, assignResponsible }
  )
)(ResponsibleModal);
