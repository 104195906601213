import { createReducer } from 'redux-starter-kit';
import { handleError } from '../../utils/handleError';
import { BulletinApi } from '../../_helpers/service';
import Dict from '../../utils/Dict';
import i18next from 'i18next';
import { capitalize } from 'lodash';

/**
 * Constants
 */

export const kezekBulletinTableModule = 'kezekBulletinTable';
const LOADING_TABLE = `${kezekBulletinTableModule}/LOADING_TABLE`;
const TABLE_DATA = `${kezekBulletinTableModule}/TABLE_DATA`;
const CLEAR_STATE = `${kezekBulletinTableModule}/CLEAR_STATE`;

/**
 * Reducer
 */

const initialState = {
  loadingTable: false,
  tableData: {
    content: [],
    totalElements: 0
  }
};

export default createReducer(initialState, {
  [LOADING_TABLE]: (state, action) => {
    state.loadingTable = action.payload;
  },
  [TABLE_DATA]: (state, action) => {
    const filter = action.filter || {};
    if (filter.pageNumber && filter.pageNumber !== 1) {
      state.tableData.content = [...state.tableData.content, ...action.payload.content];
    } else {
      state.tableData = action.payload;
    }
  },
  [CLEAR_STATE]: () => initialState
});

/**
 * Actions
 */

export const clearState = () => ({ type: CLEAR_STATE });

export const loadData = filter => async dispatch => {
  try {
    dispatch({ type: LOADING_TABLE, payload: true });
    let { data } = await BulletinApi.loadData(handleFilter(filter));
    if (data.status === 'SUCCESS') {
      const dictKato = await Dict.itemsObject('60');
      const dictAgeGroup = await Dict.itemsObject('gu_age_group');
      const dictQueuedType = await Dict.itemsObject('gu_queued_type');
      const dictTypeBulletin = await Dict.itemsObject('gu_type_bulletin');
      const dictStatusWeb = await Dict.itemsObject('gu_bulletin_status_web');
      const dictAcademicYear = await Dict.itemsObject('gu_academic_year_do');
      for (let row of data.result.content) {
        row.serviceProviderLocation = dictKato[row.serviceProviderLocation] || {};
        row.ageGroupName = dictAgeGroup[row.ageGroup] || {};
        row.queuedTypeName = dictQueuedType[row.queuedType] || {};
        row.bulletinTypeName = dictTypeBulletin[row.typeBulletin] || {};
        row.statusWebName = dictStatusWeb[row.statusWeb] || {};
        row.academicYearName = dictAcademicYear[row.beginAcademicYear] || {};
      }
      dispatch({ type: TABLE_DATA, payload: data.result, filter });
    } else {
      handleError(data, i18next.t('kezekBulletinDucks_errorLoadTable'));
    }
  } catch (error) {
    handleError(error, i18next.t('kezekBulletinDucks_errorLoadTable'));
  } finally {
    dispatch({ type: LOADING_TABLE, payload: false });
  }
};

function handleFilter(filter) {
  const filterData = { ...filter };

  if (filterData.serviceProviderName) {
    filterData[`serviceProvider${capitalize(i18next.language)}Name`] =
      filterData.serviceProviderName;
    delete filterData.serviceProviderName;
  }

  if (filterData.nameGroup) {
    filterData[`nameGroup${capitalize(i18next.language)}`] =
      filterData.nameGroup;
    delete filterData.nameGroup;
  }

  return JSON.stringify(filterData);
}
