import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import withTranslation from '../../components/_hoc/withTranslation';
import { connect, useSelector } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs';
import withModal from '../../components/_hoc/withModal';
import useTableFilter from '../../components/TableFilter/useTableFilter';
import { statusColor } from '../../utils/statusColors';
import i18next from 'i18next';
import './MyTaskTranslate';
import { loadTableData, clearState, myTaskModule } from './MyTaskDucks';
import { myTaskColumns } from './MyTaskColumns';
import Table from '../../_ui/Table/StaticTablePaginated';
import Button from '../../_ui/Button/Button';
import Modal from '../../_ui/Modal/Modal';
import AssignExecutor from './TaskPage/AssignExecutor/AssignExecutor';
import useSimpleModal from '../../components/_hooks/useSimpleModal';
import HandleIcon from '@material-ui/icons/DonutLarge';
import { loginModule } from '../LoginPage/LoginDucks';
import useDict from '../../components/_hooks/useDict';
import { TASK_TYPE } from '../../_helpers/Constants';
import SelectUser from './TaskPage/SelectUser/SelectUser';
import FilterDatePicker from '../../components/TableFilter/ColumnsFilters/FilterDatePicker';
import FilterYesNoField from '../../components/TableFilter/ColumnsFilters/FilterYesNoField';
import Access from '../../components/Access';
import permissions from '../../_helpers/permissions';
import Pagination from '@material-ui/lab/Pagination';

function MyTaskPage({ t, lang, loadingTable, tableData, loadTableData, clearState }) {
  const [checkedList, setCheckedList] = useState([]);
  const { filter, toPage } = useTableFilter(loadTableData);
  const modal = useSimpleModal();
  const { services } = useSelector(state => state[loginModule].user.organization);

  const breadCrumbItems = [
    { name: i18next.t('breadcrumbs_home'), href: '/' },
    { name: i18next.t('breadcrumbs_myTask') }
  ];

  const serviceType = useDict('gu_services').filter(item =>
    services ? services.includes(item.code) : true
  );

  useEffect(() => clearState, [clearState]);

  return (
    <>
      <BreadCrumbs items={breadCrumbItems} />
      <Paper className="pb3 pt1 px3" style={{ minWidth: 1800 }}>
        <h3>{t('myTaskPage_title')}</h3>
        <div className="mb1 flex justify-between">
          <div className="flex items-center">
            <Button
              icon={<HandleIcon fontSize="small" className="mr1" />}
              text={t('myTaskPage_handle_btn')}
              disabled={!checkedList.length}
              onClick={e => {
                modal.open(checkedList);
                e.stopPropagation();
              }}
            />
            <Access ps={permissions.ES_HEAD}>
              <div className="flex items-center ml2">
                <FilterYesNoField name="assignedToMeOnly" />
                <span style={{ whiteSpace: 'nowrap' }}>
                  {t('myTaskPage_columnOnlyMyTask')}
                </span>
              </div>
            </Access>
          </div>
          <div className="flex items-center">
            <span style={{ whiteSpace: 'nowrap' }}>{t('myTaskPage_columnDate')}</span>
            <FilterDatePicker
              name="submissionDateMin"
              style={{ width: 200, margin: '0 10px' }}
              placeholder={t('myTaskPage_columnDateFrom')}
            />
            <FilterDatePicker
              endDate
              name="submissionDateMax"
              style={{ width: 200 }}
              placeholder={t('myTaskPage_columnDateTo')}
            />
          </div>
        </div>
        <Table
          columns={myTaskColumns({
            t,
            serviceType,
            lang,
            checkedList,
            setCheckedList,
            content: tableData.content
          })}
          data={tableData.content}
          sortable={false}
          urlOnClick={({ original }) =>
            original.isUserTask ? `/task/${original.id}?taskType=${original.type}` : false
          }
          totalElements={tableData.totalElements}
          loading={loadingTable}
          rowBgColor={row => statusColor[row.original.resolutionType]}
        />
        <Pagination
          onChange={(e, page) => toPage(page)}
          page={filter.pageNumber}
          count={Math.ceil(tableData.totalElements / filter.pageSize)}
          style={{ marginTop: 10 }}
          variant="outlined"
          shape="rounded"
        />
      </Paper>
      {modal.isOpen && (
        <Modal
          open
          maxWidth="md"
          onClose={modal.close}
          children={
            <div className="pb3 pt1 px3">
              {tableData.content.find(item => item.id === checkedList[0]).type ===
              TASK_TYPE.SELECT_USER ? (
                <SelectUser
                  taskIds={modal.data}
                  taskData={tableData.content.filter(item =>
                    checkedList.includes(item.id)
                  )}
                />
              ) : (
                <AssignExecutor taskIds={modal.data} />
              )}
            </div>
          }
        />
      )}
    </>
  );
}

export default compose(
  withTranslation,
  connect(
    state => ({
      loadingTable: state[myTaskModule].loadingTable,
      tableData: state[myTaskModule].tableData
    }),
    { loadTableData, clearState }
  )
)(withModal(MyTaskPage));
