import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import StaticTablePagination from '../../../_ui/Table/StaticTablePaginated';
import { EmployessColumns } from './EmployessColumns'
import { loadEmployees, colleaguesModule } from '../ColleaguesDucks';
import useTableFilter from '../../../components/TableFilter/useTableFilter';
import { loginModule } from '../../LoginPage/LoginDucks';
import FilterInputField from '../../../components/TableFilter/ColumnsFilters/FilterInputField';
import {ROLES_ADMIN} from '../../../_helpers/Constants'

export default function EmployessPage() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [staffType, setStaffType] = useState('gu_staff_type')
  const data = useSelector(state => state[colleaguesModule].employees);
  const loadingTable = useSelector(state => state[colleaguesModule].loadingTable);
  const [isAdmin, setIsAdmin] = useState(false);

  const { user } = useSelector(
    state => state[loginModule]
  );
  useEffect(() => {
    user.roles.forEach((item) => {
      if (item === ROLES_ADMIN.ES_ADMINS || item === ROLES_ADMIN.ES_GODS) {
        setIsAdmin(true)
      }
    })
  }, [user.roles])
  const { nextPage } = useTableFilter(filter =>
    dispatch(loadEmployees({filter, type: 'WORKING'}))
  );

  useEffect(() => {
    if (user.organization.orgType === '01') {
      setStaffType('ddo_staff_type');
    } else if (user.organization.orgType === '02') {
      setStaffType('school_staff_type');
    } else if (user.organization.orgType === '10') {
      setStaffType('section_staff_type');
    } else {
      setStaffType('gu_staff_type');
    }
  }, [])

  return (
    <div className='mt1'>
      <StaticTablePagination
       
        columns={
          !isAdmin ? [...EmployessColumns({
          t,
          lang: i18n.language,
          staffType
         })] : [
          ...EmployessColumns({
            t,
            lang: i18n.language,
            staffType
           }), 
           {
            Header: t('contingentPage_columnBinOrg'),
            accessor: 'bin',
            Filter: <FilterInputField name="bin" type="number" />
          },
         ]
        }
        data={data.content}
        sortable={false}
        urlOnClick={row => `/colleagues/save/${row.original.id}`}
        loading={loadingTable}
        totalElements={data.totalElements}
        onClickLoadMore={() => nextPage()}
      />
    </div>
  );
}

