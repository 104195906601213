import { addTranslation } from '../../_helpers/Translate';

addTranslation({
  ru: {
    classPage_title: 'Классы',
    classPage_number: 'Номер класса',
    classPage_class: 'Класс',
    classPage_eduForm: 'Форма обучения',
    classPage_language: 'Язык обучения',
    classPage_liter: 'Литера',
    classPage_add: 'Добавить',
    classPage_stateOrderYes: 'Да',
    classPage_stateOrderNo: 'Нет',
    classPage_errorLoadTable: 'Не удалось загрузить класса',
    classPage_action: 'Действие',
    classPage_errorRemove: 'Не удалось удалить элемент',
    classPage_deleteSuccess: 'Элемент успешно удаленно',
    classPage_errorRemoveInfo: 'Удаление класса невозможно т.к. в этом классе числятся дети, необходимо перенести всех детей в другие классы',
    classPage_tableActionDelete: 'Удалить',
    classPage_educators: 'Преподаватель',
  },
  kk: {
    classPage_title: 'Сыныптар',
    classPage_number: 'Сынып нөмірі',
    classPage_class: 'Сынып',
    classPage_eduForm: 'Оқыту түрі   ',
    classPage_language: 'Оқыту тілі',
    classPage_liter: 'Литера',
    classPage_add: 'Қосу',
    classPage_stateOrderYes: 'Ия',
    classPage_stateOrderNo: 'Жоқ',
    classPage_errorLoadTable: 'Сынып жүктеу мүмкін болмады',
    classPage_action: 'Әрекет',
    classPage_errorRemove: 'Элемент жою мүмкін болмады',
    classPage_deleteSuccess: 'Элемент сәтті жойылды',
    classPage_errorRemoveInfo: 'Сыныпты жою мүмкін емес, өйткені бұл сыныпта балалар бар, барлық балаларды басқа сыныптарға ауыстыру керек',
    classPage_tableActionDelete: 'Жою',
    classPage_educators: 'Оқытушы',
  }
});