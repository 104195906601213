import { createReducer } from 'redux-starter-kit';
import { handleError } from '../../utils/handleError';
import { ClassApi, GroupApi } from '../../_helpers/service';
import Notice from '../../utils/Notice';
import i18next from 'i18next';
import Dict from '../../utils/Dict';
import lodash from 'lodash'


/**
 * Constants
 */

export const classListModule = 'classList';
const LOADING = `${classListModule}/LOADING`;
const SET_DATA = `${classListModule}/SET_DATA`;
const CLEAR_STATE = `${classListModule}/CLEAR_STATE`;
const SET_CHILD = `${classListModule}/SET_CHILD`;
const FREE_KIDS = `${classListModule}/FREE_KIDS`;

/**
 * Reducer
 */

const initialState = {
  loading: false,
  data: {},
  child: {
    content: [],
    totalElements: 0
  },
  freeKids: [],
};

export default createReducer(initialState, {
  [SET_DATA]: (state, action) => {
    state.data = action.payload;
  },
  [SET_CHILD]: (state, action) => {
    const filter = action.filter || {};
    if (filter.pageNumber && filter.pageNumber !== 1) {
      state.child.content = [...state.child.content, ...action.payload.content];
    } else {
      state.child = action.payload;
    }
  },
  [FREE_KIDS]: (state, action) => {
    state.freeKids = action.payload;
  },   
  [LOADING]: (state, action) => {
    state.loading = action.payload;
  },
  [CLEAR_STATE]: () => initialState
});

/**
 * Actions
 */

export const loadData = ({id}) => async dispatch => {
  try {
    dispatch({ type: LOADING, payload: true });
    let { data } = await ClassApi.getItemClass({id});
    dispatch({type: SET_DATA, payload: data})
  } catch (error) {
    handleError(error, i18next.t('classListPage_errorLoadTable'));
  } finally {
    dispatch({ type: LOADING, payload: false });
  }
};

export const getClassChild = ({filter, id}) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const {data} = await ClassApi.getClassChild({filter: JSON.stringify(filter), id});
    const dictlanguage = await Dict.itemsObject('gu_language');
      for (let row of data.result.content) {
        row.languageChild = dictlanguage[row.language] || {};
      }
    dispatch({ type: SET_CHILD, payload: data.result, filter });
  } catch (error) {
    handleError(error, i18next.t('classListPage_errorLoadChild'));
  } finally {
    dispatch({ type: LOADING, payload: false });
  }
}

export const addChild = ({ groupId, childId, filter, id }) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const data = await GroupApi.addChildGroup({groupId, params: {id: childId}})
    if (data.status === 200) {
      dispatch(getClassChild({ filter, id }))
      Notice.success(i18next.t('classListPage_saveFormSuccess'));
    }
  } catch (error) {
    handleError(error, i18next.t('classListPage_errorSaveForm'));
  } finally {
    dispatch({ type: LOADING, payload: false });    
  }
}

export const clearState = () => ({ type: CLEAR_STATE });
