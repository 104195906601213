import  { useState } from 'react';

function useTableExpander() {
  const [expanded, setExpanded] = useState({});

  const editExpanded = (isExpanded, index) => {
    const newExpanded = { ...expanded };
    isExpanded ? delete newExpanded[index] : (newExpanded[index] = {});
    setExpanded(newExpanded);
  };

  return { expanded, editExpanded };
}

export default useTableExpander;
