import { statusColumns } from '../statusColumns';
import Chart from 'chart.js';

export function renderHorizontalCharts(t, lang, data, status) {
  const statuses = status ? [status] : Object.keys(statusColumns);
  const config = {
    type: 'horizontalBar',
    data: {
      labels: data.map(item => item.service[`${lang}_name`]),
      datasets: [
        {
          backgroundColor: '#4ea3e3',
          data: data.map(item => {
            let result = 0;
            for (const column of statuses) {
              if (typeof item[column] === 'number') {
                result += item[column];
              }
            }
            return result;
          })
        }
      ]
    },
    options: {
      responsive: false,
      legend: { display: false },
      title: { display: false },
      scales: {
        xAxes: [{ ticks: { beginAtZero: true } }],
        yAxes: [{ ticks: { display: false } }]
      }
    }
  };

  let elem = document.createElement('canvas');
  let container = document.getElementById('horizontal');
  elem.style.width = '100%';
  elem.style.height = '600px';
  container.innerHTML = '';
  container.appendChild(elem);
  new Chart(elem.getContext('2d'), config);
}
