import React, { useEffect } from 'react';
import PassportForm from '../../../../passport/FormData/PassportForm';
import { changeFormValue } from '../../../../passport/Passport';

function ResetAgeGroup() {
    const beginAcademicYear = PassportForm.values['form_contingent_do_child.begin_academic_year'];
    
   /* useEffect(() => {
        changeFormValue('form_contingent_do_child.age_group', '');
    }, [beginAcademicYear])
    */
    return null;
}
  
export default ResetAgeGroup;