import moment from 'moment';

export const queueFilterValues = {
  queue_name: 'ALL',
  datetime_begin: moment()
    .add(-20, 'd')
    .format('YYYY-MM-DDTHH:mm:ss'),
  datetime_end: moment().format('YYYY-MM-DDTHH:mm:ss')
};

export const queueByNameValues = {
  status: 'ALL',
  app_name: 'ALL',
  queue_name: 'ALL',
  datetime_begin: moment()
    .add(-20, 'd')
    .format('YYYY-MM-DDTHH:mm:ss'),
  datetime_end: moment().format('YYYY-MM-DDTHH:mm:ss'),
  pageNumber: 1,
  pageSize: 20
};

export const queueNames = [
  { code: 'ALL', ru_name: 'ALL', kk_name: 'ALL' },
  { code: 'EGOV_ES', ru_name: 'EGOV_ES', kk_name: 'EGOV_ES' },
  { code: 'EXTAPP_ES', ru_name: 'EXTAPP_ES', kk_name: 'EXTAPP_ES' },
  { code: 'ES_EXTAPP', ru_name: 'ES_EXTAPP', kk_name: 'ES_EXTAPP' },
  {
    code: 'ES_EGOV_FAILURE',
    ru_name: 'ES_EGOV_FAILURE',
    kk_name: 'ES_EGOV_FAILURE'
  },
  {
    code: 'ES_EGOV_RESPONSE',
    ru_name: 'ES_EGOV_RESPONSE',
    kk_name: 'ES_EGOV_RESPONSE'
  },
  { code: 'EXPORT_FS', ru_name: 'EXPORT_FS', kk_name: 'EXPORT_FS' },
  {
    code: 'ES_NEDB_RESPONSE',
    ru_name: 'ES_NEDB_RESPONSE',
    kk_name: 'ES_NEDB_RESPONSE'
  }
];

export const statusNames = [
  { code: 'ALL', ru_name: 'ALL', kk_name: 'ALL' },
  { code: 'SUCCESS', ru_name: 'SUCCESS', kk_name: 'SUCCESS' },
  { code: 'FAILURE', ru_name: 'FAILURE', kk_name: 'FAILURE' },
  { code: 'WAITING', ru_name: 'WAITING', kk_name: 'WAITING' }
];
