import i18next from 'i18next';
import Notice from '../../utils/Notice';
import './NCLayerTranslate'

export const NCALayerSign = (doc, isAuth) =>
  new Promise(resolve => {
    const socket = new WebSocket('wss://127.0.0.1:13579/');

    socket.onmessage = event => {
      const result = JSON.parse(event.data);
      if (result.message === 'action.canceled') {
        resolve(null);
        socket.close();
      }

      if (result.status === true) {
        let res = result?.body?.result?.length > 0 ? result?.body?.result[0] : null;
        resolve(res);
        socket.close();
      }
    };

    socket.onclose = event => {
      if (!event.wasClean) {
        resolve(null);
        Notice.error(i18next.t('ncaLayer_switched_off'));
      }
    };

    socket.onopen = () => {
      const signInfo = {
        module: 'kz.gov.pki.knca.basics',
        method: 'sign',
        args: {
          allowedStorages: 'PKCS12',
          format: 'xml',
          data: doc,
          signingParams: {
            decode: false,
            encapsulate: true,
            digested: false,
            tsaProfile: {}
          },
          signerParams: {
            extKeyUsageOids: [],
            chain: []
          },
          locale: localStorage.getItem('lang')
        }
      };

      socket.send(JSON.stringify(signInfo));
    };
  });
