import { createReducer } from 'redux-starter-kit';
import { handleError } from '../../utils/handleError';
import i18next from 'i18next';
import { QueueApi } from '../../_helpers/service';
import moment from 'moment';

/**
 * Constants
 */
export const queueModule = 'queue';
const LOADING = `${queueModule}/LOADING`;
const LOADING_CANCEL_QUEUE = `${queueModule}/LOADING_CANCEL_QUEUE`;
const SET_TAB1_DATA = `${queueModule}/SET_TAB1_DATA`;
const SET_TAB2_DATA = `${queueModule}/SET_TAB2_DATA`;
const SET_DATA_BY_NAME = `${queueModule}/SET_DATA_BY_NAME`;
const CLEAR_STATE = `${queueModule}/CLEAR_STATE`;

/**
 * Reducer
 */
const initialState = {
  loading: true,
  loadingCancelQueue: true,
  tab1Data: [],
  tab2Data: [],
  dataByName: {
    content: [],
    totalElements: 1078019
  }
};

export default createReducer(initialState, {
  [LOADING]: (state, action) => {
    state.loading = action.payload;
  },
  [LOADING_CANCEL_QUEUE]: (state, action) => {
    state.loadingCancelQueue = action.payload;
  },
  [SET_TAB1_DATA]: (state, action) => {
    state.tab1Data = action.payload;
  },
  [SET_TAB2_DATA]: (state, action) => {
    state.tab2Data = action.payload;
  },
  [SET_DATA_BY_NAME]: (state, action) => {
    const filter = action.filter || {};
    if (filter.pageNumber && filter.pageNumber !== 1) {
      state.dataByName.content = [...state.dataByName.content, ...action.payload.content];
    } else {
      state.dataByName = action.payload;
    }
  },
  [CLEAR_STATE]: () => initialState
});

/**
 * Actions
 */
export const clearState = () => ({ type: CLEAR_STATE });

export const loadTab1Data = filter => async dispatch => {
  try {
    dispatch({ type: LOADING, payload: true });
    const response = await QueueApi.loadTab1Data(filter);
    const data = [];
    for (const row of response.data.content) {
      const newRow = {};
      for (const item of row.passportValues) {
        newRow[item.code] = item.value;
      }
      data.push(newRow);
    }
    dispatch({ type: SET_TAB1_DATA, payload: data });
  } catch (error) {
    handleError(error, i18next.t('queuePage_errorLoadTable'));
  } finally {
    dispatch({ type: LOADING, payload: false });
  }
};

export const loadTab2Data = requestId => async dispatch => {
  try {
    dispatch({ type: LOADING, payload: true });
    const response = await QueueApi.loadTab2Data(requestId);
    const data = [];
    for (const row of response.data.content) {
      const newRow = {};
      for (const item of row.passportValues) {
        if (item.code === 'last_attempt_date' && item.value) {
          newRow[item.code] = moment(item.value).format('DD.MM.YYYY HH:mm');
        } else {
          newRow[item.code] = item.value;
        }
      }
      data.push(newRow);
    }
    dispatch({ type: SET_TAB2_DATA, payload: data });
  } catch (error) {
    handleError(error, i18next.t('queuePage_errorLoadTable'));
  } finally {
    dispatch({ type: LOADING, payload: false });
  }
};

export const loadDataByName = filter => async dispatch => {
  try {
    dispatch({ type: LOADING, payload: true });
    const response = await QueueApi.loadDataByName(filter);
    const data = [];
    for (const row of response.data.content) {
      const newRow = {};
      for (const item of row.passportValues) {
        if (item.code === 'last_attempt_date' && item.value) {
          newRow[item.code] = moment(item.value).format('DD.MM.YYYY HH:mm');
        } else {
          newRow[item.code] = item.value;
        }
      }
      data.push(newRow);
    }
    dispatch({
      type: SET_DATA_BY_NAME,
      filter: filter,
      payload: {
        content: data,
        totalElements: response.data.totalElements
      }
    });
  } catch (error) {
    handleError(error, i18next.t('queuePage_errorLoadTable'));
  } finally {
    dispatch({ type: LOADING, payload: false });
  }
};

export const cancelQueue = (id, reloadPage) => async dispatch => {
  try {
    dispatch({ type: LOADING_CANCEL_QUEUE, payload: true });
    await QueueApi.cancelQueue(id);
    reloadPage();
  } catch (error) {
    handleError(error, i18next.t('queuePage_errorCancelQueue'));
  } finally {
    dispatch({ type: LOADING_CANCEL_QUEUE, payload: false });
  }
};
