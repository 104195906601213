import React from 'react';
import moment from 'moment';
import FilterInputField from '../../components/TableFilter/ColumnsFilters/FilterInputField';
import { Color } from '../ThemeProvider/ThemeStyle';

const styles = {
  textAlign: 'center',
  alignSelf: 'center'
};

export const ReportColumns = ({ t, days, open, cause, lang }) => {
  let newDays = []
  days && days.forEach(item => {
    newDays.push({
      Header: item,
      accessor: String(`${item}`),
      Cell: ({original}) => {
        let currentVisited = null;
        let text = null;
        if (original?.visitsInfo) {
          original.visitsInfo.forEach((visitInfo) => {
            if (moment(visitInfo.logDate).format('DD') === item) {
              currentVisited = visitInfo;
              currentVisited['userID'] = original.childId ? original.childId : original.staffId;
            }
          })
        }
        cause.forEach((item) => {
          if (item.code === currentVisited?.['absenceReason']) {
            if (lang == 'ru') {
              text = item?.fisrt_letter_ru
            } else {
              text = item?.first_letter_kk
            }
          }
        })
        return (
          <>
            {
              currentVisited?.isVisited === false && <div 
              style={{
                backgroundColor: 
                  currentVisited.absenceReason && Color.injury  || 
                  Color.secondary,
                textAlign: 'center',
                transform: 'scale(2)',
                marginTop: 1,
                display: 'list-item',
                cursor: 'pointer'
              }}
              onClick={() => {if (open) {
                open(currentVisited)
              }}}
            > 
              <span style={{ transform: 'scale(0.45)', display: 'block', color: '#000' }}> 
                {text}
              </span>
            </div>
            }
            {
              currentVisited?.isVisited === true && 
              <div 
                style={{
                  backgroundColor: Color.bgColorSuccess,
                  textAlign: 'center',
                  transform: 'scale(2)',
                  marginTop: 1,
                  display: 'list-item',
                  cursor: 'pointer'
                }}
                onClick={() => {if (open) {
                  open(currentVisited)
                }}}
              > 
                <span style={{ transform: 'scale(0.55)', display: 'block' }}> + </span>
              </div>
            }
          </>
        ) 
      },
      Filter: <span></span>,
    })
  });
  let daysTable = [
    {
      Header: '№',
      accessor: 'rowNumber',
      maxWidth: 100,
      width: 100,
      filterable: false,
      Cell: (row) => row.original.iin && row.index + 1,
    },
    {
      Header: 'ID',
      accessor: 'childId',
      Filter: <FilterInputField name="childId" type="number" />,
      maxWidth: 150,
      width: 150,
      Cell: ({ original}) => {
        return (
          <div>
            {original.childId && original.childId}
            {original.staffId && original.staffId}
          </div>
        )
      },
    },
    {
      Header: t('pageReport_fullName'),
      accessor: 'fullName',
      Filter: <FilterInputField name="fullName" />,
      Cell: ({ original}) => {
        return (
          <div style={{fontWeight: original.totalFinish ? 600 : null}}>
            {original.surname} {original.name} {original?.middlename}
          </div>
        )
      },
      maxWidth: 470,
      width: 470,
    },
    ...newDays,
    {
      Header: t('pageReport_visitedDays'),
      accessor: 'visitedDays',
      Filter: <span></span>,
      maxWidth: 250,
      width: 250,
      style: styles
    },
    {
      Header: <div>
        <p className='reportBorderBottom'>{t('pageReport_missedDays')}</p>
        <div style={{display: 'flex'}}>
          <p className='reportBorderRight'>{t('pageReport_withoutReason')}</p>
          <p style={{padding: 0, margin: 0, width: '50%', maxWidth: '50%'}}>{t('pageReport_withReason')}</p>
        </div>
      </div>,
      accessor: 'unvisitedDaysWithoutReason',
      Filter: <span></span>,
      maxWidth: 600,
      width: 600,
      Cell: ({ original}) => {
        return (
          <div style={{display: 'flex'}}>
            <p className='reportBorderRight'>{original.unvisitedDaysWithReason}</p> 
            <p style={{padding: 0, margin: 0, width: '50%', maxWidth: '50%'}}>{original.unvisitedDaysWithoutReason}</p>
          </div>
        )
      },
      style: styles
    },
  ];
  return daysTable
};
