import { addTranslation } from '../../_helpers/Translate';

addTranslation({
  ru: {
    kezekStopList_title: 'Стоп лист',
    kezekStopList_columnID: '№ заявки',
    kezekStopList_childIIN: 'ИИН ребенка',
    kezekStopList_childName: 'ФИО ребенка',
    kezekStopList_requesterIIN: 'ИИН заявителя',
    kezekStopList_requesterName: 'ФИО заявителя',
    kezekStopList_stopListDate: 'Дата перехода в стоп-лист',
    kezekStopList_deadlineStopList: 'Срок нахождения в стоп-листе',
    kezekStopList_legalLocality: 'Населенный пункт',
    kezekStopList_serviceProviderBin: 'БИН',

    kezekStopListDucks_errorLoadTable: 'Не удалось загрузить стоп лист',
  },
  kk: {
    kezekStopList_title: 'Тоқтату парағы',
    kezekStopList_columnID: 'Өтініш №',
    kezekStopList_childIIN: 'Баланың ЖСН',
    kezekStopList_childName: 'Баланың аты-жөні',
    kezekStopList_requesterIIN: 'Өтініш берушінің ЖСН-і',
    kezekStopList_requesterName: 'Өтініш берушінің аты-жөні',
    kezekStopList_stopListDate: 'Тоқтату парағы тізіміне көшу күні',
    kezekStopList_deadlineStopList: 'Тоқтату парағы тізіміндегі уақыт ұзақтығы',
    kezekStopList_legalLocality: 'Елді мекен',
    kezekStopList_serviceProviderBin: 'БСН',

    kezekStopListDucks_errorLoadTable: 'Тоқтату парағын жүктеу мүмкін болмады',
  }
});
