import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormHelperText from '@material-ui/core/FormHelperText';
import Field from '../Form/Field';

const CheckboxControl = props => {
  const {
    label,
    value,
    onChange,
    fullWidth,
    disabled,
    name,
    error,
    helperText,
    color,
    ...restProps
  } = props;

  return (
    <FormControl
      fullWidth={fullWidth}
      disabled={disabled}
      error={!!error}
    >
      <FormControlLabel
        name={name}
        disabled={disabled}
        control={
          <Checkbox
            checked={value}
            onChange={onChange}
            value={name}
            color={color}
            {...restProps}
          />
        }
        label={label}
      />
      {!!error && <FormHelperText error={!!error}>{helperText}</FormHelperText>}
    </FormControl>
  );
};

const JustCheckbox = props => {
  const { withoutForm, name, onChange, ...restProps } = props;

  return withoutForm ?
    <CheckboxControl
      name={name}
      onChange={onChange}
      {...restProps}
    />
    :
    <Field name={name}>
      {({ onChange, ...fieldProps }) =>
        <CheckboxControl
          name={name}
          onChange={event => onChange(event.target.checked)}
          {...restProps}
          {...fieldProps}
        />
      }
    </Field>;
};

JustCheckbox.propsTypes = {
  withoutForm: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  color: PropTypes.string
};

JustCheckbox.defaultProps = {
  withoutForm: false,
  label: '',
  value: false,
  disabled: false,
  color: 'primary'
};

export default JustCheckbox;
