import React, { useState, useEffect } from 'react';
import Field from '../Form/Field';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import FormHelperText from '@material-ui/core/FormHelperText';
import MuiSelect from '@material-ui/core/Select';
import Dict from '../../utils/Dict';
import i18next from 'i18next';

const getChecked = (optionValue, value) =>
  value instanceof Array ? value.includes(optionValue) : value === optionValue;

const getOptionsValue = (options, value) => {
  let result = [];
  options.forEach(option => {
    getChecked(option.code, value) && result.push(option[`${i18next.language}_name`]);
  });
  return result.join(', ');
};

function SelectComponent(props) {
  const { name, value, onChange, multiple, lookupId, disabled } = props;
  const [sOptions, setOptions] = useState();
  const options = props.options || sOptions || [];

  useEffect(() => {
    if (lookupId) {
      Dict.items(lookupId).then(dictOptions => setOptions(dictOptions));
    }
  }, [lookupId]);
  return (
    <FormControl fullWidth error={!!props.error}>
      <MuiSelect
        disabled={disabled}
        name={name}
        multiple={multiple}
        value={multiple ? value || [] : value}
        onChange={event => onChange(event)}
        displayEmpty
        MenuProps={{ style: { maxWidth: 650 } }}
        renderValue={value => {
          return value === undefined || value === '' || value.length === 0 ? (
            <span style={{ color: '#BFBFBF' }}> {props.placeholder} </span>
          ) : (
            getOptionsValue(options, value)
          );
        }}
      >
        <MenuItem value="" selected={true} disabled>
          <ListItemText primary={props.placeholder} />
        </MenuItem>
        {options.map(option => (
          <MenuItem key={option.code} value={option.code} disabled={option.disabled}>
            {multiple && (
              <Checkbox
                checked={getChecked(option.code, value)}
                style={{ marginRight: 5, width: 30 }}
              />
            )}
            <ListItemText primary={option[`${i18next.language}_name`]} />
          </MenuItem>
        ))}
      </MuiSelect>
      {props.error && <FormHelperText>{props.helperText}</FormHelperText>}
    </FormControl>
  );
}

const Select = props => {
  const { withoutForm, name, ...restProps } = props;
  return withoutForm ? (
    <SelectComponent name={name} onChange={props.onChange} {...restProps} />
  ) : (
    <Field name={name}>
      {({ onChange, ...fieldProps }) => (
        <SelectComponent
          name={name}
          {...restProps}
          {...fieldProps}
          error={props.error}
          onChange={event => {
            onChange(event.target.value);
            props.onChange && props.onChange(event);
          }}
        />
      )}
    </Field>
  );
};

Select.propsTypes = {
  withoutForm: PropTypes.bool,
  name: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired
};

Select.defaultProps = {
  withoutForm: false
};

export default Select;
