import { addTranslation } from '../../_helpers/Translate';

addTranslation({
  ru: {
    contactSupport_title: 'Обращение в службу технической поддержки',
    contactSupport_telephones: 'Телефоны службы технической поддержки:',
    contactSupport_eZhetysu: 'Для обращения в Службу Поддержки пройдите по ссылке:',
    contactSupport_skypeChat: 'Для входа в открытый чат службы необходимо пройти по ссылке:',
    contactSupport_description:
      'Чтобы связаться со службой технической поддержки, Вам необходимо направить свой вопрос (ошибку)  по почте',
    contactSupport_messageDescription: 'Содержание письма:',
    contactSupport_message1: `В заголовке письма (тема)  важно кратко указывать суть вопроса. Например : «Тип заявки не отображается» или «Не открывается сайт ${window.location.host}».`,
    contactSupport_message2:
      'В тексте письма нужно указывать наименование государственной услуги. Например : Услуга - «Выдача справок по опеке и попечительству»',
    contactSupport_message3:
      'В тексте письма нужно указывать номер заявки. Например: Номер заявки:001',
    contactSupport_message4: `В тексте письма подробно опишите проблему  и, если необходимо, прикрепите скриншоты, ссылки. Например: «${window.location.host} не открывается и выдает ошибку 504».`,
    contactSupport_example: 'Пример:',
    contactSupport_exampleText1: `Тема: Не открывается сайт ${window.location.host}`,
    contactSupport_exampleText2: 'Услуга: Выдача справок по опеке и попечительству',
    contactSupport_exampleText3: 'Номер заявки: 001',
    contactSupport_exampleText4: `${window.location.host} не открывается и выдает ошибку 504`,
    contactSupport_exampleText5: 'Снимок экрана:'
  },
  kk: {
    contactSupport_title: 'Техникалық қолдау қызметіне жүгіну',
    contactSupport_telephones: 'Техникалық қолдау телефондары:',
    contactSupport_eZhetysu: 'Қолдау қызметіне хабарласу үшін мына сілтеме бойынша өтіңіз:',
    contactSupport_skypeChat: 'Қолдау қызметінің ашық чатына кіру үшін келесі сілтеме арқылы өтіңіз:',
    contactSupport_description:
      'Техникалық қолдау қызметімен байланысу үшін, Сіз өз сұрағыңызды (қатені) поштаcы арқылы жіберуіңіз қажет',
    contactSupport_messageDescription: 'Хаттың мазмұны:',
    contactSupport_message1: `Хаттың тақырыпатында (тақырыбы) мәселеңізді қысқаша көрсетуіңіз қажет. Мысалы : «Өтінім түрі көрсетілмей тұр»  немесе «${window.location.host} сайты ашылмай тұр» ».`,
    contactSupport_message2:
      'Хаттың мәтінінде мемлекеттік қызметтің атауын көрсету қажет. Мысалы : Мемлекеттік қызметтің атауы- «Білім туралы құжаттардың телнұсқаларын беру»',
    contactSupport_message3:
      'Хаттың мәтінінде өтінім нөмірін көрсету қажет. Мысалы:Өтінім нөмірі: 001',
    contactSupport_message4: `Хаттың мәтінінде мәселені егжей-тегжейлі сипаттап, қажет болса, скриншоттарды, сілтемелерді бекітіңіз. Мысалы: ${window.location.host} ашылмай тұр және 504 қате беріп тұр»`,
    contactSupport_example: 'Мысал:',
    contactSupport_exampleText1: `Тақырып: ${window.location.host} сайты ашылмай тұр`,
    contactSupport_exampleText2:
      'Мемлекеттік қызметтің атауы: Білім туралы құжаттардың телнұсқаларын беру',
    contactSupport_exampleText3: 'Өтінім нөмірі: 001',
    contactSupport_exampleText4: `${window.location.host} сайты ашылмай жатыр және 504 қатесі шығып тұр`,
    contactSupport_exampleText5: 'Экран суреті:'
  }
});
