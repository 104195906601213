import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';
import { Color } from '../../components/ThemeProvider/ThemeStyle';

export const Wrapper = styled(Paper)`
  min-width: 1000px;
  .title {
    font-weight: 600;
    font-size: 18px;
    padding-bottom: 5px;
  }
  .left-info {
    width: 350px;
  }
`;

export const ErrorMessage = styled.div`
  color: ${Color.secondary};
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: pre-wrap;
`;

export const ButtonsWrapper = styled.div`
  position: sticky;
  bottom: 0;
  background-color: ${Color.bgColorPaper};
  padding: 10px 0;
  text-align: right;
  border-top: 1px solid ${Color.border};
  margin-top: 30px;
  z-index: 1;
  box-shadow: 0 -6px 6px -6px rgba(0, 0, 0, 0.16);
`;

export const TableWrapper = styled.div`
  .MuiFormControlLabel-root {
    margin: 0 !important;
  }
  .rt-td:first-child {
    padding: 0 !important;
  }

  .rt-thead.-header {
    top: -15px !important;
  }

  .rt-thead.-filters {
    top: 25px !important;
  }
`;
