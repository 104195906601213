import { createReducer } from 'redux-starter-kit';
import i18next from 'i18next';
import _ from 'lodash';
import { TaskApi } from '../../../../_helpers/service';
import Notice from '../../../../utils/Notice';
import { NCALayerSign } from '../../../../components/NCALayer/NCALayerSign';
import Dict from '../../../../utils/Dict';
import { handleTaskError } from '../handleTaskError';
import { forwardToNextTask } from '../../MyTaskDucks';

export const signTaskModule = 'signTaskModule';
const LOADING = `${signTaskModule}/LOADING`;
const SET_INFO = `${signTaskModule}/SET_INFO`;
const SIGNING = `${signTaskModule}/SIGNING`;
const REJECTING = `${signTaskModule}/REJECTING`;
const CLEAR_STATE = `${signTaskModule}/CLEAR_STATE`;
const SHORT_INFO = `${signTaskModule}/SHORT_INFO`;

/**
 * Reducer
 */

const initialState = {
  loading: false,
  signing: false,
  rejecting: false,
  shortInfo: false,
  info: { configuration: {} }
};

export default createReducer(initialState, {
  [SET_INFO]: (state, action) => {
    state.info = action.payload;
  },
  [LOADING]: (state, action) => {
    state.loading = action.payload;
  },
  [SIGNING]: (state, action) => {
    state.signing = action.payload;
  },
  [SHORT_INFO]: (state, action) => {
    state.shortInfo = action.payload;
  },
  [CLEAR_STATE]: () => initialState
});

/**
 * Actions
 */

export const loadData = id => async dispatch => {
  dispatch({ type: LOADING, payload: true });
  try {
    let { data } = await TaskApi.loadTask(id);
    const dictServices = await Dict.itemsObject('gu_services');
    const key = data.result.metadataKey.replace('REQUEST_FORM_', '');
    data.result.serviceTypeName = dictServices[key] || {};

    try {
      if (data.result.businessObjectId) {
        let res = await TaskApi.shortInfo(data.result.businessObjectId);
        dispatch({ type: SHORT_INFO, payload: res.data.result });
      }
    } catch (e) {}

    dispatch({ type: SET_INFO, payload: data.result });
  } catch (error) {
    handleTaskError(error, i18next.t('taskDucks_errorLoadTask'));
  } finally {
    dispatch({ type: LOADING, payload: false });
  }
};

export const complete = () => async (dispatch, getState) => {
  try {
    dispatch({ type: SIGNING, payload: true });
    const task = getState()[signTaskModule].info;
    const { data: sign } = await TaskApi.getSignString(task.id);
    const signedXml = await NCALayerSign(sign.result);
    if (signedXml) {
      const { data } = await TaskApi.signComplete(task.id, {
        '@type': 'SignBusinessObjectTaskData',
        signatureXml: signedXml
      });
      if (data.status === 'SUCCESS') {
        dispatch({ type: SET_INFO, payload: data.result });
        Notice.success(i18next.t('signTask_successSign'));
      }
    }
  } catch (error) {
    const errorCode = _.get(error, 'response.data.errors[0].code');
    if (errorCode === 'error.runtime_user_must_sing_own_signature') {
      Notice.error(i18next.t('signTask_errorSignatureValidation'));
    } else {
      handleTaskError(error, i18next.t('signTask_errorSign'));
    }
  } finally {
    dispatch({ type: SIGNING, payload: false });
  }
};

export const reject = (reason, user, closeModal) => async (dispatch, getState) => {
  try {
    dispatch({ type: REJECTING, payload: true });
    const task = getState()[signTaskModule].info;
    const { data } = await TaskApi.signReject(task.id, {
      '@type': 'SignBusinessObjectTaskData',
      signatureRejection: true,
      ...reason
    });
    dispatch({ type: SET_INFO, payload: data.result });

    forwardToNextTask(data.result.processInstanceId, user, { isSign: true }, () => {
      Notice.success(i18next.t('signTask_successReject'));
    });
    closeModal && closeModal();
  } catch (error) {
    handleTaskError(error, i18next.t('signTask_errorReject'));
  } finally {
    dispatch({ type: REJECTING, payload: false });
  }
};

export const clearState = () => ({ type: CLEAR_STATE });
