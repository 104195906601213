import React from 'react';
import FilterDatePicker from '../../../components/TableFilter/ColumnsFilters/FilterDatePicker';
import FilterSelect from '../../../components/TableFilter/ColumnsFilters/FilterSelect';
import FilterInputField from '../../../components/TableFilter/ColumnsFilters/FilterInputField';
import moment from 'moment';

const styles = {
  textAlign: 'center',
  alignSelf: 'center'
};

export const ExitedColumns = ({ t, lang }) => {
  return [
    {
      Header: '№',
      accessor: 'rowNumber',
      maxWidth: 60,
      filterable: false
    },
    {
      Header: t('contingentPage_columnApplicationNumberEnrollment'),
      accessor: 'id',
      Filter: <FilterInputField name="id" type="number" />
    },
    {
      Header: t('contingentPage_columnEnrollmentDate'),
      accessor: 'enrolledDate',
      style: styles,
      Cell: row => moment(row.original.enrolledDate).format('DD.MM.YYYY'),
      Filter: <FilterDatePicker name="enrolledDate" lang={lang} />,
    },
    {
      Header: t('contingentPage_columnRetirementDate'),
      accessor: 'expelledDate',
      style: styles,
      Cell: row => moment(row.original.expelledDate).format('DD.MM.YYYY'),
      Filter: <FilterDatePicker name="expelledDate" lang={lang} />,
    },
    {
      Header: t('contingentPage_columnChild_iin'),
      accessor: 'childIin',
      style: styles,
      Filter: <FilterInputField name="iin" type="number" />
    },
    {
      Header: t('contingentPage_columnFullName'),
      accessor: 'childFullName',
       Cell: ({ original}) => {
        return (
          <div>
            {original.childSurname} {original.childName} {original?.childMiddlename}
          </div>
        )
      },
      Filter: <FilterInputField name="childFullName" />
    },
    {
      Header: t('contingentPage_columnAgeGroup'),
      accessor: 'ageGroup',
      Cell: ({ original: { ageGroupName } }) => ageGroupName[`${lang}_name`],
      Filter: <FilterSelect name="ageGroup" dictCode="gu_age_group" lang={lang} />
    },
    {
      Header: t('contingentPage_columnQueuedType'),
      accessor: 'queuedType',
      Cell: ({ original: { queuedTypeName } }) => queuedTypeName[`${lang}_name`],
      Filter: <FilterSelect name="queuedType" dictCode="gu_queued_type" lang={lang} />
    },
    {
      Header: t('contingentPage_columnPrivilegeType'),
      accessor: 'privilegeType',
      Cell: ({ original: { privilege } }) => privilege[`${lang}_name`],
      Filter: <FilterSelect name="privilegeType" dictCode="gu_privilege" lang={lang} />
    },
    {
      Header: t('contingentPage_columnOrderNum'),
      accessor: 'orderNum',
      Filter: <FilterInputField name="orderNum" type="number" />
    },
    {
      Header: t('contingentPage_columnOrderDate'),
      accessor: 'orderDate',
      Cell: row => moment(row.original.orderDate).format('DD.MM.YYYY'),
      Filter: <FilterDatePicker name="orderDate" lang={lang} />
    },
    {
      Header: t('contingentPage_columnBeginAcademicYear'),
      accessor: 'beginAcademicYear',
      Cell: ({ original: { academicYearName } }) => academicYearName[`${lang}_name`],
      Filter: <FilterSelect name="beginAcademicYear" dictCode="gu_academic_year_do" lang={lang} />
    },
    {
      Header: t('contingentPage_columnLanguage'),
      accessor: 'language',
      Cell: ({ original: { languageChild } }) => languageChild[`${lang}_name`],
      Filter: <FilterSelect name="language" dictCode="gu_language" lang={lang} />
    },
  ];
};
