import React, { useCallback, useEffect, useState } from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import useColumnFilter from '../useColumnFilter';
import CloseIcon from '@material-ui/icons/Close';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';

function FilterDatePicker({ name, endDate, style, placeholder }) {
  const { i18n } = useTranslation();
  const { value: filterValue, setFilter } = useColumnFilter(name);
  const [value, setValue] = useState(null);
  const [error, setError] = useState();

  useEffect(() => {
    if (filterValue && !value) {
      setValue(filterValue);
    }
    // eslint-disable-next-line
  }, [filterValue, setValue]);

  const textFieldComponent = useCallback(props => {
    return (
      <TextField
        {...props}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {props.value && (
                <IconButton
                  className="show-on-hover"
                  onClick={() => {
                    setFilter(null);
                    setValue(null);
                  }}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              )}
              {props.InputProps.endAdornment.props.children}
            </InputAdornment>
          )
        }}
      />
    );
    // eslint-disable-next-line
  }, []);

  return (
    <KeyboardDatePicker
      name={name}
      value={value}
      error={!!error}
      onError={error => setError(error)}
      variant="inline"
      format="DD.MM.YYYY"
      autoOk
      invalidDateMessage={
        i18n.language === 'ru' ? 'Недопустимый формат даты' : 'Қате күн мәні'
      }
      minDateMessage={
        i18n.language === 'ru'
          ? 'Дата не должна быть раньше минимальной даты'
          : 'Қате күн мәні'
      }
      maxDateMessage={
        i18n.language === 'ru'
          ? 'Дата не должна быть после максимальной даты'
          : 'Қате күн мәні'
      }
      onChange={(date, dateString) => {
        if (date && date.isValid()) {
          const dateMoment = endDate
            ? date
                .hours(23)
                .minutes(59)
                .seconds(59)
                .milliseconds(0)
            : date
                .hours(0)
                .minutes(0)
                .seconds(0)
                .milliseconds(0);
          setValue(dateMoment.format('YYYY-MM-DDTHH:mm:ss'));
          setFilter(dateMoment.format('YYYY-MM-DDTHH:mm:ss'));
        } else {
          setValue(dateString);
          setFilter(null);
        }
      }}
      placeholder={placeholder}
      TextFieldComponent={textFieldComponent}
      style={style}
    />
  );
}

export default FilterDatePicker;
