import { addTranslation } from '../../_helpers/Translate';

addTranslation({
  ru: {
    groupsTablePage_title: 'Группы',
    groupsTablePage_information: 'Сведения о группе',
    groupsTablePage_numberGroup: 'Номер группы',
    groupsTablePage_nameGroup: 'Наименование группы',
    groupsTablePage_beginAcademicYear: 'Учебный год',
    groupsTablePage_ageGroup: 'Возрастная группа',
    groupsTablePage_language: 'Язык обучения',
    groupsTablePage_stateOrder: 'Госзаказ',
    groupsTablePage_bin: 'БИН',
    groupsTablePage_legalLocality: 'Населенный пункт',
    groupsTablePage_add: 'Добавить',
    groupsTablePage_stateOrderYes: 'Да',
    groupsTablePage_stateOrderNo: 'Нет',
    groupsTablePage_errorLoadTable: 'Не удалось загрузить группу',
    groupsTablePage_action: 'Действие',
    groupsTablePage_errorRemove: 'Не удалось удалить элемент',
    groupsTablePage_deleteSuccess: 'Элемент успешно удаленно',
    groupsTablePage_errorRemoveInfo: 'Удаление группы невозможно т.к. в этой группе числятся дети, необходимо перенести всех детей в другие группы',
    groupsTablePage_tableActionUpdate: 'Изменить',
    groupsTablePage_tableActionDelete: 'Удалить',
    groupsTablePage_educators: 'Воспитатель',
  },
  kk: {
    groupsTablePage_title: 'Топтар',
    groupsTablePage_information: 'Топ туралы мәліметтер',
    groupsTablePage_numberGroup: 'Топ нөмірі',
    groupsTablePage_nameGroup: 'Топ аты',
    groupsTablePage_beginAcademicYear: 'Оқу жылы',
    groupsTablePage_ageGroup: 'Жас тобы',
    groupsTablePage_language: 'Оқыту тілі',
    groupsTablePage_stateOrder: 'Мемлекеттік тапсырыс',
    groupsTablePage_bin: 'БИН',
    groupsTablePage_legalLocality: 'Аймақ',
    groupsTablePage_add: 'Қосу',
    groupsTablePage_stateOrderYes: 'Ия',
    groupsTablePage_stateOrderNo: 'Жоқ',
    groupsTablePage_errorLoadTable: 'Топты жүктеу мүмкін болмады',
    groupsTablePage_action: 'Әрекет',
    groupsTablePage_errorRemove: 'Элементті жою мүмкін болмады',
    groupsTablePage_deleteSuccess: 'Элемент сәтті жойылды',
    groupsTablePage_errorRemoveInfo: 'Топты жою мүмкін емес, себебі бұл топта балалар бар. Барлық балаларды басқа топтарға ауыстыру қажет.',
    groupsTablePage_tableActionUpdate: 'Өзгерту',
    groupsTablePage_tableActionDelete: 'Жою',
    groupsTablePage_educators: 'Тәрбиешілер',
  }
});