import React, {useEffect, useState} from 'react';
import PassportForm from '../../passport/FormData/PassportForm';
import {DocumentApi} from '../../_helpers/service';
import { CONTINGENT_STATUS } from '../../_helpers/Constants';
import { PhotoWrapper } from './ContingentPassportStyle'

const getInfo = (data, t, lang) => {
  return [
    { label: t('contingentPassport_infoDirectionNumber'), value: data.directionId },
    { label: t('contingentPassport_infoDirectionStatus'), value: data.statusDirection[`${lang}_name`] },
    { label: t('contingentPassport_infoApplicantIIN'), value: data.iin },
  ].filter(item => item.value);
};
  
export default function ContingentInfo({ info, t, lang }) {
  const [urlImg, setUrlImg] = useState('')

  useEffect(() => {
    let value = PassportForm.values['form_contingent_do_child.Foto'];
    if (value) {
      DocumentApi.view(value['downloadLink'], value.version)
        .then(response => {
          setUrlImg(URL.createObjectURL(response.data));
        });
    }
  }, [PassportForm.values['form_contingent_do_child.Foto']]);

  useEffect(() => {
    setTimeout(() => {
      const elemet = document.getElementById('attach_form_contingent_do_child.Foto');
      elemet && elemet.addEventListener("change", (event) => {
        setUrlImg(URL.createObjectURL(event.target.files[0]));
      })
    }, 2000);
  }, [])

  return (
    <>
      {
        info.contingentStatus !== CONTINGENT_STATUS.ENROLLED &&
        <div className="mb2 card p2">
          {getInfo(info, t, lang).map((list, index) => (
            <div className="pd1" key={index}>
              <span className="bold textSecondary mr1">{list.label}:</span>
              <span>{list.value}</span>
            </div>
          ))} 
        </div>
      }
      {
        info.contingentStatus === CONTINGENT_STATUS.ENROLLED &&
        <div className="mb2">
          {
            urlImg ? 
            <div style={{ width: '220px', height: '240px' }}>
              <img 
                src={urlImg}  
                style={{
                  width: '100%',
                  height: '100%',
                  borderRadius: '6px',
                }}
                alt=''
              />
            </div> :
            <PhotoWrapper> <p> {t('contingentPassport_noPhoto')} </p> </PhotoWrapper>
          }
        </div>
      }
    </>
  );
}
